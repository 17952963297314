(function () {
    'use strict';

    angular
        .module('app.deactevatedUser')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider', 'routeConfigurationProvider'];
    function routeConfig($stateProvider, routeConfigurationProvider) {
        $stateProvider
            .state('deactevatedUser', {
                parent: 'base',
                url: '/deactevatedUser',
                templateUrl: '/app/deactevatedUser/deactevatedUser.html',
                controller: 'DeactevatedUser',
                controllerAs: 'vm',
                requireADLogin: true
            });
                
    }
})();