(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('categoriesDataService', category);

    category.$inject = ['$http', 'logger', '$q', 'emptyGuid'];

    function category($http, logger, $q, emptyGuid) {
        var service = {
            getAllCategories: getAllCategories,
            getAllActiveCategories: getAllActiveCategories,
            getCategoryById: getCategoryById,
            GetCategoriesThatMeetsMaximumDepthLevels: GetCategoriesThatMeetsMaximumDepthLevels,
            addCategory: addCategory,
            editCategory: editCategory,
            deleteCategory: deleteCategory,            
            getAllActiveFlatCategories: getAllActiveFlatCategories,
            convertCategoriesToCatalogFilterStructure: convertCategoriesToCatalogFilterStructure
        };

        return service;


        function convertCategoriesToFlatStructure(allCategories, allowInitialEmptyEntry) {
            var flatCategories = angular.copy(allCategories);

            angular.forEach(flatCategories, function (category, index) {
                category.name = recursive(1, angular.copy(category), allCategories) + ": " + category.name;
                category.name = category.name.substring(2, category.name.length);
            });

            if (allowInitialEmptyEntry) {
                //Ubacivanje praznog područja
                flatCategories.splice(0, 0, {
                    id: undefined,
                    name: '-'
                });
            }



            flatCategories.sort(compareCategoriesName);

            return flatCategories;

            function compareCategoriesName(a, b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }

            function recursive(step, category, categories) {
                var result = "";
                if (step > 10 || category.parent === undefined) {
                    return result;
                }

                var parent = undefined;
                //Find parent in allCategories list
                angular.forEach(categories, function (cat, index) {
                    if (cat.parent !== undefined && cat.id === category.parent.id) {
                        parent = cat;
                    }
                });

                if (parent) {
                    result += recursive(step + 1, parent, categories);
                    result += ": " + parent.name;
                }
                return result;
            }
        }

        function convertCategoriesToCatalogFilterStructure(categories) {
            angular.forEach(categories, function (cat, i) {
                cat.level = getCategoryLevel(0, cat, categories, 70);
            });

            var finalCategoryList = sortCategories(categories);
            return finalCategoryList;


            function sortCategories(categories) {
                var finalList = [];

                angular.forEach(categories, function (cat, index) {
                    if (cat.level === 0) {
                        finalList.push(cat);
                        mergeTwoArrays(finalList, getChildrenRecursive(cat, categories));                        
                    }
                })
                 
                return finalList;
            }

            function getChildrenRecursive(parent, allCategories) {
                var result = [];
                angular.forEach(allCategories, function (cat, index) {
                    if (cat.level === parent.level + 1 && cat.parent.id === parent.id) {
                        result.push(cat);
                        mergeTwoArrays(result, getChildrenRecursive(cat, categories));
                    }
                });

                return result;
            }


            function mergeTwoArrays(dest, src) {
                var n;

                for (n = 0; n < src.length; ++n) {
                    dest.push(src[n]);
                }

                return dest;
            }

            function getCategoryLevel(level, category, allCategories, maxDepth) {
                //Ako si došao do maksimalnog levela, izađi s trenutnim levelom
                if (maxDepth === 0) {
                    return level;
                }

                //Ako nemaš parenta znači da si zadnji čvor i da je trenutni level zadnji
                if (!category.parent || !category.parent.id) {
                    return level;
                }

                //Izvuci parenta iz liste svih kategorija
                var parent = undefined;
                angular.forEach(allCategories, function (potentialParent, index) {
                    if (potentialParent.id === category.parent.id) {
                        parent = potentialParent;
                    }
                });

                //Ovo se nikada ne bi smjelo dogoditi ako se dogodi znači da kategorija ima parenta koji nije u listi svih parenta
                if (!parent) {
                    return level;
                }

                //Pozovi istu metodu s većim levelom i manjim may depthom
                return getCategoryLevel(level + 1, parent, allCategories, maxDepth - 1);
            }
        }



        //#region Get
        function getAllCategories() {
            return $http.get('api/categories').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveCategories() {
            return $http.get('api/categories/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
              

        function getAllActiveFlatCategories(allowInitialEmptyEntry) {
            return getAllActiveCategories().then(function (data) {
                return convertCategoriesToFlatStructure(data, allowInitialEmptyEntry);
            }, function (error) {
                $q.reject(error);
            });
        }


        function getCategoryById(categoryId) {
            return $http.get('api/categories/' + categoryId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function GetCategoriesThatMeetsMaximumDepthLevels() {
            return $http.get('api/categories/active/maximumDepthLevel').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        //#endregion

        //#region Add, Edit and Delete
        function addCategory(category) {
            return $http.post('api/categories', category).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCategory(category) {
            return $http.put('api/categories', category).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteCategory(categoryId) {
            return $http.delete('api/categories/' + categoryId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        //#endregion        
    }
})();
