(function () {
    'use strict';

    angular
        .module('app')
        .component('surveyRow', {
            templateUrl: 'app/catalogs/components/surveyRow/surveyRow.html',
            controller: surveyRow,
            controllerAs: 'vm',
            bindings: {
                survey: '='
            }
        });

    surveyRow.$inject = ['$location'];

    function surveyRow($location) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'surveyRow';

        activate();

        function activate() { }
    }
})();
