(function () {
    'use strict';

    angular
        .module('app.cities')
        .controller('cities', citiesCtrl);

    citiesCtrl.$inject = ['logger', 'cities', 'i18nFilter'];

    function citiesCtrl(logger, cities, i18nFilter) {
        var vm = this;
        vm.cities = cities;

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowSorting: true
            },            
            {
                dataField: 'postalCode',
                caption: i18nFilter('translations.cities_gui_postal_code'),
                alignment: 'left',
                width: 200,
            },
            {
                dataField: 'country.name',
                caption: i18nFilter('translations.general_gui_country'),
                alignment: 'left',
                width: 200,
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 300,
                allowSorting: false,
                cellTemplate: 'citiesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.cities,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion
       
    }
})();
