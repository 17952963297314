(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('urgentMessagesConfigurationDataService', urgentMessagesConfiguration);

    urgentMessagesConfiguration.$inject = ['$http', '$q'];

    function urgentMessagesConfiguration($http, $q) {
        var service = {
            getUrgentMessagesConfiguration: getUrgentMessagesConfiguration,
            editUrgentMessagesConfiguration: editUrgentMessagesConfiguration
        };

        return service;

        function getUrgentMessagesConfiguration() {
            return $http.get('api/urgentMessagesConfiguration').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editUrgentMessagesConfiguration(urgentMessages) {
            return $http.put('api/urgentMessagesConfiguration', urgentMessages).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
