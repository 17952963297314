(function () {
    'use strict';

    angular
        .module('app.releaseHistory')
        .controller('releaseHistory', releaseHistory);

    releaseHistory.$inject = ['$http', '$uibModal', 'releaseHistoryData'];

    function releaseHistory($http, $uibModal, releaseHistoryData) {
        /* jshint validthis:true */
        var vm = this;

        vm.releases = releaseHistoryData;
        vm.openDetails = openDetails;

        function openDetails(release) {
            $uibModal.open({
                templateUrl: 'app/manage/releaseHistory/details/details.html',
                controller: 'details',
                controllerAs: 'vm',
                resolve: {
                    details: function () {
                        return release;
                    }
                }
            });
        }
    }
})();
