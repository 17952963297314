(function () {
    'use strict';

    angular
        .module('app.accessories')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('accessories', {
            parent: 'base',
            url: '/accessories',
            templateUrl: 'app/accessories/accessories.html',
            controller: 'Accessories',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['GlobalAdmin', 'EducationAdmin', 'TenantAdmin']
            }
        });
    }
})();
