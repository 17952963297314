(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('tileGrid', tileGrid);

    tileGrid.$inject = ['logger'];
    
    function tileGrid (logger) {
        // Usage:
        //     <tile-grid></tile-grid>
        // Creates:
        // Alan Jagar
        var directive = {
            link: link,
            templateUrl: 'app/blocks/directives/tileGrid/tileGrid.html',
            restrict: 'E',
            scope: {
                paginationItem: '=',
                data: '=',
                loading: '=',
                pageChangedFn: '&'
            }
        };
        return directive;

        function link(scope, element, attrs) {

            scope.pageChanged = function () {
                scope.pageChangedFn();
            }

            //scope.pageChanged = function(){
            //    logger.log("Page is changed");
            //}
        }
    }

})();