(function () {
    'use strict';

    angular
        .module('app.urgentMessagesConfiguration')
        .controller('UrgentMessagesConfiguration', urgentMessagesConfiguration);

    urgentMessagesConfiguration.$inject = ['logger', 'i18nFilter', 'urgentMessages', 'urgentMessagesConfigurationDataService', 'exceptionHelper', '$state', '$stateParams'];

    function urgentMessagesConfiguration(logger, i18nFilter, urgentMessages, urgentMessagesConfigurationDataService, exceptionHelper, $state, $stateParams) {
        var vm = this;
        vm.urgentMessages = urgentMessages;
        vm.update = update;
        vm.localeMessages = {
            imageUrl: i18nFilter('translations.tiles_configuration_edit_success_message')
        };

        vm.formData = {
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            }
        };

        function update() {
            return urgentMessagesConfigurationDataService.editUrgentMessagesConfiguration(angular.copy(vm.urgentMessages)).then(function (additionalData) {
                logger.success(i18nFilter('translations.urgentMessages_configuration_queue_edit'));
            }, function (error) {
                exceptionHelper.handleException(error, false);
            });
        }
    }
})();
