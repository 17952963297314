(function () {
    'use strict';

    angular
        .module('app.countries')
        .controller('showPersonRecord', showPersonRecordCtrl);

    showPersonRecordCtrl.$inject = ['logger', 'personRecord', 'personRecordsDataService', '$state', 'i18nFilter'];

    function showPersonRecordCtrl(logger, personRecord, personRecordsDataService, $state, i18nFilter) {
        var vm = this;
        vm.item = personRecord;
        vm.titles = vm.item.personRecordTitlesVM;
        vm.showingDetails = $state.current.data.showingDetails;

        var columns = [
            {
                dataField: 'titleType',
                caption: i18nFilter('translations.show_person_record_gui_label_title_type'),
                alignment: 'left',
                width: 200,
                allowFiltering: false
            },
            {
                dataField: 'issueDate',
                caption: i18nFilter('translations.show_person_record_gui_label_issue_date'),
                alignment: 'left',
                width: 200,
                allowFiltering: false
            },
            {
                dataField: 'prolongDate',
                caption: i18nFilter('translations.show_person_record_gui_label_prolong_date'),
                alignment: 'left',
                width: 200,
                allowFiltering: false
            },
            {
                dataField: 'area',
                caption: i18nFilter('translations.show_person_record_gui_label_area'),
                alignment: 'left',
                width: 200,
                allowFiltering: false
            },
            {
                dataField: 'subArea',
                caption: i18nFilter('translations.show_person_record_gui_label_sub_area'),
                alignment: 'left',
                width: 200,
                allowFiltering: true
            },
            {
                dataField: 'publish',
                caption: i18nFilter('translations.show_person_record_gui_label_publish'),
                alignment: 'left',
                width: 200,
                allowFiltering: true
            },
            {
                dataField: 'resolveDate',
                caption: i18nFilter('translations.show_person_record_gui_label_resolve_date'),
                alignment: 'left',
                width: 200,
                allowFiltering: true
            },
            {
                dataField: 'resolveReason',
                caption: i18nFilter('translations.show_person_record_gui_label_resolve_reason'),
                alignment: 'left',
                width: 200,
                allowFiltering: true
            },
            {
                dataField: 'language',
                caption: i18nFilter('translations.show_person_record_gui_label_language'),
                alignment: 'left',
                width: 200,
                allowFiltering: true
            }
        ];

        vm.dataGrid2Options = {
            dataSource: vm.titles,
            columns: columns,
            hoverStateEnabled: true,
            wordWrapEnabled: true
        };

    }
})();
