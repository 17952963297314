(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.video')
        .controller('VideoSecondStep', videoSecondStep);

    videoSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', 'exceptionHelper', 'activitiesDataService', '$stateParams', 'dateHelper', 'i18nFilter', 'urlHelper'];

    function videoSecondStep(logger, activitiesWizardDataService, $state, $scope, exceptionHelper, activitiesDataService, $stateParams, dateHelper, i18nFilter, urlHelper) {
        
        var vm = this;
        vm.item = activitiesWizardDataService.getData();

        vm.isEdit = $state.current.data.isActivityEdit;
        
        vm.next = next;
        vm.previous = previous;
        vm.openPortal = openPortal;

        vm.formData = {
            endDateConfig: {
                type: 'date',
                width: '50%',
                inputAttr: {
                    'name': 'endDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.endDate',
                    min: 'vm.item.startDate'
                }
            },
            startDateConfig: {
                type: 'date',
                width: '50%',
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.startDate',
                    max: 'vm.item.endDate'
                }
            }
        };

        function openPortal() {
            window.open(portalUrl, '_blank');
        }

        function next() {
            if (vm.item.videoUrl && vm.item.videoUrl.indexOf('?') !== -1 && vm.item.videoUrl.indexOf('youtube') !== -1) {
                var host = vm.item.videoUrl.substring(0, vm.item.videoUrl.indexOf('?'));
                var queryParams = urlHelper.parseUrlParams(vm.item.videoUrl.substring(vm.item.videoUrl.indexOf('?') + 1));

                if (host && queryParams && queryParams.v) {
                    vm.item.videoUrl = host + '?v=' + queryParams.v;
                }
            }


            $state.go($scope.navigation[2].state);
        } 

        function end() {
            var item = angular.copy(vm.item);

            item.startDate = dateHelper.convertFromDateToString(item.startDate);
            item.endDate = dateHelper.convertFromDateToString(item.endDate);
            
            
            if (vm.isEdit) {
                activitiesDataService.editActivity(item).then(function (data) {
                    logger.success(i18nFilter("translations.activity_queue_edit"));
                    $state.go("schoolings.administration.activities", $stateParams);
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
            else {
                activitiesDataService.addActivity(item).then(function (data) {
                    logger.success(i18nFilter("translations.activity_queue_add"));
                    $state.go("schoolings.administration.activities", $stateParams);
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
