(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('DeleteCompetenceGroup', deleteGroup);

    deleteGroup.$inject = ['logger', 'competenceGroup', 'competenceGroupsDataService', 'i18nFilter', '$scope'];

    function deleteGroup(logger, competenceGroup, competenceGroupsDataService, i18nFilter, $scope) {
        var vm = this;
        vm.item = competenceGroup;
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            competenceGroupsDataService.deleteCompetenceGroup(vm.item.id).then(function (data) {
                logger.success(i18nFilter('translations.competence_group_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();