(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.lastStep')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider        
        .state('educations.administration.activities.edit.lastStep', {
            url: '/lastStep',
            templateUrl: 'app/educations/administration/activities/edit/lastStep/lastStep.html',
            controller: 'LastStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealEducationOwner'],
                isActivityEdit: true
            },
            resolve: {
                statistic: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                    return activitiesDataService.getEditActivityStatistic($stateParams.activityId);
                }]
            }
        })
        .state('educations.administration.activities.add.lastStep', {
            url: '/lastStep',
            templateUrl: 'app/educations/administration/activities/edit/lastStep/lastStep.html',
            controller: 'LastStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealEducationOwner'],
                isActivityEdit: false
            },
            resolve: {    
                statistic: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                    return activitiesDataService.getAddActivityStatistic($stateParams.id);
                }]
            }
        });
    }
})();