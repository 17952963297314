(function () {
    'use strict';

    angular
        .module('app.programs.documents')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider            
            .state('programs.FSDocuments', {
                url: '/documents/fs',
                templateUrl: 'app/programs/documents/fileSystem/documents.html',
                controller: 'ProgramDocumentsFileSystem',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner', 'RealProgramOwner','CatalogAdmin'],
                    documentsLibrary: true
                }
            });
    }
})();
