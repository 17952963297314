(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('programTile', {
            templateUrl: 'app/catalogs/components/programTile/programTile.html',
            controller: programTile,
            controllerAs: 'vm',
            bindings: {
                program: '='
            }
        });

    programTile.$inject = ['logger', 'numberFilter'];

    function programTile(logger, numberFilter) {
        var vm = this;
        vm.dateTime = new Date().getTime();
        vm.formData = {            
            truncateOptions: {
                watch: 'window',
                wrap: 'letter'
            }
        };        
    }
})();