(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('attendanceListUpload', attendanceList);

    attendanceList.$inject = ['logger', 'i18nFilter', '$scope', 'schedulesDataService', 'exceptionHelper', 'scheduleId'];

    function attendanceList(logger, i18nFilter, $scope, schedulesDataService, exceptionHelper, scheduleId) {
        var vm = this;
        vm.fileId = undefined;


        vm.cancel = cancel;
        vm.ok = ok;


        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '10',
            acceptedFiles: '.docx, .doc, .png, .jpeg, .pdf',
            addRemoveLinks: true,
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.fileId = id;
            },
            'removedfile': function (file) {
                vm.fileId = undefined;
            }
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        }


        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            schedulesDataService.uploadAttendanceList(scheduleId, vm.fileId).then(function (result) {
                logger.success(i18nFilter("translations.schedule_presence_attendance_list_modal_add_to_queue_message"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };

    }
})();
