(function () {
    'use strict';

    angular
        .module('app')
        .controller('certificateHelpEducations', certificateHelp);

    certificateHelp.$inject = ['$scope', 'templateVariables', 'i18nFilter'];

    function certificateHelp($scope, templateVariables) {
        var vm = this;

        vm.templateVariables = templateVariables;
        vm.fillVariables = fillVariables;
        vm.ok = ok;

        function fillVariables() {
            var ul = document.getElementById("unoredered-variable-list");
            vm.templateVariables.forEach(templateVar => {
                var formattedText = "{{" + templateVar.variableName + "}} - " + templateVar.name;
                var node = document.createElement("LI");
                var textnode = document.createTextNode(formattedText);
                node.appendChild(textnode);
                ul.appendChild(node);
            });
        }
        
        function ok() {
            $scope.$dismiss();
        };
    }
})();
