(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .component('lectureSpeakerFiles', {
            templateUrl: 'app/schoolingSchedulePacks/schedulePackLectures/lectureSpeakerFiles/lectureSpeakerFiles.html',
            controller: lectureSpeakerFiles,
            controllerAs: 'vm',
            bindings: {
                lecture: '=',
                isOpen: '='
            }
        });

    lectureSpeakerFiles.$inject = ['logger', 'schoolingsDataService', 'locationsDataService', 'exceptionHelper', 'documentsDataService', 'schoolingSchedulePacksDataService', '$uibModal', '$http', '$q'];

    function lectureSpeakerFiles(logger, schoolingsDataService, locationsDataService, exceptionHelper, documentsDataService, schoolingSchedulePacksDataService, $uibModal, $http, $q) {
        var vm = this;

        vm.formData = {
            expanded: vm.isOpen,
            concatedSpeakers: vm.lecture.speakers.map(s => {
                return s.displayName;
            }).join(', ')
        };

        vm.downloadSpeakerFile = downloadSpeakerFile;
        vm.deleteSpeakerFile = deleteSpeakerFile;
        vm.uploadSpeakerFile = uploadSpeakerFile;
        vm.addSpeakerFile = addSpeakerFile;
        vm.verifySpeakerFile = verifySpeakerFile;

        //#region Method binding
        vm.toggleSchooling = toggleSchooling;
        vm.toggleSendEmailNotification = toggleSendEmailNotification;
        //#endregion

        function addSpeakerFile(isEdit) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/schoolingSchedulePacks/schedulePackLectures/uploadSpeakerFileModal/uploadSpeakerFileModal.html',
                controller: 'UploadSpeakerFileDialog',
                controllerAs: 'modalvm',
                resolve: {
                    parentScope: function () { return vm; },
                    lecture: function () { return vm.lecture; },
                    speakerFile: function () { return null; },
                    isEdit: function () { return isEdit; }
                },
                windowClass: 'uploadSpeakerFileModal',
                backdrop: 'static',
                keyboard: false,
                size: 'md'
            });

            modalInstance.result.then(function (result) {
                vm.lecture.speakerFiles.push(result);
            }, function () {
                // when users clicks cancel button
            });
        }

        function uploadSpeakerFile(speakerFile) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/schoolingSchedulePacks/schedulePackLectures/uploadSpeakerFileModal/uploadSpeakerFileModal.html',
                controller: 'UploadSpeakerFileDialog',
                controllerAs: 'modalvm',
                resolve: {
                    parentScope: function () { return vm; },
                    lecture: function () { return vm.lecture; },
                    speakerFile: function () { return speakerFile; },
                    isEdit: function () { return true; }
                },
                windowClass: 'uploadSpeakerFileModal',
                backdrop: 'static',
                keyboard: false,
                size: 'md'
            });

            modalInstance.result.then(function (result) {
                if (result.isEdit) {
                    let fileIndex = vm.lecture.speakerFiles.map(x => { return x.id }).indexOf(result.id);
                    vm.lecture.speakerFiles[fileIndex] = result;
                }
                else
                    vm.lecture.speakerFiles.push(result);
            }, function () {
                // when users clicks cancel button
            });
        }

        function downloadSpeakerFile(speakerFile) {
            $http.get('api/documents/download/' + speakerFile.fileId,
                {
                    responseType: 'arraybuffer'
                }).success(function (data, status) {

                var ua = new UAParser();
                var browser = ua.getBrowser();

                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                    if (urlCreator) {
                        var blob = new Blob([data], { type: 'application/octet-stream' });

                    if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {
                        window.navigator.msSaveOrOpenBlob(blob, speakerFile.name);
                    }
                    else {
                        var url = urlCreator.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.href = url;
                        a.target = "_blank";
                        a.download = speakerFile.name;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }

                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to download speaker file ' + speakerFile.fileId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteSpeakerFile(speakerFile) {
            schoolingSchedulePacksDataService.deleteLectureSpeakerFile(speakerFile.id)
                .then(res => {
                    vm.lecture.speakerFiles = vm.lecture.speakerFiles.filter(sf => {
                        return sf.id != speakerFile.id;
                    });
                });
        }

        function verifySpeakerFile(isVerified, speakerFile) {
            schoolingSchedulePacksDataService.verifyLectureSpeakerFile(speakerFile.id, isVerified).then(function (res) {
                return speakerFile.isFileVerified = isVerified;
            });
        }

        //#region Toggle
        function toggleSendEmailNotification(e) {
            if(e)
                e.stopPropagation();
            else
                schoolingSchedulePacksDataService.toggleDoNotSendMailToLecturers(vm.lecture.id, vm.lecture.doNotsendEmailNorification).then(function (res) {
                    logger.log('Successfully toggled doNotSendMailOption on lecture: ' + vm.lecture.id);
                });
        }

        function toggleSchooling() {
            vm.formData.expanded = !vm.formData.expanded;
        }
        //#endregion

    }
})();
