(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('themesDataService', themes);

    themes.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function themes($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllThemes: getAllThemes,
            getActiveThemes: getActiveThemes,
            getThemeById: getThemeById,
            addTheme: addTheme,
            editTheme: editTheme
        };

        return service;

        function getAllThemes() {
            return $http.get('api/themes').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveThemes() {
            return $http.get('api/themes/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getThemeById(id) {
            return $http.get('api/themes/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addTheme(theme) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(theme));
            return $http.post('api/themes', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editTheme(theme) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(theme));
            return $http.put('api/themes', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
