(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('azure', azure);

    azure.$inject = ['$http', 'logger', '$q', 'dateHelper', 'i18nFilter', '$sessionStorage'];

    function azure($http, logger, $q, dateHelper, i18nFilter, $sessionStorage) {
        var base = "https://graph.microsoft.com/v1.0";
        var service = {
            getPersonalInfo: getPersonalInfo,
            addEventToCalendar: addEventToCalendar,
            getAllEvents: getAllEvents,
            getAllEventById: getAllEventById,
            editEventToCalendar: editEventToCalendar,
            checkIfUserHasCalendarEnabled: checkIfUserHasCalendarEnabled
        };

        return service;


        function getPersonalInfo() {
            return $http.get(base + '/me').then(function (data) {
                return data.data;
            }, function (data, status, header, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllEvents(userId) {
            return $http.get(base + '/users/' + userId + '/events').then(function (data) {
                return data.data;
            }, function (data, status, header, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllEventById(userId, eventId) {
            return $http.get(base + '/users/' + userId + '/events/' + eventId).then(function (data) {
                return data.data;
            }, function (data, status, header, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addEventToCalendar(userId, startTime, endTime, title, locationName, organizationName, address, meetingId) {            
            var data = buildEvent(startTime, endTime, title, locationName, organizationName, address, meetingId);

            return $http.post(base + '/users/' + userId + '/events', data).then(function (data) {
                return data.data;
            }, function (data, status, header, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editEventToCalendar(eventId, userId, startTime, endTime, title, locationName, organizationName, address) {
            var data = buildEvent(startTime, endTime, title, locationName, organizationName, address);

            return $http.post(base + '/users/' + userId + '/events/' + eventId, data).then(function (data) {
                return data.data;
            }, function (data, status, header, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function checkIfUserHasCalendarEnabled(userId) {
            return $http.get(base + '/users/' + userId + '/calendars').then(function (data) {
                if (data.data.value) {
                    return true;
                }
                return false;
            }, function (data, status, header, config) {
                return false;//if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function buildEvent(startTime, endTime, title, locationName, organizationName, address, meetingId) {

            var tenantTimeZone = $sessionStorage.userInfo.tenantConfig.applicationConfig.defaultTimeZone;


            var text = "";
            if (meetingId) {
                text = '<a href="' + meetingId + '">' + i18nFilter('translations.virtual_classroom_gui_label_connecting_link') + '</a>';
            }
            return {
                body: {
                    content: text,
                    contentType: 'HTML'
                },
                subject: title,
                meetingId: meetingId,
                location: {
                    address: {                        
                        street: address
                    },
                    displayName: organizationName + ' - ' + locationName
                },
                start: {
                    dateTime: dateHelper.convertFromDateToString(startTime),
                    timeZone: tenantTimeZone
                },
                end: {
                    dateTime: dateHelper.convertFromDateToString(endTime),
                    timeZone: tenantTimeZone
                }
            };
        }
    }
})();