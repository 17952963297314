(function () {
    'use strict';

    angular
        .module('app.categories')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('categories', {
            url: '/manage/categories',
            parent: 'base',
            templateUrl: 'app/manage/categories/categories.html',
            controller: 'Categories',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['EducationAdmin']
            },
            resolve: {
                categories: ['categoriesDataService', function (categoriesDataService) {
                    return categoriesDataService.getAllCategories();
                }]
            }
        })
        .state('categories.add', {
            url: '/add',
            templateUrl: 'app/manage/categories/edit.html',
            controller: 'EditCategories',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['EducationAdmin'],
                isEdit: false
            },
            resolve: {
                category: ['categoriesDataService', '$stateParams', function (categoriesDataService, $stateParams) {
                    return undefined;
                }],
                categories: ['categoriesDataService', function (categoriesDataService) {
                    return categoriesDataService.GetCategoriesThatMeetsMaximumDepthLevels();
                }]
            }
        })
        .state('categories.edit', {
            url: '/edit/:id',
            templateUrl: 'app/manage/categories/edit.html',
            controller: 'EditCategories',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['EducationAdmin'],
                isEdit: true
            },
            resolve: {
                category: ['categoriesDataService', '$stateParams', function (categoriesDataService, $stateParams) {
                    return categoriesDataService.getCategoryById($stateParams.id);
                }],
                categories: ['categoriesDataService', function (categoriesDataService) {
                    return categoriesDataService.GetCategoriesThatMeetsMaximumDepthLevels();
                }]
            }
        })
        .state('categories.delete', {
            url: '/delete/:id',
            // trigger the modal to open when this route is active
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/manage/categories/delete.html',
                    controller: 'DeleteCategory',
                    controllerAs: 'vm',
                })
                // change route after modal result
                .result.then(function () {
                    // change route after clicking OK button
                    $state.go('categories');
                }, function () {
                    // change route after clicking Cancel button or clicking background
                    $state.go('categories');
                });
            }],
            requireADLogin: true,
            params:{
                categoryName: undefined
            },
            data: {
                roles: ['EducationAdmin']
            }
        });
    }
})();