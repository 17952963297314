(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .controller('EditProgramThirdStep', thirdStep);

    thirdStep.$inject = ['logger', 'editProgramHelper', 'priceCategories', 'currencies', 'i18nFilter', '$scope', '$state', '$stateParams', 'pricesCategory', 'currencyCategory'];

    function thirdStep(logger, editProgramHelper, priceCategories, currencies, i18nFilter, $scope, $state, $stateParams, pricesCategory, currencyCategory) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;


        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.item = editProgramHelper.getData();
        vm.newPrice = {
            category: undefined,
            amount: undefined,
            currency: undefined
        };

        vm.formData = {
            categories: priceCategories,
            currencies: currencies
        };
        
        if (!vm.isEdit) {
            if (vm.item.prices.length !== 0) {
                angular.forEach(vm.item.prices, function (price, Index) {
                    if (price.category.id === pricesCategory.learnerPrice) {
                        vm.newPrice.amount = price.amount;
                    }
                });
            } else {
                if (vm.formData.categories) {
                    angular.forEach(vm.formData.categories, function (category, Index) {
                        if (category.id === pricesCategory.learnerPrice) {
                            vm.newPrice.category = category;
                        }
                    });
                }

                if (vm.formData.currencies) {
                    angular.forEach(vm.formData.currencies, function (currency, Index) {
                        if (currency.id === currencyCategory.eur) {
                            vm.newPrice.currency = currency;
                        }
                    });
                }
                vm.item.prices.push(vm.newPrice);
            }            
        } else {
            angular.forEach(vm.item.prices, function (price, Index) {
                if (price.category.id === pricesCategory.learnerPrice) {
                    vm.newPrice = price;
                }
            });
        }

        vm.next = next;
        vm.cancel = cancel;
        vm.back = back;
        vm.save = save;


        function next() {
            angular.forEach(vm.item.prices, function (price, Index) {
                if (price.category.id === pricesCategory.learnerPrice) {
                    price.amount = vm.newPrice.amount;
                }
            });
            $state.go($scope.navigation[3].state);
        }

        function save() {
            var item_copy = angular.copy(vm.item);
            editProgramHelper.setData(item_copy);
            editProgramHelper.done(vm.isEdit, true);
        }

        function back() {
            $state.go($scope.navigation[1].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('programs.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.program');
            }
        }
    }
})();
