(function () {
    'use strict';

    angular
        .module('app.schoolings.sidebar.owners')
        .component('schoolingOwners', {
            templateUrl: 'app/schoolings/sidebars/owners/owners.html',
            controller: ownersCtrl,
            controllerAs: 'vm',
            bindings: {
                schoolingId: '='
            }
        });

    ownersCtrl.$inject = ['logger', 'exceptionHelper', 'schoolingsDataService'];

    function ownersCtrl(logger, exceptionHelper, schoolingsDataService) {        
        var vm = this;

        vm.formData = {
            loading: true
        };

        //TODO: logic for showing


        schoolingsDataService.getSchoolingOwners(vm.schoolingId).then(function (data) {
            vm.owners = data.owners;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });       
    }
})();
