(function () {
    'use strict';

    angular
        .module('app.eLearningEducationEnrollments')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('eLearningEducationEnrollments', {
                parent: 'base',
                url: '/eLearningEducationEnrollments/:id?:pageIndex',
                templateUrl: 'app/eLearningEducationEnrollments/eLearningEducationEnrollments.html',
                controller: 'ELearningEducationEnrollments',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin']
                },
                resolve: {
                    educations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getOnlineEducations();
                    }],
                    selectedEducation: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        if (!$stateParams.id) {
                            return null;
                        }
                        return educationsDataService.getEducationById($stateParams.id);
                    }]
                }
            });
    }
})();
