(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackQuestionsAndPayments', examSchedulePackQuestionsAndPayments);
    
    examSchedulePackQuestionsAndPayments.$inject = ['questions', 'payments', 'examSchedulesDataService', 'examApplicationsDataService', '$http', '$scope', '$state', '$stateParams', '$uibModal', 'i18nFilter', 'dateHelper', 'dateFilter', 'exceptionHelper', 'logger', 'examNameAndScheduleCode'];

    function examSchedulePackQuestionsAndPayments(questions, payments, examSchedulesDataService, examApplicationsDataService, $http, $scope, $state, $stateParams, $uibModal, i18nFilter, dateHelper, dateFilter, exceptionHelper, logger, examNameAndScheduleCode) {
        /* jshint validthis:true */
        var vm = this;

        vm.item = {
            questions: questions,
            payments: payments
        };

        vm.nameAndCode = examNameAndScheduleCode.examCode + "-" + examNameAndScheduleCode.code;

        vm.confirmationTypes = [
            {
                id: false,
                format: i18nFilter('translations.general_gui_no')
            },
            {
                id: true,
                format: i18nFilter('translations.general_gui_yes')
            }
        ];

        vm.formData = {}
        vm.localeMessages = {
            questions: i18nFilter('translations.exam_questions_and_payments_gui_questions_subtitle'),
            payments: i18nFilter('translations.exam_questions_and_payments_gui_payments_subtitle'),
            downloadTooltip: i18nFilter('translations.exam_questions_and_payments_action_download_payment_proof'),
            toggleTooltip: i18nFilter('translations.exam_questions_and_payments_action_toggle_payment_confirmation'),
            viewQuestionsTooltip: i18nFilter('translations.exam_questions_and_payments_action_view_questions')

        }

        vm.item.questions.forEach(function (question) {
            if (question.questionId == null) {
                question.questionStatus = "0/1";
            } else {
                question.questionStatus = "1/1";
            }
        });

        vm.downloadPaymentProof = downloadPaymentProof;
        vm.togglePaymentConfirmationStatus = togglePaymentConfirmationStatus;
        vm.viewCommisionMemberQuestions = viewCommisionMemberQuestions;

        vm.notifyCommissionMembers = notifyCommissionMembers;
        vm.notifyCandidates = notifyCandidates;

        function downloadPaymentProof(obj) {
            if (!obj.prepaymentProof || !obj.prepaymentProof.id)
                return;

            var filename = obj.prepaymentProof.name;

            return $http.get("api/documents/download/" + obj.prepaymentProof.id, {
                responseType: 'arraybuffer'
            }).success(function (data, status, headers) {
                var ua = new UAParser();
                var browser = ua.getBrowser();

                var contentType = headers["content-type"] || "application/octet-stream";
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    var blob = new Blob([data], { type: contentType });

                    if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    }
                    else {
                        var url = urlCreator.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.target = "_blank";
                        a.download = filename; //you may assign this value from header as well 
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
            });
        }

        function togglePaymentConfirmationStatus(obj) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/questionsAndPayments/togglePaymentConfirmation/togglePaymentConfirmation.html',
                controller: 'togglePaymentConfirmation',
                controllerAs: 'vm'
            })
            .result.then(function (result) {
                // after clicking OK button
                
                examApplicationsDataService.togglePaymentConfirmation(obj.id).then(function (data) {
                    logger.success(i18nFilter("translations.exam_questions_and_payments_queue_toggle_payment_confirmation"));
                    $scope.$close(true);
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }

        function viewCommisionMemberQuestions(obj) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/questionsAndPayments/viewCommisionMemberThemeQuestions/viewCommisionMemberThemeQuestions.html',
                controller: 'viewCommisionMemberThemeQuestions',
                controllerAs: 'vm',
                resolve: {
                    question: function () {
                        return obj;
                    }
                }
            })
                .result.then(function (result) {
                    // after clicking OK button

                    
                }, function () {
                    // after clicking Cancel button or clicking background

                });
        }

        function notifyCommissionMembers() {
            vm.disableNotifyCommissionMembers = true;
            examSchedulesDataService.notifyCommissionMembers($stateParams.schedulePackId).then(function (data) {
                logger.success(i18nFilter("translations.exam_questions_and_payments_queue_notifyCommissionMembers"));
                vm.disableNotifyCommissionMembers = false;
            }, function (error) {
                vm.disableNotifyCommissionMembers = false;
                exceptionHelper.handleException(error);
            });
        }

        function notifyCandidates() {
            vm.disableNotifyCandidates = true;
            examApplicationsDataService.notifyCandidates($stateParams.schedulePackId).then(function (data) {
                logger.success(i18nFilter("translations.exam_questions_and_payments_queue_notifyCandidates"));
                vm.disableNotifyCandidates = false;
            }, function (error) {
                vm.disableNotifyCandidates = false;
                exceptionHelper.handleException(error);
            });
        }

        var questionsColumns = [
            {
                dataField: 'commissionMemberName',
                caption: i18nFilter('translations.exam_arrangements_gui_label_commission_member_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'examActivityName',
                caption: i18nFilter('translations.exams_edit_gui_label_activity_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'examScheduleDate',
                dataType: "date",
                alignment: 'left',
                allowFiltering: true,
                allowSorting: true,
                sortOrder: 'desc',
                caption: i18nFilter('translations.exam_arrangements_gui_label_StartDate'),
                cssClass: 'table-column-space-format wrapped-column',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate'); //dd.MM.yyyy
                }
            },
            {
                dataField: 'examThemeName',
                caption: i18nFilter('translations.exam_arrangements_gui_label_commission_member_themes'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'questionStatus',
                caption: i18nFilter('translations.exam_questions_and_payments_gui_label_question_status'),
                alignment: 'left',
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 150,
                allowSorting: false,
                cellTemplate: 'paymentsActionTemplate'
            }
        ];

        var paymentsColumns = [
            {
                dataField: 'userDisplayName',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'userEmail',
                caption: i18nFilter('translations.general_gui_email'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'price',
                caption: i18nFilter('translations.exams_edit_gui_label_price'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'paymentConfirmation',
                caption: i18nFilter('translations.exam_questions_and_payments_gui_label_is_exam_payed'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 150,
                lookup: {
                    dataSource: vm.confirmationTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 150,
                allowSorting: false,
                cellTemplate: 'paymentsActionTemplate'
            }
        ];

        vm.questionsDataGridOptions = {
            dataSource: vm.item.questions,
            columns: questionsColumns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        vm.paymentsDataGridOptions = {
            dataSource: vm.item.payments,
            columns: paymentsColumns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
