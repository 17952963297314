(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('tilesConfigurationDataService', tilesConfiguration);

    tilesConfiguration.$inject = ['$http', '$q'];

    function tilesConfiguration($http, $q) {
        var service = {
            getTilesConfiguration: getTilesConfiguration,
            editTilesConfiguration: editTilesConfiguration
        };

        return service;

        function getTilesConfiguration() {
            return $http.get('api/tilesConfiguration').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editTilesConfiguration(tiles) {
            return $http.put('api/tilesConfiguration', tiles).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
