(function () {
    'use strict';

    angular
        .module('app.competences')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('competences', {
                parent: 'base',
                url: '/manage/competences',
                templateUrl: 'app/manage/competences/competences.html',
                controller: 'competences',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    competences: ['competenceDataService', function (competenceDataService) {
                        return competenceDataService.getCompetences();
                    }],
                    competencesGroups: ['competenceGroupsDataService', function (competenceGroupsDataService) {
                        return competenceGroupsDataService.getCompetencesGroups();
                    }],
                    competencesLevelsTemplate: ['competenceLevelsTemplateDataService', function (competenceLevelsTemplateDataService) {
                        return competenceLevelsTemplateDataService.getCompetenceLevelsTemplate();
                    }]
                },
                data: {
                    roles: ['GlobalAdmin', 'EducationAdmin']
                }
            });
    }
})();