(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .factory('programSchedulePackHelper', programSchedulePackHelper);

    programSchedulePackHelper.$inject = ['$http'];

    function programSchedulePackHelper($http) {
        var instance_map = {};

        var service = {
            clear: clear,
            registerDirective: registerDirective,
            getApisForKey: getApisForKey,
            deregisterDirective: deregisterDirective,
            scheduleExpanded: false
        };

        return service;

        function clear() {
            instance_map = {};
            service.scheduleExpanded = false;
        }

        function registerDirective(key, api) {
            if (instance_map[key] && instance_map[key].length !== 0) {
                instance_map[key].push(api);
            }
            else {
                instance_map[key] = [];
                instance_map[key].push(api);
            }
        }

        function getApisForKey(key) {
            return instance_map[key];
        }

        function deregisterDirective(key, id) {
            if (instance_map[key] && instance_map[key].length !== 0) {
                instance_map[key] = _.filter(instance_map[key], function (api) { api.id !== id });
            }
        }        
    }
})();
