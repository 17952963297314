(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('copyProgram', copyProgram);

    copyProgram.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'schoolingSchedulePacksDataService', 'pastSchedules','roles'];

    function copyProgram(logger, $stateParams, $scope, i18nFilter, exceptionHelper, schoolingSchedulePacksDataService, pastSchedules,roles) {
        var vm = this;

        vm.pastSchedules = pastSchedules;
        vm.roles = roles;
        vm.ok = ok;
        vm.cancel = cancel;

        async function ok() {
            let program = await schoolingSchedulePacksDataService.getSchoolingScheduleProgramByScheduleId(vm.pastSchedule.id);
            // set to current schedule id
            program.scheduleId = $stateParams.schoolingScheduleId;

            // delete section, lecture and speaker ids, so that they are properly inserted when saving
            program.sections.forEach(function (section) {
                delete section.id;
                section.lectures.forEach(function (lecture) {
                    delete lecture.id;
                    lecture.speakers.forEach(function (speaker) {
                        delete speaker.id;
                        // map role
                        speaker.role = vm.roles[speaker.role];
                    });
                });

                section.moderators.forEach(function (moderator) {
                    delete moderator.id;
                });
            });
            $scope.$close(program);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
