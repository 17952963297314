(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('schedulePacksDataService', schedulePacksDataService);

    schedulePacksDataService.$inject = ['$http', 'logger', 'locale', '$q', 'dateHelper', 'decimalHelper', 'eFormTypes'];

    function schedulePacksDataService($http, logger, locale, $q, dateHelper, decimalHelper, eFormTypes) {
        var service = {
            getAllSchedulePacksAdvanced: getAllSchedulePacksAdvanced,                     
            getEmptySchedulePackForProgram: getEmptySchedulePackForProgram,
            getEmptySchedulePackForEducation: getEmptySchedulePackForEducation,
            getSchedulePackById: getSchedulePackById,
            addSchedulePack: addSchedulePack,
            editSchedulePack: editSchedulePack,
            sendMailToLearners: sendMailToLearners,
            cancelSchedulePack: cancelSchedulePack,
            findOrCreateUser: findOrCreateUser,
            replaceEFormLearnerOnSchedulePack: replaceEFormLearnerOnSchedulePack,
            programAndEducationOwners: programAndEducationOwners,
            sendSurvey: sendSurvey,
            sendReport: sendReport,
            lockSurvey: lockSurvey,
            participantLockedSurvey: false,
            contractorLockedSurvey: false,
            coordinatorLockedSurvey: false,
        };

        return service;

        function getAllSchedulePacksAdvanced(filter) {
            filter.startTime.searchText1 = dateHelper.convertFromDateToString(filter.startTime.searchText1);
            filter.startTime.searchText2 = dateHelper.convertFromDateToString(filter.startTime.searchText2);

            filter.endTime.searchText1 = dateHelper.convertFromDateToString(filter.endTime.searchText1);
            filter.endTime.searchText2 = dateHelper.convertFromDateToString(filter.endTime.searchText2);

            return $http.post('api/schedulePacks/filter', filter)
                .then(function (data) {
                    angular.forEach(data.data.data, function (element, index) {
                        element.startTime = dateHelper.convertFromStringToDate(element.startTime);
                        element.endTime = dateHelper.convertFromStringToDate(element.endTime);
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getSchedulePackById(schedulePackId) {
            return $http.get('api/schedulePacks/' + schedulePackId).then(function (data) {
                _.each(data.data.schedules, function (schedule, index) {
                    schedule.startTime = dateHelper.convertFromStringToDate(schedule.startTime);
                    schedule.endTime = dateHelper.convertFromStringToDate(schedule.endTime);
                    if (schedule.instructors && schedule.instructors.length !== 0) {
                        _.each(schedule.instructors, function (scheduleInstructor) {
                            scheduleInstructor.duration = scheduleInstructor.duration + "";
                        });
                    }
                });
                if (data.data.price) {
                    data.data.price.amount = data.data.price.amount + "";
                }
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSchedulePack(schedulePack) {
            angular.forEach(schedulePack.schedules, function (schedule, index) {
                schedule.startTime = dateHelper.convertFromDateToString(schedule.startTime);
                schedule.endTime = dateHelper.convertFromDateToString(schedule.endTime);
            });

            return $http.post('api/schedulePacks', schedulePack).then(function (data) {                
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editSchedulePack(schedulePack) {
            angular.forEach(schedulePack.schedules, function (schedule, index) {
                schedule.startTime = dateHelper.convertFromDateToString(schedule.startTime);
                schedule.endTime = dateHelper.convertFromDateToString(schedule.endTime);
            });

            return $http.put('api/schedulePacks', schedulePack).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function cancelSchedulePack(schedulePack) {
            return $http.post('api/schedulePacks/cancel', schedulePack).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function replaceEFormLearnerOnSchedulePack(replaceData) {
            return $http.post('api/eForms/replaceLearner/' + replaceData.eFormId, replaceData).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to replace eForm learner.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEmptySchedulePackForProgram(programId) {
            return $http.get('api/schedulePacks/empty/programs/' + programId).then(function (data) {
                _.each(data.data.schedules, function (schedule) {
                    schedule.startTime = undefined;
                    schedule.endTime = undefined;
                })
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEmptySchedulePackForEducation(educationId) {
            return $http.get('api/schedulePacks/empty/educations/' + educationId).then(function (data) {
                _.each(data.data.schedules, function (schedule) {
                    schedule.startTime = undefined;
                    schedule.endTime = undefined;
                })
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function findOrCreateUser(eformType, entityId, searchUser) {
            let url = 'api/eForms/';
            url += (eformType == eFormTypes.education ? 'educations/' : 'programs/')
            url += (entityId + '/addUser');

            return $http.post(url, searchUser).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendMailToLearners(schedulePackId) {
            return $http.get('api/schedulePacks/' + schedulePackId + '/mail').then(function (data) {                
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function programAndEducationOwners(entityId, type) {
            return $http.get('api/schedulePacks/owners/' + entityId + '/' + type).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.owners, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendSurvey(sendSurveyVm) {
            return $http.post('api/schedulePacks/sendSurvey', sendSurveyVm).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to send survey.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendReport(sendSurveyVm) {
            return $http.get('api/schedulePacks/surveyReport').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to send survey.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function lockSurvey(lockSurveyEducationVm) {
            return $http.post('api/schedulePacks/lockSurveys', lockSurveyEducationVm).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to lock survey.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
