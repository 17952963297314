(function () {
    'use strict';

    angular
        .module('app.countries')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('countries', {
                parent: 'base',
                url: '/manage/countries',
                templateUrl: 'app/manage/countries/countries.html',
                controller: 'countries',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    countries: ['countriesDataService', function (countriesDataService) {
                        return countriesDataService.getCountries();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('countries.delete', {
                url: '/delete/:id',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/countries/delete/deleteCountry.html',
                        controller: 'deleteCountry',
                        controllerAs: 'vm'
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('countries');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('countries');
                        });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('countries.edit', {
                url: '/edit/:id',
                templateUrl: 'app/manage/countries/edit/editCountry.html',
                controller: 'editCountry',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    country: ['countriesDataService', '$stateParams', function (countriesDataService, $stateParams) {
                        return countriesDataService.getCountryById($stateParams.id);
                    }]                    
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('countries.add', {
                url: '/add',
                templateUrl: 'app/manage/countries/edit/editCountry.html',
                controller: 'editCountry',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    country: ['countriesDataService', '$stateParams', function (countriesDataService, $stateParams) {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            ;
    }
})();
