(function () {
    'use strict';

    angular
        .module('app.exams.scholarships')
        .controller('EditScholarship', editScholarship);

    editScholarship.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'examType', 'scholarship', 'scholarshipsDataService', 'usersDataService', 'exceptionHelper', 'logger'];

    function editScholarship(decimalHelper, dateHelper, $state, i18nFilter, examType, scholarship, scholarshipsDataService, usersDataService, exceptionHelper, logger) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = scholarship;
        vm.isEdit = $state.current.data.isEdit;
        vm.localeMessages = {
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select")
        }
        
        vm.formData = {
            users: [],
            dateOfReceivedContractConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'dateOfReceivedContract'
                },
                bindingOptions: {
                    value: 'vm.item.dateOfReceivedContract'
                }
            },
            requestForPaymentConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'requestForPayment'
                },
                bindingOptions: {
                    value: 'vm.item.requestForPayment'
                }
            },
            dateOfPaymentConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'dateOfPayment'
                },
                bindingOptions: {
                    value: 'vm.item.dateOfPayment'
                }
            },
            dateOfTheSignedContractConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'dateOfTheSignedContract'
                },
                bindingOptions: {
                    value: 'vm.item.dateOfTheSignedContract'
                }
            },
            expiryDateOfTheContractConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'expiryDateOfTheContract'
                },
                bindingOptions: {
                    value: 'vm.item.expiryDateOfTheContract'
                }
            },
            dateOfSubmittedDocumentsConfig: {
                type: 'date',
                width: '80%',
                inputAttr: {
                    'name': 'dateOfSubmittedDocuments'
                },
                bindingOptions: {
                    value: 'vm.item.dateOfSubmittedDocuments'
                }
            }
        }

        vm.add = add;
        vm.save = save;
        vm.refreshUsers = refreshUsers;
        
        function add() {
            scholarshipsDataService.addScholarship(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_scholarships_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            scholarshipsDataService.editScholarship(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_scholarships_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }
    }
})();
