(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schedulePacks', {
                parent: 'base',
                url: '/schedulePacks/:entityId',
                templateUrl: 'app/schedulePacks/schedulePacks.html',
                controller: 'schedulePacks',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    educations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getAllActiveEducationsWithSynchronousActivities();
                    }],
                    programs: ['programsDataService', function (programsDataService) {
                        return programsDataService.getProgramsForSchedulePacks();
                    }]
                },
                data: {                    
                    roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin', 'Instructor']
                }
            })
            .state('schedulePacks.educationSchedulePackAdd',{
                url: '/add/education?educationId',
                templateUrl: 'app/schedulePacks/editSchedulePack/editEducationSchedulePack/editEducationSchedulePack.html',
                controller: 'EditEducationSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getEmptySchedulePackForEducation($stateParams.educationId);
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }],
                    instructors: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEducationInstructors($stateParams.educationId);
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    education: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEducationById($stateParams.educationId);
                    }],
                    coordinators: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEducationOwners($stateParams.educationId);
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin', 'EducationOwner']
                }
            })
            .state('schedulePacks.educationSchedulePackEdit', {
                url: '/:schedulePackId/edit/education?educationId',
                templateUrl: 'app/schedulePacks/editSchedulePack/editEducationSchedulePack/editEducationSchedulePack.html',
                controller: 'EditEducationSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,                
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getSchedulePackById($stateParams.schedulePackId);
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }],
                    instructors: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEducationInstructors($stateParams.educationId);
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    education: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEducationById($stateParams.educationId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin', 'EducationOwner']
                }
            })
            .state('schedulePacks.programSchedulePackAdd', {
                url: '/add/program?programId',
                templateUrl: 'app/schedulePacks/editSchedulePack/editProgramSchedulePack/editProgramSchedulePack.html',
                controller: 'EditProgramSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getEmptySchedulePackForProgram($stateParams.programId);
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin', 'EducationOwner']
                }
            })
            .state('schedulePacks.programSchedulePackEdit', {
                url: '/:schedulePackId/edit/program?programId',
                templateUrl: 'app/schedulePacks/editSchedulePack/editProgramSchedulePack/editProgramSchedulePack.html',
                controller: 'EditProgramSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getSchedulePackById($stateParams.schedulePackId);                        
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin', 'EducationOwner']
                }
            })
            .state('schedulePacks.details', {
                url: '/details?schedulePackId&scheduleId',
                templateUrl: 'app/schedulePacks/details/details.html',
                controller: 'schedulePackDetails',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin', 'Instructor']
                },
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getSchedulePackById($stateParams.schedulePackId);
                    }],
                    isUserInstructorInAnySchedule: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isUserInstructorInSchedulePack($stateParams.schedulePackId);
                    }],
                    isUserOwnerOfEntityInsideSchedulePack: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isOwnerOfEntityInsideSchedulePack($stateParams.schedulePackId);
                    }]
                }
            })
            .state('schedulePacks.records', {
                url: '/records?schedulePackId&scheduleId',
                templateUrl: 'app/schedulePacks/records/records.html',
                controller: 'SchedulePackRecords',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin', 'Instructor']
                },
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getSchedulePackById($stateParams.schedulePackId);
                    }],
                    isUserInstructorInAnySchedule: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isUserInstructorInSchedulePack($stateParams.schedulePackId);
                    }],
                    isUserOwnerOfEntityInsideSchedulePack: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isOwnerOfEntityInsideSchedulePack($stateParams.schedulePackId);
                    }]
                }
            })
            .state('schedulePacks.approvals', {
                url: '/approvals?schedulePackId',
                templateUrl: 'app/schedulePacks/approvals/approvals.html',
                controller: 'schedulePackApprovalsController',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin', 'Instructor']
                },
                resolve: {
                    schedulePack: ['schedulePacksDataService', '$stateParams', function (schedulePacksDataService, $stateParams) {
                        return schedulePacksDataService.getSchedulePackById($stateParams.schedulePackId);
                    }],
                    isUserInstructorInAnySchedule: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isUserInstructorInSchedulePack($stateParams.schedulePackId);
                    }],
                    isUserOwnerOfEntityInsideSchedulePack: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isOwnerOfEntityInsideSchedulePack($stateParams.schedulePackId);
                    }],
                }
            });
    }
})();
