(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.firstStep')
        .controller('FirstStep', firstStep);

    firstStep.$inject = ['logger', '$state', '$scope', 'activitiesWizardDataService', '$stateParams', 'activityTypes', 'i18nFilter', 'activitiesDataService'];

    function firstStep(logger, $state, $scope, activitiesWizardDataService, $stateParams, activityTypes, i18nFilter, activitiesDataService) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.item.schoolingId = $stateParams.id;

        vm.isEdit = $state.current.data.isActivityEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        
        vm.formData = {
            activityTypes: [
                {
                    id: activityTypes.genericAcitvityType,                    
                    name: i18nFilter('translations.filter_schooling_activity_type_generic')
                },
                {
                    id: activityTypes.surveyAcitvityType,
                    name: i18nFilter('translations.filter_schooling_activity_type_survey')
                },
                {
                    id: activityTypes.videoActivityType,
                    name: i18nFilter('translations.filter_schooling_activity_type_video')
                },
                {
                    id: activityTypes.classroomActivityType,
                    name: i18nFilter('translations.filter_schooling_activity_type_classroom')
                },
                {
                    id: activityTypes.html5ActivityType,
                    name: i18nFilter('translations.filter_schooling_activity_type_html5')
                },
                {
                    id: activityTypes.eExamActivityType,
                    name: i18nFilter('translations.filter_schooling_activity_type_eExam')
                }
            ],            
            tinymceOptions: {
                plugins: 'advlist autolink lists image link imagetools charmap hr anchor searchreplace wordcount insertdatetime code nonbreaking emoticons paste textcolor colorpicker textpattern',
                toolbar1: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            }
        };

        if (vm.isEdit) {
            angular.forEach(vm.formData.activityTypes, function (type, index) {
                if (type.id === vm.item.activityType.id) {
                    vm.item.activityType = type;
                }
            });
        }

        //Definiranje metoda
        vm.next = next;
        vm.typeChanged = typeChanged;
        vm.estDurationValidation = estDurationValidation;
        vm.formData.activityTypes = vm.formData.activityTypes;

        function estDurationValidation() {

            var months = vm.item.month === undefined ? 0 : parseInt(vm.item.month);
            var days = vm.item.day === undefined ? 0 : parseInt(vm.item.day);
            var hours = vm.item.hour === undefined ? 0 : parseInt(vm.item.hour);
            var minutes = vm.item.minute === undefined ? 0 : parseInt(vm.item.minute);

            var sum = months + days + hours + minutes;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function next() {            
            $state.go($scope.navigation[1].state);
        }

        function typeChanged() {
            activitiesWizardDataService.getWizardSteps(vm.item.activityType.id, vm.isEdit); //Automatically refresh navigation steps on form       
        }
        
    }
})();
