(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('deleteSchoolingDocumentModal', deleteSchoolingDocumentModal);

    deleteSchoolingDocumentModal.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteSchoolingDocumentModal(logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(true);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
