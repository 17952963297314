(function () {
    'use strict';
    angular
        .module('app.catalogs')
        .controller('WorkProgramDialog', WorkProgramDialog);

    WorkProgramDialog.$inject = ['logger', 'i18nFilter', '$scope', 'years', 'documentsDataService', 'exceptionHelper'];
    
    function WorkProgramDialog(logger, i18nFilter, $scope, years, documentsDataService, exceptionHelper) {
        var modalvm = this;
        modalvm.cancel = cancel;
        modalvm.confirm = confirm;
        modalvm.generateWorkProgramDocument = generateWorkProgramDocument;

        modalvm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
        }

        modalvm.formData = {
            year: undefined,
            years: years
        }
        
        function confirm() {
            if (modalvm.year == "" || modalvm.year == undefined) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }

            generateWorkProgramDocument(modalvm.year);
        }

        function generateWorkProgramDocument(year) {
            documentsDataService.downloadDocument('/api/schoolings/' + year + '/workProgramDocument', i18nFilter('translations.schoolings_documents_label_for_workProgram') + year + '.docx').then(function (data) {
                GenerateWorkProgramDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();


