(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('schoolingTile', {
            templateUrl: 'app/catalogs/components/schoolingTile/schoolingTile.html',
            controller: schoolingTile,
            controllerAs: 'vm',
            bindings: {
                schooling: '='
            }
        });

    schoolingTile.$inject = ['logger', 'learningPlanItemStatus', 'numberFilter'];

    function schoolingTile(logger, learningPlanItemStatus, numberFilter) {
        var vm = this;

        vm.formData = {
            learningPlanItemStatus: learningPlanItemStatus,
            learnerStatusStr: undefined,
            learnerStatus: {
                late: 1,
                finished: 2,
                enrolled: 3,
                neutral: 4
            },
            truncateOptions: {
                watch: 'window',
                wrap: 'letter'
            }
        };

        vm.getLearnerStatus = getLearnerStatus;
        vm.getLearnerPercentage = getLearnerPercentage;
        vm.dateTime = new Date().getTime();

        function getLearnerStatus() {
            if (vm.schooling.learningPlanItemStatus === undefined || vm.schooling.learningPlanItemStatus === null) {
                //Nije upisan u edukaciju                
                return vm.formData.learnerStatus.neutral;
            }
            else if (vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.finished) {
                //Završio je edukaciju
                return vm.formData.learnerStatus.finished;
            }
            else if (!vm.schooling.learningPlanItemDeadline || vm.schooling.learningPlanItemDeadline.getTime() >= new Date().getTime()) {
                //Samostalno je upisan ili administrativno i ne kasni
                return vm.formData.learnerStatus.enrolled;
            }
            else if (vm.schooling.learningPlanItemDeadline.getTime() < new Date().getTime()) {
                //Administrativno upisan
                return vm.formData.learnerStatus.late;
            }

            //Do ovdje ne bi trebao nikada doći
            logger.log("Can't calculate learner status", vm.schooling);
            return vm.formData.learnerStatus.neutral;
        }

        function getLearnerPercentage() {
            if
            (
                (vm.schooling.learningPlanItemStatus !== undefined
                    ||
                    vm.schooling.learningPlanItemStatus !== null
                )
                &&
                (vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.started
                    ||
                    vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.notStarted
                )
            ) {
                var numberOfFinishedLearningActivities = vm.schooling.numberOfFinishedLearningActivities;
                var numberOfLearningActivities = vm.schooling.numberOfLearningActivities;

                if (numberOfLearningActivities === 0) {
                    return "0%";
                }
                else if (numberOfFinishedLearningActivities === 0) {
                    return "0%";
                }
                else {
                    var calculation = numberOfFinishedLearningActivities / numberOfLearningActivities;

                    return (calculation * 100) + "%";
                }
            }
            else if
            (
                (vm.schooling.learningPlanItemStatus !== undefined || vm.schooling.learningPlanItemStatus !== null)
                    &&
                (vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.finished)
            ) {
                return "100%";
            }
            return "0%";
        }

    }
})();
