(function () {
    'use strict';

    angular
        .module('app.schoolings.documents')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider            
            .state('schoolings.FSDocuments', {
                url: '/documents/fs',
                templateUrl: 'app/schoolings/documents/fileSystem/documents.html',
                controller: 'SchoolingDocumentsFileSystem',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    documentsLibrary: true,
                    forSchedule: false
                }
            })
            .state('schoolings.scheduleFSDocuments', {
                url: '/schedules/:scheduleId/documents/fs',
                templateUrl: 'app/schoolings/documents/fileSystem/documents.html',
                controller: 'SchoolingDocumentsFileSystem',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    documentsLibrary: true,
                    forSchedule: true
                }
            });
    }
})();
