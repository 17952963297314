(function () {
    'use strict';
    angular
        .module('app.schoolingSchedulePacks')
        .controller('LinkContractModal', LinkContractModal);

    LinkContractModal.$inject = ['$uibModalInstance', '$stateParams', 'logger', 'contractsDataService', 'exceptionHelper', 'i18nFilter', '$scope', 'allContracts', 'employeeId'];

    function LinkContractModal($uibModalInstance, $stateParams, logger, contractsDataService, exceptionHelper, i18nFilter, $scope, allContracts, employeeId) {
        var vm = this;

        vm.cancel = cancel;
        vm.link = link;

        vm.allContracts = allContracts;

        function link() {
            logger.log("Link contract: ", vm.chosenContract);

            var scheduleId = $stateParams.schoolingScheduleId;
            var itemCopy = angular.copy(vm.chosenContract);

            contractsDataService.addSchoolingScheduleContractLink(itemCopy.id, employeeId, scheduleId).then(function (data) {
                logger.success(i18nFilter('translations.contract_queue_add'));
                $uibModalInstance.close();
            }, function (error) {
                exceptionHelper.handleException(error);
                $uibModalInstance.$dismiss();
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
       
    }
})();


