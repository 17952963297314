(function () {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('tileGridDocuments', tileGridDocuments);

    tileGridDocuments.$inject = ['logger'];

    function tileGridDocuments(logger) {
        // Usage:
        //     <tile-grid-documents></tile-grid-documents>
        // Creates:
        // Bojan Došen
        var directive = {
            link: link,
            templateUrl: 'app/blocks/directives/tileGridDocuments/tileGridDocuments.html',
            restrict: 'E',
            scope: {
                paginationItem: '=',
                data: '=',
                loading: '=',
                pageChanged: '=',
                hasPagination: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {
            
            //scope.pageChanged = function(){
            //    logger.log("Page is changed");
            //}
        }
    }

})();