(function () {
    'use strict';

    angular
        .module('app.cities')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('cities', {
                parent: 'base',
                url: '/manage/cities',
                templateUrl: 'app/manage/cities/cities.html',
                controller: 'cities',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    cities: ['citiesDataService', function (citiesDataService) {
                        return citiesDataService.getCities();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('cities.delete', {
                url: '/delete/:id',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/cities/delete/deleteCity.html',
                        controller: 'deleteCity',
                        controllerAs: 'vm'
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('cities');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('cities');
                        });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('cities.edit', {
                url: '/edit/:id',
                templateUrl: 'app/manage/cities/edit/editCity.html',
                controller: 'editCity',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    city: ['citiesDataService', '$stateParams', function (citiesDataService, $stateParams) {
                        return citiesDataService.getCityById($stateParams.id);
                    }],
                    countries: ['countriesDataService', function (countriesDataService) {
                        return countriesDataService.getActiveCountries();
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('cities.add', {
                url: '/add',
                templateUrl: 'app/manage/cities/edit/editCity.html',
                controller: 'editCity',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    city: ['citiesDataService', '$stateParams', function (citiesDataService, $stateParams) {
                        return {};
                    }],
                    countries: ['countriesDataService', function (countriesDataService) {
                        return countriesDataService.getActiveCountries();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            ;
    }
})();
