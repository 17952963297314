(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('learningPlanItemSuccess', learningPlanItemSuccessFilter);

    learningPlanItemSuccessFilter.$inject = ['logger', 'learningPlanItemSuccess', 'i18nFilter'];

    function learningPlanItemSuccessFilter(logger, learningPlanItemSuccess, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value == learningPlanItemSuccess.unknown){
                return i18nFilter('translations.general_gui_success_unknown');
            }
            else if (value == learningPlanItemSuccess.success){
                return i18nFilter('translations.general_gui_success_pass');
            }
            else if (value == learningPlanItemSuccess.fail) {
                return i18nFilter('translations.general_gui_success_fail');
            }
        };
    }
})();