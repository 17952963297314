(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('SecondStepUser', secondStepUser);

    secondStepUser.$inject = ['logger', 'user', 'userEntityRoles', 'editUserHelper', 'gender', 'employmentStatus', 'organizationsDataService', 'rolesDataService', 'i18nFilter', '$scope', 'usersDataService', 'exceptionHelper', 'citiesDataService', '$state', 'eFormTypeEntity', 'entityRoles', 'applicationRoles', '$sessionStorage', 'authProviders', '$uibModal'];

    function secondStepUser(logger, user, userEntityRoles, editUserHelper, gender, employmentStatus, organizationsDataService, rolesDataService, i18nFilter, $scope, usersDataService, exceptionHelper, citiesDataService, $state, eFormTypeEntity, entityRoles, applicationRoles, $sessionStorage, authProviders, $uibModal) {
        var vm = this;

        vm.isEdit = $state.current.data.isEdit;
        vm.item = user;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
            asyncSelectPlaceholder: i18nFilter('translations.general_gui_async_ddl_placeholder_select')
        };

        vm.formData = {
            gender: gender,
            organizations: [],
            tenantIdentityProviderIsADFS: $sessionStorage.userInfo.tenantConfig.authenticationAndAuthorization.identityProvider === authProviders.activeDirectoryFS,
            userIsGlobalAdmin: rolesDataService.isUserInRoles(['GlobalAdmin']),
            employmentStatus: employmentStatus,
            dateToPreventCache: new Date().getTime(),
            potentialUserIsLoading: false,
            potentialTransferUsers: defaultSelection(),
            clearTransferEventTypeRoles: undefined,
            roles: [],
            userEntityRoles: userEntityRoles
        };

        vm.formData.roles = rolesDataService.getPotentialUserRoles(true, !vm.formData.userIsGlobalAdmin);


        vm.save = save;
        vm.back = back;
        vm.cancel = cancel;
        vm.transferEventTypeRoles = transferEventTypeRoles;
        vm.displayName = displayName;
        vm.capitalizeFirstLetter = capitalizeFirstLetter;
        vm.getUserForTransferAppData = getUserForTransferAppData;
        vm.onSelected = onSelected;
        vm.clearTransferEventTypeRoles = clearTransferEventTypeRoles;

        //############################### ROLES SECTION ###############################

        if (!vm.isEdit) {
            //ADD
            vm.item.active = true;
        } else {
            //EDIT
            vm.item.roles = _.filter(vm.item.roles, function (role) {
                return role.id !== applicationRoles.kbAdmin && role.id !== applicationRoles.learner && role.id !== applicationRoles.educationOwner && role.id !== applicationRoles.programOwner && role.id !== applicationRoles.instructor && role.id !== applicationRoles.manager && role.id !== applicationRoles.surveyOwner && role.id !== applicationRoles.examOwner;
            });
        }

        //############################### TABLE SECTION ###############################
        if ($sessionStorage.userInfo.tenantResourceKey == "cip") //CIP
        {
            //Enums data naming
            vm.eventEntityType = [
                {
                    id: eFormTypeEntity.exams,
                    format: i18nFilter('translations.filter_user_entity_type_exam')
                },
                {
                    id: eFormTypeEntity.schooling,
                    format: i18nFilter('translations.filter_user_entity_type_schooling')
                }
            ];
        }
        else if ($sessionStorage.userInfo.tenantResourceKey == "eua") //eUA
        {
            vm.eventEntityType = [
                {
                    id: eFormTypeEntity.education,
                    format: i18nFilter('translations.filter_user_entity_type_education')
                },
                {
                    id: eFormTypeEntity.program,
                    format: i18nFilter('translations.filter_user_entity_type_program')
                },
                {
                    id: eFormTypeEntity.onlineEducation,
                    format: i18nFilter('translations.filter_user_entity_type_online_education')
                }
            ];
        }
        else{
            logger.error(i18nFilter('translations.general_error_message_unknown_tenant'))
        }
        vm.eventEntityRole = [
            {
                id: entityRoles.owner,
                format: i18nFilter('translations.filter_user_entity_role_owner')
            },
            {
                id: entityRoles.contactPerson,
                format: i18nFilter('translations.filter_user_entity_role_contact_person')
            }
        ];

        //Entity roles table data
        vm.mainGridOptions = {
            bindingOptions: {
                'dataSource': 'vm.formData.userEntityRoles'
            },
            filterRow: { visible: true },
            searchPanel: { visible: false },
            selection: { mode: "multiple" },
            selectedRowKeys: editUserHelper.getEventTypeRoles(),
            onSelectionChanged: function (selectedTypeRoles) {
                logger.log("Selection occurred: ", selectedTypeRoles);

                editUserHelper.setEventTypeRoles(selectedTypeRoles.selectedRowsData);
            },
            allowSorting: true,
            columns: [
                {
                    dataField: "entityName",
                    caption: i18nFilter("translations.user_operation_gui_application_roles_table_schedule_names"),  //Naziv dogodka
                    allowSorting: true,
                    width: '60%',
                },
                {
                    dataField: "entityType",
                    caption: i18nFilter("translations.user_operation_gui_application_roles_table_schedule_entity_types"), //Tip dogodka
                    allowSorting: true,
                    width: '30%',
                    lookup: {
                        dataSource: vm.eventEntityType,
                        valueExpr: 'id',
                        displayExpr: 'format'
                    }
                },
                {
                    dataField: "entityRole",
                    caption: i18nFilter("translations.user_operation_gui_application_roles_table_schedule_entity_role"),   //Vloga
                    allowSorting: true,
                    width: '30%',
                    lookup: {
                        dataSource: vm.eventEntityRole,
                        valueExpr: 'id',
                        displayExpr: 'format'
                    }
                }
            ],

        };

        //############################### TRANSFER SECTION ###############################

        function transferEventTypeRoles() {
            var potentialTransferUsers = editUserHelper.getRemoteUser();
            var selectedEventTypeRoles = editUserHelper.getEventTypeRoles();

            if (potentialTransferUsers && selectedEventTypeRoles.length > 0) {
                var idPair = [user.id, potentialTransferUsers.id];

                usersDataService.transferUserEntityRoles(idPair, selectedEventTypeRoles).then(function (data) {
                    logger.success(i18nFilter('translations.user_operation_edit_transfer_loading'));
                    editUserHelper.clearRemoteUser();
                    editUserHelper.clearEventTypeRoles();
                }, function (error) {
                    exceptionHelper.handleException(error, true);
                });
            }
        }

        function clearTransferEventTypeRoles() {
            vm.formData.potentialTransferUsers = editUserHelper.clearRemoteUser();
        }

        //############################### NAMING SECTION ###############################

        function displayName() {
            var firstName = vm.item.firstName ? vm.item.firstName : '';
            var lastName = vm.item.lastName ? vm.item.lastName : '';
            vm.item.displayName = (firstName + ' ' + lastName).trim();

        }

        function capitalizeFirstLetter(fieldName) {
            if (!fieldName)
                return;

            if (vm.item[fieldName] && vm.item[fieldName].length) {
                var displayNameWords = vm.item[fieldName].split(" ");
                var capitalizedString = "";
                for (var i = 0; i < displayNameWords.length; i++) {
                    let word = displayNameWords[i].charAt(0).toUpperCase() + displayNameWords[i].slice(1).toLowerCase();

                    //Regex match "-" and any character that isn't whitespace or digit etc.
                    word = word.replace(/[\-–—]+[^\s\d!"#$%&/()=?*+|\\,;.:_]/ug, subStr => subStr.toUpperCase());

                    capitalizedString += word + " ";
                }
                vm.item[fieldName] = capitalizedString.trim();
            }

            if (fieldName != "displayName") {
                vm.displayName();
            }
        }

        //#region potential active users search
        function getUserForTransferAppData(query) {
            if (query === undefined || query === "" || query.length < 3) {
                return;
            }

            //Pass on user id with table, otherwise empty array
            const userIds = user.id ? [user.id] : [];
            vm.formData.potentialUserIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.potentialUserIsLoading = false;
            }, function (error) {
                vm.formData.potentialUserIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function defaultSelection() {
            return editUserHelper.getRemoteUser();
        }

        function onSelected(selectedItem) {
            if (selectedItem)
                editUserHelper.setRemoteUser(selectedItem);
        }

        //############################### BUTTONS SECTION ###############################

        function save() {
            transferEventTypeRoles();   //separate logic call

            usersDataService.editUser(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.user_queue_edit'));
                $state.go('users');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function back() {
            $state.go($scope.navigation[0].state);
        }

        function cancel() {
            editUserHelper.clearRemoteUser();
        }
    }
})();
