(function () {
    'use strict';

    angular
        .module('app.profile')
        .controller('UserCertificateList', userCertificateList);

    userCertificateList.$inject = ['logger', 'certificates', 'eventType'];

    function userCertificateList(logger, certificates, eventType) {
        var vm = this;
        vm.events = certificates;
        vm.formData = {
            eventType: eventType
        };
        
    }
})();
