(function () {
    'use strict';

    angular
        .module('app.notifications')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('notifications', {
                parent: 'base',
                url: '/manage/notifications',
                templateUrl: 'app/manage/notifications/notifications.html',
                controller: 'notifications',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                }
            })
            .state('notifications.notificationConfiguration', {
                url: '/configuration',
                templateUrl: 'app/manage/notifications/configuration/configuration.html',
                controller: 'notificationConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {
                    notifications: ['notificationsDataService', function (notificationsDataService) {
                        return notificationsDataService.getNotificationsSettings();
                    }]
                }
            })
            .state('notifications.notificationConfiguration.editNotificationConfiguration', {
                url: '/edit',
                templateUrl: 'app/manage/notifications/configuration/editConfiguration/editConfiguration.html',
                controller: 'editConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {
                    notifications: ['notificationsDataService', function (notificationsDataService) {
                        return notificationsDataService.getNotificationsSettings();
                    }]
                }
            })
            .state('notifications.templatesConfiguration', {
                url: '/template',
                templateUrl: 'app/manage/notifications/templatesConfiguration/templatesConfiguration.html',
                controller: 'templatesConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {                    
                    notificationTemplates: ['notificationsDataService', function (notificationsDataService) {
                        return notificationsDataService.getNotificationsTemplates();
                    }]
                }
            })
            .state('notifications.templatesConfiguration.editTemplatesConfiguration', {
                url: '/edit/:id',
                templateUrl: 'app/manage/notifications/templatesConfiguration/editTemplatesConfiguration/editTemplatesConfiguration.html',
                controller: 'editTemplatesConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {
                    notificationTemplate: ['notificationsDataService', '$stateParams', function (notificationsDataService, $stateParams) {
                        return notificationsDataService.getNotificationTemplateById($stateParams.id);
                    }]
                }
            })
            .state('notifications.notificationHistory', {
                url: '/history',
                templateUrl: 'app/manage/notifications/history/history.html',
                controller: 'notificationHistory',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                }
            })
            .state('notifications.notificationHistory.notificationHistoryDetails', {
                url: '/details/:id',
                templateUrl: 'app/manage/notifications/history/historyDetails/historyDetails.html',
                controller: 'notificationHistoryDetails',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {
                    historyDetails: ['notificationsDataService', '$stateParams', function (notificationsDataService, $stateParams) {
                        return notificationsDataService.getNotificationHistoryDetails($stateParams.id);
                    }]
                }
            });
    }
})();