(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('scholarshipsDataService', scholarships);

    scholarships.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function scholarships($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllScholarships: getAllScholarships,
            getScholarshipById: getScholarshipById,
            addScholarship: addScholarship,
            editScholarship: editScholarship,
            deleteScholarship: deleteScholarship
        };

        return service;

        function getAllScholarships() {
            return $http.get('api/scholarships').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getScholarshipById(id) {
            return $http.get('api/scholarships/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addScholarship(scholarship) {
            scholarship.dateOfReceivedContract = dateHelper.convertToDateTime(scholarship.dateOfReceivedContract);
            scholarship.requestForPayment = dateHelper.convertToDateTime(scholarship.requestForPayment);
            scholarship.dateOfPayment = dateHelper.convertToDateTime(scholarship.dateOfPayment);
            scholarship.dateOfTheSignedContract = dateHelper.convertToDateTime(scholarship.dateOfTheSignedContract);
            scholarship.expiryDateOfTheContract = dateHelper.convertToDateTime(scholarship.expiryDateOfTheContract);
            scholarship.dateOfSubmittedDocuments = dateHelper.convertToDateTime(scholarship.dateOfSubmittedDocuments);
            var fd = new FormData();

            fd.append('item', JSON.stringify(scholarship));
            return $http.post('api/scholarships', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editScholarship(scholarship) {
            scholarship.dateOfReceivedContract = dateHelper.convertToDateTime(scholarship.dateOfReceivedContract);
            scholarship.requestForPayment = dateHelper.convertToDateTime(scholarship.requestForPayment);
            scholarship.dateOfPayment = dateHelper.convertToDateTime(scholarship.dateOfPayment);
            scholarship.dateOfTheSignedContract = dateHelper.convertToDateTime(scholarship.dateOfTheSignedContract);
            scholarship.expiryDateOfTheContract = dateHelper.convertToDateTime(scholarship.expiryDateOfTheContract);
            scholarship.dateOfSubmittedDocuments = dateHelper.convertToDateTime(scholarship.dateOfSubmittedDocuments);
            var fd = new FormData();

            fd.append('item', JSON.stringify(scholarship));
            return $http.put('api/scholarships', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteScholarship(id) {
            return $http.delete('api/scholarships/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
