(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('emailServiceProvider', emailServiceProvider);

    emailServiceProvider.$inject = ['logger', 'i18nFilter'];

    function emailServiceProvider(logger, i18nFilter) {
        return function (input) {            
            var value = Number(input);
            if (value === 1) {
                return 'SMTP';
            }
            else if (value === 2) {
                return 'Exchange';
            }
        };
    }

})();