(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('locationsDataService', activities);

    activities.$inject = ['$http', 'logger', 'locale', '$q'];

    function activities($http, logger, locale, $q) {
        var service = {
            getLocations: getLocations,
            getVirtualLocations: getVirtualLocations,
            getRegularLocations:getRegularLocations,
            getLocationById: getLocationById,
            addLocation: addLocation,
            editLocation: editLocation,
            deleteLocation: deleteLocation,
            getLocationCalendarSchedules: getLocationCalendarSchedules            
        };

        return service;


        function getLocations() {
            return $http.get('api/locations').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get locations');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getVirtualLocations() {
            return $http.get('api/locations/virtual').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get locations');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getRegularLocations() {
            return $http.get('api/locations/regular').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get locations');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getLocationById(id) {
            return $http.get('api/locations/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get location', id);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addLocation(location) {
            return $http.post('api/locations', location).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to add location');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editLocation(location) {
            return $http.put('api/locations', location).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to edit location');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteLocation(id) {
            return $http.delete('api/locations/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to delete location');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getLocationCalendarSchedules(id) {
            return $http.get('api/locations/' + id + '/calendarSchedules').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        
    }
})();
