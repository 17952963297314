(function () {
    'use strict';

    angular
        .module('app.tenants')
        .controller('Tenants', Tenants);

    Tenants.$inject = ['logger', '$state', 'exceptionHelper', 'tenants', 'i18nFilter'];

    function Tenants(logger, $state, exceptionHelper, tenants, i18nFilter) {
        var vm = this;        

        vm.dtOptions = {};
        vm.tenants = tenants;
       

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit')
        };

        
        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                sortOrder: 'asc',
                allowSorting: true
            },
            {
                dataField: 'id',
                caption: i18nFilter('translations.general_gui_id'),
                width: 350,
                alignment: 'left',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                width: 150,
                allowSorting: false,
                cellTemplate: 'tenantsStatusTemplate'
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'tenantsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.tenants,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion
        
    }
})();
