(function () {
    'use strict';

    angular
        .module('app.contracts')
        .controller('DeleteContract', deleteContract);

    deleteContract.$inject = ['logger', '$stateParams', '$scope', 'contractsDataService', 'i18nFilter'];

    function deleteContract(logger, $stateParams, $scope, contractsDataService, i18nFilter) {
        var vm = this;
        var contract = $stateParams.contract;

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            contractsDataService.deleteContract($stateParams.contractId).then(function (data) {
                logger.success(i18nFilter('translations.contract_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();
