(function () {
    'use strict';

    angular
        .module('app.analytics')
        .component('eformsOfInterest', {
            templateUrl: 'app/analytics/components/eformsOfInterest.component.html',
            controller: eFormsOfInterest,
            controllerAs: 'vm',
            //bindings: {
            //    budget: '='
            //}
        });

    eFormsOfInterest.$inject = ['$state', '$scope', 'i18nFilter', 'dateFilter', 'analyticsDataService'];

    function eFormsOfInterest($state, $scope, i18nFilter, dateFilter, analyticsDataService) {
        var vm = this;

        var columns = [
            {
                dataField: 'entityName',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                allowSorting: true,
                filterOperations: [
                    "contains"
                ]
            },
            {
                dataField: 'interestCount',
                alignment: 'right',
                allowSorting: true,
                allowFiltering: false,
                width: 120,
                caption: i18nFilter('translations.analytics_gui_title_number_of_eforms_of_interest')
            }
        ];

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                let filter = null;
                if (loadOptions.filter) {
                    filter = loadOptions.filter[2];
                }

                analyticsDataService.getEntityEFormInterest(filter).then(function (data) {
                    d.resolve(data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter("translations.exception_message_fetching_attendees"));
                    d.reject();
                });

                return d.promise();
            }
        };

        vm.dataGridOptions = {
            dataSource: dataSource,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            remoteOperations: {
                filtering: true
            },
            wordWrapEnabled: false,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
