(function () {
    'use strict';

    angular
        .module('app.organizations')
        .controller('Organizations', organizations);

    organizations.$inject = ['logger', 'i18nFilter', 'organizations', 'organizationsDataService', 'exceptionHelper'];

    function organizations(logger, i18nFilter, organizations, organizationsDataService, exceptionHelper) {
        var vm = this;
        vm.dtOptions = {};
        vm.persons = [];
        vm.organizations = organizations;       

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit'),
            refresh: i18nFilter('translations.general_gui_refresh'),
            details: i18nFilter('translations.general_gui_details')
        };

        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                sortOrder: 'asc',
                allowSorting: true
            },
            {
                dataField: 'shortName',
                caption: i18nFilter('translations.organizations_gui_short_name'),
                alignment: 'left',
                allowSorting: true,
                width: 350
            },
            {
                dataField: 'taxId',
                caption: i18nFilter('translations.organizations_gui_tax_id'),
                alignment: 'center',
                width: 150,
                allowSorting: false
            },
            {
                dataField:'externalId',
                caption: i18nFilter('translations.organizations_gui_registration_number'),
                alignment: 'center',
                width:150,
                allowSorting: false
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 150,
                allowSorting: false,
                cellTemplate: 'organizationsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.organizations,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        vm.syncOrganization = syncOrganization;

        function syncOrganization(organizationId) {
            organizationsDataService.syncOrganization(organizationId).then(function (data) {
                logger.success(i18nFilter('translations.organization_queue_edit'));
            }, function (error) {
                exceptionHelper.handleException(error);
                });
            
        }
                
    }
})();
