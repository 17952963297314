(function () {
    'use strict';

    angular
        .module('app.proficiencyExamBudgets')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('proficiencyExamBudgets', {
                data: {
                    roles: ['ProficiencyExamAdmin', 'ProficiencyExamOwner']
                }
            })
    }
})();
