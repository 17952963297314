(function () {
    'use strict';

    angular
        .module('app.educations.sidebar.help')
        .component('educationHelp', {
            templateUrl: 'app/educations/sidebars/help/help.html',
            controller: helpController,
            controllerAs: 'vm',
            bindings: {
                educationId: '='
            }
        });

    helpController.$inject = ['logger', 'exceptionHelper', 'educationsDataService', 'i18nFilter','$uibModal'];

    function helpController(logger, exceptionHelper, educationsDataService, i18nFilter, $uibModal) {
        var vm = this;

        vm.formData = {
            loading: true
        };
        vm.contactUs = i18nFilter('translations.education_gui_sidebar_help_contact_us');       

        vm.sendMail = sendMail;

        function sendMail() {        
            $uibModal.open({
                templateUrl: 'app/educations/sidebars/help/emailForm/emailFormEua.html',
                controller: 'emailFormCtrlEua',
                controllerAs: 'vm',
                resolve: {
                    info: function () {
                        return vm.info;
                    }                
                }
            });
        }

        educationsDataService.getEducationInfo(vm.educationId).then(function (data) {
            vm.info = data;            
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });


        educationsDataService.getEducationOwners(vm.educationId).then(function (data) {
            vm.owners = data.owners;
            vm.formData.loading = false;
            vm.to = '';
            for (var i = 0, len = vm.owners.length; i < len; i++) {
                if (vm.owners.length === 1) {
                    vm.to = vm.owners[i].email;
                    break;
                }
                if (i === vm.owners.length) {
                    vm.to += vm.owners[i].email;
                    break;
                }
                if (vm.owners[i].email !== null) {
                    vm.to += vm.owners[i].email + ', ';
                }
            }           
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        }); 
    }
})();
