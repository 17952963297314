(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('EditCompetence', editCompetence);

    editCompetence.$inject = ['logger', 'competence', 'competenceGroups', '$scope', 'i18nFilter', 'isEdit'];

    function editCompetence(logger, competence, competenceGroups, $scope, i18nFilter, isEdit) {
        var vm = this;
        vm.competenceGroups = competenceGroups;
        vm.isEdit = isEdit;

        if (vm.isEdit) {
            vm.item = competence;
        } else {
            vm.item = {
                id: undefined,
                text: undefined,
                definition: undefined,
                status: undefined,
                competences: undefined,
                competenceGroup: undefined,
                items: undefined,
                expanded: undefined
            };
        }
        vm.formData = {};
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            vm.item.text = DOMPurify.sanitize(vm.item.text);
            $scope.$close(vm.item);
        };
    }
})();
