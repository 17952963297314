(function() {
    'use strict';

    angular
        .module('app.schoolings.sidebar.info')
        .component('schoolingInfo', {
            templateUrl: 'app/schoolings/sidebars/info/info.html',
            controller: infoCtrl,
            controllerAs: 'vm',
            bindings: {
                schoolingId: '='
            }
        });

    
    infoCtrl.$inject = ['logger', 'exceptionHelper', 'schoolingsDataService', '$uibModal'];

    function infoCtrl(logger, exceptionHelper, schoolingsDataService, $uibModal) {
        var vm = this;

        vm.info = undefined;

        vm.formData = {
            loading: true
        };

        //Get schooling info with vm.schoolingId parametar 
        schoolingsDataService.getSchoolingInfo(vm.schoolingId).then(function (data) {
            vm.info = data;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });
    }
})();
