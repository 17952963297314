(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.scorm')
        .controller('ScormSecondStep', scormSecondStep);

    scormSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope'];

    function scormSecondStep(logger, activitiesWizardDataService, $state, $scope) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.end = end;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;

        function end() {
            activitiesWizardDataService.done(vm.isEdit);
        }

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
