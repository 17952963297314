(function () {
    'use strict';

    angular
        .module('app.analytics')
        .component('budgetsTable', {
            templateUrl: 'app/analytics/components/budgetsTable.component.html',
            controller: budgetsTable,
            controllerAs: 'vm',
            //bindings: {
            //    budget: '='
            //}
        });

    budgetsTable.$inject = ['$state', '$scope', 'i18nFilter', 'dateFilter', 'analyticsDataService', 'documentsDataService'];

    function budgetsTable($state, $scope, i18nFilter, dateFilter, analyticsDataService, documentsDataService) {
        var vm = this;

        vm.exportBudgetsData = exportBudgetsData;
        vm.budgetEducationsDataGrids = budgetEducationsDataGrids;

        function exportBudgetsData() {
            documentsDataService.downloadDocument('api/analytics/budgets/export', 'Analitika izvoz sklopi.xlsx');
        }

        //#region Budgets data table

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                allowSorting: true
            },
            {
                dataField: 'start',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'end',
                dataType: "date",
                alignment: 'left',
                sortOrder: "asc",
                allowSorting: true,
                allowFiltering: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                dataField: 'budgetLeft',
                caption: i18nFilter('translations.general_gui_amount_left_euro'),
                alignment: 'right',
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'price.amount',
                caption: i18nFilter('translations.general_gui_amount_euro'),
                alignment: 'right',
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'numberOfAttendees',
                caption: i18nFilter('translations.analytics_budgets_table_budgets_data_column_educations'),
                alignment: 'right',
                allowSorting: true,
                allowFiltering: true
            }
        ];

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                analyticsDataService.getAllBudgets().then(function (data) {
                    d.resolve(data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter("translations.exception_message_fetching_attendees"));
                    d.reject();
                });

                return d.promise();
            }
        };

        vm.dataGridOptions = {           
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: {
                visible: true
            },
            searchPanel: {
                visible: false
            },
            pager: {
                visible: true
            },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            },
            cellHintEnabled: true,
            masterDetail: {
                enabled: true,
                template: "detail"
            },
            wordWrapEnabled: false
        };
        //#endregion Budgets data table

        //#region Budget Educations data table

        //columns data setup
        var budgetEducationColumns = [
            {
                dataField: 'educationName',
                width: 'auto',
                caption: i18nFilter('translations.analytics_budgets_table_budgets_educations_data_column_educations')
            },
            {
                dataField: 'numberOfEvents',
                width: 'auto',
                caption: i18nFilter('translations.analytics_budgets_table_budgets_educations_data_column_number_of_events')
            },
            {
                dataField: 'numberOfAttendances',
                width: 'auto',
                caption: i18nFilter('translations.analytics_budgets_table_budgets_educations_data_column_number_of_attendances')
            }
        ];

        //subtable so original data source is passed on
        function budgetEducationsDataGrids(budgetEducationsData) {
            //data grid setup for individual item
            let dataGrid = {
                filterRow: {
                    visible: false
                },
                dataSource: budgetEducationsData,
                searchPanel: {
                    visible: false
                },
                columns: budgetEducationColumns
            };

            return dataGrid;
        };

        //#endregion
    }
})();
