(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('uploadRecord', uploadRecord);

    uploadRecord.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'schedulesDataService', 'scheduleId', 'instructor'];

    function uploadRecord(logger, $stateParams, $scope, i18nFilter, exceptionHelper, schedulesDataService, scheduleId, instructor) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;

        vm.instructor = instructor;
        vm.fileId = undefined;
        vm.scheduleId = scheduleId;

        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '10',
            acceptedFiles: '.docx, .doc',
            addRemoveLinks: true,
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.fileId = id;
            },
            'removedfile': function (file) {
                vm.fileId = undefined;
            }
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        }


        function ok() {
            schedulesDataService.uploadInstructorRecord(vm.instructor.id, vm.scheduleId, vm.fileId).then(function (data) {
                logger.success(i18nFilter("translations.schedule_presence_records_modal_upload_add_to_queue_message"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
