(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('decimalHelper', decimalHelper);

    decimalHelper.$inject = ['logger', '$locale'];

    function decimalHelper(logger, $locale) {
        var service = {
            convertDecimalToLocaleString: convertDecimalToLocaleString,
            round: round
        };

        return service;

        function convertDecimalToLocaleString(value) {
            if (value === undefined || value === null) {
                return value;
            }

            var decimalStep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
            var groupStep = $locale.NUMBER_FORMATS.GROUP_SEP;

            var decimalHolder = '%%DECIMAL%%';
            var separatorHolder = '%%SEPARATOR%%';

            value = value.toString().replace('.', decimalHolder);
            value = value.replace(',', separatorHolder);
            value = value.replace(decimalHolder, decimalStep);
            value = value.replace(separatorHolder, groupStep);
            return value;
        }

        function round(number, precision) {
            var pair = (number + 'e').split('e')
            var value = Math.round(pair[0] + 'e' + (+pair[1] + precision))
            pair = (value + 'e').split('e')
            return +(pair[0] + 'e' + (+pair[1] - precision))
        }
    }
})();