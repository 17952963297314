(function () {
    'use strict';

    angular
        .module('app.welcome')
        .controller('Welcome', welcome);

    welcome.$inject = ['logger', '$rootScope', '$state', '$localStorage', 'appName', '$window', '$location', 'adalAuthenticationService', 'tenantResourceKey'];

    function welcome(logger, $rootScope, $state, $localStorage, appName, $window, $location, adalAuthenticationService, tenantResourceKey) {        
        var vm = this;
        vm.appName = appName;
        vm.goHome = goHome;
        vm.tenantResourceKey = tenantResourceKey;

        if ($localStorage.toState) {
            var toState = $localStorage.toState;
            $localStorage.toState = undefined;
            $state.go(toState, undefined);
        }

        function goHome() {
            vm.showLoading = true;
            if (vm.tenantResourceKey == 'eua') {
                $localStorage.toState = 'classicCatalog.education';
            } else {
                $localStorage.toState = 'classicCatalog';
            }
            adalAuthenticationService.login(true);
        }
    }
})();
