(function () {
    'use strict';

    angular
        .module('app.educations.activities')
        .component('html5Activity', {
            templateUrl: 'app/educations/activities/components/html5/html5Activity.html',
            controller: html5Activity,
            controllerAs: 'vm',
            bindings: {
                activity: '=', //access via vm.acitivty
                learningActivityId: '=',
                ignoreFirstOpenRule: '='
            }
        });

    html5Activity.$inject = ['logger', 'learningActivitySuccess', 'learningActivityState', 'activitiesDataService', 'exceptionHelper', 'i18nFilter', 'dateHelper', 'learningPlanItemStatus'];

    function html5Activity(logger, learningActivitySuccess, learningActivityState, activitiesDataService, exceptionHelper, i18nFilter, dateHelper, learningPlanItemStatus) {
        var vm = this;
        var ignoreNextToggle = false;
        

        vm.localeMessages = {
            activityStart: i18nFilter('translations.activity_gui_tooltip_activity_start'),
            activityFinish: i18nFilter('translations.activity_gui_tooltip_activity_finish'),
            activityEstimatedDuration: i18nFilter('translations.activity_gui_tooltip_activity_estimated_duration'),
            activityScore: i18nFilter('translations.activity_gui_tooltip_activity_score'),
            activityPass: i18nFilter('translations.general_gui_success_pass'),
            activityFail: i18nFilter('translations.general_gui_success_fail'),
            activityTypeTooltip: i18nFilter('translations.filter_education_activity_type_generic')
        };

        vm.formData = {
            //Flags
            isStarted: isActivityDateStarted(),
            isExpanded: vm.ignoreFirstOpenRule ? false : angular.copy(vm.activity.isOpen),
            isLate: isUserLate(),
            //Data
            learningActivitySuccess: learningActivitySuccess,
            learningActivityState: learningActivityState,
            learningPlanItemStatus: learningPlanItemStatus
        };

        vm.confirmFinish = confirmFinish;
        vm.toggle = toggle;
        vm.openContent = openContent;

        // Otvori aktivnost ukoliko je prethodno bila odabrana
        if (vm.learningActivityId && vm.activity.learningActivityId === vm.learningActivityId) {
            vm.formData.isExpanded = true;
        }




        function confirmFinish() {
            logger.log("Confirm finish");
            vm.ignoreNextToggle = true;
            activitiesDataService.confirmFinishHtml5Activity(vm.activity.learningActivityId).then(function (data) {
                logger.success(i18nFilter("translations.activity_queue_finish"));
                vm.ignoreNextToggle = false;
            }, function (error) {
                vm.ignoreNextToggle = false;
                exceptionHelper.handleException(error);
            });
        }

        // Handlanje klika za expand
        function toggle() {
            if (!ignoreNextToggle) {
                vm.formData.isExpanded = !vm.formData.isExpanded;
            }
            else {
                ignoreNextToggle = false;
            }
        }

        function isActivityDateStarted() {
            if (vm.activity.data.startDate) {
                vm.activity.data.startDate = dateHelper.convertFromStringToDate(vm.activity.data.startDate);
                var date = angular.copy(vm.activity.data.startDate);
                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "true" tako da se može pokrenuti
                return true;
            }
        }

        function isUserLate() {
            if (vm.activity.deadLine) {
                vm.activity.deadLine = dateHelper.convertFromStringToDate(vm.activity.deadLine);
                var date = angular.copy(vm.activity.deadLine);
                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "false" tako da ispadne da ne kasni
                return false;
            }
        }

        function openContent() {
            vm.ignoreNextToggle = true;
            window.open('api/activities/learningActivities/' + vm.activity.learningActivityId + '/html5/open', '_blank');
        }
    }
})();
