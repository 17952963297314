(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('confirmSendMailToInstructors', confirmSendMailToInstructors);

    confirmSendMailToInstructors.$inject = ['logger', 'i18nFilter', 'schedulePackId', '$scope', 'exceptionHelper', 'schedulePacksDataService'];

    function confirmSendMailToInstructors(logger, i18nFilter, schedulePackId, $scope, exceptionHelper, schedulePacksDataService) {
        var vm = this;        

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(true);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
