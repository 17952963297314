(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .factory('editSchoolingHelper', editSchoolingHelper);

    editSchoolingHelper.$inject = ['logger', 'schoolingsDataService', 'exceptionHelper', '$state', '$stateParams', 'i18nFilter', 'schoolingAccessMode'];

    function editSchoolingHelper(logger, schoolingsDataService, exceptionHelper, $state, $stateParams, i18nFilter, schoolingAccessMode) {

        var internalData = undefined;
        var wizardSteps = undefined;
        var myImage = undefined;

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            setData: setData,
            clearData: clearData,
            addPlan: addPlan,
            editPlan: editPlan,
            removePlan: removePlan,
            isYearUnique: isYearUnique,
            done: done,
            getMyImage: getMyImage,
            setMyImage: setMyImage,
            clearMyImage: clearMyImage
        };

        return service;

        function getData() {
            if (!internalData) {
                internalData = {
                    //Set default properties
                    prices: [],
                    plans: [],
                    canOwnersCreateAssignments: true,
                    hasSequentialActivities: true,
                    certificationEnabled: true,
                    accessMode: schoolingAccessMode.publicAccess,
                    active: true,
                    allActivitiesDone: true,
                    allActivitiesSuccessfullyDone: true,
                    successCriteriaPercantageEnabled: false,
                    fieldNames: [],
                    automaticApplicationConfirmation: false
                };
            }
            return internalData;
        }

        function getMyImage() {
            let res = myImage ? myImage : '';
            return res;
        }
        function setMyImage(img) {
            myImage = img;
        }
        function clearMyImage() {
            myImage = undefined;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'schoolings.administration.edit.firstStep',
                        text: i18nFilter('translations.schooling_edit_wizard_first_step_title')
                    },
                    {
                        state: 'schoolings.administration.edit.secondStep',
                        text: i18nFilter('translations.schooling_edit_wizard_second_step_title')
                    },
                    {
                        state: 'schoolings.administration.edit.thirdStep',
                        text: i18nFilter('translations.schooling_edit_wizard_third_step_title')
                    },
                    {
                        state: 'schoolings.administration.edit.fourthStep',
                        text: i18nFilter('translations.schooling_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'schoolings.administration.edit.fifthStep',
                        text: i18nFilter('translations.schooling_edit_wizard_fifth_step_title')
                    },
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'addSchooling.firstStep',
                        text: i18nFilter('translations.schooling_edit_wizard_first_step_title')
                    },
                    {
                        state: 'addSchooling.secondStep',
                        text: i18nFilter('translations.schooling_edit_wizard_second_step_title')
                    },
                    {
                        state: 'addSchooling.thirdStep',
                        text: i18nFilter('translations.schooling_edit_wizard_third_step_title')
                    },
                    {
                        state: 'addSchooling.fourthStep',
                        text: i18nFilter('translations.schooling_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'addSchooling.fifthStep',
                        text: i18nFilter('translations.schooling_edit_wizard_fifth_step_title')
                    },
                ];
            }


            return wizardSteps;
        }

        function setData(schooling) {
            internalData = schooling;
        }

        function clearData() {
            internalData = undefined;
        }

        function addPlan(plan) {
            internalData.plans.push(plan);
        }

        function editPlan(plan) {
            var found = false;
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == plan.year) {
                    internalData.plans[i] = plan;
                    found = true;
                    break;
                }
            }
            if (!found) {
                addPlan(plan);
            }
        }

        function removePlan(year) {
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == year) {
                    internalData.plans.splice(i, 1);
                    break;
                }
            }
        }

        function isYearUnique(year, budgetLineItem) {
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == year && internalData.plans[i].budgetLineItem == budgetLineItem) {
                    return false;
                }
            }
            return true;
        }

        function done(isEdit) {
            //Call right data service method
            if (isEdit) {
                return schoolingsDataService.editSchooling(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.schooling_operation_edit_success_message', additionalData.data.schoolingName));
                    $state.go("schoolings.home", $stateParams, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
            else {
                schoolingsDataService.addSchooling(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.schooling_operation_add_success_message', additionalData.schoolingName));
                    $state.go("classicCatalog.schooling");
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }
    }
})();
