(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('examSchedulePacks', {
                parent: 'base',
                url: '/examSchedulePacks/:entityId',
                templateUrl: 'app/examSchedulePacks/examSchedulePacks.html',
                controller: 'examSchedulePacks',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    exams: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveExamsByCoordinator();
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.add', {
                url: '/add/exam',
                templateUrl: 'app/examSchedulePacks/create/create.html',
                controller: 'examSchedulePackCreate',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    exam: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamForScheduleById($stateParams.entityId);
                    }],
                    examSchedule: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return null;
                    }],
                    themes: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getThemesByExamId($stateParams.entityId);
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.edit', {
                url: '/:schedulePackId/edit/exam',
                templateUrl: 'app/examSchedulePacks/create/create.html',
                controller: 'examSchedulePackCreate',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    exam: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamForScheduleById($stateParams.entityId);
                    }],
                    examSchedule: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamScheduleById($stateParams.schedulePackId);
                    }],
                    themes: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getThemesByExamId($stateParams.entityId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.arrangementsAdd', {
                url: '/schedule/:schedulePackId/arrangement/add',
                templateUrl: 'app/examSchedulePacks/arrangements/arrangements.html',
                controller: 'examSchedulePackArrangements',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    locations: ['$stateParams', 'locationsDataService', function ($stateParams, locationsDataService) {
                        return locationsDataService.getLocations();
                    }],
                    recorders: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamRecordersByExamId($stateParams.entityId);
                    }],
                    chairmen: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamChairmenByExamId($stateParams.entityId);
                    }],
                    preparers: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamPreparersByExamId($stateParams.entityId);
                    }],
                    evaluators: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamEvaluatorsByExamId($stateParams.entityId);
                    }],
                    scheduleActivities: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getScheduleActivitiesByScheduleId($stateParams.schedulePackId);
                    }],
                    examArrangement: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return {};
                    }],
                    themesGroupedByCommissionMember: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamThemesGroupedByCommissionMemberByExamId($stateParams.entityId, $stateParams.schedulePackId);
                    }],
                    examApplicationLearners: ['$stateParams', 'examApplicationsDataService', function ($stateParams, examApplicationsDataService) {
                        return examApplicationsDataService.getApplicationLearnersByScheduleId($stateParams.schedulePackId);
                    }],
                    examApplicationLearnersActivites: ['$stateParams', 'examApplicationsDataService', function ($stateParams, examApplicationsDataService) {
                        return examApplicationsDataService.getApplicationLearnersActivitesByScheduleId($stateParams.schedulePackId);
                    }],
                    examNameAndScheduleCode: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.arrangementsEdit', {
                url: '/schedule/:schedulePackId/arrangement/edit',
                templateUrl: 'app/examSchedulePacks/arrangements/arrangements.html',
                controller: 'examSchedulePackArrangements',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    locations: ['$stateParams', 'locationsDataService', function ($stateParams, locationsDataService) {
                        return locationsDataService.getLocations();
                    }],
                    recorders: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamRecordersByExamId($stateParams.entityId);
                    }],
                    chairmen: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamChairmenByExamId($stateParams.entityId);
                    }],
                    preparers: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamPreparersByExamId($stateParams.entityId);
                    }],
                    evaluators: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamEvaluatorsByExamId($stateParams.entityId);
                    }],
                    scheduleActivities: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return [];
                    }],
                    examArrangement: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamScheduleCommissionsByExamScheduleId($stateParams.schedulePackId);
                    }],
                    themesGroupedByCommissionMember: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamThemesGroupedByCommissionMemberByExamId($stateParams.entityId, $stateParams.schedulePackId);
                    }],
                    examApplicationLearners: ['$stateParams', 'examApplicationsDataService', function ($stateParams, examApplicationsDataService) {
                        return examApplicationsDataService.getApplicationLearnersByScheduleId($stateParams.schedulePackId);
                    }],
                    examApplicationLearnersActivites: ['$stateParams', 'examApplicationsDataService', function ($stateParams, examApplicationsDataService) {
                        return examApplicationsDataService.getApplicationLearnersActivitesByScheduleId($stateParams.schedulePackId);
                    }],
                    examNameAndScheduleCode: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }],
                    toastr: ['$stateParams', 'toastr', function ($stateParams, toastr) {
                        return toastr;
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.questionsAndPayments', {
                url: '/schedule/:schedulePackId/questionsAndPayments',
                templateUrl: 'app/examSchedulePacks/questionsAndPayments/questionsAndPayments.html',
                controller: 'examSchedulePackQuestionsAndPayments',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    questions: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getQuestions($stateParams.schedulePackId);
                    }],
                    payments: ['$stateParams', 'examApplicationsDataService', function ($stateParams, examApplicationsDataService) {
                        return examApplicationsDataService.getApplicationLearnersByScheduleId($stateParams.schedulePackId);
                    }],
                    examNameAndScheduleCode: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }]
                },
                data: {
                    roles: ['ExamAdmin', 'ExamOwner']
                }
            })

            .state('examSchedulePacks.applications', {
                url: '/:schedulePackId/applications',
                templateUrl: 'app/examSchedulePacks/applications/applications.html',
                controller: 'examSchedulePackApplications',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    examApplications: ['examApplicationsDataService', '$stateParams', function (examApplicationsDataService, $stateParams) {
                        return examApplicationsDataService.getExamApplicationsByScheduleId($stateParams.schedulePackId);
                    }],
                    examNameAndScheduleCode: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }],
                    examSchedule: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamScheduleById($stateParams.schedulePackId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.application', {
                url: '/:schedulePackId/applications/application?applicationId',
                templateUrl: 'app/examSchedulePacks/application/application.html',
                controller: 'examSchedulePackApplication',
                controllerAs: 'vm',
                requireADLogin: true,
                parent: 'examSchedulePacks.applications',
                resolve: {
                    examApplication: ['examApplicationsDataService', '$stateParams', function (examApplicationsDataService, $stateParams) {
                        return examApplicationsDataService.getExamApplicationById($stateParams.applicationId);
                    }],
                    customerPrices: ['examApplicationsDataService', '$stateParams', function (examApplicationsDataService, $stateParams) {
                        return examApplicationsDataService.getExamCustomerPricesByScheduleId($stateParams.schedulePackId);
                    }],
                    commissionMembers: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return examsDataService.getDistinctCommissionMembersByExamId($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })
            
            .state('examSchedulePacks.documents', {
                url: '/:schedulePackId/applications/documents?applicationId',
                templateUrl: 'app/examSchedulePacks/documents/documents.html',
                controller: 'examSchedulePackDocuments',
                controllerAs: 'vm',
                requireADLogin: true,
                parent: 'examSchedulePacks.applications',
                resolve: {
                    examApplication: ['examApplicationsDataService', '$stateParams', function (examApplicationsDataService, $stateParams) {
                        return examApplicationsDataService.getExamApplicationById($stateParams.applicationId);
                    }],
                    activeTemplates: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getListOfActiveTemplatesByLearnerId($stateParams.applicationId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })

            .state('examSchedulePacks.arrangementsDocuments', {
                url: '/:schedulePackId/arrangements/documents?schedulePackId?activityId',
                views: {
                    'arrangementsDocuments@examSchedulePacks.arrangementsEdit': {
                        templateUrl: 'app/examSchedulePacks/arrangements/Documents/documents.html',
                        controller: 'examSchedulePackArrangementsDocuments',
                        controllerAs: 'vm'
                    
                    }
                },
                requireADLogin: true,
                parent: 'examSchedulePacks.arrangementsEdit',
                resolve: {
                    examSchedule: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamScheduleById($stateParams.schedulePackId);
                    }],
                    activeTemplates: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getListOfActiveTemplatesByScheduleId($stateParams.schedulePackId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
              
            })

            .state('examSchedulePacks.arrangementsCommissionDocuments', {
                url: '/:schedulePackId/arrangements/commissionDocuments?schedulePackId?activityId',
                views: {
                    'arrangementsCommissionDocuments@examSchedulePacks.arrangementsEdit': {
                        templateUrl: 'app/examSchedulePacks/arrangements/Documents/documents.html',
                        controller: 'examSchedulePackArrangementsDocuments',
                        controllerAs: 'vm'
                    }
                },
                requireADLogin: true,
                parent: 'examSchedulePacks.arrangementsEdit',
                params: { commissionId: null },
                resolve: {
                    examSchedule: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamScheduleById($stateParams.schedulePackId);
                    }],
                    activeTemplates: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getListOfActiveTemplatesByScheduleId($stateParams.schedulePackId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })

            .state('examSchedulePacks.resultsEdit', {
                url: '/schedule/:schedulePackId/results/edit?applicationId',
                templateUrl: 'app/examSchedulePacks/results/results.html',
                controller: 'examSchedulePackResults',
                controllerAs: 'vm',
                requireADLogin: true,
                parent: 'examSchedulePacks.applications',
                resolve: {
                    examResult: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamResult($stateParams.schedulePackId, $stateParams.applicationId);
                    }],
                    commissionMembersGroupedByThemeOnTheExam: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamCommissionMembersGroupedByThemeByExamId($stateParams.entityId);
                    }],
                    recorders: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamRecordersByExamId($stateParams.entityId);
                    }],
                    chairmen: ['$stateParams', 'examsDataService', function ($stateParams, examsDataService) {
                        return examsDataService.getExamChairmenByExamId($stateParams.entityId);
                    }],
                    distinctCommissionMembers: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return examsDataService.getDistinctCommissionMembersByExamId($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['ExamOwner']
                }
            })
            .state('examSchedulePacks.paymentOrder', {
                url: '/schedule/:schedulePackId/paymentOrders',
                templateUrl: 'app/examSchedulePacks/paymentOrders/paymentOrders.html',
                controller: 'examSchedulePackPaymentOrders',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    examArrangement: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamSchedulePaymentOrderCommissionsByExamScheduleId($stateParams.schedulePackId);
                    }],
                    examNameAndScheduleCode: ['$stateParams', 'examSchedulesDataService', function ($stateParams, examSchedulesDataService) {
                        return examSchedulesDataService.getExamNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['ExamOwner']
                }
            });
    }
})();
