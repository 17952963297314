(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities')
        .controller('EditActivity', edit);

    edit.$inject = ['logger', '$scope', 'activitiesWizardDataService', '$stateParams', '$state', 'activity'];

    function edit(logger, $scope, activitiesWizardDataService, $stateParams, $state, activity) {
        var vm = this;

        activitiesWizardDataService.clearData();
        vm.isEdit = $state.current.data.isEdit;
        if (vm.isEdit) {
            vm.navigation = activitiesWizardDataService.getWizardSteps(activity.activityType.id, vm.isEdit);
            activitiesWizardDataService.setData(activity);
        }
        else {
            vm.navigation = activitiesWizardDataService.getWizardSteps(null, vm.isEdit);
        }
        $scope.navigation = vm.navigation;
    }
})();
