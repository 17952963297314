(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('schoolingApplicationsDataService', schoolingApplications);

    schoolingApplications.$inject = ['$http', 'logger', '$q'];

    function schoolingApplications($http, logger, $q) {
        var service = {
            getSchoolingApplicationsByScheduleId: getSchoolingApplicationsByScheduleId,
            getSchoolingApplicationById: getSchoolingApplicationById,
            getApplicationLearnersByScheduleId: getApplicationLearnersByScheduleId,
            saveSchoolingApplicationResponse: saveSchoolingApplicationResponse,
            getSchoolingCustomerPricesByScheduleId: getSchoolingCustomerPricesByScheduleId,
            togglePaymentConfirmation: togglePaymentConfirmation,
            notifyCandidates: notifyCandidates,
            downloadFile: downloadFile,
            deleteSchoolingCertificateDocument: deleteSchoolingCertificateDocument,
            getApplicationLearnersActivitesByScheduleId: getApplicationLearnersActivitesByScheduleId,
            transferSchoolingApplication: transferSchoolingApplication,
            saveSchoolingApplicationApproved: saveSchoolingApplicationApproved,
            saveSchoolingApplicationsResponse: saveSchoolingApplicationsResponse,
            learnerEnrolledSchoolingApplicationSchedule: learnerEnrolledSchoolingApplicationSchedule
        };

        return service;

        function getSchoolingApplicationsByScheduleId(id) {
            return $http.get('api/schoolingApplications/schedule/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function getSchoolingApplicationById(id) {
            return $http.get('api/schoolingApplications/application/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getApplicationLearnersByScheduleId(id) {
            return $http.get('api/schoolingApplications/applicationLearnersBySchedule/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getApplicationLearnersActivitesByScheduleId(id) {
            return $http.get('api/schoolingApplications/applicationLearnersActivitiesBySchedule/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function saveSchoolingApplicationResponse(schoolingApplication, status) {
            return $http.post('api/schoolingApplications/saveApplicationResponse/' + status, schoolingApplication).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to update schooling application response.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function saveSchoolingApplicationsResponse(schoolingApplications, status) {
            return $http.post('api/schoolingApplications/saveApplicationsResponse/' + status, schoolingApplications).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingCustomerPricesByScheduleId(id) {
            return $http.get('api/schoolingApplications/application/prices/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function togglePaymentConfirmation(examApplicationLearnerId) {
            return $http.get('api/schoolingApplications/togglePaymentConfirmation/applicationLearnerId/' + examApplicationLearnerId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function notifyCandidates(examScheduleId) {
            return $http.get('api/schoolingApplications/notifyCandidates/' + examScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function transferSchoolingApplication(application) {
            return $http.post('api/schoolingApplications/transferApplication/', application).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to transfer schooling application.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function saveSchoolingApplicationApproved(application) {
            return $http.post('api/schoolingApplications/saveApproved/', application).then(function (data) {
                return data.data;
            }, function (data) {
                logger.log('Failed to save newly created approved schooling application.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function downloadFile(path) {

            var filename = path.substring(path.lastIndexOf('/') + 1);

            var body = {
                path: path
            };

            return $http.post("api/schoolingApplications/downloadFile", body, {
                responseType: 'arraybuffer'
            }).success(function (data, status, headers) {
                var ua = new UAParser();
                var browser = ua.getBrowser();

                var contentType = headers["content-type"] || "application/octet-stream";
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    var blob = new Blob([data], { type: contentType });

                    if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    }
                    else {
                        var url = urlCreator.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.target = "_blank";
                        a.download = filename; //you may assign this value from header as well 
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
            });
        }

        function deleteSchoolingCertificateDocument(basicSchoolingApplicationLearner) {
            $http.post('api/schoolingApplications/deleteApplicationCertificate/', basicSchoolingApplicationLearner).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function learnerEnrolledSchoolingApplicationSchedule(scheduleId, learnerId) {
            return $http.get('api/schoolingApplications/schedule/' + scheduleId + '/learnerEnrolled/' + learnerId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
