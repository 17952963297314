(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditEducationFifthStep', EditEducationFifthStep);

    EditEducationFifthStep.$inject = ['$uibModal', 'logger', 'i18nFilter', '$state', '$scope', 'editEducationHelper', '$stateParams'];

    function EditEducationFifthStep($uibModal, logger, i18nFilter, $state, $scope, editEducationHelper, $stateParams) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.editEducationHelper = editEducationHelper;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.item = editEducationHelper.getData();
        if (vm.item.plans != null) {
            vm.item.plans.forEach(function (plan) {
                var tmp = "";
                plan.location.forEach(function (lctn) {
                    if (tmp != "") tmp += ", ";
                    tmp += lctn.name + "\u00A0(" + lctn.price + ",\u00A0" + lctn.participantsCost + ")";
                });
                plan.allLocations = tmp;
            });
        }

        vm.formData = {
            dataColumns: [
                i18nFilter('translations.education_gui_year'),
                i18nFilter('translations.education_gui_itemsInQ1'),
                i18nFilter('translations.education_gui_itemsInQ2'),
                i18nFilter('translations.education_gui_itemsInQ3'),
                i18nFilter('translations.education_gui_itemsInQ4'),
                i18nFilter('translations.education_gui_budgetLineItem'),
                i18nFilter('translations.exam_arrangements_gui_label_location'),
                i18nFilter('translations.education_gui_numOfParticipants'),
                i18nFilter('translations.education_gui_budget'),
                i18nFilter('translations.general_gui_actions')
            ],
            noData: i18nFilter('translations.exam_arrangements_gui_warning_no_data')
        };

        vm.maxInstructorPrice = 0;
        vm.item.prices.forEach(function (price) {
            if (price.category.name == 'InstructorPrice' && price.amount > vm.maxInstructorPrice) {
                vm.maxInstructorPrice = price.amount;
            }
        });
        

        vm.add = add;
        vm.edit = edit;
        vm.remove = remove;
        vm.next = next;
        vm.cancel = cancel;
        vm.back = back;
        vm.save = save;

        function add() {
            $uibModal.open({
                templateUrl: 'app/educations/administration/edit/fifthStep/editFifthStep/editFifthStep.html',
                controller: 'EditEducationFifthStepPlan',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getLocations();
                    }],
                    plan: function () {
                        return {};
                    },
                    isEdit: function () {
                        return false;
                    }
                }
            })
            .result.then(function (plan) {
                calculateBudget(plan);
                editEducationHelper.addPlan(plan);
            }, function () {
                
            });
        }

        function edit(plan) {
            $uibModal.open({
                templateUrl: 'app/educations/administration/edit/fifthStep/editFifthStep/editFifthStep.html',
                controller: 'EditEducationFifthStepPlan',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    locations: ['$stateParams', 'locationsDataService', function ($stateParams, locationsDataService) {
                        return locationsDataService.getLocations();
                    }],
                    plan: function () {
                        return angular.copy(plan);
                    },
                    isEdit: function () {
                        return true;
                    }
                }
            })
            .result.then(function (plan) {
                calculateBudget(plan);
                editEducationHelper.editPlan(plan);
            }, function () {
                
            });
        }

        function calculateBudget(plan) {
            if (vm.item.estDurationHour != null) {
                var hours = vm.item.estDurationHour, days = ~~(vm.item.estDurationHour / 8) + 1;
            } else {
                switch (vm.item.estDurationDay) {
                    case 1:
                        var hours = 8, days = vm.item.estDurationDay;
                        break;
                    case -1:
                        var hours = 4, days = 1;
                        break;
                    case -2:
                        var hours = 16, days = 2;
                        break;
                    default:
                        var hours = vm.item.estDurationDay * 8, days = vm.item.estDurationDay;
                }
            }

            plan.budget = 0;
            var totalItems = parseInt(plan.itemsInQ1) + parseInt(plan.itemsInQ2) + parseInt(plan.itemsInQ3) + parseInt(plan.itemsInQ4);
            plan.location.forEach(function (lctn) {
                plan.budget += totalItems * (lctn.price + parseInt(plan.numOfParticipants) * days * lctn.participantsCost + hours * vm.maxInstructorPrice);
            });
        }

        function remove(year) {
            editEducationHelper.removePlan(year);
        }

        function save() {
            var item_copy = editEducationHelper.setCodeListData(vm.item);
            editEducationHelper.setData(item_copy);
            editEducationHelper.done(vm.isEdit, true);
        }                              

        function next() {
            $state.go($scope.navigation[5].state);
        }

        function back() {
            $state.go($scope.navigation[3].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('educations.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.education');
            }
        }
    }
})();
