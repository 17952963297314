(function () {
    'use strict';

    angular
        .module('app.educations.sidebar.lecturers')
        .component('educationLecturers', {
            templateUrl: 'app/educations/sidebars/lecturers/lecturers.html',
            controller: lecturersController,
            controllerAs: 'vm',
            bindings: {
                educationId: '='
            }
        });

    lecturersController.$inject = ['logger', 'exceptionHelper', 'educationsDataService'];

    function lecturersController(logger, exceptionHelper, educationsDataService) {        
        var vm = this;

        vm.formData = {
            loading: true,
            page:1
        };       

        educationsDataService.getEducationLecturers(vm.educationId).then(function (data) {
            vm.lecturers = data.lecturers;
            vm.formData.loading = false;
            if (vm.lecturers) {
                vm.totalItems = vm.lecturers.length;
            }
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });  
                
        vm.viewby = 5;       
        vm.formData.page = 1;
        vm.itemsPerPage = vm.viewby;
        vm.maxSize = 4; 

        vm.setPage = function (pageNo) {
            vm.formData.page = pageNo;
        };

        vm.pageChanged = function () {
            
        };        
    }
})();
