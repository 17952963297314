(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamSecondStep', secondStep);

    secondStep.$inject = ['logger', '$state', 'editExamsHelper', '$scope', '$stateParams', 'templates', 'tenantInfo', 'i18nFilter', '$uibModal'];

    function secondStep(logger, $state, editExamsHelper, $scope, $stateParams, templates, tenantInfo, i18nFilter, $uibModal) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;

        vm.item = editExamsHelper.getData();

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.formData = {
            templates: templates
        };
        
        vm.tenantInfo = tenantInfo;
        vm.next = next;
        vm.cancel = cancel;
        vm.back = back;
        vm.openInstructorFeeHelp = openInstructorFeeHelp;
        vm.removeTemplate = removeTemplate;
        vm.save = save;

        function save() {
            var item_copy = editExamsHelper.setCodeListData(vm.item);
            editExamsHelper.setData(item_copy);
            editExamsHelper.done(vm.isEdit, true);
        } 

        function next() {
            $state.go($scope.navigation[2].state);
        }

        function back() {
            $state.go($scope.navigation[0].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('exams.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.exam');
            }
        }

        function openInstructorFeeHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/exams/administration/edit/secondStep/instructorFeeHelp/examsInstructorFeeHelp.html',
                controller: 'examsInstructorFeeHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function removeTemplate(fieldName) {
            this.item[fieldName] = undefined;
        }

        $('#coordinatorNotificationCommissionEdit').on('touchspin.on.min', function () {
            $("#coordinatorNotificationCommissionEdit").trigger("touchspin.uponce");
            $("#coordinatorNotificationCommissionEdit").trigger("touchspin.downonce");
        });
        
        $('#coordinatorNotificationPrepareForExam').on('touchspin.on.min', function () {
            $("#coordinatorNotificationPrepareForExam").trigger("touchspin.uponce");
            $("#coordinatorNotificationPrepareForExam").trigger("touchspin.downonce");
        });

        $('#coordinatorNotificationPrintPaperwork').on('touchspin.on.min', function () {
            $("#coordinatorNotificationPrintPaperwork").trigger("touchspin.uponce");
            $("#coordinatorNotificationPrintPaperwork").trigger("touchspin.downonce");
        });

        $('#coordinatorNotificationEnterGrades').on('touchspin.on.min', function () {
            $("#coordinatorNotificationEnterGrades").trigger("touchspin.uponce");
            $("#coordinatorNotificationEnterGrades").trigger("touchspin.downonce");
        });

    }
})();
