(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities')
        .controller('LearnerPoints', learnerPoints);

    learnerPoints.$inject = ['logger', 'i18nFilter', '$scope', 'dateFilter', 'periods', 'learningPlanItemStatus', 'schoolingsDataService', '$stateParams'];
    
    function learnerPoints(logger, i18nFilter, $scope, dateFilter, periods, learningPlanItemStatus, schoolingsDataService, $stateParams) {
        var vm = this;                
        vm.periods = periods;

        //#region Period helper
        var defaultPeriod = getDefaultPeriod();
        vm.defaultPeriodId = defaultPeriod ? defaultPeriod.id : undefined;
        //#endregion

        
        vm.rowClick = rowClick;
       

        //#region Table
        vm.learningPlanItemStatuses = [
            {
                id: learningPlanItemStatus.removedFromPlan,
                format: i18nFilter('translations.filter_learning_plan_item_status_removed_from_plan')
            },
            {
                id: learningPlanItemStatus.notStarted,
                format: i18nFilter('translations.filter_learning_plan_item_status_not_started')
            },
            {
                id: learningPlanItemStatus.started,
                format: i18nFilter('translations.filter_learning_plan_item_status_started')
            },
            {
                id: learningPlanItemStatus.finished,
                format: i18nFilter('translations.filter_learning_activity_status_finished')
            }
        ];
        vm.options = {
            firstName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            lastName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            email: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            learningPlanItemStatus: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            learningPeriod: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            enrollmentDate: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            deadline: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };
        
        var columns = [
            {
                dataField: 'firstName',
                caption: i18nFilter('translations.general_gui_first_name'),
                alignment: 'left',
                dataType: 'string',
                allowSorting: true
            },
            {
                dataField: 'lastName',
                caption: i18nFilter('translations.general_gui_last_name'),
                alignment: 'left',
                sortOrder: 'asc',
                dataType: 'string',
                allowSorting: true
            },
            {
                dataField: 'email',
                caption: i18nFilter('translations.general_gui_email'),
                alignment: 'left',
                dataType: 'string',
                allowSorting: true
            },
            {
                dataField: 'learningPlanItemStatus',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                dataType: 'number',
                allowSorting: true,
                lookup: {
                    dataSource: vm.learningPlanItemStatuses,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                dataField: 'learningPeriod.id',
                caption: i18nFilter('translations.general_gui_learning_period'),
                alignment: 'left',
                filterValue: vm.defaultPeriodId,
                dataType: 'number',
                lookup: {
                    dataSource: vm.periods,
                    valueExpr: 'id',
                    displayExpr: 'name'
                },
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_enroll_datetime'),
                alignment: 'left',
                dataType: "date",
                dataField: 'enrollmentDate',
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
            },
            {
                caption: i18nFilter('translations.general_gui_deadline'),
                alignment: 'left',
                dataType: "date",
                dataField: 'deadline',
                allowSorting: true,
                format: "dd.MM.yyyy",
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                dataType: 'string',
                allowSorting: false,
                cellTemplate: 'learnerPointsActionTemplate'
            }
        ];

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();
                clearFilterAndSort();

                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterApplayer(loadOptions.filter);
                        }
                        //Filter za datume
                        else {
                            dateFilterApplayer(loadOptions.filter)
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterApplayer(filterElement);
                                }
                                //Filter za datume
                                else {
                                    dateFilterApplayer(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    sortApplayer(loadOptions.sort);
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken
                
                return d.promise();
            }
        };
        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };


        function clearFilterAndSort() {
            vm.options.firstName.filterType1 = undefined;
            vm.options.firstName.searchText1 = undefined;
            vm.options.firstName.filterType2 = undefined;
            vm.options.firstName.searchText2 = undefined;
            vm.options.firstName.sortType = undefined;

            vm.options.lastName.filterType1 = undefined;
            vm.options.lastName.searchText1 = undefined;
            vm.options.lastName.filterType2 = undefined;
            vm.options.lastName.searchText2 = undefined;
            vm.options.lastName.sortType = undefined;

            vm.options.email.filterType1 = undefined;
            vm.options.email.searchText1 = undefined;
            vm.options.email.filterType2 = undefined;
            vm.options.email.searchText2 = undefined;
            vm.options.email.sortType = undefined;

            vm.options.learningPlanItemStatus.filterType1 = undefined;
            vm.options.learningPlanItemStatus.searchText1 = undefined;
            vm.options.learningPlanItemStatus.filterType2 = undefined;
            vm.options.learningPlanItemStatus.searchText2 = undefined;
            vm.options.learningPlanItemStatus.sortType = undefined;

            vm.options.learningPeriod.filterType1 = undefined;
            vm.options.learningPeriod.searchText1 = undefined;
            vm.options.learningPeriod.filterType2 = undefined;
            vm.options.learningPeriod.searchText2 = undefined;
            vm.options.learningPeriod.sortType = undefined;

            vm.options.enrollmentDate.filterType1 = undefined;
            vm.options.enrollmentDate.searchText1 = undefined;
            vm.options.enrollmentDate.filterType2 = undefined;
            vm.options.enrollmentDate.searchText2 = undefined;
            vm.options.enrollmentDate.sortType = undefined;
            
            vm.options.deadline.filterType1 = undefined;
            vm.options.deadline.searchText1 = undefined;
            vm.options.deadline.filterType2 = undefined;
            vm.options.deadline.searchText2 = undefined;
            vm.options.deadline.sortType = undefined;
        }

        function sortApplayer(sort) {
            if (sort[0].selector == "firstName") {
                vm.options.firstName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "lastName") {
                vm.options.lastName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "learningPlanItemStatus") {
                vm.options.learningPlanItemStatus.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "email") {
                vm.options.email.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "learningPeriod.id") {
                vm.options.learningPeriod.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "enrollmentDate") {
                vm.options.enrollmentDate.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector == "deadline") {
                vm.options.deadline.sortType = sort[0].desc ? 'desc' : 'asc';
            }
        }

        function simpleFilterApplayer(element) {
            if (element[0] == "firstName") {
                vm.options.firstName.filterType1 = element[1];
                vm.options.firstName.searchText1 = element[2];
            }
            if (element[0] == "lastName") {
                vm.options.lastName.filterType1 = element[1];
                vm.options.lastName.searchText1 = element[2];
            }
            if (element[0] == "email") {
                vm.options.email.filterType1 = element[1];
                vm.options.email.searchText1 = element[2];
            }
            if (element[0] === 'learningPlanItemStatus') {
                vm.options.learningPlanItemStatus.filterType1 = element[1];
                vm.options.learningPlanItemStatus.searchText1 = element[2];
            }
            if (element[0] === 'learningPeriod.id') {
                vm.options.learningPeriod.filterType1 = element[1];
                vm.options.learningPeriod.searchText1 = element[2];
            }
            if (element[0] === 'enrollmentDate') {
                vm.options.enrollmentDate.filterType1 = element[1];
                vm.options.enrollmentDate.searchText1 = element[2];
            }
            if (element[0] === 'deadline') {
                vm.options.deadline.filterType1 = element[1];
                vm.options.deadline.searchText1 = element[2];
            }
        }

        function dateFilterApplayer(element) {            
            
            if (element[0][0] === 'enrollmentDate' && element[2][0] === 'enrollmentDate') {
                vm.options.enrollmentDate.filterType1 = element[0][1];
                vm.options.enrollmentDate.searchText1 = element[0][2];
                vm.options.enrollmentDate.filterType2 = element[2][1];
                vm.options.enrollmentDate.searchText2 = element[2][2];
            }
            if (element[0][0] === 'deadline' && element[2][0] === 'deadline') {
                vm.options.deadline.filterType1 = element[0][1];
                vm.options.deadline.searchText1 = element[0][2];
                vm.options.deadline.filterType2 = element[2][1];
                vm.options.deadline.searchText2 = element[2][2];
            }
        }



        //#endregion

        function getDefaultPeriod() {
            var defaultPeriod = undefined;
            angular.forEach(vm.periods, function (element, index) {
                if (element.isDefault) {
                    defaultPeriod = element;
                }
            });
            return defaultPeriod;
        }

        function rowClick(learner) {
            vm.learnerDetails = learner;
        }

           
    }
})();
