(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('learningActivityStatusFilter', learningActivityStatus);

    learningActivityStatus.$inject = ['logger', 'learningActivityState', 'i18nFilter'];


    function learningActivityStatus(logger, learningActivityState, i18nFilter) {
        return function (input) {
            var value = Number(input)
            
            if (value == learningActivityState.finished) {
                return i18nFilter('translations.filter_learning_activity_status_finished');
            }
            else if (value == learningActivityState.started) {
                return i18nFilter('translations.filter_learning_activity_status_in_progress');
            }
            return '';
        };
    }
})();