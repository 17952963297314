(function () {
    "use strict";
    angular
        .module("app.schedulePacks")
        .controller("EditContractDialog", EditContractDialog);

    EditContractDialog.$inject = [
        "$uibModalInstance",
        "dateHelper",
        "budgetEntities",
        "usersDataService",
        "logger",
        "contractsDataService",
        "exceptionHelper",
        "organizationsDataService",
        "i18nFilter",
        "$scope",
        "instructor",
        "parentScope",
        "budgetsDataService",
    ];

    function EditContractDialog(
        $uibModalInstance,
        dateHelper,
        budgetEntities,
        usersDataService,
        logger,
        contractsDataService,
        exceptionHelper,
        organizationsDataService,
        i18nFilter,
        $scope,
        instructor,
        parentScope,
        budgetsDataService
    ) {
        var modalvm = this;

        modalvm.cancel = cancel;
        modalvm.save = save;
        modalvm.add = add;
        modalvm.parentScope = parentScope;

        modalvm.areDatesValid = areDatesValid;
        modalvm.searchOrganizations = searchOrganizations;
        modalvm.clearOrganizationSelection = clearOrganizationSelection;
        modalvm.startDateValidate = startDateValidate;
        modalvm.endDateValidate = endDateValidate;
        modalvm.refreshUsers = refreshUsers;

        modalvm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter(
                "translations.general_gui_async_ddl_placeholder_select"
            ),
            selectPlaceholder: i18nFilter(
                "translations.general_gui_placeholder_select"
            ),
        };

        modalvm.instructorForContract =
            modalvm.parentScope.schedule.instructors.filter(
                (instructori) => instructori.instructor.id === instructor
            )[0];

        modalvm.searchBudgets = searchBudgets;
        modalvm.clearBudgetelection = clearBudgetSelection;
        modalvm.onBudgetSelected = onBudgetSelected;
        modalvm.orderDateValidate = orderDateValidate;
        modalvm.setBudgetAmount = setBudgetAmount;
        modalvm.setAmount = setAmount;

        modalvm.formData = {
            entities: budgetEntities,
            signingTimeConfig: {
                type: "date",
                width: "100%",
                inputAttr: {
                    name: "signingDate",
                    "ng-required": "false",
                },
                bindingOptions: {
                    value: "modalvm.item.signingDate",
                },
            },
            startTimeConfig: {
                type: "date",
                width: "100%",
                onValueChanged: function (e) {
                    modalvm.endDatevalidator.validate();
                },
                inputAttr: {
                    name: "startDate",
                    "ng-required": "true",
                },
                bindingOptions: {
                    value: "modalvm.item.start",
                },
            },
            startDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_start_date_is_either_blank_or_invalid"
                        ),
                    },
                    {
                        type: "custom",
                        validationCallback: modalvm.startDateValidate,
                        reevaluate: true,
                    },
                ],
                onInitialized: function (e) {
                    modalvm.startDatevalidator = e.component;
                },
            },
            endTimeConfig: {
                type: "date",
                width: "100%",
                onValueChanged: function (e) {
                    modalvm.startDatevalidator.validate();
                },
                inputAttr: {
                    name: "endDate",
                    "ng-required": "true",
                },
                bindingOptions: {
                    value: "modalvm.item.end",
                },
            },
            endDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_end_date_is_either_blank_or_invalid"
                        ),
                    },
                    {
                        type: "custom",
                        validationCallback: modalvm.endDateValidate,
                        reevaluate: true,
                    },
                ],
                onInitialized: function (e) {
                    modalvm.endDatevalidator = e.component;
                },
            },
            orderDateConfig: {
                type: "date",
                width: "100%",
                onValueChanged: function (e) {
                    modalvm.orderDateValidate.validate();
                },
                inputAttr: {
                    name: "orderDate",
                    "ng-required": "true",
                },
                bindingOptions: {
                    value: "vm.item.orderDate",
                },
            },
            orderDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_order_date_is_either_blank_or_invalid"
                        ),
                    },
                    {
                        type: "custom",
                        validationCallback: modalvm.startDateValidate,
                        reevaluate: true,
                    },
                ],
                onInitialized: function (e) {
                    modalvm.orderDateValidate = e.component;
                },
            },

            organizations: [],
            usersIsLoading: false,
            organizationsIsLoading: false,
            budgets: [],
            budgetsIsLoading: false,
            contractorsFromDbIsLoading: false,
            contractTemplates: [],
            contractTemplatesIsLoading: false,
        };

        function translateExecutionStyles(executionStyles) {
            executionStyles.forEach(function (executionStyle) {
                executionStyle.name = i18nFilter(executionStyle.name);
            });
        }

        function startDateValidate(options) {
            if (
                modalvm.item.end != "" &&
                modalvm.item.end != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) >
                    dateHelper.createNewDateTimeWithoutTime(modalvm.item.end)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_start_date_should_be_less_than_end_date"
                );
                return false;
            }

            return true;
        }

        function endDateValidate(options) {
            if (
                modalvm.item.start != "" &&
                modalvm.item.start != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) <
                    dateHelper.createNewDateTimeWithoutTime(modalvm.item.start)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_end_date_should_be_greater_than_start_date"
                );
                return false;
            }

            return true;
        }

        function areDatesValid() {
            if (!modalvm.item.start || !modalvm.item.end) {
                return false;
            }
            var start = modalvm.item.start.getTime();
            var end = modalvm.item.end.getTime();

            return start <= end;
        }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                modalvm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            modalvm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(
                function (data) {
                    modalvm.formData.organizations = data;
                    modalvm.formData.organizationsIsLoading = false;
                },
                function (error) {
                    modalvm.formData.organizationsIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function clearOrganizationSelection() {
            modalvm.item.organization = undefined;
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];

            usersDataService.searchForActiveUsers(query, userIds).then(
                function (data) {
                    modalvm.formData.users = data;
                    modalvm.formData.usersIsLoading = false;
                },
                function (error) {
                    modalvm.formData.usersIsLoading = false;
                    logger.error(
                        i18nFilter(
                            "translations.contract_exception_message_fetching_users"
                        )
                    );
                }
            );
        }

        if (modalvm.instructorForContract.hasActiveContract) {
            contractsDataService
                .getContractById(modalvm.instructorForContract.contractId)
                .then(function (res) {
                    if (res.amount != null)
                        res.amount = res.amount.toString().replace(".", ",");
                    modalvm.item = res;
                });
        }
        // instructor with no active contract for this edu
        else if (
            !modalvm.instructorForContract.hasActiveContract &&
            !modalvm.instructorForContract.hasOwnProperty("contractId")
        ) {
            modalvm.item = {};
            modalvm.item.contractors = [
                modalvm.instructorForContract.instructor,
            ];
            var end = new Date(parentScope.schedule.endTime);
            end.setDate(end.getDate() + 1);

            modalvm.item.start = parentScope.schedule.startTime;
            modalvm.item.end = end;
            modalvm.item.signingDate = new Date();
            var entityToAdd = budgetEntities.filter(function (entity) {
                return entity.id == parentScope.education.id;
            })[0];

            modalvm.item.entities = [
                {
                    id: entityToAdd.id,
                    name: entityToAdd.name,
                    code: entityToAdd.code,
                    type: entityToAdd.type,
                },
            ];
        } else {
            contractsDataService
                .getContractById(modalvm.instructorForContract.contractId)
                .then(function (res) {
                    modalvm.item = res;
                    if (res.amount != null)
                        modalvm.item.amount = modalvm.item.amount
                            .toString()
                            .replace(".", ",");
                });
        }

        function add() {
            logger.log("Add: ", modalvm.item);

            var itemCopy = angular.copy(modalvm.item);
            if (itemCopy.amount != null && itemCopy.amount != undefined)
                itemCopy.amount = parseFloat(itemCopy.amount.replace(",", "."));

            contractsDataService.addContract(itemCopy).then(
                function (data) {
                    logger.success(
                        i18nFilter("translations.contract_queue_add")
                    );
                    $uibModalInstance.close("added");
                },
                function (error) {
                    exceptionHelper.handleException(error);
                    $uibModalInstance.dismiss();
                }
            );
        }

        function save() {
            logger.log("Save: ", modalvm.item);
            var itemCopy = angular.copy(modalvm.item);
            if (itemCopy.amount != null && itemCopy.amount != undefined)
                if (itemCopy.amount.toString().includes(",")) {
                    itemCopy.amount = parseFloat(
                        itemCopy.amount.toString().replace(",", ".")
                    );
                } else {
                    itemCopy.amount.toString().concat(".00");
                }

            contractsDataService.editContract(itemCopy).then(
                function (data) {
                    logger.success(
                        i18nFilter("translations.contract_queue_edit")
                    );
                    $uibModalInstance.close("saved");
                },
                function (error) {
                    exceptionHelper.handleException(error);
                    $uibModalInstance.dismiss();
                }
            );
        }

        function cancel() {
            $scope.$dismiss();
        }

        function searchBudgets(query) {
            if (!query || query === "") {
                modalvm.formData.budgets = [];
                return;
            }

            modalvm.formData.budgetsIsLoading = true;
            budgetsDataService.searchActiveBudgets(query).then(
                function (data) {
                    modalvm.formData.budgets = data;
                    modalvm.formData.budgetsIsLoading = false;
                },
                function (error) {
                    modalvm.formData.budgetsIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function onBudgetSelected() {
            modalvm.item.budgetAmount = modalvm.item.budget.budgetAmount;
            modalvm.item.budgetAmountInWords =
                modalvm.item.budget.budgetAmountInWords;
            modalvm.item.orderDate = modalvm.item.budget.orderDate;
            modalvm.item.amount = modalvm.item.budget.budgetAmount;
            modalvm.item.orderNumber = modalvm.item.budget.orderNumber;
        }

        function clearBudgetSelection() {
            modalvm.item.budget = undefined;
        }

        function orderDateValidate(options) {
            if (
                modalvm.item.orderDate != "" &&
                modalvm.item.orderDate != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) >
                    dateHelper.createNewDateTimeWithoutTime(vm.item.orderDate)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_start_date_should_be_less_than_end_date"
                );
                return false;
            }

            return true;
        }

        function setBudgetAmount() {
            modalvm.item.budgetAmount = modalvm.item.amount;
            modalvm.item.budgetAmountInWords = null;
        }

        function setAmount() {
            modalvm.item.amount = modalvm.item.budgetAmount;
            modalvm.item.budgetAmountInWords = null;
        }
    }
})();
