(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('deleteCatalogue', deleteCatalogue);

    deleteCatalogue.$inject = ['logger', '$stateParams', 'cataloguesDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteCatalogue(logger, $stateParams, cataloguesDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            cataloguesDataService.deleteCatalogue($stateParams.catalogueId).then(function (data) {
                logger.success(i18nFilter("translations.exam_catalogues_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
