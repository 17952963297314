(function () {
    'use strict';

    angular
        .module('app.programs.administration.learners')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('programs.administration.learners', {
                url: '/learners',
                templateUrl: 'app/programs/administration/learners/learners.html',
                controller: 'ProgramLearners',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {
                    
                }
            });
    }
})();
