(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('SchedulePackRecords', records);

    records.$inject = [
        '$http',
        'logger',        
        'i18nFilter',
        'dateFilter',
        'schedulesDataService',
        '$stateParams',
        'exceptionHelper',        
        'rolesDataService',
        '$uibModal',
        'documentsDataService',
        'schedulePack',
        '$sessionStorage',
        'templatesDataService',
        'documentTriggersAndEvents',
        '$state',
        'isUserInstructorInAnySchedule',
        'isUserOwnerOfEntityInsideSchedulePack'
    ];

    function records(
        $http,
        logger,
        i18nFilter,
        dateFilter,
        schedulesDataService,
        $stateParams,
        exceptionHelper,
        rolesDataService,
        $uibModal,
        documentsDataService,
        schedulePack,        
        $sessionStorage,
        templatesDataService,
        documentTriggersAndEvents,
        $state,
        isUserInstructorInAnySchedule,
        isUserOwnerOfEntityInsideSchedulePack
    ) {
        var vm = this;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            selectAsyncPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select")
        };

        vm.formData = {
            schedulePack: schedulePack,
            instructorRecords: [],

            selectedScheduleGroup: undefined,
            selectedSchedule: undefined,

            certificateOfEmploymentId: undefined,
            userId: $sessionStorage.userInfo.userId,
            userHasAdminRole: rolesDataService.isUserInRoles(['EducationAdmin', 'CatalogAdmin']),
            isUserInstructorInAnySchedule: isUserInstructorInAnySchedule,
            isUserOwnerOfEntityInsideSchedulePack: isUserOwnerOfEntityInsideSchedulePack,
        };

        vm.showDetails = showDetails;
        vm.downloadCertificateOfEmployment = downloadCertificateOfEmployment;
        vm.uploadCertificateOfEmployment = uploadCertificateOfEmployment;
        vm.getFormTitle = getFormTitle;

        function getFormTitle() {
            var name = null;
            if (vm.formData.schedulePack.programName == null)
                name = vm.formData.schedulePack.educationName;
            else
                name = vm.formData.schedulePack.programName;
            var from = dateFilter(vm.formData.schedulePack.startTime, 'shortDate');
            var to = dateFilter(vm.formData.schedulePack.endTime, 'shortDate');
            if (from === to) {
                return name + ' ' + from;
            } else {
                return name + ' ' + from + ' - ' + to;
            }
        }

        //#region Disable schedule where user has no access to be 
        _.forEach(vm.formData.schedulePack.schedules, function (schedule) {
            var userIsInstructor = _.some(schedule.instructors, function (instructor) { return instructor.id === $sessionStorage.userInfo.userId; });
            var userIsOwner = _.some(schedule.educationOwners, function (owner) { return owner.id === $sessionStorage.userInfo.userId; });
            schedule.enabled = userIsOwner || userIsInstructor || rolesDataService.isUserInRoles(['EducationAdmin', 'CatalogAdmin']);
        });
        //#endregion


        //#region Select schedule from route
        if ($stateParams.scheduleId) {
            var schedule = _.find(vm.formData.schedulePack.schedules, function (schedule) {
                return schedule.id === $stateParams.scheduleId;
            });

            showDetails(schedule);
        }
        else {
            var schedule = _.find(vm.formData.schedulePack.schedules, function (schedule) {
                return schedule.enabled;
            });

            showDetails(schedule);
        }
        //#endregion

        //#region Filter
        function showDetails(schedule) {
            vm.formData.selectedSchedule = schedule;
            $stateParams.scheduleId = vm.formData.selectedSchedule.id;
            $state.go('schedulePacks.records', $stateParams, { reload: false, notify: false });

            schedulesDataService.getInstructorsCertificateOfEmploymentsRecordsForSchedule(schedule.id).then(function (data) {
                vm.formData.instructorRecords = data;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
        //#endregion
       
        function uploadCertificateOfEmployment(instructor) {
            var modal = $uibModal.open({
                templateUrl: 'app/schedulePacks/records/uploadCertificateOfEmployment/uploadCertificateOfEmployment.html',
                controller: 'uploadCertificateOfEmployment',
                controllerAs: 'vm',
                resolve: {
                    scheduleId: [function () { return vm.formData.selectedSchedule.id }],
                    instructor: [function () { return instructor }]
                }
            });

            modal.result.then(function () {
                //Ok
            }, function () {
                //Fail
            });
        }

        function downloadCertificateOfEmployment(id, fileName) {
            documentsDataService.downloadDocument('/api/schedules/' + vm.formData.selectedSchedule.id + '/instructorCertificateOfEmployment/' + id, fileName);
        }
        //#endregion

    }
})();
