(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('templatesDataService', templates);

    templates.$inject = ['$http', 'logger', 'locale', '$q'];

    function templates($http, logger, locale, $q) {
        var service = {
            getTemplates: getTemplates,
            getAllActiveTemplates: getAllActiveTemplates,
            getTemplateById: getTemplateById,
            addTemplate: addTemplate,
            editTemplate: editTemplate,
            deleteTemplate: deleteTemplate,
            getEducationTemplateId: getEducationTemplateId,
            getListOfActiveTemplatesByScheduleId: getListOfActiveTemplatesByScheduleId,
            getListOfActiveTemplatesByLearnerId: getListOfActiveTemplatesByLearnerId,
            getTemplateVariables: getTemplateVariables,
            getListOfSchoolingTemplates: getListOfSchoolingTemplates
        };

        return service;


        function getTemplates() {
            return $http.get('api/templates').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveTemplates() {
            return $http.get('api/templates/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTemplateById(id) {
            return $http.get('api/templates/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addTemplate(item) {
            return $http.post('api/templates', item).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editTemplate(item) {

            return $http.put('api/templates', item).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        } 

        function deleteTemplate(templateId) {
            return $http.delete('api/templates/' + templateId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationTemplateId(educationId, documentEventTrigger) {
            return $http.get('api/templates/' + educationId + '/documentEventTrigger/' + documentEventTrigger).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getListOfActiveTemplatesByScheduleId(scheduleId) {
            return $http.get('api/templates/' + scheduleId + '/listOfActiveByScheduleId').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getListOfActiveTemplatesByLearnerId(learnerId) {
            return $http.get('api/templates/' + learnerId + '/listOfActiveByLearnerId').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTemplateVariables(documentEventTrigger) {
            return $http.get('api/templates/' + documentEventTrigger + '/templateVariables/').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }        

        function getListOfSchoolingTemplates(schoolingId) {
            return $http.get('api/templates/' + schoolingId + '/schoolingTemplates').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
