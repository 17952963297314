(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('toggle', toggleButton);

    toggleButton.$inject = ['$window'];
    
    function toggleButton ($window) {
        // Usage:
        //     <toggle></toggle>
        // Creates:
        // 
        var directive = {
            restrict: 'E',
            templateUrl: 'app/blocks/directives/toggle/toggleButton.html',
            link: link,
            scope: {
                model: '@'
            }
        };
        return directive;

        function link(scope, element, attrs) {
            scope.model = attrs.model;
        }
    }

})();