(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackDocuments', examSchedulePackDocuments)
        .filter('contains', function () {
            return function (array, needle) {
                return array.indexOf(needle) >= 0;
            };
        });
            examSchedulePackDocuments.$inject = ['$state', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', 'examApplication', 'examApplicationsDataService', 'documentsDataService', 'activeTemplates', 'templatesDataService'];

            function examSchedulePackDocuments($state, $scope, i18nFilter, exceptionHelper, logger, examApplication, examApplicationsDataService, documentsDataService, activeTemplates, templatesDataService) {
                var vm = this;

                examApplication.applicationStatus
                vm.generateExamRejectedApplication = generateExamRejectedApplication;
                vm.examApplication = examApplication;
                vm.generatePDIDecisionExemptedFromTheAdministrationAndTheEU = generatePDIDecisionExemptedFromTheAdministrationAndTheEU;
                //vm.generateSIC1DecisionFulfillmentOfConditions = generateSIC1DecisionFulfillmentOfConditions;
                vm.generatePdiDecisionResignationWritingAndApproach = generatePdiDecisionResignationWritingAndApproach;
                vm.generatePdiDecisionRepeatCivilWrittenExam = generatePdiDecisionRepeatCivilWrittenExam;
                vm.generatePdiSchedule = generatePdiSchedule;
                vm.generateSIC1CommissionRecord = generateSIC1CommissionRecord;
                vm.generateSIC1certificateValuer = generateSIC1certificateValuer;
                vm.generatePDICertificateOfPassedExam = generatePDICertificateOfPassedExam;
                vm.generateUPR1DecisionRepeatVerbalExam = generateUPR1DecisionRepeatVerbalExam;
                vm.generatePDIDecisionNegativeWrittenExams = generatePDIDecisionNegativeWrittenExams;
                vm.generatePDICheckingDiplomaForm = generatePDICheckingDiplomaForm;
                vm.generatePDIInviteForAward = generatePDIInviteForAward;
                //vm.generatePDICertificateAward = generatePDICertificateAward;
                vm.generatePDICertificateGrades = generatePDICertificateGrades;
                vm.generatePDIDecisionWithdrawalOfRequests = generatePDIDecisionWithdrawalOfRequests;
                vm.generateSIC1NoticeOutcomeAndOptions = generateSIC1NoticeOutcomeAndOptions;
                vm.generatePDIExamCommissionReallocation = generatePDIExamCommissionReallocation;
                vm.generateMJU13 = generateMJU13;
                vm.generateMJU24 = generateMJU24;
                vm.generateMJU34 = generateMJU34;
                vm.generateMJU35 = generateMJU35;
                vm.formData = {
                    documentsExpanded: true,
                    selectedSchedule: undefined,
                    examApplication: examApplication,
                    activeTemplates: activeTemplates
                }

                function generateExamRejectedApplication() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/examRejectedApplicationDocument', i18nFilter('translations.exams_documents_label_for_reject_applicationTemplate') + '.docx').then(function (data) {
                        GenerateExamRejectedApplicationDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generatePDIDecisionExemptedFromTheAdministrationAndTheEU() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiDecisionExemptedFromTheAdministrationAndTheEUDocument', i18nFilter('translations.exams_documents_label_for_pdiDecisionExemptedFromTheAdministrationAndTheEUTemplate') + '.docx').then(function (data) {
                        GeneratePDIDecisionExemptedFromTheAdministrationAndTheEUAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                /*function generateSIC1DecisionFulfillmentOfConditions() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/sic1DecisionFulfillmentOfConditions', i18nFilter('translations.exams_documents_label_for_sic1DecisionFulfillmentOfConditionsTemplate') + '.docx').then(function (data) {
                        GenerateSIC1DecisionFulfillmentOfConditionsAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }*/
                function generatePdiDecisionResignationWritingAndApproach() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiDecisionResignationWritingAndApproach', i18nFilter('translations.exams_documents_label_for_pdiDecisionResignationWritingAndApproachTemplate') + '.docx').then(function (data) {
                        GeneratePdiDecisionResignationWritingAndApproachDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePdiDecisionRepeatCivilWrittenExam() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiDecisionRepeatCivilWrittenExam', i18nFilter('translations.exams_documents_label_for_pdiDecisionRepeatCivilWrittenExamTemplate') + '.docx').then(function (data) {
                        GeneratePdiDecisionRepeatCivilWrittenExamDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePdiSchedule() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiSchedule', i18nFilter('translations.exams_documents_label_for_pdiScheduleTemplate') + '.docx').then(function (data) {
                        GeneratePdiScheduleDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generateSIC1CommissionRecord() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/sic1CommissionRecord', i18nFilter('translations.exams_documents_label_for_sic1CommissionRecordTemplate') + '.docx').then(function (data) {
                        GenerateSIC1CommissionRecordDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generateSIC1certificateValuer() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/sic1certificateValuer', i18nFilter('translations.exams_documents_label_for_sic1certificateValuerTemplate') + '.docx').then(function (data) {
                        GenerateSIC1certificateValuerDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDICertificateOfPassedExam() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiCertificateOfPassedExam', i18nFilter('translations.exams_documents_label_for_pdiCertificateOfPassedExamTemplate') + '.docx').then(function (data) {
                        GeneratePDICertificateOfPassedExamDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generateUPR1DecisionRepeatVerbalExam() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/upr1DecisionRepeatVerbalExam', i18nFilter('translations.exams_documents_label_for_upr1DecisionRepeatVerbalExamTemplate') + '.docx').then(function (data) {
                        GenerateUPR1DecisionRepeatVerbalExamDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDIDecisionNegativeWrittenExams() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiDecisionNegativeWrittenExams', i18nFilter('translations.exams_documents_label_for_pdiDecisionNegativeWrittenExamsTemplate') + '.docx').then(function (data) {
                        GeneratePDIDecisionNegativeWrittenExamsDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDICheckingDiplomaForm() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiCheckingDiplomaForm', i18nFilter('translations.exams_documents_label_for_pdiCheckingDiplomaFormTemplate') + '.docx').then(function (data) {
                        GeneratePDICheckingDiplomaFormDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDIInviteForAward() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiInviteForAward', i18nFilter('translations.exams_documents_label_for_pdiInviteForAwardTemplate') + '.docx').then(function (data) {
                        GeneratePDIInviteForAwardDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                /*function generatePDICertificateAward() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiCertificateAward', i18nFilter('translations.exams_documents_label_for_pdiCertificateAwardTemplate') + '.docx').then(function (data) {
                        GeneratePDICertificateAwardDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }*/ 
                function generatePDICertificateGrades() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiCertificateGrades', i18nFilter('translations.exams_documents_label_for_pdiCertificateGradesTemplate') + '.docx').then(function (data) {
                        GeneratePDICertificateGradesDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDIDecisionWithdrawalOfRequests() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiDecisionWithdrawalOfRequests', i18nFilter('translations.exams_documents_label_for_pdiDecisionWithdrawalOfRequestsTemplate') + '.docx').then(function (data) {
                        GeneratePDIDecisionWithdrawalOfRequestsDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generateSIC1NoticeOutcomeAndOptions() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/sic1NoticeOutcomeAndOptions', i18nFilter('translations.exams_documents_label_for_sic1NoticeOutcomeAndOptionsTemplate') + '.docx').then(function (data) {
                        GenerateSIC1NoticeOutcomeAndOptionsDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                } 
                function generatePDIExamCommissionReallocation() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/pdiExamCommissionReallocation', i18nFilter('translations.exams_documents_label_for_pdiExamCommissionReallocationTemplate') + '.docx').then(function (data) {
                        GeneratePDIExamCommissionReallocationDocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generateMJU13() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/mju13', i18nFilter('translations.exams_documents_label_for_MJU13Template') + '.docx').then(function (data) {
                        GenerateMJU13DocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generateMJU24() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/mju24', i18nFilter('translations.exams_documents_label_for_MJU24Template') + '.docx').then(function (data) {
                        GenerateMJU24DocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generateMJU34() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/mju34', i18nFilter('translations.exams_documents_label_for_MJU34Template') + '.docx').then(function (data) {
                        GenerateMJU34DocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
                function generateMJU35() {
                    documentsDataService.downloadDocument('/api/examApplications/' + vm.examApplication.id + '/mju35', i18nFilter('translations.exams_documents_label_for_MJU35Template') + '.docx').then(function (data) {
                        GenerateMJU35DocumentAsync
                    }, function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: { exceptionCode: -90007, operationCode: 0 }
                            },
                            status: 400
                        };
                        exceptionHelper.handleException(error);
                    });
                }
            }
        })();
