(function () {
    'use strict';

    angular
        .module('app')
        .factory('programDocumentsDataService', documents);

    documents.$inject = ['$http', 'logger', '$q', '$window'];

    function documents($http, logger, $q, $window) {
        var service = {
            getDocuments: getDocuments,
            deleteItem: deleteItem,
            renameItem: renameItem,
            downloadFile: downloadFile,
            downloadDocument: downloadDocument,
            newFolder: newFolder,
            uploadFile: uploadFile,
            addLink: addLink,
            openInNewTab: openInNewTab,
            editLink: editLink,
            getProgramDocumentsRelativePath: getProgramDocumentsRelativePath,
            regenerateCertificate: regenerateCertificate
        };

        return service;

        function getDocuments(path) {

            var body = {
                path: path
            };

            return $http.post('api/programDocumentsManager/getDocumentsList', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteItem(path) {
            var body = {
                path: path
            };

            return $http.post('api/programDocumentsManager/remove', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function renameItem(path, newName) {
            var body = {
                path: path,
                name: newName
            };

            return $http.post('api/programDocumentsManager/rename', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editLink(toDeletePath, toCreatePath, url, name) {
            return deleteItem(toDeletePath).then(function () {
                return addLink(toCreatePath, url, name);
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function openInNewTab(path) {            
            $window.open(path);
        }

        function downloadFile(path) {

            var filename = path.substring(path.lastIndexOf('/') + 1);

            var body = {
                path: path
            };


            return $http.post("api/programDocumentsManager/downloadFile", body, {
                responseType: 'arraybuffer'
            }).success(function (data, status, headers) {
                var ua = new UAParser();
                var browser = ua.getBrowser();

                var contentType = headers["content-type"] || "application/octet-stream";
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    var blob = new Blob([data], { type: contentType });

                    if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    }
                    else {
                        var url = urlCreator.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.target = "_blank";
                        a.download = filename; //you may assign this value from header as well 
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
            });
        }

        function newFolder(path, name) {
            var body = {
                path: path,
                name: name
            };

            return $http.post('api/programDocumentsManager/createFolder', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addLink(path, url, name) {
            var body = {
                path: path,
                name: name,
                url: url
            };

            return $http.post('api/programDocumentsManager/addLink', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function uploadFile(path, fileId) {
            var body = {
                path: path,
                fileId: fileId
            };

            return $http.post('api/programDocumentsManager/upload', body).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function downloadDocument(path, fileName) {

            return $http.get(path, {
                responseType: 'arraybuffer'
            }).then(function (data, status, headers) {
                var ua = new UAParser();
                var browser = ua.getBrowser();
                var header = data.headers();

                if (fileName === null || fileName === undefined) {
                    fileName = header["fileName"];
                }

                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                if (urlCreator) {
                    var blob = new Blob([data.data]);

                    if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    }
                    else {
                        var url = urlCreator.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.target = "_blank";
                        a.download = fileName; //you may assign this value from header as well 
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getProgramDocumentsRelativePath(programId) {

            return 'programs\\' + programId + '\\documents';
        }

        function regenerateCertificate(learningPlanItemId) {
            return $http.get('api/learningPlans/' + learningPlanItemId + '/regenerateCertificate').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
