(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamFourthStep', fourthStep);

    fourthStep.$inject = ['logger', 'editExamsHelper', 'usersDataService', '$scope', '$state', '$stateParams', 'i18nFilter', '$sessionStorage'];

    function fourthStep(logger, editExamsHelper, usersDataService, $scope, $state, $stateParams, i18nFilter, $sessionStorage) {
        var vm = this;
        //Inherit from parent scope
        vm.navigation = $scope.navigation;

        vm.item = editExamsHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.logData = logData;
        vm.previous = previous;
        vm.next = next;
        vm.cancel = cancel;
        vm.refreshUsers = refreshUsers;
        vm.filterUniqueThemes = filterUniqueThemes;
        vm.activityWithNoThemes = activityWithNoThemes;
        vm.PreparersEvaluatorsRequired = false;
        vm.save = save;

        if ($sessionStorage.userInfo.tenantResourceKey == "eua") {
            vm.tenantEUA = true;
        } else {
            vm.tenantEUA = false;
        }

        if (vm.item.activity != null && vm.item.activity.length > 0 && vm.item.activity.filter(examActivity => examActivity.activityType === 3).length != 0) {
            vm.PreparersEvaluatorsRequired = true;
        }
  

        if (typeof vm.item.committeMembersThemes === "undefined" || vm.item.committeMembersThemes === null) {
            vm.item.committeMembersThemes = [];
        }

        vm.formData = {
            users: [],
            usersIsLoading: false,
            uniqueThemes: filterUniqueThemes()
        };

        vm.localeMessages = {
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select")
        };

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function previous() {
            $state.go(vm.navigation[2].state, $stateParams);
        }

        function next() {
            $state.go(vm.navigation[4].state, $stateParams);
        }

        function save() {
            var item_copy = editExamsHelper.setCodeListData(vm.item);
            editExamsHelper.setData(item_copy);
            editExamsHelper.done(vm.isEdit, true);
        } 

        function filterUniqueThemes() {
            var themes = [];
            if (vm.item.activity != null && vm.item.activity.length > 0) {
                for (var i = 0; i < vm.item.activity.length; i++) {
                    if (vm.item.activity[i].themes.length > 0) {
                        themes = themes.concat(vm.item.activity[i].themes);
                    }
                }
            }
            var uniqueThemes = removeDuplicates(themes, "id");
            return uniqueThemes;
        }

        function removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject = {};

            for (var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }

            for (i in lookupObject) {
                newArray.push(lookupObject[i]);
            }

            //ensure data sequence
            if (vm.item.committeMembersThemes.length > 0) {
                for (var x = 0; x < newArray.length; x++) {
                    if (x < vm.item.committeMembersThemes.length) {
                        if (newArray[x].id !== vm.item.committeMembersThemes[x].themeId) {
                            if (newArray.filter(theme => theme.id !== vm.item.committeMembersThemes[x].themeId).length < newArray.length) {
                                vm.item.committeMembersThemes.splice(x, 0, null);
                            }
                            else {
                                vm.item.committeMembersThemes.splice(x, 1);
                                x--;
                            }
                        }
                    }
                }
            }

            return newArray;
        }

        function activityWithNoThemes() {
            if (vm.item.activity != null && vm.item.activity.length > 0) {
                for (var i = 0; i < vm.item.activity.length; i++) {
                    if ((typeof vm.item.activity[i].themes === "undefined" || vm.item.activity[i].themes.length === 0) && vm.item.activity[i].activityType !== 3) {
                        return true;
                    }
                }
            }
            return false;
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('exams.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.exam');
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }
    }
})();
