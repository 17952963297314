(function () {
    'use strict';

    angular
        .module('app.surveys')
        .controller('SurveyHome', surveyHome);

    surveyHome.$inject = ['logger', 'survey', '$sce'];

    function surveyHome(logger, survey, $sce) {
        var vm = this;
        vm.title = 'survey home';
        vm.survey = survey;

        vm.trustAsHtml = trustAsHtml;

        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        }
    }
})();
