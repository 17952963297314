(function () {
    'use strict';

    angular
        .module('app.surveys.administration')
        .controller('DeleteSurvey', deleteSurvey);

    deleteSurvey.$inject = ['logger', '$state', '$stateParams', '$scope', 'surveysDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteSurvey(logger, $state, $stateParams, $scope, surveysDataService, exceptionHelper, i18nFilter) {
        var vm = this;        
        vm.cancel = cancel;
        vm.ok = ok;

        vm.surveyName = $stateParams.surveyName;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            surveysDataService.deleteSurvey($stateParams.surveyId).then(function (result) {
                logger.success(i18nFilter('translations.survey_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
                $scope.$dismiss();
            });
        };
    }
})();
