(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('scrollIf', scrollIf);

    scrollIf.$inject = ['$window'];
    
    function scrollIf ($window) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            setTimeout(function () {
                if (scope.$eval(attrs.scrollIf)) {
                    window.scrollTo(0, element[0].offsetTop - 100)
                }
            });
        }
    }

})();