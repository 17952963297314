(function () {
    'use strict';

    angular
        .module('app.schoolings.home')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolings.home', {
                url: '/home',
                templateUrl: 'app/schoolings/home/home.html',
                controller: 'SchoolingHome',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner', 'CatalogAdmin', 'SchoolingOwner', 'Instructor']
                }
            })
            .state('schoolings.home.enroll', {
                url: '/enroll',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/schoolings/sidebars/info/enroll.html',
                        controller: 'EnrollToSchooling',
                        controllerAs: 'vm',
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('schoolings.home', { id: $stateParams.id }, { reload: true });
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('schoolings.home', { id: $stateParams.id }, { reload: true });
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['Learner']
                }
            })
            .state('schoolings.home.removeFromPlan', {
                url: '/removeFromPlan',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/schoolings/sidebars/info/removeFromPlan.html',
                        controller: 'RemoveFromUserLearningPlan',
                        controllerAs: 'vm',
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('schoolings.home', { id: $stateParams.id }, { reload: true });
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('schoolings.home', { id: $stateParams.id }, { reload: true });
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['Learner']
                }
            });
    }
})();
