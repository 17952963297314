(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('RejectedELearnerModalDialog', RejectedELearnerModalDialog);

    RejectedELearnerModalDialog.$inject = ['logger', 'i18nFilter', '$scope', 'exceptionHelper', 'schedulePacksDataService', 'selectedEducation'];

    function RejectedELearnerModalDialog(logger, i18nFilter, $scope, exceptionHelper, schedulePacksDataService, selectedEducation) {
        var modalvm = this;

        //data setup
        modalvm.item = { rejectLearnerReason: null };
        modalvm.defaultText = "";

        //functions setup
        modalvm.confirmEFormLearnerRejection = confirmEFormLearnerRejection;
        modalvm.cancel = cancel;

        //setup rejected reason using any predefined text
        modalvm.item.rejectLearnerReason = modalvm.defaultText;

        //#region Confirm, Reject functions
        function confirmEFormLearnerRejection() {
            $scope.$close(modalvm.item.rejectLearnerReason);
        }

        function cancel() {
            $scope.$dismiss();
        }
        //#endregion Confirm, Reject functions
    }

})();
