(function () {
    'use strict';

    angular
        .module('app')
        .factory('citiesDataService', cities);

    cities.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function cities($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {            
            getAllFilteredActiveCities: getAllFilteredActiveCities,
            getCities: getCities,
            getCityById: getCityById,
            deleteCity: deleteCity,
            addCity: addCity,
            editCity: editCity
        };

        return service;
                
        function getAllFilteredActiveCities(filter) {
            return $http.get('/api/cities/active/search/' + filter)
               .then(function (data, status, headers, config) {
                   return data.data;
               }, function (data, status, headers, config) {
                   if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
               });
        }

        function getCities() {
            return $http.get('api/cities').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCityById(id) {
            return $http.get('api/cities/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteCity(id) {
            return $http.delete('api/cities/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCity(city) {
            return $http.post('api/cities', city).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCity(city) {
            return $http.put('api/cities', city).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
