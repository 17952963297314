(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('activitiesDataService', activities);

    activities.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function activities($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllActiveEducationActivitiesSummaryForEducation: getAllActiveEducationActivitiesSummaryForEducation,
            addActivity: addActivity,
            editActivity: editActivity,
            getSchedulePackActivitiesByEducationId: getSchedulePackActivitiesByEducationId,

          
            moveUp: moveUp,
            moveDown: moveDown,
            deleteActivity: deleteActivity,
            getActivityById: getActivityById,
            getActivityTypes: getActivityTypes,
            deactivateActivity: deactivateActivity,

            getAddActivityStatistic: getAddActivityStatistic,
            getEditActivityStatistic: getEditActivityStatistic,
            
            getUserActivityEvents: getUserActivityEvents
        };

        return service;

        function getAllActiveEducationActivitiesSummaryForEducation(id) {
            return $http.get('api/educations/' + id + '/activities/active/summary').then(function (data) {
                //angular.forEach(data.data, function (shedule, index) {                   
                //    shedule.startTime = dateHelper.convertFromDateToString(shedule.startTime);
                //    shedule.endTime = dateHelper.convertFromDateToString(shedule.endTime);
                //});                
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
      

        function getSchedulePackActivitiesByEducationId(educationId) {
            return $http.get('api/educations/' + educationId + '/activities/synchronous').then(function (data) {                
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //#region Activity actions
        function confirmFinishGenericActivity(learningActivtiyId) {
            return $http.post('api/activities/learningActivities/' + learningActivtiyId + '/confirmFinishGeneric').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function confirmFinishHtml5Activity(learningActivtiyId) {
            return $http.post('api/activities/learningActivities/' + learningActivtiyId + '/confirmFinishHtml5').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function confirmFinishOfficeMixActivity(learningActivtiyId) {
            return $http.post('api/activities/learningActivities/' + learningActivtiyId + '/confirmFinishOfficeMix').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function confirmFinishVideoActivity(learningActivtiyId) {
            return $http.post('api/activities/learningActivities/' + learningActivtiyId + '/confirmFinishVideo').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //#endregion

        //#region Akcije na upravljanju aktivnostima 
        function moveUp(id) {
            return $http.post('api/activities/' + id + '/moveUp').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveDown(id) {
            return $http.post('api/activities/' + id + '/moveDown').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteActivity(id) {
            return $http.delete('api/activities/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deactivateActivity(id) {
            return $http.put('api/activities/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addActivity(activity) {
            return $http.post('api/activities', activity).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editActivity(activity) {
            return $http.put('api/activities', activity).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        //#endregion

        //#region Dohvat aktivnosti za edit "education/id/manage/activities"
        function getActivityById(id) {
            return $http.get('api/activities/' + id + '/edit').then(function (data) {
                parseDataInCorrectFormat(data.data);

                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function parseDataInCorrectFormat(activity) {
            switch (activity.activityType.id) {
                case activityTypes.officeMixActivityType:
                case activityTypes.surveyAcitvityType:
                case activityTypes.videoActivityType:
                case activityTypes.genericAcitvityType:
                    activity.startDate = dateHelper.convertFromStringToDate(activity.startDate);
                    activity.endDate = dateHelper.convertFromStringToDate(activity.endDate);
                    break;
            }
        }
        //#endregion


        function getActivityTypes() {
            return $http.get('api/activityTypes').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAddActivityStatistic(educationId) {
            return $http.get('api/activities/add/statistic/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEditActivityStatistic(educationActivityId) {
            return $http.get('api/activities/edit/statistic/' + educationActivityId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getUserActivityEvents(userId) {
            return $http.get('api/users/' + userId + ' /events').then(function (data) {
                angular.forEach(data.data, function (element, index) {
                    element.date = dateHelper.convertFromStringToDate(element.date);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch user activity events');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
