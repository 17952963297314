(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('educationActivityTypeFilter', educationActivityType);

    educationActivityType.$inject = ['logger', 'activityTypes', 'i18nFilter'];

    function educationActivityType(logger, activityTypes, i18nFilter) {
        return function (input) {
            var value = input;
            if (value == activityTypes.genericAcitvityType){
                return i18nFilter('translations.filter_education_activity_type_generic');
            }
            else if (value === activityTypes.virtualClassroomAcitvityType){
                return i18nFilter('translations.filter_education_activity_type_virtual');
            }
            else if (value === activityTypes.surveyAcitvityType) {
                return i18nFilter('translations.filter_education_activity_type_survey');
            }
            else if (value === activityTypes.examAcitvityType) {
                return i18nFilter('translations.filter_education_activity_type_exam');
            }
            else if (value === activityTypes.videoActivityType) {
                return i18nFilter('translations.filter_education_activity_type_video');
            }
            else if (value === activityTypes.scormActivityType) {
                return i18nFilter('translations.filter_education_activity_type_scorm');
            }
            else if (value === activityTypes.officeMixActivityType) {
                return i18nFilter('translations.filter_education_activity_type_office_mix');
            }
            else if (value === activityTypes.classroomActivityType) {
                return i18nFilter('translations.filter_education_activity_type_classroom');
            }
            else if (value === activityTypes.html5ActivityType) {
                return i18nFilter('translations.filter_education_activity_type_html5');
            }
            else if (value === activityTypes.eExamActivityType) {
                return i18nFilter('translations.filter_education_activity_type_eExam');
            }
            else if (value === activityTypes.eExamForEducationActivityType) {
                return i18nFilter('translations.filter_education_activity_type_eExamForEducation');
            }
        };
    }
})();
