(function () {
    'use strict';

    angular
        .module('app.countries')
        .controller('countries', countriesCtrl);

    countriesCtrl.$inject = ['logger', 'countries', 'i18nFilter'];

    function countriesCtrl(logger, countries, i18nFilter) {
        var vm = this;
        vm.countries = countries;

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowSorting: true
            },            
            {
                dataField: 'alpha2Code',
                caption: i18nFilter('translations.country_gui_alpha2Code'),
                alignment: 'left',
                width: 200,
                allowSorting: true
            },
            {
                dataField: 'alpha3Code',
                caption: i18nFilter('translations.country_gui_alpha3Code'),
                alignment: 'left',
                width: 200,
                allowSorting: true
            },
            {
                dataField: 'numericCode',
                caption: i18nFilter('translations.country_gui_numericCode'),
                alignment: 'left',
                width: 200,
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 300,
                allowSorting: false,
                cellTemplate: 'countriesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.countries,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

    }
})();
