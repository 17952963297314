(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('status', status);
    
        status.$inject = ['logger','i18nFilter'];

        function status(logger, i18nFilter) {
            return function (input) {
                var outActive = '<i class="fa fa-check"></i>';
                var outNotActive = '<i class="fa fa-times"></i>';
                var value = Number(input)
                if (value == 1) {
                    return i18nFilter('translations.general_status_active');
                    //return outActive;
                }
                return i18nFilter('translations.general_status_deactiveted');
                //return outNotActive;
            };
        }

})();