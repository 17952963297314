(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .component('educationBudgetPie', {
            templateUrl: 'app/schedulePacks/components/educationBudgetPie/educationBudgetPie.component.html',
            controller: educationBudgetPie,
            controllerAs: 'vm',
            bindings: {
                education: '=',
                isEdit: '='
            }
        });

    educationBudgetPie.$inject = ['logger', 'programSchedulePackHelper', '$scope', 'i18nFilter', 'exceptionHelper', 'budgetsDataService', 'decimalHelper'];

    function educationBudgetPie(logger, programSchedulePackHelper, $scope, i18nFilter, exceptionHelper, budgetsDataService, decimalHelper) {
        var vm = this;

        vm.api = {
            scheduleChanged: scheduleChanged,
            checkIfThereIsValidBudget: checkIfThereIsValidBudget
        };
        var count = 0;
        var totalReserved = 0;

        vm.formData = {
            budgetStatistics: undefined,
            firstScheduleDate: undefined,
            schedulesData: undefined,

            minusAmount: undefined,
            remainingAmount: undefined,
            totalReserved: undefined,
            totalSpent: undefined,
            total: undefined,

            isLoading: false,

            //Chart
            chartLegendTotal: i18nFilter('translations.schedule_budget_gui_chart_total'),
            chartData: [0, 0, 0],
            chartLabels: [
                i18nFilter('translations.schedule_budget_gui_chart_total_remaining'),
                i18nFilter('translations.schedule_budget_gui_chart_total_reserved'),
                i18nFilter('translations.schedule_budget_gui_chart_total_spent')
            ]
        }

        //#region API
        function scheduleChanged() {
            vm.formData.schedulesData = [];
            var allstartTimes = [];
            _.each(programSchedulePackHelper.getApisForKey('schedule-' + vm.education.id), function (api) {
                //Fetch budget pie data
                var schedulePieData = api.getBudgetPieData();
                vm.formData.schedulesData.push(schedulePieData);

                if (schedulePieData.startTime) {
                    allstartTimes.push(schedulePieData.startTime);
                }
            });

            //Filter out min startTime
            if (allstartTimes.length !== 0) {
                vm.formData.firstScheduleDate = new Date(Math.min.apply(null, allstartTimes));
            }

            if (checkIfFirstScheduleIsInRangeOfLocalBudget()) {
                setBudgetData();
            }
            else {
                getBudget()
            }
        }

        function checkIfThereIsValidBudget() {
            if (vm.formData.budgetStatistics && vm.formData.budgetStatistics.budget) {
                return true;
            }
            return false;
        }

        programSchedulePackHelper.registerDirective('pie-' + vm.education.id, vm.api)

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective('pie-' + vm.education.id, vm.api.id);
        });
        //#endregion

        function checkIfFirstScheduleIsInRangeOfLocalBudget() {
            if (vm.formData.budgetStatistics && vm.formData.budgetStatistics.budget && vm.formData.budgetStatistics.budget.start && vm.formData.budgetStatistics.budget.end) {
                return (vm.formData.budgetStatistics.budget.start.getTime() <= vm.formData.firstScheduleDate.getTime()) && (vm.formData.firstScheduleDate.getTime() <= vm.formData.budgetStatistics.budget.end.getTime())
            }
            return false;
        }

        function getBudget() {
            if (vm.formData.firstScheduleDate) {
                vm.formData.isLoading = true;
                budgetsDataService.getBudgetByEducationIdAndStartDate(vm.education.id, vm.formData.firstScheduleDate).then(function (data) {
                    vm.formData.budgetStatistics = data;
                    if (data.budget) {
                        setBudgetData();
                    }
                    vm.formData.isLoading = false;
                }, function (error) {
                    vm.formData.isLoading = false;
                    exceptionHelper.handleException(error);
                });
            }
        }

        function setBudgetData() {    
            vm.formData.total = parseFloat(vm.formData.budgetStatistics.budget.price.amount.replace(',', '.'));               
            vm.formData.totalReserved = decimalHelper.round(vm.formData.budgetStatistics.reserved, 2);
            vm.formData.totalSpent = decimalHelper.round(vm.formData.budgetStatistics.spent, 2);
            vm.formData.remainingAmount = decimalHelper.round(vm.formData.total - vm.formData.totalReserved - vm.formData.totalSpent, 2);

            if (vm.formData.remainingAmount < 0) {
                vm.formData.minusAmount = vm.formData.remainingAmount;
                vm.formData.remainingAmount = 0;
            } else {
                vm.formData.minusAmount = undefined;
            }
            vm.formData.chartData = [vm.formData.remainingAmount, vm.formData.totalReserved, vm.formData.totalSpent];
        }


    }
})();
