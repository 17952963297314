(function () {
    'use strict';

    angular
        .module('app.programs.educations')
        .controller('ProgramEducations', programEducationCtrl);

    programEducationCtrl.$inject = ['logger', 'educations'];

    function programEducationCtrl(logger, educations) {
        var vm = this;
        vm.formData = {
            educations: educations
        };
    }
})();
