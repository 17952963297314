(function () {
    'use strict';

    angular
        .module('app.exams')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('exams', {
            url: '/exams/{id}',
            parent: 'base',
            templateUrl: 'app/exams/exams.html',
            controller: 'Exams',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'ExamAdmin', 'ExamOwner', 'Instructor']
            },
            resolve: {
                isExamCreator: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isCurrentUserExamCreator($stateParams.id);
                }],
                isExamCoordinator: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isCurrentUserExamCoordinator($stateParams.id);
                }],
                exam: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                    return examsDataService.getExamById($stateParams.id);
                }]
            }
        });
    }
})();
