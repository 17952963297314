(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schoolingSchedulePacks', schoolingSchedulePacks);

    schoolingSchedulePacks.$inject = ['emptyGuid', 'schoolings', 'dateFilter', 'i18nFilter', 'logger', 'schoolingSchedulePacksDataService', 'schedulePacksSearchOptions', '$stateParams', '$state', '$uibModal'];

    function schoolingSchedulePacks(emptyGuid, schoolings, dateFilter, i18nFilter, logger, schoolingSchedulePacksDataService, schedulePacksSearchOptions, $stateParams, $state, $uibModal) {
        /* jshint validthis:true */
        var vm = this;

        vm.formData = {
            schoolingSchedulePackEntities: [],
            searchOptions: {
                futureSchoolingSchedulePacks: schedulePacksSearchOptions.future,
                lastYearSchoolingSchedulePacks: schedulePacksSearchOptions.lastYear,
                allSchoolingSchedulePacks: schedulePacksSearchOptions.all
            },
            emptyGuid: emptyGuid
        };

        vm.refreshTable = refreshTable;
        vm.searchOption = vm.formData.searchOptions.allSchoolingSchedulePacks;
        vm.addSchoolingSchedulePack = addSchoolingSchedulePack;
        vm.editSchoolingSchedulePack = editSchoolingSchedulePack;
        vm.schoolingScheduleProgram = schoolingScheduleProgram;
        vm.schoolingScheduleContract = schoolingScheduleContract;
        vm.deleteSchoolingSchedulePack = deleteSchoolingSchedulePack;
        vm.schoolingScheduleBilling = schoolingScheduleBilling;
        vm.openLearnersBillModal = openLearnersBillModal;

        angular.forEach(schoolings, function (schooling, index) {
            schooling.type = 'schooling';
            vm.formData.schoolingSchedulePackEntities.push(schooling);
        });

        if ($stateParams.entityId) {
            vm.schoolingSchedulePackEntity = _.find(vm.formData.schoolingSchedulePackEntities, function (entity) { return entity.id === $stateParams.entityId; });
        }

        vm.localeMessages = {
            edit: i18nFilter('translations.general_action_edit'),
            program: i18nFilter('translations.schoolingSchedule_gui_program_notificationTitle'),
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            hasDifferenceBetweenEnrolledUsers: i18nFilter('translations.schedule_packs_gui_has_difference_between_enrolled_users'),
            approveApplications: i18nFilter('translations.exam_schedules_action_confirm_applications'),
            contract: i18nFilter('translations.exam_schedules_action_contracts'),
            lectureSpeakerFiles: i18nFilter('translations.schoolingSchedulePack_files'),
            deleteSchoolingSchedulePack: i18nFilter('translations.schoolingSchedulePack_delete'),
            billing: i18nFilter('translations.schoolingSchedule_gui_billing_notificationTitle'),
            learnersBill: i18nFilter('translations.schedule_gui_action_learners_bill'),
        };

        function refreshTable() {
            if (vm.schoolingSchedulePackEntity.id) {
                $stateParams.entityId = vm.schoolingSchedulePackEntity.id;
                if ($state.current.name !== "schoolingSchedulePacks") {
                    $state.go('schoolingSchedulePacks', $stateParams);
                }
                else {
                    $state.go('schoolingSchedulePacks', $stateParams, { reload: false, notify: false });
                }
                vm.gridInstance.refresh();
            }
        }

        vm.options = {
            name: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            startTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            endTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            visibility: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            orderNo: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            organizationName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            capacity: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            location:{
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.schedule_gui_label_Name'),
                alignment: 'left',
                allowSorting: true
            },
            {
                dataField: 'startDate',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                sortOrder: 'desc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'endDate',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                caption: i18nFilter('translations.schedule_gui_label_visibility'),
                alignment: 'center',
                allowSorting: true,
                width: 150,
                dataField : "visibility",
                cellTemplate: 'schoolingSchedulePackVisibilityTemplate'
            },
            {
                dataField: 'location',
                caption: i18nFilter('translations.schedule_gui_label_location'),
                alignment: 'left',
                allowSorting: true,
                width: 150
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                width: 200,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];

        function simpleFilterExtruder(element) {
            if (element[0] === "name") {
                vm.options.name.filterType1 = element[1];
                vm.options.name.searchText1 = element[2];
            }
            if (element[0] === "location") {
                vm.options.startTime.filterType1 = element[1];
                vm.options.startTime.searchText1 = element[2];
            }
            if (element[0] === "startTime") {
                vm.options.startTime.filterType1 = element[1];
                vm.options.startTime.searchText1 = element[2];
            }
            if (element[0] === "endTime") {
                vm.options.endTime.filterType1 = element[1];
                vm.options.endTime.searchText1 = element[2];
            }
            if (element[0] === "visibility") {
                vm.options.visibility.filterType1 = element[1];
                vm.options.visibility.searchText1 = element[2];
            }
            if (element[0] === "orderNo") {
                vm.options.capacity.filterType1 = element[1];
                vm.options.capacity.searchText1 = element[2];
            }
            if (element[0] === "organizationName") {
                vm.options.organizationName.filterType1 = element[1];
                vm.options.organizationName.searchText1 = element[2];
            }
            if (element[0] === "capacity") {
                vm.options.capacity.filterType1 = element[1];
                vm.options.capacity.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'startTime' && element[2][0] === 'startTime') {
                vm.options.startTime.filterType1 = element[0][1];
                vm.options.startTime.searchText1 = element[0][2];
                vm.options.startTime.filterType2 = element[2][1];
                vm.options.startTime.searchText2 = element[2][2];
            }
            if (element[0][0] === 'endTime' && element[2][0] === 'endTime') {
                vm.options.endTime.filterType1 = element[0][1];
                vm.options.endTime.searchText1 = element[0][2];
                vm.options.endTime.filterType2 = element[2][1];
                vm.options.endTime.searchText2 = element[2][2];
            }
        }

        function clearFilter() {

            vm.options.name.filterType1 = undefined;
            vm.options.name.searchText1 = undefined;
            vm.options.name.filterType2 = undefined;
            vm.options.name.searchText2 = undefined;

            vm.options.startTime.filterType1 = undefined;
            vm.options.startTime.searchText1 = undefined;
            vm.options.startTime.filterType2 = undefined;
            vm.options.startTime.searchText2 = undefined;

            vm.options.endTime.filterType1 = undefined;
            vm.options.endTime.searchText1 = undefined;
            vm.options.endTime.filterType2 = undefined;
            vm.options.endTime.searchText2 = undefined;

            vm.options.visibility.filterType1 = undefined;
            vm.options.visibility.searchText1 = undefined;
            vm.options.visibility.filterType2 = undefined;
            vm.options.visibility.searchText2 = undefined;

            vm.options.orderNo.filterType1 = undefined;
            vm.options.orderNo.searchText1 = undefined;
            vm.options.orderNo.filterType2 = undefined;
            vm.options.orderNo.searchText2 = undefined;

            vm.options.organizationName.filterType1 = undefined;
            vm.options.organizationName.searchText1 = undefined;
            vm.options.organizationName.filterType2 = undefined;
            vm.options.organizationName.searchText2 = undefined;

            vm.options.capacity.filterType1 = undefined;
            vm.options.capacity.searchText1 = undefined;
            vm.options.capacity.filterType2 = undefined;
            vm.options.capacity.searchText2 = undefined;

            vm.options.location.filterType1 = undefined;
            vm.options.location.searchText1 = undefined;
            vm.options.location.filterType2 = undefined;
            vm.options.location.searchText2 = undefined;
        }

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                        //Filter za datume
                        else {
                            dateFilterExtruder(loadOptions.filter);
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterExtruder(filterElement);
                                }
                                //Filter za datume
                                else {
                                    dateFilterExtruder(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.startTime.sortType = undefined;
                    vm.options.endTime.sortType = undefined;
                    vm.options.visibility.sortType = undefined;
                    vm.options.orderNo.sortType = undefined;
                    vm.options.organizationName.sortType = undefined;
                    vm.options.capacity.sortType = undefined;
                    vm.options.location.sortType = undefined;
                    vm.options.name.sortType = undefined;
                    if (loadOptions.sort[0].selector === "name") {
                        vm.options.name.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "startDate") {
                        vm.options.startTime.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "endDate") {
                        vm.options.endTime.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "visibility") {
                        vm.options.visibility.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "orderNo") {
                        vm.options.orderNo.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "organizationName") {
                        vm.options.organizationName.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "capacity") {
                        vm.options.capacity.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "location") {
                        vm.options.location.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken
                vm.options.searchOption = vm.searchOption;

                if (vm.schoolingSchedulePackEntity.id) {
                    vm.options.entityId = vm.schoolingSchedulePackEntity.id;
                }

                schoolingSchedulePacksDataService.getAllSchoolingSchedulePacksAdvanced(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.schedule_packs_error_message_fetching_schedule_packs'));
                    d.reject();
                });

                return d.promise();
            }
        };

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            columnAutoWidth: true,
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function addSchoolingSchedulePack() {
            $stateParams.schoolingId = vm.schoolingSchedulePackEntity.id;
            $state.go('schoolingSchedulePacks.schoolingSchedulePackAdd', $stateParams);
        }

        function editSchoolingSchedulePack(schoolingSchedulePack) {
            $stateParams.schoolingSchedulePackId = schoolingSchedulePack.id;
            $stateParams.schoolingId = schoolingSchedulePack.schoolingId;
            $state.go('schoolingSchedulePacks.schoolingSchedulePackEdit', $stateParams);
        }

        function schoolingScheduleProgram(schoolingSchedule) {
            $stateParams.schoolingScheduleId = schoolingSchedule.id;
            schoolingSchedulePacksDataService.selectedSectionIndex = 0;
            $state.go('schoolingSchedulePacks.program', $stateParams);
        }

        function schoolingScheduleContract(schoolingSchedule) {
            $stateParams.schoolingScheduleId = schoolingSchedule.id;
            $state.go('schoolingSchedulePacks.contract', $stateParams);
        }
        
        function schoolingScheduleBilling(schoolingSchedule) {
            $stateParams.schoolingId = schoolingSchedule.schoolingId;
            $stateParams.schoolingScheduleId = schoolingSchedule.id;
            $state.go('schoolingSchedulePacks.billing', $stateParams);
        }

        function openLearnersBillModal(schedulePackId) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/learnersBill/learnersBill.html',
                controller: 'schoolingLearnersBill',
                controllerAs: 'vm',
                resolve: {
                    schedulePackId: function () {
                        return schedulePackId;
                    }
                }
            });
        }

        function deleteSchoolingSchedulePack(scheduleId) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/removeSchoolingSchedulePack/removeSchoolingSchedulePack.html',
                controller: 'removeSchoolingSchedulePack',
                controllerAs: 'vm',
                resolve: {
                    canDeleteSchedule: function () {
                        return schoolingSchedulePacksDataService.checkCanDeleteSchedule(scheduleId);
                    }
                }
            })
            .result.then(function () {
                // after clicking OK button
                schoolingSchedulePacksDataService.removeSchedule(scheduleId);

                // refresh here
                $state.reload();
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }
    }
})();
