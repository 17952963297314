(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .controller('EditProgramFifthStep', fifthStep);

    fifthStep.$inject = ['$state', '$scope', 'editProgramHelper', '$stateParams', 'fieldNames'];

    function fifthStep($state, $scope, editProgramHelper, $stateParams, fieldNames) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = editProgramHelper.getData();

        vm.formData = {
            fields: fieldNames,
        };

        vm.done = done;
        vm.cancel = cancel;
        vm.back = back;

        function done() {
            editProgramHelper.done(vm.isEdit);
        }
      
        function back() {
            $state.go($scope.navigation[3].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('programs.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.program');
            }
        }
    }
})();
