(function () {
    'use strict';

    angular
        .module('app.welcome')
        .controller('Loading', loading);

    loading.$inject = ['logger', '$rootScope', '$state', '$sessionStorage', 'appName', 'authHelper', 'status', 'i18nFilter'];

    function loading(logger, $rootScope, $state, $sessionStorage, appName, authHelper, status, i18nFilter) {
        var vm = this;
        vm.appName = appName;
        $rootScope.$on("rolesSaved", function (args) {

            var toState = $sessionStorage.toState;
            var toParams = $sessionStorage.toParams;
            
            $state.go(toState.name, toParams);
        });

        $rootScope.$on("customAuthDone", function (event, args) {
            authHelper.decrementAuthInProgressFlag();
            var numberOfAuthInProgress = authHelper.getAuthInProgressFlag();

            if ($sessionStorage.hasAccess !== true) {
                $sessionStorage.hasAccess = args.hasAccess;
            }

            if (numberOfAuthInProgress > 0) {
                return;
            }

            var toState = $sessionStorage.toState;
            var toParams = $sessionStorage.toParams;

            $sessionStorage.toState = undefined;
            $sessionStorage.toParams = undefined;

            toParams.auth = $sessionStorage.hasAccess;

            $sessionStorage.hasAccess = false;

            $state.go(toState, toParams, undefined);
        });
    }
})();
