(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.html5Activity')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.activities.edit.html5SecondStep', {
                url: '/html5SecondStep',
                templateUrl: 'app/educations/administration/activities/edit/html5/secondStep.html',
                controller: 'educationsHtml5SecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner'],
                    isActivityEdit: true
                }
            })
            .state('educations.administration.activities.add.html5SecondStep', {
                url: '/html5SecondStep',
                templateUrl: 'app/educations/administration/activities/edit/html5/secondStep.html',
                controller: 'educationsHtml5SecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner'],
                    isActivityEdit: false
                }
            });
    }
})();
