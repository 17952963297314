(function () {
    'use strict';

    angular
        .module('app.proficiencyExamSchedules')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('proficiencyExamSchedules', {
                parent: 'base',
                url: '/proficiencyExamSchedules',
                //templateUrl: 'app/proficiencyExamSchedules/proficiencyExamSchedules.html',
                //controller: 'ProficiencyExamSchedulesCatalog',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['ProficiencyExamAdmin', 'ProficiencyExamOwner']
                },
                resolve: {
                    tabs: ['tenantDataService', 'applicationTabs', function (tenantDataService, applicationTabs) {
                        var tabs = [applicationTabs.catalogEducations, applicationTabs.catalogExams, applicationTabs.catalogPrograms, applicationTabs.catalogSchoolings, applicationTabs.catalogSurveys, applicationTabs.catalogProficiencyExams];
                        return tenantDataService.getTenantTabs(tabs);
                    }],
                    tenantResourceKey: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenantResourceKey();
                    }]
                }
            });
    }
})();
