(function () {
    'use strict';

    angular
        .module('blocks.router')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider', '$urlRouterProvider'];
    function routeConfig($stateProvider, $urlRouterProvider) {

        $stateProvider        
        .state('tenantNotDefined', {
            parent: 'base',
            url: '/tenantNotDefined',
            templateUrl: 'app/blocks/router/tenantNotDefined.html'
        })
        .state('notFound', {
            parent: 'base',
            url: '/notFound',
            templateUrl: 'app/blocks/router/notFound.html'
        })
        .state('error', {
            parent: 'base',
            url: '/error',
            templateUrl: 'app/blocks/router/error/error.html',
            controller: 'Error',
            controllerAs: 'vm',
            params: {
                details: null
            }
        });

        $urlRouterProvider.otherwise(
            function ($injector, $stateParams) {
                var isAdalFrame = window !== window.parent;

                var $state = $injector.get('$state'),
                    $$path = $stateParams.$$path,
                    $$url = $stateParams.$$url,
                    $log = $injector.get('$log');

                if ($$path) {
                    if (($$path.indexOf('id_token') !== -1 && $$url.indexOf('id_token') !== -1) || ($$path.indexOf('access_token') !== -1 && $$url.indexOf('access_token') !== -1) || $$path.indexOf('/error=') !== -1) {
                        // Do nothing if we have adal's token in the url
                    } else if (!isAdalFrame) {
                        $state.go('welcome');
                    }
                }
                else if (!isAdalFrame) {
                    $state.go('welcome');
                }
            }
        );

    }
})();