(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .component('percentQuestion', {
            templateUrl: 'app/surveys/questionGroups/questions/components/percentQuestion/percentQuestion.html',
            controller: percentQuestion,
            controllerAs: 'vm',
            bindings: {
                question: '=',
                isEdit: '='
            }
        });

    percentQuestion.$inject = ['surveyQuestionHelper', '$scope', 'i18nFilter', 'guidGeneratorDataService'];

    function percentQuestion(surveyQuestionHelper, $scope, i18nFilter, guidGeneratorDataService) {
        var vm = this;

        vm.answers = [];
        vm.newAnswer = undefined;

        vm.addAnswer = addAnswer;
        vm.deleteAnswer = deleteAnswer;
        vm.isValid = true;

        vm.localeMessagess = {
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.api = {
            id: vm.question.id,
            checkIfFormIsValid: checkIfFormIsValid,
            getData: getData
        };

        if (vm.isEdit) {
            vm.answers = angular.copy(vm.question.subQuestions[0].possibleAnswers);
        }

        surveyQuestionHelper.clear();
        surveyQuestionHelper.registerDirective(vm.question.id, vm.api);

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective(vm.question.id, vm.api.id);
        });

        function addAnswer() {
            if (vm.newAnswer) {
                vm.answers.push({
                    id: guidGeneratorDataService.getGuid(),
                    text: vm.newAnswer
                });
                vm.newAnswer = '';

                if (vm.answers.length >= 2) {
                    vm.isValid = true;
                }
            }
        }

        function deleteAnswer(answer) {
            var indexToDelete = vm.answers.indexOf(answer);
            if (indexToDelete !== -1) {
                vm.answers.splice(indexToDelete, 1);
            }
        }

        function checkIfFormIsValid() {
            if (vm.answers.length < 2) {
                vm.isValid = false;
                return false;
            } else {
                vm.isValid = true;
                return true;
            }
        }

        function getData() {
            return vm.answers;
        }
    }
})();
