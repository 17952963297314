(function () {
    'use strict';

    angular
        .module('app')
        .controller('doorScheduleHelp', doorScheduleHelp);

    doorScheduleHelp.$inject = ['$scope'];

    function doorScheduleHelp($scope) {
        var vm = this;
        
        vm.ok = ok;
        
        

        function ok() {
            $scope.$dismiss();
           
        };
    }
})();
