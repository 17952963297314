(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolingSchedulePacks', {
                parent: 'base',
                url: '/schoolingSchedulePacks/:entityId',
                templateUrl: 'app/schoolingSchedulePacks/schoolingSchedulePacks.html',
                controller: 'schoolingSchedulePacks',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schoolings: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getAllActiveSchoolingsWithSynchronousActivities();
                    }]
                },
                data: {                    
                    roles: ['CatalogAdmin', 'SchoolingOwner', 'SchoolingAdmin', 'Instructor']
                }
            })
            .state('schoolingSchedulePacks.schoolingSchedulePackAdd',{
                url: '/add/schooling?schoolingId',
                templateUrl: 'app/schoolingSchedulePacks/editSchoolingSchedulePack/editSchoolingSchedulePack/editSchoolingSchedulePack.html',
                controller: 'editSchoolingSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schoolingSchedulePack: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getEmptySchoolingSchedulePackForSchooling($stateParams.schoolingId);
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return schoolingsDataService.getSchoolingById($stateParams.schoolingId);
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.schoolingSchedulePackEdit', {
                url: '/:schoolingSchedulePackId/edit/schooling',
                templateUrl: 'app/schoolingSchedulePacks/editSchoolingSchedulePack/editSchoolingSchedulePack/editSchoolingSchedulePack.html',
                controller: 'editSchoolingSchedulePack',
                controllerAs: 'vm',
                requireADLogin: true,                
                resolve: {
                    schoolingSchedulePack: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingSchedulePackById($stateParams.schoolingSchedulePackId);
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        var schoolingId = $stateParams.entityId;
                        return schoolingsDataService.getSchoolingById(schoolingId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.program', {
                url: '/:schoolingScheduleId/program',
                templateUrl: 'app/schoolingSchedulePacks/program/program.html',
                controller: 'schoolingScheduleProgram',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    program: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingScheduleProgramByScheduleId($stateParams.schoolingScheduleId);
                    }],
                    paidInstructorRoles: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getPaidInstructorRolesForSchooling($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.billing', {
                url: '/:schoolingId/:schoolingScheduleId/billing',
                templateUrl: 'app/schoolingSchedulePacks/billing/billing.html',
                controller: 'schoolingScheduleBilling',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    billing: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingScheduleBillingByScheduleId($stateParams.schoolingId, $stateParams.schoolingScheduleId);
                    }],
                    paidInstructorRoles: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getPaidInstructorRolesForSchooling($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.programDocuments', {
                url: '/documents',
                views: {
                    'programDocuments@schoolingSchedulePacks.program': {
                        templateUrl: 'app/schoolingSchedulePacks/program/programDocuments/programDocuments.html',
                        controller: 'programDocuments',
                        controllerAs: 'vm'
                    }
                },
                requireADLogin: true,
                parent: 'schoolingSchedulePacks.program',
                resolve: {
                    templates: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getListOfSchoolingTemplates($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.contract', {
                url: '/:schoolingScheduleId/contract',
                templateUrl: 'app/schoolingSchedulePacks/contract/contract.html',
                controller: 'schoolingScheduleContract',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schoolingScheduleContractEmployees: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingScheduleEmployeesByScheduleId($stateParams.schoolingScheduleId);
                    }],
                    templates: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getListOfSchoolingTemplates($stateParams.entityId);
                    }],
                    schoolingScheduleName: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingScheduleNameByScheduleId($stateParams.schoolingScheduleId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            .state('schoolingSchedulePacks.applications', {
                url: '/:schedulePackId/applications',
                templateUrl: 'app/schoolingSchedulePacks/applications/applications.html',
                controller: 'schoolingSchedulePackApplications',
                controllerAs: 'vm',
                requireADLogin: true,
                params: {
                    currentPageIndex: 0
                },
                resolve: {
                    schoolingApplications: ['schoolingApplicationsDataService', '$stateParams', function (schoolingApplicationsDataService, $stateParams) {
                        return schoolingApplicationsDataService.getSchoolingApplicationsByScheduleId($stateParams.schedulePackId);
                    }],
                    schoolingNameAndScheduleCode: ['$stateParams', 'schoolingSchedulePacksDataService', function ($stateParams, schoolingSchedulePacksDataService) {
                        return schoolingSchedulePacksDataService.getSchoolingNameAndScheduleCodeByScheduleId($stateParams.schedulePackId);
                    }]                  
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            
            .state('schoolingSchedulePacks.application', {
                url: '/application?applicationId',
                templateUrl: 'app/schoolingSchedulePacks/application/application.html',
                controller: 'schoolingSchedulePackApplication',
                controllerAs: 'vm',
                requireADLogin: true,
                parent: 'schoolingSchedulePacks.applications',
                resolve: {
                    schoolingApplication: ['schoolingApplicationsDataService', '$stateParams', function (schoolingApplicationsDataService, $stateParams) {
                        return schoolingApplicationsDataService.getSchoolingApplicationById($stateParams.applicationId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })
            
            .state('schoolingSchedulePacks.addApplication', {
                url: '/addApplication',
                templateUrl: 'app/schoolingSchedulePacks/addApplication/addApplication.html',
                controller: 'schoolingSchedulePackAddApplication',
                controllerAs: 'vm',
                requireADLogin: true,
                parent: 'schoolingSchedulePacks.applications',
                resolve: {
                    schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return schoolingsDataService.getSchoolingById($stateParams.entityId);
                    }],
                    userInfoFields: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return schoolingsDataService.getActiveSchoolingFieldNamesBySchoolingIdAsync($stateParams.entityId);
                    }],
                    schoolingLearnerPrice: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return schoolingsDataService.getSchoolingLearnerPriceBySchoolingIdAsync($stateParams.entityId);
                    }]
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })

            .state('schoolingSchedulePacks.schedulePackLectures', {
                url: '/:schoolingScheduleId/lectures',
                templateUrl: 'app/schoolingSchedulePacks/schedulePackLectures/schedulePackLectures.html',
                controller: 'schedulePackLectures',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schoolingSchedulePackLectures: ['schoolingSchedulePacksDataService', '$stateParams', function (schoolingSchedulePacksDataService, $stateParams) {
                        return schoolingSchedulePacksDataService.getSchoolingSchedulePackLectureFiles($stateParams.schoolingScheduleId);
                    }],
                    schoolingNameAndScheduleCode: ['$stateParams', 'schoolingSchedulePacksDataService', function ($stateParams, schoolingSchedulePacksDataService) {
                        return schoolingSchedulePacksDataService.getSchoolingNameAndScheduleCodeByScheduleId($stateParams.schoolingScheduleId);
                    }]        
                },
                data: {
                    roles: ['SchoolingAdmin', 'SchoolingOwner']
                }
            })

            ;
    }
})();
