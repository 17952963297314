(function () {
    'use strict';

    angular
        .module('app.budgets')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('budgets', {
                parent: 'base',
                url: '/manage/budgets',
                templateUrl: 'app/manage/budgets/budgets.html',
                controller: 'budgets',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    budgets: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getAllBudgets();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('budgets.delete', {
                url: '/delete/:budgetId',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/budgets/deleteBudget/deleteBudget.html',
                        controller: 'DeleteBudget',
                        controllerAs: 'vm',
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                },
                params: {
                    budget: undefined
                }
            })
            .state('budgets.add', {
                url: '/add',
                templateUrl: 'app/manage/budgets/editBudget/editBudget.html',
                controller: 'EditBudget',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    budget: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return {};
                    }],
                    budgetEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetEntities();
                    }],
                    budgetExamEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetExamEntities();
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('budgets.edit', {
                url: '/:budgetId/edit',
                templateUrl: 'app/manage/budgets/editBudget/editBudget.html',
                controller: 'EditBudget',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    budget: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetById($stateParams.budgetId);
                    }],
                    budgetEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetEntities();
                    }],
                    budgetExamEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetExamEntities();
                    }],
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            });
    }    
})();
