(function () {
    'use strict';

    angular
        .module('app.educations.sidebar.learners')
        .component('educationLearners', {
            templateUrl: 'app/educations/sidebars/learners/learners.html',
            controller: learnersCtrl,
            controllerAs: 'vm',
            bindings: {
                educationId: '='
            }
        });

    learnersCtrl.$inject = ['logger', 'exceptionHelper', 'educationsDataService'];

    function learnersCtrl(logger, exceptionHelper, educationsDataService) {
        var vm = this;

        vm.formData = {
            loading: true
        };

        //TODO: logic for showing


        educationsDataService.getEducationLearnersForCurrentPeriod(vm.educationId).then(function (data) {
            vm.learners = data.learners;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });       
    }
})();
