(function () {

    'use strict';

    angular
        .module('blocks.controls')
        .directive('truncate', truncate);

    function truncate($timeout) {
        return {
            restrict: 'A',
            scope: {
                truncateOptions: '='
            },
            link: function (scope, element) {
                $timeout(function () {
                    element.dotdotdot(scope.truncateOptions);

                });
            }
        };
    }

})();