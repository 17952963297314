(function () {
    'use strict';
    angular
        .module('app.schoolingSchedulePacks')
        .controller('TransferApplicationDialog', TransferApplicationDialog);

    TransferApplicationDialog.$inject = ['$uibModalInstance', 'dateHelper', 'usersDataService', 'logger', 'exceptionHelper', 'i18nFilter', '$scope', 'parentScope', 'schedulePacks','schoolingApplicationsDataService', 'application'];

    function TransferApplicationDialog($uibModalInstance, dateHelper, usersDataService, logger, exceptionHelper, i18nFilter, $scope, parentScope, schedulePacks, schoolingApplicationsDataService, application) {
        var modalvm = this;

        modalvm.cancel = cancel;
        modalvm.add = add;
        modalvm.parentScope = parentScope;
        //modalvm.schedulePacks = schedulePacks;
        modalvm.refreshSchedulePacks = refreshSchedulePacks;
        modalvm.item = { id: application };

        modalvm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
        }

        modalvm.schedulePacks = schedulePacks;

        function refreshSchedulePacks() {
            return modalvm.schedulePacks;
        }


        function add() {
            logger.log("Transfering application: ", modalvm.item);
            var itemCopy = angular.copy(modalvm.item);
            itemCopy.schoolingSchedulePackId = itemCopy.schedulePack.id;

            schoolingApplicationsDataService.transferSchoolingApplication(itemCopy).then(function(result) {
                $uibModalInstance.close('confirmed transfer');
            });
        }

        function cancel() {
            $scope.$dismiss();
        }

    }
})();


