(function () {
    'use strict';

    angular
        .module('app.programs.administration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('programs.administration', {
                url: '/administration',
                templateUrl: 'app/programs/administration/administration.html',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'ProgramOwner', 'EducationAdmin']
                }
            });
    }
})();
