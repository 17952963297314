(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('logsDataService', logs);

    logs.$inject = ['$http', 'logger', 'locale', '$q', 'dateHelper'];

    function logs($http, logger, locale, $q, dateHelper) {
        var service = {
            getLogs: getLogs
        };

        return service;

        function getLogs(filter) {
            filter.date.searchText1 = dateHelper.convertFromDateToString(filter.date.searchText1);
            filter.date.searchText2 = dateHelper.convertFromDateToString(filter.date.searchText2);
            
            return $http.post('api/logs', filter).then(function (data) {
                angular.forEach(data.data.data, function (element, index) {
                    element.date = dateHelper.convertFromStringToDate(element.date);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get logs', data);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            })
        }
    }
})();