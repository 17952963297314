(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('examTile', {
            templateUrl: 'app/catalogs/components/examTile/examTile.html',
            controller: examTile,
            controllerAs: 'vm',
            bindings: {
                exam: '='
            }
        });

    examTile.$inject = ['logger', 'numberFilter'];

    function examTile(logger, numberFilter) {
        var vm = this;

        vm.formData = {
            truncateOptions: {
                watch: 'window',
                wrap: 'letter'
            }
        };

        vm.dateTime = new Date().getTime();
    }
})();
