(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('analyticsDataService', analyticsDataService);

    analyticsDataService.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function analyticsDataService($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllBudgets: getAllBudgets,
            getSchedulesCalendar: getSchedulesCalendar,
            getEntityEFormInterest: getEntityEFormInterest,
            getEFormInterestReport: getEFormInterestReport,
            getLearningRecords: getLearningRecords,
            getEducationTypeReport: getEducationTypeReport,
            getEnrolmentYears: getEnrolmentYears,
            getEnrolmentYearData: getEnrolmentYearData,
            getUserStatusData: getUserStatusData
        };

        return service;

        function getAllBudgets() {
            return $http.get('/api/analytics/budgets')
                .then(function (data) {
                    angular.forEach(data.data, function (element, index) {
                        element.start = dateHelper.convertFromStringToDate(element.start);
                        element.end = dateHelper.convertFromStringToDate(element.end);

                        var entitiesString = '';
                        for (var i = 0; i < element.entities.length; i++) {
                            if (i === element.entities.length - 1) {
                                entitiesString += element.entities[i].name;
                            } else {
                                entitiesString += element.entities[i].name + '; ';
                            }
                        }
                        element.entitiesString = entitiesString;

                        var examEntitiesString = '';
                        for (var i = 0; i < element.examEntities.length; i++) {
                            if (i === element.examEntities.length - 1) {
                                examEntitiesString += element.examEntities[i].name;
                            } else {
                                examEntitiesString += element.examEntities[i].name + '; ';
                            }
                        }
                        element.examEntitiesString = examEntitiesString;
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getSchedulesCalendar(startDate, endDate, currentView) {
            return $http.get('/api/analytics/calendar', { params: { startDate, endDate, currentView } })
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }


        function getEntityEFormInterest(entityName) {
            return $http.get('/api/analytics/eformsOfInterest', { params: { entityName } })
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }


        function getEFormInterestReport() {
            return $http.get('/api/analytics/getEFormInterestReport')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getLearningRecords(filter) {
            filter.startTime.searchText1 = dateHelper.convertFromDateToString(filter.startTime.searchText1);
            filter.startTime.searchText2 = dateHelper.convertFromDateToString(filter.startTime.searchText2);

            filter.endTime.searchText1 = dateHelper.convertFromDateToString(filter.endTime.searchText1);
            filter.endTime.searchText2 = dateHelper.convertFromDateToString(filter.endTime.searchText2);

            return $http.post('api/analytics/learningRecordsFilter', filter)
                .then(function (data) {
                    angular.forEach(data.data.data, function (element, index) {
                        element.startTime = dateHelper.convertFromStringToDate(element.startTime);
                        element.endTime = dateHelper.convertFromStringToDate(element.endTime);
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getEducationTypeReport(filter) {
            return $http.post('/api/analytics/getEducationTypeReport', filter)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getEnrolmentYears() {
            return $http.get('/api/analytics/getEnrolmentYears')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getEnrolmentYearData(year) {
            return $http.get('/api/analytics/getEnrolmentYearData', { params: { year } })
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
    
        function getUserStatusData() {
            return $http.get('/api/analytics/getUserStatusData')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
    }
})();
