(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('schoolingRow', {
            templateUrl: 'app/catalogs/components/schoolingRow/schoolingRow.html',
            controller: schoolingRow,
            controllerAs: 'vm',
            bindings: {
                schooling: '='
            }
        });

    schoolingRow.$inject = ['logger', 'learningPlanItemStatus', 'numberFilter'];

    function schoolingRow(logger, learningPlanItemStatus, numberFilter) {
        var vm = this;
        vm.formData = {
            learningPlanItemStatus: learningPlanItemStatus
        };

        vm.getLearnerPercentage = getLearnerPercentage;

        function getLearnerPercentage() {
            if
            (
                (   vm.schooling.learningPlanItemStatus !== undefined
                    ||
                    vm.schooling.learningPlanItemStatus !== null
                )
                    &&
                (   vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.started
                        ||
                    vm.schooling.learningPlanItemStatus === vm.formData.learningPlanItemStatus.notStarted
                )
            )
            {
                var numberOfFinishedLearningActivities = vm.schooling.numberOfFinishedLearningActivities;
                var numberOfLearningActivities = vm.schooling.numberOfLearningActivities;

                if (numberOfLearningActivities === 0) {
                    return "";
                }
                else if (numberOfFinishedLearningActivities === 0) {
                    return "(0)%";
                }
                else {
                    var calculation = numberOfLearningActivities / numberOfFinishedLearningActivities;

                    return "(" + numberFilter(calculation, 2) + ")%";
                }
            }
            return "";
        }

    }
})();
