(function () {
    'use strict';

    angular
        .module('app.exams.home')
        .controller('ExamsHome', examsHome);

    examsHome.$inject = ['logger', 'exam', '$sce'];

    function examsHome(logger, exam, $sce) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'exam home';
        vm.exam = exam;

        vm.trustAsHtml = trustAsHtml;

        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        };
        
    }
})();
