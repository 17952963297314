(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('targetGroupsCatalogFilter', {
            templateUrl: 'app/catalogs/filters/targetGroups/targetGroupsCatalogFilter.html',
            controller: targetGroupsCatalogFilter,
            controllerAs: 'vm',
            bindings: {
                data: '=',
                onChanged: '='
            }
        });

    targetGroupsCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function targetGroupsCatalogFilter(logger, i18nFilter) {
        var vm = this;

        vm.data.forEach(x => {
            x.name = x.parentName +':'+ x.name;
        })

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.catalog_gui_target_groups_filter_placeholder")
        };

        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 
    }
})();
