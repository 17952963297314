(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .controller('EditProgramBaseStep', editProgramBaseStep);

    editProgramBaseStep.$inject = ['logger', 'editProgramHelper', 'i18nFilter', '$state', '$scope', 'program', 'emptyGuid'];

    function editProgramBaseStep(logger, editProgramHelper, i18nFilter, $state, $scope, program, emptyGuid) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = program;
        vm.navigation = [];
        vm.containerStyle = {};
        editProgramHelper.clearData();

        if (vm.isEdit) {

            vm.containerStyle['margin-top'] = '-20px';
            vm.navigation = editProgramHelper.getWizardSteps(vm.isEdit);

            if (vm.item.surveyEnabled) {
                var surveyGroup = {
                    id: emptyGuid,
                    name: i18nFilter('translations.education_gui_choose_survey_group_no_questions')
                };

                var sg = vm.item.surveyGroups[0];

                if (sg.id === emptyGuid) {
                    vm.item.surveyGroups = surveyGroup;
                } else {
                    vm.item.surveyGroups = sg;
                }
            }

            editProgramHelper.setData(vm.item);
        }
        else {
            vm.containerStyle['margin'] = '10px';
            vm.navigation = editProgramHelper.getWizardSteps(null, vm.isEdit);
        }

        $scope.navigation = vm.navigation;
        vm.goToStep = function (navItem) {
            $state.go(navItem.state);
        }
    }
})();
