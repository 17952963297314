(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .component('schedulePackSchoolingWrapper', {
            templateUrl: 'app/schoolingSchedulePacks/components/schoolingWrapper/schoolingWrapper.html',
            controller: schedulePackSchoolingWrapper,
            controllerAs: 'vm',
            bindings: {
                schedules: '=',
                isOpen: '=',
                isEdit: '='
            }
        });

    schedulePackSchoolingWrapper.$inject = ['logger', 'schoolingsDataService', 'locationsDataService', 'exceptionHelper'];

    function schedulePackSchoolingWrapper(logger, schoolingsDataService, locationsDataService, exceptionHelper) {
        var vm = this;

        vm.formData = {
            expanded: vm.isOpen,

            possibleInstructors: [],
            possibleLocations: [],
            schooling: undefined,
            schoolingId: undefined,
            schoolingName: undefined,

            instructorsReady: false,
            locationsReady: false,
            schoolingReady: false
        };

        //#region Method binding
        vm.toggleSchooling = toggleSchooling;
        //#endregion

        //#region Initial data setup
        if (vm.schedules && vm.schedules.length !== 0) {
            vm.formData.schoolingId = vm.schedules[0].schoolingId;
            vm.formData.schoolingName = vm.schedules[0].schoolingName;

            locationsDataService.getRegularLocations().then(function (data) {
                vm.formData.possibleLocations = data;
                vm.formData.locationsReady = true;
            }, function (error) {
                vm.formData.locationsReady = true;
                exceptionHelper.handleException(error);
            });

            schoolingsDataService.getSchoolingInstructors(vm.formData.schoolingId).then(function (data) {
                vm.formData.possibleInstructors = data;
                vm.formData.instructorsReady = true;
            }, function (error) {
                vm.formData.instructorsReady = true;
                exceptionHelper.handleException(error);
            });
            
            schoolingsDataService.getSchoolingById(vm.formData.schoolingId).then(function (data) {
                vm.formData.schooling = data;
                vm.formData.schoolingReady = true;
            }, function (error) {
                exceptionHelper.handleException(error);
                vm.formData.schoolingReady = true;
            });
            
        }
        //#endregion

        //#region Toggle
        function toggleSchooling() {
            vm.formData.expanded = !vm.formData.expanded;
        }
        //#endregion

    }
})();
