(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('learnersDataService', learners);

    learners.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper'];

    function learners($http, logger, locale, $q, activityTypes, jsonHelper) {
        var service = {            
            confirmAdministrativeFinish: confirmAdministrativeFinish, //Potrebna izmjena
            cancelAdministrativeFinish: cancelAdministrativeFinish
        };

        return service;
        
        function confirmAdministrativeFinish(learningPlanItemIds) {
            return $http.post('api/learners/administrativeFinish', learningPlanItemIds).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function cancelAdministrativeFinish(learningPlanItemIds) {
            return $http.post('api/learners/administrativeCancelFinish', learningPlanItemIds).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
