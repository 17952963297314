(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('classicCatalog', {
                parent: 'base',
                url: '/classicCatalog',
                templateUrl: 'app/catalogs/classic/classicCatalog.html',
                controller: 'ClassicCatalog',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin', 'EducationOwner', 'ExamAdmin', 'ExamOwner', 'Instructor', 'ProgramOwner']
                },
                resolve: {
                    tabs: ['tenantDataService', 'applicationTabs', function (tenantDataService, applicationTabs) {
                        var tabs = [applicationTabs.catalogEducations, applicationTabs.catalogExams, applicationTabs.catalogPrograms, applicationTabs.catalogSchoolings, applicationTabs.catalogSurveys, applicationTabs.catalogProficiencyExams];
                        return tenantDataService.getTenantTabs(tabs);
                    }],
                    tenantResourceKey: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenantResourceKey();
                    }]
                }
            })
            .state('classicCatalog.schooling', {
                url: '/schooling',
                templateUrl: 'app/catalogs/classic/schooling/classicSchooling.html',
                controller: 'ClassicCatalogSchooling',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'SchoolingAdmin', 'SchoolingOwner', 'ExamAdmin', 'ExamOwner', 'Instructor', 'ProgramOwner']
                },
                resolve: {
                    categories: ['categoriesDataService', '$q', function (categoriesDataService, $q) {
                        return categoriesDataService.getAllActiveCategories().then(function (data) {
                            return categoriesDataService.convertCategoriesToCatalogFilterStructure(data);
                        }, function (error) {
                            return $q.reject(error);
                        });
                    }],
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    userIsSchoolingOwner: ['authDataService', function (authDataService) {
                        return authDataService.isCurrentUserSchoolingOwnerOfAnySchooling();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevelsForSchooling();
                    }],
                    targetGroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('classicCatalog.education', {
                url: '/education',
                templateUrl: 'app/catalogs/classic/education/classicEducation.html',
                controller: 'ClassicCatalogEducation',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin', 'EducationOwner', 'ExamAdmin', 'ExamOwner', 'Instructor', 'ProgramOwner']
                },
                resolve: {
                    categories: ['categoriesDataService', '$q', function (categoriesDataService, $q) {
                        return categoriesDataService.getAllActiveCategories().then(function (data) {
                            return categoriesDataService.convertCategoriesToCatalogFilterStructure(data);
                        }, function (error) {
                            return $q.reject(error);
                        });
                    }],
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    userIsEducationOwner: ['authDataService', function (authDataService) {
                        return authDataService.isCurrentUserEducationOwnerOfAnyEducation();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }],
                    targetGroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('classicCatalog.program', {
                url: '/program',
                templateUrl: 'app/catalogs/classic/program/classicProgram.html',
                controller: 'ClassicCatalogProgram',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin', 'EducationOwner', 'ExamAdmin', 'ExamOwner', 'Instructor', 'ProgramOwner']
                },
                resolve: {
                    categories: ['categoriesDataService', '$q', function (categoriesDataService, $q) {
                        return categoriesDataService.getAllActiveCategories().then(function (data) {
                            return categoriesDataService.convertCategoriesToCatalogFilterStructure(data);
                        }, function (error) {
                            return $q.reject(error);
                        });
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }]
                }
            })
            .state('classicCatalog.survey', {
                url: '/survey',
                templateUrl: 'app/catalogs/classic/survey/survey.html',
                controller: 'ClassicCatalogSurvey',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('classicCatalog.exam', {
                url: '/exam',
                templateUrl: 'app/catalogs/classic/exam/classicExam.html',
                controller: 'ClassicCatalogExam',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin', 'EducationOwner', 'ExamAdmin', 'ExamOwner', 'Instructor', 'ProgramOwner']
                },
                resolve: {
                    categories: ['categoriesDataService', '$q', function (categoriesDataService, $q) {
                        return categoriesDataService.getAllActiveCategories().then(function (data) {
                            return categoriesDataService.convertCategoriesToCatalogFilterStructure(data);
                        }, function (error) {
                            return $q.reject(error);
                        });
                    }],
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    userIsExamCoordinator: ['authDataService', function (authDataService) {
                        return authDataService.isCurrentUserCoordinatorOfAnyExam();
                    }],
                    targetGroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            });
    }
})();
