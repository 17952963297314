(function () {
    'use strict';

    angular
        .module('app.programs.administration.delete')
        .controller('DeleteProgram', deleteProgram);

    deleteProgram.$inject = ['logger', '$scope', '$state', '$stateParams', 'programsDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteProgram(logger, $scope, $state, $stateParams, programsDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.name = $stateParams.name;
        vm.id = $stateParams.programId;

        vm.cancel = cancel;
        vm.ok = ok;
        
        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            programsDataService.deleteProgram(vm.id).then(function (additionalData) {
                logger.success(i18nFilter('translations.program_operation_delete_success_message', additionalData.name));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error,false);
            });
        };
    }
})();
