(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.officeMix')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider        
        .state('schoolings.administration.activities.edit.officeMixSecondStep', {
            url: '/officeMixSecondStep',
            templateUrl: 'app/schoolings/administration/activities/edit/officeMix/secondStep.html',
            controller: 'OfficeMixSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isActivityEdit: true
            }
        })
        .state('schoolings.administration.activities.add.officeMixSecondStep', {
            url: '/officeMixSecondStep',
            templateUrl: 'app/schoolings/administration/activities/edit/officeMix/secondStep.html',
            controller: 'OfficeMixSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isActivityEdit: false
            }
        });
    }
})();