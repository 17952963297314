(function () {
    'use strict';

    angular
        .module('blocks.router')
        .run(routehelper);

    routehelper.$inject = ['$rootScope', '$urlRouter', 'logger', '$sessionStorage', 'rolesDataService', '$state', 'tenantDataService', '$location', 'exceptions', 'exceptionHelper', 'status', 'adalAuthenticationService', 'usersDataService', 'authDataService', '$window', 'authHelper', 'jsonHelper', 'newAdminPortalUrl'];
    function routehelper($rootScope, $urlRouter, logger, $sessionStorage, rolesDataService, $state, tenantDataService, $location, exceptions, exceptionHelper, status, adalAuthenticationService, usersDataService, authDataService, $window, authHelper, jsonHelper, newAdminPortalUrl) {

        //#region Errors in resolve & not found 
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            logger.log("----------------------------------------------------------------------------------------------------------------");
            logger.log("Error: ", error);
            logger.log('$stateChangeError fromState: ', fromState);
            logger.log('$stateChangeError toState: ', toState);
            logger.log("----------------------------------------------------------------------------------------------------------------");

            //If ADAL error
            if (
                (error && error.exception && (typeof error.exception === 'string' || error.exception instanceof String) && error.exception.indexOf('Token renewal operation failed') !== -1)
                ||
                (error && error.exception && (typeof error.exception === 'string' || error.exception instanceof String) && error.exception.indexOf('AAD') !== -1)
                ||
                (error && error.exception && (typeof error.exception === 'string' || error.exception instanceof String) && error.exception.indexOf('login in progress') !== -1)
            ) {
                event.preventDefault();
                $sessionStorage.toState = toState.name;
                $sessionStorage.toParams = toParams;

                logger.log("toState: ", $sessionStorage.toState);
                logger.log("toParams: ", $sessionStorage.toParams);

                adalAuthenticationService.login(true);
            }
            else if (typeof error.exception === 'string' || error.exception instanceof String) {
                //Controller exception

                var tempError = {
                    exception: {
                        additionalData: {},
                        userId: undefined,
                        tenantId: undefined,
                        exceptionCode: undefined,
                        operationCode: undefined,
                        workerResult: undefined,
                        requestQueueId: undefined,
                        processType: undefined
                    },
                    status: 400
                };

                event.preventDefault();
                $state.go('error', { details: tempError });
            }
            else if (error.exception !== null && typeof error.exception === 'object') {
                //Controller base exception                
                event.preventDefault();
                $state.go('error', { details: error });
            }
        });



        $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
            event.preventDefault();
            logger.log("Page not found: ", unfoundState);
            $state.go("notFound");
        });
        //#endregion

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {

            //#region Za mali prokaz slike na edukaciji
            $rootScope.isSmallCover = toState.name !== 'educations.home' && toState.name !== 'programs.home' && toState.name !== 'exams.home' && toState.name !== 'schoolings.home';
            $rootScope.$broadcast("smallCoverChanged");

            //Something for sidebar
            $rootScope.$broadcast("stateChanged", toState.name);

            if (toState.name === 'loading') {
                $rootScope.isLoadingPage = true;
            } else {
                $rootScope.isLoadingPage = false;
            }

            //#endregion

            if (toState.name !== 'unsupportedBrowser' && isOldBrowser()) {
                event.preventDefault();
                $state.go('unsupportedBrowser');
            }

            if (toState.name === 'tenantNotDefined' || toState.name === 'unsupportedBrowser') {
                return;
            }


            if (!$sessionStorage.userInfo
                && !$sessionStorage.rolesInProgress
                && toState.name !== 'welcome'
                && toState.name !== 'error'
                && $rootScope.userInfo
                && $rootScope.userInfo.isAuthenticated) {

                event.preventDefault();
                $sessionStorage.rolesInProgress = true;
                $sessionStorage.toState = toState;
                $sessionStorage.toParams = toParams;

                usersDataService.getUserInfo().then(function (data) {
                    if (data !== undefined) {
                        //Set flags
                        $sessionStorage.tenantIsNotDefined = false;
                        $sessionStorage.rolesInProgress = false;

                        //Saving data
                        $sessionStorage.userInfo = data;

                        //Notify
                        $rootScope.$broadcast("rolesSaved");
                    }
                }, function (error) {
                    exceptionHelper.handleException(error, true);
                });
                $state.go('loading');
            }
            else if ($sessionStorage.userInfo) {
                validateRole();
            }
            
            function validateRole() {
                var userStatus = $sessionStorage.userInfo.userStatus;

                if (userStatus === status.processing && toState.name !== 'registration.edit') {
                    event.preventDefault();
                    $window.location.href = newAdminPortalUrl[$sessionStorage.userInfo.tenantResourceKey] + 'registration';
                }

                if (userStatus === status.deactivated && toState.name !== 'deactevatedUser') {
                    event.preventDefault();
                    $state.go('deactevatedUser');
                }

                if (toState.data && toState.data.roles) {
                    var roles = $sessionStorage.userInfo.roles;
                    var rolesRequired = toState.data.roles;

                    $sessionStorage.toState = toState;
                    $sessionStorage.toParams = toParams;


                    var isUnauthorized = true;

                    angular.forEach(roles, function (valueRole, keyRole) {
                        angular.forEach(rolesRequired, function (valueRoleRequired, keyRequired) {
                            if (valueRole.name === valueRoleRequired) {
                                isUnauthorized = false;
                            }
                        });
                    });

                    //Prema zatraženim aplikacijskim ulogama korisnik nema prava,
                    //Provjeri je li korisnik ima neka druga prava
                    if (isUnauthorized) {
                        var result = checkSpecialRoles(toState, toParams, event);
                        if (result === 1) {
                            isUnauthorized = false;
                        }
                    }

                    if (isUnauthorized) {
                        event.preventDefault();
                        $state.go('unauthorized', { stateName: toState.name });
                        return;
                    }
                }
            }
        });

        /**
         * Metoda vraća 0 ukoliko traženi state ne zahtjeva dodatne provjere
         * vraća 1 ukoliko sam provjerio prava na serveru i prava su ok
         * vraća -1 ukoliko sam provjerio prava na serveru i prava nisu ok
         * @param {any} toState
         * @param {any} toParams
         * @param {any} event
         */
        function checkSpecialRoles(toState, toParams, event) {
            var requredRoles = toState.data.roles;

            var redirectToLoading = false;
            var keepGoing = true;
            var result = undefined;

            angular.forEach(requredRoles, function (role, index) {
                if (keepGoing) {

                    if (role === "RealEducationOwner") {
                        // Provjeri je li korisnik stvarni vlasnik edukacije
                        var alreadyFinished = checkIfAuthIsAlreadyFinished(toParams);
                        if (alreadyFinished !== undefined) {
                            keepGoing = false;
                            result = alreadyFinished;
                        }
                        else {
                            event.preventDefault();
                            $sessionStorage.toState = toState;
                            $sessionStorage.toParams = toParams;

                            authHelper.incrementAuthInProgressFlag();
                            authDataService.isCurrentUserRealEducationOwner(toParams.id).then(authFinishedEvent, authErrorEvent);
                            redirectToLoading = true;
                        }
                    }

                     if (role === "RealProgramOwner") {
                        // Provjeri je li korisnik stvarni vlasnik edukacije
                        var alreadyFinished = checkIfAuthIsAlreadyFinished(toParams);
                        if (alreadyFinished !== undefined) {
                            keepGoing = false;
                            result = alreadyFinished;
                        }
                        else {
                            event.preventDefault();
                            $sessionStorage.toState = toState;
                            $sessionStorage.toParams = toParams;

                            authHelper.incrementAuthInProgressFlag();
                            authDataService.isCurrentUserRealProgramOwner(toParams.programId).then(authFinishedEvent, authErrorEvent);
                            redirectToLoading = true;
                        }
                    }

                    if (role === "EnrolledLearner") {
                        // Provjeri je li korinsik upisan u edukaciju
                        var alreadyFinished = checkIfAuthIsAlreadyFinished(toParams);
                        if (alreadyFinished !== undefined) {
                            keepGoing = false;
                            result = alreadyFinished;
                        }
                        else {
                            event.preventDefault();
                            $sessionStorage.toState = toState;
                            $sessionStorage.toParams = toParams;

                            authHelper.incrementAuthInProgressFlag();
                            authDataService.isUserEnrolledInEducation(toParams.id).then(authFinishedEvent, authErrorEvent);
                            redirectToLoading = true;
                        }
                    }
                }
            });

            if (!keepGoing && result !== undefined) {
                return result;
            }


            if (redirectToLoading) {
                $state.go('loading');
                return 1;
            }
            else {
                return 0;
            }
        }

        function authFinishedEvent(data) {
            $rootScope.$broadcast("customAuthDone", { hasAccess: data });
        }

        function authErrorEvent(error) {
            exceptionHelper.handleException(error);
        }

        function checkIfAuthIsAlreadyFinished(toParams) {
            if (toParams && toParams.auth !== undefined) {
                return toParams.auth ? 1 : -1;
            }
        }

        function isOldBrowser() {
            var ua = $window.navigator.userAgent;

            var ISFF = ua.indexOf('Firefox') != -1;
            var ISOPERA = ua.indexOf('Opera') != -1;
            var ISCHROME = ua.indexOf('Chrome') != -1;
            var ISSAFARI = ua.indexOf('Safari') != -1 && !ISCHROME;
            var ISWEBKIT = ua.indexOf('WebKit') != -1;

            var ISIE = ua.indexOf('Trident') > 0 || navigator.userAgent.indexOf('MSIE') > 0;
            var ISIE6 = ua.indexOf('MSIE 6') > 0;
            var ISIE7 = ua.indexOf('MSIE 7') > 0;
            var ISIE8 = ua.indexOf('MSIE 8') > 0;
            var ISIE9 = ua.indexOf('MSIE 9') > 0;
            var ISIE10 = ua.indexOf('MSIE 10') > 0;
            var ISOLD = ISIE6 || ISIE7 || ISIE8; // MUST be here

            var ISIE11UP = ua.indexOf('MSIE') == -1 && ua.indexOf('Trident') > 0;
            var ISIE10UP = ISIE10 || ISIE11UP;
            var ISIE9UP = ISIE9 || ISIE10UP;

            var oldBrowser = !ISFF && !ISCHROME && !ISSAFARI && !ISOPERA && !ISIE11UP;
            return oldBrowser;
        }




        //#region Potencijalno nepotrebno
        var isAdalFrame = window !== window.parent;
        $rootScope.$on('$locationChangeStart', function (e) {
            //ako postoji access_token u urlu ili postoji id_token u urlu ili /error nema u urlu ili je adalframe?
            if ($location.path().indexOf('access_token') > -1 || $location.path().indexOf('id_token') > -1 || $location.path().indexOf('/error=') === 0 || isAdalFrame) {
                e.preventDefault();
            }
        });
        //#endregion



        //Log loading bar events
        //$rootScope.$on('cfpLoadingBar:loaded', function (url, result) {            
        //    var resultStr = JSON.stringify(result);

        //    logger.log("cfpLoadingBar:loaded, url: ", url);
        //    logger.log("cfpLoadingBar:loaded, result: ", resultStr);
        //});
    }
})();
