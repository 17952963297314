(function () {
    'use strict';

    angular
        .module('app.budgets')
        .controller('budgets', budgets);

    budgets.$inject = ['budgets', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'budgetsDataService'];

    function budgets(budgets, i18nFilter, dateFilter, exceptionHelper, logger, budgetsDataService) {
        /* jshint validthis:true */
        var vm = this;
        
        vm.budgets = budgets;
        vm.localeMessages = {
            edit: i18nFilter('translations.general_action_edit'),
            delete: i18nFilter('translations.general_action_delete')
        };
        vm.delete = deleteBudget;        

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                width: 300,
                allowSorting: true
            },
            {
                dataField: 'start',
                dataType: "date",
                alignment: 'left',
                sortOrder: "desc",
                allowSorting: true,
                allowFiltering: true,
                width: 100,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'end',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                width: 100,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {     
                dataField:'entitiesString',
                dataType:'string',
                caption: i18nFilter('translations.budget_gui_budget_education_connections'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                allowSorting: false
            },
            {
                dataField: 'examEntitiesString',
                dataType: 'string',
                caption: i18nFilter('translations.budget_gui_budget_exam_connections'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                allowSorting: false
            },
            {
                dataField: 'price.amount',
                caption: i18nFilter('translations.budget_gui_budget_amount'),
                alignment: 'left',
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                width: 150,
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'price.amountInWords',
                caption: i18nFilter('translations.budget_gui_budget_amount_in_words'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                allowSorting: false
            },
            {
                dataField: 'publicOrder.orderNumber',
                dataType: 'string',
                caption: i18nFilter('translations.budget_gui_budget_public_order_number'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                allowSorting: false
            },
            {
                dataField: 'publicOrder.orderDate',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                width: 100,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.budget_gui_budget_public_order_date')
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                width: 100,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];


        vm.dataGridOptions = {
            dataSource: vm.budgets,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function deleteBudget(id) {            
            logger.log("Delete: ", vm.item);            
            budgetsDataService.deleteBudget(id).then(function (data) {
                logger.success(i18nFilter('translations.budget_queue_delete'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });            
        }

    }
})();
