(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingThirdStep', thirdStep);

    thirdStep.$inject = ['logger', 'i18nFilter', '$state', '$scope', 'editSchoolingHelper', 'priceCategories', 'currencies', '$stateParams', 'SchoolingActivityScheduleInstructorRoles'];

    function thirdStep(logger, i18nFilter, $state, $scope, editSchoolingHelper, priceCategories, currencies, $stateParams, SchoolingActivityScheduleInstructorRoles) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.navigation = $scope.navigation;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.formData = {
            categories: priceCategories,
            currencies: currencies,
            instructorTypes: translateCollections(SchoolingActivityScheduleInstructorRoles)
        };

        vm.item = editSchoolingHelper.getData();
        vm.formData.instructorTypes.forEach(function (instructorType) {
            instructorType.availabe = true;
        });
        if (vm.item.prices.length == 0) {
            vm.item.prices.push({
                // TODO fix hardcoded values
                category: {
                    id: "15a225bd-73ae-e711-a250-9c2a708864f4",
                    name: "LearnerPrice"
                },
                currency: {
                    id: "18a225bd-73ae-e711-a250-9c2a708864f4",
                    isoCode: "EUR"
                }
            });
            vm.item.prices.push({
                // TODO fix hardcoded values
                category: {
                    id: "16a225bd-73ae-e711-a250-9c2a708864f4",
                    name: "InstructorPrice"
                },
                currency: {
                    id: "18a225bd-73ae-e711-a250-9c2a708864f4",
                    isoCode: "EUR"
                }
            });
        } else {
            vm.item.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice") {
                    if (typeof price.instructorType != "object") {
                        for (var index in vm.formData.instructorTypes) {
                            if (price.instructorType == index) {
                                price.instructorType = vm.formData.instructorTypes[index];
                                break;
                            }
                        }
                    }
                    if (price.instructorType == null) {
                        price.instructorType = vm.formData.instructorTypes[0];
                    }
                    vm.formData.instructorTypes[price.instructorType.id].availabe = false;
                }
            });
        }
        
        vm.addPrice = addPrice;
        vm.removePrice = removePrice;
        vm.changeInstructorTypeAvailability = changeInstructorTypeAvailability;
        vm.getAvailableInstructorTypes = getAvailableInstructorTypes;

        vm.cancel = cancel;
        vm.next = next;
        vm.back = back;
        vm.save = save;

        function save() {
            var item_copy = angular.copy(vm.item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                });
            }

            item_copy.schoolingExecutionStyle = item_copy.schoolingExecutionStyle.id;

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id != null) {
                    price.instructorType = price.instructorType.id;
                }
            });
            editSchoolingHelper.setData(item_copy);
            editSchoolingHelper.done(vm.isEdit);
        }

        function addPrice() {
            vm.item.prices.push({
                // TODO fix hardcoded values
                category: {
                    id: "16a225bd-73ae-e711-a250-9c2a708864f4",
                    name: "InstructorPrice"
                },
                currency: {
                    id: "18a225bd-73ae-e711-a250-9c2a708864f4",
                    isoCode: "EUR"
                }
            });
        }

        function removePrice(index) {
            if (vm.item.prices[index].instructorType != undefined && vm.item.prices[index].instructorType.id != undefined) {
                vm.formData.instructorTypes[vm.item.prices[index].instructorType.id].availabe = true;
            }
            vm.item.prices.splice(index, 1);
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            })
            return data;
        }

        function changeInstructorTypeAvailability(newValue, oldValue) {
            if (oldValue != "") {
                var obj = JSON.parse(oldValue);
                vm.formData.instructorTypes[obj.id].availabe = true;
            }
            vm.formData.instructorTypes[newValue.id].availabe = false;
        }

        function getAvailableInstructorTypes() {
            return vm.formData.instructorTypes.filter(function (instructorType) {
                return instructorType.availabe;
            });
        }

        function next() {
            $state.go($scope.navigation[3].state);
        }

        function back() {
            $state.go($scope.navigation[1].state);                       
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('schoolings.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.schooling');
            }
        }
    }
})();
