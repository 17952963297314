(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('usersDataService', users);

    users.$inject = ['$http', 'logger', '$q', 'roleTranslatorFilter', 'blobHelper', 'dateHelper'];

    function users($http, logger, $q, roleTranslatorFilter, blobHelper, dateHelper) {
        var service = {
            getUserInfo: getUserInfo,
            getAllUsers: getAllUsers,
            getAllUsersAdvanced: getAllUsersAdvanced,
            getUserById: getUserById,
            getUserEntityRoles: getUserEntityRoles,
            transferUserEntityRoles: transferUserEntityRoles,
            //searchActiveUsers: searchActiveUsers,
            searchActiveUsers2: searchActiveUsers2,
            searchForActiveUsers: searchForActiveUsers,
            //searchKrpanAssociates: searchKrpanAssociates,
            addUser: addUser,
            editUser: editUser,
            editUserProfile: editUserProfile,
            registerUser: registerUser,
            joinUserProfiles: joinUserProfiles
        };

        return service;

        function getUserInfo() {
            return $http.get('/api/roles?' + new Date().toString())
                    .then(function (data) {
                        return data.data;
                    }, function (data, status, headers, config) {
                        if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                    });
        }

        function getAllUsersAdvanced(filter) {
            return $http.post('/api/users/filter', filter)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getAllUsers() {
            return $http.get('/api/users?' + new Date().toString())
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getUserById(userId) {
            return $http.get('/api/users/' + userId)
                .then(function (data) {
                    angular.forEach(data.data.roles, function (role, index) {
                        role.name = roleTranslatorFilter(role);
                    });
                    data.data.consentDate = dateHelper.convertFromStringToDate(data.data.consentDate);

                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getUserEntityRoles(userId) {
            return $http.get('api/users/' + userId + '/entityroles')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function transferUserEntityRoles(user, selectedEventTypeRoles) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(selectedEventTypeRoles));

            return $http.put(`api/users/transfer/${user[0]}/${user[1]}/entityroles`, fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //@Deprecated - old function that returned everything (didn't allow constrained searches, except for 1 user)
        /*function searchActiveUsers(query, userId) {
            if (userId != null) {
                return $http.get('api/users/active/search/' + query + '/' + userId).then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    logger.log("Failed to fetch all active users that contains " + query);
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
            }
            else {
                return $http.get('api/users/active/search/' + query + '/' + null).then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    logger.log("Failed to fetch all active users that contains " + query);
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
            }

        }*/

        //@New - replaced all instances of searchActiveUsers & searchKrpanAssociates
        function searchForActiveUsers(query, userIds, searchType) {
            let existingUserIds = [];
            let search = 1; //Update to enums

            if (userIds != null && Array.isArray(userIds)) {
                existingUserIds = userIds;
            }

            if (searchType != null && Number.isInteger(searchType)) {
                search = searchType;
            }

            const simpleSearch = {
                query: query,
                userIds: existingUserIds,
                searchType: search
            }

            return $http.post('api/users/active/search', simpleSearch).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch all active users that contains " + query);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //@Deprecated - Replaced with unified search function searchForActiveUsers that also allows constraint searches
        /*function searchKrpanAssociates(query) {
            return $http.get('api/users/activeKrpanAssociates/search/' + query).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch all active krpan associates that contain " + query);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }*/

        function searchActiveUsers2(query) {
            return $http.get('api/users/active/search2/' + query).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch all active users that contains " + query);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
                        
        function addUser(user) {
            var fd = new FormData();

            if (user.picture) {
                var blob = blobHelper.dataURItoBlob(user.picture);
                user.picture = null;
                fd.append('picture', blob);
            }
            
            fd.append('item', JSON.stringify(user));

            return $http.post('api/users', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editUser(user) {
            var fd = new FormData();

            if (user.picture) {
                var blob = blobHelper.dataURItoBlob(user.picture);
                user.picture = null;
                fd.append('picture', blob);
            }
            
            fd.append('item', JSON.stringify(user));

            return $http.put('api/users', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editUserProfile(user) {
            var fd = new FormData();

            if (user.picture) {
                var blob = blobHelper.dataURItoBlob(user.picture);
                user.picture = null;
                fd.append('picture', blob);
            }

            fd.append('item', JSON.stringify(user));

            return $http.put('api/users/profile', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function joinUserProfiles(user) {
            return $http.put(`api/users/copy/${user[0]}/${user[1]}`).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function registerUser(user) {
            var fd = new FormData();

            if (user.picture) {
                var blob = blobHelper.dataURItoBlob(user.picture);
                user.picture = null;
                fd.append('picture', blob);
            }

            fd.append('item', JSON.stringify(user));

            return $http.put('api/users/register', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
