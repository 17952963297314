(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('bulletinsDataService', bulletins);

    bulletins.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function bulletins($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getBulletins: getBulletins,
            getBulletinById: getBulletinById,
            addBulletin: addBulletin,
            editBulletin: editBulletin,
            deleteBulletin: deleteBulletin
        };

        return service;

        function getBulletins() {
            return $http.get('api/bulletin').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getBulletinById(id) {
            return $http.get('api/bulletin/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addBulletin(bulletin) {
            bulletin.publishedOn = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(bulletin.publishedOn));

            return $http.post('api/bulletin', bulletin).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editBulletin(bulletin) {
            bulletin.publishedOn = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(bulletin.publishedOn));

            return $http.put('api/bulletin', bulletin).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteBulletin(id) {
            return $http.delete('api/bulletin/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
