(function () {
    'use strict';

    angular
        .module('app')
        .factory('competenceDataService', competences);

    competences.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function competences($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getCompetences: getCompetences,
            getCompetenceById: getCompetenceById,
            deleteCompetence: deleteCompetence,
            addCompetence: addCompetence,
            editCompetence: editCompetence
        };

        return service;

        

        function getCompetences() {
            return $http.get('api/competence').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCompetenceById(id) {
            return $http.get('api/competence/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteCompetence(id) {
            return $http.delete('api/competence/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCompetence(competence) {
            return $http.post('api/competence', competence).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCompetence(competence) {
            return $http.put('api/competence', competence).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
