(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('promotedEntitiesDataService', activities);

    activities.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function activities($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getPromotedEntities: getPromotedEntities,
            deletePromotedEntity: deletePromotedEntity,
            moveUpEntity: moveUpEntity,
            moveDownEntity: moveDownEntity,
            addPromotedEntity: addPromotedEntity
        };

        return service;

        function getPromotedEntities() {
            return $http.get('api/promotedEntities').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deletePromotedEntity(id) {
            return $http.delete('api/promotedEntities/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveUpEntity(id) {
            return $http.put('api/promotedEntities/' + id + '/moveUp').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveDownEntity(id) {
            return $http.put('api/promotedEntities/' + id + '/moveDown').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addPromotedEntity(entity) {
            return $http.post('api/promotedEntities', entity).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();