(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.survey')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolings.administration.activities.edit.surveySecondStep', {
                url: '/surveySecondStep',
                templateUrl: 'app/schoolings/administration/activities/edit/survey/secondStep.html',
                controller: 'SurveySecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                    isActivityEdit: true
                },
                resolve: {
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            })
            .state('schoolings.administration.activities.add.surveySecondStep', {
                url: '/surveySecondStep',
                templateUrl: 'app/schoolings/administration/activities/edit/survey/secondStep.html',
                controller: 'SurveySecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                    isActivityEdit: false
                },
                resolve: {
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            });
    }
})();
