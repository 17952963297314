(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.lastStep')
        .controller('LastStep', lastStep);

    lastStep.$inject = ['logger', 'activitiesDataService', 'exceptionHelper', 'activitiesWizardDataService', '$state', '$scope', 'statistic', '$stateParams', 'dateHelper', 'i18nFilter', 'status'];

    function lastStep(logger, activitiesDataService, exceptionHelper, activitiesWizardDataService, $state, $scope, statistic, $stateParams, dateHelper, i18nFilter, status) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.end = end;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;
        vm.statistic = statistic;
        vm.status = status;

        function end() {
            var item = angular.copy(vm.item);
                        
            if (vm.isEdit) {
                activitiesDataService.editActivity(item).then(function (additionalData) {
                    logger.success(i18nFilter('translations.activity_operation_edit_success_message', additionalData.activityName));
                    $state.go("educations.administration.activities", $stateParams, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
            else {
                activitiesDataService.addActivity(item).then(function (additionalData) {
                    logger.success(i18nFilter('translations.activity_operation_add_success_message', additionalData.activityName));
                    $state.go("educations.administration.activities", $stateParams, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function previous() {
            $state.go($scope.navigation[$scope.navigation.length - 2].state);
        }
    }
})();
