(function () {
    'use strict';

    angular
        .module('app.surveys')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys', {
                url: '/surveys/{surveyId}',
                parent: 'base',
                templateUrl: 'app/surveys/surveys.html',
                controller: 'Surveys',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                },
                resolve: {                    
                    survey: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getSurveyById($stateParams.surveyId);
                    }]
                }
            })
            .state('surveys.home', {
                url: '/home',
                templateUrl: 'app/surveys/home/home.html',
                controller: 'SurveyHome',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                },
                resolve: {
                    survey: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getSurveyById($stateParams.surveyId);
                    }]
                }
            });
    }
})();
