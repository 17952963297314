(function () {
    'use strict';

    angular
        .module('app.certificateSigner')
        .controller('certificateSignerTable', certificateSignerTable);

    certificateSignerTable.$inject = ['i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'certificateSignerService', '$scope', '$state', 'documentCertificationSign', '$location', 'documentsDataService'];

    function certificateSignerTable(i18nFilter, dateFilter, exceptionHelper, logger, certificateSignerService, $scope, $state, documentCertificationSign, $location, documentsDataService) {

        var vm = this;

        let requestid = $location.search().requestid;
        let status = $location.search().status;

        if (requestid && status) {
            certificateSignerService.updateSignerWebServiceData({ requestid, status }).then(function (data) {
            }, function (error) {
            });
        }

        vm.signEnabled = signEnabled;
        vm.sign = sign;
        vm.updateSigningData = updateSigningData;
        vm.downloadSignedCertificate = downloadSignedCertificate;

        vm.documentCertificationSign = [
            {
                id: documentCertificationSign.waiting,
                format: i18nFilter('translations.general_certificate_sign_status_waiting')
            },
            {
                id: documentCertificationSign.signed,
                format: i18nFilter('translations.general_certificate_sign_status_signed')
            }
        ];



        function signEnabled(){
            if (vm.gridInstance) {
                let selectedRows = vm.gridInstance.getSelectedRowsData();        
                let enableSignButton = selectedRows.length && selectedRows.every((item) => {
                    return item.certificateSignStatus === 2;
                });

                return enableSignButton;
            }

            return false;
        }


        function updateSigningData(documents) {
            certificateSignerService.signDocument(documentToSign).then(function (data) {
            }, function (error) {
            });
        }


        function sign(documents) {
            let documentsToSign = documents.map((document) => {
                return {
                    documentId: document.documentId,
                    type: document.type
                }
            });

            var documentToSign = {
                documents: documentsToSign,
                callbackUrl: $location.absUrl()
            };


            certificateSignerService.signDocument(documentToSign).then(function (data) {
            }, function (error) {
                d.reject();
            });
        }

        function downloadSignedCertificate(document) {
            documentsDataService
                .downloadDocument(
                    "api/documentsDb/download/"+document.documentId,
                    "Potrdilo-" + document.documentName + ".pdf"
                )
                .then(
                    function (data) { },
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        vm.localeMessages = {
            sign: i18nFilter('translations.manage_sign_certificates'),
            download: i18nFilter('translations.manage_download_signed_certificate')
        };

        var columns = [
            {
                dataField: 'firstName',
                caption: "Ime",
                alignment: 'left',
                //width: 200,
                allowSorting: true
            },
            {
                dataField: 'lastName',
                caption: "Priimek",
                alignment: 'left',
                //width: 200,
                allowSorting: true
            },
            {
                dataField: 'eventName',
                caption: "Dogodek",
                alignment: 'left',
                //width: 550,
                allowSorting: true
            },
            {
                dataField: 'documentTitle',
                caption: "Dokument",
                alignment: 'left',
                //width: 550,
                allowSorting: true
            },
            {
                dataField: 'certificateSignStatus',
                caption: "Status dokumenta",
                alignment: 'left',
                //width: 200,
                allowSorting: true,
                selectedFilterOperation: "=",
                filterValue: 2, // waiting for signing
                lookup: {
                    dataSource: vm.documentCertificationSign,
                    valueExpr: "id",
                    displayExpr: "format",
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                //width: 75,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];


        vm.dataGridOptions = {
            dataSource:  new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/CertificateSignOData/GetCertificatesForSigning",
                    key: "enrollmentDocumentId",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            };
                        }
                    }
                }
            }),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                showNavigationButtons: true
            },
            selection: {
                mode: 'multiple',
                selectAllMode: 'page'
            },
            cellHintEnabled: true,
            hoverStateEnabled: true,
            wordWrapEnabled: false,
            sorting: {
                mode: 'single'
            },
            masterDetail: {
                enabled: false,
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
        };

    }
})();
