(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('attemps', attempsFilter);

    attempsFilter.$inject = ['logger', 'i18nFilter'];

    function attempsFilter(logger, i18nFilter) {
        return function (input) {
            var value = Number(input)
            switch (value) {
                case -1:
                    return i18nFilter('translations.general_gui_unlimited');
                default:
                    return input;
            }
        };
    }
})();