(function () {
    'use strict';
    angular
        .module('app.schoolingSchedulePacks')
        .controller('EmployeeContractModal', EmployeeContractModal);

    EmployeeContractModal.$inject = ['$uibModalInstance', '$stateParams', 'dateHelper', 'budgetEntities', 'usersDataService', 'logger', 'contractsDataService', 'exceptionHelper', 'organizationsDataService', 'i18nFilter', '$scope', 'employeeRow', 'contractingPartyType'];

    function EmployeeContractModal($uibModalInstance, $stateParams, dateHelper, budgetEntities, usersDataService, logger, contractsDataService, exceptionHelper, organizationsDataService, i18nFilter, $scope, employeeRow, contractingPartyType) {
        var vm = this;

        vm.item = {};

        vm.cancel = cancel;
        vm.add = add;
        vm.areDatesValid = areDatesValid;
        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.startDateValidate = startDateValidate;
        vm.endDateValidate = endDateValidate;
        vm.refreshUsers = refreshUsers;
        vm.onPartySelect = onPartySelect;
        vm.employeeRow = employeeRow;
        vm.item.amount = employeeRow.executionPrice;

        vm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
        };

        vm.formData = {
            entities: budgetEntities,
            signingTimeConfig: {
                type: 'date',
                width: '100%',
                inputAttr: {
                    'name': 'signingDate',
                    'ng-required': 'false'
                },
                bindingOptions: {
                    value: 'vm.item.signingDate'
                },
            },
            startTimeConfig: {
                type: 'date',
                width: '100%',
                onValueChanged: function (e) {
                    vm.endDatevalidator.validate();
                },
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.start'
                }
            },
            startDateValidationOps: {
                validationRules: [
                    { type: 'required', message: i18nFilter('translations.general_validation_message_start_date_is_either_blank_or_invalid') },
                    { type: 'custom', validationCallback: vm.startDateValidate, reevaluate: true }
                ],
                onInitialized: function (e) {
                    vm.startDatevalidator = e.component;
                }
            },
            endTimeConfig: {
                type: 'date',
                width: '100%',
                onValueChanged: function (e) {
                    vm.startDatevalidator.validate();
                },
                inputAttr: {
                    'name': 'endDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.end'
                }
            },
            endDateValidationOps: {
                validationRules: [
                    { type: 'required', message: i18nFilter('translations.general_validation_message_end_date_is_either_blank_or_invalid') },
                    { type: 'custom', validationCallback: vm.endDateValidate, reevaluate: true }
                ],
                onInitialized: function (e) {
                    vm.endDatevalidator = e.component;
                }
            },
            organizations: [],
            usersIsLoading: false,
            organizationsIsLoading: false
        };

        vm.contractingPartyType = translateCollections(contractingPartyType);
        if (vm.item.contractingPartyType != null)
            vm.item.contractingPartyType = vm.contractingPartyType[vm.item.contractingPartyType];


        // TODO
        //vm.instructorForContract = vm.parentScope.schedule.instructors
        //    .filter(instructori =>
        //        instructori.instructor.id === instructor
        //)[0];

        //vm.item.contractors = [vm.instructorForContract.instructor];

        
        //var entityToAdd = budgetEntities.filter(function (entity) { return entity.id == parentScope.education.id })[0];

        //vm.item.entities = [{
        //    id: entityToAdd.id,
        //    name: entityToAdd.name,
        //    code: entityToAdd.code,
        //    type: entityToAdd.type
        //}];


        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });
            return data;
        }

        function startDateValidate(options) {
            if ((vm.item.end != "" && vm.item.end != null) && dateHelper.createNewDateTimeWithoutTime(options.value) > dateHelper.createNewDateTimeWithoutTime(vm.item.end)) {
                options.rule.message = i18nFilter('translations.general_validation_message_start_date_should_be_less_than_end_date');
                return false;
            }

            return true;
        };

        function endDateValidate(options) {
            if ((vm.item.start != "" && vm.item.start != null) && dateHelper.createNewDateTimeWithoutTime(options.value) < dateHelper.createNewDateTimeWithoutTime(vm.item.start)) {
                options.rule.message = i18nFilter('translations.general_validation_message_end_date_should_be_greater_than_start_date');
                return false;
            }

            return true;
        };

        function areDatesValid() {
            if (!vm.item.start || !vm.item.end) {
                return false;
            }
            var start = vm.item.start.getTime();
            var end = vm.item.end.getTime();

            return start <= end;
        }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.usersIsLoading = false;
                logger.error(i18nFilter("translations.contract_exception_message_fetching_users"));
            });
        }

        function add() {
            logger.log("Create and link contract: ", vm.item);

            var scheduleId = $stateParams.schoolingScheduleId;
            var itemCopy = angular.copy(vm.item);
            if (itemCopy.amount != null)
                itemCopy.amount = parseFloat(itemCopy.amount.toString().replace(",", "."));

            itemCopy.contractingPartyType = itemCopy.contractingPartyType.id;
            
            contractsDataService.addSchoolingScheduleContractCreate(itemCopy, employeeRow.id, scheduleId).then(function (data) {
                logger.success(i18nFilter('translations.contract_queue_add'));
                $uibModalInstance.close();
            }, function (error) {
                exceptionHelper.handleException(error);
                $uibModalInstance.$dismiss();
            });
        }

        function onPartySelect() {
            vm.item.contractingParty = vm.item.contractingPartyAjpes.name;
            vm.item.address = vm.item.contractingPartyAjpes.address;
            vm.item.taxId = vm.item.contractingPartyAjpes.taxId;
            vm.item.iban = vm.item.contractingPartyAjpes.iban;
        }

        function cancel() {
            $scope.$dismiss();
        }
       
    }
})();


