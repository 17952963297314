(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditEducationThirdStep', thirdStep);

    thirdStep.$inject = ['logger', 'editEducationHelper', '$scope', '$state', '$stateParams', 'activities', 'i18nFilter'];

    function thirdStep(logger, editEducationHelper, $scope, $state, $stateParams, activities, i18nFilter) {
        var vm = this;

        //Inherit from parent scope
        vm.navigation = $scope.navigation;

        vm.item = editEducationHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.formData = {
            activities: activities
        };

        vm.logData = logData;
        vm.previous = previous;
        vm.next = next;
        vm.cancel = cancel;
        vm.save = save;


        //#region table
        var columns = [
            {
                dataField: 'orderNo',
                caption: i18nFilter('translations.general_gui_order'),
                alignment: 'left',
                width: 120,
                sortOrder: 'asc',
                allowSorting: false
            },
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowSorting: false
            },
            {
                caption: i18nFilter('translations.general_gui_type'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'educationActivityNameTemplate'
            },
            {
                dataField: 'pointsTotal',
                caption: i18nFilter('translations.general_gui_total_points'),
                alignment: 'left',
                width: 120,
                allowSorting: false
            },
            {
                caption: i18nFilter('translations.general_gui_points_for_success'),
                alignment: 'left',
                width: 120,
                allowSorting: false,
                cellTemplate: 'enoughPointsTemplate'
            },
            {
                dataField: 'isRequiredForEducationPass',
                caption: i18nFilter('translations.activity_gui_label_required_activity'),
                //alignment: 'left',
                allowSorting: false
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.formData.activities,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion




        function previous() {
            $state.go(vm.navigation[1].state, $stateParams);
        }

        function next() {            
            $state.go(vm.navigation[3].state, $stateParams);
        }

        function save() {
            var item_copy = editEducationHelper.setCodeListData(vm.item);
            editEducationHelper.setData(item_copy);
            editEducationHelper.done(vm.isEdit,true);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('educations.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.education');
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }
    }
})();
