(function () {
    'use strict';

    angular
        .module('app')
        .controller('eFormProgramSelfPayerHelp', eFormProgramSelfPayerHelp);

    eFormProgramSelfPayerHelp.$inject = ['$scope', 'templateVariables', 'i18nFilter'];

    function eFormProgramSelfPayerHelp($scope, templateVariables, i18nFilter) {
        var vm = this;
        vm.templateVariables = templateVariables;
        vm.ok = ok;

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.template_templateVariable_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'variableName',
                caption: i18nFilter('translations.template_templateVariable_variableName'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'tableName',
                caption: i18nFilter('translations.template_templateVariable_tableName'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'tableValue',
                caption: i18nFilter('translations.template_templateVariable_tableValues'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
        ];

        vm.dataGridOptions = {
            dataSource: vm.templateVariables,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function ok() {
            $scope.$dismiss();

        };
    }
})();
