(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.exam')
        .controller('ExamSecondStep', examSecondStep);

    examSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$scope', '$state', 'exams', 'rolesDataService', 'myQtestUrlConfiguration', 'examsDataService', '$window', 'locale', 'status'];

    function examSecondStep(logger, activitiesWizardDataService, $scope, $state, exams, rolesDataService, myQtestUrlConfiguration, examsDataService, $window, locale, status) {

        var failToGetMyQTestLinkErrorMessage = "";

        function setTranslations() {
            locale.ready('translations').then(function () {
                failToGetMyQTestLinkErrorMessage = locale.getString('translations.exception_message_fail_to_get_myQTest_link');
            });
        }

        setTranslations();


        var vm = this;
        vm.title = 'secondStep';
        vm.end = end;
        vm.previous = previous;
        vm.exams = exams;
        vm.item = activitiesWizardDataService.getData();
        vm.isEdit = $state.current.data.isActivityEdit;
        vm.status = status;
        vm.spinOptions = {
            verticalbuttons: true
        };

        var token = '';

        vm.showMyQtestConfig = rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationOwner']);
        if (vm.showMyQtestConfig) {
            examsDataService.getToken().then(function (data) {
                token = encodeURIComponent(data);
            }, function (error) {                
                logger.error(failToGetMyQTestLinkErrorMessage);
            });
        }

        vm.openExamConfiguration = function () {
            if (vm.item.examId) {
                $window.open(myQtestUrlConfiguration + "?token=" + token + "&examId=" + vm.item.examId.id, "_blank");
            }
        }

        if (vm.isEdit) {
            vm.item.noDaysBetweenAttempts = vm.item.noDaysBetweenAttempts.toString();
            vm.item.noAttemptsAllowed = vm.item.noAttemptsAllowed + '';
            angular.forEach(exams, function (element, index) {
                if (element.id == vm.item.examId) {
                    vm.item.examId = element;
                }
            });
        }
        else {
            vm.item.noDaysBetweenAttempts = '0'; // Inicijalna vrijednost mora biti u string literalima, jer html input interpretira vrijednosti kao string
        }

        function end() {
            activitiesWizardDataService.done(vm.isEdit);
        }

        function previous() {
            //logger.log('Navigation obj: ', $scope.navigation);
            $state.go($scope.navigation[0].state);
        }
    }
})();
