(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('surveyTile', {
            templateUrl: 'app/catalogs/components/surveyTile/surveyTile.html',
            controller: surveyTile,
            controllerAs: 'vm',
            bindings: {
                survey: '='
            }
        });

    surveyTile.$inject = ['$location'];

    function surveyTile($location) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'surveyTile';
        vm.formData = {
            truncateOptions: {
                watch: 'window',
                wrap: 'letter'
            }
        };
        vm.dateTime = new Date().getTime();        
    }
})();
