(function () {
    'use strict';

    angular
        .module('app')
        .controller('emailFormCtrlCip',emailFormCtrlCip);

    emailFormCtrlCip.$inject = ['logger', 'info', '$scope', 'i18nFilter', 'schoolingsDataService', '$sanitize'];

    function emailFormCtrlCip(logger, info, $scope, i18nFilter, schoolingsDataService, $sanitize) {
        /* jshint validthis:true */
        var vm = this;

        vm.question = i18nFilter('translations.schooling_gui_sidebar_help_question_for');
        vm.formName = i18nFilter('translations.schooling_gui_sidebar_help_contact_us');
        vm.info = info;
        vm.subject = vm.question + " " + vm.info.schoolingName;
        vm.cancel = cancel;
        vm.send = send;

        function send() {    
            var mail = {
                body: vm.body,
                subject: vm.subject
            };
            mail.body = DOMPurify.sanitize(mail.body);
            schoolingsDataService.sendSupportMail(vm.info.schoolingId, mail);      
            logger.success(i18nFilter("translations.schooling_help_mail_success"));
            $scope.$dismiss();
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
