(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('executionStylesCatalogFilter', {
            templateUrl: 'app/catalogs/filters/executionStyles/executionStylesCatalogFilter.html',
            controller: executionStylesCatalogFilter,
            controllerAs: 'vm',
            bindings: {
                data: '=',
                onChanged: '='
            }
        });

    executionStylesCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function executionStylesCatalogFilter(logger, i18nFilter) {
        var vm = this;
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 
    
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.catalog_gui_execution_style_filter_placeholder")
        };

    }
})();
