(function () {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('office365ribbon', office365ribbon);

    office365ribbon.$inject = ['adalAuthenticationService', '$sessionStorage', '$rootScope', 'appName', '$state', 'sicasLogOutUrl', 'postLogoutRedirectUri', 'newAdminPortalUrl', '$window'];

    function office365ribbon(adalAuthenticationService, $sessionStorage, $rootScope, appName, $state, sicasLogOutUrl, postLogoutRedirectUri, newAdminPortalUrl, $window) {
        // Usage:
        //     <office365ribbon></office365ribbon>
        var directive = {
            restrict: 'E',
            templateUrl: 'app/blocks/directives/office365ribbon/office365ribbon.html',
            link: link
        };
        return directive;

        function link(scope, element, attrs) {
            scope.user = $rootScope.userInfo;
            if ($sessionStorage.userInfo && $sessionStorage.userInfo.displayName) {
                scope.accountName = $sessionStorage.userInfo.displayName;
            }
            else if (scope.user.profile.unique_name){
                scope.accountName = scope.user.profile.unique_name;
            }
            else {
                scope.accountName = scope.user.profile.given_name;
            }
            scope.appName = appName;
            scope.userName = $rootScope.userInfo.profile.name ? $rootScope.userInfo.profile.name : $rootScope.userInfo.profile.unique_name;
            
            scope.userAvatar = 'assets/img/2000px-Microsoft_logo.svg.png';

            scope.signOut = function () {
                var sicalIFrame = document.getElementById('sical-iframe');
                var adfsIFrame = document.getElementById('adfs-iframe');
                var tenantId = $sessionStorage.userInfo.tenantId;
                sicalIFrame.src = sicasLogOutUrl;

                sicalIFrame.onload = function () {
                    $sessionStorage.userInfo = undefined;
                    $sessionStorage.toState = undefined;
                    $sessionStorage.toParams = undefined;
                    $sessionStorage.rolesInProgress = undefined;
                    adfsIFrame.src = adalAuthenticationService.logOutUrl();                    
                };

                adfsIFrame.onload = function () {
                    window.location.href = postLogoutRedirectUri[tenantId];
                };
            };

            scope.myProfile = function () {
                $window.location.href = newAdminPortalUrl[$sessionStorage.userInfo.tenantResourceKey] + "profile/edit";
            };
        }

    }

})();
