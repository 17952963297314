(function () {
    'use strict';

    angular
        .module('app.tenants')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('tenants', {
                parent: 'base',
                url: '/manage/tenants',
                templateUrl: 'app/manage/tenants/tenants.html',
                controller: 'Tenants',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['GlobalAdmin']
                },
                resolve: {
                    tenants: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenants();
                    }]
                }
            })
            .state('tenants.baseAdd', {
                url: '/add',
                templateUrl: 'app/manage/tenants/baseStep/baseStep.html',
                controller: 'EditTenants',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add',
                    roles: ['GlobalAdmin']
                },
                resolve: {
                    tenant: ['tenantDataService', '$stateParams', function (tenantDataService, $stateParams) {
                        return {};
                    }],
                    cultures: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getCultures();
                    }] 
                }
            })
            .state('tenants.baseAdd.firstStep', {
                url: '/firstStep',
                templateUrl: 'app/manage/tenants/firstStep/firstStep.html',
                controller: 'EditTenantFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add: General data',
                    roles: ['GlobalAdmin']
                }
            })
            .state('tenants.baseAdd.secondStep', {
                url: '/secondStep',
                templateUrl: 'app/manage/tenants/secondStep/secondStep.html',
                controller: 'EditTenantSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add: File management',
                    roles: ['GlobalAdmin']
                }
            })
            .state('tenants.baseAdd.thirdStep', {
                url: '/thirdStep',
                templateUrl: 'app/manage/tenants/thirdStep/thirdStep.html',
                controller: 'EditTenantThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add: Options',
                    roles: ['GlobalAdmin']
                }
            })                
            .state('tenants.baseEdit', {
                url: '/edit/:id',
                templateUrl: 'app/manage/tenants/baseStep/baseStep.html',
                controller: 'EditTenants',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit',
                    roles: ['GlobalAdmin']
                },
                resolve: {
                    tenant: ['tenantDataService', '$stateParams', function (tenantDataService, $stateParams) {
                        return tenantDataService.getTenantById($stateParams.id);
                    }],
                    cultures: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getCultures();
                    }] 
                }
            })
            .state('tenants.baseEdit.firstStep', {
                url: '/firstStep',
                templateUrl: 'app/manage/tenants/firstStep/firstStep.html',
                controller: 'EditTenantFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit: General data',
                    roles: ['GlobalAdmin']
                }
            })
            .state('tenants.baseEdit.secondStep', {
                url: '/secondStep',
                templateUrl: 'app/manage/tenants/secondStep/secondStep.html',
                controller: 'EditTenantSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit: File management',
                    roles: ['GlobalAdmin']
                }
            })
            .state('tenants.baseEdit.thirdStep', {
                url: '/thirdStep',
                templateUrl: 'app/manage/tenants/thirdStep/thirdStep.html',
                controller: 'EditTenantThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit: Options',
                    roles: ['GlobalAdmin']
                }
            });
    }
})();
