(function () {
    'use strict';

    angular
        .module('app.schoolings.activities')
        .component('videoActivity', {
            templateUrl: 'app/schoolings/activities/components/video/video.html',
            controller: videoActivity,
            controllerAs: 'vm',
            bindings: {
                activity: '=', //access via vm.acitivty
                learningActivityId: '=',
                ignoreFirstOpenRule: '='
            }
        });

    videoActivity.$inject = ['logger', 'learningActivitySuccess', 'learningActivityState', 'activitiesDataService', 'exceptionHelper', '$sce', 'i18nFilter', 'dateHelper', 'learningPlanItemStatus'];

    function videoActivity(logger, learningActivitySuccess, learningActivityState, activitiesDataService, exceptionHelper, $sce, i18nFilter, dateHelper, learningPlanItemStatus) {
        var vm = this;
        var ignoreNextToggle = false;
        

        vm.localeMessages = {
            activityStart: i18nFilter('translations.activity_gui_tooltip_activity_start'),
            activityFinish: i18nFilter('translations.activity_gui_tooltip_activity_finish'),
            activityEstimatedDuration: i18nFilter('translations.activity_gui_tooltip_activity_estimated_duration'),
            activityScore: i18nFilter('translations.activity_gui_tooltip_activity_score'),
            activityTypeTooltip: i18nFilter('translations.filter_schooling_activity_type_video')
        };

        vm.formData = {
            //Flags
            isStarted: isActivityDateStarted(),
            isExpanded: vm.ignoreFirstOpenRule ? false : angular.copy(vm.activity.isOpen),
            isLate: isUserLate(),
            //Data
            learningActivitySuccess: learningActivitySuccess,
            learningActivityState: learningActivityState,

            youtube: false,
            office365Video: false,

            iFrameUrl: undefined,
            learningPlanItemStatus: learningPlanItemStatus
        };
        
        vm.formData.iFrameUrl = $sce.trustAsResourceUrl(getEmbedUrl(vm.activity.data.videoUrl));

        vm.confirmFinish = confirmFinish;
        vm.toggle = toggle;

        // Otvori aktivnost ukoliko je prethodno bila odabrana
        if (vm.learningActivityId && vm.activity.learningActivityId == vm.learningActivityId) {
            vm.formData.isExpanded = true;
        }




        function confirmFinish() {
            vm.ignoreNextToggle = true;
            activitiesDataService.confirmFinishVideoActivity(vm.activity.learningActivityId).then(function (data) {
                logger.success(i18nFilter("translations.activity_queue_finish"));
                vm.ignoreNextToggle = false;
            }, function (error) {
                vm.ignoreNextToggle = false;
                exceptionHelper.handleException(error);
            });
        }

        //#region Handlanje klika za expand
        function toggle() {
            if (!ignoreNextToggle) {
                vm.formData.isExpanded = !vm.formData.isExpanded;
            }
            else {
                ignoreNextToggle = false;
            }
        }
        //#endregion

        //#region Time 
        function isActivityDateStarted() {
            if (vm.activity.data.startDate) {
                vm.activity.data.startDate = dateHelper.convertFromStringToDate(vm.activity.data.startDate);
                var date = angular.copy(vm.activity.data.startDate);

                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "true" tako da se može pokrenuti
                return true;
            }
        }

        function isUserLate() {
            if (vm.activity.deadLine) {
                vm.activity.deadLine = dateHelper.convertFromStringToDate(vm.activity.deadLine);
                var date = angular.copy(vm.activity.deadLine);

                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "false" tako da ispadne da ne kasni
                return false;
            }
        }
        //#endregion

        //#region Url helper
        //TODO: Re factor
        function getEmbedUrl(url) {
            if (!url) {
                vm.formData.youtube = false;
                vm.formData.office365Video = false;
                return;
            }
            if (url.indexOf('sharepoint.com') !== -1) {
                //Office365Video
                vm.formData.youtube = false;
                vm.formData.office365Video = true;

                var vid = getUrlValueByParametarName('vid', url);
                var chid = getUrlValueByParametarName('chid', url);
                var host = url.substring(0, url.indexOf('/_layouts/15/') + 13);

                var result = host + 'VideoEmbedHost.aspx?chId=' + chid.replace(/-/g, '%2D') + '&vId=' + vid.replace(/-/g, '%2D') + '&width=640&height=360&autoPlay=false&showInfo=true';
                return result;
            } else if (url.indexOf('youtube.com') !== -1 || url.indexOf('youtu.be') !== -1) {
                //Youtube video
                vm.formData.youtube = true;
                vm.formData.office365Video = false;
                return 'https://www.youtube.com/embed/' + getYoutubeIdFromUrl(url);
            }
        }

        function getUrlValueByParametarName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        function getYoutubeIdFromUrl(url) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var match = url.match(regExp);
            return (match && match[7].length == 11) ? match[7] : false;
        }
        //#endregion
    }
})();
