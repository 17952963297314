(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('notificationsDataService', notifications);

    notifications.$inject = ['$http', 'logger', '$q', 'dateHelper', 'mailHelper'];

    function notifications($http, logger, $q, dateHelper, mailHelper) {
        var service = {
            getNotificationsSettings: getNotificationsSettings,
            editNotificationsSettings: editNotificationsSettings,
            getNotificationsTemplates: getNotificationsTemplates,
            getNotificationTemplateById: getNotificationTemplateById,
            editNotificationTemplate: editNotificationTemplate,
            getNotificationsHistory: getNotificationsHistory,
            getNotificationHistoryDetails: getNotificationHistoryDetails,
            sendTestMail: sendTestMail,
            getNotificationVariables: getNotificationVariables,
            getNewsletterHistory: getNewsletterHistory
        };

        return service;

        function getNotificationsSettings() {
            return $http.get('/api/notifications')
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editNotificationsSettings(notificationConfig) {
            return $http.put('/api/notifications', notificationConfig).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendTestMail(notificationConfig) {
            return $http.post('api/notifications', notificationConfig).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to send test mail', data);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getNotificationsTemplates() {
            return $http.get('/api/notifications/templates')
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getNotificationTemplateById(id) {
            return $http.get('/api/notifications/templates/' + id)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editNotificationTemplate(notificationTemplate) {
            return $http.put('/api/notifications/templates', notificationTemplate).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }       

        function getNotificationsHistory(filter) {
            filter.lastSentAttempt.searchText1 = dateHelper.convertFromDateToString(filter.lastSentAttempt.searchText1);
            filter.lastSentAttempt.searchText2 = dateHelper.convertFromDateToString(filter.lastSentAttempt.searchText2);
            filter.lastSentAttempt.sortType = 'desc';
            
            return $http.post('api/notifications/history', filter).then(function (data) {
                angular.forEach(data.data.data, function (element, index) {
                    element.lastSentAttempt = dateHelper.convertFromStringToDate(element.lastSentAttempt);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get history', data);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getNewsletterHistory(filter) {
            return $http.get('api/notifications/templates/newsletterHistory', filter).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get newsletter history', data);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getNotificationHistoryDetails(id) {
            return $http.get('/api/notifications/history/' + id)
                .then(function (data, status, headers, config) {
                    if (data.data.mailSentExceptions != null && data.data.mailSentExceptions.exceptions != null)
                        angular.forEach(data.data.mailSentExceptions.exceptions, function (element, index) {
                            element.sentAttemptDateTime = dateHelper.convertFromStringToDate(element.sentAttemptDateTime);
                        });                    
                    return mailHelper.parseMail(data.data);
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getNotificationVariables(notificationIdentifier) {
            return $http.get('api/templates/' + notificationIdentifier + '/notificationVariables/').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
