(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .controller('EditProgramSecondStep', secondStep);

    secondStep.$inject = ['logger', '$state', 'editProgramHelper', '$scope', '$stateParams', 'templates', 'i18nFilter', '$uibModal', 'surveys', 'surveysDataService', 'emptyGuid', 'rolesDataService'];

    function secondStep(logger, $state, editProgramHelper, $scope, $stateParams, templates, i18nFilter, $uibModal, surveys, surveysDataService, emptyGuid, rolesDataService) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;

        vm.item = editProgramHelper.getData();

        vm.canGatheringEvidence = rolesDataService.isUserInRoles(['GlobalAdmin', 'EducationAdmin']);

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            generateDocuments: i18nFilter("translations.program_edit_gui_tooltip_info_generate_documents"),
            generateDocumentStamp: i18nFilter("translations.education_edit_gui_tooltip_info_generate_document_stamp"),
            generateDocumentSign: i18nFilter("translations.education_edit_gui_tooltip_info_generate_document_sign"),  
            survey: i18nFilter("translations.program_gui_tooltip_survey"),
            gatheringEvidence: i18nFilter("translations.education_edit_gui_tooltip_info_gathering_evidence"),
        };

        vm.formData = {
            templates: templates,
            surveys: surveys,
            surveyGroups: []
        };

        if (vm.isEdit) {           
            if (vm.item.surveyEnabled) {
                surveysDataService.getDdlSurveyQuestionGroupsBySurveyId(vm.item.survey.id).then(function (data) {
                    vm.formData.surveyGroups = data;

                    var item = {
                        id: emptyGuid,
                        name: i18nFilter('translations.education_gui_choose_survey_group_no_questions')
                    };
                    vm.formData.surveyGroups.splice(0, 0, item);
                }, function (error) {
                    logger.error(i18nFilter("translations.education_exception_message_fetching_survey_groups"));
                });
            }
        }

        vm.next = next;
        vm.cancel = cancel;
        vm.back = back;
        vm.openInstructorFeeHelp = openInstructorFeeHelp;
        vm.openCertificateHelp = openCertificateHelp;
        vm.removeCertificateTemplate = removeCertificateTemplate;
        vm.removeInstructorFeeTemplate = removeInstructorFeeTemplate;
        vm.openEFormSelfPayerHelp = openEFormSelfPayerHelp;
        vm.openEFormOrganizationHelp = openEFormOrganizationHelp;
        vm.surveyChange = surveyChange;
        vm.removeSurvey = removeSurvey;
        vm.removeSurveyGroup = removeSurveyGroup;
        vm.surveyEnabledChange = surveyEnabledChange;
        vm.save = save;

        function next() {
            $state.go($scope.navigation[2].state);
        }

        function back() {
            $state.go($scope.navigation[0].state);
        }

        function save() {
            var item_copy = angular.copy(vm.item);
            editProgramHelper.setData(item_copy);
            editProgramHelper.done(vm.isEdit, true);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('programs.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.program');
            }
        }

        function openInstructorFeeHelp() {
            $uibModal.open({
                templateUrl: 'app/programs/administration/edit/secondStep/instructorFeeHelp/programsInstructorFeeHelp.html',
                controller: 'programsInstructorFeeHelp',
                controllerAs: 'vm'
            });
        }

        function removeCertificateTemplate() {
            vm.item.template = undefined;
        }

        function removeInstructorFeeTemplate() {
            vm.item.instructorFeeTemplate = undefined;
        }

        function openCertificateHelp() {
            $uibModal.open({
                templateUrl: 'app/programs/administration/edit/secondStep/certificateHelp/certificateHelp.html',
                controller: 'certificateHelp',
                controllerAs: 'vm'
            });
        }

        function openEFormSelfPayerHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/programs/administration/edit/secondStep/eFormProgramSelfPayerHelp/eFormProgramSelfPayerHelp.html',
                controller: 'eFormProgramSelfPayerHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function openEFormOrganizationHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/programs/administration/edit/secondStep/eFormProgramOrganizationHelp/eFormProgramOrganizationHelp.html',
                controller: 'eFormProgramOrganizationHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function surveyChange() {
            if (vm.item.survey) {
                surveysDataService.getDdlSurveyQuestionGroupsBySurveyId(vm.item.survey.id).then(function (data) {
                    vm.formData.surveyGroups = data;
                    var item = {
                        id: emptyGuid,
                        name: i18nFilter('translations.education_gui_choose_survey_group_no_questions')
                    };
                    vm.formData.surveyGroups.splice(0, 0, item);
                }, function (error) {
                    logger.error(i18nFilter("translations.education_exception_message_fetching_survey_groups"));
                });
            }
        }

        function removeSurvey() {
            vm.item.survey = undefined;
            vm.item.surveyGroups = undefined;
        }

        function removeSurveyGroup() {
            vm.item.surveyGroups = undefined;
        }

        function surveyEnabledChange() {
            if (!vm.item.surveyEnabled) {
                vm.item.survey = undefined;
                vm.item.surveyGroups = undefined;
            }
        }
    }
})();
