(function () {
    'use strict';

    angular
        .module('app.programs.administration.delete')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('programs.administration.delete', {
                url: '/delete',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/programs/administration/delete/deleteProgram.html',
                        controller: 'DeleteProgram',
                        controllerAs: 'vm'
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('classicCatalog.program');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('programs.home', $stateParams);
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner', 'Instructor']
                },
                resolve: {
                },
                params: {
                    name: undefined
                }
            })
            ;
    }
})();
