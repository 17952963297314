(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('eventEducation', eventEducation);

    eventEducation.$inject = ['logger', 'documentsDataService'];
    
    function eventEducation(logger, documentsDataService) {
        // Usage:
        //     <eventEducation></eventEducation>
        // Creates:
        // 
        var directive = {
            link: link,
            templateUrl: 'app/blocks/directives/eventEducation/eventEducation.html',
            restrict: 'E',
            scope: {
                data: '=',
                user: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {

            scope.downloadCertificate = function () {
                documentsDataService.downloadDocument("api/learningPlans/" + scope.data.learningPlanItemId + "/certificate", "Potrdilo.pdf");
            };
        }
    }

})();
