(function () {
    'use strict';

    angular
        .module('app.educations.administration.delete')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.delete', {
                url: '/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/educations/administration/delete/delete.html',
                        controller: 'DeleteEducation',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            // redirect done after deletion of education is successfull
                            $state.go('classicCatalog.education');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('educations.home', { id: $stateParams.id });
                        });
                }],
                params: {
                    educationName: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin']
                }
            });
    }
})();
