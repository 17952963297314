(function () {
    'use strict';

    angular
        .module('app.programs')
        .controller('ProgramsCtrl', programs);

    programs.$inject = [
        'logger',
        'program',
        '$rootScope',
        '$location',
        'rolesDataService',
        'educationsDataService',
        'isProgramOwner',        
        'i18nFilter'
    ];

    function programs(
        logger,
        program,
        $rootScope,
        $location,
        rolesDataService,
        educationsDataService,
        isProgramOwner,        
        i18nFilter
    ) {
        var vm = this;

        vm.item = program;
        vm.isSmallCover = $rootScope.isSmallCover;


        vm.status = status;

        vm.localeMessages = {        };

        vm.formData = {
            isSmallCover: $rootScope.isSmallCover,
            navAuth: {
                about: rolesDataService.isUserInRoles(['Learner']),
                educations: rolesDataService.isUserInRoles(['Learner']),
                documents: rolesDataService.isUserInRoles(['Learner']),
                //Vlasnik trenutne edukacije, Upravitelj kataloga, instruktor na barem jednom terminu unutar edukacije, upravitelj zadataka
                manage: isProgramOwner || rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationAdmin']),

                manageEdit: isProgramOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageDelete: rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageLearners: isProgramOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
            }
        };


        vm.imageStyle = {
            "background-color": "#78C7C9",
            "min-height": "340px"
        };

        $rootScope.$on('smallCoverChanged', function () {
            vm.formData.isSmallCover = $rootScope.isSmallCover;
        });

        //Configuration for marking top level navigation properties if we are on sub level
        vm.isActive = function (view) {
            var path = $location.path();

            switch (view) {
                case '/home':
                    if (path.endsWith('/home')) {
                        return true;
                    }
                    break;
                case '/educations':
                    if (path.endsWith('/educations') && !path.endsWith('/administration/educations')) {
                        return true;
                    }
                    break;
                case '/learners':
                    if (path.endsWith('/learners')) {
                        return true;
                    }
                    break;
                case '/documents':
                    if (path.endsWith('/documents/fs') || path.endsWith('/documents/sp')) {
                        return true;
                    }
                    break;   
                case '/administration':
                    if (
                        path.endsWith('/administration/edit/about') 
                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
    }
})();
