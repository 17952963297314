(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('userTitlesDataService', userTitles);

    userTitles.$inject = ['$http', 'logger', '$q'];

    function userTitles($http, logger, $q) {
        var service = {
            getActiveBasicUserTitles: getActiveBasicUserTitles
        };

        return service;

        function getActiveBasicUserTitles() {
            return $http.get('api/userTitles/BasicUserTitles')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
    }
})();