(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('roleTranslator', roleFilter);

    roleFilter.$inject = ['logger', 'applicationRoles', 'i18nFilter'];

    function roleFilter(logger, applicationRoles, i18nFilter) {
        return function (input) {
            
            if (input == undefined) {
                return input;
            }
            
            var name = undefined;
                  
            //Dobio sam samo naziv role
            if (input.id === undefined) {
                if (input === 'GlobalAdmin') {
                    name = i18nFilter('translations.filter_role_global_admin');
                }
                else if (input === 'TenantAdmin') {
                    name = i18nFilter('translations.filter_role_tenant_admin');
                }
                else if (input === 'EducationAdmin') {
                    name = i18nFilter('translations.filter_role_education_admin');
                }
                else if (input === 'CatalogAdmin') {
                    name = i18nFilter('translations.filter_role_catalog_admin');
                }               
                else if (input === 'KBAdmin') {
                    name = i18nFilter('translations.filter_role_knowledge_base_admin');
                }
                else if (input === 'EducationOwner' || input === 'RealEducationOwner') {
                    name = i18nFilter('translations.filter_role_education_owner');
                }
                else if (input === 'Instructor') {
                    name = i18nFilter('translations.filter_role_instructor');
                }
                else if (input === 'Learner') {
                    name = i18nFilter('translations.filter_role_learner');
                }
                else if (input === 'EnrolledLearner') {
                    name = i18nFilter('translations.filter_role_enrolled_learner');
                }
                else if (input === 'Manager') {
                    name = i18nFilter('translations.filter_role_manager');
                }
                else if (input === 'ProgramOwner') {
                    name = i18nFilter('translations.filter_role_program_owner');
                }
                else if (input === 'SurveyAdmin') {
                    name = i18nFilter('translations.filter_role_survey_admin');
                }
                else if (input === 'SurveyOwner') {
                    name = i18nFilter('translations.filter_role_survey_owner');
                }
                else if (input === 'ExamAdmin') {
                    name = i18nFilter('translations.filter_role_exam_admin');
                }
                else if (input === 'ExamOwner') {
                    name = i18nFilter('translations.filter_role_exam_owner');
                }
                return name;
            }


            //Dobio sam objekt koji na sebi ima Id
            if (input.id === applicationRoles.globalAdmin) {
                name = i18nFilter('translations.filter_role_global_admin');
            }
            else if (input.id === applicationRoles.tenantAdmin) {
                name = i18nFilter('translations.filter_role_tenant_admin');
            }
            else if (input.id === applicationRoles.educationAdmin) {
                name = i18nFilter('translations.filter_role_education_admin');
            }
            else if (input.id === applicationRoles.catalogAdmin) {
                name = i18nFilter('translations.filter_role_catalog_admin');
            }            
            else if (input.id === applicationRoles.kbAdmin) {
                name = i18nFilter('translations.filter_role_knowledge_base_admin');
            }
            else if (input.id === applicationRoles.educationOwner) {
                name = i18nFilter('translations.filter_role_education_owner');
            }
            else if (input.id === applicationRoles.instructor) {
                name = i18nFilter('translations.filter_role_instructor');
            }
            else if (input.id === applicationRoles.learner) {
                name = i18nFilter('translations.filter_role_learner');
            }
            else if (input.id === applicationRoles.programOwner) {
                name = i18nFilter('translations.filter_role_program_owner');
            }
            else if (input.id === applicationRoles.surveyAdmin) {
                name = i18nFilter('translations.filter_role_survey_admin');
            }
            else if (input.id === applicationRoles.surveyOwner) {
                name = i18nFilter('translations.filter_role_survey_owner');
            }
            else if (input.id === applicationRoles.examAdmin) {
                name = i18nFilter('translations.filter_role_exam_admin');
            }
            else if (input.id === applicationRoles.examOwner) {
                name = i18nFilter('translations.filter_role_exam_owner');
            }

            return name;
        };
    }
})();
