(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .controller('EditSurveyQuestionGroup', editSurveyQuestionGroup);

    editSurveyQuestionGroup.$inject = ['logger', 'surveyQuestionGroup', '$scope', '$state', 'surveysDataService', 'exceptionHelper', '$stateParams', 'i18nFilter'];

    function editSurveyQuestionGroup(logger, surveyQuestionGroup, $scope, $state, surveysDataService, exceptionHelper, $stateParams, i18nFilter) {
        var vm = this;
        vm.item = surveyQuestionGroup;
        vm.isEdit = $state.current.data.isEdit;

        if (!vm.isEdit) {
            vm.item.surveyId = $stateParams.surveyId;
        }

        vm.formData = {            
            tinymceOptions: {
                toolbar1: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | forecolor backcolor'
            }
        };

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        }

        function ok() {
            if (vm.isEdit) {
                surveysDataService.editSurveyGroup(vm.item).then(function (result) {
                    logger.success(i18nFilter('translations.survey_group_queue_edit'));
                    $scope.$close(true);
                }, function (error) {
                    exceptionHelper.handleException(error);
                    $scope.$dismiss();
                });
            } else {
                surveysDataService.addSurveyGroup(vm.item).then(function (result) {
                    logger.success(i18nFilter('translations.survey_group_queue_add'));
                    $scope.$close(true);
                }, function (error) {
                    exceptionHelper.handleException(error);
                    $scope.$dismiss();
                });
            }
        }
    }
})();
