(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('tileSlider', tileSlider);

    tileSlider.$inject = ['logger', 'activityTypes', '$state'];
    
    function tileSlider(logger, activityTypes, $state) {
        // Usage:
        //     <tile-slider size="large|small"></tile-slider>
        // Creates:
        // 
        var directive = {
            link: link,
            templateUrl: 'app/blocks/directives/tileSlider/tileSlider.html',
            scope: {
                data: '=',
                size: '@size',
                mark: '@mark'
            },
            restrict: 'E'
        };
        return directive;

        function link(scope, element, attrs) {
            var $slyFrame = $(element).find('.sly-frame');
            var $tiles = $slyFrame.find('.tiles');
            var $navPrev = $slyFrame.siblings('.sly-nav-prev');
            var $navNext = $slyFrame.siblings('.sly-nav-next');

            scope.activityTypes = activityTypes;

            $slyFrame.sly({
                slidee: $tiles,
                scrollSource: $tiles,
                dragSource: $tiles,
                mouseDragging: true,
                touchDragging: true,
                releaseSwing: true,
                swingSpeed: 0.2,
                horizontal: true,
                scrollBy: 320,
                prevPage: $navPrev,
                nextPage: $navNext,
                speed: 300
            });

            scope.navigate = function (id) {
                //ui-sref="educations.home({id: item.id})"
                if (scope.size && scope.size == 'small') {
                    var educationId = undefined;
                    angular.forEach(scope.data, function (element, index) {
                        if (element.id == id) {
                            educationId = element.educationId;
                        }
                    });
                    if (educationId) {
                        $state.go("educations.activities", { id: educationId, learningActivityId: id });
                    }
                }
                else {
                    $state.go("educations.home", { id: id });
                }
            }

            // Ako nema ovoga onda se pojavi bug - ne žele se prikazati strelice na slideru
            setTimeout(function () {
                $(window).resize();
            }, 100);
        }
    }

})();