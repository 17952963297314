(function () {
    'use strict';

    angular
        .module('app')
        .factory('subAreasDataService', subAreas);

    subAreas.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function subAreas($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getSubAreas: getSubAreas
        };

        return service;

        function getSubAreas() {
            return $http.get('api/subAreas').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
