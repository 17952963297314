(function () {
    'use strict';

    angular
        .module('app.profile')
        .controller('EditUserController', editUserController);

    editUserController.$inject = ['logger', 'user', '$uibModal', 'gender', 'citiesDataService', 'organizationsDataService', 'employmentStatus', '$scope', 'usersDataService', 'exceptionHelper', '$state', '$sessionStorage', 'i18nFilter', 'termsOfUse', '$sanitize'];

    function editUserController(logger, user, $uibModal, gender, citiesDataService, organizationsDataService, employmentStatus, $scope, usersDataService, exceptionHelper, $state, $sessionStorage, i18nFilter, termsOfUse, $sanitize) {
        var vm = this;

        var tenantId = $sessionStorage.userInfo.tenantId;
        vm.termsUrl = termsOfUse[tenantId];

        vm.item = user;
        vm.localeMessages = {
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
            asyncSelectPlaceholder: i18nFilter('translations.general_gui_async_ddl_placeholder_select')
        };
        vm.formData = {
            typeOfAction: $state.current.data.type,
            gender: gender,
            organizations: [],
            cities: [],
            citiesIsLoading: false,
            organizationsIsLoading: false,
            employmentStatus: employmentStatus
        };

        vm.searchCities = searchCities;
        vm.searchOrganizations = searchOrganizations;
        vm.clearCitySelection = clearCitySelection;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.save = save;
        vm.displayName = displayName;
        vm.openTermsOfUse = openTermsOfUse;
        vm.dateTime = new Date().getTime();
        vm.capitalizeFirstLetter = capitalizeFirstLetter;

        function save() {
            vm.item.description = DOMPurify.sanitize(vm.item.description);
            usersDataService.editUserProfile(angular.copy(vm.item)).then(function (data) {
                usersDataService.getUserInfo().then(function (data) {
                    logger.success(i18nFilter('translations.user_profile_succesfully_saved'));
                    $sessionStorage.userInfo = data;
                    $state.go("classicCatalog");
                }, function (error) {
                    exceptionHelper.handleException(error, true);
                });
            }, function (error) {
                exceptionHelper.handleException(error, true);
            });
        }

        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////
        //#endregion

        //#region Cities
        function searchCities(filter) {
            if (!filter || filter === "") {
                vm.formData.cities = [];
                return;
            }
            vm.formData.citiesIsLoading = true;
            citiesDataService.getAllFilteredActiveCities(filter).then(function (data) {
                vm.formData.cities = data;
                vm.formData.citiesIsLoading = false;
            }, function (error) {
                vm.formData.citiesIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearCitySelection() {
            vm.item.city = undefined;
        }
        //#endregion

        //#region Organizations
        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
        }
        //#endregion

        function displayName() {
            var firstName = vm.item.firstName ? vm.item.firstName : '';
            var lastName = vm.item.lastName ? vm.item.lastName : '';
            vm.item.displayName = (firstName + ' ' + lastName).trim();

        }

        function capitalizeFirstLetter(fieldName) {
            if (!fieldName) 
                return;
            
            if (vm.item[fieldName] && vm.item[fieldName] && vm.item[fieldName].length) {
                var displayNameWords = vm.item[fieldName].split(" ");
                var capitalizedString = "";
                for (var i = 0; i < displayNameWords.length; i++) {
                    let word = displayNameWords[i].charAt(0).toUpperCase() + displayNameWords[i].slice(1).toLowerCase();

                    //Regex match "-" and any character that isn't whitespace or digit etc.
                    word = word.replace(/[\-–—]+[^\s\d!"#$%&/()=?*+|\\,;.:_]/ug, subStr => subStr.toUpperCase());

                    capitalizedString += word + " ";
                }
                vm.item[fieldName] = capitalizedString.trim();
            }

            if (fieldName != "displayName") {
                vm.displayName();
            }
        }

        function openTermsOfUse() {
            $uibModal.open({
                templateUrl: 'app/profile/edit/termsOfUse/termsOfUse.html',
                controller: 'termsOfUse',
                controllerAs: 'vm'               
            });
        }
    }
})();
