(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('notification', notification);

    notification.$inject = ['$scope', '$stateParams', 'i18nFilter'];

    function notification($scope, $stateParams, i18nFilter) {
        var vm = this;
        
        vm.ok = ok;
        vm.no = no;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(true);
        };

        function no() {
            $scope.$close(false);
        };

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
