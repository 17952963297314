(function () {
    'use strict';

    angular
        .module('app.budgets')
        .controller('EditBudget', editBudget);

    editBudget.$inject = ['decimalHelper', 'dateHelper', '$state', 'priceCategories', 'currencies', 'i18nFilter', 'budget', 'budgetsDataService', 'exceptionHelper', 'budgetEntities', 'budgetExamEntities', 'logger', 'currencyCategory', 'pricesCategory'];

    function editBudget(decimalHelper, dateHelper, $state, priceCategories, currencies, i18nFilter, budget, budgetsDataService, exceptionHelper, budgetEntities, budgetExamEntities, logger, currencyCategory, pricesCategory) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = budget;
        vm.isEdit = $state.current.data.isEdit;
        vm.startDateValidate = startDateValidate;
        vm.endDateValidate = endDateValidate;
        vm.localeMessages = {
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select')
        } 
        
        vm.formData = {
            minusAmount: undefined,
            categories: priceCategories,
            currencies: currencies,
            startTimeConfig: {
                type: 'date',
                width: '100%',
                onValueChanged: function (e) {
                    vm.endDatevalidator.validate();
                },
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.start'
                }
            },
            startDateValidationOps: {
                validationRules: [
                    { type: 'required', message: i18nFilter('translations.general_validation_message_start_date_is_either_blank_or_invalid') },
                    { type: 'custom', validationCallback: vm.startDateValidate, reevaluate: true }
                ],
                onInitialized: function (e) {
                    vm.startDatevalidator = e.component;
                }
            },
            endTimeConfig: {
                type: 'date',
                width: '100%',
                onValueChanged: function (e) {
                    vm.startDatevalidator.validate();
                },
                inputAttr: {
                    'name': 'endDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.end'
                }
            },
            endDateValidationOps : {
                validationRules: [
                    { type: 'required', message: i18nFilter('translations.general_validation_message_end_date_is_either_blank_or_invalid') },
                    { type: 'custom', validationCallback: vm.endDateValidate, reevaluate: true }
                ],
                onInitialized: function (e) {
                    vm.endDatevalidator = e.component;
                }
            },
            chartLegendTotal: i18nFilter('translations.schedule_budget_gui_chart_total'),
            chartData: [0, 0, 0],
            chartLabels: [
                i18nFilter('translations.schedule_budget_gui_chart_total_remaining'),
                i18nFilter('translations.schedule_budget_gui_chart_total_reserved'),
                i18nFilter('translations.schedule_budget_gui_chart_total_spent')
            ],
            entities: budgetEntities,
            examEntities: budgetExamEntities,
            total: undefined,
            totalReserved: undefined,
            totalSpent: undefined,
            remainingAmount: undefined,
            publicOrderDate: {
                type: 'date',
                width: '100%',
                inputAttr: {
                    'name': 'orderDate'
                },
                bindingOptions: {
                    value: 'vm.item.publicOrder.orderDate'
                }
            },
        }

        vm.add = add;
        vm.save = save;
        vm.areDatesValid = areDatesValid;
        vm.setBudgetData = setBudgetData;
        
        if (!vm.isEdit) {
            // When adding a budget
            vm.item.price = {
                amount: undefined,
                amountInWords: undefined,
                category: undefined,
                currency: undefined
            }
            vm.item.amount = 0;
            if (vm.formData.categories) {
                angular.forEach(vm.formData.categories, function (category, Index) {                   
                    if (category.id === pricesCategory.budgetPrice) {
                        vm.item.price.category = category;
                    }
                });
            }

            if (vm.formData.currencies) {
                angular.forEach(vm.formData.currencies, function (currency, Index) {
                    if (currency.id === currencyCategory.eur) {
                        vm.item.price.currency = currency;
                    }
                });
            }

            if (!vm.item.start) {
                vm.item.start = new Date();
                vm.item.start.setHours(0, 0, 0, 0);
            }

            if (!vm.item.end) {
                vm.item.end = new Date();
                vm.item.end.setHours(0, 0, 0, 0);
            }

            vm.item.publicOrder = {
                orderNumber: undefined,
                orderDate: undefined
            }

        } else {
            // When editing a budget
            getBudget();
        }

        function startDateValidate(options) {
            if ((vm.item.end != "" && vm.item.end != null) && dateHelper.createNewDateTimeWithoutTime(options.value) >= dateHelper.createNewDateTimeWithoutTime(vm.item.end)) {
                options.rule.message = i18nFilter('translations.general_validation_message_start_date_should_be_less_than_end_date');
                return false;
            }

            return true;
        };

        function endDateValidate(options) {
            if ((vm.item.start != "" && vm.item.start != null) && dateHelper.createNewDateTimeWithoutTime(options.value) <= dateHelper.createNewDateTimeWithoutTime(vm.item.start)) {
                options.rule.message = i18nFilter('translations.general_validation_message_end_date_should_be_greater_than_start_date');
                return false;
            }

            return true;
        };
        
        function areDatesValid() {
            if (!vm.item.start || !vm.item.end) {
                return false;
            }
            var start = vm.item.start.getTime();
            var end = vm.item.end.getTime();

            return start < end;
        }

        function add() {
            budgetsDataService.addBudget(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.budget_queue_add'));
                $state.go("^");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            budgetsDataService.editBudget(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.budget_queue_edit'));
                $state.go("^");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function getBudget() {
            budgetsDataService.getBudgetByEducationIdAndStartDate(vm.item.entities[0].id, vm.item.start).then(function (data) {
                vm.budgetData = data;
                if (data.budget !== undefined) {                    
                    vm.setBudgetData();
                }
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function setBudgetData() {
            vm.formData.total = parseFloat(vm.budgetData.budget.price.amount.replace(',', '.'));
            vm.formData.totalReserved = decimalHelper.round(vm.budgetData.reserved, 2);
            vm.formData.totalSpent = decimalHelper.round(vm.budgetData.spent, 2);
            vm.formData.remainingAmount = decimalHelper.round(vm.formData.total - vm.budgetData.reserved - vm.budgetData.spent, 2);

            if (vm.formData.remainingAmount < 0) {
                vm.formData.minusAmount = vm.formData.remainingAmount;
                vm.formData.remainingAmount = 0;
            }

            vm.formData.chartData = [vm.formData.remainingAmount, vm.formData.totalReserved, vm.formData.totalSpent];

        }

        vm.educationsFilter = function (entity) {
            return entity.type == 0;
        }
    }
})();
