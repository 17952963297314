(function () {
    'use strict';

    angular
        .module('app')
        .controller('deleteDigitalSignature', deleteDigitalSignature);

    deleteDigitalSignature.$inject = ['logger', '$scope', '$stateParams', 'exceptionHelper', 'i18nFilter', 'fileName'];

    function deleteDigitalSignature(logger, $scope, $stateParams, exceptionHelper, i18nFilter, fileName) {
        var vm = this;

        vm.fileName = fileName;
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {            
             $scope.$close(true);            
        };
    }
})();
