(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('scheduleLocked', scheduleLocked);
    
    scheduleLocked.$inject = ['logger','i18nFilter'];

    function scheduleLocked(logger, i18nFilter) {
            return function (input) {                
                var value = Number(input)
                if (value == 1) {
                    return i18nFilter('translations.general_gui_yes');
                } else {
                    return i18nFilter('translations.general_gui_no');
                }
            };
        }

})();