(function () {
    'use strict';

    angular
        .module('app.surveys')
        .controller('Surveys', surveys);

    surveys.$inject = ['logger', 'survey', '$rootScope', '$location', 'surveysDataService', 'i18nFilter', '$sessionStorage', '$state', 'rolesDataService'];

    function surveys(logger, survey, $rootScope, $location, surveysDataService, i18nFilter, $sessionStorage, $state, rolesDataService) {
        var vm = this;

        vm.survey = survey;
        vm.status = status;

        vm.localeMessages = {
        };

        vm.formData = {
            isSmallCover: true,
            navAuth: {
                manage: rolesDataService.isUserInRoles(['SurveyAdmin', 'SurveyOwner']),
                manageEdit: rolesDataService.isUserInRoles(['SurveyAdmin', 'SurveyOwner']),
                manageDelete: rolesDataService.isUserInRoles(['SurveyAdmin', 'SurveyOwner'])
            }
        };

        vm.imageStyle = {
            "background-color": "#78C7C9",
            "min-height": "400px"
        };
        
        //Configuration for marking top level navigation properties if we are on sub level
        vm.isActive = function (view) {
            var path = $location.path();

            switch (view) {
                case '/home':
                    if (path.endsWith('/home')) {
                        return true;
                    }
                    break;                             
                case '/questionGroups':
                    if (path.endsWith('/questionGroups')) {
                        return true;
                    }
                    break;
                case '/administration':
                    if (
                        path.endsWith('/administration/edit') ||
                        path.endsWith('/administration/delete') 

                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
    }
})();
