(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('editConfiguration', editConfiguration);

    editConfiguration.$inject = ['notifications', 'notificationsDataService', 'logger', 'exceptionHelper', '$state', 'emailProviders', 'i18nFilter'];

    function editConfiguration(notifications, notificationsDataService, logger, exceptionHelper, $state, emailProviders, i18nFilter) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'editConfiguration';
        vm.item = notifications;
        vm.done = done;
        vm.sendMail = sendMail;
        vm.formData = {
            providers: [
                emailProviders.smtp
            ]
        };

        if (vm.item.notificationServiceConfig.eMailService && vm.item.notificationServiceConfig.eMailService.smtpConfig && vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials !== undefined && vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials !== null) {
            vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials = !vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials;
        }


        function done() {
            if (!vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials && vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials) {
                vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials.username = undefined;
                vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials.password = undefined;
            }

            var itemCopy = angular.copy(vm.item);
            itemCopy.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials = !itemCopy.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials;

            notificationsDataService.editNotificationsSettings(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.email_gui_logger_configuration_in_queue'), data);
                $state.go("notifications.notificationConfiguration");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function sendMail() {
            if (!vm.item.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials && vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials) {
                vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials.username = undefined;
                vm.item.notificationServiceConfig.eMailService.smtpConfig.credentials.password = undefined;
            }

            var itemCopy = angular.copy(vm.item);
            itemCopy.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials = !itemCopy.notificationServiceConfig.eMailService.smtpConfig.useDefaultCredentials;

            notificationsDataService.sendTestMail(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.email_gui_logger_configuration_in_queue'), data);
                $state.go("notifications.notificationConfiguration");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
