(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('notificationHistoryDetails', notificationHistoryDetails);

    notificationHistoryDetails.$inject = ['historyDetails'];

    function notificationHistoryDetails(historyDetails) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = historyDetails;

        vm.formData = {
            tinymceOptions: {
                contenteditable: false,
                readonly: true
            }
        };
    }
})();
