(function () {
    'use strict';

    angular
        .module('app.educations.activities')
        .component('universalActivity', {
            templateUrl: 'app/educations/activities/components/universal/universalActivity.html',
            controller: universalActivity,
            controllerAs: 'vm',
            bindings: {
                activity: '=', //access via vm.acitivty
            }
        });

    universalActivity.$inject = ['logger', 'learningActivitySuccess', 'learningActivityState', 'activitiesDataService', 'exceptionHelper', 'activityTypes', 'i18nFilter'];

    function universalActivity(logger, learningActivitySuccess, learningActivityState, activitiesDataService, exceptionHelper, activityTypes, i18nFilter) {
        var vm = this;
        var ignoreNextToggle = false;
        vm.formData = {
            activityTypes: activityTypes,
            estimatedDuration: i18nFilter("translations.activity_gui_tooltip_activity_estimated_duration"),
            iconClass: []
        };



        switch (vm.activity.educationActivityType.id) {
            case activityTypes.genericAcitvityType:
                vm.formData.iconClass = ['fa', 'fa-sticky-note', 'fa-4x'];
                break;
            case activityTypes.virtualClassroomAcitvityType:
                vm.formData.iconClass = ['fa', 'fa-users', 'fa-4x'];
                break;
            case activityTypes.surveyAcitvityType:
                vm.formData.iconClass = ['fa', 'fa-check-square', 'fa-4x'];
                break;
            case activityTypes.examAcitvityType:
                vm.formData.iconClass = ['fa', 'fa-edit', 'fa-4x'];
                break;
            case activityTypes.videoActivityType:
                vm.formData.iconClass = ['fa', 'fa-video-camera', 'fa-4x'];
                break;
            case activityTypes.classroomActivityType:
                vm.formData.iconClass = ['fa', 'fa-users', 'fa-4x'];
                break;
            case activityTypes.html5ActivityType:
                vm.formData.iconClass = ['fa', 'fa-html5', 'fa-4x'];
                break;
            case activityTypes.eExamActivityType:
                vm.formData.iconClass = ['fas', 'fa-file-alt"', 'fa-4x'];
                break;
        }
        
    }
})();
