Dropzone.autoDiscover = false;

(function () {
    'use strict';
    var app = angular.module('app', [
        'app.core',
        'app.dataservice',
        'app.tenants',
        'app.filters', 
        'app.manage',
        'app.queue',
        'app.certificateSigner',
        'app.accessories',
        'app.organizations', 
        'app.users',
        'app.learningPeriod',
        'app.catalogs',  
        'app.welcome', 
        'app.notifications',
        'app.contracts',
        'app.educations', 
        'app.educations.administration', 
        'app.educations.administration.edit',
        'app.educations.administration.activities',
        'app.educations.administration.learnerPoints',
        'app.educations.administration.activities.edit.firstStep',
        'app.educations.administration.activities.edit.genericActivity',
        'app.educations.administration.activities.edit.officeMix',
        'app.educations.administration.activities.edit.classroomEducation',
        'app.educations.administration.activities.edit.virtualClassroom',        
        'app.educations.administration.activities.edit.exam',
        'app.educations.administration.activities.edit.video',
        'app.educations.administration.activities.edit.scorm',
        'app.educations.administration.activities.edit.survey',
        'app.educations.administration.activities.edit.eExam',
        'app.educations.administration.activities.edit.eExamForEducation',
        'app.educations.home',
        'app.educations.sidebar.categories',
        'app.educations.sidebar.info',
        'app.educations.sidebar.learners',
        'app.educations.sidebar.owners',
        'app.educations.sidebar.contact',
        'app.educations.sidebar.lecturers',
        'app.educations.sidebar.help',
        'app.educations.activities',
        'app.educations.documents',
        'app.educations.administration.delete',
        'app.educations.administration.activities.edit.lastStep',
        'app.educations.administration.activities.edit.html5Activity', 
        
        'app.schoolings',
        'app.schoolings.administration',
        'app.schoolings.administration.edit',
        'app.schoolings.administration.learnerPoints',
        'app.schoolings.home',
        'app.schoolings.sidebar.categories',
        'app.schoolings.sidebar.info',
        'app.schoolings.sidebar.owners',
        'app.schoolings.sidebar.contact',
        'app.schoolings.sidebar.help',
        'app.schoolings.documents',
        'app.schoolings.administration.delete',
        'app.categories',     
        'app.unauthorized',
        'app.reports',        
        'app.helper',
        'app.logs',
        'app.locations',        
        'app.profile',
        'app.base',
        'app.certificates',  
        'app.unsupportedBrowser',
        'app.programs',
        'app.programs.home',
        'app.programs.administration',
        'app.programs.administration.edit',
        'app.countries',
        'app.cities',
        'app.programs.administration.delete',
        'app.programs.educations',
        'app.programs.documents',

        'app.promotedEntities',
        'app.registration',
        'app.releaseHistory',
        'app.deactevatedUser',
        'app.budgets',       
        'app.competences',
        'app.schedulePacks',
        'app.schoolingSchedulePacks',
        'app.proficiencyExamSchedules',
        'app.programs.administration.learners',
        'app.surveys',
        'app.surveys.administration',
        'app.surveys.questionGroups',
        'app.surveys.sidebar.surveyOwners',
        'app.surveys.sidebar.surveyUsed',
        'app.tilesConfiguration',
        'app.urgentMessagesConfiguration',
        'app.eLearningEducationEnrollments',
        'app.exams',
        'app.exams.administration',
        'app.exams.administration.edit',
        'app.exams.home',
        'app.exams.sidebar.info',
        'app.exams.sidebar.contact',
        'app.exams.administration.delete',
        'app.schoolings.administration.delete',
        'app.examSchedulePacks',
        'app.exams.catalogues',
        'app.exams.scholarships',
        'app.exams.bulletin',
        'app.exams.bulletinSubscriptions',
        'app.exams.themes',
        'app.exams.workplace',
        'app.targetGroups',
        'app.surveys.sidebar.surveyUsed',
        'app.personRecords',
        'app.logout',
        'app.renewSession',
        'app.analytics',

        'app.proficiencyExamBudgets',
        'app.proficiencyExamLevels'
    ]);
})();
