(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('programDocuments', programDocuments);

    programDocuments.$inject = ['$state', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', '$stateParams', 'documentsDataService', 'templates'];

    function programDocuments($state, $scope, i18nFilter, exceptionHelper, logger, $stateParams, documentsDataService,templates) {
        var vm = this;

        vm.templates = templates;

        vm.generateProgramDocument = generateProgramDocument;
        vm.generateInvitationDocument = generateInvitationDocument;
        
        function generateProgramDocument() {
            documentsDataService.downloadDocument('/api/schoolingSchedulePacks/' + $stateParams.schoolingScheduleId + '/programDocument', i18nFilter('translations.schoolingSchedule_gui_program_programDocument') + '.docx').then(function (data) {
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }

        function generateInvitationDocument() {
            documentsDataService.downloadDocument('/api/schoolingSchedulePacks/' + $stateParams.schoolingScheduleId + '/invitationDocument', i18nFilter('translations.schoolingSchedule_gui_program_invitationDocument') + '.docx').then(function (data) {
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
    }
})();
