(function () {
    'use strict';

    angular
        .module('app.programs.educations')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        /// <summary>
        /// Routes the configuration.
        /// </summary>
        /// <param name="$stateProvider">The $state provider.</param>
        /// <returns></returns>
        $stateProvider
            .state('programs.educations', {
                url: '/educations',
                templateUrl: 'app/programs/educations/educations.html',
                controller: 'ProgramEducations',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    educations: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getProgramEducations($stateParams.programId);
                    }]
                },
                data: {
                    roles: ['Learner', 'CatalogAdmin', 'EducationOwner', 'Instructor', 'RealProgramOwner']
                }
            })
            ;
    }
})();
