(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('FirstStepUser', firstStepUser);

    firstStepUser.$inject = ['logger', 'user', 'gender', 'employmentStatus', 'organizationsDataService', 'rolesDataService', 'editUserHelper', 'i18nFilter', '$scope', 'usersDataService', 'exceptionHelper', 'citiesDataService', '$state', '$stateParams', 'applicationRoles', '$sessionStorage', 'authProviders', '$uibModal'];

    function firstStepUser(logger, user, gender, employmentStatus, organizationsDataService, rolesDataService, editUserHelper, i18nFilter, $scope, usersDataService, exceptionHelper, citiesDataService, $state, $stateParams, applicationRoles, $sessionStorage, authProviders, $uibModal) {
        var vm = this;

        vm.isEdit = $state.current.data.isEdit;
        vm.item = user;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
            asyncSelectPlaceholder: i18nFilter('translations.general_gui_async_ddl_placeholder_select')
        };

        vm.formData = {
            gender: gender,
            organizations: [],
            tenantIdentityProviderIsADFS: $sessionStorage.userInfo.tenantConfig.authenticationAndAuthorization.identityProvider === authProviders.activeDirectoryFS,
            userIsGlobalAdmin: rolesDataService.isUserInRoles(['GlobalAdmin']),
            employmentStatus: employmentStatus,
            dateToPreventCache: new Date().getTime(),
            potentialUserIsLoading: false,
            potentialTransferUsers: undefined,
            clearTransferUserSelection: undefined,
            roles: []
        };

        vm.formData.roles = rolesDataService.getPotentialUserRoles(true, !vm.formData.userIsGlobalAdmin);


        vm.add = add;
        vm.save = save;
        vm.next = next;
        vm.cancel = cancel;
        vm.transferUserdata = transferUserdata;
        vm.refreshCities = refreshCities;
        vm.clearCitySelection = clearCitySelection;
        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.displayName = displayName;
        vm.capitalizeFirstLetter = capitalizeFirstLetter;
        vm.getUserForTransferAppData = getUserForTransferAppData;
        vm.clearTransferUserSelection = clearTransferUserSelection;

        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////
        //#endregion

        if (!vm.isEdit) {
            //ADD
            vm.item.active = true;
        } else {
            //EDIT
            vm.navigation = editUserHelper.getWizardSteps($stateParams.id, vm.isEdit);

            vm.item.roles = _.filter(vm.item.roles, function (role) {
                return role.id !== applicationRoles.kbAdmin && role.id !== applicationRoles.learner && role.id !== applicationRoles.educationOwner && role.id !== applicationRoles.programOwner && role.id !== applicationRoles.instructor && role.id !== applicationRoles.manager && role.id !== applicationRoles.surveyOwner && role.id !== applicationRoles.examOwner;
            });
        }

        function add() {
            usersDataService.addUser(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.user_queue_add'));
                $state.go('users');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            var potentialTransferUsers = editUserHelper.getRemoteUser();
            var selectedEventTypeRoles = editUserHelper.getEventTypeRoles();

            if (potentialTransferUsers && selectedEventTypeRoles.length > 0) {
                var idPair = [user.id, potentialTransferUsers.id];

                usersDataService.transferUserEntityRoles(idPair, selectedEventTypeRoles).then(function (data) {
                    logger.success(i18nFilter('translations.user_operation_edit_transfer_loading'));
                    editUserHelper.clearRemoteUser();
                    editUserHelper.clearEventTypeRoles();
                }, function (error) {
                    exceptionHelper.handleException(error, true);
                });
            }

            usersDataService.editUser(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.user_queue_edit'));
                $state.go('users');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function next() {
            $state.go($scope.navigation[1].state);
        }

        function cancel() {
            editUserHelper.clearRemoteUser();
        }

        function transferUserdata() {

            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: 'app/manage/users/edit/editUserTransferModal/editUserTransferModal.html',
                controller: 'editUserTransferModal',
                controllerAs: 'modalvm',
                resolve: {
                    currentUser: user,
                    remoteUser: vm.formData.potentialTransferUsers
                },
                windowClass: 'editUserTransferModal',
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });

            modalInstance.result.then(function (result) {
                var idPair = [user.id, vm.formData.potentialTransferUsers.id];

                usersDataService.joinUserProfiles(idPair).then(function (data) {
                    logger.success(i18nFilter('translations.user_operation_edit_transfer_loading'));
                }, function (error) {
                    exceptionHelper.handleException(error, true);
                });
            }, function () {
                // when users click cancel button
            })
        }

        function refreshCities(filter) {
            if (!filter || filter === "") {
                vm.formData.cities = [];
                return;
            }
            vm.formData.citiesIsLoading = true;
            citiesDataService.getAllFilteredActiveCities(filter).then(function (data) {
                vm.formData.cities = data;
                vm.formData.citiesIsLoading = false;
            }, function (error) {
                vm.formData.citiesIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
        }

        function clearTransferUserSelection() {
            vm.formData.potentialTransferUsers = undefined;
        }

        function clearCitySelection() {
            vm.item.city = undefined;
        }

        function capitalizeFirstLetter(fieldName) {
            if (!fieldName)
                return;

            if (vm.item[fieldName] && vm.item[fieldName].length) {
                var displayNameWords = vm.item[fieldName].split(" ");
                var capitalizedString = "";
                for (var i = 0; i < displayNameWords.length; i++) {
                    let word = displayNameWords[i].charAt(0).toUpperCase() + displayNameWords[i].slice(1).toLowerCase();

                    //Regex match "-" and any character that isn't whitespace or digit etc.
                    word = word.replace(/[\-–—]+[^\s\d!"#$%&/()=?*+|\\,;.:_]/ug, subStr => subStr.toUpperCase());

                    capitalizedString += word + " ";
                }
                vm.item[fieldName] = capitalizedString.trim();
            }

            if (fieldName != "displayName") {
                vm.displayName();
            }
        }

        function displayName() {
            var firstName = vm.item.firstName ? vm.item.firstName : '';
            var lastName = vm.item.lastName ? vm.item.lastName : '';
            vm.item.displayName = (firstName + ' ' + lastName).trim();

        }

        //#region potential active users search
        function getUserForTransferAppData(query) {
            if (query === undefined || query === "" || query.length < 3) {
                return;
            }

            //Pass on user id with table, otherwise empty array
            const userIds = user.id ? [user.id] : [];
            vm.formData.potentialUserIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.potentialUserIsLoading = false;
            }, function (error) {
                vm.formData.potentialUserIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }
    }
})();
