(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('visibilityType', visibilityType);

    visibilityType.$inject = ['i18nFilter'];

    function visibilityType(i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value === 1 || input === 'Public') {
                return i18nFilter('translations.schedule_gui_label_visibility_public');
            }
            return i18nFilter('translations.schedule_gui_label_visibility_private');
        };
    }

})();
