(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('editTemplatesConfiguration', editTemplatesConfiguration);

    editTemplatesConfiguration.$inject = ['notificationTemplate', 'notificationsDataService', 'exceptionHelper', 'logger', '$state', 'i18nFilter', 'notificationTypesClassification', 'notificationSendingType', '$uibModal', 'priority', 'delivery', '$http', 'notificationTemplatesTypes', 'dateHelper'];

    function editTemplatesConfiguration(notificationTemplate, notificationsDataService, exceptionHelper, logger, $state, i18nFilter, notificationTypesClassification, notificationSendingType, $uibModal, priority, delivery, $http, notificationTemplatesTypes, dateHelper) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = notificationTemplate;  
        vm.finish = finish;
        vm.openVariablesDialog = openVariablesDialog;
        vm.openUploadAttachmentDialog = openUploadAttachmentDialog;
        vm.openHistoryDialog = openHistoryDialog;
        vm.removeAttachment = removeAttachment;
        vm.areDatesValid = areDatesValid;
        vm.listArrayString = listArrayString;
        vm.isEmailWithMoreChoices = isEmailWithMoreChoices;
        vm.isEmailRolesCCEmpty = isEmailRolesCCEmpty;
        vm.isEmailRolesBCCEmpty = isEmailRolesBCCEmpty;
        vm.userStatusChoiceChange = userStatusChoiceChange;

        vm.userStatusChoice = {
            id: 0,
            choice: vm.item.sentNotificationUserStatusChoice[0],
            emailRoles: vm.item.sentNotificationUserStatusRoles[0],
            emailRolesCC: vm.item.sentNotificationUserStatusRolesCC[0],
            emailRolesBCC: vm.item.sentNotificationUserStatusRolesBCC[0]
        }

        vm.localeMessages = {
            add_mail_attachment: i18nFilter('translations.email_gui_add_mail_attachment_tooltip'),
            emailNotificationStatusInfo: i18nFilter('translations.email_gui_edit_tooltip_status_info')
        };
        
        if (vm.item.notificationTemplateConfiguration === null) {
            vm.item.notificationTemplateConfiguration = {
                recurringTemplateConfig:{
                    typeOfSending: notificationSendingType.everyDay
                }
            }            
        }
        
        if (vm.item.notificationType === 10008 && vm.item.notificationTemplateConfiguration.recurringTemplateConfig.numberOfDaysBeforeSchedulePackStartDate == null)
            vm.item.notificationTemplateConfiguration.recurringTemplateConfig.numberOfDaysBeforeSchedulePackStartDate = 3;

               
        vm.formData = {
            typeOfSending: [notificationSendingType.everyDay, notificationSendingType.dayOfWeek, notificationSendingType.dayOfMonth],
            daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
            daysOfTheMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
            hoursOfDay: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            minOfHour: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor',
                file_picker_types: 'image',
                convert_urls: false,
                file_picker_callback: filePickerCallback
            },
            classification: [notificationTypesClassification.event, notificationTypesClassification.determinedByTime],
            priorityStatus: {
                normal: priority.normal,
                low: priority.low,
                high: priority.high
            },
            deliveryStatus: {
                none: delivery.none,
                success: delivery.success,
                fail: delivery.fail
            },
            notificationTemplatesTypes: notificationTemplatesTypes,
            sendDateConfig: {
                type: 'date',
                name: 'sendDate',
                value: vm.item.notificationTemplateConfiguration.recurringTemplateConfig.sendDate,
                bindingOptions: {
                    value: 'vm.item.notificationTemplateConfiguration.recurringTemplateConfig.sendDate'
                },
                inputAttr: {
                    'id': 'sendDate',
                    'ng-required': true
                },
                onValueChanged: function (e) {
                    vm.startDatevalidator.validate();
                },
                onFocusOut: function (e) {
                    vm.startDatevalidator.validate();
                },
                disabledDates: function (args) {
                    var dayOfWeek = args.date.getDay(),
                        isFutureDate = args.view === "month" && args.date > new Date(),
                        isWeekend = args.view === "month" && (dayOfWeek === 0 || dayOfWeek === 6);

                    if (args.view === "month" && (!isWeekend || !isFutureDate)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            startDateValidationOps: {
                validationRules: [
                    { type: 'required', message: i18nFilter('translations.general_validation_message_enter_required_field') }
                ],
                onInitialized: function (e) {
                    vm.startDatevalidator = e.component;
                }
            },
        };

        function removeAttachment(index) {
            vm.item.attachments.splice(index, 1);
        }

        function openUploadAttachmentDialog() {

            var modalInstance = $uibModal.open({
                templateUrl: 'app/manage/notifications/templatesConfiguration/editTemplatesConfiguration/uploadDialog/uploadAttachment.html',
                controller: 'uploadAttachment',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false
            });

            modalInstance.result.then(function (document) {
                if (document) {
                    vm.item.attachments.push({
                        documentId: document.id,
                        notificationTemplateId: vm.item.id,
                        document: {
                            id: document.id,
                            name: document.name
                        }
                    })
                }
            }, function () {
                //close callback
                console.info('upload attachment modal closed');
            });

        };

        function openVariablesDialog(notificationIdentifier) {
            $uibModal.open({
                url: '/:notificationIdentifier',
                templateUrl: 'app/manage/notifications/templatesConfiguration/editTemplatesConfiguration/variablesDialog/variablesDialog.html',
                controller: 'variablesDialog',
                controllerAs: 'vm',
                resolve: {
                    notificationVariables: ['notificationsDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getNotificationVariables(notificationIdentifier);
                    }]
                }
            });
        }

        function openHistoryDialog() {
            $uibModal.open({
                templateUrl: 'app/manage/notifications/templatesConfiguration/editTemplatesConfiguration/historyDialog/historyDialog.html',
                controller: 'sendHistory',
                controllerAs: 'vm',
                resolve: {
                    sendHistory: ['notificationsDataService', '$stateParams', function (notificationsDataService, $stateParams) {
                        return notificationsDataService.getNewsletterHistory();
                    }]
                }
            });
        }

        function areDatesValid(options) {
            if (vm.item.notificationType == vm.formData.notificationTemplatesTypes.MN_1 && vm.item.isActive && !vm.item.notificationTemplateConfiguration.recurringTemplateConfig.sendDate) {
                return false;
            }

            return true;
        };

        function filePickerCallback(cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();

                reader.onload = function () {
                    var fd = new FormData();
                    fd.append("file", file, file.name);

                    $http.post("api/documents/tinymceupload", fd, {
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined }
                    }).then(function (data) {
                        cb(data.data.link, { title: data.data.name });

                    }, function (data, status, headers, config) {
                        if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                    });
                };
                reader.readAsDataURL(file);
            }
            input.click();
        }

        function finish() {
            notificationsDataService.editNotificationTemplate(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.email_gui_logger_configuration_template_in_queue'), data);
                $state.go("notifications.templatesConfiguration");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function userStatusChoiceChange(index) {
            vm.userStatusChoice.id = index;
            vm.userStatusChoice.emailRoles = vm.item.sentNotificationUserStatusRoles[index];
            vm.userStatusChoice.emailRolesCC = vm.item.sentNotificationUserStatusRolesCC[index];
            vm.userStatusChoice.emailRolesBCC = vm.item.sentNotificationUserStatusRolesBCC[index];
        }

        function isEmailWithMoreChoices() {
            if (vm.item.sentNotificationUserStatusChoice && vm.item.sentNotificationUserStatusChoice.length > 1)
                return true;
            return false;
        }

        function isEmailRolesCCEmpty() {
            if (vm.userStatusChoice.emailRolesCC && vm.userStatusChoice.emailRolesCC.length > 0)
                return false;
            return true;
        }

        function isEmailRolesBCCEmpty() {
            if (vm.userStatusChoice.emailRolesBCC && vm.userStatusChoice.emailRolesBCC.length > 0)
                return false;
            return true;
        }

        function listArrayString(dataList) {
            if (dataList && dataList.length > 0) {
                return dataList.join(', ');
            }
        }
    }
})();
