(function () {
    'use strict';

    angular
        .module('app')
        .factory('authHelper', date);

    date.$inject = ['logger', '$sessionStorage'];

    function date(logger, $sessionStorage) {
        var service = {
            incrementAuthInProgressFlag: incrementAuthInProgressFlag,
            decrementAuthInProgressFlag: decrementAuthInProgressFlag,
            getAuthInProgressFlag: getAuthInProgressFlag
        };

        return service;

        function incrementAuthInProgressFlag() {
            var numberOfAuthInProgress = $sessionStorage.authInProgress;
            if (!numberOfAuthInProgress) {
                numberOfAuthInProgress = 0;
            }
            numberOfAuthInProgress++;
            $sessionStorage.authInProgress = numberOfAuthInProgress;
        }


        function decrementAuthInProgressFlag() {
            var numberOfAuthInProgress = $sessionStorage.authInProgress;

            if (numberOfAuthInProgress) {
                numberOfAuthInProgress--;
            }

            $sessionStorage.authInProgress = numberOfAuthInProgress;
        }

        function getAuthInProgressFlag(){
            return $sessionStorage.authInProgress;
        }
    }
})();