(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('statusCatalogFilter', {
            templateUrl: 'app/catalogs/filters/status/statusCatalogFilter.html',
            controller: statusCatalogFilter,
            controllerAs: 'vm',
            bindings: {                
                onChanged: '=',
                langKey: '='
            }
        });

    statusCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function statusCatalogFilter(logger, i18nFilter) {
        var vm = this;
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 

        vm.isChecked = false;
    }
})();
