(function () {
    'use strict';

    angular
        .module('app.countries')
        .controller('editCity', editCountryCtrl);

    editCountryCtrl.$inject = ['logger', 'city', 'countries', 'citiesDataService', '$state', 'i18nFilter', '$sanitize'];

    function editCountryCtrl(logger, city, countries, citiesDataService, $state, i18nFilter, $sanitize) {

        var vm = this;
        vm.item = city;
        vm.isEdit = $state.current.data.isEdit;
        vm.save = save;
        vm.add = add;

        vm.formData = {
            countries: countries
        };


        function add() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            citiesDataService.addCity(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.city_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            citiesDataService.editCity(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.city_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

    }
})();
