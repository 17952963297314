(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('languagesDataService', languages);

    languages.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper'];

    function languages($http, logger, locale, $q, activityTypes, jsonHelper) {
        var service = {
            getAllLanguages: getAllLanguages
        };

        return service;

        function getAllLanguages() {
            
            return $http.get('api/languages').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }



    }
})();