(function () {
    'use strict';

    angular
        .module('app')
        .controller('cancelSchedulePack', cancelSchedulePack);

    cancelSchedulePack.$inject = ['$scope', 'schedulePack', '$uibModalInstance', 'status'];

    function cancelSchedulePack($scope, schedulePack, $uibModalInstance, status) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.item = { schedulePackId: schedulePack.id, cancelComment: schedulePack.cancelComment };
        vm.alreadyCancelled = schedulePack.status == status.cancelled;

        function ok() {
            $uibModalInstance.close(vm.item);
        };

        function cancel() {
            $scope.$dismiss();
        };
    }
})();
