(function () {
    "use strict";

    angular
        .module("app.schedulePacks")
        .controller("schedulePackDetails", details);

    details.$inject = [
        "logger",
        "i18nFilter",
        "schedulesDataService",
        "$stateParams",
        "exceptionHelper",
        "rolesDataService",
        "$uibModal",
        "learningActivityScheduleStatus",
        "learningPlanItemStatus",
        "learningPlanItemSuccess",
        "learningActivityState",
        "dateFilter",
        "usersDataService",
        "$state",
        "$http",
        "documentsDataService",
        "schedulePack",
        "$sessionStorage",
        "eFormsUrl",
        "eFormTypes",
        "schedulePacksDataService",
        "eFormStatus",
        "eFormPayer",
        "organizationsDataService",
        "citiesDataService",
        "status",
        "examApplicationFieldType",
        "dateHelper",
        "$scope",
        "surveyType",
    ];

    function details(
        logger,
        i18nFilter,
        schedulesDataService,
        $stateParams,
        exceptionHelper,
        rolesDataService,
        $uibModal,
        learningActivityScheduleStatus,
        learningPlanItemStatus,
        learningPlanItemSuccess,
        learningActivityState,
        dateFilter,
        usersDataService,
        $state,
        $http,
        documentsDataService,
        schedulePack,
        $sessionStorage,
        eFormsUrl,
        eFormTypes,
        schedulePacksDataService,
        eFormStatus,
        eFormPayer,
        organizationsDataService,
        citiesDataService,
        status,
        examApplicationFieldType,
        dateHelper,
        $scope,
        surveyType
    ) {
        var vm = this;

        //#region Slider
        var $slyFrame = $("#sly-frame-id");
        var $reportTiles = $("#schedule-tiles");
        var $navPrev = $(".sly-nav-prev");
        var $navNext = $(".sly-nav-next");

        $slyFrame.sly({
            slidee: $reportTiles,
            scrollSource: $reportTiles,
            dragSource: $reportTiles,
            mouseDragging: true,
            touchDragging: true,
            releaseSwing: true,
            swingSpeed: 0.2,
            horizontal: true,
            scrollBy: 320,
            prevPage: $navPrev,
            nextPage: $navNext,
            speed: 300,
        });

        // Ako nema ovoga onda se pojavi bug - ne žele se prikazati strelice na slideru
        setTimeout(function () {
            $(window).resize();
        }, 100);

        //#endregion

        vm.localeMessages = {
            selectPlaceholder: i18nFilter(
                "translations.general_gui_placeholder_select"
            ),
            selectAsyncPlaceholder: i18nFilter(
                "translations.general_gui_async_ddl_placeholder_select"
            ),
            userConsentTooltip: i18nFilter(
                "translations.schedule_presence_gui_main_table_user_consent_tooltip"
            ),
            educationConfirmedInPrograms: i18nFilter(
                "translations.schedule_packs_gui_note_for_linked_program_tooltip"
            ),
            addUserTooltip: i18nFilter(
                "translations.user_operation_create_new_user"
            ),
            educationIsFinishedTooltip: i18nFilter(
                "translations.schedule_presence_main_table_user_is_already_finished_education"
            ),
            asyncSelectPlaceholder: i18nFilter(
                "translations.general_gui_async_ddl_placeholder_select"
            ),
            learnerIsRetaking: i18nFilter(
                "translations.schedule_presence_gui_main_table_learner_is_retaking"
            ),
            closeSurvey: i18nFilter(
                "translations.schedule_surveys_close_survey"
            ),
            closeSurveys: i18nFilter(
                "translations.schedule_surveys_close_surveys"
            ),
        };

        vm.fieldType = examApplicationFieldType;

        vm.i18nFilter = i18nFilter; //Exposed to outside because detail table column definition

        vm.formData = {
            startDateConfig: {
                type: "date",
                width: "100%",
                showClearButton: true,
                acceptCustomValue: false,
                onValueChanged: function (e) {
                    if (!vm.formData.selectedNewLearner) return;
                    var startDate = vm.formData.selectedNewLearner.dateOfBirth;
                    vm.formData.selectedNewLearner.dateOfBirth =
                        dateHelper.createNewDateTimeWithoutSeconds(startDate);
                    if (
                        vm.formData.selectedNewLearner.dateOfBirth == undefined
                    ) {
                        vm.formData.selectedNewLearner.dateOfBirth =
                            dateHelper.convertFromStringToDate(startDate);
                    }
                },
                bindingOptions: {
                    value: "vm.formData.selectedNewLearner.dateOfBirth",
                },
                inputAttr: {
                    name: "dateOfBirth",
                    "ng-required": "true",
                },
            },
            startDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_start_date_is_either_blank_or_invalid"
                        ),
                    },
                ],
                onInitialized: function (e) {
                    vm.startDatevalidator = e.component;
                },
            },
            isInitialLoadOfApplication: true,

            documentsExpanded: true,
            recordsExpanded: false,
            donutChartExpanded: false,

            schedulePack: schedulePack,
            learners: [],
            learnersInfo: {},
            instructorRecords: [],

            instructorsIsLoading: false,

            selectedPeriod: undefined,
            selectedScheduleGroup: undefined,
            selectedSchedule: undefined,

            selectedPersons: [],

            showConfirmPresence: false,
            showDenyPresence: false,

            showRemoveFromSchedule: false,
            showFinishEducation: false,
            showCancelFinishEducation: false,

            showApplicationResponces: false,

            learningPlanItemStatus: learningPlanItemStatus,
            learningPlanItemSuccess: learningPlanItemSuccess,
            chartData: null,
            chartLabels: [
                i18nFilter("translations.schedule_presence_gui_chart_enrolled"),
                i18nFilter(
                    "translations.schedule_presence_gui_chart_justified_missed"
                ),
                i18nFilter("translations.schedule_presence_gui_chart_missed"),
                i18nFilter(
                    "translations.schedule_presence_gui_chart_completed"
                ),
            ],
            potentialUserIsLoading: false,
            organizationsIsLoading: false,
            citiesIsLoading: false,
            eFormPayer: eFormPayer,
            organizations: [],
            cities: [],
            substituteLearnerCollapsed: true,
            enrollLearnerCollapsed: true,
            dateOfBirthField: false,
            administrativeEnrollForm: undefined,

            surveysExpanded: false,
            surveyType: surveyType,
        };

        _.forEach(vm.formData.schedulePack.fieldNames, function (field) {
            if (field.definition) {
                var definitionForParsing = field.definition.replace(/'/g, '"');
                field.definitionParsed = JSON.parse(definitionForParsing).map(
                    function (value, index) {
                        return { id: index, name: value };
                    }
                );
            }
            if (field.code === "dateOfBirth") {
                vm.formData.dateOfBirthField = true;
            }
        });

        vm.validate = validate;

        vm.gridInstance = null;
        vm.rowToExpand = null;

        function validate() {
            if (vm.formData.selectedNewLearner === undefined) {
                return true;
            }
            if (
                vm.formData.administrativeEnrollForm.$invalid ||
                !vm.formData.selectedNewLearner.payer ||
                (vm.formData.selectedNewLearner.payer ==
                    vm.formData.eFormPayer.organization &&
                    vm.formData.selectedNewLearner
                        .cotisationPayerOrganization &&
                    !vm.formData.selectedNewLearner.cotisationPayerOrganization
                        .organization) ||
                (vm.formData.dateOfBirthField &&
                    !vm.formData.selectedNewLearner.dateOfBirth)
            ) {
                return true;
            } else {
                return false;
            }
        }

        //#region Disable schedule where user has no access to be
        _.forEach(vm.formData.schedulePack.schedules, function (schedule) {
            var userIsOwner = _.some(
                schedule.educationOwners,
                function (owner) {
                    return owner.id === $sessionStorage.userInfo.userId;
                }
            );
            schedule.enabled =
                userIsOwner ||
                rolesDataService.isUserInRoles(["EducationAdmin"]);
        });
        //#endregion

        //#region Method binding
        vm.showDetails = showDetails;
        vm.confirmPresence = confirmPresence;
        vm.denayPresence = denayPresence;
        vm.denayJustifiedPresence = denayJustifiedPresence;
        vm.confirmFinish = confirmFinish;
        vm.generateDoorSchedule = generateDoorSchedule;
        vm.generateAttendanceList = generateAttendanceList;
        vm.uploadAttendanceList = uploadAttendanceList;
        vm.getPotentialLearnersForSchedule = getPotentialLearnersForSchedule;
        vm.toggleDocuments = toggleDocuments;
        vm.toggleRecords = toggleRecords;
        vm.toggleDonutChart = toggleDonutChart;
        vm.downloadEmptyRecord = downloadEmptyRecord;
        vm.downloadRecord = downloadRecord;
        vm.downloadAttendanceList = downloadAttendanceList;
        vm.uploadRecord = uploadRecord;
        vm.administrativeEnroll = administrativeEnroll;
        vm.lockSchedule = lockSchedule;
        vm.unlockSchedule = unlockSchedule;
        vm.openEducationFileArchive = openEducationFileArchive;
        vm.downloadCertificate = downloadCertificate;
        vm.canDownloadCertificate = canDownloadCertificate;
        vm.regenerateCetificate = regenerateCetificate;
        vm.canRegenerateCertificate = canRegenerateCertificate;
        vm.uploadCertificate = uploadCertificate;
        vm.canUploadCertificate = canUploadCertificate;
        vm.openEForm = openEForm;
        vm.downloadInstructorFee = downloadInstructorFee;
        vm.sendMailToLearners = sendMailToLearners;
        vm.cancelFinish = cancelFinish;
        vm.sendMailToSelectedLearners = sendMailToSelectedLearners;
        vm.downloadExcelAttendanceList = downloadExcelAttendanceList;
        vm.confirmApplication = confirmApplication;
        vm.denyApplication = denyApplication;
        vm.sendMailToInstructors = sendMailToInstructors;
        vm.getPotentialUserForSchedule = getPotentialUserForSchedule;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.clearCitySelection = clearCitySelection;
        vm.searchOrganizations = searchOrganizations;
        vm.refreshCities = refreshCities;
        vm.organizationChanged = organizationChanged;
        vm.applierChanged = applierChanged;
        vm.openCreateNewUserModal = openCreateNewUserModal;
        vm.administrativeEnroll2 = administrativeEnroll2;
        vm.substituteLearner = substituteLearner;
        vm.getFormTitle = getFormTitle;
        vm.generateAttendanceListAgg = generateAttendanceListAgg;
        vm.searchOrganizationsByExternalIdOrTaxId =
            searchOrganizationsByExternalIdOrTaxId;
        vm.showPersonalInfo = false;
        vm.toggleSurveys = toggleSurveys;
        vm.sendSurvey = sendSurvey;
        vm.lockSurvey = lockSurvey;
        vm.lockSurveyTranslation = lockSurveyTranslation;
        vm.exportSurveyData = exportSurveyData;
        vm.checkSurveyValidityForParticipant =
            checkSurveyValidityForParticipant;
        vm.checkSurveyValidityForContractor = checkSurveyValidityForContractor;
        vm.checkSurveyValidityForCoordinator =
            checkSurveyValidityForCoordinator;
        //#endregion

        vm.showParticipantSurveySection = false;
        vm.showContractorSurveySection = false;
        vm.showCoordinatorSurveySection = false;
        vm.enableParticipantSendSurvey = false;
        vm.enableContractorSendSurvey = false;
        vm.enableCoordinatorSendSurvey = false;

        vm.schedulePacksDataService = schedulePacksDataService;

        if (vm.formData.schedulePack.spSurveyEducations) {
            var findParticipantSurvey =
                vm.formData.schedulePack.spSurveyEducations.find(function (
                    survey
                ) {
                    return survey.surveyType == 1;
                });
            if (findParticipantSurvey) {
                this.showParticipantSurveySection = true;
            }

            var findContractorSurvey =
                vm.formData.schedulePack.spSurveyEducations.find(function (
                    survey
                ) {
                    return survey.surveyType == 3;
                });
            if (findContractorSurvey) {
                this.showContractorSurveySection = true;
            }

            var findCoordinatorSurvey =
                vm.formData.schedulePack.spSurveyEducations.find(function (
                    survey
                ) {
                    return survey.surveyType == 2;
                });
            if (findCoordinatorSurvey) {
                this.showCoordinatorSurveySection = true;
            }
        }

        function getFormTitle() {
            var name = null;
            if (vm.formData.schedulePack.programName == null)
                name = vm.formData.schedulePack.educationName;
            else name = vm.formData.schedulePack.programName;
            var from = dateFilter(
                vm.formData.schedulePack.startTime,
                "shortDate"
            );
            var to = dateFilter(vm.formData.schedulePack.endTime, "shortDate");
            if (from === to) {
                return name + " " + from;
            } else {
                return name + " " + from + " - " + to;
            }
        }

        //#region Toggles
        function toggleDocuments() {
            vm.formData.documentsExpanded = !vm.formData.documentsExpanded;
        }

        function toggleRecords() {
            vm.formData.recordsExpanded = !vm.formData.recordsExpanded;
        }

        function toggleSurveys() {
            vm.formData.surveysExpanded = !vm.formData.surveysExpanded;

            if (vm.formData.surveysExpanded) {
                checkSurveyValidityForParticipant();
                checkSurveyValidityForContractor();
                checkSurveyValidityForCoordinator();
            }
        }
        //#endregion

        //#region Select schedule from route
        if ($stateParams.scheduleId) {
            var schedule = _.find(
                vm.formData.schedulePack.schedules,
                function (schedule) {
                    return schedule.id === $stateParams.scheduleId;
                }
            );

            showDetails(schedule);
        } else {
            var schedule = _.find(
                vm.formData.schedulePack.schedules,
                function (schedule) {
                    return schedule.enabled;
                }
            );

            showDetails(schedule);
        }
        //#endregion

        //#region Surveys
        function sendSurvey(educationActivitySchedulePackId, surveyId, surveyType) {
            if (educationActivitySchedulePackId && surveyType) {
                var sendSurveyEducationVm = {
                    educationActivitySchedulePackId:
                        educationActivitySchedulePackId,
                    surveyId: surveyId,
                    participantSurveyValidity: undefined,
                    contractorSurveyValidity: undefined,
                    coordinatorSurveyValidity: undefined,
                    surveyType: surveyType,
                };


                if (surveyType == 1) {
                    sendSurveyEducationVm.participantSurveyValidity =
                      vm.formData.schedulePack.participantSurveyValidity;
                      vm.formData.schedulePack.participantSurveyEducationStatus = 1;
                  } else if (surveyType == 3) {
                    sendSurveyEducationVm.contractorSurveyValidity =
                      vm.formData.schedulePack.contractorSurveyValidity;
                      vm.formData.schedulePack.contractorSurveyEducationStatus = 1;
                  } else if (surveyType == 2) {
                    sendSurveyEducationVm.coordinatorSurveyValidity =
                      vm.formData.schedulePack.coordinatorSurveyValidity;
                      vm.formData.schedulePack.coordinatorSurveyEducationStatus = 1;
                  }
                schedulePacksDataService
                    .sendSurvey(sendSurveyEducationVm)
                    .then((res) => {
                        logger.success(
                            i18nFilter(
                                "translations.schedule_surveys_notification_sending_survey"
                            )
                        );
                    });
            } else {
                logger.error("There was a problem sending email");
            }
        }

        function lockSurvey(surveyEducations, educationActivitySchedulePackId, educationId, surveyType) {
            if (educationActivitySchedulePackId && surveyType && surveyEducations) {
                var firstSurveyId = surveyEducations.find((surEdu) => surEdu.surveyType == surveyType).surveyId;

                var lockSurveyEducationVm = {
                    educationActivitySchedulePackId:
                        educationActivitySchedulePackId,
                    scheduleId: $stateParams.scheduleId,
                    surveyId: firstSurveyId,
                    educationId: educationId,
                    surveyType: surveyType,
                };
                schedulePacksDataService
                    .lockSurvey(lockSurveyEducationVm)
                    .then((res) => {
                        logger.success(
                            i18nFilter(
                                "translations.schedule_surveys_notification_lock_survey"
                            )
                        );


                    });

                checkSurveyValidityForParticipant();
                checkSurveyValidityForContractor();
                checkSurveyValidityForCoordinator();

                if (surveyType == 1) {
                    vm.formData.schedulePack.participantSurveyEducationStatus = 2;
                } else if (surveyType == 3) {
                    vm.formData.schedulePack.contractorSurveyEducationStatus = 2;
                } else if (surveyType == 2) {
                    vm.formData.schedulePack.coordinatorSurveyEducationStatus = 2;
                }
            } else {
                logger.error("There was a problem sending email");
            }
        }

        function lockSurveyTranslation(typeOfSurvey) {
            if (vm.formData.schedulePack.spSurveyEducations && vm.formData.schedulePack.spSurveyEducations.length > 1) {
                let count = 0;

                vm.formData.schedulePack.spSurveyEducations.forEach(surveyEducation => {
                    if (typeOfSurvey == surveyEducation.surveyType)
                        count++;
                    else if (typeOfSurvey == surveyEducation.surveyType)
                        count++;
                    else if (typeOfSurvey == surveyEducation.surveyType)
                        count++;
                });

                if (count > 1)
                    return vm.localeMessages.closeSurveys;
                else
                    return vm.localeMessages.closeSurvey;
            }
            else
                return vm.localeMessages.closeSurvey;
        }

        function exportSurveyData(
            educationActivitySchedulePackId,
            surveyType
        ) {

            documentsDataService.downloadDocument('api/schedulePacks/surveyReport/' + educationActivitySchedulePackId + '/' + surveyType, 'Poročilo anketa.xlsx');

        }

        //#endregion

        //#region Filters
        function showDetails(schedule) {
            if (!schedule.enabled) {
                return;
            }

            if (vm.formData.selectedSchedule && vm.formData.selectedSchedule.id != schedule.id) {
                vm.mainGridOptions.dataSource.reload();
                vm.detailGrid.clearSelection();
                vm.formData.selectedPersons = [];
                showFinishEducation();
                vm.formData.donutChartExpanded = false;
                vm.formData.chartData = null;
            }
            vm.formData.selectedSchedule = schedule;
            $stateParams.scheduleId = vm.formData.selectedSchedule.id;
            $state.go("schedulePacks.details", $stateParams, {
                reload: false,
                notify: false,
            });

            schedulesDataService
                .getInstructorRecordsForSchedule(schedule.id)
                .then(
                    function (data) {
                        vm.formData.instructorRecords = data;
                    },
                    function (error) {
                        exceptionHelper.handleException(error, true);
                    }
                );
        }
        //#endregion

        //#region Potential users search
        function getPotentialLearnersForSchedule(query) {
            if (!query || query.length < 3) {
                return;
            }

            schedulesDataService
                .getPotentialLearnersForSchedule(
                    vm.formData.selectedSchedule.id,
                    query
                )
                .then(
                    function (data) {
                        vm.formData.potentialLearners = data;
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
        }
        //#endregion

        //#region potential active users search
        function getPotentialUserForSchedule(query, selected) {
            if (query === undefined || query === "" || query.length < 3) {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.potentialUserIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(
                function (data) {
                    vm.formData.users = data;
                    vm.formData.potentialUserIsLoading = false;
                },
                function (error) {
                    vm.formData.potentialUserIsLoading = false;
                    logger.error(
                        i18nFilter(
                            "translations.education_exception_message_fetching_education_owners"
                        )
                    );
                }
            );
        }
        //#endregion

        //#region Table
        //#region Filter lists
        vm.scheduleStatus = [
            {
                id: learningActivityScheduleStatus.ongoing,
                format: i18nFilter(
                    "translations.schedule_gui_label_user_ongoing"
                ),
            },
            {
                id: learningActivityScheduleStatus.enrolled,
                format: i18nFilter(
                    "translations.schedule_gui_label_user_enrolled"
                ),
            },
            {
                id: learningActivityScheduleStatus.missed,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_schedule_missed"
                ),
            },
            {
                id: learningActivityScheduleStatus.completed,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_finished_schedule"
                ),
            },
            {
                id: learningActivityScheduleStatus.cancelled,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_cancelled"
                ),
            },
            {
                id: learningActivityScheduleStatus.justifiedMissed,
                format: i18nFilter(
                    "translations.schedule_presence_main_actions_justified_missed_schedule"
                ),
            },
        ];
        vm.activityStates = [
            {
                id: learningActivityState.started,
                format: i18nFilter(
                    "translations.filter_learning_activity_status_in_progress"
                ),
            },
            {
                id: learningActivityState.finished,
                format: i18nFilter(
                    "translations.filter_learning_activity_status_finished"
                ),
            },
        ];

        vm.scheduleEFormStatus = [
            {
                id: eFormStatus.inProgress,
                format: i18nFilter(
                    "translations.filter_eform_status_in_progress"
                ),
            },
            {
                id: eFormStatus.finished,
                format: i18nFilter("translations.filter_eform_status_finished"),
            },
            {
                id: eFormStatus.pendingPersonalData,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_personal_data"
                ),
            },
            {
                id: eFormStatus.pendingConfirmation,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_confirmation"
                ),
            },
            {
                id: eFormStatus.rejected,
                format: i18nFilter("translations.filter_eform_status_rejected"),
            },
        ];
        //#endregion

        vm.mainGridOptions = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/ScheduleLearnerOData/GetScheduleLearners",
                    beforeSend: function (e) {
                        e.url += "(scheduleId=" + vm.formData.selectedSchedule.id + ")";
                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            };
                        }
                    }
                }
            }),
            onContentReady: function (e) {
                vm.detailGrid = e.component;
                vm.formData.learners = e.component.getDataSource()._items;
            },
            filterRow: { visible: true },
            searchPanel: { visible: false },
            selection: {
                mode: "multiple",
            },
            onRowExpanded: function (e) {
                const model = e.key;
                if (!vm.formData.learnersInfo[model.learningPlanItemId]) {
                    schedulesDataService.getScheduleLearnerInfo(model.learningPlanItemId)
                    .then(
                        function (data) {
                            vm.formData.learnersInfo[data.learningPlanItemId] = data;
                        },
                        function (error) {
                            exceptionHelper.handleException(error);
                        }
                    );
                }
            },
            onSelectionChanged: function (selectedItems) {
                logger.log("Selection occurred: ", selectedItems);

                vm.formData.selectedPersons = selectedItems.selectedRowsData;
                if (
                    vm.formData.selectedPersons &&
                    vm.formData.selectedPersons.length !== 0
                ) {
                    vm.formData.showSendMailToSelectedLearners = true;
                    checkRequirementsForActions();
                } else {
                    vm.formData.showConfirmPresence = false;
                    vm.formData.showDenyPresence = false;
                    vm.formData.showRemoveFromSchedule = false;
                    vm.formData.showFinishEducation = false;
                    vm.formData.showCancelFinishEducation = false;
                    vm.formData.showSendMailToSelectedLearners = false;
                    vm.formData.showApplicationResponces = false;
                }
            },
            columns: [
                {
                    allowFiltering: true,
                    caption: i18nFilter(
                        "translations.schedule_presence_gui_main_table_user_educations"
                    ),
                    dataField: "learnerDisplayName",
                    width: "auto",
                    cellTemplate: "displayNameTemplate",
                    dataType: "string",
                    //calculateCellValue: function (rowData) {
                    //    return rowData.user.displayName;
                    //},
                    allowSorting: true,
                },
                {
                    dataField: "eFormPayerName",
                    caption: i18nFilter(
                        "translations.examSchedulePack_application_payerName"
                    ),
                    allowSorting: true,
                    width: "40%",
                    //minWidth: '300px'
                },
                {
                    dataField: "learningActivityScheduleStatus",
                    caption: i18nFilter(
                        "translations.schedule_presence_gui_main_table_schedule_status"
                    ),
                    allowSorting: true,
                    //    width: 'auto',
                    lookup: {
                        dataSource: vm.scheduleStatus,
                        valueExpr: "id",
                        displayExpr: "format",
                    },
                },
            ],
            masterDetail: {
                enabled: true,
                template: "detail",
            },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
        };
        //#endregion

        //#region create new user modal
        function openCreateNewUserModal(learnerType) {
            var modalInstance = $uibModal.open({
                templateUrl:
                    "app/schedulePacks/createNewUserModal/createNewUserModal.html",
                controller: "AddUserDialog",
                controllerAs: "modalvm",
                resolve: {
                        titles: ['userTitlesDataService', '$stateParams', function (userTitlesDataService, $stateParams) {
                            return userTitlesDataService.getActiveBasicUserTitles();
                        }]
                    },
            });

            modalInstance.result.then(
                function (data) {
                    //confirm callback
                    let eformType = null;
                    let entityId = null;

                    if (vm.formData.schedulePack.educationId) {
                        eformType = eFormTypes.education;
                        entityId = vm.formData.schedulePack.educationId;
                    } else {
                        eformType = eFormTypes.program;
                        entityId = vm.formData.schedulePack.programId;
                    }

                    console.info("creating new user ...");
                    schedulePacksDataService
                        .findOrCreateUser(eformType, entityId, data)
                        .then(
                            (result) => {
                                logger.success(
                                    i18nFilter(
                                        "translations.education_schedules_add_new_user_success_notification_message"
                                    )
                                );
                                if (learnerType == 1)
                                    vm.formData.selectedNewLearner = result;
                                else if (learnerType == 2)
                                    vm.formData.selectedSubstituteLearner =
                                        result;

                                //$state.go('schedulePacks', {}, { reload: true });
                            },
                            function (error) {
                                exceptionHelper.handleException(error, false);
                            }
                        );
                },
                function () {
                    //close callback
                    console.info("cancel SchedulePack modal closed");
                }
            );
        }
        //#endregion

        function applierChanged() {
            if (
                vm.formData.selectedNewLearner.payer ==
                vm.formData.eFormPayer.person
            ) {
                if (
                    vm.formData.selectedNewLearner.cotisationPayerOrganization
                ) {
                    vm.formData.organizationQuery = null;
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.organization =
                        null;

                    vm.formData.selectedNewLearner.cotisationPayerOrganization.responsiblePerson =
                        null;
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.email =
                        null;
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.mobileNumber =
                        null;
                }
            } else if (
                vm.formData.selectedNewLearner.payer ==
                vm.formData.eFormPayer.organization
            ) {
                if (vm.formData.selectedNewLearner.personPayer) {
                    vm.formData.selectedNewLearner.personPayer.city = null;
                    vm.formData.selectedNewLearner.personPayer.address = null;
                    vm.formData.selectedNewLearner.personPayer.taxId = null;
                }
            }
        }

        //#region organization and cities
        function organizationChanged() {}
        function refreshCities(filter) {
            if (!filter || filter === "") {
                vm.formData.cities = [];
                return;
            }
            vm.formData.citiesIsLoading = true;
            citiesDataService.getAllFilteredActiveCities(filter).then(
                function (data) {
                    vm.formData.cities = data;
                    vm.formData.citiesIsLoading = false;
                },
                function (error) {
                    vm.formData.citiesIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        //@Deprecated - not used in template
        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(
                function (data) {
                    vm.formData.organizations = data;
                    vm.formData.organizationsIsLoading = false;
                },
                function (error) {
                    vm.formData.organizationsIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
        }

        function clearCitySelection() {
            vm.item.city = undefined;
        }
        //#endregion

        //#region substitute learner
        function substituteLearner() {
            var replacerLearnerData = {
                oldLearnerId: vm.formData.selectedExistingLearner.learnerId,
                substituteLearnerId: vm.formData.selectedSubstituteLearner.id,
                learnerReplaceReason: vm.formData.learnerReplaceReason,
                eFormId: vm.formData.selectedExistingLearner.eFormId,
                educationId: schedulePack.educationId
            };

            schedulePacksDataService
                .replaceEFormLearnerOnSchedulePack(replacerLearnerData)
                .then((res) => {
                    logger.success(
                        i18nFilter(
                            "translations.schedule_queue_add_admin_replace_learner"
                        )
                    );
                    vm.formData.substituteLearnerCollapsed =
                        !vm.formData.substituteLearnerCollapsed;

                    vm.formData.selectedExistingLearner = null;
                    vm.formData.learnerReplaceReason = null;
                    vm.formData.selectedSubstituteLearner = null;
                });
        }
        //#endregion

        //#region donut chart
        function toggleDonutChart() {
            vm.formData.donutChartExpanded = !vm.formData.donutChartExpanded;
            if (vm.formData.donutChartExpanded && vm.formData.chartData == null) {
                schedulesDataService
                    .getLearnerStatistics(
                        vm.formData.selectedSchedule.id)
                    .then(
                        function (data) {
                            vm.formData.chartData = [
                                data.enrolledCount,
                                data.justifiedMissedCount,
                                data.missedCount,
                                data.completedCount,
                            ];
                        },
                        function (error) {
                            exceptionHelper.handleException(error);
                        }
                    );
            }
        }
        //#endregion

        //#region Helper
        function checkRequirementsForActions() {
            showConfirmPresence();
            showDenyPresence();
            showRemoveFromSchedule();
            showFinishEducation();
            showCancelFinishEducation();
            showApplicationResponces();
        }

        //#endregion

        //#region Show conditions

        function showSendMailToSelectedLearners() {
            vm.formData.showSendMailToSelectedLearners = true;
        }

        function showConfirmPresence() {
            var conditionIsSatisfied = true;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.learningPlanItemStatus ===
                            vm.formData.learningPlanItemStatus.finished ||
                        learner.eFormStatus !== eFormStatus.finished
                    ) {
                        conditionIsSatisfied = false;
                    }
                }
            );
            vm.formData.showConfirmPresence = conditionIsSatisfied;
        }

        function showDenyPresence() {
            var conditionIsSatisfied = true;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.learningPlanItemStatus ===
                            vm.formData.learningPlanItemStatus.finished ||
                        learner.eFormStatus !== eFormStatus.finished
                    ) {
                        conditionIsSatisfied = false;
                    }
                }
            );

            vm.formData.showDenyPresence = conditionIsSatisfied;
        }

        function showRemoveFromSchedule() {
            var conditionIsSatisfied = true;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.learningActivityScheduleStatus !==
                            learningActivityScheduleStatus.enrolled &&
                        learner.learningActivityScheduleStatus !==
                            learningActivityScheduleStatus.onWaitingList
                    ) {
                        conditionIsSatisfied = false;
                    }
                    if (
                        learner.learningPlanItemStatus ===
                        vm.formData.learningPlanItemStatus.finished
                    ) {
                        conditionIsSatisfied = false;
                    }
                    if (learner.eFormStatus !== eFormStatus.finished) {
                        conditionIsSatisfied = false;
                    }
                }
            );

            vm.formData.showRemoveFromSchedule = conditionIsSatisfied;
        }

        function showFinishEducation() {
            var conditionIsSatisfied = vm.formData.selectedPersons.length > 0;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.learningPlanItemStatus ===
                        learningPlanItemStatus.finished ||
                        learner.eFormStatus !== eFormStatus.finished
                    ) {
                        conditionIsSatisfied = false;
                    }
                    conditionIsSatisfied &= learner.canFinish;
                }
            );

            vm.formData.showFinishEducation = conditionIsSatisfied;
        }

        function showCancelFinishEducation() {
            var conditionIsSatisfied = true;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.learningPlanItemStatus !==
                            learningPlanItemStatus.finished ||
                        learner.eFormStatus !== eFormStatus.finished
                    ) {
                        conditionIsSatisfied = false;
                    }
                }
            );

            vm.formData.showCancelFinishEducation = conditionIsSatisfied;
        }

        function showApplicationResponces() {
            var conditionIsSatisfied = true;

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.eFormStatus !== eFormStatus.pendingConfirmation
                    ) {
                        conditionIsSatisfied = false;
                    }
                }
            );

            vm.formData.showApplicationResponces = conditionIsSatisfied;
        }
        //#endregion

        //#region Actions
        function confirmPresence() {
            var learningActivityAcheduleIds = [];
            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    learningActivityAcheduleIds.push(
                        learner.learningActivityScheduleId
                    );
                }
            );

            schedulesDataService.confirmPresence(
                learningActivityAcheduleIds,
                vm.formData.selectedSchedule.id
            );
        }

        function denayPresence() {
            var learningActivityAcheduleIds = [];
            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    learningActivityAcheduleIds.push(
                        learner.learningActivityScheduleId
                    );
                }
            );

            schedulesDataService.denyPresence(
                learningActivityAcheduleIds,
                vm.formData.selectedSchedule.id
            );
        }

        function denayJustifiedPresence() {
            var learningActivityAcheduleIds = [];
            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    learningActivityAcheduleIds.push(
                        learner.learningActivityScheduleId
                    );
                }
            );

            schedulesDataService.denayJustifiedPresence(
                learningActivityAcheduleIds,
                vm.formData.selectedSchedule.id
            );
        }

        function confirmApplication() {
            var educationActivitySchedulePackIds = [];
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    educationActivitySchedulePackIds.push(
                        learner.schedulePackId
                    );
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.id);
                }
            );

            schedulesDataService.confirmApplication(
                educationActivitySchedulePackIds,
                eFormIds,
                learnerIds,
                vm.formData.selectedSchedule
            );
        }

        function denyApplication() {
            var educationActivitySchedulePackId = [];
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    educationActivitySchedulePackId.push(
                        learner.schedulePackId
                    );
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.id);
                }
            );

            schedulesDataService.denyApplication(
                educationActivitySchedulePackId,
                eFormIds,
                learnerIds,
                vm.formData.selectedSchedule,
                rejectReason
            );
        }

        function confirmFinish() {
            $uibModal.open({
                templateUrl:
                    "app/schedulePacks/details/confirmFinishEducation/confirmFinishEducation.html",
                controller: "confirmFinishEducation",
                controllerAs: "vm",
                resolve: {
                    learners: [
                        function () {
                            return vm.formData.selectedPersons;
                        },
                    ],
                    schedule: [
                        function () {
                            return vm.formData.selectedSchedule;
                        },
                    ],
                },
            });
        }

        function cancelFinish() {
            $uibModal.open({
                templateUrl:
                    "app/schedulePacks/details/cancelFinishEducation/cancelFinishEducation.html",
                controller: "cancelFinishEducation",
                controllerAs: "vm",
                resolve: {
                    learners: [
                        function () {
                            return vm.formData.selectedPersons;
                        },
                    ],
                    schedule: [
                        function () {
                            return vm.formData.selectedSchedule;
                        },
                    ],
                },
            });
        }

        function generateDoorSchedule() {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/doorSchedule",
                    "Urnik.docx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function generateAttendanceList() {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/attendanceList",
                    "Seznam udelezencev.docx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function generateAttendanceListAgg() {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/attendanceListAgg/" +
                        vm.formData.selectedSchedule.schedulePackId,
                    "Seznam prisotnosti.docx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function openEForm(eFormId, eFormType) {
            if (!eFormId || !eFormType) {
                return;
            }
            var tenantId = $sessionStorage.userInfo.tenantId;

            if (eFormType === eFormTypes.education) {
                window.open(
                    eFormsUrl[tenantId] + "/edit/education/" + eFormId,
                    "_blank"
                );
            } else if (eFormType === eFormTypes.program) {
                window.open(
                    eFormsUrl[tenantId] + "/edit/program/" + eFormId,
                    "_blank"
                );
            }
        }

        function uploadAttendanceList() {
            $uibModal.open({
                templateUrl:
                    "app/schedulePacks/details/attendanceList/attendanceList.html",
                controller: "attendanceListUpload",
                controllerAs: "vm",
                resolve: {
                    scheduleId: [
                        function () {
                            return vm.formData.selectedSchedule.id;
                        },
                    ],
                },
            });
        }

        function downloadEmptyRecord(instructorId) {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/ " +
                        vm.formData.selectedSchedule.id +
                        " /instructorRecords/ " +
                        instructorId +
                        "/empty",
                    "Zapisnik izvajalca.docx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function downloadRecord(instructorId, fileName) {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/instructorRecords/" +
                        instructorId,
                    fileName
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function downloadAttendanceList(fileName) {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/attendanceListDownload/",
                    fileName
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function uploadRecord(instructor) {
            var modal = $uibModal.open({
                templateUrl:
                    "app/schedulePacks/details/uploadRecord/uploadRecord.html",
                controller: "uploadRecord",
                controllerAs: "vm",
                resolve: {
                    scheduleId: [
                        function () {
                            return vm.formData.selectedSchedule.id;
                        },
                    ],
                    instructor: [
                        function () {
                            return instructor;
                        },
                    ],
                },
            });
        }

        function administrativeEnroll(user) {
            /*
            schedulesDataService.administrativeEnroll(user, vm.formData.selectedSchedule.id)
                .then(function () {
                    logger.success(i18nFilter('translations.schedule_queue_add_admin_user_enrollment'));

                }, function (error) {
                    exceptionHelper.handleException(error);
                });
                */
        }

        function administrativeEnroll2() {
            if (vm.formData.selectedNewLearner.educationLevel) {
                vm.formData.selectedNewLearner.educationLevel =
                    vm.formData.selectedNewLearner.educationLevel.name;
            }

            var eFormData = {
                id: null,
                learners: [vm.formData.selectedNewLearner],
            };

            if (schedulePack.educationId) {
                eFormData.education = { id: schedulePack.educationId };
                eFormData.pricePerLearner =
                    vm.formData.schedulePack.educationLearnerPrice;
            } else if (schedulePack.programId) {
                eFormData.education = { id: schedulePack.programId };
                eFormData.pricePerLearner =
                    vm.formData.schedulePack.programLearnerPrice;
            }

            if (vm.formData.selectedNewLearner.payer == eFormPayer.person) {
                eFormData.personPayerCity =
                    vm.formData.selectedNewLearner.personPayer.city.cityWithPostalCode;
                eFormData.personPayerAddress =
                    vm.formData.selectedNewLearner.personPayer.address;
                eFormData.personPayerTaxId =
                    vm.formData.selectedNewLearner.personPayer.taxId;
            } else if (
                vm.formData.selectedNewLearner.payer == eFormPayer.organization
            ) {
                eFormData.organizationPayerNationalId =
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.organization.externalId;

                eFormData.contactPersonName =
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.responsiblePerson;
                eFormData.contactPersonEmail =
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.email;
                eFormData.contactPersonPhone =
                    vm.formData.selectedNewLearner.cotisationPayerOrganization.mobileNumber;
            }

            eFormData.payer = vm.formData.selectedNewLearner.payer;
            eFormData.status = status.processing;
            eFormData.eFormStatus = eFormStatus.inProgress;
            eFormData.type = vm.formData.schedulePack.educationId
                ? eFormTypes.education
                : eFormTypes.program;
            eFormData.administrativeEnrollReason =
                vm.formData.selectedNewLearner.administrativeEnrollReason;
            eFormData.submittedBy = $sessionStorage.userInfo.userId;

            eFormData.pricePerLearner = vm.formData.schedulePack.price
                ? vm.formData.schedulePack.price.amount
                : eFormData.pricePerLearner;
            eFormData.pricePerLearner = vm.formData.schedulePack.groupPrice
                ? vm.formData.schedulePack.groupPrice.amount
                : eFormData.pricePerLearner;
            eFormData.isGroupPrice =
                vm.formData.schedulePack.groupPrice != null;

            eFormData.interest = false;
            eFormData.schedulePack = { id: vm.formData.schedulePack.id };

            schedulesDataService.administrativeEnroll(eFormData).then(
                function (result) {
                    logger.success(
                        i18nFilter(
                            "translations.schedule_queue_add_admin_user_enrollment"
                        )
                    );
                    vm.formData.enrollLearnerCollapsed = true;
                    vm.formData.selectedNewLearner = null;
                    vm.showPersonalInfo = false;
                },
                function (error) {
                    exceptionHelper.handleException(error);
                }
            );
        }

        function lockSchedule() {
            schedulesDataService
                .lockSchedule(vm.formData.selectedSchedule.id)
                .then(
                    function (data) {
                        logger.success(
                            i18nFilter(
                                "translations.schedule_presence_lock_schedule_success_message"
                            )
                        );
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function unlockSchedule() {
            schedulesDataService
                .unlockSchedule(vm.formData.selectedSchedule.id)
                .then(
                    function (data) {
                        logger.success(
                            i18nFilter(
                                "translations.schedule_presence_lock_schedule_success_message"
                            )
                        );
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function openEducationFileArchive() {
            $state.go("educations.scheduleFSDocuments", {
                id: vm.formData.selectedSchedule.educationId,
                scheduleId: vm.formData.selectedSchedule.id,
            });
        }
        function downloadCertificate(learningPlanItemData) {
            if (canDownloadCertificate(learningPlanItemData)) {
                documentsDataService
                    .downloadDocument(
                        "api/learningPlans/" + learningPlanItemData.learningPlanItemId + "/certificate",
                        "Potrdilo-" + learningPlanItemData.learningPlanItemId + ".pdf"
                    )
                    .then(
                        function (data) { },
                        function (error) {
                            error = {
                                exception: {
                                    additionalData: {},
                                    result: {
                                        exceptionCode: -90007,
                                        operationCode: 0,
                                    },
                                },
                                status: 400,
                            };
                            exceptionHelper.handleException(error);
                        }
                    );
            }
        }

        function canDownloadCertificate(learningPlanItemData) {
            return learningPlanItemData.certificateExists
                && learningPlanItemData.documentCertificationSign != 2
                && learningPlanItemData.learningPlanItemStatus == vm.formData.learningPlanItemStatus.finished
                && learningPlanItemData.learningPlanItemSuccess === vm.formData.learningPlanItemSuccess.success;
        }

        function regenerateCetificate(learningPlanItemData) {
            if (canRegenerateCertificate(learningPlanItemData)) {
                documentsDataService.regenerateCertificate(learningPlanItemData.learningPlanItemId).then(
                    function (data) {
                        logger.success(
                            i18nFilter(
                                "translations.education_regenerate_certificate_queue_add"
                            )
                        );
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
            }
        }

        function canRegenerateCertificate(learningPlanItemData) {
            return learningPlanItemData.templateGenerationEnabled
                && learningPlanItemData.documentCertificationSign != 2
                && learningPlanItemData.learningPlanItemStatus == vm.formData.learningPlanItemStatus.finished
                && learningPlanItemData.learningPlanItemSuccess === vm.formData.learningPlanItemSuccess.success;
        }

        function uploadCertificate(learner) {
            if (canUploadCertificate(learner)
            ) {
                $uibModal.open({
                    templateUrl:
                        "app/schedulePacks/details/uploadCertificateModal/uploadCertificateModal.html",
                    controller: "uploadCertificateModal",
                    controllerAs: "vm",
                    resolve: {
                        learningPlanItemId: [
                            function () {
                                return learner.data.learningPlanItemId;
                            },
                        ],
                    },
                });
            }
        }

        function canUploadCertificate(learner) {
            return learner.data.learningPlanItemSuccess === vm.formData.learningPlanItemSuccess.success
                && !vm.formData.schedulePack.isCertificationEnabled;
        }

        function downloadInstructorFee(instructorId) {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/instructorFee/" +
                        instructorId,
                    "AuthorsFee.docx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function sendMailToLearners() {
            $uibModal.open({
                templateUrl:
                    "app/schedulePacks/details/confirmSendMailToLearners/confirmSendMailToLearners.html",
                controller: "confirmSendMailToLearners",
                controllerAs: "vm",
                resolve: {
                    schedulePackId: [
                        function () {
                            return vm.formData.schedulePack.id;
                        },
                    ],
                },
            });
        }

        function sendMailToSelectedLearners() {
            var subject = undefined;
            var to = "";

            if (vm.formData.schedulePack.educationName) {
                subject = vm.formData.schedulePack.educationName;
            } else {
                subject = vm.formData.schedulePack.programName;
            }

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (vm.formData.selectedPersons.length == index - 1) {
                        to += learner.learnerEmail;
                    } else {
                        to += learner.learnerEmail + ";";
                    }
                }
            );

            window.location.href = "mailto:?bcc=" + to + "&subject=" + subject;
        }

        function downloadExcelAttendanceList() {
            documentsDataService
                .downloadDocument(
                    "/api/schedules/" +
                        vm.formData.selectedSchedule.id +
                        "/attendanceListExcelDownload",
                    "Seznam udeležencev.xlsx"
                )
                .then(
                    function (data) {},
                    function (error) {
                        error = {
                            exception: {
                                additionalData: {},
                                result: {
                                    exceptionCode: -90007,
                                    operationCode: 0,
                                },
                            },
                            status: 400,
                        };
                        exceptionHelper.handleException(error);
                    }
                );
        }

        function searchOrganizationsByExternalIdOrTaxId() {
            organizationsDataService
                .searchOrganizationsByExternalIdOrTaxId(
                    vm.formData.organizationQuery
                )
                .then(function (res) {
                    if (
                        !vm.formData.selectedNewLearner
                            .cotisationPayerOrganization
                    )
                        vm.formData.selectedNewLearner.cotisationPayerOrganization =
                            {};

                    vm.formData.selectedNewLearner.cotisationPayerOrganization.organization =
                        res;
                });
        }

        function sendMailToInstructors() {
            $uibModal
                .open({
                    templateUrl:
                        "app/schedulePacks/details/confirmSendMailToInstructors/confirmSendMailToInstructors.html",
                    controller: "confirmSendMailToInstructors",
                    controllerAs: "vm",
                    resolve: {
                        schedulePackId: [
                            function () {
                                return vm.formData.schedulePack.id;
                            },
                        ],
                    },
                })
                .result.then(
                    function (results) {
                        // after clicking OK button
                        var subject = undefined;
                        var to = "";
                        var body =
                            i18nFilter(
                                "translations.schedule_send_mail_to_instructors_message"
                            ) +
                            "%0A" +
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            "/#/educations/" +
                            $stateParams.entityId +
                            "/schedules/" +
                            $stateParams.scheduleId +
                            "/documents/fs";

                        if (vm.formData.schedulePack.educationName) {
                            subject = vm.formData.schedulePack.educationName;
                        } else {
                            subject = vm.formData.schedulePack.programName;
                        }

                        angular.forEach(
                            vm.formData.instructorRecords,
                            function (instructorRecord, index) {
                                if (
                                    vm.formData.instructorRecords.length ==
                                    index - 1
                                ) {
                                    to += instructorRecord.instructor.email;
                                } else {
                                    to +=
                                        instructorRecord.instructor.email + ";";
                                }
                            }
                        );

                        window.location.href =
                            "mailto:?subject=" +
                            subject +
                            "&bcc=" +
                            to +
                            "&body=" +
                            body;
                    },
                    function () {
                        // after clicking Cancel button or clicking background
                    }
                );
        }



        function checkSurveyValidityForParticipant() {
            if (
                vm.formData.schedulePack.participantSurveyValidity !=
                    undefined &&
                vm.formData.schedulePack.participantSurveyValidity > 0
            ) {
                vm.enableParticipantSendSurvey = true;
            } else {
                vm.enableParticipantSendSurvey = false;
            }
        }

        function checkSurveyValidityForContractor() {
            if (
                vm.formData.schedulePack.contractorSurveyValidity !=
                    undefined &&
                vm.formData.schedulePack.contractorSurveyValidity > 0
            ) {
                vm.enableContractorSendSurvey = true;
            } else {
                vm.enableContractorSendSurvey = false;
            }
        }

        function checkSurveyValidityForCoordinator() {
            if (
                vm.formData.schedulePack.coordinatorSurveyValidity !=
                    undefined &&
                vm.formData.schedulePack.coordinatorSurveyValidity > 0
            ) {
                vm.enableCoordinatorSendSurvey = true;
            } else {
                vm.enableCoordinatorSendSurvey = false;
            }
        }
        //#endregion
    }
})();
