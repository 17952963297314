(function () {
    'use strict';

    angular
        .module('app.programs.documents')
        .controller('ProgramDocumentsFileSystemDelete', deletecontroller);

    deletecontroller.$inject = ['logger', '$scope', 'exceptionHelper', 'programDocumentsDataService', 'name', 'path'];

    function deletecontroller(logger, $scope, exceptionHelper, programDocumentsDataService, name, path) {        
        var vm = this;
        vm.name = name;
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            programDocumentsDataService.deleteItem(path).then(function (data) {
                $scope.$close(true);
            }, function (error) {
                vm.formData.loading = false;
                exceptionHelper.handleException(error);
            });
        };
    }
})();
