(function () {
    'use strict';

    angular
        .module('app')
        .factory('personRecordsDataService', personRecords);

    personRecords.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function personRecords($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getPersonRecords: getPersonRecords,
            getPersonRecordById: getPersonRecordById,
            getLanguages: getLanguages
        };

        return service;

        function getPersonRecords() {
            return $http.get('api/personRecords').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPersonRecordById(id) {
            return $http.get('api/personRecords/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getLanguages() {
            return $http.get('api/personRecords/languages').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
