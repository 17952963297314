(function () {
    'use strict';

    angular
        .module('app.exams.bulletin')
        .controller('deleteBulletinsController', deleteBulletinsController);

    deleteBulletinsController.$inject = ['logger', '$stateParams', 'bulletinsDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteBulletinsController(logger, $stateParams, bulletinsDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            bulletinsDataService.deleteBulletin($stateParams.bulletinId).then(function (data) {
                logger.success(i18nFilter("translations.exam_bulletin_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
