(function () {
    'use strict';
    
    angular
        .module('app.schedulePacks')
        .controller('AddUserDialog', AddUserDialog);

    AddUserDialog.$inject = ['logger', 'gender', 'titles', 'i18nFilter', '$scope', 'usersDataService', 'exceptionHelper', '$state', '$sessionStorage', 'authProviders', 'schedulePacksDataService', '$stateParams'];

    function AddUserDialog(logger, gender, titles, i18nFilter, $scope, usersDataService, exceptionHelper, $state, $sessionStorage, authProviders, schedulePacksDataService, $stateParams) {
        var modalvm = this;

        //modalvm.isEdit = $state.current.data.isEdit;
        modalvm.item = { title: null, firstName: null, lastName: null, gender: null, displayName: null, email: null };
        modalvm.titles = titles;

        modalvm.localeMessages = {
            selectPlaceholder: i18nFilter('translations.general_gui_placeholder_select'),
            asyncSelectPlaceholder: i18nFilter('translations.general_gui_async_ddl_placeholder_select'),
            titlePlaceholder: i18nFilter('translations.general_gui_placeholder_name')
        };

        modalvm.formData = {
            gender: gender,
            organizations: [],
            tenantIdentityProviderIsADFS: $sessionStorage.userInfo.tenantConfig.authenticationAndAuthorization.identityProvider === authProviders.activeDirectoryFS,
            dateToPreventCache: new Date().getTime()
        };

        modalvm.add = add;
        modalvm.cancel = cancel;
        modalvm.displayName = displayName;


        function add() {
            $scope.$close(modalvm.item);
        }

        function cancel() {
            $scope.$dismiss();
        }

        function displayName() {
            var titleName = modalvm.item.title ? modalvm.item.title.name + ' ' : ''
            var firstName = modalvm.item.firstName ? modalvm.item.firstName : '';
            var lastName = modalvm.item.lastName ? modalvm.item.lastName : '';
            modalvm.item.displayName = (titleName + firstName + ' ' + lastName).trim();

        }
    }
    
})();
