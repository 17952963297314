(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.video')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('educations.administration.activities.edit.videoSecondStep', {
            url: '/videoSecondStep',
            templateUrl: 'app/educations/administration/activities/edit/video/secondStep.html',
            controller: 'VideoSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            resolve: {
                //portalUrl: ['videosDataService', function (videosDataService) {
                //    return videosDataService.getVideoPortalUrl();
                //}]
            },
            data: {
                roles: ['CatalogAdmin', 'RealEducationOwner'],
                isActivityEdit: true
            }
        })
        .state('educations.administration.activities.add.videoSecondStep', {
            url: '/videoSecondStep',
            templateUrl: 'app/educations/administration/activities/edit/video/secondStep.html',
            controller: 'VideoSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            resolve: {
                //portalUrl: ['videosDataService', function (videosDataService) {
                //    return videosDataService.getVideoPortalUrl();
                //}]
            },
            data: {
                roles: ['CatalogAdmin', 'RealEducationOwner'],
                isActivityEdit: false
            }
        });
    }
})();