(function () {
    'use strict';

    angular
        .module('app.analytics')
        .controller('analytics', analytics);

    analytics.$inject = ['logger', '$rootScope', '$location', 'rolesDataService', 'i18nFilter', '$sessionStorage', '$state', '$uibModal', 'analyticsDataService'];

    function analytics(logger, $rootScope, $location, rolesDataService, i18nFilter, $sessionStorage, $state, $uibModal, analyticsDataService) {
        var vm = this;
        vm.openReportViwerModal = openReportViwerModal;

        vm.formData = {
        };



        function openReportViwerModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/analytics/components/reportViewer/reportViewer.html',
                controller: 'reportViwerDialog',
                controllerAs: 'modalvm',
                size: 'lg',
                resolve: {
                    report: function () {
                        return analyticsDataService.getEFormInterestReport();
                    }
                }
            });

            modalInstance.result.then(function (data) {

            }, function () {
                //close callback
                console.info('cancel SchedulePack modal closed');
            });
        }
    }
})();
