(function () {
    'use strict';

    angular
        .module('app.educations.administration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('educations.administration', {
            url: '/administration',
            templateUrl: 'app/educations/administration/administration.html',            
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'EducationOwner', 'EducationAdmin']
            }
        });
    }
})();
