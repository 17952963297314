(function () {
    'use strict';

    angular
        .module('app.logout')
        .controller('Logout', logout);

    logout.$inject = ['logger', '$rootScope', '$state', '$localStorage', 'appName', '$window', '$location', 'adalAuthenticationService', '$http','publicSiteUrl','tenantResourceKey'];

    function logout(logger, $rootScope, $state, $localStorage, appName, $window, $location, adalAuthenticationService, $http, publicSiteUrl, tenantResourceKey) {        
        var vm = this;
        vm.appName = appName;
        vm.publicSiteUrl = publicSiteUrl[tenantResourceKey];
        vm.publicSite = publicSite;
        vm.tenantResourceKey = tenantResourceKey;

        if ($localStorage.toState) {
            var toState = $localStorage.toState;
            $localStorage.toState = undefined;
            $state.go(toState, undefined);
        }

        function publicSite() {
            window.location.href = vm.publicSiteUrl;
        }
    }
})();
