(function () {
    'use strict';

    angular
        .module('app.base')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {

        $stateProvider
            .state('base', {
                abstract: true,
                templateUrl: 'app/base/base.html',
                controller: 'base',
                controllerAs: 'vm',
                resolve: {
                    localization: ['logger', 'locale', function (logger, locale) {
                        return locale.ready('translations').then(function () {
                            logger.log("Translations loaded");
                            return true;
                        });
                    }],
                    tabs: ['tenantDataService', 'applicationTabs', function (tenantDataService, applicationTabs) {
                        var tabs = [
                            applicationTabs.educationSchedulePack,
                            applicationTabs.proficiencyExamsSchedulePack,
                            applicationTabs.examSchedulePack,
                            applicationTabs.schoolingSchedulePack,
                            applicationTabs.onlineEducations,
                            applicationTabs.reports,
                            applicationTabs.extras,
                            applicationTabs.eForms,
                            applicationTabs.analytics
                        ];

                        return tenantDataService.getTenantTabs(tabs);
                    }]
                },
                params: {
                    auth: undefined
                }
            })

    }
})();
