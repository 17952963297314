(function () {
    'use strict';

    angular
        .module('app')
        .controller('editTemplate', editTemplate);

    editTemplate.$inject = ['$http', 'logger', '$state', 'templatesDataService', 'exceptionHelper', 'template', 'i18nFilter', '$sessionStorage','documentsDataService'];

    function editTemplate($http, logger, $state, templatesDataService, exceptionHelper, template, i18nFilter, $sessionStorage, documentsDataService) {
        var vm = this;
        template.fileId = undefined;
        vm.item = template;
        vm.isEdit = $state.current.data.isEdit;

        vm.add = add;
        vm.edit = edit;
        vm.downloadTemplate = downloadTemplate;

        function downloadTemplate() {
            documentsDataService.downloadDocument('/api/templates/' + template.id + '/download', template.fileName);
        }

        function add() {
            templatesDataService.addTemplate(vm.item).then(function () {
                logger.success(i18nFilter('translations.manage_templates_queue_add'));
                $state.go('^');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function edit() {
            templatesDataService.editTemplate(vm.item).then(function () {
                logger.success(i18nFilter('translations.manage_templates_queue_edit'));
                $state.go('^');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }


        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message') + ' ' + i18nFilter('translations.templates_gui_upload_warning'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message') + ' ' + i18nFilter('translations.templates_gui_upload_warning'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '10',
            acceptedFiles: '.docx, .xlsx',
            addRemoveLinks: true,
            error: "Error"
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.item.fileId = id;
            },
            'removedfile': function (file) {
                if (file.accepted) {
                    vm.item.fileId = undefined;
                }
            }
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        }

    }
})();
