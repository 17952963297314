(function () {
    'use strict';

    //#region String extension method
    String.prototype.capitalizeFirstLetter = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
    //#endregion

    angular.module('app.core', [
        'ngStorage',
        'chart.js',
        'ngSanitize',
        'angular-loading-bar',
        'ngMessages',
        'AdalAngular',
        'bootstrap.fileField',
        'ui.select',
        'ui.bootstrap',
        'tmh.dynamicLocale',
        'ngLocalize',
        'ngLocalize.Config',
        'ngLocalize.InstalledLanguages',
        'ngLocalize.Events',
        'angularBootstrapNavTree',
        'datePicker',
        'colorpicker.module',
        'ngImgCrop',
        'blocks.logger',
        'blocks.router',
        'blocks.controls',
        '720kb.socialshare',
        'dx',
        'toastr',
        'angularCSS',
        'thatisuday.dropzone',
        'angularLoad'
    ]);
})();