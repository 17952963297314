(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('tenantDataService', tenant);

    tenant.$inject = ['$http', 'logger', '$q', '$sessionStorage', 'blobHelper'];

    function tenant($http, logger, $q, $sessionStorage, blobHelper) {
        var service = {
            clearData: clearData,
            getCultures: getCultures,
            getTenants: getTenants,
            getTenantById: getTenantById,
            editTenant: editTenant,
            addTenant: addTenant,
            getTenantInfo: getTenantInfo,
            getTenantTabs: getTenantTabs,
            hasTenantFeature: hasTenantFeature,
            hasTenantElement: hasTenantElement,
            showSidebar: showSidebar,
            getTenantResourceKey: getTenantResourceKey
        };

        return service;


        function getTenants() {
            return $http.get('/api/tenants')
                .then(function(data) {
                    return data.data;
                }, function(data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function addTenant(tenant) {
            return $http.post('/api/tenants', clearData(tenant), { transformRequest: angular.identity, headers: { 'Content-Type': undefined } })
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editTenant(tenant) {
            return $http.put('/api/tenants', clearData(tenant), { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTenantById(id) {
            return $http.get('/api/tenants/' + id)
                .then(function(data) {
                    return data.data;
                }, function(data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        
        function getTenantInfo() {
            return $http.get('/api/tenants/info')
                .then(function(data) {
                    return data.data;
                }, function(data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getTenantTabs(tabIds) {
            return $http.post('/api/tenants/tabs', tabIds)
                .then(function (data) {
                    return data.data.tabs;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        
        function hasTenantFeature(feature) {
            var isOk = false;
            if ($sessionStorage.tenantFeatures) {
                var tenantFeatures = $sessionStorage.tenantFeatures;
                angular.forEach(tenantFeatures, function (element, index) {
                    if (element.name === feature && element.access) {
                        isOk = true;
                    }
                });
            }
            return isOk;
        }

        function hasTenantElement(elementName) {
            var isOk = false;
            if ($sessionStorage.tenantFeatures) {
                var tenantFeatures = $sessionStorage.tenantFeatures;
                angular.forEach(tenantFeatures, function (featureElement, featureIndex) {
                    angular.forEach(featureElement.elements, function (element, elementIndex) {
                        if (element.name === elementName && element.access && featureElement.access) {
                            isOk = true;
                        }
                    });
                });
            }
            return isOk;
        }

        function showSidebar(elementName, state) {
            var isOk = false;
            if ($sessionStorage.tenantFeatures) {
                var tenantFeatures = $sessionStorage.tenantFeatures;
                angular.forEach(tenantFeatures, function (featureElement, featureIndex) {
                    angular.forEach(featureElement.elements, function (element, elementIndex) {
                        var isStateInList = false;
                        if(element.settings){
                            isStateInList = element.settings.toLowerCase().indexOf(state.toLowerCase()) !== -1;
                        }
                        if (element.name === elementName && element.access && featureElement.access && isStateInList) {
                            isOk = true;
                        }
                    });
                });
            }
            return isOk;
        }

        function getCultures() {

            return $http.get('/api/cultures')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function clearData(tenant) {
            var data = angular.copy(tenant);

            const formData = new FormData();

            var regionalSettings = data.configuration.applicationConfig.regionalSettings.name;

            data.configuration.applicationConfig.regionalSettings = regionalSettings;
            if (data.uploadedMaintenancePageImage) {
                var blob = blobHelper.dataURItoBlob(data.uploadedMaintenancePageImage);
                data.uploadedMaintenancePageImage = null;
                formData.append("tenant", JSON.stringify(data));
                formData.append("uploadedMaintenancePageImage", blob);

            }
            else {
                formData.append("tenant", JSON.stringify(data));
                formData.append("uploadedMaintenancePageImage", null);
            }
            
            return formData;
        }

        function getTenantResourceKey() {
            return $http.get('/api/resources/GetTenant')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
    }
})();
