(function () {
    'use strict';

    angular
        .module('app.locations')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('locations', {
            parent: 'base',
            url: '/manage/locations',
            templateUrl: 'app/manage/locations/locations.html',
            controller: 'locations',
            controllerAs: 'vm',
            requireADLogin: true,
            resolve:{
                locations: ['locationsDataService', function (locationsDataService) {
                    return locationsDataService.getLocations();
                }]
            },
            data: {
                roles: ['EducationAdmin']
            }
        })
            .state('locations.delete', {
                url: '/delete/:id',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/locations/deleteLocations.html',
                        controller: 'deleteLocations',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('locations');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('locations');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('locations.calendar', {
                url: '/calendar/:id',
                templateUrl: 'app/manage/locations/locationCalendar.html',
                controller: 'locationCalendar',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    calendarSchedules: ['locationsDataService', '$stateParams', function (locationsDataService, $stateParams) {
                        return locationsDataService.getLocationCalendarSchedules($stateParams.id);
                    }]
                },
                data: {
                }
            })
        .state('locations.edit', {
            url: '/edit/:id',
            templateUrl: 'app/manage/locations/editLocations.html',
            controller: 'editLocations',
            controllerAs: 'vm',
            requireADLogin: true,
            resolve:{
                organizations: ['organizationsDataService', function (organizationsDataService) {
                    return organizationsDataService.getAllActiveCompaniesAndCompanyGroups();
                }],
                location: ['locationsDataService', '$stateParams', function(locationsDataService, $stateParams) {
                    return locationsDataService.getLocationById($stateParams.id);
                }],
                equipments: ['equipmentsDataService', function (equipmentsDataService) {
                    return equipmentsDataService.getAllActiveEquipments();
                }]
            },
            data: {
                isEdit: true,
                roles: ['EducationAdmin']
            }
        })
        .state('locations.add', {
            url: '/add',
            templateUrl: 'app/manage/locations/editLocations.html',
            controller: 'editLocations',
            controllerAs: 'vm',
            requireADLogin: true,
            resolve: {
                organizations: ['organizationsDataService', function (organizationsDataService) {
                    return organizationsDataService.getAllActiveCompaniesAndCompanyGroups();
                }],
                location: ['locationsDataService', '$stateParams', function (locationsDataService, $stateParams) {
                    return {};
                }],
                equipments: ['equipmentsDataService', function (equipmentsDataService) {
                    return equipmentsDataService.getAllActiveEquipments();
                }]
            },
            data: {
                isEdit: false,
                roles: ['EducationAdmin']
            }
        });
    }
})();
