(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.genericActivity')
        .controller('GenericSecondStep', genericSecondStep);

    genericSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', 'activitiesDataService', 'exceptionHelper', '$stateParams'];

    function genericSecondStep(logger, activitiesWizardDataService, $state, $scope, activitiesDataService, exceptionHelper, $stateParams) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.next = next;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;
        
        function next() {
            $state.go($scope.navigation[2].state);
        }       

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
