(function () {
    'use strict';

    angular
        .module('app.certificates')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('templates', {
                parent: 'base',
                url: '/manage/templates',
                templateUrl: 'app/manage/templates/templates.html',
                controller: 'Templates',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                },
                resolve: {
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getTemplates();
                    }]
                }
            })
            .state('templates.edit', {
                url: '/:templateId/edit',
                templateUrl: 'app/manage/templates/edit/editTemplate.html',
                controller: 'editTemplate',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin'],
                    isEdit: true
                },
                resolve: {
                    template: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateById($stateParams.templateId);
                    }]
                }
            })
            .state('templates.add', {
                url: '/add',
                templateUrl: 'app/manage/templates/edit/editTemplate.html',
                controller: 'editTemplate',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin'],
                    idEdit: false
                },
                resolve: {
                    template: ['templatesDataService', function (templatesDataService) {
                        return {};
                    }]
                }
            })
            .state('templates.delete', {
                url: '/delete/:templateId',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/templates/delete/deleteTemplate.html',
                        controller: 'deleteTemplate',
                        controllerAs: 'vm',
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                },
                params: {
                    template: undefined
                }
            });
    }
})();