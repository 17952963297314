(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('locationTypeFilter', locationType);

    locationType.$inject = ['logger', 'locationType', 'i18nFilter'];

    function locationType(logger, locationType, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value === locationType.classroom) {
                return i18nFilter('translations.location_gui_classroom');
            }
            else if (value === locationType.virtual) {
                return i18nFilter('translations.location_gui_virtual');
            }
        };
    }
})();