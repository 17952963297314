(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('EditEducationSchedulePack', editEducationSchedulePack);

    editEducationSchedulePack.$inject = ['programSchedulePackHelper', 'dateFilter','exceptionHelper', '$state', 'logger', 'i18nFilter', '$stateParams', 'priceCategories', 'currencies', 'locations', 'instructors', 'education', 'schedulePack', 'schedulePacksDataService'];

    function editEducationSchedulePack(programSchedulePackHelper, dateFilter,exceptionHelper, $state, logger, i18nFilter, $stateParams, priceCategories, currencies, locations, instructors, education, schedulePack, schedulePacksDataService) {
        /* jshint validthis:true */
        var vm = this;
        programSchedulePackHelper.clear();
        vm.item = schedulePack;
        vm.isEdit = $state.current.data.isEdit;
        vm.programSchedulePackHelper = programSchedulePackHelper;
        //#region Locale messages
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        //#endregion

        vm.formData = {
            isSchedulePackInvalid: true,
            isSchedulePackFormInvalid: true,
            minDate: undefined,
            maxDate: undefined,
            educationId: $stateParams.educationId, 
            categories: priceCategories,
            currencies: currencies,
            instructors: instructors,
            locations: locations,
            education: education
        };

        //#region Method binding   
        vm.saveChanges = saveChanges;
        vm.add = add;
        vm.checkIfPackFormIsValid = checkIfPackFormIsValid;
        vm.checkIfSchedulesAreValid = checkIfSchedulesAreValid;
        vm.getSchedulePackDuration = getSchedulePackDuration;
        //#endregion

        function getSchedulePackDuration() {
            var from = dateFilter(vm.item.startTime, 'shortDate');
            var to = dateFilter(vm.item.endTime, 'shortDate');
            if (from === to) {
                return from;
            } else {
                return from + ' - ' + to;
            }
        }

        function add() {
            var allFormsAreValid = checkIfSchedulesAreValid() && checkIfPackFormIsValid() && checkIfThereIsValidBudget();
            if (!allFormsAreValid || !vm.programSchedulePackHelper.scheduleExpanded) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }

            var itemCopy = angular.copy(vm.item);

            itemCopy.schedules.forEach(function (schedule) {
                if (schedule.instructors != null) {
                    schedule.instructors.forEach(function (scheduleInstructor) {
                        scheduleInstructor.role = scheduleInstructor.role.id;
                        scheduleInstructor.extraCost = parseFloat(scheduleInstructor.extraCost.toString().replace(',', '.'));
                        scheduleInstructor.travelCost = parseFloat(scheduleInstructor.travelCost.toString().replace(',', '.'));
                    });
                }
            });

            if (itemCopy.price) 
                itemCopy.price.amount = itemCopy.price.amount.toString().replace(',', '.');
            
            if (itemCopy.groupPrice) 
                itemCopy.groupPrice.amount = itemCopy.groupPrice.amount.toString().replace(',', '.');
            
            schedulePacksDataService.addSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_operation_add_success_message'));
                if (data.instructorOverlap)
                    logger.success(i18nFilter('translations.schedule_packs_gui_operation_overlapping_instructor_message'));
                $state.go("^", {}, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function saveChanges() {
            var allFormsAreValid = checkIfSchedulesAreValid() && checkIfPackFormIsValid() && checkIfThereIsValidBudget();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }

            var itemCopy = angular.copy(vm.item);

            itemCopy.schedules.forEach(function (schedule) {
                if (schedule.instructors != null) {
                    schedule.instructors.forEach(function (scheduleInstructor) {
                        scheduleInstructor.role = scheduleInstructor.role.id;
                        scheduleInstructor.extraCost = parseFloat(scheduleInstructor.extraCost.toString().replace(',', '.'));
                        scheduleInstructor.travelCost = parseFloat(scheduleInstructor.travelCost.toString().replace(',', '.'));
                    });
                }
            });

            if (itemCopy.price) {
                itemCopy.price.amount = itemCopy.price.amount.toString().replace(',', '.');
            }

            if (itemCopy.groupPrice) {
                itemCopy.groupPrice.amount = itemCopy.groupPrice.amount.toString().replace(',', '.');
            }
            schedulePacksDataService.editSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_operation_edit_success_message'));
                if (data.instructorOverlap)
                    logger.success(i18nFilter('translations.schedule_packs_gui_operation_overlapping_instructor_message'));
                $state.go("^", {}, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function checkIfSchedulesAreValid() {
            var allFormsAreValid = true;
            _.each(programSchedulePackHelper.getApisForKey('schedule-' + vm.formData.education.id), function (api) {
                var formIsValid = api.checkIfFormIsValid();
                if (!formIsValid) {
                    allFormsAreValid = false;
                }
            });
            return allFormsAreValid;
        }

        function checkIfPackFormIsValid() {
            var allFormsAreValid = true;
            _.each(programSchedulePackHelper.getApisForKey('pack'), function (api) {
                var formIsValid = api.checkIfFormIsValid();
                if (!formIsValid) {
                    allFormsAreValid = false;
                }
            });
            return allFormsAreValid;
        }

        function checkIfThereIsValidBudget() {
            var allFormsAreValid = true;

            _.each(programSchedulePackHelper.getApisForKey('pie-' + vm.formData.education.id), function (api) {
                var formIsValid = api.checkIfThereIsValidBudget();
                if (!formIsValid) {
                    allFormsAreValid = false;
                }
            });

            return allFormsAreValid;
        }

        
    }
})();
