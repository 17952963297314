(function () {
    'use strict';

    angular
        .module('app.analytics')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('analytics', {
                url: '/analytics',
                parent: 'base',
                templateUrl: 'app/analytics/analytics.html',
                controller: 'analytics',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['AnalyticsAdmin']
                },
                resolve: {
                   
                }
            });
    }
})();
