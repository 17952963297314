(function () {
    'use strict';

    angular
        .module('app.countries')
        .controller('editCountry', editCountryCtrl);

    editCountryCtrl.$inject = ['logger', 'country', 'countriesDataService', '$state', 'i18nFilter', 'exceptionHelper', '$sanitize'];

    function editCountryCtrl(logger, country, countriesDataService, $state, i18nFilter, exceptionHelper, $sanitize) {
        
        var vm = this;
        vm.item = country;
        vm.isEdit = $state.current.data.isEdit;
        vm.save = save;
        vm.add = add;


        function add() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            countriesDataService.addCountry(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.country_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            countriesDataService.editCountry(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.country_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

    }
})();
