(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('httpInterceptorHelper', httpInterceptorHelper);

    httpInterceptorHelper.$inject = ['jsonHelper', '$q'];

    function httpInterceptorHelper(jsonHelper, $q) {
        var service = {
            response: response,
            responseError: responseError            
        };

        return service;

        //Success
        function response(res) {            
            if (res.headers()['content-type'] === "application/json") {
                if (res.data) {
                    res.data = jsonHelper.convertKeysToCamelCase(res.data);
                }
            }
            return res;
        }

        //Fail
        function responseError(res) {
            res = jsonHelper.convertKeysToCamelCase(res);
            return $q.reject(res);
        }
    }
})();