(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('catalogsDataService', activities);

    activities.$inject = ['$http', 'logger', '$q', 'dateHelper'];

    function activities($http, logger, $q, dateHelper) {
        var service = {
            getEducations: getEducations,
            getSchoolings: getSchoolings,
            getPrograms: getPrograms,
            getSurveys: getSurveys,
            getExams: getExams,
            getProficiencyExams: getProficiencyExams
        };

        return service;

        function getEducations(filter) {
            return $http.post('api/catalogs/educations', filter).then(function (data) {
                angular.forEach(data.data.educations, function (education, index) {
                    education.learningPlanItemDeadline = dateHelper.convertFromStringToDate(education.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolings(filter) {
            return $http.post('api/catalogs/schoolings', filter).then(function (data) {
                angular.forEach(data.data.schoolings, function (schooling, index) {
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getPrograms(filter) {
            return $http.post('api/catalogs/programs', filter).then(function (data) {
                angular.forEach(data.data.educations, function (education, index) {
                    education.learningPlanItemDeadline = dateHelper.convertFromStringToDate(education.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSurveys(filter) {
            return $http.post('api/catalogs/surveys', filter).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExams(filter) {
            return $http.post('api/catalogs/exams', filter).then(function (data) {
                angular.forEach(data.data.educations, function (education, index) {
                    education.learningPlanItemDeadline = dateHelper.convertFromStringToDate(education.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProficiencyExams(filter) {
            return $http.post('api/catalogs/proficiencyExams', filter).then(function (data) {
                angular.forEach(data.data.educations, function (education, index) {
                    education.learningPlanItemDeadline = dateHelper.convertFromStringToDate(education.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
