(function () {
    'use strict';
    
    angular
        .module('app.dataservice')
        .factory('reportsDataService', reports);



    reports.$inject = ['$http', 'logger', '$q'];

    function reports($http, logger, $q) {
        var service = {
            getAllReports: getAllReports,
            getReportById: getReportById            
        };

        return service;

        function getAllReports() {
            return $http.get('api/performaReports/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getReportById(reportId) {
            return $http.get('api/performaReports/active/' + reportId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }                
    }

})();
