(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('DeleteCompetence', deleteCompetence);

    deleteCompetence.$inject = ['logger', 'competence', 'competenceDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteCompetence(logger, competence, competenceDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.item = competence;

        function ok() {
            competenceDataService.deleteCompetence(vm.item.id).then(function (data) {
                logger.success(i18nFilter("translations.competence_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
