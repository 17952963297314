(function () {
    'use strict';

    angular
        .module('app.educations.administration.learnerPoints')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.learnerPoints', {
                url: '/learners',
                templateUrl: 'app/educations/administration/learnerPoints/learners.html',
                controller: 'LearnerPoints',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {                    
                    periods: ['periodsDataService', function (periodsDataService) {
                        return periodsDataService.getAllLearningPeriods();
                    }]
                }
            });
    }
})();
