(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .component('scalarQuestion', {
            templateUrl: 'app/surveys/questionGroups/questions/components/scalarQuestion/scalarQuestion.html',
            controller: scalarQuestion,
            controllerAs: 'vm',
            bindings: {
                question: '=',
                isEdit: '='
            }
        });

    scalarQuestion.$inject = ['surveyQuestionHelper', '$scope', 'guidGeneratorDataService'];

    function scalarQuestion(surveyQuestionHelper, $scope, guidGeneratorDataService) {
        var vm = this;

        vm.newMin = undefined;
        vm.newMax = undefined;

        vm.isMinMaxValid = true;

        vm.api = {
            id: vm.question.id,
            checkIfFormIsValid: checkIfFormIsValid,
            getData: getData
        };

        vm.checkIfFormIsValid = checkIfFormIsValid;

        surveyQuestionHelper.clear();
        surveyQuestionHelper.registerDirective(vm.question.id, vm.api);

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective(vm.question.id, vm.api.id);
        });

        if (vm.isEdit) {
            vm.newMin = vm.question.subQuestions[0].possibleAnswers[0].text;
            vm.newMax = vm.question.subQuestions[0].possibleAnswers[vm.question.subQuestions[0].possibleAnswers.length - 1].text;
        }

        function checkIfFormIsValid() {
            var isValid = true;
            if (!vm.newMin || !vm.newMax) {
                if (!vm.newMin) {
                    $scope.scalarFrom.min.$touched = true;
                } else if (!vm.newMax) {
                    $scope.scalarFrom.max.$touched = true;
                } else {
                    $scope.scalarFrom.min.$touched = true;
                    $scope.scalarFrom.max.$touched = true;
                }

                isValid = false;
            } else if (vm.newMin || vm.newMax) {
                isValid = vm.newMin < vm.newMax;
                vm.isMinMaxValid = isValid;
            }
            return isValid;
        }

        function getData() {
            var numbersBeetween = vm.newMax - vm.newMin -1;
            vm.answers = [];
            vm.answers.push({ id: guidGeneratorDataService.getGuid(), text: vm.newMin });

            for (var i = 1; i <= numbersBeetween; i++) {
                vm.answers.push({ id: guidGeneratorDataService.getGuid(), text: Number(vm.newMin) + i });
            }

            vm.answers.push({ id: guidGeneratorDataService.getGuid(), text: vm.newMax });            

            return vm.answers;
        }
    }
})();
