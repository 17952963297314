(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('acceptPersonFilter', acceptPerson);

    acceptPerson.$inject = ['logger', 'acceptPersons', 'i18nFilter'];

    function acceptPerson(logger, acceptPersons, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if(value === acceptPersons.learner){
                return i18nFilter('translations.filter_role_learner');
            }
            else if(value === acceptPersons.educationOwner){
                return i18nFilter('translations.educations_gui_education_owner');
            }
        };
    }
})();