(function () {
    'use strict';

    angular
        .module('app.renewSession')
        .controller('renewSessionModal', renewSessionModal);

    renewSessionModal.$inject = ['$location', '$scope'];

    function renewSessionModal($location, $scope) {
        /* jshint validthis:true */
        var modalvm = this;

        modalvm.doSessionRenew = doSessionRenew;
        modalvm.cancel = cancel;

        function doSessionRenew() {
            $scope.$close(null);
        }

        function cancel() {
            $scope.$dismiss();
        }

    }
})();
