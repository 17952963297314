(function () {
    'use strict';

    angular
        .module('app')
        .controller('details', detailsCtrl);

    detailsCtrl.$inject = ['logger', 'details', '$scope'];

    function detailsCtrl(logger, details, $scope) {
        /* jshint validthis:true */
        var vm = this;
        vm.details = details;
        vm.cancel = cancel;

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
