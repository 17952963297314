(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('exceptionLevel', attempsFilter);

    attempsFilter.$inject = ['logger', 'exceptionsLevels', 'i18nFilter'];

    function attempsFilter(logger, exceptionsLevels, i18nFilter) {
        return function (input) {
            var value = Number(input)
            switch (value) {
                case exceptionsLevels.trace:
                    return i18nFilter('translations.log_level_trace');
                case exceptionsLevels.debug:
                    return i18nFilter('translations.log_level_debug');
                case exceptionsLevels.information:
                    return i18nFilter('translations.log_level_info');
                case exceptionsLevels.warning:
                    return i18nFilter('translations.log_level_warning');
                case exceptionsLevels.error:
                    return i18nFilter('translations.log_level_error');
                case exceptionsLevels.critical:
                    return i18nFilter('translations.log_level_critical');
                case exceptionsLevels.none:
                    return '-';
                default:
                    return input;
            }
        };
    }
})();