(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('confirmSendMailToLearners', confirmSendMailToLearners);

    confirmSendMailToLearners.$inject = ['logger', 'i18nFilter', 'schedulePackId', '$scope', 'exceptionHelper', 'schedulePacksDataService'];

    function confirmSendMailToLearners(logger, i18nFilter, schedulePackId, $scope, exceptionHelper, schedulePacksDataService) {
        var vm = this;        

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            schedulePacksDataService.sendMailToLearners(schedulePackId).then(function (data) {
                logger.success(i18nFilter('translations.schedule_presence_send_mail_success_message'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
