(function () {
    'use strict';
    
    angular
        .module('app.exams.catalogues')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('catalogues', {
                parent: 'base',
                url: '/manage/catalogues',
                templateUrl: 'app/manage/catalogues/catalogues.html',
                controller: 'catalogues',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    catalogues: ['cataloguesDataService', '$stateParams', function (cataloguesDataService, $stateParams) {
                        return cataloguesDataService.getAllCatalogues();
                    }]
                },
                data: {
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.add', {
                url: '/add',
                templateUrl: 'app/manage/catalogues/editCatalogue/editCatalogue.html',
                controller: 'EditCatalogue',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    catalogue: ['cataloguesDataService', '$stateParams', function (cataloguesDataService, $stateParams) {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.edit', {
                url: '/edit/:catalogueId',
                templateUrl: 'app/manage/catalogues/editCatalogue/editCatalogue.html',
                controller: 'EditCatalogue',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    catalogue: ['cataloguesDataService', '$stateParams', function (cataloguesDataService, $stateParams) {
                        return cataloguesDataService.getCatalogueById($stateParams.catalogueId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.delete', {
                url: '/delete/:catalogueId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/catalogues/deleteCatalogue/deleteCatalogue.html',
                        controller: 'deleteCatalogue',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('catalogues');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('catalogues');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.questions', {
                url: '/:catalogueId/questions',
                templateUrl: 'app/manage/catalogues/questions/questions.html',
                controller: 'questions',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    catalogue: ['cataloguesDataService', '$stateParams', function (cataloguesDataService, $stateParams) {
                        return cataloguesDataService.getCatalogueById($stateParams.catalogueId);
                    }],
                    questions: ['questionsDataService', '$stateParams', function (questionsDataService, $stateParams) {
                        return questionsDataService.getQuestionsByCatalogueId($stateParams.catalogueId);
                    }]
                },
                data: {
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.questions.add', {
                url: '/add',
                templateUrl: 'app/manage/catalogues/questions/editQuestion/editQuestion.html',
                controller: 'editQuestion',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    question: ['questionsDataService', '$stateParams', function (questionsDataService, $stateParams) {
                        return {
                            catalogueId: $stateParams.catalogueId
                        };
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.questions.edit', {
                url: '/edit/:questionId',
                templateUrl: 'app/manage/catalogues/questions/editQuestion/editQuestion.html',
                controller: 'editQuestion',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    question: ['questionsDataService', '$stateParams', function (questionsDataService, $stateParams) {
                        return questionsDataService.getQuestionById($stateParams.questionId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner', 'EducationAdmin', 'EducationOwner']
                }
            })
            .state('catalogues.questions.delete', {
                url: '/delete/:questionId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/catalogues/questions/deleteQuestion/deleteQuestion.html',
                        controller: 'deleteQuestion',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('catalogues.questions');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('catalogues.questions');
                    });
                }],
                params: {
                    definition: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['GlobalAdmin', 'ExamAdmin', 'ExamOwner','EducationAdmin', 'EducationOwner']
                }
            });
    }    
})();
