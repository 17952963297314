(function () {
  "use strict";

  angular
    .module("app.catalogs")
    .controller("ClassicCatalogProficiencyExam", ClassicCatalogProficiencyExam);

  ClassicCatalogProficiencyExam.$inject = [
    "logger",
    "categories",
    "targetGroups",
    "catalogsDataService",
    "exceptionHelper",
    "i18nFilter",
    "rolesDataService",
    "$scope",
    "contentTypeTags",
    "myEntitiesCatalogFilter",
    "userIsProficiencyExamCoordinator",
  ];

  function ClassicCatalogProficiencyExam(
    logger,
    categories,
    targetGroups,
    catalogsDataService,
    exceptionHelper,
    i18nFilter,
    rolesDataService,
    $scope,
    contentTypeTags,
    myEntitiesCatalogFilter,
    userIsProficiencyExamCoordinator
  ) {
    var vm = this;

    vm.onCategoryChanged = onCategoryChanged;
    vm.onStatusChanged = onStatusChanged;

    vm.refresh = refresh;
    vm.clearSearchQuery = clearSearchQuery;
    vm.sortClick = sortClick;
    vm.modeClick = modeClick;
    vm.toggleFilters = toggleFilters;
    vm.onMyEntitiesChanged = onMyEntitiesChanged;
    vm.onTargetGroupChanged = onTargetGroupChanged;

    vm.formData = {
      userIsProficiencyExamCoordinator: userIsProficiencyExamCoordinator,
      showStatusFilter: rolesDataService.isUserInRoles(["CatalogAdmin"]),
      showAddProficiencyExam: rolesDataService.isUserInRoles([
        "CatalogAdmin",
        "ProficiencyExamAdmin",
      ]),
      loading: false,
      proficiencyExams: [],
      totalCount: 0,
      filter: {
        showDeactevated: false,
        sortOrder: undefined,
        searchQuery: undefined,
        categories: undefined,
        myEntities: undefined,
        page: 1,
        itemsPerPage: 8,
        tags: [
          {
            id: contentTypeTags.educationProficiencyExam,
            name: undefined,
          },
        ],
        targetGroups: undefined,
      },
      isFiltersShowing: false,

      sortOrders: [
        {
          text: i18nFilter(
            "translations.catalog_gui_filter_sort_options_alphabetical_asc"
          ),
          value: "name asc",
        },
        {
          text: i18nFilter(
            "translations.catalog_gui_filter_sort_options_alphabetical_desc"
          ),
          value: "name desc",
        },
        {
          text: i18nFilter(
            "translations.catalog_gui_filter_sort_options_rating_asc"
          ),
          value: "rating asc",
        },
        {
          text: i18nFilter(
            "translations.catalog_gui_filter_sort_options_rating_desc"
          ),
          value: "rating desc",
        },
      ],

      showingModes: [
        {
          text: i18nFilter("translations.catalog_gui_view_option_icons"),
          value: "tiles",
          icon: "fa-th",
        },
        {
          text: i18nFilter("translations.catalog_gui_view_option_list"),
          value: "list",
          icon: "fa-list",
        },
      ],

      categories: categories,
      targetGroups: targetGroups,
    };

    setInitialState();

    function setInitialState() {
      vm.formData.filter.sortOrder = vm.formData.sortOrders[0];
      vm.formData.filter.showingMode = vm.formData.showingModes[0];
      vm.formData.filter.myEntities = myEntitiesCatalogFilter.all;
      refresh();
    }

    function refresh() {
      vm.formData.loading = true;
      catalogsDataService.getProficiencyExams(vm.formData.filter).then(
        function (data) {
          vm.formData.proficiencyExams = data.proficiencyExams;
          vm.formData.totalCount = data.totalCount;
          vm.formData.loading = false;
        },
        function (error) {
          vm.formData.loading = false;
          exceptionHelper.handleException(error);
        }
      );
    }

    function clearSearchQuery() {
      vm.formData.filter.searchQuery = "";
      refresh();
    }

    function onCategoryChanged(categories) {
      vm.formData.filter.categories = categories;
      logger.log("On category changed: ", vm.formData.filter);

      refresh();
    }

    function onStatusChanged(value) {
      vm.formData.filter.showDeactevated = value;
      refresh();
    }

    function sortClick(sortItem) {
      vm.formData.filter.sortOrder = sortItem;
      vm.refresh();
    }

    function onMyEntitiesChanged(value) {
      vm.formData.filter.myEntities = value;
      logger.log("On my entities changed: ", vm.formData.filter);

      refresh();
    }

    function onTargetGroupChanged(targetGroups) {
      vm.formData.filter.targetGroups = targetGroups;
      logger.log("On target group changed: ", vm.formData.filter);

      refresh();
    }

    function modeClick(modeItem) {
      vm.formData.filter.showingMode = modeItem;
      vm.refresh();
    }

    function toggleFilters() {
      $(".filters").toggleClass("hide-filters");

      if (!vm.formData.isFiltersShowing) {
        vm.formData.isFiltersShowing = true;
      } else {
        setTimeout(function () {
          vm.formData.isFiltersShowing = false;
          $scope.$apply();
        }, 300);
      }
    }
  }
})();
