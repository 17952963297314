(function () {
    'use strict';

    angular
        .module('app.surveys.administration.delete')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys.administration.delete', {
                url: '/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/administration/delete/delete.html',
                        controller: 'DeleteSurvey',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('home');
                        });
                }],
                params: {
                    surveyName: undefined,
                    surveyId: undefined
                },
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin']
                }
            });
    }
})();
