(function () {
    'use strict';

    angular
        .module('app.analytics')
        .component('schedulesCalendar', {
            templateUrl: 'app/analytics/components/schedulesCalendar.component.html',
            controller: schedulesCalendar,
            controllerAs: 'vm',
            bindings: {

            }
        });

    schedulesCalendar.$inject = ['$state', '$scope', 'i18nFilter', 'dateFilter', 'analyticsDataService'];

    function schedulesCalendar($state, $scope, i18nFilter, dateFilter, analyticsDataService) {
        var vm = this;

        vm.openAppointment = openAppointment;
        vm.formatDates = formatDates;

        var dataSource = {
            load: function (loadOptions) {
                let currentView = vm.calendarInstance.option().currentView;
                var d = new $.Deferred();
                analyticsDataService.getSchedulesCalendar(loadOptions.dxScheduler.startDate, loadOptions.dxScheduler.endDate, currentView).then(function (data) {
                    d.resolve(data, { totalCount: data.length });
                }, function (error) {
                    logger.error(i18nFilter('translations.contract_error_message_fetching_contracts'));
                    d.reject();
                });

                return d.promise();
            }
        };


        vm.schedulerOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            views: [
                {
                    type: "day"
                },
                {
                    type: "workWeek"
                },
                {
                    type: "month",
                    maxAppointmentsPerCell: 4
                }
            ],
            currentView: "month",
            currentDate: new Date(),
            editing: false,
            allowAdding: false,
            allowDeleting: false,
            allowDragging: false,
            allowResizing: false,
            allowUpdating: false,
            showAllDayPanel: false,
            appointmentTemplate: appointmentTemplate,
            appointmentTooltipTemplate: 'tooltip-template',
            startDayHour: 7,
            endDayHour: 22,
            cellDuration: 15,
            crossScrollingEnabled: false,
            height: 800,
            onInitialized: function (e) {
                vm.calendarInstance = e.component;
            },
            onAppointmentFormCreated: function (e) {
                var form = e.form;

                form.option('items', [
                    {
                        label: {
                            text: 'Dogodek',
                        },
                        editorType: 'dxTextBox',
                        dataField: 'text'
                    },
                    {
                        label: {
                            text: 'Začetni datum',
                        },
                        editorOptions: {
                            type: 'datetime'
                        },
                        editorType: 'dxDateBox',
                        dataField: 'startDate'
                    },
                    {
                        label: {
                            text: 'Končni datum',
                        },
                        editorOptions: {
                            type: 'datetime'
                        },
                        editorType: 'dxDateBox',
                        dataField: 'endDate'
                    },
                    {
                        label: {
                            text: 'Izvajalci',
                        },
                        editorType: 'dxTextArea',
                        dataField: 'instructors'
                    },
                    {
                        label: {
                            text: 'Koordinatorji',
                        },
                        editorType: 'dxTextArea',
                        dataField: 'owners'
                    },
                    {
                        label: {
                            text: 'Prostor',
                        },
                        editorType: 'dxTextBox',
                        dataField: 'location'
                    },
                    {
                        label: {
                            text: 'Št. prijav',
                        },
                        editorOptions: {
                            width: '25%'
                        },
                        editorType: 'dxTextBox',
                        dataField: 'numberOFEnrollments'
                    }
                ]);                
            }
        };


        function appointmentTemplate(model) {
            let view = this.option().currentView;

            if (view == "day") {
                return $(`<div class='showtime-preview'>
                     <div class='dx-scheduler-appointment-title'><strong>${model.text}</strong></div>
                     <div class='dx-scheduler-appointment-content-details' style='white-space: normal'>
                     <div>${DevExpress.localization.date.format(new Date(model.startDate), 'shortTime')}-${DevExpress.localization.date.format(new Date(model.endDate), 'shortTime')}</div>
                     <div>${model.location}</div>
                     <div>Koordinator: ${model.owners}</div>
                     <div>Izvajalci: ${model.instructors}</div>
                     <div>Št. prijav: ${model.numberOFEnrollments}</div>
                    </div>
                     </div>`);
            }
            else if (view == "workWeek") {
                return $(`<div class='showtime-preview'>
                     <div> <strong> ${ model.text } </strong> </div>
                     <div class='dx-scheduler-appointment-content-details'> ${DevExpress.localization.date.format(new Date(model.startDate), 'shortTime')}-${DevExpress.localization.date.format(new Date(model.endDate), 'shortTime')}</div>
                     </div>`);
            }
            else {
                return $(`<div class='showtime-preview'><div>${model.text}</div>`);
            }
        }

        function openAppointment(data) {
            vm.calendarInstance.showAppointmentPopup(data, false);
            vm.calendarInstance.hideAppointmentTooltip()
        }

        function formatDates(startDate, endDate) {

            var startDate = new Date(startDate),
                endDate = new Date(endDate);

            var startDateString = DevExpress.localization.date.format(startDate, "mediumdatemediumtime") + " - ";
            var endDateString = startDate.getDate() === endDate.getDate() ? DevExpress.localization.date.format(endDate, "shorttime") : DevExpress.localization.date.format(endDate, "mediumdatemediumtime");

            return startDateString + endDateString;
        }
      
    }
})();
