(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('editQuestion', editQuestion);

    editQuestion.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'examCatalogueQuestionType', 'question', 'questionsDataService', '$uibModal', 'exceptionHelper', 'logger', '$scope', '$sce', '$sanitize'];

    function editQuestion(decimalHelper, dateHelper, $state, i18nFilter, examCatalogueQuestionType, question, questionsDataService, $uibModal, exceptionHelper, logger, $scope, $sce, $sanitize) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = question;
        vm.isEdit = $state.current.data.isEdit;
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        }
        
        vm.formData = {
            examCatalogueQuestionTypes: examCatalogueQuestionType,
            examCatalogueQuestionType: [
                {
                    id: 0,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_essay')
                },
                {
                    id: 1,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_fillIn')
                },
                {
                    id: 2,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_dropdown')
                },
                {
                    id: 3,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_multipleChoice')
                },
                {
                    id: 4,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_singleChoice')
                },
                {
                    id: 5,
                    format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_connect')
                }
            ]
        }

        vm.addFillInAnswer = addFillInAnswer;
        vm.addDropdownAnswer = addDropdownAnswer;
        vm.addMultipleChoicePossibleAnswer = addMultipleChoicePossibleAnswer;
        vm.addMultipleChoiceCorrectAnswer = addMultipleChoiceCorrectAnswer;
        vm.addSingleChoiceAnswer = addSingleChoiceAnswer;
        vm.addConnectAnswer = addConnectAnswer;
        vm.removeFillInAnswer = removeFillInAnswer;
        vm.removeDropdownAnswer = removeDropdownAnswer;
        vm.removeMultipleChoicePossibleAnswer = removeMultipleChoicePossibleAnswer;
        vm.removeMultipleChoiceCorrectAnswer = removeMultipleChoiceCorrectAnswer;
        vm.removeSingleChoiceAnswer = removeSingleChoiceAnswer;
        vm.removeConnectAnswer = removeConnectAnswer;
        vm.add = add;
        vm.save = save;
        vm.embedCodeChanged = embedCodeChanged;

        vm.questionDefinition = {
            fillIn: {
                CorrectAnswers: [{id: guid(), value: ""}]
            },
            dropdown: {
                Choices: [{id: guid(), value: ""}],
                CorrectAnswer: ""
            },
            multipleChoice: {
                Choices: [{id: guid(), value: ""}],
                CorrectAnswers: [{id: guid(), value: ""}]
            },
            singleChoice: {
                Choices: [{id: guid(), value: ""}],
                CorrectAnswer: ""
            },
            connect: {
                LeftChoices: [{id: guid(), value: ""}],
                RightChoices: [{id: guid(), value: ""}]
            }
        }

        if (vm.isEdit) {
            vm.thisCanBeusedInsideNgBindHtml = $sce.trustAsHtml(vm.item.embedCodeHtmlString);

            for (var index in vm.formData.examCatalogueQuestionType) {
                if (vm.item.type == index) {
                    vm.item.type = vm.formData.examCatalogueQuestionType[index];
                    break;
                }
            }

            if(vm.item.type.id == examCatalogueQuestionType.essay) {
                vm.item.name = JSON.parse(vm.item.definition).Name;
            } else if(vm.item.type.id == examCatalogueQuestionType.fillIn) {
                vm.item.name = JSON.parse(vm.item.definition).Name;
                vm.questionDefinition.fillIn.CorrectAnswers = [];
                var list = vm.item.correctAnswer.split(",");
                for (var index in list) {
                    vm.questionDefinition.fillIn.CorrectAnswers.push({id: guid(), value: list[index]});
                }
            } else if(vm.item.type.id == examCatalogueQuestionType.dropdown) {
                var definition = JSON.parse(vm.item.definition);
                vm.item.name = definition.Name;
                vm.questionDefinition.dropdown.Choices = [];
                var list = definition.Choices;
                for (var index in list) {
                    vm.questionDefinition.dropdown.Choices.push({id: guid(), value: list[index]});
                }
                vm.questionDefinition.dropdown.CorrectAnswer = vm.item.correctAnswer;
            } else if(vm.item.type.id == examCatalogueQuestionType.multipleChoice) {
                var definition = JSON.parse(vm.item.definition);
                vm.item.name = definition.Name;
                vm.questionDefinition.multipleChoice.Choices = [];
                var list = definition.Choices;
                for (var index in list) {
                    vm.questionDefinition.multipleChoice.Choices.push({id: guid(), value: list[index]});
                }
                vm.questionDefinition.multipleChoice.CorrectAnswers = [];
                var list = vm.item.correctAnswer.split("|");
                for (var index in list) {
                    vm.questionDefinition.multipleChoice.CorrectAnswers.push({id: guid(), value: list[index]});
                }
            } else if(vm.item.type.id == examCatalogueQuestionType.singleChoice) {
                var definition = JSON.parse(vm.item.definition);
                vm.item.name = definition.Name;
                vm.questionDefinition.singleChoice.Choices = [];
                var list = definition.Choices;
                for (var index in list) {
                    vm.questionDefinition.singleChoice.Choices.push({id: guid(), value: list[index]});
                }
                vm.questionDefinition.singleChoice.CorrectAnswer = vm.item.correctAnswer;
            } else if(vm.item.type.id == examCatalogueQuestionType.connect) {
                var definition = JSON.parse(vm.item.definition);
                vm.item.name = definition.Name;
                vm.questionDefinition.connect.LeftChoices = [];
                var list = definition.LeftChoices;
                for (var index in list) {
                    vm.questionDefinition.connect.LeftChoices.push({id: guid(), value: list[index]});
                }
                vm.questionDefinition.connect.RightChoices = [];
                var list = definition.RightChoices;
                for (var index in list) {
                    vm.questionDefinition.connect.RightChoices.push({id: guid(), value: list[index]});
                }
            }
        }

        function guid() {
            return "ss-s-s-s-sss".replace(/s/g, s4);
        }
        
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        function addFillInAnswer() {
            vm.questionDefinition.fillIn.CorrectAnswers.push({id: guid(), value: ""});
        }
        function addDropdownAnswer() {
            vm.questionDefinition.dropdown.Choices.push({id: guid(), value: ""});
        }
        function addMultipleChoicePossibleAnswer() {
            vm.questionDefinition.multipleChoice.Choices.push({id: guid(), value: ""});
        }
        function addMultipleChoiceCorrectAnswer() {
            vm.questionDefinition.multipleChoice.CorrectAnswers.push({id: guid(), value: ""});
        }
        function addSingleChoiceAnswer() {
            vm.questionDefinition.singleChoice.Choices.push({id: guid(), value: ""});
        }
        function addConnectAnswer() {
            vm.questionDefinition.connect.LeftChoices.push({id: guid(), value: ""});
            vm.questionDefinition.connect.RightChoices.push({id: guid(), value: ""});
            $scope.questionForm.points.$validate();
        }

        function removeFillInAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.fillIn.CorrectAnswers.splice(index, 1);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }
        function removeDropdownAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.dropdown.Choices.splice(index, 1);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }
        function removeMultipleChoicePossibleAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.multipleChoice.Choices.splice(index, 1);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }
        function removeMultipleChoiceCorrectAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.multipleChoice.CorrectAnswers.splice(index, 1);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }
        function removeSingleChoiceAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.singleChoice.Choices.splice(index, 1);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }
        function removeConnectAnswer(index) {
            $uibModal.open({
                templateUrl: 'app/manage/catalogues/questions/removeQuestion/removeQuestion.html',
                controller: 'removeQuestion',
                controllerAs: 'vm'
            })
            .result.then(function () {
                // after clicking OK button
                vm.questionDefinition.connect.LeftChoices.splice(index, 1);
                vm.questionDefinition.connect.RightChoices.splice(index, 1);
                $scope.questionForm.points.$validate();
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }

        function transform() {
            if(vm.item.type.id == examCatalogueQuestionType.essay) {
                vm.item.definition = JSON.stringify({
                    "Name": vm.item.name
                });
            } else if(vm.item.type.id == examCatalogueQuestionType.fillIn) {
                vm.item.definition = JSON.stringify({
                    "Name": vm.item.name
                });
                vm.item.correctAnswer = [];
                vm.questionDefinition.fillIn.CorrectAnswers.forEach(function (CorrectAnswer) {
                    vm.item.correctAnswer.push(CorrectAnswer.value);
                });
                vm.item.correctAnswer = vm.item.correctAnswer.toString();
            } else if(vm.item.type.id == examCatalogueQuestionType.dropdown) {
                var obj = {
                    "Name": vm.item.name,
                    "Choices": []
                }
                vm.questionDefinition.dropdown.Choices.forEach(function (Choice) {
                    obj.Choices.push(Choice.value);
                });
                vm.item.definition = JSON.stringify(obj);
                vm.item.correctAnswer = vm.questionDefinition.dropdown.CorrectAnswer;
            } else if(vm.item.type.id == examCatalogueQuestionType.multipleChoice) {
                var obj = {
                    "Name": vm.item.name,
                    "Choices": []
                }
                vm.questionDefinition.multipleChoice.Choices.forEach(function (Choice) {
                    obj.Choices.push(Choice.value);
                });
                vm.item.definition = JSON.stringify(obj);
                var list = [];
                vm.questionDefinition.multipleChoice.CorrectAnswers.forEach(function (CorrectAnswer) {
                    list.push(CorrectAnswer.value.replace("|",""));
                });
                vm.item.correctAnswer = list.join("|");
            } else if(vm.item.type.id == examCatalogueQuestionType.singleChoice) {
                var obj = {
                    "Name": vm.item.name,
                    "Choices": []
                }
                vm.questionDefinition.singleChoice.Choices.forEach(function (Choice) {
                    obj.Choices.push(Choice.value);
                });
                vm.item.definition = JSON.stringify(obj);
                vm.item.correctAnswer = vm.questionDefinition.singleChoice.CorrectAnswer;
            } else if(vm.item.type.id == examCatalogueQuestionType.connect) {
                var obj = {
                    "Name": vm.item.name,
                    "LeftChoices": [],
                    "RightChoices": []
                }
                vm.questionDefinition.connect.LeftChoices.forEach(function (Choice) {
                    obj.LeftChoices.push(Choice.value);
                });
                vm.questionDefinition.connect.RightChoices.forEach(function (Choice) {
                    obj.RightChoices.push(Choice.value);
                });
                vm.item.definition = JSON.stringify(obj);
            }
        }

        function add() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.points = DOMPurify.sanitize(vm.item.points);
            vm.item.correctAnswerReviewFeedback = DOMPurify.sanitize(vm.item.correctAnswerReviewFeedback);
            vm.item.partiallyCorrectAnswerReviewFeedback = DOMPurify.sanitize(vm.item.partiallyCorrectAnswerReviewFeedback);
            vm.item.wrongAnswerReviewFeedback = DOMPurify.sanitize(vm.item.wrongAnswerReviewFeedback);

            transform();
            questionsDataService.addQuestion(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_questions_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {

            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.points = DOMPurify.sanitize(vm.item.points);
            vm.item.correctAnswerReviewFeedback = DOMPurify.sanitize(vm.item.correctAnswerReviewFeedback);
            vm.item.partiallyCorrectAnswerReviewFeedback = DOMPurify.sanitize(vm.item.partiallyCorrectAnswerReviewFeedback);
            vm.item.wrongAnswerReviewFeedback = DOMPurify.sanitize(vm.item.wrongAnswerReviewFeedback);

            transform();
            questionsDataService.editQuestion(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_questions_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function embedCodeChanged(e) {
            vm.thisCanBeusedInsideNgBindHtml = $sce.trustAsHtml(vm.item.embedCodeHtmlString);
        }
    }

    angular.module('app.exams.catalogues')
        .directive('pointsValidation', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attr, ngModel) {
                ngModel.$validators.pointsValidation = function (modelValue, viewValue) {
                    var vm = scope.vm;
                    var questionForm = scope.questionForm;
                    var value = modelValue || viewValue;

                    if (isNaN(value)) 
                        return false;

                    else if (vm.item.type.id != vm.formData.examCatalogueQuestionTypes.connect) {
                        return true;
                    }

                    
                    var leftChoices = vm.questionDefinition.connect.LeftChoices;
                    var rightChoices = vm.questionDefinition.connect.RightChoices;
                    value = parseInt(value);

                    if ((value / parseFloat(leftChoices.length)) % 1 != 0) {
                        return false;
                    }

                    return true;
                };
            }
        };
    });
})();
