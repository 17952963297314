(function () {
    'use strict';
    angular
        .module('blocks.controls')
        .directive('decimalNumber', decimalNumber);
    decimalNumber.$inject = ['$locale', 'logger'];
    function decimalNumber($locale, logger) {
        // Usage:
        //     <decimalNumber></decimalNumber>
        // Creates:
        // 
        var directive = {
            link: link,
            require: 'ngModel',
            restrict: 'A'
        };
        return directive;

        function link(scope, elm, attrs, ctrl) {
            /**
             * This function is added to the list of the $parsers.
             * It will be executed the DOM (the view value) change.
             * Array.unshift() put it in the beginning of the list, so
             * it will be executed before all the other
             */
			 
			ctrl.required = attrs.required == undefined ? false : true;

            ctrl.$parsers.unshift(function (viewValue) {
                var decimalStep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                var groupStep = $locale.NUMBER_FORMATS.GROUP_SEP;
                
                var regexStr = '^'
                    + '['
                    + '+'
                    + '-'
                    + ']'
                    + '?'                    
                    + '\\'
                    + 'd'
                    + '+'
                    + '('
                    + '\\'
                    + groupStep
                    + '\\'
                    + 'd'
                    + '{'
                    + '3'
                    + '}'
                    + ')'
                    + '*'                    
                    + '\\'
                    + decimalStep
                    + '?'
                    + '\\'
                    + 'd'
                    + '*'
                    + '$';
				
                logger.log("Custom decimal number validator called", regexStr, viewValue);
                var regex = new RegExp(regexStr);
								
				if(!ctrl.required && viewValue == ''){
					ctrl.$setValidity('decimal', true);
					return viewValue;
				}
				
                if (regex.test(viewValue)) {
                    ctrl.$setValidity('decimal', true);
                    return viewValue;
                }
                else {
                    ctrl.$setValidity('decimal', false);
                    return undefined;
                }                                
            });

            ctrl.$validate();           
            

        }
    }
})();
