(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('addCandidates', addCandidates);

    addCandidates.$inject = ['commissionMembersGroupedByTheme', 'examApplicationLearners', 'existingCandidates', 'existingCandidatesOnOtherCommissions', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'examSchedulesDataService', 'examScheduleId', 'activity', 'commission'];

    function addCandidates(commissionMembersGroupedByTheme, examApplicationLearners, existingCandidates, existingCandidatesOnOtherCommissions, logger, $stateParams, $scope, i18nFilter, exceptionHelper, examSchedulesDataService, examScheduleId, activity, commission) {
        var vm = this;
        vm.item = {};
        var candidatesVM = [], candidateIds = [];

        var activityId = activity.activityId;

        commissionMembersGroupedByTheme.forEach(function (themeCommissionMemberVM, index) {
            themeCommissionMemberVM.commissionMembersCopy = angular.copy(themeCommissionMemberVM.commissionMembers);
        });

        var isEdit = existingCandidates != null && existingCandidates.length > 0;
        if (isEdit) {            
            existingCandidates.forEach(function (existingCandidate) {
                candidateIds.push(existingCandidate.candidate.id);
            });

            // Get candidates applied themes
            let appliedThemesPromise = examSchedulesDataService.getCandidatesActivityAppliedThemes(examScheduleId, candidateIds, activityId).then(function (candidatesAppliedThemes) {
                // Adding candidates applied themes, if they are active, and they match the id given by the service (ExamLearnerAppliedThemesVM).
                candidatesAppliedThemes.forEach(function (candidateThemes) {

                    for (let i = 0; i < existingCandidates.length; i++) {
                        let existingCandidate = existingCandidates[i];
                        if (existingCandidate.candidate.id == candidateThemes.learnerId) {
                            // Adding applied themes for this candidate (an entry in the add candidate dropdown)
                            existingCandidate.themesCommissionMembers = [];

                            // Look for active commission members to process candidate column accordingly through theme assigning below
                            var foundActiveCommMemb = false;
                            if (commission.commissionMembers != null) {
                                commission.commissionMembers.forEach(function (commMemb) {
                                    if (commMemb.commissionMember.active) {
                                        foundActiveCommMemb = true;
                                    }
                                });
                            }

                            if (foundActiveCommMemb) {
                                // Go through all possible themes and applied themes for this candidate
                                for (let i = 0; i < commissionMembersGroupedByTheme.length; i++) {
                                    var foundTheme = false;
                                    var numThemes = candidateThemes.appliedThemes != null ? candidateThemes.appliedThemes.length : 0;
                                    for (let j = 0; j < numThemes; j++) {
                                        // Check if the theme is applied for this candidate (he enrolled for it)
                                        if (commissionMembersGroupedByTheme[i].theme.id == candidateThemes.appliedThemes[j].id) {
                                            // Add a valid entry for this theme
                                            let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                                            commMembThemeCopy.appliedTheme = true;
                                            existingCandidate.themesCommissionMembers.push(commMembThemeCopy);
                                            foundTheme = true;
                                            break;
                                        }
                                    }
                                    // Check if the theme was applied or not
                                    if (foundTheme == false && activity.themeIds.has(commissionMembersGroupedByTheme[i].theme.id)) {
                                        // Add an empty theme (if no comm member is chosen, the display will be an empty string)
                                        let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                                        commMembThemeCopy.commissionMembersCopy = [];
                                        commMembThemeCopy.appliedTheme = false;
                                        commMembThemeCopy.commissionMembers = [];
                                        existingCandidate.themesCommissionMembers.push(commMembThemeCopy);
                                    }
                                }
                            }
                            else { // add empty entries for display in grid
                                activity.themes.forEach(function (theme) {
                                    existingCandidate.themesCommissionMembers.push({ theme: theme, chosenCommissionMembers: "", commissionMembers: [] });
                                });
                            }

                            existingCandidate.themesCommissionMembers.sort((a, b) => a.theme.name.localeCompare(b.theme.name));

                            var candidateVM = {
                                'candidate': existingCandidate.candidate,
                                'appliedThemes': (candidateThemes.appliedThemes != undefined ? candidateThemes.appliedThemes : []),
                                'themesCommissionMembers': existingCandidate.themesCommissionMembers
                            };
                            candidatesVM[i] = candidateVM;

                            // Found and processed all the data for this candidate
                            break;
                        }
                    }
                });
            }, function (error) {
                exceptionHelper.handleException(error);
            });

            // Fulfilling promise
            appliedThemesPromise.finally(function () {

                vm.candidateIdsDict = {};
                var filteredCandidatesVM = candidatesVM.filter(function (candidateVM, index) {
                    vm.candidateIdsDict[candidateVM.candidate.id] = index;
                    return existingCandidatesOnOtherCommissions.indexOf(candidateVM.candidate.userId) === -1;
                });

                vm.availableToChoose = filteredCandidatesVM.filter(function (candidateVM, index) {
                    return !candidateVM.candidate.active && candidateVM.appliedThemes != null && candidateVM.appliedThemes.length > 0;
                });
                vm.item.candidatesVM = candidatesVM;
            });
        } else {
            examApplicationLearners.forEach(function (applicationLearner) {
                candidateIds.push(applicationLearner.id);
            });

            // Get candidates applied themes (in this case used only for one candidate at a time)
            let appliedThemesPromise = examSchedulesDataService.getCandidatesActivityAppliedThemes(examScheduleId, candidateIds, activityId).then(function (candidatesAppliedThemes) {
                // Adding candidates applied themes, if they are active, and they match the id given by the service (ExamLearnerAppliedThemesVM).
                candidatesAppliedThemes.forEach(function (candidateThemes) {
                    for (let i = 0; i < examApplicationLearners.length; i++) {
                        let applicationLearner = examApplicationLearners[i];
                        if (applicationLearner.id == candidateThemes.learnerId) {
                            // Adding applied themes for this candidate (an entry in the add candidate dropdown)
                            applicationLearner.themesCommissionMembers = [];

                            // Go through all possible themes and applied themes for this candidate
                            for (let i = 0; i < commissionMembersGroupedByTheme.length; i++) {
                                var foundTheme = false;
                                var numThemes = candidateThemes.appliedThemes != null ? candidateThemes.appliedThemes.length : 0;
                                for (let j = 0; j < numThemes; j++) {
                                    // Check if the theme is applied for this candidate (he enrolled for it)
                                    if (commissionMembersGroupedByTheme[i].theme.id == candidateThemes.appliedThemes[j].id) {
                                        // Add a valid entry for this theme
                                        let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                                        commMembThemeCopy.appliedTheme = true;
                                        applicationLearner.themesCommissionMembers.push(commMembThemeCopy);
                                        foundTheme = true;
                                        break;
                                    }
                                }
                                // Check if the theme was applied or not
                                if (foundTheme == false) {
                                    // Add an empty theme (if no comm member is chosen, the display will be an empty string)
                                    let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                                    commMembThemeCopy.commissionMembersCopy = [];
                                    commMembThemeCopy.commissionMembers = [];
                                    commMembThemeCopy.appliedTheme = false;
                                    applicationLearner.themesCommissionMembers.push(commMembThemeCopy);
                                }
                            }
                            if (commissionMembersGroupedByTheme.length===0) { // add empty entries for display in grid
                                activity.themes.forEach(function (theme) {
                                    applicationLearner.themesCommissionMembers.push({ theme: theme, chosenCommissionMembers: "", commissionMembers: [] });
                                });
                            }

                            applicationLearner.themesCommissionMembers.sort((a, b) => a.theme.name.localeCompare(b.theme.name));

                            var candidateVM = {
                                'candidate': applicationLearner,
                                'appliedThemes': (candidateThemes.appliedThemes != null ? candidateThemes.appliedThemes : []),
                                'themesCommissionMembers': applicationLearner.themesCommissionMembers
                            };
                            candidatesVM[i] = candidateVM;

                            // Found and processed all the data for this candidate
                            break;
                        }
                    }
                });
            }, function (error) {
                exceptionHelper.handleException(error);
            });

            // Fulfilling promise
            appliedThemesPromise.finally(function () {

                vm.candidateIdsDict = {};
                var filteredCandidatesVM = candidatesVM.filter(function (candidateVM, index) {
                    vm.candidateIdsDict[candidateVM.candidate.id] = index;
                    return existingCandidatesOnOtherCommissions.indexOf(candidateVM.candidate.userId) === -1;
                });
                
                vm.availableToChoose = filteredCandidatesVM.filter(function (candidateVM, index) {
                    return !candidateVM.candidate.active && candidateVM.appliedThemes != null && candidateVM.appliedThemes.length > 0;
                });
                vm.item.candidatesVM = candidatesVM;
            });
        }
        
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        vm.formData = {};

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            if (vm.choosenUser != null) {
                var index = vm.candidateIdsDict[vm.choosenUser.candidate.id];
                candidatesVM[index].candidate.active = true;
                activity.activeLearners.add(candidatesVM[index].candidate.id);
                $scope.$close(candidatesVM);
            } else {
                $scope.$dismiss();
            }
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
