(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .controller('ClassicCatalog', classicCatalog);

    classicCatalog.$inject = ['logger', '$sessionStorage', '$state', 'rolesDataService', 'tabs', 'applicationTabs', 'tenantResourceKey', 'newAdminPortalUrl'];

    function classicCatalog(logger, $sessionStorage, $state, rolesDataService, tabs, applicationTabs, tenantResourceKey, newAdminPortalUrl) {
        /* jshint validthis:true */
        var vm = this;
        vm.showEducations = tabs.filter(t => { return t.code == applicationTabs.catalogEducations })[0].showTab;
        vm.showExams = tabs.filter(t => { return t.code == applicationTabs.catalogExams })[0].showTab; 
        vm.showSchoolings = tabs.filter(t => { return t.code == applicationTabs.catalogSchoolings })[0].showTab; 
        vm.showPrograms = tabs.filter(t => { return t.code == applicationTabs.catalogPrograms })[0].showTab; 
        vm.showSurveys = rolesDataService.isUserInRoles(['SurveyAdmin', 'SurveyOwner']) && tabs.filter(t => { return t.code == applicationTabs.catalogSurveys })[0].showTab;
        vm.isActive = isActive;
        vm.showProficiencyExams = tabs.filter(t => { return t.code == applicationTabs.catalogProficiencyExams })[0].showTab; 

        vm.newAdminPortalUrl = newAdminPortalUrl[tenantResourceKey] + '/classicCatalog/proficiencyExam';
        vm.tenantResourceKey = tenantResourceKey;

        function isActive(stateName) {
            return $state.current.name === stateName;
        }
    }
})();
