(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackArrangementsDocuments', examSchedulePackArrangementsDocuments)
        .filter('contains', function () {
            return function (array, needle) {
                return array.indexOf(needle) >= 0;
            };
        });
    examSchedulePackArrangementsDocuments.$inject = ['$state', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', 'examSchedulesDataService', 'examSchedule', 'documentsDataService', 'activeTemplates', 'templatesDataService', '$stateParams'];

    function examSchedulePackArrangementsDocuments($state, $scope, i18nFilter, exceptionHelper, logger, examSchedulesDataService, examSchedule, documentsDataService, activeTemplates, templatesDataService, $stateParams) {
        var vm = this;
        vm.IsCommissionDocuments = $stateParams.hasOwnProperty("commissionId");
        vm.examSchedule = examSchedule;
        vm.generateSIC1Decision = generateSIC1Decision;
        vm.generatePDILetterToThePreparersOfAWrittenAssignment = generatePDILetterToThePreparersOfAWrittenAssignment;
        vm.generateSIC1Schedule = generateSIC1Schedule;
        vm.generatePdiScheduleForConsultations = generatePdiScheduleForConsultations;
        vm.generatePdiForwardingTasksCivil = generatePdiForwardingTasksCivil;
        vm.generateTravelExpenses = generateTravelExpenses;
        //vm.generatePDICheckingDiplomaLetter = generatePDICheckingDiplomaLetter;
        vm.generatePdiScheduleForExamSchedule = generatePdiScheduleForExamSchedule;
        vm.generateMJU12 = generateMJU12;
        vm.generateMJU15 = generateMJU15;
        vm.generateMJU16 = generateMJU16;
        vm.generateMJU16a = generateMJU16a;
        vm.generateMJU25 = generateMJU25;
        vm.formData = {
            documentsExpanded: true,
            //selectedSchedule: undefined,
            examSchedule: examSchedule,
            activeTemplates: activeTemplates
        }

        function generateSIC1Decision() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/sic1DecisionDocument/' + $stateParams.commissionId, i18nFilter('translations.exams_documents_label_for_sic1DecisionTemplate') + '.docx').then(function (data) {
                GenerateSIC1DecisionAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generatePDILetterToThePreparersOfAWrittenAssignment() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/pdiLetterToThePreparersOfAWrittenAssignmentDocument/'+$stateParams.commissionId, i18nFilter('translations.exams_documents_label_for_pdiLetterToThePreparersOfAWrittenAssignmentTemplate') + '.docx').then(function (data) {
                GeneratePDILetterToThePreparersOfAWrittenAssignmentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateSIC1Schedule() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/sic1ScheduleDocument', i18nFilter('translations.exams_documents_label_for_sic1ScheduleTemplate') + '.docx').then(function (data) {
                GenerateSic1ScheduleDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        } 
        function generatePdiScheduleForConsultations() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/pdiScheduleForConsultations', i18nFilter('translations.exams_documents_label_for_pdiScheduleForConsultationsTemplate') + '.docx').then(function (data) {
                GeneratePdiScheduleForConsultationsDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        } 
        function generatePdiForwardingTasksCivil() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/PdiForwardingTasksCivil', i18nFilter('translations.exams_documents_label_for_pdiForwardingTasksCivilTemplate') + '.docx').then(function (data) {
                GeneratePdiForwardingTasksCivilDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        } 
        function generateTravelExpenses() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/travelExpenses/' + $stateParams.activityId , i18nFilter('translations.exams_documents_label_for_travelExpensesTemplate') + '.docx').then(function (data) {
                GenerateTravelExpensesDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        } 
        /*function generatePDICheckingDiplomaLetter() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/pdiCheckingDiplomaLetter', i18nFilter('translations.exams_documents_label_for_pdiCheckingDiplomaLetterTemplate') + '.docx').then(function (data) {
                GeneratePDICheckingDiplomaLetterDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }*/
        function generatePdiScheduleForExamSchedule() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/pdiScheduleForExamSchedule', i18nFilter('translations.exams_documents_label_for_pdiScheduleForExamScheduleTemplate') + '.docx').then(function (data) {
                GeneratePdiScheduleForExamScheduleDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateMJU12() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/mju12' + (vm.IsCommissionDocuments ? ('/'+$stateParams.commissionId):''), i18nFilter('translations.exams_documents_label_for_MJU12Template') + '.docx').then(function (data) {
                GenerateMJU12DocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateMJU15() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/mju15', i18nFilter('translations.exams_documents_label_for_MJU15Template') + '.docx').then(function (data) {
                GenerateMJU15DocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateMJU16() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/mju16', i18nFilter('translations.exams_documents_label_for_MJU16Template') + '.docx').then(function (data) {
                GenerateMJU16DocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateMJU16a() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/mju16a', i18nFilter('translations.exams_documents_label_for_MJU16aTemplate') + '.docx').then(function (data) {
                GenerateMJU16aDocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
        function generateMJU25() {
            documentsDataService.downloadDocument('/api/examApplications/' + vm.examSchedule.id + '/mju25', i18nFilter('translations.exams_documents_label_for_MJU25Template') + '.docx').then(function (data) {
                GenerateMJU25DocumentAsync
            }, function (error) {
                error = {
                    exception: {
                        additionalData: {},
                        result: { exceptionCode: -90007, operationCode: 0 }
                    },
                    status: 400
                };
                exceptionHelper.handleException(error);
            });
        }
    }
})();
