(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('reverse', reverse);

    reverse.$inject = ['logger'];

    function reverse(logger) {
        return function (items) {
            return items.slice().reverse();
        };
    }
})();