(function () {
    'use strict';

    angular
        .module('app')
        .controller('educationsHtml5SecondStep', secondStepcontroller);

    secondStepcontroller.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', 'activitiesDataService', 'exceptionHelper', '$stateParams', 'i18nFilter', 'status'];

    function secondStepcontroller(logger, activitiesWizardDataService, $state, $scope, activitiesDataService, exceptionHelper, $stateParams, i18nFilter, status) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.next = next;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;
        vm.status = status;
        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '100',
            acceptedFiles: '.zip',
            addRemoveLinks: true
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);                
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.item.fileId = id;
            },
            'removedfile': function (file) {
                vm.item.fileId = undefined;
            }
        };

        vm.dzMethods = {};

        function next() {
            $state.go($scope.navigation[2].state);
        }       

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
