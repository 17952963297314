(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.edit', {
                url: '/edit',
                templateUrl: 'app/educations/administration/edit/baseStep/edit.html',
                controller: 'EditAdministration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    education: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return educationsDataService.getEditEducation($stateParams.id);
                    }]
                }
            })

            .state('educations.administration.edit.firstStep', {
                url: '/about',
                templateUrl: 'app/educations/administration/edit/firstStep/firstStep.html',
                controller: 'EditEducationFistStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {                    
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('educations.administration.edit.secondStep', {
                url: '/options',
                templateUrl: 'app/educations/administration/edit/secondStep/secondStep.html',
                controller: 'EditEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    prerequisiteEducations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getAllActiveEducations();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }],
                }
            })

            .state('educations.administration.edit.thirdStep', {
                url: '/points',
                templateUrl: 'app/educations/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditEducationThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    activities: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                        return activitiesDataService.getAllActiveEducationActivitiesSummaryForEducation($stateParams.id);
                    }]
                }
            })

            .state('educations.administration.edit.fourthStep', {
                url: '/prices',
                templateUrl: 'app/educations/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditEducationFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]            
                }
            })

            .state('educations.administration.edit.fifthStep', {
                url: '/plans',
                templateUrl: 'app/educations/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditEducationFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                }
            })

            .state('educations.administration.edit.sixthStep', {
                url: '/eForms',
                templateUrl: 'app/educations/administration/edit/sixthStep/sixthStep.html',
                controller: 'EditEducationSixthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveEducationFieldNames();
                    }]
                }
            })

            .state('addEducation', {
                url: '/addEducation',
                parent: 'base',
                templateUrl: 'app/educations/administration/edit/baseStep/edit.html',
                controller: 'EditAdministration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    education: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                        return {};
                    }],
                }
            })

            .state('addEducation.firstStep', {
                url: '/about',
                templateUrl: 'app/educations/administration/edit/firstStep/firstStep.html',
                controller: 'EditEducationFistStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {                   
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('addEducation.secondStep', {
                url: '/options',
                templateUrl: 'app/educations/administration/edit/secondStep/secondStep.html',
                controller: 'EditEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    prerequisiteEducations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getAllActiveEducations();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }],
                }
            })
            .state('addEducation.thirdStep', {
                url: '/points',
                templateUrl: 'app/educations/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditEducationThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    activities: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                        return activitiesDataService.getAllActiveEducationActivitiesSummaryForEducation($stateParams.id);
                    }]
                }
            })
            .state('addEducation.fourthStep', {
                url: '/prices',
                templateUrl: 'app/educations/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditEducationFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]            
                }
            })
            .state('addEducation.sixthStep', {
                url: '/eForms',
                templateUrl: 'app/educations/administration/edit/sixthStep/sixthStep.html',
                controller: 'EditEducationSixthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveEducationFieldNames();
                    }]
                }
            })
            .state('addEducation.fifthStep', {
                url: '/plans',
                templateUrl: 'app/educations/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditEducationFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                }
            })
            ;
    }
})();
