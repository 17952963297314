(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('editCandidates', editCandidates);

    editCandidates.$inject = ['commissionMembersGroupedByTheme', 'chosenCandidate', 'existingCandidates', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'examScheduleId', 'activityId', 'examSchedulesDataService'];

    function editCandidates(commissionMembersGroupedByTheme, chosenCandidate, existingCandidates, logger, $stateParams, $scope, i18nFilter, exceptionHelper, examScheduleId, activityId, examSchedulesDataService) {
        var vm = this;
        vm.item = {};
        var candidateVM;

        commissionMembersGroupedByTheme.forEach(function (themeCommissionMemberVM, index) {
            if (themeCommissionMemberVM.commissionMembersCopy == null) {
                themeCommissionMemberVM.commissionMembersCopy = angular.copy(themeCommissionMemberVM.commissionMembers);
            }
        });
        
        vm.item.candidatesVM = existingCandidates;

        vm.candidateIdsDict = {};
        vm.choosenUser = vm.item.candidatesVM.filter(function (candidateVM, index) {
            vm.candidateIdsDict[candidateVM.candidate.id] = index;
            return candidateVM.candidate.id == chosenCandidate.id;
        });
        vm.choosenUser = vm.choosenUser[0];
        

        // Get candidates applied themes
        examSchedulesDataService.getCandidatesActivityAppliedThemes(examScheduleId, [vm.choosenUser.candidate.id], activityId).then(function (candidatesAppliedThemes) {
            // Adding candidates applied themes, if they are active, and they match the id given by the service (ExamLearnerAppliedThemesVM).
            
            vm.choosenUser.themesCommissionMembers = [];
            
            // Go through all possible themes and applied themes for this candidate
            for (let i = 0; i < commissionMembersGroupedByTheme.length; i++) {
                var foundTheme = false;
                var numThemes = candidatesAppliedThemes[0].appliedThemes != null ? candidatesAppliedThemes[0].appliedThemes.length : 0;
                for (let j = 0; j < numThemes; j++) {
                    // Check if the theme is applied for this candidate (he enrolled for it)
                    if (commissionMembersGroupedByTheme[i].theme.id == candidatesAppliedThemes[0].appliedThemes[j].id) {
                        // Add a valid entry for this theme
                        let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                        commMembThemeCopy.appliedTheme = true;
                        vm.choosenUser.themesCommissionMembers.push(commMembThemeCopy);
                        foundTheme = true;
                        break;
                    }
                }
                // Check if the theme was applied or not
                if (foundTheme == false) {
                    // Add an empty theme (if no comm member is chosen, the display will be an empty string)
                    let commMembThemeCopy = angular.copy(commissionMembersGroupedByTheme[i]);
                    commMembThemeCopy.commissionMembersCopy = [];
                    commMembThemeCopy.appliedTheme = false;
                    commMembThemeCopy.commissionMembers = [];
                    vm.choosenUser.themesCommissionMembers.push(commMembThemeCopy);
                }
            }

            vm.choosenUser.themesCommissionMembers.sort((a, b) => a.theme.name.localeCompare(b.theme.name));

            // result object
            candidateVM = {
                'candidate': vm.choosenUser.candidate,
                'appliedThemes': (candidatesAppliedThemes[0].appliedThemes != undefined ? candidatesAppliedThemes[0].appliedThemes : []),
                'themesCommissionMembers': vm.choosenUser.themesCommissionMembers
            };

            vm.choosenUser = candidateVM;

        }, function (error) {
            exceptionHelper.handleException(error);
        });

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        vm.formData = {};

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(vm.item.candidatesVM);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
