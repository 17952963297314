(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('trim', trim);

    trim.$inject = ['logger'];

    function trim(logger) {
        return function (input) {
            var out = '';
            
            if (input.length < 21) {
                out = input;
            }
            else {
                out = input.substring(0, 20) + '...';
            }
            return out;
        };
    }

})();