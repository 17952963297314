(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('notifications', notifications);

    notifications.$inject = ['$state'];

    function notifications($state) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'notifications';      
        vm.isActive = function (stateName) {
            var currentStateName = $state.current.name;
            return currentStateName.indexOf(stateName) !== -1;
        };
    }
})();
