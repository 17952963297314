(function () {
    'use strict';

    angular
        .module('app.tilesConfiguration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('tilesConfiguration', {
                parent: 'base',
                url: '/manage/tilesConfiguration',
                templateUrl: 'app/manage/tilesConfiguration/tilesConfiguration.html',
                controller: 'TilesConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    tiles: ['tilesConfigurationDataService', '$stateParams', function (tilesConfigurationDataService, $stateParams) {
                        return tilesConfigurationDataService.getTilesConfiguration();
                    }]
                },
                data: {
                    roles: ['EducationAdmin', 'GlobalAdmin']
                }
            });
    }
})();
