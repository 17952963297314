(function () {
    'use strict';

    angular
        .module('app.organizations')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('organizations', {
            parent: 'base',
            url: '/manage/organizations',
            templateUrl: 'app/manage/organizations/organizations.html',
            controller: 'Organizations',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['EducationAdmin']
            },
            resolve: {
                organizations: ['organizationsDataService', function (organizationsDataService) {
                    return organizationsDataService.getAllOrganizations();
                }]
            }
            })
            .state('organizations.details', {                
                url: '/manage/organizations/:id',
                templateUrl: 'app/manage/organizations/details/details.html',
                controller: 'OrganizationDetails',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                },
                resolve: {
                    organization: ['organizationsDataService', '$stateParams', function (organizationsDataService, $stateParams) {
                        return organizationsDataService.getOrganizationById($stateParams.id);
                    }]
                }
            })
            .state('organizations.import', {
                url: '/import',
                templateUrl: 'app/manage/organizations/importOrganizations.html',
                controller: 'importOrganizations',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            });
    }
})();
