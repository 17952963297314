(function () {
  "use strict";

  angular.module("app.analytics").component("enrolmentAnalytics", {
    templateUrl:
      "app/analytics/components/enrolmentAnalytics/enrolmentAnalytics.component.html",
    controller: enrolmentAnalytics,
    controllerAs: "vm",
  });

  enrolmentAnalytics.$inject = [
    "$location",
    "logger",
    "i18nFilter",
    "dateFilter",
    "exceptionHelper",
    "analyticsDataService",
  ];

  function enrolmentAnalytics(
    $location,
    logger,
    i18nFilter,
    dateFilter,
    exceptionHelper,
    analyticsDataService
  ) {
    /* jshint validthis:true */
    var vm = this;

    vm.formData = {
      years: [],
      successfulEnrolments: undefined,
      unsuccessfulEnrolments: undefined,
    };

    vm.selectedYear = undefined;
    vm.enrolmentYearData = undefined;

    vm.localeMessages = {
      selectPlaceholder: i18nFilter(
        "translations.general_gui_placeholder_select"
      ),
    };

    vm.showData = false;

    vm.refreshTable = refreshTable;

    analyticsDataService.getEnrolmentYears().then(
      function (data) {
        vm.formData.years = data;
      },
      function (error) {
        exceptionHelper.handleException(error);
        vm.formData.years.push(0);
      }
    );

    function refreshTable() {
      if (vm.selectedYear) {
        analyticsDataService.getEnrolmentYearData(vm.selectedYear).then(
          function (data) {
            vm.enrolmentYearData = data;
            if (vm.enrolmentYearData) {
              vm.formData.successfulEnrolments =
                vm.enrolmentYearData.successfulEnrolments;
              vm.formData.unsuccessfulEnrolments =
                vm.enrolmentYearData.unsuccessfulEnrolments;
              vm.showData = true;
            }
          },
          function (error) {
            exceptionHelper.handleException(error);
            vm.enrolmentYearData = undefined;
          }
        );
      }
    }
  }
})();
