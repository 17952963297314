(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('applicationRoles', applicationRoles);

    applicationRoles.$inject = ['logger', 'i18nFilter'];
    
    function applicationRoles(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }

            var value = Number(input);
             
            switch (value) {
                case 1:
                    return i18nFilter('translations.filter_role_global_admin');
                case 2:
                    return i18nFilter('translations.filter_role_tenant_admin');
                case 3:
                    return i18nFilter('translations.filter_role_education_admin');
                case 4:
                    return i18nFilter('translations.filter_role_catalog_admin');
                case 5:
                    return i18nFilter('translations.filter_role_assignment_admin');
                case 6:
                    return i18nFilter('translations.filter_role_knowledge_base_admin');
                case 7:
                    return i18nFilter('translations.filter_role_education_owner');
                case 8:
                    return i18nFilter('translations.filter_role_instructor');
                case 9:
                    return i18nFilter('translations.filter_role_learner');
                case 10:
                    return i18nFilter('translations.filter_role_program_owner');
                case 11:
                    return i18nFilter('translations.filter_role_manager');
                case 12:
                    return i18nFilter('translations.filter_role_survey_admin');
                case 13:
                    return i18nFilter('translations.filter_role_survey_owner');
                case 14:
                    return i18nFilter('translations.filter_role_exam_admin');
                case 15:
                    return i18nFilter('translations.filter_role_exam_owner');
                case 20:
                    return i18nFilter('translations.filter_role_proficiency_exam_admin');
                case 21:
                    return i18nFilter('translations.filter_role_proficiency_exam_owner');
            }
        };
    }
})();
