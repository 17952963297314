(function () {
    'use strict';

    angular
        .module('app.countries')
        .controller('deleteCountry', deleteCountry);

    deleteCountry.$inject = ['logger', '$stateParams', 'countriesDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteCountry(logger, $stateParams, countriesDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            countriesDataService.deleteCountry($stateParams.id).then(function (data) {
                logger.success(i18nFilter("translations.country_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
