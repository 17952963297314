(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.survey')
        .controller('SurveySecondStep', surveySecondStep);

    surveySecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', '$stateParams', 'dateHelper', 'i18nFilter', 'surveys'];

    function surveySecondStep(logger, activitiesWizardDataService, $state, $scope, $stateParams, dateHelper, i18nFilter, surveys) {
        var vm = this;

        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.next = next;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")            
        };

        vm.formData = {            
            surveys: surveys
        }

        function next() {
            $state.go($scope.navigation[2].state);
        }

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
