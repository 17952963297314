(function () {
    'use strict';

    angular
        .module('app.exams.home')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('exams.home', {
            url: '/home',
            templateUrl: 'app/exams/home/home.html',
            controller: 'ExamsHome',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'ExamAdmin', 'ExamOwner']
            }
        });
    }
})();
