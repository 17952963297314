(function () {
    'use strict';

    angular
        .module('app')
        .controller('SchoolingDocumentsFileSystem', schoolingDocumentsFileSystem);

    schoolingDocumentsFileSystem.$inject = ['logger', 'documentsDataService', 'exceptionHelper', '$stateParams', '$state', 'dmsPermission', 'i18nFilter', '$uibModal'];

    function schoolingDocumentsFileSystem(logger, documentsDataService, exceptionHelper, $stateParams, $state, dmsPermission, i18nFilter, $uibModal) {
        var vm = this;
        vm.forSchedule = $state.current.data.forSchedule;

        vm.formData = {
            loading: false,
            dmsPermission: dmsPermission,

            files: [],
            currentItem: {
                type: 'dir',
                path: documentsDataService.getSchoolingDocumentsRelativePath($stateParams.id),
                displayPath: documentsDataService.getSchoolingDocumentsRelativePath($stateParams.id)
            },
            selectedItem: undefined,
            currentPathParts: [],

            newFolderName: undefined,
            newFileId: undefined,

            truncateOptions: {
                watch: 'window',
                wrap: 'letter'
            }
        };

        if (vm.forSchedule) {
            documentsDataService.getSchoolingScheduleDocumentsRelativePath($stateParams.id, $stateParams.scheduleId)
                .then(function (data) {
                    vm.formData.currentItem.path = data;
                    vm.formData.currentItem.displayPath = data;

                    parsePath();
                    getData();
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        } else {
            parsePath();
            getData();
        }

        //#region File upload drag & drop
        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 10,
            maxFilesize: '10',
            acceptedFiles: '.txt, .png, .bmp, .pdf, .csv, .json, .zip, .url, .xml, .jpg, .gif, .ppsx, .pps, .pptx, .ppt, .doc, .dot, .dotx, .docx, .docm, .odt, .xlsx, .xlsm, .xlsb, .xlsx, .ods, .xlsx, .odp, .pptm',
            addRemoveLinks: false,
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                var id = xhr;
                vm.formData.newFileId = id;
                uploadFile(file);

            },
            'removedfile': function (file) {
                vm.formData.newFileId = undefined;
            },
            'error': function (file, details) {
                vm.dzMethods.removeFile(file);
                if (details.indexOf('File is too big') !== -1) {
                    logger.error(i18nFilter('translations.schooling_gui_documents_upload_max_size_error'));
                }
            }
        };
        vm.dzMethods = {};
        //#endregion

        vm.getData = getData;
        vm.open = open;
        vm.openFromPath = openFromPath;
        vm.renameSelectedItem = renameSelectedItem;
        vm.deleteSelectedItem = deleteSelectedItem;
        vm.newFolder = newFolder;
        vm.addLink = addLink;
        vm.editLink = editLink;
        vm.openInNewTab = openInNewTab;
        vm.showUploadFile = showUploadFile;
        vm.isShowUploadFile = false;

        function showUploadFile() {
            if (vm.isShowUploadFile) {
                vm.isShowUploadFile = !vm.isShowUploadFile;
            } else {
                vm.isShowUploadFile = !vm.isShowUploadFile;
            }
        }

        function getData() {
            vm.formData.loading = true;
            documentsDataService.getDocuments(vm.formData.currentItem.path).then(function (data) {
                vm.formData.loading = false;
                vm.formData.files = data.files;
                vm.formData.currentItem = data.currentFolder;


                parsePath();
            }, function (error) {
                vm.formData.loading = false;
                exceptionHelper.handleException(error);
            });
        }

        function open(file) {
            if (file.type === "dir") {
                openDirectory(file);
            }
            else if (file.type === "file") {
                downloadFile(file);
            }
            else if (file.type === "link") {
                openInNewTab(file);
            }
        }

        function openFromPath(path) {
            var dir = {
                type: 'dir',
                path: path
            };
            open(dir);
        }

        function renameSelectedItem(item) {
            $uibModal.open({
                templateUrl: 'app/schoolings/documents/fileSystem/rename/rename.html',
                controller: 'SchoolingDocumentsFileSystemRename',
                controllerAs: 'vm',
                resolve: {
                    oldName: function () {
                        return item.name;
                    },
                    path: function () {
                        return item.path;
                    }
                }
            })
                .result.then(function () {
                    // change route after clicking OK button
                    getData();
                }, function () {
                    // change route after clicking Cancel button or clicking background
                });
        }

        function deleteSelectedItem(item) {
            $uibModal.open({
                templateUrl: 'app/schoolings/documents/fileSystem/delete/delete.html',
                controller: 'SchoolingDocumentsFileSystemDelete',
                controllerAs: 'vm',
                resolve: {
                    name: function () {
                        return item.name;
                    },
                    path: function () {
                        return item.path;
                    }
                }
            })
                .result.then(function () {
                    // change route after clicking OK button
                    getData();
                }, function () {
                    // change route after clicking Cancel button or clicking background
                });
        }

        function newFolder() {
            $uibModal.open({
                templateUrl: 'app/schoolings/documents/fileSystem/newFolder/newFolder.html',
                controller: 'SchoolingDocumentsFileSystemNewFolder',
                controllerAs: 'vm',
                resolve: {
                    path: function () {
                        return vm.formData.currentItem.path;
                    }
                }
            })
                .result.then(function () {
                    // change route after clicking OK button
                    getData();
                }, function () {
                    // change route after clicking Cancel button or clicking background
                });
        }

        function addLink() {
            $uibModal.open({
                templateUrl: 'app/schoolings/documents/fileSystem/addLink/addLink.html',
                controller: 'SchoolingDocumentsFileSystemAddLink',
                controllerAs: 'vm',
                resolve: {
                    path: function () {
                        return vm.formData.currentItem.path;
                    }
                }
            })
                .result.then(function () {
                    // change route after clicking OK button
                    getData();
                }, function () {
                    // change route after clicking Cancel button or clicking background
                });
        }

        function editLink(item) {
            $uibModal.open({
                templateUrl: 'app/schoolings/documents/fileSystem/editLink/editLink.html',
                controller: 'SchoolingDocumentsFileSystemEditLink',
                controllerAs: 'vm',
                resolve: {
                    path: function () {
                        return vm.formData.currentItem.path;
                    },
                    item: function () {
                        return item;
                    }
                }
            })
                .result.then(function () {
                    // change route after clicking OK button
                    getData();
                }, function () {
                    // change route after clicking Cancel button or clicking background
                });
        }

        function openInNewTab(file) {
            documentsDataService.openInNewTab(file.linkUrl);
        }

        function downloadFile(file) {
            documentsDataService.downloadFile(file.path);
        }

        function openDirectory(file) {
            vm.formData.currentItem = file;
            getData();
        }

        //#region Utils
        function parsePath() {
            if (!vm.formData.currentItem.displayPath) {
                //TODO: copy orginal path
            }

            var displayPathArray = vm.formData.currentItem.displayPath.split("\\");
            var orginalPathArray = vm.formData.currentItem.path.split("\\");

            vm.formData.currentPathParts = [];
            angular.forEach(displayPathArray, function (element, index) {
                var wrapper = {
                    name: element,
                    compleatePath: ''
                };

                for (var i = 0; i <= index; i++) {
                    wrapper.compleatePath += orginalPathArray[i];
                    if (i !== index) {
                        wrapper.compleatePath += '/';
                    }
                }
                vm.formData.currentPathParts.push(wrapper);
            });


            vm.formData.currentPathParts[0].name = '<span class="fa fa-home fa-lg"></span>';
            vm.formData.currentPathParts[0].compleatePath = vm.formData.currentPathParts[2].compleatePath;
            vm.formData.currentPathParts.splice(1, 2);
        }
        //#endregion

        function uploadFile(fileFromControl) {
            if (!vm.formData.newFileId) {
                return;
            }

            documentsDataService.uploadFile(vm.formData.currentItem.path, vm.formData.newFileId).then(function (data) {
                getData();

                clearNewFiles(fileFromControl);
                vm.isShowUploadFile = false;
            }, function (error) {
                clearNewFiles(fileFromControl);
                exceptionHelper.handleException(error);
            });
        }

        function clearNewFiles(fileFromControl) {
            vm.formData.newFileId = undefined;
            vm.dzMethods.removeFile(fileFromControl);
        }

    }
})();
