(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities')
        .controller('ActivitiesAdministration', activities);

    activities.$inject = ['logger', 'activities', 'activitiesDataService', '$stateParams', '$state', 'exceptionHelper', 'rolesDataService', 'i18nFilter', 'activityTypes', 'status'];

    function activities(logger, activities, activitiesDataService, $stateParams, $state, exceptionHelper, rolesDataService, i18nFilter, activityTypes, status) {
        var vm = this;

        vm.activities = activities;

        vm.localeMessagess = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down'),
            schedulesTooltip: i18nFilter('translations.schedule_gui_manage_tooltip'),
            deactivateActivity: i18nFilter('translations.general_action_deactivate_activity')
        };

        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.findLowestOrderNo = findLowestOrderNo;
        vm.findHighestOrderNo = findHighestOrderNo;
        vm.status = status;

        vm.formData = {
            activityTypes: activityTypes
        };


        
        //#region table
        var columns = [
            {
                dataField: 'orderNo',
                caption: i18nFilter('translations.general_gui_order'),
                alignment: 'left',
                width: 100,
                sortOrder: 'asc',
                allowSorting: false
            },
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowSorting: false
            },
            {
                dataField: 'educationActivityId',
                caption: i18nFilter('translations.general_gui_type'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'educationActivityTypeTemplate'
            },
            {
                dataField: 'educationActivityId',
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'activitiesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: activities,
            onRowPrepared: onRowPrepared,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        function onRowPrepared(e) {
            if (e.rowType !== "data")
                return;

            if (e.data.status == 0)
                e.rowElement[0].classList.add("greyBackground") ;
        }

        function moveUp(id) {
            activitiesDataService.moveUp(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_up'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function moveDown(id) {
            activitiesDataService.moveDown(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_down'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function findLowestOrderNo() {
            var lowest = vm.activities[0].orderNo;
            angular.forEach(vm.activities, function (e, index) {
                if (e.orderNo < lowest) {
                    lowest = e.orderNo;
                }
            });
            return lowest;
        }

        function findHighestOrderNo() {
            var highest = vm.activities[0].orderNo;
            angular.forEach(vm.activities, function (e, index) {
                if (e.orderNo > highest) {
                    highest = e.orderNo;
                }
            });
            return highest;
        }

       
    }
})();
