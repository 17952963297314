(function () {
    'use strict';

    angular
        .module('app.programs.documents')
        .controller('ProgramDocumentsFileSystemAddLink', addLink);

    addLink.$inject = ['logger', 'programDocumentsDataService', '$scope', 'path', 'exceptionHelper'];

    function addLink(logger, programDocumentsDataService, $scope, path, exceptionHelper) {
        var vm = this;

        vm.cancel = cancel;
        vm.ok = ok;
        vm.name = undefined;
        vm.url = undefined;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            programDocumentsDataService.addLink(path, vm.url, vm.name).then(function (data) {
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();



