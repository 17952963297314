(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .controller('QuestionGroups', questionGroups);

    questionGroups.$inject = ['logger', 'questionGroups', '$stateParams', 'rolesDataService'];

    function questionGroups(logger, questionGroups, $stateParams, rolesDataService) {
        var vm = this;
        vm.questionGroups = questionGroups;
        
        vm.formData = {
            isUserAdmin: rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationOwner']),
            showAddQuestionGroup: rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationOwner'])
        };
    }
})();
