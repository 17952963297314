(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('editSpeaker', editSpeaker);

    editSpeaker.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'schoolingSchedulePacksDataService', 'SchoolingActivityScheduleInstructorRoles', 'speaker','paidInstructorRoles'];

    function editSpeaker(logger, $stateParams, $scope, i18nFilter, exceptionHelper, schoolingSchedulePacksDataService, SchoolingActivityScheduleInstructorRoles, speaker, paidInstructorRoles) {
        var vm = this;
                       
        vm.speaker = speaker;

        vm.speakers = [];
        vm.roles = translateCollections(SchoolingActivityScheduleInstructorRoles);

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
        };        

        vm.refreshSpeakers = refreshSpeakers;

        vm.ok = ok;
        vm.cancel = cancel;

        function refreshSpeakers(query) {
            if (query === undefined || query === "") {
                return;
            }
            schoolingSchedulePacksDataService.getSchoolingScheduleProgramModerators($stateParams.schoolingScheduleId, query).then(function (data) {
                vm.speakers = data;
            }, function (error) {
                logger.error(i18nFilter("translations.schoolingSchedule_gui_program_exception_message_fetching_speakers"));
            });
        }

        function translateCollections(data) {           
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });

            //filter roles to paid roles only
            let paidRoles = data.filter(function (row) {
                return paidInstructorRoles.includes(row.id);
            });

            return paidRoles;
        }

        function ok() {
            $scope.$close(vm.speaker);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
