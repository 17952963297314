(function () {
    'use strict';

    angular
        .module('app.educations.documents')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider            
            .state('educations.FSDocuments', {
                url: '/documents/fs',
                templateUrl: 'app/educations/documents/fileSystem/documents.html',
                controller: 'EducationDocumentsFileSystem',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    documentsLibrary: true,
                    forSchedule: false
                }
            })
            .state('educations.scheduleFSDocuments', {
                url: '/schedules/:scheduleId/documents/fs',
                templateUrl: 'app/educations/documents/fileSystem/documents.html',
                controller: 'EducationDocumentsFileSystem',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    documentsLibrary: true,
                    forSchedule: true
                }
            });
    }
})();
