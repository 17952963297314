(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackResults', examSchedulePackResults);
    
    examSchedulePackResults.$inject = ['examResult', 'commissionMembersGroupedByThemeOnTheExam', 'recorders', 'chairmen', 'distinctCommissionMembers', 'examSchedulesDataService', 'examApplicationStatus', 'activityType', 'gradeDescriptionType', 'absenceType', '$scope', '$state', '$stateParams', '$uibModal', '$location', 'i18nFilter', 'dateHelper', 'dateFilter', 'exceptionHelper', 'logger', 'documentsDataService'];

    function examSchedulePackResults(examResult, commissionMembersGroupedByThemeOnTheExam, recorders, chairmen, distinctCommissionMembers, examSchedulesDataService, examApplicationStatus, activityType, gradeDescriptionType, absenceType, $scope, $state, $stateParams, $uibModal, $location, i18nFilter, dateHelper, dateFilter, exceptionHelper, logger, documentsDataService) {
        /* jshint validthis:true */
        var vm = this;

        vm.formData = {
            examApplicationStatus: examApplicationStatus,
            activityType: activityType,
            activityTypes: [],
            gradeDescriptionTypes: [],
            activityGradeDescriptionTypes: [],
            absenceTypes: [],
            recorders: recorders,
            chairmen: chairmen,
            distinctCommissionMembers: angular.copy(distinctCommissionMembers)
        };

        for (var [type, id] of Object.entries(activityType)) {
            vm.formData.activityTypes.push({
                'id': id,
                'format': i18nFilter('translations.exams_gui_label_activity_type_' + type)
            });
        }

        for (var [type, id] of Object.entries(gradeDescriptionType)) {
            vm.formData.gradeDescriptionTypes.push({
                'id': id,
                'format': i18nFilter('translations.exam_results_gui_label_grade_description_type_' + type)
            });

            if (id < 3) {
                vm.formData.activityGradeDescriptionTypes.push({
                    'id': id,
                    'format': i18nFilter('translations.exam_results_gui_label_grade_description_type_' + type)
                });
            }
        }

        for (var [type, id] of Object.entries(absenceType)) {
            vm.formData.absenceTypes.push({
                'id': id,
                'format': i18nFilter('translations.exam_results_gui_label_absence_type_' + type)
            });
        }

        vm.item = examResult;

        var preparers = [];
        var evaluators = [];
        vm.item.scheduleActivities.forEach(function (scheduleActivity) {
            scheduleActivity.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                if(themeCommissionMembers.preparedBy==null)
                    themeCommissionMembers.preparedBy = angular.copy(themeCommissionMembers.commissionMembers);

                if (themeCommissionMembers.evaluatedBy==null)
                    themeCommissionMembers.evaluatedBy = angular.copy(themeCommissionMembers.commissionMembers);

                Array.prototype.push.apply(preparers, themeCommissionMembers.preparedBy.map(x => x.id));
                Array.prototype.push.apply(evaluators, themeCommissionMembers.evaluatedBy.map(x => x.id));

                for (var index in commissionMembersGroupedByThemeOnTheExam) {
                    if (themeCommissionMembers.theme.id == commissionMembersGroupedByThemeOnTheExam[index].theme.id) {
                        themeCommissionMembers.commissionMembersOnTheExam = angular.copy(commissionMembersGroupedByThemeOnTheExam[index].commissionMembers);
                        break;
                    }
                }               
            });
        });
        preparers = new Set(preparers);
        evaluators = new Set(evaluators);
        filterCommissionMembers();

        vm.item.scheduleActivities.forEach(function (scheduleActivity, index) {
            scheduleActivity.activityGradeDateConfig = {
                type: 'date',
                width: '100%',
                acceptCustomValue: false,
                showClearButton: true,
                inputAttr: {
                    'name': 'activityGradeDate' + index
                },
                bindingOptions: {
                    value: 'vm.item.scheduleActivities[' + index + '].activityGradeDate'
                }
            };
        });



        if (vm.item.overallGradeDescription != null)
            vm.item.overallGradeDescription = vm.formData.gradeDescriptionTypes[vm.item.overallGradeDescription];

        if (vm.item.overallAbsence != null)
            vm.item.overallAbsence = vm.formData.absenceTypes[vm.item.overallAbsence];

        vm.item.scheduleActivities.forEach(function (scheduleActivity, index) {
            if (scheduleActivity.activityGradeDescription != null)
                scheduleActivity.activityGradeDescription = vm.formData.activityGradeDescriptionTypes[scheduleActivity.activityGradeDescription];

            if (scheduleActivity.activityAbsence != null)
                scheduleActivity.activityAbsence = vm.formData.absenceTypes[scheduleActivity.activityAbsence];

            scheduleActivity.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                if (themeCommissionMembers.gradeDescription != null)
                    themeCommissionMembers.gradeDescription = vm.formData.gradeDescriptionTypes[themeCommissionMembers.gradeDescription];
            });
        });
        
        vm.copyActivityGrade = copyActivityGrade;
        vm.applyCandidate = applyCandidate;
        vm.previous = previous;
        vm.next = next;
        vm.save = save;
        vm.saveTrigger = saveTrigger;
        vm.saveCurrent = saveCurrent;
        vm.saveAndNavigate = saveAndNavigate;
        vm.sendEmailToApplier = sendEmailToApplier;
        vm.removedPreparer = removedPreparer;
        vm.addedPreparer = addedPreparer;
        vm.removedEvaluator = removedEvaluator;
        vm.addedEvaluator = addedEvaluator;
        vm.downloadTemplate = downloadTemplate;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            totalSubtitle: i18nFilter('translations.exam_results_gui_total_subtitle'),
            nextApplication: i18nFilter("translations.examSchedulePack_application_nextApplication"),
            previousApplication: i18nFilter("translations.examSchedulePack_application_previousApplication")
        };

        vm.item.scheduleActivities.forEach(function (activity, indexActivity) {
            var type = vm.formData.activityTypes.find(function (element) {
                return element.id == activity.activityType;
            });
            activity.activityTypeName = type.format;

            activity.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                themeCommissionMembers.startDateString = dateHelper.convertFromDateToString(themeCommissionMembers.startDate);
            });
        });

        function copyActivityGrade(indexCurrentActivity) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/results/copyActivityGrade/copyActivityGrade.html',
                controller: 'copyActivityGrade',
                controllerAs: 'vm',
                resolve: {
                    'activityGradeDescriptionTypes': function () {
                        return vm.formData.activityGradeDescriptionTypes;
                    },
                    'choices': function () {
                        return vm.item.scheduleActivities[indexCurrentActivity].previousActivityGrades;
                    },
                    'currentActivity': function () {
                        return {
                            indexActivity: indexCurrentActivity,
                            typeActivity: vm.item.scheduleActivities[indexCurrentActivity].activityTypeName,
                            nameActivity: vm.item.scheduleActivities[indexCurrentActivity].name
                        };
                    }
                }
            })
            .result.then(function (chosen) {
                // after clicking OK button
                vm.item.scheduleActivities[indexCurrentActivity].themesCommissionMembers.forEach(function (themeCommissionMembers) {
                    for (var i = 0, len = chosen.themesCommissionMembers.length; i < len; i++) {
                        if (themeCommissionMembers.theme.id == chosen.themesCommissionMembers[i].theme.id) {
                            themeCommissionMembers.gradePoints = chosen.themesCommissionMembers[i].gradePoints;
                            themeCommissionMembers.gradeDescription = chosen.themesCommissionMembers[i].gradeDescription;

                            for (var index in vm.formData.gradeDescriptionTypes) {
                                if (themeCommissionMembers.gradeDescription == index) {
                                    themeCommissionMembers.gradeDescription = vm.formData.gradeDescriptionTypes[index];
                                    break;
                                }
                            }

                            break;
                        }
                    }
                });

                vm.item.scheduleActivities[indexCurrentActivity].activityGradeDate = chosen.activityGradeDate;
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }

        function previous() {
            $stateParams.applicationId = vm.item.previousResultId;

            if (vm.item.previousApplicationStatus == vm.formData.examApplicationStatus.approved) {
                $state.go('examSchedulePacks.resultsAdd', $stateParams, { reload: true });
            } else if (vm.item.previousApplicationStatus == vm.formData.examApplicationStatus.present || vm.item.previousApplicationStatus == vm.formData.examApplicationStatus.absentJustified || vm.item.previousApplicationStatus == vm.formData.examApplicationStatus.absentUnjustified) {
                $state.go('examSchedulePacks.resultsEdit', $stateParams, { reload: true });
            }
        }

        function next() {
            $stateParams.applicationId = vm.item.nextResultId;

            $state.go('examSchedulePacks.resultsEdit', $stateParams, { reload: true });
        }

        function saveAndNavigate() {
            save().then(function (data) {
                if (vm.item.nextResultId !== null) {
                    next();
                } else {
                    $state.go('examSchedulePacks.applications', $stateParams, { reload: true });
                }
            });
        }

        function saveCurrent() {
            var copy = angular.copy(vm.item);
            if (copy.overallGradeDescription != null) {
                copy.overallGradeDescription = copy.overallGradeDescription.id;
            }
            if (copy.overallAbsence != null) {
                copy.overallAbsence = copy.overallAbsence.id;
            }

            copy.scheduleActivities.forEach(function (scheduleActivity) {
                if (scheduleActivity.activityGradeDescription != null) {
                    scheduleActivity.activityGradeDescription = scheduleActivity.activityGradeDescription.id;
                }
                if (scheduleActivity.activityAbsence != null) {
                    scheduleActivity.activityAbsence = scheduleActivity.activityAbsence.id;
                }

                scheduleActivity.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                    if (themeCommissionMembers.gradeDescription != null) {
                        themeCommissionMembers.gradeDescription = themeCommissionMembers.gradeDescription.id;
                    }
                });
            });

            // save should return a promise so we can chain things to it
            return examSchedulesDataService.editExamResult(angular.copy(copy), copy.fileId).then(function (data) {
                logger.success(i18nFilter('translations.exam_results_operation_edit_success_message'));
                // Post request to examschedulecontroler to edit the exam and upload file
                if (data.fileId != "") {
                    examSchedulesDataService.uploadAttachmentPostRequest(data.examResultId, data.fileId);
                }
                vm.item.id = data.examResultId;
                $state.reload(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function saveTrigger() {
            var copy = angular.copy(vm.item);
            if (copy.overallGradeDescription != null) {
                copy.overallGradeDescription = copy.overallGradeDescription.id;
            }
            if (copy.overallAbsence != null) {
                copy.overallAbsence = copy.overallAbsence.id;
            }

            copy.scheduleActivities.forEach(function (scheduleActivity) {
                if (scheduleActivity.activityGradeDescription != null) {
                    scheduleActivity.activityGradeDescription = scheduleActivity.activityGradeDescription.id;
                }
                if (scheduleActivity.activityAbsence != null) {
                    scheduleActivity.activityAbsence = scheduleActivity.activityAbsence.id;
                }

                scheduleActivity.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                    if (themeCommissionMembers.gradeDescription != null) {
                        themeCommissionMembers.gradeDescription = themeCommissionMembers.gradeDescription.id;
                    }
                });
            });

            // save should return a promise so we can chain things to it
            return examSchedulesDataService.editExamResult(angular.copy(copy), vm.item.fileId).then(function (data) {
                if (data.fileId != "") {
                    examSchedulesDataService.uploadAttachmentPostRequest(data.examResultId, data.fileId);
                }
                vm.item.id = data.examResultId;
                $state.reload(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            

            vm.item.finished = true;
            if ($scope.resultForm.$invalid) {
                angular.forEach($scope.resultForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                return;
            }
            
            saveCurrent();
        }

        function applyCandidate() {
            // we save first, so the data we need to copy is in the database
            save().then(function (data) {
                // open modal
                $uibModal.open({
                    templateUrl: 'app/examSchedulePacks/results/applyCandidate/applyCandidate.html',
                    controller: 'applyCandidate',
                    controllerAs: 'vm',
                    resolve: {
                        'examSchedules': function ($stateParams, examSchedulesDataService) {
                            return examSchedulesDataService.getAvailableExamSchedulesByExamId($stateParams.entityId);
                        }
                    }
                })
                .result.then(function (chosenScheduleId) {
                    // after clicking OK button
                    examSchedulesDataService.applyCandidate(chosenScheduleId, $stateParams.applicationId).then(function (data) { // applicationId = learnerId/candidateId
                        // navigate to the confirm application form for the new learner id
                        $location.path('/examSchedulePacks/' + $stateParams.entityId + '/' + chosenScheduleId + '/applications/' + chosenScheduleId + '/applications/application').search('applicationId='+data);
                    });
                }, function () {
                    // after clicking Cancel button or clicking background
                });
            });
        }

        function sendEmailToApplier() {
            var subject = undefined;

            if (vm.item.candidate.userDisplayName && vm.item.examName) {
                var candidateReference = 'kandidata';
                if (vm.item.candidate.candidateGender === 2) {
                    candidateReference = 'kandidatko';
                }
                subject = 'Uspešnost pri izpitu "' + vm.item.examName + '" za ' + candidateReference + ' ' + vm.item.candidate.userDisplayName;
            }
            else if (vm.item.examName) {
                subject = 'Uspešnost pri izpitu "' + vm.item.examName + '"';
            }
            
            window.location.href = 'mailto:' + vm.item.candidate.applierEmail + "\?subject=" + subject;
        }

        // filters the list of commission members to exclude preparers and evaluators
        function filterCommissionMembers() {
            vm.formData.distinctCommissionMembers = distinctCommissionMembers.filter(x => !preparers.has(x.id));
            vm.formData.distinctCommissionMembers = vm.formData.distinctCommissionMembers.filter(x => !evaluators.has(x.id));
        }
        function removedPreparer(member) {            
            preparers.delete(member.id);
            filterCommissionMembers();
        }
        function addedPreparer(member) {
            preparers.add(member.id);
            filterCommissionMembers();
        }
        function removedEvaluator(member) {
            evaluators.delete(member.id);
            filterCommissionMembers();
        }
        function addedEvaluator(member) {
            evaluators.add(member.id);
            filterCommissionMembers();
        }  
        
        function downloadTemplate() {
            documentsDataService.downloadDocument('/api/examSchedules/' + vm.item.id + '/downloadExamResultDocument', vm.item.fileName);
        }
        
        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message') + ' ' + i18nFilter('translations.exam_results_gui_upload_warning'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message') + ' ' + i18nFilter('translations.exam_results_gui_upload_warning'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '100',
            acceptedFiles: '.docx, .pdf',
            addRemoveLinks: true,
            error: "Error"
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.item.fileId = id;
            },
            'removedfile': function (file) {
                if (file.accepted) {
                    vm.item.fileId = undefined;
                }
            }
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        }
    }
})();

angular
    .module('app.examSchedulePacks').directive('autoSaveForm', function ($timeout) {
        return {
            require: ['^form'],
            link: function ($scope, $element, $attrs, $ctrls) {

                var $formCtrl = $ctrls[0];
                var savePromise = null;
                var expression = $attrs.autoSaveForm || 'true';

                $scope.$watch(function () {

                    if ($formCtrl.$dirty) {

                        if (savePromise) {
                            $timeout.cancel(savePromise);
                        }

                        savePromise = $timeout(function () {

                            savePromise = null;

                            if ($formCtrl.$name == "arrangementsForm" || $formCtrl.$name == "resultForm" ) {
                                if ($formCtrl.$name == "resultForm" && $scope.resultForm.$invalid) {
                                    angular.forEach($scope.resultForm.$error, function (field) {
                                        angular.forEach(field, function (errorField) {
                                            errorField.$setTouched();
                                        });
                                    });
                                    return;
                                }
                                if ($formCtrl.$name == "arrangementsForm" && $scope.arrangementsForm.$invalid) {
                                    angular.forEach($scope.arrangementsForm.$error, function (field) {
                                        angular.forEach(field, function (errorField) {
                                            errorField.$setTouched();
                                        });
                                    });
                                    return;
                                }
                            }
                            if ($scope.$eval(expression) !== false) {
                                console.log('Form data persisted -- setting prestine flag');
                                $formCtrl.$setPristine();
                            }


                        }, 3000);
                    }

                });
            }
        };

    });
