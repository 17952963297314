(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('certificateSignerService', certificateSigner);

    certificateSigner.$inject = ['$http', 'logger', '$q'];

    function certificateSigner($http, logger, $q) {
        var service = {
            getDocumentsForSigning: getDocumentsForSigning,
            signDocument: signDocument,
            updateSignerWebServiceData: updateSignerWebServiceData
        };

        return service;

        function getDocumentsForSigning() {
            return $http.get('/api/CertificateSign/GetAllDocuments/')
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function signDocument(certificate) {
            return $http.post('/api/CertificateSign/sign/', certificate)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }


        function updateSignerWebServiceData(certificate) {
            return $http.post('/api/CertificateSign/updateSignerWebServiceData/', certificate)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
    }
})();
