(function () {
    'use strict';

    angular
        .module('app.unauthorized')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('unauthorized', {
            parent: 'base',
            requireADLogin: true,
            url: '/unauthorized',
            controller: 'Unauthorized',
            controllerAs: 'vm',
            templateUrl: 'app/unauthorized/unauthorized.html',
            params: { stateName: null }
        });
    }
})();