(function () {
    'use strict';
    var module = angular.module('app.dataservice');

    var activityTypes = {
        genericAcitvityType: '87e9cc74-e902-e611-a1dd-9c2a708864f4',
        virtualClassroomAcitvityType: 'd9d0d7a5-e902-e611-a1dd-9c2a708864f4',
        surveyAcitvityType: 'cfaa7784-e902-e611-a1dd-9c2a708864f4',
        examAcitvityType: '5f7bc2b0-e902-e611-a1dd-9c2a708864f4',
        videoActivityType: 'd8d0d7a5-e902-e611-a1dd-9c2a708864f4',
        scormActivityType: '92c56289-9549-481f-adb0-3b8f85a2a1b9',
        officeMixActivityType: '92d45289-9549-481f-adb0-3b8f85a2a1b9',
        classroomActivityType: '10d45289-7449-481f-adb0-3b8f85a2a1b9',
        html5ActivityType: '6818c473-ffe4-49a8-b173-9c03ac3ca134',
        eExamActivityType: '88b3f5e7-eff8-4257-af2b-a070adafd04b',
        eExamForEducationActivityType: '73caa2ad-2f1b-4330-82ad-31830b863915'
    };

    module.value('activityTypes', activityTypes);

    var priority = {
        normal: 0,
        low: 1,
        high: 2
    };

    module.value('priority', priority);

    var delivery = {
        none: 0,
        success: 1,
        fail: 2
    };

    module.value('delivery', delivery);

    var schedulePackType = {
        onsite: 0,
        remote: 1
    };

    module.value('schedulePackType', schedulePackType);

    var activityStatusIndicator = {
        ok: 0,
        late: 1,
        finish: 2,
        neutral: -1
    };

    module.value('activityStatusIndicator', activityStatusIndicator);

    var applicationRoles = {
        globalAdmin: 1,
        tenantAdmin: 2,
        educationAdmin: 3,
        catalogAdmin: 4,
        kbAdmin: 6,
        educationOwner: 7,
        instructor: 8,
        learner: 9,
        programOwner: 10,
        surveyAdmin: 12,
        surveyOwner: 13,
        examAdmin: 14,
        examOwner: 15,
        schoolingAdmin: 16,
        schoolingOwner: 17,
        analyticsAdmin: 18,
        certificateSigner: 19,
        proficiencyExamAdmin: 20,
        proficiencyExamOwner: 21
    };

    module.value('applicationRoles', applicationRoles);

    var applicationTabs = {
        educationSchedulePack: 1,
        examSchedulePack: 2,
        schoolingSchedulePack: 3,
        onlineEducations: 4,
        reports: 5,
        extras: 6,
        eForms: 7,
        catalogEducations: 8,
        catalogExams: 9,
        catalogSchoolings: 10,
        catalogSurveys: 11,
        catalogPrograms: 12,
        addExamApplication: 13,
        addEFormApplication: 14,
        addProgramApplication: 15,
        addOnlineEducationApplication: 16,
        addSchoolingApplication: 17,
        analytics: 18,
        catalogProficiencyExams: 19,
        proficiencyExamsSchedulePack: 20
    };

    module.value('applicationTabs', applicationTabs);

    var operationCodes = {
        //#region Categories
        categoryAddException: -20006,
        categoryEditException: -20007,
        categoryDeleteException: -20008,

        categoryAddSuccess: 20001,
        categoryEditSuccess: 20002,
        categoryDeleteSuccess: 20003,
        //#endregion

        //#region Locations
        locationAddException: -50003,
        locationEditException: -50004,
        locationDeleteException: -50005,


        locationAddSuccess: 50001,
        locationEditSuccess: 50002,
        locationDeleteSuccess: 50003,
        //#endregion

        //#region Organizations
        organizationAddException: -40009,
        organizationEditException: -40010,
        OrganizationSyncException: -40011,

        organizationAddSuccess: 40001,
        organizationEditSuccess: 40002,
        OrganizationSyncSuccess: 40003,

        //#endregion

        //#region Learning period
        learningPeriodAddException: -30010,
        learningPeriodEditException: -30009,
        learningPeriodMoveDownException: -30008,
        learningPeriodMoveUpException: -30007,
        learningPeriodSetAsDefaultException: -30006,


        learningPeriodAddSuccess: 30001,
        learningPeriodEditSuccess: 30002,
        learningPeriodSetDefaultSuccess: 30003,
        learningPeriodMoveUpSuccess: 30004,
        learningPeriodMoveDownSuccess: 30005,
        //#endregion

        //#region Tenants
        tenantEditException: -10006,
        tenantAddException: -10005,

        tenantAddSuccess: 10001,
        tenantEditSuccess: 10002,
        //#endregion

        //#region Educations
        educationAddException: -60001,
        educationEditException: -60002,
        educationDeleteException: -60003,
        educationEnrollException: -60004,
        educationDisenrollException: -60005,
        educationRatingException: -60006,
        learnerEducationFinishException: -60007,
        adminFinishLearnerEducationException: -60008,

        educationAddSuccess: 60001,
        educationEditSuccess: 60002,
        educationDeleteSuccess: 60003,
        educationEnrollSuccess: 60004,
        educationDisenrollSuccess: 60005,
        educationRatingSuccess: 60006,
        learnerEducationFinishSuccess: 60007,
        adminFinishLearnerEducationSuccess: 60008,
        adminFinishLearnersEducationInfo: 60009,
        adminCancelFinishLearnersEducationInfo: 60010,
        //#endregion 

        //#region Document templates
        documentTemplateAddException: -70001,
        documentTemplateEditException: -70002,
        documentTemplateDeleteException: -70003,

        documentTemplateAddSuccess: 70001,
        documentTemplateEditSuccess: 70002,
        documentTemplateDeleteSuccess: 70003,
        //#endregion

        //#region Activities
        activityAddException: -80001,
        activityEditException: -80002,
        activityDeleteException: -80003,
        activityConfirmFinishException: -80004,
        activityMoveUpException: -80005,
        activityMoveDownException: -80006,

        activityAddSuccess: 80001,
        activityEditSuccess: 80002,
        activityDeleteSuccess: 80003,
        activityConfirmFinishSuccess: 80004,
        activityMoveUpSuccess: 80005,
        activityMoveDownSuccess: 80006,
        //#endregion

        //#region Schedules
        schedulePackAddException: -90001,
        schedulePackEditException: -90002,
        scheduleEnrollException: -90004,
        scheduleDisenrollException: -90005,
        scheduleDenyPresenceException: -90006,
        scheduleConfirmPresenceException: -90007,
        scheduleAdminEnrollException: -90009,
        scheduleAdminDisenrollException: -90010,
        scheduleUnlockException: -90015,
        scheduleLockException: -90016,


        schedulePackAddSuccess: 90001,
        schedulePackEditSuccess: 90002,
        scheduleEnrollSuccess: 90004,
        scheduleDisenrollSuccess: 90005,
        scheduleDenyPresenceSuccess: 90006,
        scheduleConfirmPresenceSuccess: 90007,
        scheduleEnrolledToWaitingListSuccess: 90008,
        scheduleAdminEnrollSuccess: 90009,
        scheduleAdminDisenrollSuccess: 90010,

        scheduleConfirmLearnersPresenceInfo: 90011,
        scheduleMissLearnersPresenceInfo: 90012,
        scheduleAdminEnrollLearnersInfo: 90013,
        scheduleAdminDisenrollLearnersInfo: 90014,
        scheduleUnlockSuccess: 90015,
        scheduleLockSuccess: 90016,
        scheduleJustifiedMissLearnersPresenceInfo: 90017,
        //#endregion

        //#region Diploms
        generateDocumentException: -100001,
        regenerateDocumentException: -100002,

        generateDocumentSuccess: 100001,
        regenerateDocumentSuccess: 100002,
        //#endregion

        //#region Assignemnts
        assignmentAddException: -110001,
        assignmentEditException: -110002,
        assignmentDeleteException: -110003,

        assignmentAddSuccess: 110001,
        assignmentEditSuccess: 110002,
        assignmentDeleteSuccess: 110003,
        //#endregion

        //#region City
        cityAddException: -120001,
        cityEditException: -120002,
        cityDeleteException: -120003,

        cityAddSuccess: 120001,
        cityEditSuccess: 120002,
        cityDeleteSuccess: 120003,
        //#endregion

        //#region Country
        countryAddException: -130001,
        countryEditException: -130002,
        countryDeleteException: -130003,

        countryAddSuccess: 130001,
        countryEditSuccess: 130002,
        countryDeleteSuccess: 130003,
        //#endregion

        //#region Program
        programAddException: -140001,
        programEditException: -140002,
        programDeleteException: -140003,
        programEnrollException: -140004,
        programDisenrollException: -140005,
        programFinishException: -140006,

        programAddSuccess: 140001,
        programEditSuccess: 140002,
        programDeleteSuccess: 140003,
        programEnrollSuccess: 140004,
        programDisenrollSuccess: 140005,
        programFinishSuccess: 140006,
        //#endregion

        //#region Promoted entities
        promotedEntityAddException: -150001,
        promotedEntityDeleteException: -150002,
        promotedEntityMoveUpException: -150003,
        promotedEntityMoveDownException: -150004,

        promotedEntityAddSuccess: 150001,
        promotedEntityDeleteSuccess: 150002,
        promotedEntityMoveUpSuccess: 150003,
        promotedEntityMoveDownSuccess: 150004,
        //#endregion

        //#region Users
        userAddException: -160001,
        userEditException: -160002,

        userAddSuccess: 160001,
        userEditSuccess: 160002,
        //#endregion

        //#region Mails
        mailSendException: -200001,
        mailConstructException: -200002,

        mailSendSuccess: 200001,
        //#endregion


        //#region Profile
        profileEditException: -170001,

        profileEditSuccess: 170001,
        //#endregion

        //#region Notification
        editNotificationConfigException: -220001,
        editNotificationTemplateConfigException: -220002,

        editNotificationTemplateConfigSuccess: 220002,
        editNotificationConfigSuccess: 220001,
        //#endregion

        //#region Budgets
        budgetAddException: -240001,
        budgetDeleteException: -240002,
        budgetEditException: -240003,

        budgetAddSuccess: 240001,
        budgetDeleteSuccess: 240002,
        budgetEditSuccess: 240003,
        //#endregion

        //#region Budget contracts
        contractAddException: -250001,
        contractDeleteException: -250002,
        contractEditException: -250003,

        contractAddSuccess: 250001,
        contractDeleteSuccess: 250002,
        contractEditSuccess: 250003,
        //#endregion

        //#region Schedule documents
        instructorRecordUploadException: -260001,
        instructorCertificateOfEmploymentUploadException: -260002,
        attendanceListUploadException: -260003,

        instructorRecordUploadSuccess: 260001,
        instructorCertificateOfEmploymentUploadSuccess: 260002,
        attendanceListUploadSuccess: 260003,
        //#endregion 

        //#region Competences
        competenceGroupAddSuccess: 270001,
        competenceGroupEditSuccess: 270002,
        competenceGroupDeleteSuccess: 270003,

        competenceGroupAddException: -270001,
        competenceGroupEditException: -270002,
        competenceGroupDeleteException: -270003,

        competenceAddSuccess: 270004,
        competenceEditSuccess: 270005,
        competenceDeleteSuccess: 270006,

        competenceAddException: -270004,
        competenceEditException: -270005,
        competenceDeleteException: -270006,

        competenceLevelAddSuccess: 270007,
        competenceLevelEditSuccess: 270008,
        competenceLevelDeleteSuccess: 270009,

        competenceLevelAddException: -270007,
        competenceLevelEditException: -270008,
        competenceLevelDeleteException: -270009,

        competenceLevelsTemplateAddSuccess: 270010,
        competenceLevelsTemplateEditSuccess: 270011,

        competenceLevelsTemplateAddException: -270010,
        competenceLevelsTemplateEditException: -270011,
        //#endregion

        //#region Schedule documents
        surveyAddSuccess: 28001,
        surveyAddException: -28001,

        surveyEditSuccess: 28002,
        surveyEditException: -28002,

        surveyDeleteSuccess: 28003,
        surveyDeleteException: -28003/*{{EXCEPTION_CLIENT_ENUM_PLACEHOLDER}}*/
        //#endregion 
    };

    module.value('operationCodes', operationCodes);

    //#region Process type
    var processType = {
        selfEnrollment: 3,
        resetEducationEnrollment: 4,
        sharepointCreateEducation: 6,
        defaultTemplateUpload: 7,
        categoryAdd: 8,
        categoryEdit: 9,
        categoryDelete: 10,
        tenantAdd: 11,
        tenantEdit: 12,
        learningPeriodAdd: 13,
        learningPeriodEdit: 14,
        learningPeriodSetDefault: 15,
        learningPeriodMoveUp: 16,
        learningPeriodMoveDown: 17,
        locationEdit: 18,
        locationAdd: 19,
        locationDelete: 20,
        organizationAdd: 21,
        organizationEdit: 22,
        organizationDelete: 23,
        educationRating: 24,
        learnerFinishEducation: 25,
        educationAdd: 26,
        educationEdit: 27,
        educationDelete: 28,
        activityMoveUp: 29,
        activityMoveDown: 30,
        editActivity: 31,
        activityDelete: 32,
        
        confirmGenericActivity: 33,
        confirmOfficeMixActivity: 34,
        selfEnrollmentAgain: 35,
        addActivity: 36,
        confirmVideoActivity: 37,
        addSchedulePack: 39,
        editSchedulePack: 40,
        enrollInSchedule: 43,
        selfLeaveFromSchedule: 44,
        deleteSchedule: 45,
        generateEducationFinishDocuments: 46,
        generateEducationActivityDocuments: 47,
        generateEducationProgramDocuments: 48,
        confirmSharePointSurveyActivity: 49,
        editTemplate: 50,
        addTemplate: 51,
        deleteTemplate: 52,
        countryDelete: 53,
        countryAdd: 54,
        countryEdit: 55,
        cityDelete: 56,
        cityAdd: 57,
        cityEdit: 58,
        programAdd: 59,
        programEdit: 60,
        programDelete: 61,
        promotedEntityDelete: 62,
        promotedEntityAdd: 63,
        promotedEntityMoveUp: 64,
        promotedEntityMoveDown: 65,
        userAdd: 66,
        userTransfer: 500,
        UserEntityRoleTransfer: 501,
        userEdit: 67,
        organizationSync: 68,
        editUserProfile: 69,
        notificationConfigEdit: 70,
        notificationTemplateConfigEdit: 71,

        sendMail: 76,
        generalHealthTask: 77,
        mailHealthTask: 78,

        budgetAdd: 79,
        budgetEdit: 80,
        budgetDelete: 81,

        contractAdd: 82,
        contractEdit: 83,
        contractDelete: 84,
        confirmHtml5Activity: 85,
        uploadInstructorCertificateOfEmployment: 86,
        uploadInstructorRecord: 87,

        administrativeConfirmSchedulePresence: 88,
        administrativeDenySchedulePresence: 89,
        activityDeactivate: 90,
        administrativeEnrollUserInSchedule: 91,
        administrativeDisenrollUsersFromSchedule: 92,

        administrativeFinishLearnersEducations: 93,
        lockSchedule: 94,
        unlockSchedule: 95,

        uploadAttendanceList: 96,

        competenceAdd: 97,
        competenceEdit: 98,
        competenceDelete: 99,
        competenceLevelAdd: 100,
        competenceLevelEdit: 101,
        competenceLevelDelete: 102,
        competenceGroupAdd: 103,
        competenceGroupEdit: 104,
        competenceGroupDelete: 105,
        competenceLevelsTemplateAdd: 106,
        competenceLevelsTemplateEdit: 107,


        regenerateEducationFinishDocuments: 108,
        regenerateEducationFinishDocuments: 462014,

        eFormFinish: 109,

        administrativeJustifiedDenySchedulePresence: 111,

        eFormDelete: 112,
        programFinish: 113,
        programRegenerateCertificate: 114,

        generateProgramnFinishDocuments: 115,

        administrativeCancelFinishLearnersEducations: 116,

        surveyAdd: 117,
        surveyEdit: 118,
        surveyDelete: 119,

        surveyGroupAdd: 120,
        surveyGroupEdit: 121,
        surveyGroupDelete: 122,
        surveyGroupOrderUp: 123,
        surveyGroupOrderDown: 124,

        questionAdd: 125,
        questionEdit: 126,
        questionDelete: 127,
        questionOrderUp: 128,
        questionOrderDown: 129,

        surveyResponseFinish: 130,
        tilesConfigurationEdit: 131,/*{{EXCEPTION_CLIENT_ENUM_PLACEHOLDER}}*/

        //#region Agitavit +460000
        eFormLearnerReplace: 463000,

        programConfirmSchedulePresence: 465088,

        examAdd: 460000,
        examEdit: 460001,
        examDelete: 460002,

        examScheduleAdd: 460050,
        examScheduleEdit: 460051,
        examScheduleDelete: 460052,

        examEntryStart: 460100,
        examEntrySubmit: 460101,
        examEntrySaveAnswers: 460102,

        examCatalogueAdd: 460150,
        examCatalogueEdit: 460151,
        examCatalogueDelete: 460152,
        examCatalogueCopy: 460153,

        examQuestionAdd: 460200,
        examQuestionEdit: 460201,
        examQuestionDelete: 460202,

        examQuestionUp: 462033,
        examQuestionDown: 462034,


        examApplicationUpdate: 460251,
        examApplicationLearnerTogglePaymentConfirmation: 460253,
        examApplicationReviewUpdate: 460254,

        examThemeAdd: 460300,
        examThemeEdit: 460301,

        examScholarshipAdd: 460350,
        examScholarshipEdit: 460351,
        examScholarshipDelete: 460352,

        examBulletinSubscriptionAdd: 460360,
        examBulletinSubscriptionEdit: 460361,
        examBulletinSubscriptionDelete: 460362,

        examBulletinAdd: 460370,
        examBulletinEdit: 460371,
        examBulletinDelete: 460372,

        examArrangementAdd: 460400,
        examArrangementEdit: 460401,

        examResultAdd: 460450,
        examResultEdit: 460451,

        targetGroupAdd: 460500,
        targetGroupEdit: 460501,
        targetGroupDelete: 460502,

        targetSubgroupAdd: 460550,
        targetSubgroupEdit: 460551,
        targetSubgroupDelete: 460552,

        examCandidateQuestionAdd: 460700,
        examCandidateQuestionEdit: 460701,
        examCandidateQuestionDelete: 460702,

        confirmEducationApplication: 461000,
        confirmOnlineEducationApplication: 461010,
        denyEducationApplication: 461001,
        denyOnlineEducationApplication: 461011,
        cancelEducationApplication: 461002,
        updateCommissionPaymentOrder: 461100,
        LockSurveyEducation: 461200,
        SendSurveyEducation: 461201,

        schoolingScheduleProgramEdit: 462020,
        schoolingScheduleBillingDirectCostEdit: 462030,
        schoolingScheduleBillingIndirectCostEdit: 462031,
        schoolingScheduleIssueUserCertificate: 462025,
        schoolingScheduleIssueCertificates: 462026,

        schoolingApplicationAdd : 460353,
        schoolingApplicationUpdate : 460354,
        schoolingApplicationTransfer : 460355,
        schoolingApplicationsUpdate : 460356,
        schoolingApplicationDelete : 460359,
        schoolingApplicationLearnerRemove: 460340,

        schoolingApplicationDeleteCertificate: 460383,

        schoolingScheduleContractAdd: 202,
        schoolingScheduleContractLink: 203,
        schoolingScheduleContractDelete: 204,
        finishSchooling: 460341,
        schoolingApplicationAddApproved: 460342,

        adminProgramDisenroll: 462013,

        urgentMessagesConfigurationUpdate: 133,

        certificateSign: 463001,
        updateSignDocumentData: 463003,

        generateEducationFinishDocumentsGroup: 464001,
        regenerateEducationFinishDocumentsGroup: 464002

        //#endregion
        /*{{EXCEPTION_CLIENT_ENUM_PLACEHOLDER}}*/
    };

    module.value('processType', processType);


    //#endregion

    //#region Worker status
    var workerStatus = {
        success: 1,
        warning: 2,
        fail: 3
    };

    module.value('workerStatus', workerStatus);


    //#endregion

    var exceptions = {
        //#region Competence
        CompetenceGroupDuplicateNameExists: -280001,
        CompetenceSubgroupDuplicateNameExists: -280002,
        CompetenceDuplicateNameExists: -280003,
        CompetenceLevelDuplicateNameExists: -280004,
        CompetenceLevelDuplicateOrderNumberExists: -280005,
        CompetenceSubgroupOrCompetenceExists: -280006,
        //#endregion

        //#region General exception
        nameToLong: -70001,
        externalIdToLong: -70002,
        duplicateNameExists: -70003,
        duplicateExternalIdExists: -70004,
        incorectFormatException: -70005,
        endDateIsGreaterThanStartDate: -70006,
        urlFormatException: -70007,
        defaultPeriodMissing: -70008,
        tenantNotDefined: -70009,
        objectNotFoundException: -70010,
        businessLogicException: -70011,
        //#endregion

        //#region Contract
        somethingWrongWithSharePoint: -999990,
        //#endregion

        //#region Contract
        ibanValidationFailed: -27001,
        contractIsOverlapping: -27002,
        contractWithSameNumberAlreadyExists: -27003,
        cannotDeleteBudgetContractBecauseItIsUsedInSomeSchedules: -27004,
        removedContractorsHaveActiveSchedules: -27005,
        //#endregion

        //#region Budget
        allRelatedEntitiesNeedsToBeActive: -26001,
        budgetIsOverlapping: -26002,
        cannotDeleteBudgetBecauseItIsUsedBySomeSchedules: -26003,
        removedEducationsHaveActiveSchedules: -26004,
        //#endregion

        //#region Authentication
        userTenantNotFound: -240001,
        tokenInvalid: -240002,
        //#endregion

        //#region Promoted entities
        promotedEntityAlreadyExists: -160001,
        //#endregion

        //#region ShP survey
        userDidNotCompletedSurvey: -150001,
        //#endregion

        //#region Users
        cannotSelectDynamicRole: -230001,
        userWithTheSameNameLastNameAndEmailExists: -230002,
        emailNotUnique: -230003,
        //#endregion

        //#region Programs
        programDuplicateNameExists: -220001,
        programHasNoOwners: -220002,
        cannotDeletePromotedProgram: -220003,
        promotedProgramCannotBeDeactivated: -220004,
        programHasEducationsWithSchedules: -220005,
        cannotDeleteEnrolledProgram: -220006,
        //#endregion

        //#region Cities
        cannotDeleteCityWithOrganizationRelation: -210001,
        //#endregion

        //#region Countries
        cannotDeleteCountryWithCityRelation: -200001,
        //#endregion

        //#region Educations
        educationHasNoCategory: -80001,
        educationHasNoOwners: -80002,
        educationHasEnrolledUsersAndCannotBeDeactivated: -80003,
        educationHasEnrolledUsersByAssignment: -80004,
        educationIsFinishedAndCannotBeDeleted: -80005,
        educationSelfEnrollmentNotAllowed: -80006,
        userIsEnrolledInEducation: -80007,
        userCannotEnrollToUnfinishedEducation: -80008,
        cannotReenrollEducationInSamePeriod: -80009,
        userAlreadyDisenrolledFromEducation: -80010,
        educationAlreadyRated: -80012,
        cannotFinishEducationBecauseLastEnrollmentIsNotStarted: -80013,
        thereIsRequiredActivitiesThatIsNotFinished: -80014,
        educationDuplicateNameExists: -80015,
        cannotFinishEducationLearnerHasInProcessActivity: -80016,
        duplicateCodeForEducationOrProgramException: -80017,
        cannotDeleteEducationWhichIsPartOfAProgram: -80018,
        missingRequiredTags: -80019,
        cannotDeletePromotedEducation: -80020,
        promotedEducationCannotBeDeactivated: -80021,
        userIsNotCatalogAdminAndEducationOwner: -80022,
        scheduleWithoutActiveActivity: -80023,
        scheduleWithoutActivity: -80024,
        //#endregion

        //#region Schoolings
        userIsEnrolledInSchooling: -90007,
        //#endregion

        //#region Assignments
        assignmentDoesNotHaveSelectedLearners: -140001,
        cannotAddEnrolledLearnersToAssigment: -140002,
        cannotAddAssignmentBecauseSomeUserHasBeenEnrolledInScheduleBefore: -140003,
        //#endregion

        //#region Does not exist 
        learningActivityScheduleDoesNotExist: 130001,
        educationActivityScheduleDoesNotExist: -130002,
        educationActivityDoesNotExist: -130003,
        learningPlanItemDoesNotExists: -130004,
        learningActivityDoesNotExists: -130005,
        locationDoesNotExist: -130007,
        fileDoesNotExist: -130008,
        //#endregion

        //#region Schedule
        instructorsScheduleException: -120001,
        overLappingSchedule: -120002,
        cannotDeleteScheduleWithEnrolledLearners: -120004,
        userDoesNotEnrolledInActivitySchedule: -120005,
        userWithFinishedActivityCannotBeEnrolledInActivitySchedule: -120006,
        scheduleIsFullException: -120007,
        cannotConfirmedCompletedUserSchedule: -120008,
        cannotDisenrolledUserFromCompletedSchedule: -120009,
        cannotDisenrolledUserFromMissedSchedule: -120010,
        cannotDisenrolledUserFromCancelledSchedule: -120011,
        cannotDenyMissedUserSchedule: -120012,
        missedCheckOutDateForDisenrollSchedule: -120013,
        missedCheckInDateForEnrollSchedule: -120014,
        cannotEnrollUserInScheduleWhosAlreadyEnrolled: -120015,
        //#endregion

        //#region Education activities
        totalPointsCannotBeNegative: -110001,
        estimatedTimeInWrongFormat: -110002,
        educationActivityDuplicateNameExists: -110004,
        learnerHasEnrolledOrCompletedLearningActivitySchedule: -110005,
        learnerCapacityCannotBeNegative: -110006,
        scheduleWithoutInstructorsException: -110007,
        unableToDeleteActivityWithExistingSchedules: -110008,
        cannotAddActivityBecauseOfEducationExecutionTypeConsistention: -110009,
        //#endregion

        //#region DMS
        dmsConfigMissingProperty: -91001,

        invalidFileNameChars: -91004,
        fileNameAlreadyExists: -91005,
        fileIsNotSupported: -91006,
        //#endregion

        //#region DocumentTemplates
        fileIsTooLarge: -99001,
        fileFormatException: -99002,
        duplicateTemplateNameExists: -99003,
        cannotDeleteTemplateWithActiveEducationRelation: -99004,
        templateCannotBeEmpty: -99005,
        missingTemplateException: -99006,
        generateDocumentException: -99007,
        cannotDeleteTemplateWithActiveSchoolingRelation: -99008,
        //#endregion

        //#region Process Service
        missingClientID: -60001,
        dataIsNotDefined: -60002,
        //#endregion

        //#region Location
        locationHasActiveShedules: -50006,
        //#endregion

        //#region Organizations        
        organizationHasDeletedOrDeactivatedParentException: -40003,
        organizationParentTypeLevelException: -40004,
        organizationChildrenTypeLevelException: -40005,
        organizationHasActiveUsersAndItCannotBeDeactivated: -40006,
        organizationHasActiveChildrenAndItCannotBeDeactivated: -40007,
        organizationHasActiveLocationsAndItCannotBeDeactivated: -40008,
        //#endregion

        //#region Learning Period        
        scheduleIsOutOfLearningPeriodDate: -30004,
        learningPeriodDateException: -30003,
        //#endregion

        //#region Categories        
        categoryExistsInProgram: -20006,
        categoryExistsInEducation: -20005,
        categoryDeletedOrDeactivatedParentException: -20004,
        categoryHierarchyLevelException: -20003,
        //#endregion

        //#region Tenants
        tenantExternalIdToLong: -10008,
        tenantSharepointConfigurationIsInvalid: -10007,
        tenantConfigurationInWrongFormat: -10004,
        //#endregion

        //#region Surveys
        surveyQuestionGroupDoesNotExist: -300001,
        surveyQuestionDoesNotExist: -300002,
        surveyDuplicateNameExists: -300003,
        surveyGroupDuplicateNameExists: -300004,
        surveyCanNotBeDeletedException: -300005,
        //#region

        //region ReleaseHistory
        releasesHistoryCouldNotAccessJSONData: -320001,
        //#region

        //#region Agitavit -460000
        examHasNoCategory: -460002,
        examHasNoCoordinators: -460003,
        examHasNoCommitteeMembers: -460004,
        examHasNoContactPerson: -460005,
        examDuplicateName: -460006,

        examScheduleInvalidTimespan: -461001,
        examScheduleInvalidDuration: -461002,
        examScheduleDuplicateCode: -461003,
        examScheduleInvalidIpRange: -461004,
        examThemeIsAlreadyUsedOnApplication: -462002,

        signingServiceNotAvailable: -463006,

        examArrangementHasMembersWithFinalResults: -462003,
        cannotDeleteSchoolingWithSchedules: -90023,
        cannotReturnFinishedOrAttendingSchoolingApplicationLearnercs: -90024,
        cannotDeleteSchoolingCertificateDocument: -90025,
        //#endregion

        // ^
        // |
        //ERRORS        
        error: -1
    };

    module.value('exceptions', exceptions);

    var exceptionsLevels = {
        trace: 0,
        debug: 1,
        information: 2,
        warning: 3,
        error: 4,
        critical: 5,
        none: 6
    };

    module.value('exceptionsLevels', exceptionsLevels);

    var status = {
        deleted: -1,
        deactivated: 0,
        active: 1,
        processing: 2,
        cancelled: 3
    };

    module.value('status', status);

    var learningActivityState = {
        started: 1,
        finished: 2
    };

    module.value('learningActivityState', learningActivityState);

    var locationType = {
        classroom: 0,
        virtual: 1
    };
    module.value('locationType', locationType);


    var learningPlanItemStatus = {
        removedFromPlan: -1,
        notStarted: 0,
        started: 1,
        finished: 2
    };

    module.value('learningPlanItemStatus', learningPlanItemStatus);

    var learningPlanItemSuccess = {
        unknown: 0,
        success: 2,
        fail: 1
    };

    module.value('learningPlanItemSuccess', learningPlanItemSuccess);

    var learningActivitySuccess = {
        unknown: 0,
        success: 2,
        fail: 1
    };

    module.value('learningActivitySuccess', learningActivitySuccess);

    var virtualClassroomType = {
        skype: 1,
        other: 2
    };
    module.value('virtualClassroomType', virtualClassroomType);

    var learningActivityScheduleStatus = {
        ongoing: 0,
        enrolled: 1,
        onWaitingList: 2,
        missed: 3,
        completed: 4,
        cancelled: 5,
        justifiedMissed: 6
    };

    module.value('learningActivityScheduleStatus', learningActivityScheduleStatus);


    var scheduleEnrollmentStatus = {
        success: 1,
        onWaitingListStem: 2,
        onWaitingListRegular: 3,
        onWaitingList: 4,
        fail: 5,
        noSeatsAvailable: 6
    };
    module.value('scheduleEnrollmentStatus', scheduleEnrollmentStatus);

    var eFormPayer = {
        person: 1,
        organization: 2
    };
    module.value('eFormPayer', eFormPayer);


    var conferenceType = {
        private: 1,
        public: 2
    };
    module.value('conferenceType', conferenceType);

    var certificateTemplateType = {
        education: 1,
        program: 2
    };
    module.value('certificateTemplateType', certificateTemplateType);

    var eventType = {
        education: 1,
        activity: 2
    };
    module.value('eventType', eventType);

    var acceptPerson = {
        learner: 1,
        educationOwner: 2
    };
    module.value('acceptPersons', acceptPerson);

    var organizationLevels = {
        companyGroup: 1,
        company: 10,
        organizationUnit: 20
    };
    module.value('organizationLevels', organizationLevels);

    module.value('emptyGuid', '00000000-0000-0000-0000-000000000000');


    var educationAccessMode = {
        publicAccess: 1,
        browseOnly: 2,
        enrolledUsersAndOwnersOnly: 3
    };

    module.value('educationAccessMode', educationAccessMode);

    var schoolingAccessMode = {
        Yes: 1,
        No: 2,
    };

    module.value('schoolingAccessMode', schoolingAccessMode);


    var schoolingApplicationLearnerAttendance = {
        Yes: 1,
        No: 0,
        JustifiedMissed: 2
    };

    module.value('schoolingApplicationLearnerAttendance', schoolingApplicationLearnerAttendance);


    var onlineEducationVisibility = {
        All: 1,
        Organization: 2
    }

    module.value('onlineEducationVisibility', onlineEducationVisibility)

    //#region Route configuration
    var routeConfiguration = [
        {
            key: 'app1',
            home: false,
            css: true
        },
        {
            key: 'app2',
            home: false,
            css: true
        }
    ];
    module.value('routeConfigurationV', routeConfiguration);

    module.provider('routeConfiguration', function () {
        this.$get = function () {
            return {};
        };

        this.getConfiguration = function () { return routeConfiguration; };
        this.getHostParts = function () {
            var result = {};
            var regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
            var urlParts = regexParse.exec(window.location.hostname);
            result.domain = urlParts[1];
            result.type = urlParts[2];
            result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);
            return result;
        };
    });
    //#endregion

    var educationFinishConfirmationMode = {
        learner: 1,
        educationOwner: 2
    };

    module.value('educationFinishConfirmationMode', educationFinishConfirmationMode);

    var surveyType = {
        none: 0,
        participant: 1,
        coordinator: 2,
        contractor: 3
    };

    module.value('surveyType', surveyType);


    var reportGroup = {
        learner: 1,
        manager: 2,
        admin: 3
    };

    module.value('reportGroup', reportGroup);

    var reportType = {
        telerik: 1
    };

    module.value('reportType', reportType);

    var educationExecutionStyle = [
        { name: 'translations.education_gui_performing_option_on_site', id: 1 },
        { name: 'translations.education_gui_performing_option_online', id: 2 },
        { name: 'translations.education_gui_performing_option_blended', id: 3 },
        { name: 'translations.education_gui_performing_option_school', id: 4 },
        { name: 'translations.education_gui_performing_option_conference', id: 5 },
        { name: 'translations.education_gui_performing_option_seminar', id: 6 },
        { name: 'translations.education_gui_performing_option_consultation', id: 7 },
        { name: 'translations.education_gui_performing_option_workshop', id: 8 },
        { name: 'translations.education_gui_performing_option_simulation', id: 9 },
        { name: 'translations.education_gui_performing_option_course', id: 10 },
        { name: 'translations.education_gui_performing_option_linguistic_education', id: 11 },
        { name: 'translations.education_gui_performing_option_supervision', id: 12 },
        { name: 'translations.education_gui_performing_option_group_meeting', id: 13 },
        { name: 'translations.education_gui_performing_option_discussion', id: 14 },
        { name: 'translations.education_gui_performing_option_online_education', id: 15 },
        { name: 'translations.education_gui_performing_option_prepare_seminar', id: 16 },
        { name: 'translations.education_gui_performing_option_improve_seminar', id: 17 },
        { name: 'translations.education_gui_performing_option_exam_test', id: 18 },
        { name: 'translations.education_gui_performing_option_expert_consultation', id: 19 }
    ];

    module.value('educationExecutionStyle', educationExecutionStyle);

    var schoolingExecutionStyle = [
        { name: 'translations.schooling_gui_performing_option_school', id: 1 },
        { name: 'translations.schooling_gui_performing_option_conference', id: 2 },
        { name: 'translations.schooling_gui_performing_option_seminar', id: 3 },
        { name: 'translations.schooling_gui_performing_option_consultation', id: 4 },
        { name: 'translations.schooling_gui_performing_option_workshop', id: 5 },
        { name: 'translations.schooling_gui_performing_option_simulation', id: 6 },
        { name: 'translations.schooling_gui_performing_option_course', id: 7 },
        { name: 'translations.schooling_gui_performing_option_linguistic_schooling', id: 8 },
        { name: 'translations.schooling_gui_performing_option_group_meeting', id: 9 },
        { name: 'translations.schooling_gui_performing_option_discussion', id: 10 },
        { name: 'translations.schooling_gui_performing_option_online_schooling', id: 11 },
        { name: 'translations.schooling_gui_performing_option_prepare_seminar', id: 12 },
        { name: 'translations.schooling_gui_performing_option_improv_seminar', id: 13 },
        { name: 'translations.schooling_gui_performing_option_individual_supervision', id: 14 },
        { name: 'translations.schooling_gui_performing_option_seminar_and_workshop', id: 15 },
        { name: 'translations.schooling_gui_performing_option_excursion', id: 16 },
        { name: 'translations.schooling_gui_performing_option_workshop_and_knowledge_assessment', id: 17 },
        { name: 'translations.schooling_gui_performing_option_study_visit', id: 18 }
    ];

    module.value('schoolingExecutionStyle', schoolingExecutionStyle);

    var schoolingEstDuration = [
        { name: 'translations.schooling_gui_est_duration_option_half_day', id: 1 },
        { name: 'translations.schooling_gui_est_duration_option_one_day', id: 2 },
        { name: 'translations.schooling_gui_est_duration_option_two_days', id: 3 },
        { name: 'translations.schooling_gui_est_duration_option_multi_days', id: 4 },
    ];

    module.value('schoolingEstDuration', schoolingEstDuration);


    var schoolingEstDurationDays = [
        { name: 'translations.schooling_gui_label_half_day', value: -1 },
        { name: 'translations.schooling_gui_label_one_day', value: 1 },
        { name: 'translations.schooling_gui_label_two_day', value: 2 },
        { name: 'translations.schooling_gui_label_multiple_day', value: -2 }
    ];

    module.value('schoolingEstDurationDays', schoolingEstDurationDays);


    var estDurationDays = [
        { name: 'translations.education_gui_label_half_day', value: -1 },
        { name: 'translations.education_gui_label_one_day', value: 1 },
        { name: 'translations.education_gui_label_multiple_day', value:-2 }
    ];

    module.value('estDurationDays', estDurationDays);

    var EducationActivityScheduleInstructorRoles = [
        { name: "translations.schedule_gui_no_role", id: 0 },
        { name: "translations.schedule_gui_lecture_performer", id: 1 },
        { name: "translations.schedule_gui_workshop_guide", id: 2 },
        { name: "translations.schedule_gui_independent_workshop_performer", id: 3 },
        { name: "translations.schedule_gui_supervision_performer", id: 4 },
        { name: "translations.schedule_gui_consultation_performer", id: 5 },
        { name: "translations.schedule_gui_moderator_guide", id: 6 },
        { name: "translations.schedule_gui_course_guide", id: 7 },
        { name: "translations.schedule_gui_linguistic_education_performer", id: 8 },
        { name: "translations.schedule_gui_group_meeting_guide", id: 9 },
        { name: "translations.schedule_gui_conversation_participant", id: 10 },
        { name: "translations.schedule_gui_eeducation_guide", id: 11 },
        { name: "translations.schedule_gui_professor", id: 12 }
    ];

    module.value('EducationActivityScheduleInstructorRoles', EducationActivityScheduleInstructorRoles);

    var SchoolingActivityScheduleInstructorRoles = [
        { name: "translations.schooling_schedule_gui_no_role", id: 0 },
        { name: "translations.schooling_schedule_gui_intro_speech", id: 1 },
        { name: "translations.schooling_schedule_gui_lecture_performer", id: 2 },
        { name: "translations.schooling_schedule_gui_lecture_repeating_performer", id: 3 },
        { name: "translations.schooling_schedule_gui_workshop_guide", id: 4 },
        { name: "translations.schooling_schedule_gui_simulation", id: 5 },
        { name: "translations.schooling_schedule_gui_consultation_performer", id: 6 },
        { name: "translations.schooling_schedule_gui_moderator_guide", id: 7 },
        { name: "translations.schooling_schedule_gui_course_guide", id: 8 },
        { name: "translations.schooling_schedule_gui_perform_lecture_pdi", id: 9 },
        { name: "translations.schooling_schedule_gui_linguistic_education_performer", id: 10 },
        { name: "translations.schooling_schedule_gui_supervision_individual_performer", id: 11 },
        { name: "translations.schooling_schedule_gui_supervision_group_performer", id: 12 },
        { name: "translations.schooling_schedule_gui_supervision_adhoc_performer", id: 13 },
        { name: "translations.schooling_schedule_gui_group_meeting_guide", id: 14 },
        { name: "translations.schooling_schedule_gui_conversation_participant_with_presentation", id: 15 },
        { name: "translations.schooling_schedule_gui_conversation_participant_in_discussion", id: 16 },
        { name: "translations.schooling_schedule_gui_independent_workshop_performer", id: 17 },
        { name: "translations.schooling_schedule_gui_eschooling_guide", id: 18 },
        { name: "translations.schooling_schedule_gui_school_workshop_guide", id: 19 },
        { name: "translations.schooling_schedule_gui_workshop_participant", id: 20 }
    ];

    module.value('SchoolingActivityScheduleInstructorRoles', SchoolingActivityScheduleInstructorRoles);

    var contractingPartyType = [
        { name: "translations.contract_gui_contracting_party_type_manual", id: 0 },
        { name: "translations.contract_gui_contracting_party_type_ajpes", id: 1 },
        {name: "translations.contract_gui_contracting_party_type_manual_from_db_users", id: 2}
    ];

    module.value('contractingPartyType', contractingPartyType);

    var promotedEntityType = {
        education: 1,
        program: 2,
        schooling: 3
    };

    module.value('promotedEntityType', promotedEntityType);


    var scheduleVisibility = {
        private: 0,
        public: 1
    };

    module.value('scheduleVisibility', scheduleVisibility);

    var scheduleExecutionType = {
        normal: 0,
        remote: 1
    };

    module.value('scheduleExecutionType', scheduleExecutionType);

    var gender = {
        unknown: 0,
        male: 1,
        female: 2
    };

    module.value('gender', gender);

    var employmentStatus = {
        unknown: 0,
        student: 1,
        employed: 2,
        unemployed: 3,
        retired: 4
    };

    module.value('employmentStatus', employmentStatus);


    var dmsPermission = {
        fullControl: 1,
        read: 2
    };

    module.value('dmsPermission', dmsPermission);

    var dmsProviders = {
        SharepointOnlineProvider: 'SharePoint Online',
        WindowsDMSProvider: 'Windows Document Management System'
    };

    module.value('dmsProviders', dmsProviders);

    var authProviders = {
        azureAD: 'AAD',
        activeDirectoryFS: 'ADFS'
    };

    module.value('authProviders', authProviders);

    var dgProviders = {
        yammer: 'Yammer',
        microsoftTeams: 'Microsoft teams',
        slack: 'Slack'
    };

    module.value('dgProviders', dgProviders);

    var examStatus = {
        allExams: 0,
        active: 1
    };
    module.value('examStatus', examStatus);

    var examOwnerType = {
        allExams: 1,
        myExams: 2
    };
    module.value('examOwnerType', examOwnerType);

    var examQuestionType = {
        checkBox: 1,
        radioButton: 2,
        essay: 3,
        freeText: 4
    };

    module.value('examQuestionType', examQuestionType);


    var examQuestionGroupUsageModel = {
        noQuestions: 1,
        randomQuestions: 2,
        orderedQuestions: 3
    };

    module.value('examQuestionGroupUsageModel', examQuestionGroupUsageModel);


    var examVisibilityType = {
        public: 1,
        private: 2
    };

    module.value('examVisibilityType', examVisibilityType);

    var examType = {
        automatic: 1,
        manual: 2
    };

    module.value('examType', examType);

    var examCatalogueQuestionType = {
        essay: 0,
        fillIn: 1,
        dropdown: 2,
        multipleChoice: 3,
        singleChoice: 4,
        connect: 5
    };

    module.value('examCatalogueQuestionType', examCatalogueQuestionType);

    var emailProviders = {
        smtp: 1,
        exchange: 2
    };

    module.value('emailProviders', emailProviders);

    var notificationTemplatesTypes = {
        N1_1: 10001,
        N1_2: 10002,
        N1_3: 10003,
        N1_4: 10004,
        N1_5: 10005,
        N1_6: 10006,
        N1_7: 10007,
        N1_8: 10008,
        N1_9: 10009,
        N1_10: 10010,
        N1_11: 10011,
        N1_12: 10012,
        N1_13: 10013,
        N1_14: 10014,
        N1_15: 10015,
        N1_16: 10016,
        N1_17: 10017,
        N2_2: 20002,
        N2_3: 20003,
        N2_4: 20004,
        N2_11: 20011,
        N2_14: 20014,
        N3_2: 30002,
        N3_3: 30003,
        N3_11: 30011,
        N3_81: 10022,
        N1_63: 10023,
        N30_55: 10024,
        N30_56: 10025,
        MN_1: 1000070
    };

    module.value('notificationTemplatesTypes', notificationTemplatesTypes);


    var myEntitiesCatalogFilter = {
        onlyMine: 1,
        all: 2
    }
    module.value('myEntitiesCatalogFilter', myEntitiesCatalogFilter);

    var contentTypeTags = {
        educationTag: 'a819aa45-19dd-47c4-db6e-08d4fa7c4da3',
        educationExam: 'd597fd73-9821-44bf-db71-08d4fa7c4da3'
    };

    module.value('contentTypeTags', contentTypeTags);

    var notificationTypesClassification = {
        event: 1,
        determinedByTime: 2
    };

    module.value('notificationTypesClassification', notificationTypesClassification);

    var notificationSendingType = {
        everyDay: 1,
        dayOfWeek: 2,
        dayOfMonth: 3
    };

    module.value('notificationSendingType', notificationSendingType);

    var pricesCategory = {
        learnerPrice: '15a225bd-73ae-e711-a250-9c2a708864f4',
        instructorPrice: '16a225bd-73ae-e711-a250-9c2a708864f4',
        budgetPrice: '17a225bd-73ae-e711-a250-9c2a708864f4',
        groupPrice: '1a7b40b7-5e36-46b9-a425-6bcc1c59f39f'
    };

    module.value('pricesCategory', pricesCategory);

    var currencyCategory = {
        hrk: '17a225bd-73ae-e711-a250-9c2a708864f4',
        eur: '18a225bd-73ae-e711-a250-9c2a708864f4',
        usd: '19a225bd-73ae-e711-a250-9c2a708864f4'
    };

    module.value('currencyCategory', currencyCategory);

    var documentTriggersAndEvents = {
        start: 1,
        end: 2,
        doorSchedule: 3,
        attendanceList: 4,
        instructorRecord: 5,
        certificateOfEmployment: 6
    };

    module.value('documentTriggersAndEvents', documentTriggersAndEvents);

    var schedulePacksSearchOptions = {
        future: 1,
        lastYear: 2,
        all: 3
    };

    module.value('schedulePacksSearchOptions', schedulePacksSearchOptions);

    var schedulePacksUnlockedSearchOptions = {
        mine: 1,
        all: 2
    };

    module.value('schedulePacksUnlockedSearchOptions', schedulePacksUnlockedSearchOptions);

    var eFormTypes = {
        education: 1,
        program: 2,
        exams: 3
    };

    module.value('eFormTypes', eFormTypes);

    var bruto2Percentage = {
        unemployed: 1.0938,
        firmVatPayer: 1.22,
        firm: 1
    };

    module.value('bruto2Percentage', bruto2Percentage);

    var questionTypes = {
        freeText: 1,
        radio: 2,
        checkbox: 3,
        scalar: 4,
        matrix: 5,
        percent: 6
    };

    module.value('questionTypes', questionTypes);

    var activityType = {
        writtenExam: 1,
        verbalExam: 2,
        eExam: 3
    };

    module.value('activityType', activityType);

    var examAccessMode = {
        publicAccess: 1,
        browseOnly: 2,
        enrolledUsersAndCoordinatorsOnly: 3
    };

    module.value('examAccessMode', examAccessMode);

    var commissionMemberType = {
        president: 0,
        member: 1,
        independentExpert:2
    };

    module.value('commissionMemberType', commissionMemberType);

    var examSearchOptions = {
        active: 1,
        finished: 2,
        all: 3
    };

    module.value('examSearchOptions', examSearchOptions);

    var gradeDescriptionType = {
        verySuccessful: 0,
        successful: 1,
        unsuccessful: 2,
        unsuccessfulCanRepeat: 3,
        passed: 4,
        notPassed: 5,
        didNotComeOrSignOut: 6
    };

    module.value('gradeDescriptionType', gradeDescriptionType);
    
    var absenceType = {
        unjustified: 0,
        justified: 1
    };

    module.value('absenceType', absenceType);

    var examScheduleApplicationType = {
        single: 0,
        group: 1,
        singleAndGroup: 2
    };

    module.value('examScheduleApplicationType', examScheduleApplicationType);

    var examApplicationStatus = {
        inProgress: 0,
        finished: 1,
        approved: 2,
        rejected: 3,
        present: 4,
        absentJustified: 5,
        absentUnjustified: 6,
        pendingPersonalData: 7,
        waiting: 8,
        disenrolled: 9
    };

    module.value('examApplicationStatus', examApplicationStatus);

    var examApplicationFieldType = {
        text: 0,
        date: 1,
        dropdown: 2,
        bool: 3,
        number: 4,
        upload: 5,
        fullAddress: 6,
        fullAddressCityAndPost: 7,
        fullAddressStreetAndNumber: 8,
        dateDuration: 9,
        email: 10,
        bologne: 11,
        PdiApplication : 12,
        Article19Application : 13,
        Article19aApplication: 14,
        ExamDateAndCertificateNumber: 15,
        DateAndTextFillIn: 16
    };

    module.value('examApplicationFieldType', examApplicationFieldType);

    var eFormStatus = {
        inProgress: 1,
        sentToProcess: 2,
        finished: 3,
        pendingPersonalData: 4,
        pendingConfirmation: 5,
        rejected: 6
    };

    module.value('eFormStatus', eFormStatus);

    var eFormTypeEntity = {
        education: 1,
        program: 2,
        onlineEducation: 3,
        exams: 4,
        schooling: 5
    };

    module.value('eFormTypeEntity', eFormTypeEntity);

    var entityRoles = {
        owner: 1,
        contactPerson: 2
    };

    module.value('entityRoles', entityRoles);

    var documentCertificationSign = {
        notApplicable : 1,
        waiting : 2,
        signed : 3
    };

    module.value('documentCertificationSign', documentCertificationSign);
})();
