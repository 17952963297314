(function () {
    'use strict';

    angular
        .module('app.releaseHistory')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('releaseHistory', {
                parent: 'base',
                url: '/manage/releaseHistory',
                templateUrl: 'app/manage/releaseHistory/releaseHistory.html',
                controller: 'releaseHistory',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                },
                resolve: {
                    releaseHistoryData: ['releaseHistoryDataService', '$stateParams', function (releaseHistoryDataService, $stateParams) {
                        return releaseHistoryDataService.getReleaseHistory();
                    }]
                }
            })
            ;
    }
})();
