(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('documentIcon', documentIcon);

    documentIcon.$inject = ['logger'];

    function documentIcon(logger) {
        return function (input) {

            var wordIcon = '<i class="fa fa-file-word-o"></i>';
            var powerPointIcon = '<i class="fa fa-file-powerpoint-o"></i>';
            var excelIcon = '<i class="fa fa-file-excel-o"></i>';
            var defaultIcon = '<i class="fa fa-file"></i>'
            var pdfIcon = '<i class="fa fa-file-pdf-o"></i>'

            switch (input) {
                case 'docx':
                    return wordIcon;
                case 'doc':
                    return wordIcon;
                case 'pptx':
                    return powerPointIcon;
                case 'ppt':
                    return powerPointIcon;
                case 'xlsx':
                    return excelIcon;
                case 'xls':
                    return excelIcon;
                case 'pdf':
                    return pdfIcon;
                default:
                    return defaultIcon;
            }
        };
    }

})();