(function () {
    'use strict';

    angular
        .module('app.users')
        .factory('editUserHelper', editUserHelper);

    editUserHelper.$inject = ['logger', 'educationsDataService', 'exceptionHelper', '$state', '$stateParams', 'i18nFilter', 'educationAccessMode'];

    function editUserHelper(logger, educationsDataService, exceptionHelper, $state, $stateParams, i18nFilter, educationAccessMode) {

        var internalData = undefined;
        var wizardSteps = undefined;
        var eventTypeRoles = [];
        var remoteUser = undefined;

        var service = {
            getData: getData,
            getEventTypeRoles: getEventTypeRoles,
            getRemoteUser: getRemoteUser,
            getWizardSteps: getWizardSteps,
            setData: setData,
            setEventTypeRoles: setEventTypeRoles,
            setRemoteUser: setRemoteUser,
            clearData: clearData,
            clearEventTypeRoles: clearEventTypeRoles,
            clearRemoteUser: clearRemoteUser,
            done: done
        };

        return service;

        function getData() {
            if (!internalData) {
                internalData = {
                    //Set default properties
                    empty: undefined
                };
            }
            return internalData;
        }

        function getEventTypeRoles() {
            return eventTypeRoles;
        }

        function getRemoteUser() {
            return remoteUser;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'users.baseEdit.firstStep',
                        text: i18nFilter('translations.user_operation_edit_title')
                    },
                    {
                        state: 'users.baseEdit.secondStep',
                        text: i18nFilter('translations.user_operation_roles_title')
                    }
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'users.baseAdd.firstStep',
                        text: i18nFilter('translations.user_operation_add_title')
                    }
                ];
            }

            return wizardSteps;
        }

        function setData(selectedRoles) {
            if (internalData === undefined)
                getData();

            internalData.selectedEventTypeRoles = selectedRoles;
        }

        function setEventTypeRoles(eventRoles) {
            eventTypeRoles = eventRoles;
        }

        function setRemoteUser(transferUser) {
            return remoteUser = transferUser;
        }

        function clearData() {
            internalData = undefined;
        }

        function clearEventTypeRoles() {
            eventTypeRoles = [];
        }

        function clearRemoteUser() {
            return remoteUser = undefined;
        }

        function done(isEdit, partialSave) {
            //Call right data service method
            if (isEdit) {
                return educationsDataService.editEducation(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.education_operation_edit_success_message'));
                    $state.go(partialSave ? $state.current : "users", {}, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
            else {
                educationsDataService.addEducation(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.education_operation_add_success_message'));
                    $state.go("users");
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }
    }
})();
