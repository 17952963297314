(function () {
    'use strict';

    angular
        .module('blocks.logger')
        .factory('logger', logger);

    logger.$inject = ['$log', 'toastr', 'enableDebugLogging'];

    function logger($log, toastr, enableDebugLogging) {
        var service = {
            showToasts: true,

            error: error,
            info: info,
            success: success,
            warning: warning,

            // straight to console; bypass toastr
            log: log
        };

        return service;
        /////////////////////

        function log(message, data) {
            if (enableDebugLogging) {
                $log.log(message, data);
            }
        }

        function error(message, data) {
            toastr.error(message);
            $log.error('Error: ' + message, data);
        }

        function info(message, data) {
            toastr.info(message);
            $log.info('Info: ' + message, data);
        }

        function success(message, data) {
            toastr.success(message);
            $log.info('Success: ' + message, data);
        }

        function warning(message, data) {
            toastr.warning(message);
            $log.warn('Warning: ' + message, data);
        }
    }
}());
