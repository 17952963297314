(function () {
    'use strict';

    var core = angular.module('app.core');

    //#region SICAS
    var clientId = 'e5f30220-f952-4d74-bbec-73ec7f01b4e4';
    var applicationName = 'LMS';
    var authInstance = 'https://du-sts.gov.si/';
    var sicasLogOutUrl = 'https://sicas.gov.si/bl/logout?eid=http://du-sts.gov.si/adfs/services/trust';
    //#endregion

    //#region Logger configuration
    core.value("enableDebugLogging", false);
    //#endregion

    //#region EForms
    core.value("eFormsUrl", {
        "a297a0e4-39ec-e511-80ec-00155d010a02": "https://eua-prijave.gov.si/eForms",
        "ee1d3823-2bf2-e811-9c34-005056818ee6": "https://cip-eprijave.gov.si/eForms"
    });
    //#endregion

    //#region Terms of use
    core.value("termsOfUse", {
        "a297a0e4-39ec-e511-80ec-00155d010a02": "https://ua.gov.si/o-nas/splosni-pogoji-uporabe/",
        "ee1d3823-2bf2-e811-9c34-005056818ee6": "https://cip.gov.si/kdo-smo/splosni-pogoji-ecip/"
    });
    //#endregion

    //#region Public site url
    core.value("publicSiteUrl", {
        "eua": "https://ua.gov.si",
        "cip": "https://cip.gov.si"
    });
    //#endregion

    //#region Post logout redirect uri
    core.value("postLogoutRedirectUri", {
        "a297a0e4-39ec-e511-80ec-00155d010a02": "https://eua.gov.si/#/logout",
        "ee1d3823-2bf2-e811-9c34-005056818ee6": "https://cip-lms.gov.si/#/logout"
    });
    //#endregion
    
    //#region New admin portal url
    core.value("newAdminPortalUrl", {
        "eua": "https://eua-admin.gov.si/",
        "cip": "https://cip-admin.gov.si/"
    });
    //#endregion
    

    //#region ADAL configuration
    core.config(authenticationConfig);
    authenticationConfig.$inject = ['$httpProvider', 'adalAuthenticationServiceProvider'];
    function authenticationConfig($httpProvider, adalAuthenticationServiceProvider) {
        adalAuthenticationServiceProvider
            .init({
                instance: authInstance,
                tenant: 'adfs',
                clientId: clientId,
                endpoints: {
                    'https://eua.gov.si': 'https://eua.gov.si',
                    'https://eua-prijave.gov.si/': 'https://eua-prijave.gov.si/'
                },
                cacheLocation: 'localStorage',
                extraQueryParameter: 'nux=1',
                anonymousEndpoints: [
                    'api/resources/GetTenant'
                ]
            }, $httpProvider);
    }
    //#endregion

    //#region Constants
    core.value('appName', applicationName);
    core.value('clientId', clientId);
    core.value('sicasLogOutUrl', sicasLogOutUrl);
    //#endregion

    //#region App name
    core.run(function () {
        let tenant = '';
        $(document).ready(function () {
            let tenant = "";
                $.ajax({
                    url: 'https://eua.gov.si/api/resources/GetTenant',
                    type: 'GET',
                    dataType: 'text',
                    success: function (data, textStatus, xhr) {
                        tenant = data;
                        if (tenant === "eua"){
                            applicationName = "eUA";
                        }
                        document.title = applicationName;

                    },
                    error: function (xhr, textStatus, errorThrown) {
                        console.log('Error in Operation', textStatus + " " + errorThrown,);
                    }
                });
        });    });
    //#endregion
})();
