(function () {
    'use strict';    

    angular
        .module('app.filters')
        .filter('notificationTemplateName', notificationTemplateName);

    notificationTemplateName.$inject = ['logger', 'i18nFilter'];
    
    function notificationTemplateName(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }
            
            var value = Number(input);
            return value;
            /*switch (value) {
                case 10001:
                    return i18nFilter('translations.email_filter_send_notification_template_name_reminder_for_unfinished_education');
                case 10002:
                    return i18nFilter('translations.email_filter_send_notification_template_name_education_entry_notice');
                case 20002:
                    return i18nFilter('translations.email_filter_send_notification_template_name_program_entry_notice');
                case 10005:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notice_of_change_of_term');
                case 10006:
                    return i18nFilter('translations.email_filter_send_notification_template_name_education_term_registration_confirmation');
                case 10007:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notice_of_free_place_in_the_period_of_education');
                case 10008:
                    return i18nFilter('translations.email_filter_send_notification_template_name_education_schedule_reminder');
                case 10009:
                    return i18nFilter('translations.email_filter_send_notification_template_name_education_term_termination_notice');
                case 10010:
                    return i18nFilter('translations.email_filter_send_notification_template_name_education_additional_term_notice');
                case 10011:
                    return i18nFilter('translations.email_filter_send_notification_template_name_from_education_cancellation_notice');
                case 20011:
                    return i18nFilter('translations.email_filter_send_notification_template_name_from_program_cancellation_notice');
                case 10012:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notice_of_term_assignment_to_the_lecturer');
                case 10013:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notice_of_registration_on_the_waiting_list');
                case 10014:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notification_of_completed_education');
                case 20014:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notification_of_completed_program');
                case 10015:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notification_of_the_need_to_verify_personal_information');
                case 10016:
                    return i18nFilter('translations.email_filter_send_notification_template_name_notification_of_completion_of_the_e-exam_evaluation');
                case 1000022:
                    return i18nFilter('translations.email_filter_send_notification_template_name_schooling_schedule_reminder');
                default:
                    return value;
            }*/
        };
    }
})();
