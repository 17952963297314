(function () {
    'use strict';

    angular
        .module('app')
        .controller('EducationDocumentsFileSystemEditLink', editLink);

    editLink.$inject = ['logger', 'documentsDataService', '$scope', 'exceptionHelper', 'item', 'path'];

    function editLink(logger, documentsDataService, $scope, exceptionHelper, item, path) {
        var vm = this;

        vm.cancel = cancel;
        vm.ok = ok;
        vm.item = item;
        vm.path = path;
        vm.noDots = noDots;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            documentsDataService.editLink(vm.item.path, vm.path, vm.item.linkUrl, vm.item.name).then(function (data) {
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };

        function noDots() {
            if (vm.item && vm.item.name && vm.item.name.indexOf(".") != -1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
})();
