(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('examSchedulesDataService', examSchedulesDataService);

    examSchedulesDataService.$inject = ['$http', 'logger', '$sessionStorage', '$rootScope', '$q', 'dateHelper', 'blobHelper', 'decimalHelper'];

    function examSchedulesDataService($http, logger, $sessionStorage, $rootScope, $q, dateHelper, blobHelper, decimalHelper) {
        var service = {
            addExamSchedule: addExamSchedule,
            editExamSchedule: editExamSchedule,
            getExamScheduleById: getExamScheduleById,
            getExamScheduleCommissionsByExamScheduleId: getExamScheduleCommissionsByExamScheduleId,
            addExamScheduleCommissions: addExamScheduleCommissions,
            editExamScheduleCommissions: editExamScheduleCommissions,
            getExamResult: getExamResult,
            addExamResult: addExamResult,
            editExamResult: editExamResult,
            getQuestions: getQuestions,
            notifyCommissionMembers: notifyCommissionMembers,
            getExamNameAndScheduleCodeByScheduleId: getExamNameAndScheduleCodeByScheduleId,
            isCodeAvailable: isCodeAvailable,
            getCandidatesInappropriateCommissionMembersLinked: getCandidatesInappropriateCommissionMembersLinked,
            isTravelOrderTemplateSet: isTravelOrderTemplateSet,
            getAvailableExamSchedulesByExamId: getAvailableExamSchedulesByExamId,
            applyCandidate: applyCandidate,
            getScheduleActivitiesByScheduleId: getScheduleActivitiesByScheduleId, 
            getExamSchedulePaymentOrderCommissionsByExamScheduleId: getExamSchedulePaymentOrderCommissionsByExamScheduleId,
            updateExamScheduleAsync: updateExamScheduleAsync,
            getCalculatedPaymentOrderDataAsync: getCalculatedPaymentOrderDataAsync,
            getCandidatesActivityAppliedThemes: getCandidatesActivityAppliedThemes,
            getCommissionMemberWithLowestParticipation: getCommissionMemberWithLowestParticipation,
            suggestCommissionMembers: suggestCommissionMembers,
            uploadAttachmentPostRequest: uploadAttachmentPostRequest,
            addCommissionsForActivity: addCommissionsForActivity,
            sendGradeResultNotification: sendGradeResultNotification,
            examActivityDate: examActivityDate,
            editExamScheduleStatus: editExamScheduleStatus,
            getActivityLocations: getActivityLocations
        };

        return service;

        function addExamSchedule(scheduledExam) {
            scheduledExam.startDate = dateHelper.convertFromDateToString(scheduledExam.startDate);
            
            return $http.post('api/examSchedules', scheduledExam).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to add exam schedule.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function examActivityDate(activityId, locationId) {
            return $http.get('api/examSchedules/getSchedulePackLocationDate/' + activityId + '/' + locationId).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                logger.log('Failed to edit exam schedule.');
                if (data) { return $q.reject({ exception: data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editExamSchedule(scheduledExam) {
            scheduledExam.startDate = dateHelper.convertFromDateToString(scheduledExam.startDate);
           
            return $http.put('api/examSchedules/' + scheduledExam.id, scheduledExam).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to edit exam schedule.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editExamScheduleStatus(scheduledExam) {

            return $http.put('api/examSchedules/change/status/' + scheduledExam.id, scheduledExam).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to change exam schedule status.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamScheduleById(id) {
            return $http.get('api/examSchedules/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamScheduleCommissionsByExamScheduleId(id) {
            return $http.get('api/examSchedules/commissions/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule commissions.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamSchedulePaymentOrderCommissionsByExamScheduleId(id) {
            return $http.get('api/examSchedules/commissionsPaymentOrder/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule commissions for payment order.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addExamScheduleCommissions(examScheduleCommissions) {
            //examScheduleCommissions.startDate = dateHelper.convertFromDateToString(examScheduleCommissions.startDate);
            var fd = new FormData();
            fd.append('item', JSON.stringify(examScheduleCommissions));

            return $http.post('api/examSchedules/commissions', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to add exam schedule commissions.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editExamScheduleCommissions(examScheduleCommissions) {
            //examScheduleCommissions.startDate = dateHelper.convertFromDateToString(examScheduleCommissions.startDate);
            var fd = new FormData();
            fd.append('item', JSON.stringify(examScheduleCommissions));

            return $http.put('api/examSchedules/commissions', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to edit exam schedule commissions.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        /*function getCandidateScheduleActivities(scheduleid, candidateid) {
            return $http.get('api/examSchedules/' + scheduleid + '/candidate/' + candidateid + '/results/add').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule results.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }*/

        function getExamResult(scheduleid, candidateid) {
            return $http.get('api/examSchedules/' + scheduleid + '/candidate/' + candidateid + '/results/edit').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule results.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function addExamResult(examResult) {
            var fd = new FormData();
            fd.append('item', JSON.stringify(examResult));

            return $http.post('api/examSchedules/results', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to add exam result.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editExamResult(examResult, fileId) {
            var fd = new FormData();
            fd.append('item', JSON.stringify(examResult));

            return $http.post('api/examSchedules/results/fileId/' + fileId, fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to edit exam result.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function suggestCommissionMembers(activityId) {
            return $http.get('api/examSchedules/suggestCommissionMembers/' + activityId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get suggested commission members.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getQuestions(examScheduleId) {
            return $http.get('api/examQuestions/byExamSchedule/' + examScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedule results.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function notifyCommissionMembers(examScheduleId) {
            return $http.get('api/examQuestions/notifyCommissionMembers/' + examScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamNameAndScheduleCodeByScheduleId(examScheduleId) {
            return $http.get('api/examSchedules/nameandcode/' + examScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam name and schedule code.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCodeAvailable(code, examId) {
            return $http.get('api/examSchedules/exam/' + examId + '/code/' + code + '/availability/').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get availability of schedule code');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCommissionMemberWithLowestParticipation(examScheduleId, activeCommissionMemberIds) {
            return $http.post('api/examSchedules/commissionMemberWithLowestParticipation', { scheduleId: examScheduleId, commissionMemberIds: activeCommissionMemberIds }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                    logger.log('Failed to get CommissionMemberWithLowestParticipation for exam scheduleId ' + examScheduleId +'. Commission member ids: ' + activeCommissionMemberIds);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCandidatesInappropriateCommissionMembersLinked(examScheduleId, candidateIds, includeAppLearnerInnapropriateUsers) {
            return $http.post('api/examSchedules/inappropriateCommissionMembersLinked', { scheduleId: examScheduleId, candidateIds: candidateIds, includeAppLearnerInnapropriateUsers: includeAppLearnerInnapropriateUsers }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get InappropriateCommissionMembersLinked for exam scheduleId ' + examScheduleId + ' candidate Ids ' + candidateIds);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCandidatesActivityAppliedThemes(examScheduleId, examCandidateIds, examActivityId) {
            // With a post request there is an object created and passed, that suits the ExamScheduleGetAppliedThemesVM view model
            return $http.post('api/examSchedules/candidatesActivityAppliedThemes', { scheduleId: examScheduleId, candidateIds: examCandidateIds, activityId: examActivityId } ).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                    logger.log('Failed to get candidatesActivityAppliedThemes.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isTravelOrderTemplateSet(examScheduleId) {
            return $http.get('api/examSchedules/examSchedule/' + examScheduleId + '/travelOrderStatus/').then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get travel order status of schedule code');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAvailableExamSchedulesByExamId(examId) {
            return $http.get('api/examSchedules/availableForAdmin/' + examId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam schedules for exam id ' + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function applyCandidate(examScheduleId, candidateId) {
            return $http.post('api/examSchedules/applyCandidate/' + examScheduleId +'/'+candidateId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to apply candidate to selected schedule.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        function getScheduleActivitiesByScheduleId(scheduleId) {
            return $http.get('api/examSchedules/' + scheduleId + '/activities/').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get exam schedule activities for schedule id " + scheduleId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function updateExamScheduleAsync(examCommissionPaymentOrder) {
            
            var fd = new FormData();
            fd.append('item', JSON.stringify(examCommissionPaymentOrder));

            return $http.post('api/examSchedules/paymentOrder/save', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to update commission payment order.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCalculatedPaymentOrderDataAsync(examCommissionId) {
            return $http.get('api/examSchedules/paymentOrder/data/' + examCommissionId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function uploadAttachmentPostRequest(examResultId, fileId) {
            return $http.get('api/examSchedules/' + examResultId + '/' + fileId + '/editExamUploadAttachment').then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getActivityLocations(id) {
            return $http.get('api/examSchedules/scheduleActivity/locations' + '/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCommissionsForActivity(activityData) {
            return $http.post('api/examSchedules/scheduleActivity/commissions', activityData).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendGradeResultNotification(schedulePackId) {
            return $http.post('api/examSchedules/gradeNotification/' + schedulePackId , schedulePackId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
    }
})();
