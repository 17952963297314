(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('schoolingsDataService', schoolings);

    schoolings.$inject = ['$http', 'logger', '$q', 'dateHelper', 'blobHelper', 'decimalHelper'];

    function schoolings($http, logger, $q, dateHelper, blobHelper, decimalHelper) {
        var service = {
            getSchoolingById: getSchoolingById,
            getSchoolingInfo: getSchoolingInfo,
            getSchoolingCategories: getSchoolingCategories,
            getSchoolingOwners: getSchoolingOwners,
            getSchoolingContactPersons: getSchoolingContactPersons,
            getEditSchooling: getEditSchooling,
            getAllActiveSchoolings: getAllActiveSchoolings,
            getAllActiveSchoolingsWithSynchronousActivities: getAllActiveSchoolingsWithSynchronousActivities,
            getActiveSchoolingFieldNames: getActiveSchoolingFieldNames,
            getActiveSchoolingFieldNamesBySchoolingIdAsync: getActiveSchoolingFieldNamesBySchoolingIdAsync,
            //searchOrganizationsByExternalIdOrTaxId: searchOrganizationsByExternalIdOrTaxId,
            getSchoolingLearnerPriceBySchoolingIdAsync: getSchoolingLearnerPriceBySchoolingIdAsync,
            addSchooling: addSchooling,
            editSchooling: editSchooling,
            deleteSchooling: deleteSchooling,
            


            getProgramSchoolings: getProgramSchoolings,
            getCurrentSchoolings: getCurrentSchoolings,

            getPotentialSchoolingsForPromotedEntities: getPotentialSchoolingsForPromotedEntities,

            sendSupportMail: sendSupportMail,

            checkUserEnrollment: checkUserEnrollment,
            resetSchoolingEnrollment: resetSchoolingEnrollment,
            
            getOnlineSchoolings: getOnlineSchoolings,
            checkCanDeleteSchooling: checkCanDeleteSchooling
        };

        return service;

        function sendSupportMail(schoolingId, mail) {
            return $http.post('api/schoolings/' + schoolingId + '/sendSupportMail', mail).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingById(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingInfo(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/sidebar/info').then(function (data) {
                //TODO: datumi
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get schooling info');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingCategories(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/sidebar/categories').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingOwners(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/sidebar/owners').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch schooling owners');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingContactPersons(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/sidebar/contactPersons').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch schooling contact persons');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getEditSchooling(id) {
            return $http.get('api/schoolings/' + id + '/edit').then(function (data) {
                angular.forEach(data.data.prices, function (price, index) {
                    price.amount = decimalHelper.convertDecimalToLocaleString(price.amount);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch edit schooling");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveSchoolings() {
            return $http.get('api/schoolings/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveSchoolingsWithSynchronousActivities() {
            return $http.get('api/schoolings/active/synchronous').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSchooling(schooling) {
            var fd = new FormData();
            var schooling_copy = angular.copy(schooling);

            if (schooling_copy.picture) {
                let blob = blobHelper.dataURItoBlob(schooling_copy.picture);
                schooling_copy.picture = null;
                fd.append('picture', blob);
            }

            if (schooling_copy.logotype) {
                let blob = blobHelper.dataURItoBlob(schooling_copy.logotype);
                schooling_copy.logotype = null;
                fd.append('logotype', blob);
            }

            fd.append('item', JSON.stringify(schooling_copy));

            return $http.post('api/schoolings', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editSchooling(schooling) {
            var fd = new FormData();

            if (schooling.picture) {
                let blob = blobHelper.dataURItoBlob(schooling.picture);
                schooling.picture = null;
                fd.append('picture', blob);
            }

            if (schooling.logotype) {
                let blob = blobHelper.dataURItoBlob(schooling.logotype);
                schooling.logotype = null;
                fd.append('logotype', blob);
            }

            fd.append('item', JSON.stringify(schooling));

            return $http.put('api/schoolings/' + schooling.id, fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                logger.log('Failed to change edit schooling');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteSchooling(id) {
            return $http.delete('api/schoolings/' + id).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getCurrentSchoolings() {
            return $http.get('api/schoolings/current').then(function (data) {
                angular.forEach(data.data, function (schooling, index) {
                    schooling.learningPlanItemDeadline = dateHelper.convertFromStringToDate(schooling.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramSchoolings(programId) {
            return $http.get('api/programs/' + programId + '/schoolings').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPotentialSchoolingsForPromotedEntities() {
            return $http.get('api/promotedEntities/potential/schoolings').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function checkUserEnrollment(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/checkEnrollment').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function resetSchoolingEnrollment(schoolingId) {
            return $http.post('api/schoolings/' + schoolingId + '/resetEnrollment').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getOnlineSchoolings() {
            return $http.get('api/schoolings/online').then(function (data) {
                //Data transformation here
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveSchoolingFieldNames() {
            return $http.get('api/schoolings/fieldNames/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get active schooling field names");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveSchoolingFieldNamesBySchoolingIdAsync(schoolingId) {
            return $http.get('api/schoolings/fieldNames/bySchoolingId/' + schoolingId).then(function (data) {
                return data.data;
            }, function (data) {
                logger.log("Failed to get active schooling field names by schooling id " + schoolingId + ".");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //@Deprecated && @Duplicate - should be removed as this is used in organisations
        /*function searchOrganizationsByExternalIdOrTaxId(query) {
            return $http.get('/api/organizations/searchByExternalIdOrTaxId/' + query).then(function (data) {
                return data.data;
            }, function (data) {
                logger.log("Failed to search for external id or tax id " + query + ".");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }*/

        function getSchoolingLearnerPriceBySchoolingIdAsync(schoolingId) {
            return $http.get('api/schoolings/learnerPrice/' + schoolingId).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function checkCanDeleteSchooling(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/canDelete').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
