(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('deleteQuestion', deleteQuestion);

    deleteQuestion.$inject = ['logger', '$stateParams', 'questionsDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteQuestion(logger, $stateParams, questionsDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.definition = $stateParams.definition;

        function ok() {
            questionsDataService.deleteQuestion($stateParams.questionId).then(function (data) {
                logger.success(i18nFilter("translations.exam_questions_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
