(function () {
    'use strict';

    angular
        .module('app.surveys.home')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys.home', {
            url: '/home',
                templateUrl: 'app/surveys/home/home.html',
            controller: 'SurveyHome',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'EducationOwner', 'Instructor']
            }
        });
    }
})();
