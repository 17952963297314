(function () {
    'use strict';

    angular
        .module('app.logout')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('logout', {
                url: '/logout',
                templateUrl: 'app/logout/logout.html',
                controller: 'Logout',
                controllerAs: 'vm',
                resolve: {
                    tenantResourceKey: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenantResourceKey();
                    }]
                }
            });
    }
})();
