(function () {
    'use strict';

    angular
        .module('app.categories')
        .controller('EditCategories', editCategories);

    editCategories.$inject = ['logger', 'category', '$state', 'categories', 'categoriesDataService', 'exceptionHelper', 'i18nFilter', 'emptyGuid', '$sanitize'];

    function editCategories(logger, category, $state, categories, categoriesDataService, exceptionHelper, i18nFilter, emptyGuid, $sanitize) {
        var vm = this;
        vm.title = 'edit';
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            hierarchyLevel: i18nFilter("translations.manage_categories_note_hierarchy_level_limit"),
        };

        vm.formData = {
            parents: categories,
            showParentCategoryDisabledMessage: false,
            oldParentCategory: undefined
        };

        if (vm.isEdit) {
            vm.item = category;
            prepereCategories();
            filterParentList();
        }
        else {
            vm.item = {};
            prepereCategories();
            vm.item.active = true;
        }

        vm.add = add;
        vm.edit = edit;

        function filterParentList() {
            //Provjeri je li trenutno nadređeno područje u listi, ako je ok, ako nije ispiši poruku 
            //  da je trenutno područje deaktivirano ili obrisano i da je za uređivanje kategorije 
            //  potrebno odabrati novo nadređeno područje

            var parentCategoryIsInParentList = false;
            angular.forEach(vm.formData.parents, function (category, index) {
                if (vm.item.parent != undefined && vm.item.parent.id == category.id) {
                    parentCategoryIsInParentList = true;
                }
            });

            if (!parentCategoryIsInParentList) {
                vm.formData.oldParentCategory = angular.copy(vm.item.parent);
                vm.item.parent = undefined;
                vm.formData.showParentCategoryDisabledMessage = true;
            }

            //Ukoliko je odabrani parrent null ili Guid.empty, postavi odabrani na prvi item iz liste
            if (vm.item.parent == undefined || vm.item.parent.id == undefined || vm.item.parent.id == emptyGuid) {
                vm.item.parent = vm.formData.parents[0];
            }

        }

        function prepereCategories() {

            //Izbaci trenutno područje
            angular.forEach(vm.formData.parents, function (category, index) {
                if (vm.item != undefined && vm.item.id == category.id) {
                    vm.formData.parents.splice(index, 1);
                }
            });

            //Flat struktura
            var allCategoriesOriginal = angular.copy(vm.formData.parents);

            angular.forEach(vm.formData.parents, function (category, index) {
                category.name = recursive(1, angular.copy(category), allCategoriesOriginal) + ": " + category.name;
                category.name = category.name.substring(2, category.name.length);
            });

            //Ubacivanje praznog područja
            vm.formData.parents.splice(0, 0, {
                id: emptyGuid,
                name: '-'
            });

            function recursive(step, category, allCategories) {
                var result = "";
                if (step > 10 || category.parent == undefined) {
                    return result;
                }

                var parent = undefined;
                //Find parent in allCategories list
                angular.forEach(allCategories, function (cat, index) {
                    if (cat.parent != undefined && cat.id == category.parent.id) {
                        parent = cat;
                    }
                });

                if (parent) {
                    result += recursive(step + 1, parent, allCategories);
                    result += ": " + parent.name;
                }
                return result;
            }
        }

        function add() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            categoriesDataService.addCategory(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter("translations.category_queue_add"));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function edit() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            categoriesDataService.editCategory(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter("translations.category_queue_edit"));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
