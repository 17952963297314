(function () {
    'use strict';

    angular
        .module('app.tenants')
        .controller('EditTenant', editTenant);

    editTenant.$inject = ['$stateParams', 'logger', '$state', 'tenantDataService', 'exceptionHelper', 'exceptions', 'i18nFilter', 'tenant', '$sanitize'];

    function editTenant($stateParams, logger, $state, tenantDataService, exceptionHelper, exceptions, i18nFilter, tenant, $sanitize) {
        var vm = this;
        vm.save = save;
        vm.add = add;
        vm.isEdit = $state.current.data.isEdit;
        vm.downloadSettings = downloadSettings;
        vm.item = tenant;

        vm.localeMessages = {
            editTenantStatusInfo: i18nFilter('translations.tenants_gui_edit_tooltip_status_info')
        };

        if (!vm.isEdit) {
            vm.item.active = true;
        }

        function add() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            tenantDataService.addTenant(vm.item).then(function (result) {
                logger.success(i18nFilter('translations.tenant_queue_add'), result);
                $state.go('tenants', null);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }


        function save() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            tenantDataService.editTenant(vm.item).then(function (result) {
                logger.success(i18nFilter('translations.tenant_queue_edit'), result);
                $state.go('tenants', null);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }


        function downloadSettings() {
            var ua = new UAParser();
            var browser = ua.getBrowser();

            if (browser.name === 'Edge' || browser.name.indexOf('IE') !== -1) {

                var cleanString = S(angular.copy(vm.item.settings)).collapseWhitespace().s;

                var fileData = [cleanString];
                var blobObject = new Blob(fileData);

                window.navigator.msSaveOrOpenBlob(blobObject, 'settings.json');
            }
            else {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(vm.item.settings));
                element.setAttribute('download', 'settings.json');
                element.setAttribute('target', '_blank');
                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            }
        }

    }
})();
