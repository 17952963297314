(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schedulePackLectures', schedulePackLectures);

    schedulePackLectures.$inject = ['$stateParams', '$state', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', 'examApplicationStatus', '$http', 'schoolingSchedulePacksDataService', 'documentsDataService', 'schoolingSchedulePackLectures', 'schoolingNameAndScheduleCode'];

    function schedulePackLectures($stateParams, $state, $scope, i18nFilter, exceptionHelper, logger, examApplicationStatus, $http, schoolingSchedulePacksDataService, documentsDataService, schoolingSchedulePackLectures, schoolingNameAndScheduleCode) {
        var vm = this;

        vm.sectionTitle = i18nFilter('translations.schoolingSchedulePack_files_gui_title', schoolingNameAndScheduleCode);
        vm.lectures = schoolingSchedulePackLectures;

        vm.formData = {
            examApplicationStatus: examApplicationStatus
        };

        vm.localeMessages = {
            inspectTooltip: i18nFilter('translations.schoolingSchedule_gui_applications_openApplication'),
            finishSchoolingEmailPerLearnerTooltip: i18nFilter('translations.schoolingSchedule_gui_applications_sendFinishSchoolingEmailPerLearner'),
            editResultTooltip: i18nFilter('translations.examSchedulePack_applications_gui_action_edit_result'),
            documentsTooltip: i18nFilter('translations.examSchedulePack_applications_gui_action_documents')
        };







        
    }
})();
