(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('orderingHelper', date);

    date.$inject = ['logger', '$sessionStorage'];

    function date(logger, $sessionStorage) {
        var service = {
            findLowestOrderNo: findLowestOrderNo,
            findHighestOrderNo: findHighestOrderNo
        };

        return service;

        function findLowestOrderNo(list) {
            var lowest = 0;
            if (list !== undefined && list !== null && list.length !== 0) {
                lowest = list[0].orderNo;
                angular.forEach(list, function (e, index) {
                    if (e.orderNo < lowest) {
                        lowest = e.orderNo;
                    }
                });
            }
            return lowest;
        }

        function findHighestOrderNo(list) {
            var highest = 0;
            if (list !== undefined && list !== null && list.length !== 0) {
                {
                    highest = list[0].orderNo;
                    angular.forEach(list, function (e, index) {
                        if (e.orderNo > highest) {
                            highest = e.orderNo;
                        }
                    });
                }
            }
            return highest;
        }
    }
})();