(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('competencesCatalogFilter', {
            templateUrl: 'app/catalogs/filters/competences/competencesCatalogFilter.html',
            controller: competencesCatalogFilter,
            controllerAs: 'vm',
            bindings: {   
                data: '=',                
                onChanged: '='
            }
        });

    competencesCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function competencesCatalogFilter(logger, i18nFilter) {
        var vm = this;        

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.catalog_gui_competences_filter_placeholder")
        };
            
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 
    }
})();
