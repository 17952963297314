(function () {
    'use strict';

    angular
        .module('app.certificateSigner')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('certificateSigner', {
                parent: 'base',
                url: '/manage/certificateSignerTable',
                templateUrl: 'app/manage/certificateSigners/certificateSignerTable.html',
                controller: 'certificateSignerTable',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CertificateSigner', 'GlobalAdmin']
                }
            });
    }
})();
