(function () {
    'use strict';

    angular
        .module('app.unauthorized')
        .controller('Unauthorized', unauthorized);

    unauthorized.$inject = ['logger', '$stateParams', '$state', '$sessionStorage'];

    function unauthorized(logger, $stateParams, $state, $sessionStorage) {
        var vm = this;
        vm.requestedState = $state.get($stateParams.stateName);
        vm.showDetails = vm.requestedState !== undefined && vm.requestedState.data !== undefined && vm.requestedState.data.roles !== undefined;
        if (vm.showDetails) {
            vm.requestedRoles = vm.requestedState.data.roles;
            vm.currentRoles = $sessionStorage.userInfo.roles;
        }
    }
})();
