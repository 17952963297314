(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .component('schedulePackForm', {
            templateUrl: 'app/schedulePacks/components/schedulePackForm/schedulePackForm.component.html',
            controller: schedulePackForm,
            controllerAs: 'vm',
            bindings: {
                schedulePack: '=',
                categories: '=',
                currencies: '=',
                schedulePackType: '@'
            }
        });

    schedulePackForm.$inject = ['$scope', 'logger', 'exceptionHelper', 'i18nFilter', '$state', 'dateHelper', 'scheduleVisibility', 'scheduleExecutionType', 'pricesCategory', 'currencyCategory', 'organizationsDataService', 'programSchedulePackHelper', 'usersDataService', 'educationsDataService', 'schedulePacksDataService', 'surveyType'];

    function schedulePackForm($scope, logger, exceptionHelper, i18nFilter, $state, dateHelper, scheduleVisibility, scheduleExecutionType, pricesCategory, currencyCategory, organizationsDataService, programSchedulePackHelper, usersDataService, educationsDataService, schedulePacksDataService, surveyType) {
        var vm = this;

        vm.isEdit = $state.current.data.isEdit;
        vm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            noteForEmailNotification: i18nFilter("translations.schedule_packs_gui_note_for_email_notification_tooltip"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
        };

        vm.api = {
            checkIfFormIsValid: checkIfFormIsValid
        };

        vm.formData = {
            organizations: [],
            scheduleVisibility: scheduleVisibility,
            scheduleExecutionType: scheduleExecutionType,
            users: [],
            tinymceOptions: {
                plugins: 'advlist autolink lists image link imagetools charmap hr anchor searchreplace wordcount insertdatetime code nonbreaking emoticons paste textcolor colorpicker textpattern',
                toolbar1: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            surveyType: surveyType,
        };

        //#region Method binding
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.searchOrganizations = searchOrganizations;
        vm.setPrice = setPrice;
        vm.setGroupPrice = setGroupPrice;
        vm.organizationChanged = organizationChanged;
        vm.visibilityChanged = visibilityChanged;
        //#endregion
    
        vm.setParticipantSurveyValidity = setParticipantSurveyValidity;
        vm.setContractorSurveyValidity = setContractorSurveyValidity;
        vm.setCoordinatorSurveyValidity = setCoordinatorSurveyValidity;

        vm.isSurveyTypeEnabled = isSurveyTypeEnabled;
        
        vm.krpanFolderChange = krpanFolderChange;
        
        //#region Initial setup
        if (!vm.isEdit) {
            vm.schedulePack.visibility = vm.formData.scheduleVisibility.public;
            vm.schedulePack.executionType = vm.formData.scheduleExecutionType.normal,
            vm.schedulePack.checkinDays = 7;
            vm.schedulePack.checkoutDays = 3;
            vm.schedulePack.isSpecificPrice = false;
            vm.schedulePack.isGroupSpecificPrice = false;
            vm.schedulePack.onlyGroupApplicationsAllowed = false;
            vm.schedulePack.privateReservedSchedulePackDuration = null;

            vm.schedulePack.owners = undefined;
            var entityId;
            if (vm.schedulePack.educationId != undefined) {
                entityId = vm.schedulePack.educationId;
            }
            else {
                entityId = vm.schedulePack.programId;
            }
            schedulePacksDataService.programAndEducationOwners(entityId, vm.schedulePackType).then(function (data) {
                    vm.schedulePack.owners = data;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });

            if (vm.schedulePack.isSurveyEnabled){
                if (isSurveyTypeEnabled(vm.formData.surveyType.participant)) {
                    //only if Survey exists and we are Add-ing (NOT Edit-ing !!!)
                    vm.schedulePack.participantSurvey = true;       //turn on the checkbox by default
                    vm.schedulePack.participantSurveyValidity = 3;  //set the number to 3 days
                }
                else {
                    vm.schedulePack.participantSurveyValidity = 0;
                }
                vm.schedulePack.contractorSurveyValidity = 0;
                vm.schedulePack.coordinatorSurveyValidity = 0;
            }

        } else {
            if (vm.schedulePack.organizationName) {
                vm.formData.selectedOrganization = {
                    name: vm.schedulePack.organizationName,
                    id: vm.schedulePack.organizationId
                };
            }
            if (vm.schedulePack.price) {
                vm.schedulePack.isSpecificPrice = true;
                vm.schedulePack.price.amount = vm.schedulePack.price.amount.toString().replace('.', ',');
            }

            if (vm.schedulePack.groupPrice) {
                vm.schedulePack.isGroupSpecificPrice = true;
                vm.schedulePack.groupPrice.amount = vm.schedulePack.groupPrice.amount.toString().replace('.', ',');

            }
        }

        vm.initialCreateKrpanFolder = vm.schedulePack.createKrpanFolder;
        vm.initialKrpanFolder = "";

        if (vm.schedulePack.krpanFolder) {
            vm.initialKrpanFolder = vm.schedulePack.krpanFolder;
        }

        vm.showKrpanFolderInputWarning = false;

        //#endregion

        programSchedulePackHelper.registerDirective('pack', vm.api)

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective('pack', vm.api.id);
        });

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
                });
        }

        function clearOrganizationSelection() {
            vm.schedulePack.organizations = undefined;
        }

        function setGroupPrice() {
            if (vm.schedulePack.isGroupSpecificPrice) {
                if (!vm.schedulePack.groupPrice) {
                    vm.schedulePack.groupPrice = {
                        amount: 0,
                        category: {},
                        currency: {}
                    };

                    if (vm.categories) {
                        angular.forEach(vm.categories, function (category, Index) {
                            if (category.id === pricesCategory.groupPrice) {
                                vm.schedulePack.groupPrice.category = category;
                            }
                        });
                    }

                    if (vm.currencies) {
                        angular.forEach(vm.currencies, function (currency, Index) {
                            if (currency.id === currencyCategory.eur) {
                                vm.schedulePack.groupPrice.currency = currency;
                            }
                        });
                    }

                    vm.schedulePack.price = null;
                    vm.schedulePack.isSpecificPrice = false;
                }
            } else {
                vm.schedulePack.groupPrice = undefined;
            }
        }

        function setPrice() {
            if (vm.schedulePack.isSpecificPrice) {
                if (!vm.schedulePack.price) {
                    vm.schedulePack.price = {
                        amount: 0,
                        category: {},
                        currency: {}
                    };

                    if (vm.categories) {
                        angular.forEach(vm.categories, function (category, Index) {
                            if (category.id === pricesCategory.learnerPrice) {
                                vm.schedulePack.price.category = category;
                            }
                        });
                    }

                    if (vm.currencies) {
                        angular.forEach(vm.currencies, function (currency, Index) {
                            if (currency.id === currencyCategory.eur) {
                                vm.schedulePack.price.currency = currency;
                            }
                        });
                    }

                    vm.schedulePack.groupPrice = null;
                    vm.schedulePack.isGroupSpecificPrice = false;
                }
            } else {
                vm.schedulePack.price = undefined;
            }
        }

        function organizationChanged() {
            if (!vm.schedulePack.organizations || vm.schedulePack.organizations.length == 0) {
                vm.schedulePack.isGroupSpecificPrice = false;
                vm.schedulePack.groupPrice = null;
                vm.schedulePack.privateReservedSchedulePackDuration = null;
            }
            else if (vm.schedulePack.visibility == vm.formData.scheduleVisibility.private) {
                vm.schedulePack.privateReservedSchedulePackDuration = vm.schedulePack.educationActivitiesTotalDurationHour;
            }
        }

        function visibilityChanged() {
            if (vm.schedulePack.visibility == vm.formData.scheduleVisibility.public) {
                vm.schedulePack.privateReservedSchedulePackDuration = null;
            }
            else if (vm.schedulePack.organizations && vm.schedulePack.organizations.length > 0){
                vm.schedulePack.privateReservedSchedulePackDuration = vm.schedulePack.educationActivitiesTotalDurationHour;
            }
        }

        //#region API
        function checkIfFormIsValid() {
            $scope.schedulePackForm.checkinDays.$touched = true;
            $scope.schedulePackForm.checkoutDays.$touched = true;
            $scope.schedulePackForm.organizations.$touched = true;
            $scope.schedulePackForm.visibilityType.$touched = true;
            $scope.schedulePackForm.executionType.$touched = true;


            if ($scope.schedulePackForm.price) {
                $scope.schedulePackForm.price.$touched = true;
            }

            if ($scope.schedulePackForm.groupPrice) {
                $scope.schedulePackForm.groupPrice.$touched = true;
            }

            if (!vm.schedulePack.checkinDays && vm.schedulePack.checkinDays !== 0) {
                return false;
            }
            if (!vm.schedulePack.checkoutDays && vm.schedulePack.checkoutDays !== 0) {
                return false;
            }

            if (vm.schedulePack.privateReservedSchedulePackDuration == null && vm.schedulePack.organizations && vm.schedulePack.organizations.length > 0 && vm.schedulePack.visibility == vm.formData.scheduleVisibility.private) {
                return false;
            }

            if (!vm.schedulePack.isSurveyEnabled)
                return true;

            return $scope.schedulePackForm.$valid;
        }

        //#endregion

        // coordniators
        vm.refreshUsers = refreshUsers;
        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
            }, function (error) {
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function setParticipantSurveyValidity(){
            if (vm.schedulePack.participantSurvey){
                vm.schedulePack.participantSurveyValidity = 3;
            }
            else{
                vm.schedulePack.participantSurveyValidity = 0;
            }
        }

        function setContractorSurveyValidity(){
            if (vm.schedulePack.contractorSurvey){
                vm.schedulePack.contractorSurveyValidity = 3;
            }
            else{
                vm.schedulePack.contractorSurveyValidity = 0;
            }
        }

        function setCoordinatorSurveyValidity(){
            if (vm.schedulePack.coordinatorSurvey){
                vm.schedulePack.coordinatorSurveyValidity = 3;
            }
            else{
                vm.schedulePack.coordinatorSurveyValidity = 0;
            }
        }

        function isSurveyTypeEnabled(surveyType) {
            if (vm.schedulePack.isSurveyEnabled) {
                let count = 0;

                if (vm.schedulePack.spSurveyEducations && vm.schedulePack.spSurveyEducations.length > 0) {
                    vm.schedulePack.spSurveyEducations.forEach(survey => { 
                        if (surveyType === survey.surveyType)
                        count++;
                    });

                    if (count > 0)
                        return true;
                }
            }

            return false;
        }

        function krpanFolderChange() {
            //createKrpanFolder value should not change if its initial value was already true,
            //since the action can only be performed once

            if (!vm.initialCreateKrpanFolder) {
                if (vm.schedulePack.krpanFolder != null && vm.schedulePack.krpanFolder.length > 0) {
                    vm.schedulePack.createKrpanFolder = false;
                }
            }

            vm.showKrpanFolderInputWarning = vm.schedulePack.krpanFolder != vm.initialKrpanFolder;
        }
    }
})();
