(function () {
    'use strict';
    angular
        .module('app.schoolingSchedulePacks')
        .controller('UploadSpeakerFileDialog', UploadSpeakerFileDialog);

    UploadSpeakerFileDialog.$inject = ['$uibModalInstance', 'logger', 'exceptionHelper', 'i18nFilter', '$scope', 'parentScope', 'lecture', '$sessionStorage', 'isEdit', 'speakerFile'];

    function UploadSpeakerFileDialog($uibModalInstance, logger, exceptionHelper, i18nFilter, $scope, parentScope, lecture, $sessionStorage, isEdit, speakerFile) {
        var modalvm = this;

        modalvm.cancel = cancel;
        modalvm.parentScope = parentScope;
        modalvm.dots = false;
        if (lecture.speakers.length > 0) {
            let userId = $sessionStorage.userInfo.userId;

            let speakerId = lecture.speakers.filter(x => {
                return x.userId == userId;
            });

            modalvm.speakerId = speakerId.length == 1 ? speakerId[0].id : lecture.speakers[0].id;
        }



        modalvm.dzOptions = {
            url: isEdit && speakerFile ? "api/schoolings/reupload/" + speakerFile.id : "api/schoolings/upload/" + modalvm.speakerId,
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_frag_and_drop_default_message') + ' ' + i18nFilter('translations.schoolingSchedule_gui_label_fileUploadWarning'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message') + ' ' + i18nFilter('translations.schoolingSchedule_gui_label_fileUploadWarning'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '10',
            acceptedFiles: '.docx, .doc, .xlsx, .pptx, .ppt, .pdf, .odp, .pptm, .odt',
            addRemoveLinks: true,
            error: "Error",
            accept: function (file, done) {
                if (file.name.match(/\./g).length === 1) {
                    modalvm.dots = true;
                    done();

                }
                else {
                    modalvm.dots = false;
                    done("Cannot add file with dot in file name.");

                }
            }
        };

        modalvm.dzCallbacks = {

            'sending': function (file, xhr, formData) {
                formData.append('doNotsendEmailNorification', lecture.doNotsendEmailNorification);
                logger.log("File added to list: ", file);
            },
            'addedfile': function (file) {
                logger.log("File added to list: ", file);              
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                xhr.isEdit = isEdit && speakerFile;
                $uibModalInstance.close(xhr);
            },
            'removedfile': function (file) {
                //if (file.accepted) {
                    
                //}
            }
        };

        modalvm.dzMethods = {};

        function cancel() {
            $scope.$dismiss();
        }

    }
})();


