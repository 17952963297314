(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('applicationActionModal',applicationActionModal);

        applicationActionModal.$inject = ['logger', 'applicationData', '$scope', 'i18nFilter', 'schoolingApplicationsDataService', '$sanitize'];

    function applicationActionModal(logger, applicationData, $scope, i18nFilter, schoolingApplicationsDataService, $sanitize) {
        /* jshint validthis:true */
        var vm = this;

        vm.modalTitle = applicationData.modalTitle;
        vm.selectedPersons = applicationData.selectedPersons;
        vm.newApplicationStatus = applicationData.newApplicationStatus;
        vm.schoolingSchedulePackId = applicationData.schoolingSchedulePackId;
        vm.sendEmailNotification = applicationData.sendEmailNotification;
        vm.returnToPageIndex = applicationData.returnToPageIndex;
        vm.body = "";
        vm.cancel = cancel;
        vm.ok = ok;

        function ok() {
            var schoolingApplications = {
                schoolingApplicationsResponse: vm.selectedPersons,
                coordinatorApplicationChangeComment: DOMPurify.sanitize(vm.body),
                returnToPageIndex: vm.returnToPageIndex
            }

            angular.forEach(
                schoolingApplications.schoolingApplicationsResponse,
                function (learner, index) {
                    learner.schoolingSchedulePackId = vm.schoolingSchedulePackId;
                    learner.sendEmailNotification = vm.sendEmailNotification;
                }
            );
            
            schoolingApplicationsDataService.saveSchoolingApplicationsResponse(angular.copy(schoolingApplications), vm.newApplicationStatus).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedulePack_schooling_queue_update'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
            $scope.$dismiss();
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
