(function () {
    'use strict';

    angular
        .module('app.locations')
        .controller('locationCalendar', locationCalendar);

    locationCalendar.$inject = ['logger', 'calendarSchedules']; 

    function locationCalendar(logger, calendarSchedules) {
        var vm = this;
        
        var dataSource = new DevExpress.data.DataSource({
            store: calendarSchedules,
        });

        vm.schedulerOptions = {
            dataSource: dataSource,
            views: ["day", "week", "month"],
            currentView: "week",
            currentDate: new Date(),
            editing: false,
            allowAdding: false,
            allowDeleting: false,
            allowDragging: false,
            allowResizing: false,
            allowUpdating: false,
            showAllDayPanel: false,
            startDayHour: 7,
            width: "100%",
            height: 800
        };
    }
})();
