(function () {
    'use strict';

    angular
        .module('app.schoolings.sidebar.categories')
        .component('schoolingCategories', {
            templateUrl: 'app/schoolings/sidebars/categories/categories.html',
            controller: categoriesCtrl,
            controllerAs: 'vm',
            bindings: {
                schoolingId: '='
            }
        });

    categoriesCtrl.$inject = ['logger', 'exceptionHelper', 'schoolingsDataService'];

    function categoriesCtrl(logger, exceptionHelper, schoolingsDataService) {        
        var vm = this;

        vm.formData = {
            loading: true
        };

        //TODO: logic for showing

        
        schoolingsDataService.getSchoolingCategories(vm.schoolingId).then(function (data) {
            vm.categories = data.categories;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });
    }
})();




