(function () {
  "use strict";

  angular.module("app.analytics").component("educationTypeReport", {
    templateUrl:
      "app/analytics/components/educationTypeReport/educationTypeReport.component.html",
    controller: educationTypeReport,
    controllerAs: "vm",
  });

  educationTypeReport.$inject = [
    "$location",
    "logger",
    "i18nFilter",
    "exceptionHelper",
    "analyticsDataService",
  ];

  function educationTypeReport(
    $location,
    logger,
    i18nFilter,
    exceptionHelper,
    analyticsDataService
  ) {
    /* jshint validthis:true */
    var vm = this;

    vm.options = {
      educationType: {
        sortType: undefined,
        filterType1: undefined,
        searchText1: undefined,
        filterType2: undefined,
        searchText2: undefined,
      },
      participantNumber: {
        sortType: undefined,
        filterType1: undefined,
        searchText1: undefined,
        filterType2: undefined,
        searchText2: undefined,
      },
      activityNumber: {
        sortType: undefined,
        filterType1: undefined,
        searchText1: undefined,
        filterType2: undefined,
        searchText2: undefined,
      },
      participationsNumber: {
        sortType: undefined,
        filterType1: undefined,
        searchText1: undefined,
        filterType2: undefined,
        searchText2: undefined,
      },
      take: undefined,
      skip: undefined,
    };

    var columns = [
      {
        dataField: "educationType",
        dataType: "string",
        alignment: "left",
        allowSorting: true,
        sortOrder: "desc",
        caption: i18nFilter(
          "translations.analytics_gui_education_type_report_table_type_name"
        ),
      },
      {
        dataField: "participantNumber",
        dataType: "number",
        alignment: "center",
        allowSorting: true,
        sortOrder: "desc",
        filterOperations: ["=", "<", ">", "<=", ">=", "<>"],
        caption: i18nFilter(
          "translations.analytics_gui_education_type_report_table_number_of_participants"
        ),
        width: 145,
      },
      {
        dataField: "activityNumber",
        dataType: "number",
        alignment: "center",
        allowSorting: true,
        sortOrder: "desc",
        filterOperations: ["=", "<", ">", "<=", ">=", "<>"],
        caption: i18nFilter(
          "translations.analytics_gui_education_type_report_table_number_of_activities"
        ),
        width: 125,
      },
      {
        dataField: "participationsNumber",
        dataType: "number",
        alignment: "center",
        allowSorting: true,
        filterOperations: ["=", "<", ">", "<=", ">=", "<>"],
        sortOrder: "desc",
        caption: i18nFilter(
          "translations.analytics_gui_education_type_report_table_number_of_participations"
        ),
        width: 125,
      },
    ];

    function simpleFilterExtruder(element) {
      if (element[0] === "educationType") {
        vm.options.educationType.filterType1 = element[1];
        vm.options.educationType.searchText1 = element[2];
      }
      if (element[0] === "participantNumber") {
        vm.options.participantNumber.filterType1 = element[1];
        vm.options.participantNumber.searchText1 = element[2];
      }
      if (element[0] == "activityNumber") {
        vm.options.activityNumber.filterType1 = element[1];
        vm.options.activityNumber.searchText1 = element[2];
      }
      if (element[0] == "participationsNumber") {
        vm.options.participationsNumber.filterType1 = element[1];
        vm.options.participationsNumber.searchText1 = element[2];
      }
    }

    function clearFilter() {
      vm.options.educationType.filterType1 = undefined;
      vm.options.educationType.searchText1 = undefined;
      vm.options.educationType.filterType2 = undefined;
      vm.options.educationType.searchText2 = undefined;

      vm.options.participantNumber.filterType1 = undefined;
      vm.options.participantNumber.searchText1 = undefined;
      vm.options.participantNumber.filterType2 = undefined;
      vm.options.participantNumber.searchText2 = undefined;

      vm.options.activityNumber.filterType1 = undefined;
      vm.options.activityNumber.searchText1 = undefined;
      vm.options.activityNumber.filterType2 = undefined;
      vm.options.activityNumber.searchText2 = undefined;

      vm.options.participationsNumber.filterType1 = undefined;
      vm.options.participationsNumber.searchText1 = undefined;
      vm.options.participationsNumber.filterType2 = undefined;
      vm.options.participationsNumber.searchText2 = undefined;
    }

    var dataSource = {
      load: function (loadOptions) {
        var d = new $.Deferred();

        clearFilter();
        //Getting filter options
        if (loadOptions.filter) {
          if (loadOptions.filter.columnIndex !== undefined) {
            //Check for simple filter (string & integer)
            if (loadOptions.filter[0].constructor !== Array) {
              simpleFilterExtruder(loadOptions.filter);
            }
          } else {
            angular.forEach(
              loadOptions.filter,
              function (filterElement, filterIndex) {
                //if there is more than 1 filter
                if (filterElement.constructor === Array) {
                  //Check for simple filter (string & integer)
                  if (filterElement[0].constructor !== Array) {
                    simpleFilterExtruder(filterElement);
                  }
                }
              }
            );
          }
        }
        //Getting sort options
        if (loadOptions.sort) {
          vm.options.educationType.sortType = undefined;
          vm.options.participantNumber.sortType = undefined;
          vm.options.activityNumber.sortType = undefined;
          vm.options.participationsNumber.sortType = undefined;

          if (loadOptions.sort[0].selector === "educationType") {
            vm.options.educationType.sortType = loadOptions.sort[0].desc
              ? "desc"
              : "asc";
          }

          if (loadOptions.sort[0].selector === "participantNumber") {
            vm.options.participantNumber.sortType = loadOptions.sort[0].desc
              ? "desc"
              : "asc";
          }

          if (loadOptions.sort[0].selector === "activityNumber") {
            vm.options.activityNumber.sortType = loadOptions.sort[0].desc
              ? "desc"
              : "asc";
          }

          if (loadOptions.sort[0].selector === "participationsNumber") {
            vm.options.participationsNumber.sortType = loadOptions.sort[0].desc
              ? "desc"
              : "asc";
          }
        }

        //skip and take are used for paging
        vm.options.skip = loadOptions.skip; //A number of records that should be skipped
        vm.options.take = loadOptions.take; //A number of records that should be taken
        vm.options.searchOption = vm.searchOption;

        analyticsDataService
          .getEducationTypeReport(angular.copy(vm.options))
          .then(
            function (data) {
              d.resolve(data.data, {
                totalCount: data.totalItems,
              });
            },
            function (error) {
              logger.error(
                i18nFilter(
                  "translations.general_certificate_sign_service_not_available_exception"
                )
              );
              d.reject();
            }
          );

        return d.promise();
      },
    };

    vm.dataGridOptions = {
      dataSource: new DevExpress.data.DataSource(dataSource),
      columns: columns,
      filterRow: { visible: true },
      searchPanel: { visible: false },
      pager: { visible: true },
      paging: {
        pageSize: vm.options.itemsPerPage,
        showNavigationButtons: true,
      },
      onInitialized: function (e) {
        vm.gridInstance = e.component;
      },
      hoverStateEnabled: true,
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true,
      },
      columnAutoWidth: true,
      wordWrapEnabled: true,
      sorting: {
        mode: "single",
      },
    };
  }
})();
