(function () {
    'use strict';

    angular
        .module('app.queue')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('queue', {
                parent: 'base',
                url: '/manage/processQueue',
                templateUrl: 'app/manage/queue/processQueue.html',
                controller: 'processQueue',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['GlobalAdmin']
                }
            });
    }
})();
