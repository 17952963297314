(function () {
    'use strict';

    angular
        .module('app')
        .controller('instructorFeeHelp', instructorFeeHelp);

    instructorFeeHelp.$inject = ['$scope'];

    function instructorFeeHelp($scope) {
        var vm = this;
        
        vm.ok = ok;

        function ok() {
            $scope.$dismiss();
           
        };
    }
})();
