(function () {
    'use strict';

    angular
        .module('app.exams.scholarships')
        .controller('scholarships', scholarships);

    scholarships.$inject = ['scholarships', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'scholarshipsDataService'];

    function scholarships(scholarships, i18nFilter, dateFilter, exceptionHelper, logger, scholarshipsDataService) {
        /* jshint validthis:true */
        var vm = this;

        vm.scholarships = scholarships;
        vm.scholarships.forEach(function (scholarship) {
            scholarship.scholarshipHolderNameAndSurname = scholarship.scholarshipHolder.firstName + " " + scholarship.scholarshipHolder.lastName;
        });
        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        var columns = [
            {
                dataField: 'fileNumber',
                caption: i18nFilter('translations.exam_scholarships_edit_gui_label_FileNumber'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'scholarshipHolderNameAndSurname',
                caption: i18nFilter('translations.exam_scholarships_edit_gui_label_ScholarshipHolder'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'judicialAuthority',
                caption: i18nFilter('translations.exam_scholarships_edit_gui_label_JudicialAuthority'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'scholarshipsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.scholarships,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
