(function () {
    'use strict';

    angular
        .module('app.registration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('registration', {                
                url: '/registration',
                templateUrl: 'app/registration/registration.html',
                controller: 'registrationController',
                controllerAs: 'vm',
                requireADLogin: true
            })

            .state('registration.edit', {
                url: '/edit',
                templateUrl: 'app/profile/edit/edit.html',
                controller: 'EditUserController',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    type: 'profileEdit'
                },
                resolve: {
                    user: ['usersDataService', '$sessionStorage', function (usersDataService, $sessionStorage) {
                        return usersDataService.getUserById($sessionStorage.userInfo.userId);
                    }]
                }
            });
    }
})();