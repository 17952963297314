(function () {
    'use strict';

    angular
        .module('app.surveys.sidebar.surveyOwners')
        .component('surveyOwners', {
            templateUrl: 'app/surveys/sidebars/surveyOwners/surveyOwners.html',
            controller: surveyOwners,
            controllerAs: 'vm',
            bindings: {
                surveyId: '='
            }
        });


    surveyOwners.$inject = ['exceptionHelper', 'surveysDataService'];

    function surveyOwners(exceptionHelper, surveysDataService) {
        var vm = this;

        vm.owners = undefined;

        vm.formData = {
            loading: true
        };

        
        surveysDataService.getSurveyOwners(vm.surveyId).then(function (data) {
            vm.owners = data;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });
    }
})();
