(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamBaseStep', editExamBaseStep);

    editExamBaseStep.$inject = ['logger', 'editExamsHelper', '$stateParams', '$state', '$scope', 'exam'];

    function editExamBaseStep(logger, editExamsHelper, $stateParams, $state, $scope, exam) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = exam;
        vm.navigation = [];
        vm.containerStyle = {};
        editExamsHelper.clearData();
        if (vm.isEdit) {

            vm.containerStyle['margin-top'] = '-20px';
            vm.navigation = editExamsHelper.getWizardSteps($stateParams.id, vm.isEdit);
            editExamsHelper.setData(vm.item);
        }
        else {
            vm.containerStyle['margin'] = '10px';
            vm.navigation = editExamsHelper.getWizardSteps(null, vm.isEdit);
        }

        $scope.navigation = vm.navigation;

        vm.goToStep = function (navItem) {
            $state.go(navItem.state);
        }
    }
})();
