(function () {
    'use strict';

    angular
        .module('app.targetGroups')
        .controller('targetGroups', targetGroups);

    targetGroups.$inject = ['targetGroups', '$state', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'targetGroupsDataService', 'rolesDataService'];

    function targetGroups(targetGroups, $state, i18nFilter, dateFilter, exceptionHelper, logger, targetGroupsDataService, rolesDataService) {
        /* jshint validthis:true */
        var vm = this;
        vm.targetGroups = targetGroups;

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            targetSubgroupsTooltip: i18nFilter('translations.targetGroups_action_see_targetSubgroups')
        };

        vm.statusTypes = [
            {
                id: 0,
                format: i18nFilter('translations.general_status_deactiveted')
            },
            {
                id: 1,
                format: i18nFilter('translations.general_status_active')
            }
        ];

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'status',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 150,
                lookup: {
                    dataSource: vm.statusTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'targetGroupsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.targetGroups,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
