(function () {
    'use strict';

    angular
        .module('app.schoolings.sidebar.help')
        .component('schoolingHelp', {
            templateUrl: 'app/schoolings/sidebars/help/help.html',
            controller: helpController,
            controllerAs: 'vm',
            bindings: {
                schoolingId: '='
            }
        });

    helpController.$inject = ['logger', 'exceptionHelper', 'schoolingsDataService', 'i18nFilter','$uibModal'];

    function helpController(logger, exceptionHelper, schoolingsDataService, i18nFilter, $uibModal) {
        var vm = this;

        vm.formData = {
            loading: true
        };
        vm.contactUs = i18nFilter('translations.schooling_gui_sidebar_help_contact_us');       

        vm.sendMail = sendMail;

        function sendMail() {            
            $uibModal.open({
                templateUrl: 'app/schoolings/sidebars/help/emailForm/emailFormCip.html',
                controller: 'emailFormCtrlCip',
                controllerAs: 'vm',
                resolve: {
                    info: function () {
                        return vm.info;
                    }                
                }
            });            
        }

        schoolingsDataService.getSchoolingInfo(vm.schoolingId).then(function (data) {
            vm.info = data;            
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });


        schoolingsDataService.getSchoolingOwners(vm.schoolingId).then(function (data) {
            vm.owners = data.owners;
            vm.formData.loading = false;
            vm.to = '';
            for (var i = 0, len = vm.owners.length; i < len; i++) {
                if (vm.owners.length === 1) {
                    vm.to = vm.owners[i].email;
                    break;
                }
                if (i === vm.owners.length) {
                    vm.to += vm.owners[i].email;
                    break;
                }
                if (vm.owners[i].email !== null) {
                    vm.to += vm.owners[i].email + ', ';
                }
            }           
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        }); 
    }
})();
