(function () {
    'use strict';

    angular
        .module('app')
        .controller('variablesDialog', variablesDialog);

    variablesDialog.$inject = ['$scope', 'notificationVariables','i18nFilter'];

    function variablesDialog($scope, notificationVariables, i18nFilter) {
        var vm = this;
        vm.notificationVariables = notificationVariables;
        vm.ok = ok;

        var columns = [
            {
                dataField: 'variable',
                caption: i18nFilter('translations.email_gui_notification_notificationVariable'),
                alignment: 'left',
                cssClass: 'table-column-space-format wrapped-column',
                sortable: false
            },
            {
                dataField: 'description',
                caption: i18nFilter('translations.email_gui_notification_notificationVariable_description'),
                alignment: 'left',
                cssClass: 'table-column-space-format wrapped-column',
                sortable:false
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.notificationVariables,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function ok() {
            $scope.$dismiss();
           
        };
    }
})();
