(function () {
    'use strict';

    angular
        .module('app.educations')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('educations', {
            url: '/educations/{id}',
            parent: 'base',
            templateUrl: 'app/educations/educations.html',
            controller: 'Educations',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'EducationOwner', 'Instructor']
            },
            resolve: {
                isEducationOwner: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isCurrentUserRealEducationOwner($stateParams.id);
                }],
                isEducationInstructor: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isCurrentUserRealEducationInstructor($stateParams.id);
                }],
                isEnrolled: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isUserEnrolledInEducation($stateParams.id);
                }],
                education: ['educationsDataService', '$stateParams', function (educationsDataService, $stateParams) {
                    return educationsDataService.getEducationById($stateParams.id);
                }]
            }
        });
    }
})();