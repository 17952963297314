(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities')
        .controller('DeleteActivity', deleteActivity);

    deleteActivity.$inject = ['logger', 'activitiesDataService', '$stateParams', '$scope', 'exceptionHelper', 'i18nFilter'];

    function deleteActivity(logger, activitiesDataService, $stateParams, $scope, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.cancel = cancel;
        vm.ok = ok;
        vm.schoolingName = $stateParams.schoolingName;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            activitiesDataService.deleteActivity($stateParams.activityId).then(function (additionalData) {
                logger.success(i18nFilter('translations.activity_operation_delete_success_message', additionalData.activityName));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };

    }
})();
