(function () {
    'use strict';

    angular
        .module('app')
        .controller('deleteTemplate', deleteTemplate);

    deleteTemplate.$inject = ['logger', '$scope', 'templatesDataService', '$stateParams', 'exceptionHelper', 'i18nFilter'];

    function deleteTemplate(logger, $scope, templatesDataService, $stateParams, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.item = $stateParams.template;

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            templatesDataService.deleteTemplate($stateParams.templateId).then(function (result) {
                logger.success(i18nFilter("translations.manage_templates_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();
