(function () {
    'use strict';

    angular
        .module('app')
        .factory('competenceLevelsTemplateDataService', competenceLevelsTemplate);

    competenceLevelsTemplate.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', '$sessionStorage'];

    function competenceLevelsTemplate($http, logger, locale, $q, activityTypes, jsonHelper, $sessionStorage) {
        var service = {            
            getCompetenceLevelsTemplate: getCompetenceLevelsTemplate,
            addCompetenceLevelsTemplate: addCompetenceLevelsTemplate,
            editCompetenceLevelsTemplate: editCompetenceLevelsTemplate
        };

        return service;

        function getCompetenceLevelsTemplate() {
            return $http.get('api/competenceLevelsTemplate').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCompetenceLevelsTemplate(template) {
            return $http.post('api/competenceLevelsTemplate/', template).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCompetenceLevelsTemplate(template) {
            return $http.put('api/competenceLevelsTemplate/', template).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();