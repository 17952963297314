(function () {
    'use strict';

    angular
        .module('app.reports')
        .config(routeConfig);

    

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider, $http) {
        $stateProvider
            .state('reports', {
                parent: 'base',
                url: '/reports',
                templateUrl: 'app/reports/reports.html',
                controller: 'Reports',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    reports: ['reportsDataService', function (reportsDataService) {
                        return reportsDataService.getAllReports();
                    }]
                },
                data: {
                    roles: ['Learner']
                }
            })
            .state('reports.telerikReportDetails', {                
                url: '/details/:reportId',
                templateUrl: 'app/reports/telerikReportDetails/telerikReportDetails.html',
                controller: 'TelerikReportDetailscontroller',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    report: ['reportsDataService', '$stateParams', function (reportsDataService, $stateParams) {
                        return reportsDataService.getReportById($stateParams.reportId);
                    }]
                },
                data: {
                    roles: ['Learner']
                }
            });
    }

})();