(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('notificationHistory', notificationHistory);

    notificationHistory.$inject = ['dateFilter', 'notificationsDataService', 'i18nFilter', 'logger', 'notificationTemplatesTypes'];

    function notificationHistory(dateFilter, notificationsDataService, i18nFilter, logger, notificationTemplatesTypes) {
        /* jshint validthis:true */
        var vm = this;
        vm.details = i18nFilter('translations.general_gui_details');

        vm.notificationStatus = [
            {
                id: true,
                notificationStatusName: i18nFilter('translations.email_filter_send_notification_status_sent')
            },
            {
                id: false,
                notificationStatusName: i18nFilter('translations.email_filter_send_notification_status_error')
            }
        ];      
        
        vm.notificationTypes = [
            {
                id: notificationTemplatesTypes.N1_1,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_reminder_for_unfinished_education')
            },
            {
                id: notificationTemplatesTypes.N1_2,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_entry_notice')
            },
            {
                id: notificationTemplatesTypes.N1_3,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_cancellation_notice')
            },
            {
                id:  notificationTemplatesTypes.N1_4,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_period_change')
            },
            {
                id: notificationTemplatesTypes.N1_5,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notice_of_change_of_term')
            },
            {
                id: notificationTemplatesTypes.N1_6,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_term_registration_confirmation')
            },
            {
                id: notificationTemplatesTypes.N1_7,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notice_of_free_place_in_the_period_of_education')
            },
            {
                id: notificationTemplatesTypes.N1_8,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_schedule_reminder')
            },
            {
                id: notificationTemplatesTypes.N1_9,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_term_termination_notice')
            },
            {
                id: notificationTemplatesTypes.N1_10,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_additional_term_notice')
            },
            {
                id: notificationTemplatesTypes.N1_11,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_from_education_cancellation_notice')
            },
            {
                id: notificationTemplatesTypes.N1_12,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notice_of_term_assignment_to_the_lecturer')
            },
            {
                id: notificationTemplatesTypes.N1_13,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notice_of_registration_on_the_waiting_list')
            },
            {
                id: notificationTemplatesTypes.N1_14,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notification_of_completed_education')
            },
            {
                id: notificationTemplatesTypes.N1_15,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notification_of_the_need_to_verify_personal_information')
            },
            {
                id: notificationTemplatesTypes.N1_16,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notification_of_completion_of_the_e-exam_evaluation')
            },
            {
                id: notificationTemplatesTypes.N1_17,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_notification_of_seminar_confirmation')
            },
            {
                id: notificationTemplatesTypes.N3_2,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_entry_notice')
            },
            {
                id: notificationTemplatesTypes.N3_3,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_education_cancellation_notice')
            },
            {
                id: notificationTemplatesTypes.N3_11,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_from_education_cancellation_notice')
            },
            {
                id: notificationTemplatesTypes.N3_11,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_from_education_cancellation_notice')
            }, {
                id: notificationTemplatesTypes.N1_18,
                notificationTypeName: i18nFilter('translations.email_filter_send_notification_template_name_schooling_schedule_reminder')
            }
        ];
        
        vm.options = {   
            notificationType: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            to: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            successfullySent: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            lastSentAttempt: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },         
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'notificationType',
                caption: i18nFilter('translations.email_gui_notification_template_identifier'),
                alignment: 'left',
                sortOrder: 'asc',
                width: 150
            },
            {
                dataField: 'subject',
                caption: i18nFilter('translations.email_gui_notification_template_name'),
                alignment: 'left',
                sortOrder: 'asc'        
            },
            {
                dataField: 'to',
                caption: i18nFilter('translations.email_gui_reply_to_address'),
                width: 350,
                alignment: 'left',
                allowSorting: true
            },
            {
                dataField: 'successfullySent',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                width: 150,
                lookup: {
                    dataSource: vm.notificationStatus,
                    valueExpr: 'id',
                    displayExpr: 'notificationStatusName'
                }
            },            
            {
                dataField: 'lastSentAttempt',
                dataType: "date",
                alignment: 'left',
                width: 250,
                format: "dd.MM.yyyy",
                allowSorting: true,
                sortOrder: 'desc',
                sortIndex : 0,
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
                caption: i18nFilter('translations.general_gui_time')
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 250,
                cellTemplate: 'notificationHistoryAction'
            }
        ];

        function simpleFilterExtruder(element) {
            if (element[0] === "notificationType") {
                vm.options.notificationType.filterType1 = element[1];
                vm.options.notificationType.searchText1 = element[2];
            }
            if (element[0] === "to") {
                vm.options.to.filterType1 = element[1];
                vm.options.to.searchText1 = element[2];
            }
            if (element[0] === "successfullySent") {
                vm.options.successfullySent.filterType1 = element[1];
                vm.options.successfullySent.searchText1 = element[2];
            }
            if (element[0] === "lastSentAttempt") {
                vm.options.lastSentAttempt.filterType1 = element[1];
                vm.options.lastSentAttempt.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'notificationType' && element[2][0] === 'notificationType') {
                vm.options.notificationType.filterType1 = element[0][1];
                vm.options.notificationType.searchText1 = element[0][2];
                vm.options.notificationType.filterType2 = element[2][1];
                vm.options.notificationType.searchText2 = element[2][2];
            }
            if (element[0][0] === 'to' && element[2][0] === 'to') {
                vm.options.to.filterType1 = element[0][1];
                vm.options.to.searchText1 = element[0][2];
                vm.options.to.filterType2 = element[2][1];
                vm.options.to.searchText2 = element[2][2];
            }
            if (element[0][0] === 'successfullySent' && element[2][0] === 'successfullySent') {
                vm.options.successfullySent.filterType1 = element[0][1];
                vm.options.successfullySent.searchText1 = element[0][2];
                vm.options.successfullySent.filterType2 = element[2][1];
                vm.options.successfullySent.searchText2 = element[2][2];
            }
            if (element[0][0] === 'lastSentAttempt' && element[2][0] === 'lastSentAttempt') {
                vm.options.lastSentAttempt.filterType1 = element[0][1];
                vm.options.lastSentAttempt.searchText1 = element[0][2];
                vm.options.lastSentAttempt.filterType2 = element[2][1];
                vm.options.lastSentAttempt.searchText2 = element[2][2];
            }
        }

        function clearFilter() {
            vm.options.notificationType.filterType1 = undefined;
            vm.options.notificationType.searchText1 = undefined;
            vm.options.notificationType.filterType2 = undefined;
            vm.options.notificationType.searchText2 = undefined;

            vm.options.to.filterType1 = undefined;
            vm.options.to.searchText1 = undefined;
            vm.options.to.filterType2 = undefined;
            vm.options.to.searchText2 = undefined;

            vm.options.successfullySent.filterType1 = undefined;
            vm.options.successfullySent.searchText1 = undefined;
            vm.options.successfullySent.filterType2 = undefined;
            vm.options.successfullySent.searchText2 = undefined;

            vm.options.lastSentAttempt.filterType1 = undefined;
            vm.options.lastSentAttempt.searchText1 = undefined;
            vm.options.lastSentAttempt.filterType2 = undefined;
            vm.options.lastSentAttempt.searchText2 = undefined;
        }

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                        //Filter za datume
                        else {
                            dateFilterExtruder(loadOptions.filter);
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterExtruder(filterElement);
                                }
                                //Filter za datume
                                else {
                                    dateFilterExtruder(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.notificationType.sortType = undefined;
                    vm.options.to.sortType = undefined;
                    vm.options.successfullySent.sortType = undefined;
                    vm.options.lastSentAttempt.sortType = undefined;

                    if (loadOptions.sort[0].selector === "notificationType") {
                        vm.options.notificationType.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "to") {
                        vm.options.to.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "successfullySent") {
                        vm.options.successfullySent.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "lastSentAttempt") {
                        vm.options.lastSentAttempt.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }                    
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken

                logger.log("vm.options: ", vm.options);

                notificationsDataService.getNotificationsHistory(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    d.reject();
                });

                return d.promise();
            }
        };


        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();

