(function () {
    'use strict';

    angular
        .module('app')
        .factory('mailHelper', mailHelper);

    mailHelper.$inject = ['$http'];

    function mailHelper($http) {
        var service = {
            parseMail: parseMail
        };

        return service;

        function parseMail(data) {
            var to = data.to;
            var cc = data.cc;
            //var bcc = data.bcc;
               
            var arrayTo = to.split(';'); 
            var arrayCc = cc.split(';');
            //var arrayBcc = bcc.split(';');

            data.to = arrayTo;
            data.cc = arrayCc;
            //data.bcc = arrayBcc;

            return data;
        }
    }
})();