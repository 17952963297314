(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('copyActivityGrade', copyActivityGrade);

    copyActivityGrade.$inject = ['activityGradeDescriptionTypes', 'currentActivity', 'choices', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];

    function copyActivityGrade(activityGradeDescriptionTypes, currentActivity, choices, logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;

        vm.formData = {
            activityGradeDescriptionTypes: activityGradeDescriptionTypes
        };

        if (choices != null) {
            choices.forEach(function (choice, index) {
                if (choice.activityGradeDescription != null) {
                    var type = vm.formData.activityGradeDescriptionTypes.find(function (element) {
                        return element.id == choice.activityGradeDescription;
                    });
                    choice.activityGradeDescriptionName = type.format;
                }
            });
        }

        vm.currentActivity = currentActivity;
        vm.choices = choices;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(vm.chosenActivity);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
