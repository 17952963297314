(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('addIndependentExperts', addIndependentExperts);

    addIndependentExperts.$inject = ['commissionMembers', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];
    
    function addIndependentExperts(commissionMembers, logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;

        vm.item = {
            'commissionMembersThemes': commissionMembers
        };

        vm.commissionMemberIdToIndexDict = {}; //maps a given commission member to the index in the commission members array
        // for selection we take independent experts only
        // only those who haven't been added to the commission (active is false or undefined)
        vm.availableToChoose = vm.item.commissionMembersThemes.filter(function (member, index) {
            vm.commissionMemberIdToIndexDict[member.commissionMember.id] = index;
            //return member.themes.length === 0 && !member.commissionMember.active; // original code
            return member.isIndependentExpert && !member.commissionMember.active;
        });

        vm.formData = {};
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            let index = vm.commissionMemberIdToIndexDict[vm.choosenUser.commissionMember.id];
            vm.item.commissionMembersThemes[index].commissionMember.active = true;
            vm.item.commissionMembersThemes[index].chosenThemes = "";
            $scope.$close(vm.item);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
