(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('editCommissionMembers', editCommissionMembers);

    editCommissionMembers.$inject = ['existingCommissionMembers', 'chosenCommissionMember', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];
    
    function editCommissionMembers(existingCommissionMembers, chosenCommissionMember, logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        
        existingCommissionMembers.forEach(function (themeCommissionMemberVM, index) {
            if (themeCommissionMemberVM.themesCopy == null) {
                themeCommissionMemberVM.themesCopy = angular.copy(themeCommissionMemberVM.themes);
            }
        });
        vm.item = {
            'commissionMembersThemes': existingCommissionMembers
        };

        vm.commissionMemberIdsDict = {};
        vm.choosenUser = vm.item.commissionMembersThemes.filter(function (commissionMembersThemesVM, index) {
            vm.commissionMemberIdsDict[commissionMembersThemesVM.commissionMember.id] = index;
            return commissionMembersThemesVM.commissionMember.id == chosenCommissionMember.id;
        });
        vm.choosenUser = vm.choosenUser[0];
        
        vm.formData = {};
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            var tmp = "";
            var index = vm.commissionMemberIdsDict[vm.choosenUser.commissionMember.id];
            vm.item.commissionMembersThemes[index].themes.forEach(function (theme) {
                if (tmp != "") tmp += ", ";
                tmp += theme.name;
            });
            vm.item.commissionMembersThemes[index].chosenThemes = tmp;
            $scope.$close(vm.item);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
