(function () {
    'use strict';

    angular
        .module('app.unsupportedBrowser')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('unsupportedBrowser', {                
                requireADLogin: true,
                url: '/unsupportedBrowser',
                templateUrl: 'app/unsupportedBrowser/unsupportedBrowser.html'
            });
    }
})();