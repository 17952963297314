(function () {
    'use strict';

    angular
        .module('app.queue')
        .controller('processQueue', processQueue);

    processQueue.$inject = ['i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'queuesDataService', '$scope', '$state'];

    function processQueue(i18nFilter, dateFilter, exceptionHelper, logger, queuesDataService, $scope, $state) {

        var vm = this;

        vm.localeMessages = {
            requeue: i18nFilter('translations.manage_process_queue_requeue_action')
        };

        vm.requeue = requeue;
        vm.enableRequeue = enableRequeue;

        function requeue(queue) {
            var requeueData = queue.data;
            queuesDataService.reQueue(requeueData).then(function (data) {
                logger.success(i18nFilter('translations.manage_process_queue_requeue_requques'));
                $state.reload(true);
            }, function (error) {
                logger.error(i18nFilter('translations.manage_process_queue_requeue_error'));
                d.reject();
            });
        }

        function enableRequeue(data) {
            if (data.data.status == 1 || data.data.status == 3) {
                return true;
            }
            return false;
        }

        vm.options = {
            id: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            type: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            data: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            status: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            addedAt: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            startedProcessingAt: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            finisfedAt: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            errorMessage: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            errorStackTrace: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            userId: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            numberOfRetries: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'id',
                caption: "ID",
                alignment: 'left',
                width: 150,
                allowSorting: true
            },
            {
                dataField: 'type',
                caption: "Tip zahteve",
                alignment: 'left',
                width: 110,
                allowSorting: true
            },
            {
                dataField: 'data',
                caption: "Podrobnosti",
                alignment: 'left',
                width: 200,
                allowSorting: true,
            },
            {
                dataField: 'status',
                caption: "Status",
                alignment: 'left',
                width: 70,
                allowSorting: true
            },
            {
                dataField: 'addedAt',
                caption: "Datum nastanka",
                dataType: "date",
                alignment: 'left',
                width: 150,
                format: "dd.MM.yyyy",
                allowSorting: true,
                sortOrder: 'desc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
            },
            {
                dataField: 'startedProcessingAt',
                caption: "Začetek",
                dataType: "date",
                alignment: 'left',
                width: 150,
                format: "dd.MM.yyyy",
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
            },
            {
                dataField: 'finisfedAt',
                caption: "Konec",
                dataType: "date",
                alignment: 'left',
                width: 150,
                format: "dd.MM.yyyy",
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
            },
            {
                dataField: 'errorMessage',
                caption: "Napaka",
                alignment: 'left',
                width: 125,
                allowSorting: true
            },
            {
                dataField: 'errorStackTrace',
                caption: "Lokacija napake",
                alignment: 'left',
                width: 125,
                allowSorting: true
            },
            {
                dataField: 'userId',
                caption: i18nFilter('translations.general_gui_error_userId'),
                alignment: 'left',
                width: 150,
                allowSorting: true
            },
            {
                dataField: 'numberOfRetries',
                caption: "Št. poizkusov",
                alignment: 'left',
                width: 110,
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                width: 75,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];

        function simpleFilterExtruder(element) {

            if (element[0] == "id") {
                vm.options.id.filterType1 = element[1];
                vm.options.id.searchText1 = element[2];
            }
            if (element[0] == "status") {
                vm.options.status.filterType1 = element[1];
                vm.options.status.searchText1 = element[2];
            }
            if (element[0] == "data") {
                vm.options.data.filterType1 = element[1];
                vm.options.data.searchText1 = element[2];
            }
            if (element[0] == "type") {
                vm.options.type.filterType1 = element[1];
                vm.options.type.searchText1 = element[2];
            }
            if (element[0] == "addedAt") {
                vm.options.addedAt.filterType1 = element[1];
                vm.options.addedAt.searchText1 = element[2];
            }
            if (element[0] == "startedProcessingAt") {
                vm.options.startedProcessingAt.filterType1 = element[1];
                vm.options.startedProcessingAt.searchText1 = element[2];
            }
            if (element[0] == "finisfedAt") {
                vm.options.finisfedAt.filterType1 = element[1];
                vm.options.finisfedAt.searchText1 = element[2];
            }
            if (element[0] == "errorMessage") {
                vm.options.errorMessage.filterType1 = element[1];
                vm.options.errorMessage.searchText1 = element[2];
            }
            if (element[0] == "errorStackTrace") {
                vm.options.errorStackTrace.filterType1 = element[1];
                vm.options.errorStackTrace.searchText1 = element[2];
            }
            if (element[0] == "userId") {
                vm.options.userId.filterType1 = element[1];
                vm.options.userId.searchText1 = element[2];
            }
            if (element[0] == "numberOfRetries") {
                vm.options.numberOfRetries.filterType1 = element[1];
                vm.options.numberOfRetries.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'addedAt' && element[2][0] === 'addedAt') {
                vm.options.addedAt.filterType1 = element[0][1];
                vm.options.addedAt.searchText1 = element[0][2];
                vm.options.addedAt.filterType2 = element[2][1];
                vm.options.addedAt.searchText2 = element[2][2];
            }
            if (element[0][0] === 'startedProcessingAt' && element[2][0] === 'startedProcessingAt') {
                vm.options.startedProcessingAt.filterType1 = element[0][1];
                vm.options.startedProcessingAt.searchText1 = element[0][2];
                vm.options.startedProcessingAt.filterType2 = element[2][1];
                vm.options.startedProcessingAt.searchText2 = element[2][2];
            }
            if (element[0][0] === 'finisfedAt' && element[2][0] === 'finisfedAt') {
                vm.options.finisfedAt.filterType1 = element[0][1];
                vm.options.finisfedAt.searchText1 = element[0][2];
                vm.options.finisfedAt.filterType2 = element[2][1];
                vm.options.finisfedAt.searchText2 = element[2][2];
            }
        }

        function clearFilter() {

            vm.options.id.filterType1 = undefined;
            vm.options.id.searchText1 = undefined;
            vm.options.id.filterType2 = undefined;
            vm.options.id.searchText2 = undefined;

            vm.options.status.filterType1 = undefined;
            vm.options.status.searchText1 = undefined;
            vm.options.status.filterType2 = undefined;
            vm.options.status.searchText2 = undefined;

            vm.options.data.filterType1 = undefined;
            vm.options.data.searchText1 = undefined;
            vm.options.data.filterType2 = undefined;
            vm.options.data.searchText2 = undefined;

            vm.options.type.filterType1 = undefined;
            vm.options.type.searchText1 = undefined;
            vm.options.type.filterType2 = undefined;
            vm.options.type.searchText2 = undefined;

            vm.options.addedAt.filterType1 = undefined;
            vm.options.addedAt.searchText1 = undefined;
            vm.options.addedAt.filterType2 = undefined;
            vm.options.addedAt.searchText2 = undefined;

            vm.options.startedProcessingAt.filterType1 = undefined;
            vm.options.startedProcessingAt.searchText1 = undefined;
            vm.options.startedProcessingAt.filterType2 = undefined;
            vm.options.startedProcessingAt.searchText2 = undefined;

            vm.options.finisfedAt.filterType1 = undefined;
            vm.options.finisfedAt.searchText1 = undefined;
            vm.options.finisfedAt.filterType2 = undefined;
            vm.options.finisfedAt.searchText2 = undefined;

            vm.options.errorMessage.filterType1 = undefined;
            vm.options.errorMessage.searchText1 = undefined;
            vm.options.errorMessage.filterType2 = undefined;
            vm.options.errorMessage.searchText2 = undefined;

            vm.options.errorStackTrace.filterType1 = undefined;
            vm.options.errorStackTrace.searchText1 = undefined;
            vm.options.errorStackTrace.filterType2 = undefined;
            vm.options.errorStackTrace.searchText2 = undefined;

            vm.options.userId.filterType1 = undefined;
            vm.options.userId.searchText1 = undefined;
            vm.options.userId.filterType2 = undefined;
            vm.options.userId.searchText2 = undefined;

            vm.options.numberOfRetries.filterType1 = undefined;
            vm.options.numberOfRetries.searchText1 = undefined;
            vm.options.numberOfRetries.filterType2 = undefined;
            vm.options.numberOfRetries.searchText2 = undefined;
        }

        vm.data = [];

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                        //Filter za datume
                        else {
                            dateFilterExtruder(loadOptions.filter)
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterExtruder(filterElement);
                                }
                                //Filter za datume
                                else {
                                    dateFilterExtruder(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {

                    vm.options.id.sortType = undefined;
                    vm.options.status.sortType = undefined;
                    vm.options.data.sortType = undefined;
                    vm.options.type.sortType = undefined;
                    vm.options.addedAt.sortType = undefined;
                    vm.options.startedProcessingAt.sortType = undefined;
                    vm.options.finisfedAt.sortType = undefined;
                    vm.options.errorMessage.sortType = undefined;
                    vm.options.errorStackTrace.sortType = undefined;
                    vm.options.userId.sortType = undefined;
                    vm.options.numberOfRetries.sortType = undefined;

                    if (loadOptions.sort[0].selector == "id") {
                        vm.options.id.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "status") {
                        vm.options.status.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "data") {
                        vm.options.data.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "type") {
                        vm.options.type.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "addedAt") {
                        vm.options.addedAt.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "startedProcessingAt") {
                        vm.options.startedProcessingAt.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "finisfedAt") {
                        vm.options.finisfedAt.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "errorMessage") {
                        vm.options.errorMessage.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "errorStackTrace") {
                        vm.options.errorStackTrace.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "userId") {
                        vm.options.userId.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "numberOfRetries") {
                        vm.options.numberOfRetries.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken

                logger.log("vm.options: ", vm.options);

                queuesDataService.getQueues(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                    vm.data = data.data;
                }, function (error) {
                    logger.error(i18nFilter('translations.manage_process_queue_error_message_fetching'));
                    d.reject();
                });

                return d.promise();
            }
        };



        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            cellHintEnabled : true,
            hoverStateEnabled: true,
            wordWrapEnabled: false,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            sorting: {
                mode: 'single'
            },
            masterDetail: {
                enabled: true,
                template: 'detail',
            },
        };

        $scope.getDetailGridSettings = function (key) {
            return {
                dataSource: new DevExpress.data.DataSource({
                    store: new DevExpress.data.ArrayStore({
                        key: 'id',
                        data: vm.data,
                    }),
                    filter: ['id', '=', key.id],
                }),
                showBorders: true,
                wordWrapEnabled: true,
                columns: [
                    {
                        dataField: 'data',
                        caption: 'Podrobnosti',
                        width: 400
                    },
                    {
                        dataField: 'errorMessage',
                        caption: 'Napaka',
                        width: 400
                    },
                    {
                        dataField: 'errorStackTrace',
                        caption: 'Lokacija napake',
                        width: 400
                    }
                ],
            };
        };
    }
})();
