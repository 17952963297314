(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('copyCommissions', copyCommissions);

    copyCommissions.$inject = ['currentActivity', 'choices', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];

    function copyCommissions(currentActivity, choices, logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.currentActivity = currentActivity;
        vm.choices = choices;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(vm.chosenActivity.indexActivity);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
