(function () {
    'use strict';

    angular
        .module('app')
        .factory('organizationLevelsDataService', organizationLevelsCtrl);

    organizationLevelsCtrl.$inject = ['logger', '$http', 'i18nFilter', 'organizationLevels'];

    function organizationLevelsCtrl(logger, $http, i18nFilter, organizationLevels) {
        var service = {
            getAllOrganizationLevels: getAllOrganizationLevels
        };
        return service;

        function getAllOrganizationLevels() {
            return [
                {
                    id: organizationLevels.companyGroup,
                    name: i18nFilter('translations.filter_organization_level_company_group')
                },
                {
                    id: organizationLevels.company,
                    name: i18nFilter('translations.filter_organization_level_company')
                },
                {
                    id: organizationLevels.organizationUnit,
                    name: i18nFilter('translations.filter_organization_level_organization_unit')
                }
            ];            
        }        
    }
})();
