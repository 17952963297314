(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('questions', questions);

    questions.$inject = ['catalogue', 'questions', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'questionsDataService'];

    function questions(catalogue, questions, i18nFilter, dateFilter, exceptionHelper, logger, questionsDataService) {
        ///* jshint validthis:true */
        var vm = this;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.findLowestOrderNo = findLowestOrderNo;
        vm.findHighestOrderNo = findHighestOrderNo;

        vm.catalogueName = i18nFilter('translations.exam_questions_gui_title', catalogue.name);

        vm.questions = questions;
        vm.localeMessages2 = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down'),
        };

        vm.examQuestionTypes = [
            {
                id: 0,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_essay')
            },
            {
                id: 1,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_fillIn')
            },
            {
                id: 2,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_dropdown')
            },
            {
                id: 3,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_multipleChoice')
            },
            {
                id: 4,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_singleChoice')
            },
            {
                id: 5,
                format: i18nFilter('translations.exam_questions_edit_gui_label_question_type_connect')
            }
        ];

        var columns = [
            {
                dataField: 'definition',
                caption: i18nFilter('translations.exam_questions_gui_label_definition'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                encodeHtml: false
            },
            {
                dataField: 'type',
                caption: i18nFilter('translations.exam_questions_gui_label_type'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 350,
                lookup: {
                    dataSource: vm.examQuestionTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                dataField: 'points',
                caption: i18nFilter('translations.exam_questions_gui_label_points'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format',
                allowSorting: true,
                width: 150,
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'questionsActionTemplate'
            }
        ];

        vm.dataGridOptions2 = {
            dataSource: vm.questions,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function moveUp(id) {
            questionsDataService.moveUpQuestion(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_up'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function moveDown(id) {
            questionsDataService.moveDownQuestion(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_down'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function findLowestOrderNo() {
            var lowest = vm.questions[0].orderNo;
            angular.forEach(vm.questions, function (e, index) {
                if (e.orderNo < lowest) {
                    lowest = e.orderNo;
                }
            });
            return lowest;
        }

        function findHighestOrderNo() {
            var highest = vm.questions[0].orderNo;
            angular.forEach(vm.questions, function (e, index) {
                if (e.orderNo > highest) {
                    highest = e.orderNo;
                }
            });
            return highest;
        }
    }
})();
