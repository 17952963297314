(function () {
    'use strict';

    angular
        .module('app.exams.administration.delete')
        .controller('DeleteExam', deleteExam);

    deleteExam.$inject = ['logger', '$scope', '$stateParams', 'examsDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteExam(logger, $scope, $stateParams, examsDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        
        vm.cancel = cancel;
        vm.ok = ok;
        vm.examName = $stateParams.examName;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            examsDataService.deleteExam($stateParams.id).then(function (data) {
                logger.success(i18nFilter('translations.exam_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();
