(function () {
    'use strict';

    angular
        .module('app.analytics')
        .component('schedulesTable', {
            templateUrl: 'app/analytics/components/schedulesTable.component.html',
            controller: schedulesTable,
            controllerAs: 'vm'
        });

    schedulesTable.$inject = ['$state', '$scope', 'i18nFilter', 'schedulePacksUnlockedSearchOptions', 'eFormTypes', 'dateFilter'];

    function schedulesTable($state, $scope, i18nFilter, schedulePacksUnlockedSearchOptions, eFormTypes, dateFilter) {
        var vm = this;
        vm.formData = {
            searchOptionsUnlocked: {
                mine: schedulePacksUnlockedSearchOptions.mine,
                all: schedulePacksUnlockedSearchOptions.all
            },
            searchOptionsType: {
                education: eFormTypes.education
            }
        }

        vm.options = {
            startTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            endTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            visibility: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            orderNo: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            organizationName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            capacity: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            coordinators: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };

        var columnsUnlocked = [
            {
                dataField: 'name',
                dataType: "string",
                alignment: 'left',
                allowSorting: true,
                sortOrder: 'desc',
                caption: i18nFilter('translations.general_gui_educations_and_programs')
            },
            {
                dataField: 'startTime',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'endTime',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                caption: i18nFilter('translations.schedule_gui_label_visibility'),
                alignment: 'center',
                allowSorting: true,
                cellTemplate: 'schedulePackVisibilityTemplate',
                calculateCellValue: function (rowData) {
                    if (rowData.visibility === 'Public') {
                        return i18nFilter('translations.schedule_gui_label_visibility_public');
                    }
                    return i18nFilter('translations.schedule_gui_label_visibility_private');
                }
            },
            {
                dataField: 'organizationName',
                caption: i18nFilter('translations.schedule_gui_reserved_for'),
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true
            },
            {
                caption: i18nFilter('translations.schedule_packs_gui_capacity'),
                alignment: 'center',
                allowSorting: false,
                cellTemplate: 'capacityTemplate'
            },
            {
                dataField: 'coordinators',
                caption: i18nFilter('translations.general_gui_coordinators'),
                alignment: 'center',
                allowSorting: false,
            },
            {
                dataField: 'instructors',
                caption: i18nFilter('translations.general_gui_instructors'),
                alignment: 'center',
                allowSorting: false,
            },
        ];

        function onRowPrepared(e) {
            if (e.rowType !== "data")
                return;

            if (e.data.status == status.cancelled)
                e.rowElement[0].classList.add("disabledSchedulePack");
        }

        vm.unlockedDataGridOptions = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/SchedulePacksOData/GetSchedulePacks",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        var coordinatorOnly = vm.searchOptionUnlocked == vm.formData.searchOptionsUnlocked.mine;
                        e.url += "(coordinatorOnly=" + coordinatorOnly
                        e.url += ",all=" + false + ")";

                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            };
                        }
                    }
                }
            }),
            columns: columnsUnlocked,
            onRowPrepared: onRowPrepared,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstanceUnlocked = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            columnAutoWidth: true,
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            },
            customizeColumns: function (columns) {
                $.each(columns, function (index, column) {
                    var defaultCalculateFilterExpression = column.calculateFilterExpression;
                    column.calculateFilterExpression = function (value, selectedFilterOperation) {
                        if (this.dataType === 'string' && !this.lookup && value) {
                            return ['tolower(' + this.dataField + ')',
                            selectedFilterOperation || 'contains',
                            value.toLowerCase()]
                        }
                        else {
                            return defaultCalculateFilterExpression.apply(this, arguments);
                        }
                    }
                });
            }
        };
    }
})();
