(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('editLecture', editLecture);

    editLecture.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', '$uibModal', '$document', 'exceptionHelper', 'schoolingSchedulePacksDataService', 'SchoolingActivityScheduleInstructorRoles', 'lecture','paidInstructorRoles'];

    function editLecture(logger, $stateParams, $scope, i18nFilter, $uibModal, $document, exceptionHelper, schoolingSchedulePacksDataService, SchoolingActivityScheduleInstructorRoles, lecture,paidInstructorRoles) {
        var vm = this;

        vm.lecture = lecture;
        vm.lecture.name = "";

        vm.localeMessages = {
            boolYes: i18nFilter("translations.general_gui_yes"),
            boolNo: i18nFilter("translations.general_gui_no")
        };

        vm.fromConfig = {
            type: 'datetime',
            width: '100%',
            acceptCustomValue: true,
            inputAttr: {
                name: 'vm.lecture.from'
            },
            bindingOptions: {
                value: 'vm.lecture.from'
            }
        };
        vm.toConfig = {
            type: 'datetime',
            width: '100%',
            acceptCustomValue: true,
            inputAttr: {
                name: 'vm.lecture.to'
            },
            bindingOptions: {
                value: 'vm.lecture.to',
                min: 'vm.lecture.from'
            }
        };

        vm.addSpeaker = addSpeaker;
        vm.editSpeaker = editSpeaker;
        vm.removeSpeaker = removeSpeaker;

        vm.boolToString = boolToString;

        vm.ok = ok;
        vm.cancel = cancel;

        function addSpeaker() {
            let speakerIndex = vm.lecture.speakers.length;
            let speaker = {
                sortOrder: speakerIndex,
                duration: vm.lecture.duration
            };            
            editSpeaker(speaker,true);
        }
        function editSpeaker(speaker,adding) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/editLecture/editSpeaker/editSpeaker.html',
                controller: 'editSpeaker',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static', //disable closing modal by clicking on background, so you don't accidentally undo your changes
                resolve: {
                    // do a deep copy so the original speaker isn't changed until user clicks OK
                    speaker: angular.copy(speaker),
                    paidInstructorRoles: function () {
                        return paidInstructorRoles;
                    }
                }
            })
            .result.then(function (modifiedSpeaker) {
                // after clicking OK button
                if (adding)
                    vm.lecture.speakers.push(modifiedSpeaker);
                else
                    vm.lecture.speakers[modifiedSpeaker.sortOrder] = modifiedSpeaker;
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }
        function removeSpeaker(speakerIndex) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/editLecture/removeSpeaker/removeSpeaker.html',
                controller: 'removeSpeaker',
                controllerAs: 'vm',
                resolve: {
                    // do a deep copy so the original speaker isn't changed until user clicks OK
                    speaker: angular.copy(vm.lecture.speakers[speakerIndex])
                }
            })
            .result.then(function () {
                // after clicking OK button
                // remove lecture at given index
                vm.lecture.speakers.splice(speakerIndex, 1);

                // recalculate lectures sort order
                vm.lecture.speakers.forEach(function (speaker, index) {
                    speaker.sortOrder = index;
                });

            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function boolToString(value) {
            if (value) return vm.localeMessages.boolYes;
            else return vm.localeMessages.boolNo;
        }
        
        function ok() {
            $scope.$close(vm.lecture);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
