(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('addCommissionMembers', addCommissionMembers);

    addCommissionMembers.$inject = ['themesGroupedByCommissionMember', 'existingCommissionMembers', 'logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];
    
    function addCommissionMembers(themesGroupedByCommissionMember, existingCommissionMembers, logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;

        var isEdit = existingCommissionMembers != null && existingCommissionMembers.length > 0;
        if (isEdit) {
            existingCommissionMembers.forEach(function (themeCommissionMemberVM, index) {
                var found = false;
                for (var i = 0, len = themesGroupedByCommissionMember.length; i < len; i++) {
                    if (themesGroupedByCommissionMember[i].commissionMember.id == themeCommissionMemberVM.commissionMember.id) {
                        found = true;
                        themeCommissionMemberVM.themesCopy = angular.copy(themesGroupedByCommissionMember[i].themes);
                        break;
                    }
                    if (!found) {
                        themeCommissionMemberVM.themesCopy = angular.copy(themeCommissionMemberVM.themes);
                    }
                }
            });
            vm.item = {
                'commissionMembersThemes': existingCommissionMembers
            };
        } else {
            themesGroupedByCommissionMember.forEach(function (themeCommissionMemberVM, index) {
                themeCommissionMemberVM.themesCopy = angular.copy(themeCommissionMemberVM.themes);
            });
            vm.item = {
                'commissionMembersThemes': angular.copy(themesGroupedByCommissionMember)
            };
        }

        vm.commissionMemberIdsDict = {};
        vm.availableToChoose = vm.item.commissionMembersThemes.filter(function (commissionMembersThemesVM, index) {
            vm.commissionMemberIdsDict[commissionMembersThemesVM.commissionMember.id] = index;
            return !commissionMembersThemesVM.commissionMember.active && !commissionMembersThemesVM.isIndependentExpert;
        });

        vm.formData = {};
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            var tmp = "";
            var index = vm.commissionMemberIdsDict[vm.choosenUser.commissionMember.id];
            vm.item.commissionMembersThemes[index].commissionMember.active = true;
            vm.item.commissionMembersThemes[index].themes.forEach(function (theme) {
                if (tmp != "") tmp += ", ";
                tmp += theme.name;
            });
            vm.item.commissionMembersThemes[index].chosenThemes = tmp;
            vm.item.index = index;
            $scope.$close(vm.item);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
