(function () {
    'use strict';

    angular
        .module('app.tilesConfiguration')
        .controller('TilesConfiguration', tilesConfiguration);

    tilesConfiguration.$inject = ['logger', 'i18nFilter', 'tiles', 'tilesConfigurationDataService', 'exceptionHelper', '$state', '$stateParams'];

    function tilesConfiguration(logger, i18nFilter, tiles, tilesConfigurationDataService, exceptionHelper, $state, $stateParams) {
        var vm = this;
        vm.tiles = tiles; 
        vm.update = update;
        vm.localeMessages = {
            imageUrl: i18nFilter('translations.tiles_configuration_edit_success_message')
        };

        function update() {            
            return tilesConfigurationDataService.editTilesConfiguration(angular.copy(vm.tiles)).then(function (additionalData) {
                logger.success(i18nFilter('translations.tiles_configuration_queue_edit'));
            }, function (error) {
                exceptionHelper.handleException(error, false);
            });            
        }
    }
})();
