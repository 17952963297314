(function () {
    'use strict';

    angular
        .module('app.welcome')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('welcome', {
            url: '/',
            templateUrl: 'app/welcome/welcome.html',
            controller: 'Welcome',
            controllerAs: 'vm',
            resolve: {
                tenantResourceKey: ['tenantDataService', function (tenantDataService) {
                    return tenantDataService.getTenantResourceKey();
                }]
            }
        })
        .state('loading', {
            url: '/loading',
            templateUrl: 'app/welcome/loading.html',
            controller: 'Loading',
            controllerAs: 'vm'
        });
    }
})();
