(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schoolingScheduleBilling', schoolingScheduleBilling);

    schoolingScheduleBilling.$inject = [
        'SchoolingActivityScheduleInstructorRoles',
        'billing',
        'i18nFilter',
        '$state',
        'logger',
        'schoolingSchedulePacksDataService',
        'documentsDataService',
        '$stateParams'
    ];

    function schoolingScheduleBilling(
        SchoolingActivityScheduleInstructorRoles,
        billing,
        i18nFilter,
        $state,
        logger,
        schoolingSchedulePacksDataService,
        documentsDataService,
        $stateParams
    ) {
        var vm = this;

        vm.billing = billing;

        vm.roles = translateCollections(SchoolingActivityScheduleInstructorRoles);

        vm.moderators = [];

        vm.schoolingId = $stateParams.schoolingId;

        vm.generateZZIDocument = generateZZIDocument;
        vm.issueCertificateForUser = issueCertificateForUser;
        vm.regenerateCertificateForUser = regenerateCertificateForUser;
        vm.downloadCertificateForUser = downloadCertificateForUser;

        vm.issueCertificates = issueCertificates;
        vm.generateMonetaryString = generateMonetaryString;

        vm.localeMessages = {
            tooltipZZI: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_tooltip_ZZI'),
            tooltipCertificate: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_tooltip_certificate'),
            tooltipDownloadCertificate: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_tooltip_download_certificate'),
            tooltipRegenerateCertificate: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_tooltip_regenerate_certificate'),
        };

        vm.indirectCostsDataSource = new DevExpress.data.DataSource(vm.billing.indirectCostsData);

        vm.directCostsDataSource = new DevExpress.data.DataSource(vm.billing.directCostsData);
        
        var columns = [
            {
                dataField: 'speakerName',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_name'),
                allowEditing: false
            },
            {
                dataField: 'lectureName',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_conference_name'),
                allowEditing: false
            },
            {
                dataField: 'role',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_execution_type'),
                allowEditing: false
            },
            {
                dataField: 'duration',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_hours'),
                calculateCellValue: function (rowData) {
                    return rowData.duration.toString().replace('.', ',');
                }
            },
            {
                dataField: 'foreignSpeaker',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_foreign_lecturer')
            },
            {
                dataField: 'payment',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_payment')
            },
            {
                dataField: 'content',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_content_ready')
            },
            {
                dataField: 'executed',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_executed')
            },
            {
                dataField: 'costs',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_direct_costs_table_column_cost'),
                allowEditing: false,
                format: 'fixedPoint',
                precision: 2
            }
        ];
        
        vm.dataGridOptions = {
            dataSource: vm.directCostsDataSource,
            columns: columns,
            keyExpr: "Id",
            editing: {
                mode: "row",
                allowUpdating: true,
                allowDeleting: false,
                allowAdding: false
            },
            filterRow: { visible: false },
            searchPanel: { visible: false },
            paging: {
                enabled: true,
                pageSize: 10
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            columnAutoWidth: true,
            wordWrapEnabled: true,
            onRowUpdated: function (e) {
                e.key.duration = parseFloat(e.key.duration.toString().replace(",", "."));
                var saveItem = {
                    userId: e.key.userId,
                    speakerId: e.key.speakerId,
                    scheduleId: e.key.scheduleId,
                    duration: e.key.duration,
                    executed: e.key.executed,
                    payment: e.key.payment,
                    content: e.key.content,
                    foreignSpeaker: e.key.foreignSpeaker,
                    cost: e.key.costs
                };
                schoolingSchedulePacksDataService.saveSchoolingScheduleBillingPack(saveItem).then(function (data) {
                    var changedItem = vm.directCostsDataSource._items.find(item => item.userId === e.key.userId && item.speakerId === e.key.speakerId);
                    if (changedItem) {
                        if (changedItem.speakerId !== null) {
                            //calculate speaker costs
                            if (!e.key.payment || !e.key.executed) {
                                e.key.costs = 0;
                            }
                            else {
                                var durInHours = (Math.round(e.key.duration * 100) / 100) / 45;
                                var baseCost = durInHours * e.key.hourlyRate;
                                if (e.key.foreignSpeaker) {
                                    baseCost = baseCost * 1.2;
                                }
                                if (!e.key.content) {
                                    baseCost = baseCost * 0.9;
                                }
                                e.key.costs = Math.round(baseCost * 100) / 100; //not ceil
                            }
                        }
                        else {
                            //calculate moderator costs
                            e.key.costs = Math.ceil(e.key.duration / 240) * e.key.hourlyRate;   //ceil
                        }

                        if (changedItem.edited === false) {
                            e.key.speakerName = e.key.speakerName + ' *';
                            e.key.edited = true;
                        }
                    }
                    recalculateIndirectData(e.key.speakerId);
                    //logger.success(i18nFilter('translations.schedule_operation_add_success_message'));
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        };

        //indirect costs table
        vm.billing.directCostsData.forEach(function (speaker) {
            speaker.role = vm.roles[speaker.role].name;
        });

        var columnsIndirect = [
            {
                dataField: 'userName',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_name'),
                allowEditing: false
            },
            {
                dataField: 'travelCosts',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_travel_costs'),
                allowEditing: true,
                format: 'fixedPoint',
                precision: 2
            },
            {
                dataField: 'accommodationCosts',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_accommodation_costs'),
                allowEditing: true,
                format: 'fixedPoint',
                precision: 2
            },
            {
                dataField: 'otherCosts',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_other_costs'),
                allowEditing: true,
                format: 'fixedPoint',
                precision: 2
            },
            {
                dataField: 'executionCosts',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_execution_costs'),
                allowEditing: false,
                format: 'fixedPoint',
                precision: 2
            },
            {
                dataField: 'grossCost',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_gross_costs'),
                allowEditing: true,
                format: 'fixedPoint',
                precision: 2
            },
            {
                dataField: 'certificateIssued',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_certificate_issued'),
                allowEditing: false
            },
            {
                dataField: 'certificateIssuedDate',
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_certificate_issued_date'),
                allowEditing: false,
                dataType: 'datetime',
                alignment: 'center'
            },
            {
                caption: i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_table_column_action'),
                cellTemplate: 'actionCellTemplate',
                width: 110,
                alignment: 'center'
            }
        ];

        vm.dataGridOptionsIndirect = {
            dataSource: vm.indirectCostsDataSource,
            columns: columnsIndirect,
            keyExpr: "Id",
            editing: {
                mode: "row",
                allowUpdating: true,
                allowDeleting: false,
                allowAdding: false
            },
            filterRow: { visible: false },
            searchPanel: { visible: false },
            paging: {
                enabled: true,
                pageSize: 10
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            columnAutoWidth: true,
            wordWrapEnabled: true,
            onRowUpdated: function (e) {
                e.key.travelCosts = parseFloat(e.key.travelCosts.toString().replace(",", "."));
                e.key.accommodationCosts = parseFloat(e.key.accommodationCosts.toString().replace(",", "."));
                e.key.otherCosts = parseFloat(e.key.otherCosts.toString().replace(",", "."));
                e.key.executionCosts = parseFloat(e.key.executionCosts.toString().replace(",", "."));
                e.key.grossCost = parseFloat(e.key.grossCost.toString().replace(",", "."));
                schoolingSchedulePacksDataService.saveSchoolingScheduleIndirectBillingPack(e.key).then(function (data) {
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        };

        function recalculateIndirectData(speakerId) {
            var itemsToChange = vm.dataGridOptionsIndirect.dataSource._items;

            var sum = 0;
            itemsToChange.forEach(item => {
                var directCosts = vm.dataGridOptions.dataSource._items.filter(x => x.userId === item.userId);
                sum = 0;
                for (var i = 0; i < directCosts.length; i++) {
                    sum = sum + directCosts[i].costs;
                }
                item.executionCosts = sum;
            });
            vm.dataGridOptionsIndirect.dataSource._items = itemsToChange;

            for (var i = 0; i < vm.billing.indirectCostsData.length; i++) {
                if (vm.billing.indirectCostsData[i].userId == speakerId) {
                    vm.billing.indirectCostsData[i].executionCosts = sum;
                }
            }
        }

        function generateZZIDocument(userId) {
            var len = vm.billing.indirectCostsData.length;

            for (var i = 0; i < len; i++) {
                var indirectCosts = vm.billing.indirectCostsData[i];

                if (indirectCosts.userId == userId) {
                    // Object already filled with relevant data for updating.
                    // Ready for 'saveSchoolingScheduleIndirectBillingPack' method to update the DB value before generating ZZI.

                    schoolingSchedulePacksDataService.saveSchoolingScheduleIndirectBillingPack(indirectCosts).then(function (data) {
                        // Existing code for generating ZZI document

                        if (vm.billing.schoolingHasZZITemplate === false) {
                            $("#noTemplateAlert").removeClass("hidden");
                            $(".closeAlert").click(function () {
                                $("#noTemplateAlert").alert("close");
                            });
                        }
                        else {
                            documentsDataService.downloadDocument('/api/schoolingSchedulePacks/billing/zzi/' + $stateParams.schoolingId + '/' + $stateParams.schoolingScheduleId + '/' + userId, i18nFilter('translations.schoolingSchedule_gui_billing_indirect_costs_ZZI_fileName') + '.xlsx').then(function (data) {
                            }, function (error) {
                                error = {
                                    exception: {
                                        additionalData: {}
                                    },
                                    status: 400
                                };
                                exceptionHelper.handleException(error);
                            });
                        }

                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                }
            }
        }

        function issueCertificateForUser(scheduleId, userId) {
            schoolingSchedulePacksDataService.issueSchoolingCertificateForUser(scheduleId, userId).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedule_gui_billing_finish_certificate_message'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function issueCertificates() {
            schoolingSchedulePacksDataService.issueSchoolingCertificates($stateParams.schoolingScheduleId).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedule_gui_billing_finish_certificate_message'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function regenerateCertificateForUser(scheduleId, userId) {
            schoolingSchedulePacksDataService.regenerateSchoolingCertificateForUser(scheduleId, userId).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedule_gui_billing_regenerate_certificate_message'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function downloadCertificateForUser(scheduleId, userId) {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/downloadUserCertificate/' + scheduleId + '/' + userId, i18nFilter('translations.schoolingSchedule_gui_billing_certificate_name') + '.pdf');
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });
            return data;
        }

        function generateMonetaryString(amount) {
            return amount != null ? parseFloat(Math.round(amount * 100) / 100).toFixed(2).replace('.', ',') : "";
        }
        
    }
})();
