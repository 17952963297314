(function () {
    'use strict';

    angular
        .module('app.exams.bulletin')
        .controller('editBulletinsController', editBulletinsController);

    editBulletinsController.$inject = ['decimalHelper', '$state', 'i18nFilter', 'bulletin', 'bulletinsDataService', 'exceptionHelper', 'logger'];

    function editBulletinsController(decimalHelper, $state, i18nFilter, bulletin, bulletinsDataService, exceptionHelper, logger) {
        var vm = this;
        vm.item = bulletin;
        vm.isEdit = $state.current.data.isEdit;
        
        vm.add = add;
        vm.save = save;

        vm.form = {
            publishedOn: {
                type: 'date',
                width: '100%',
                inputAttr: {
                    'id': 'publishedOn',
                    'name': 'publishedOn',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.publishedOn'
                },
                placeholder: i18nFilter('translations.exam_bulletin_edit_gui_published_on')
            }
        }

        function add() {
            bulletinsDataService.addBulletin(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_bulletin_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            bulletinsDataService.editBulletin(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_bulletin_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
