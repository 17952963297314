(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('uploadAttachment', uploadAttachment);

    uploadAttachment.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', '$http', '$q'];

    function uploadAttachment(logger, $stateParams, $scope, i18nFilter, exceptionHelper, $http, $q) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;

        vm.fileId = undefined;

        vm.dzOptions = {
            url: "api/documentsDb/upload",
            method: "post",
            dictDefaultMessage: i18nFilter('translations.general_gui_notifications_attachment_file_default_message'),
            dictFallbackMessage: i18nFilter('translations.general_gui_frag_and_drop_fallback_message'),
            dictRemoveFile: i18nFilter('translations.general_gui_frag_and_drop_remove_file'),
            dictResponseError: i18nFilter('translations.general_gui_frag_and_drop_response_error'),
            dictMaxFilesExceeded: i18nFilter('translations.general_gui_frag_and_drop_max_files_exceeded'),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: '5',
            acceptedFiles: '.docx, .doc, .pdf',
            addRemoveLinks: true,
        };

        vm.dzCallbacks = {
            'addedfile': function (file) {
                logger.log("File added to list: ", file);
            },
            'success': function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.fileId = id;
                vm.fileName = file.name;
            },
            'removedfile': function (file) {
                vm.fileId = undefined;
            }
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        }


        function ok() {
            $scope.$close({ id: vm.fileId, name: vm.fileName }); 
        }

        function cancel() {
            if (vm.fileId) {
                $http.delete('api/documentsDb/delete/' + vm.fileId, null).then(function (data) {
                    $scope.$dismiss();
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
            }

            else {
                $scope.$dismiss();
            }
        }
    }
})();
