(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('cataloguesDataService', catalogues);

    catalogues.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function catalogues($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllCatalogues: getAllCatalogues,
            getAutomaticCatalogues: getAutomaticCatalogues,
            getManualCatalogues: getManualCatalogues,
            getCatalogueById: getCatalogueById,
            addCatalogue: addCatalogue,
            editCatalogue: editCatalogue,
            deleteCatalogue: deleteCatalogue,
            getYearsFromCataloguesPlans: getYearsFromCataloguesPlans,
            copyCatalogues: copyCatalogues
        };

        return service;

        function getAllCatalogues() {
            return $http.get('api/catalogues').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function copyCatalogues(catalogue) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(catalogue));
            return $http.post('api/catalogues/copy/' + catalogue.id, catalogue.id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAutomaticCatalogues() {
            return $http.get('api/catalogues/automatic').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getManualCatalogues() {
            return $http.get('api/catalogues/manual').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCatalogueById(id) {
            return $http.get('api/catalogues/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCatalogue(catalogue) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(catalogue));
            return $http.post('api/catalogues', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCatalogue(catalogue) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(catalogue));
            return $http.put('api/catalogues', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteCatalogue(id) {
            return $http.delete('api/catalogues/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getYearsFromCataloguesPlans() {
            return $http.get('api/catalogues/yearsFromCataloguesPlans').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
