(function () {
    'use strict';

    angular
        .module('app')
        .controller('SchoolingDocumentsFileSystemRename', rename);

    rename.$inject = ['logger', '$scope', 'exceptionHelper', 'documentsDataService', 'oldName', 'path'];

    function rename(logger, $scope, exceptionHelper, documentsDataService, oldName, path) {
        var vm = this;        
        
        vm.cancel = cancel;
        vm.ok = ok;
        vm.params = {
            path: path,
            oldName: oldName
        };
        vm.newName = vm.params.oldName;
        
        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            documentsDataService.renameItem(vm.params.path, vm.newName).then(function (data) {
                $scope.$close(true);
            }, function (error) {                
                exceptionHelper.handleException(error);
            });
        };
    }
})();
