(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('applyCandidate', applyCandidate);

    applyCandidate.$inject = ['examSchedules','logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper'];

    function applyCandidate(examSchedules,logger, $stateParams, $scope, i18nFilter, exceptionHelper) {
        var vm = this;

        vm.ok = ok;
        vm.cancel = cancel;

        vm.choices = examSchedules;

        function ok() {
            $scope.$close(vm.chosenSchedule.id);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
