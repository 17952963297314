(function () {
    'use strict';

    angular
        .module('app.certificates')
        .controller('Templates', templates);

    templates.$inject = ['logger', 'templates', 'i18nFilter', '$state'];

    function templates(logger, templates, i18nFilter, $state) {
        var vm = this;
        
        vm.localeMessagess = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')            
        };

        vm.formData = {
            templates: templates
        };

        vm.deleteTemplate = deleteTemplate;
        //#region table
        var columns = [
            {
                dataField: 'id',
                caption: i18nFilter('translations.general_gui_id'),
                alignment: 'left',
                allowSorting: true
            },
            {
                dataField: 'fileName',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                sortOrder: 'asc',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'templatesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.formData.templates,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        function deleteTemplate(template) {
            $state.go('templates.delete', { templateId: template.id, template: template });
        }

       
    }
})();
