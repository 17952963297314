(function () {
    'use strict';

    angular
        .module('app.exams.sidebar.contact')
        .component('examContact', {
            templateUrl: 'app/exams/sidebars/contact/contact.html',
            controller: contact,
            controllerAs: 'vm',
            bindings: {
                examId: '='
            }
        });

    contact.$inject = ['logger', 'exceptionHelper', 'examsDataService'];

    function contact(logger, exceptionHelper, examsDataService) {        
        var vm = this;

        vm.formData = {
            loading: true
        };

        //TODO: logic for showing


        examsDataService.getExamContactPersons(vm.examId).then(function (data) {
            vm.contactPersons = data.contactPersons;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });       
    }
})();
