(function () {
    'use strict';

    angular
        .module('app.learningPeriod')
        .controller('LearningPeriods', LearningPeriods);

    LearningPeriods.$inject = ['dateFilter', 'logger', 'periodsDataService', '$state', 'exceptionHelper', 'i18nFilter'];

    function LearningPeriods(dateFilter, logger, periodsDataService, $state, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.dtOptions = {};
        vm.periods = [];
        vm.reloadData = reloadData;
        vm.makeDefualt = makeDefualt;
        vm.findLowestOrderNo = findLowestOrderNo;
        vm.findHighestOrderNo = findHighestOrderNo;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        
        reloadData();

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit'),
            moveUp: i18nFilter('translations.general_action_move_up'),
            moveDown: i18nFilter('translations.general_action_move_down'),
            setAsCurrent: i18nFilter('translations.learning_period_gui_set_as_current_period')
        };

        //#region table
        var columns = [
            {
                dataField: 'orderNo',
                caption: i18nFilter('translations.general_gui_order'),
                alignment: 'left',
                sortOrder: 'asc',
                width: 100,
                allowSorting: true
            },
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',                
                allowSorting: true
            },
            {
                dataField: 'startDate',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start_date')
            },
            {
                dataField: 'dueDate',
                dataType: "date",
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end_date')
            },
            {
                dataField: 'isDefault',                
                caption: i18nFilter('translations.learning_period_gui_default_period'),
                alignment: 'center',
                width: 200,
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                width: 200,
                cellTemplate: 'periodActionTemplate'
            }            
        ];

        vm.dataGridOptions = {
            bindingOptions: {
                dataSource: 'vm.periods'
            },
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

       

        function moveUp(id) {
            periodsDataService.moveUp(id).then(function (data) {
                $state.reload();
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function moveDown(id) {
            periodsDataService.moveDown(id).then(function (data) {
                $state.reload();
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function findLowestOrderNo() {
            var lowest = vm.periods[0].orderNo;
            angular.forEach(vm.periods, function (e, index) {
                if (e.orderNo < lowest) {
                    lowest = e.orderNo;
                }
            });
            return lowest;
        }

        function findHighestOrderNo() {
            var highest = vm.periods[0].orderNo;
            angular.forEach(vm.periods, function (e, index) {
                if (e.orderNo > highest) {
                    highest = e.orderNo;
                }
            });
            return highest;
        }

        function reloadData() {
            periodsDataService.getAllLearningPeriods().then(function (data) {
                vm.periods = data;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
        
        function makeDefualt(id) {
            periodsDataService.makeDefualt(id).then(function (data) {
                logger.success(i18nFilter("translations.learning_period_queue_set_default_period"));
                reloadData();
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
