(function () {
    'use strict';

    angular
        .module('app.exams.bulletinSubscriptions')
        .controller('bulletinSubscriptionsController', bulletinSubscriptionsController);

    bulletinSubscriptionsController.$inject = ['i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'bulletins'];

    function bulletinSubscriptionsController(i18nFilter, dateFilter, exceptionHelper, logger, bulletins) {
        var vm = this;

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            selectPlaceholder: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_bulletin'),
        };

        vm.formData = {};
        vm.formData.bulletins = [{
            id: '00000000-0000-0000-0000-000000000000',
            code: '/'
        }].concat(bulletins);
        vm.formData.bulletin = {
            id: '00000000-0000-0000-0000-000000000000',
            code: '/'
        };

        var columns = [
            {
                dataField: 'subscriberName',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscriber_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                sortOrder: 'asc',
            },
            {
                dataField: 'subscriberAddress',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscriber_address'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'subscriberPost',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscriber_post'),
                alignment: 'left',
                width: 200,
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'numberOfCopies',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_numberOfCopies'),
                alignment: 'left',
                width: 150,
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'reference',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_reference'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'subscriberTaxNumber',
                caption: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscriber_taxno'),
                alignment: 'left',
                width: 100,
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 100,
                allowSorting: false,
                cellTemplate: 'bulletinSubscriptionActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/BulletinSubscriptionOData/GetBulletinSubscriptions",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        e.url += "(bulletinId=" + vm.formData.bulletin.id + ")"

                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            }
                        }
                    }
                },
            }),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: undefined,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        vm.refreshTable = function () {
            if (vm.gridInstance)
                vm.gridInstance.refresh();
        }
    }
})();
