(function () {
    'use strict';

    angular.module('blocks.router', [
        // Angular modules 

        // 3rd Party Modules
        'ui.router',

        // Custom modules 
        'blocks.logger'
    ]);
})();