(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('targetGroupsDataService', targetGroups);

    targetGroups.$inject = ['$http', 'logger', 'locale', '$q', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function targetGroups($http, logger, locale, $q, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllTargetGroups: getAllTargetGroups,
            getTargetGroupById: getTargetGroupById,
            addTargetGroup: addTargetGroup,
            editTargetGroup: editTargetGroup,
            deleteTargetGroup: deleteTargetGroup,

            getAllTargetSubgroups: getAllTargetSubgroups,
            getTargetSubgroupsByParentGroupId: getTargetSubgroupsByParentGroupId,
            getTargetSubgroupById: getTargetSubgroupById,
            addTargetSubgroup: addTargetSubgroup,
            editTargetSubgroup: editTargetSubgroup,
            deleteTargetSubgroup: deleteTargetSubgroup
        };

        return service;

        function getAllTargetGroups() {
            return $http.get('api/targetGroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTargetGroupById(id) {
            return $http.get('api/targetGroups/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addTargetGroup(targetGroup) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(targetGroup));
            return $http.post('api/targetGroups', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editTargetGroup(targetGroup) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(targetGroup));
            return $http.put('api/targetGroups', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteTargetGroup(id) {
            return $http.delete('api/targetGroups/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function getAllTargetSubgroups() {
            return $http.get('api/targetGroups/subgroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTargetSubgroupsByParentGroupId(id) {
            return $http.get('api/targetGroups/subgroupsByParentGroup/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getTargetSubgroupById(id) {
            return $http.get('api/targetGroups/subgroups/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addTargetSubgroup(targetSubgroup) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(targetSubgroup));
            return $http.post('api/targetGroups/subgroups', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editTargetSubgroup(targetSubgroup) {
            var fd = new FormData();

            fd.append('item', JSON.stringify(targetSubgroup));
            return $http.put('api/targetGroups/subgroups', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteTargetSubgroup(id) {
            return $http.delete('api/targetGroups/subgroups/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
