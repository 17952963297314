(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.firstStep')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider        
        .state('schoolings.administration.activities.edit.firstStep', {
            url: '/firstStep',
            templateUrl: 'app/schoolings/administration/activities/edit/firstStep/firstStep.html',
            controller: 'FirstStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isActivityEdit: true
            },
            resolve: {
                activtiyTypesList: ['activitiesDataService', function (activitiesDataService) {
                    return activitiesDataService.getActivityTypes();
                }]
            }
        })
        .state('schoolings.administration.activities.add.firstStep', {
            url: '/firstStep',
            templateUrl: 'app/schoolings/administration/activities/edit/firstStep/firstStep.html',
            controller: 'FirstStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isActivityEdit: false
            },
            resolve: {
                activtiyTypesList: ['activitiesDataService', function (activitiesDataService) {
                    return activitiesDataService.getActivityTypes();
                }]
            }
        });
    }
})();