(function () {
    'use strict';

    angular
        .module('app')
        .controller('emailFormCtrlEua',emailFormCtrlEua);

    emailFormCtrlEua.$inject = ['logger', 'info', '$scope', 'i18nFilter', 'educationsDataService',  '$sanitize'];

    function emailFormCtrlEua(logger, info, $scope, i18nFilter, educationsDataService,  $sanitize) {
        /* jshint validthis:true */
        var vm = this;

        vm.question = i18nFilter('translations.education_gui_sidebar_help_question_for');
        vm.formName = i18nFilter('translations.education_gui_sidebar_help_contact_us');
        vm.info = info;
        vm.subject = vm.question + " " + vm.info.educationName;
        vm.cancel = cancel;
        vm.send = send;

        function send() {    
            var mail = {
                body: vm.body,
                subject: vm.subject
            };
            mail.body = DOMPurify.sanitize(mail.body);
            educationsDataService.sendSupportMail(vm.info.educationId, mail);      
            logger.success(i18nFilter("translations.education_help_mail_success"));
            $scope.$dismiss();
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
