(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .component('matrixQuestion', {
            templateUrl: 'app/surveys/questionGroups/questions/components/matrixQuestion/matrixQuestion.html',
            controller: matrixQuestion,
            controllerAs: 'vm',
            bindings: {
                question: '=',
                isEdit: '='
            }
        });

    matrixQuestion.$inject = ['surveyQuestionHelper', '$scope', 'i18nFilter', 'guidGeneratorDataService'];

    function matrixQuestion(surveyQuestionHelper, $scope, i18nFilter, guidGeneratorDataService) {
        var vm = this;

        vm.api = {
            id: vm.question.id,
            checkIfFormIsValid: checkIfFormIsValid,
            getData: getData
        };

        vm.localeMessagess = {
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.rows = [];
        vm.columns = [];

        if (vm.isEdit) {
            vm.rows = angular.copy(vm.question.subQuestions);
            vm.columns = angular.copy(vm.question.subQuestions[0].possibleAnswers);
        }

        vm.newRow = undefined;
        vm.newColumn = undefined;
        vm.areRowsValid = true;
        vm.areColumnsValid = true;

        vm.addRow = addRow;
        vm.addColumn = addColumn;
        vm.deleteRow = deleteRow;
        vm.deleteColumn = deleteColumn;

        surveyQuestionHelper.clear();
        surveyQuestionHelper.registerDirective(vm.question.id, vm.api);

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective(vm.question.id, vm.api.id);
        });

        function addRow() {
            if (vm.newRow) {
                vm.rows.push({
                    subQuestionId: guidGeneratorDataService.getGuid(),
                    subQuestionName: vm.newRow
                });
                vm.newRow = '';
            }
        }

        function addColumn() {
            if (vm.newColumn) {
                vm.columns.push({
                    id: guidGeneratorDataService.getGuid(),
                    text: vm.newColumn
                });
                vm.newColumn = '';
            }
        }

        function deleteRow(row) {
            var indexToDelete = vm.rows.indexOf(row);
            if (indexToDelete !== -1) {
                vm.rows.splice(indexToDelete, 1);
            }
        }

        function deleteColumn(column) {
            var indexToDelete = vm.columns.indexOf(column);
            if (indexToDelete !== -1) {
                vm.columns.splice(indexToDelete, 1);
            }
        }

        function checkIfFormIsValid() {
            if (vm.rows.length === 0 || vm.columns.length === 0) {
                if (vm.rows.length === 0) {
                    vm.areRowsValid = false;
                }

                if (vm.columns.length === 0) {
                    vm.areColumnsValid = false;
                }

                return false;
            } else {
                return true;
            }
        }

        function getData() {            
            vm.question.subQuestions = vm.rows;

            angular.forEach(vm.question.subQuestions, function (subQuestion, index) {
                subQuestion.possibleAnswers = vm.columns;
            });

            return vm.question.subQuestions;
        }
    }
})();
