(function () {
    'use strict';

    angular
        .module('app.exams.bulletinSubscriptions')
        .controller('deleteBulletinSubscription', deleteBulletinSubscription);

    deleteBulletinSubscription.$inject = ['logger', '$stateParams', 'bulletinSubscriptionsDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteBulletinSubscription(logger, $stateParams, bulletinSubscriptionsDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            bulletinSubscriptionsDataService.deleteSubscription($stateParams.subscriptionId).then(function (data) {
                logger.success(i18nFilter("translations.exam_bulletinSubscriptions_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
