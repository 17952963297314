(function () {
    'use strict';
    
    angular
        .module('app.exams.bulletinSubscriptions')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('bulletinSubscriptions', {
                parent: 'base',
                url: '/accessories/bulletin/subscriptions',
                templateUrl: 'app/accessories/bulletinSubscriptions/bulletinSubscriptions.html',
                controller: 'bulletinSubscriptionsController',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    bulletins: ['bulletinsDataService', function (bulletinsDataService) {
                        return bulletinsDataService.getBulletins();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('bulletinSubscriptions.add', {
                url: '/add',
                templateUrl: 'app/accessories/bulletinSubscriptions/editBulletinSubscriptions/editBulletinSubscriptions.html',
                controller: 'editBulletinSubscriptionsController',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    subscription: [function () {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('bulletinSubscriptions.edit', {
                url: '/edit/:subscriptionId',
                templateUrl: 'app/accessories/bulletinSubscriptions/editBulletinSubscriptions/editBulletinSubscriptions.html',
                controller: 'editBulletinSubscriptionsController',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    subscription: ['bulletinSubscriptionsDataService', '$stateParams', function (bulletinSubscriptionsDataService, $stateParams) {
                        return bulletinSubscriptionsDataService.getSubscriptionById($stateParams.subscriptionId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('bulletinSubscriptions.delete', {
                url: '/delete/:subscriptionId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/accessories/bulletinSubscriptions/deleteBulletinSubscriptions/deleteBulletinSubscription.html',
                        controller: 'deleteBulletinSubscription',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('bulletinSubscriptions');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('bulletinSubscriptions');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            ;
    }    
})();
