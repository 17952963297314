(function () {
    'use strict';

    angular
        .module('app.programs.home')
        .controller('ProgramsHome', programs);

    programs.$inject = ['logger', 'program'];

    function programs(logger, program) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = program;

        
    }
})();
