(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('removeSpeaker', removeSpeaker);

    removeSpeaker.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper','speaker'];

    function removeSpeaker(logger, $stateParams, $scope, i18nFilter, exceptionHelper, speaker) {
        var vm = this;

        vm.speaker = speaker;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(true);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
