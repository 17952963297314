(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('bool', boolFilter);

    boolFilter.$inject = ['logger'];

    function boolFilter(logger) {
        return function (input) {
            var outActive = '<i class="fa fa-check"></i>';
            var outNotActive = '<i class="fa fa-times"></i>';
            
            if (input != undefined && input == true) {
                return outActive;
            }            
            return outNotActive;
        };
    }
})();