(function () {
    'use strict';

    var core = angular.module('app.core');

    //#region notifications
    core.config(configureNotifications);
    configureNotifications.$inject = ['toastrConfig'];

    function configureNotifications(toastrConfig) {
        angular.extend(toastrConfig, {
            progressBar: true,
            timeOut: 5000,
            containerId: 'toast-container',
            newestOnTop: true,
            closeButton: true,
            closeHtml: '<button>&times;</button>',
            target: 'body',
            allowHtml: true
        });
    };
    //#endregion

    //#region
    core.config(function (dropzoneOpsProvider) {
        dropzoneOpsProvider.setOptions({
            url: '/upload_url',
            maxFilesize: '10'
        });
    });
    //#endregion



    //#region Loading bar
    core.config(loadingBarConfig);
    function loadingBarConfig(cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    }
    //#endregion

    //#region UI Select 
    core.config(uiSelectConfig);
    uiSelectConfig.$inject = ['uiSelectConfig'];
    function uiSelectConfig(uiSelectConfig) {
        uiSelectConfig.theme = 'bootstrap';
    }
    //#endregion

    //#region Remove Cache from $http requests
    core.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.defaults.cache = false;

        $httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";
        $httpProvider.defaults.headers.common.Pragma = "no-cache";
        $httpProvider.defaults.headers.common["If-Modified-Since"] = "0";


        $httpProvider.interceptors.push(function ($q) {
            return {
                'request': function (config) {
                    if (config.url.indexOf("api/") !== -1) {

                        var langKeyStr = localStorage.getItem("ngStorage-locale");
                        if (langKeyStr) {
                            var langKey = JSON.parse(langKeyStr);
                            config.headers['Accept-Language'] = langKey.localizationKey;
                        }
                    }
                    return config || $q.when(config);
                },
            }
        });

        $httpProvider.interceptors.push('httpInterceptorHelper');
    }]);
    //#endregion

    //#region Localization & globalization settings
    //Localization
    core.value('localeConf', {
        basePath: 'release/languages',
        defaultLocale: 'sl-SI',
        sharedDictionary: 'common',
        fileExtension: '.lang.json',
        persistSelection: true,
        cookieName: 'COOKIE_LOCALE_LANG',
        observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
        delimiter: '::'
    });


    //Supported locales library overwrite
    core.value('localeSupported', ['en-US', 'sl-SL', 'hr-HR', 'it-IT']);

    var supportedLoc = [
        { globalKey: 'en', localizationKey: 'en-US', tableKey: 'en', translationKey: 'language_english', tinymce: 'en_GB' },
        { globalKey: 'si', localizationKey: 'sl-SI', tableKey: 'si', translationKey: 'language_slovenian', tinymce: 'sl_SI' },
        { globalKey: 'hr', localizationKey: 'hr-HR', tableKey: 'hr', translationKey: 'language_croatian', tinymce: 'hr' },
        { globalKey: 'it', localizationKey: 'it-IT', tableKey: 'it', translationKey: 'language_italian', tinymce: 'it' }
    ];

    //Application supported locales
    core.value('appLocaleSupported', supportedLoc);
    window.supportedLocales = supportedLoc;


    //Globalization files injection
    core.config(function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('assets/js/locales/angular-locale_{{locale}}.js');
    });

    //Initial language setup
    core.run(['locale', 'tmhDynamicLocale', '$localStorage', 'logger', 'appLocaleSupported', function (locale, tmhDynamicLocale, $localStorage, logger, appLocaleSupported) {
        var loc = getUserDefaultLanguage(appLocaleSupported);

        if ($localStorage.locale) {
            loc = $localStorage.locale;
        }

        locale.setLocale(loc.localizationKey);
        tmhDynamicLocale.set(loc.localizationKey).then(function () { });
        $localStorage.locale = loc;
    }]);

    //Table localization
    core.run(configLocale);
    configLocale.$inject = ['$localStorage', 'appLocaleSupported', 'angularLoad'];
    function configLocale($localStorage, appLocaleSupported, angularLoad) {

        var key = getUserDefaultLanguage(appLocaleSupported);
        if ($localStorage.locale) {
            key = $localStorage.locale.tableKey;
        }
                
        angularLoad.loadScript('assets/js/devExpress_v17/localization/dx.messages.' + key + '.js').then(function () {
            //console.log("Table locale script loaded successfully. Key " + key);
        }, function (error) {
            console.error("Error while loading table localization script. Key: " + key, error);
        });

        $.when(
            $.getJSON("assets/js/devExpress_v17/cldr-dates/" + key + "/ca-gregorian.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-numbers/" + key + "/numbers.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-numbers/" + key + "/currencies.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-core/likelySubtags.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-core/timeData.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-core/weekData.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-core/currencyData.json"),
            $.getJSON("assets/js/devExpress_v17/cldr-core/numberingSystems.json")
        ).then(function () {
            return [].slice.apply(arguments, [0]).map(function (result) {
                return result[0];
            });
            }).then(Globalize.load).then(function () {                
            Globalize.locale(key);
        });
    }
    //#endregion

    //#region IE polyfill startsWith, endsWith
    core.run(function () {
        if (!String.prototype.startsWith) {
            String.prototype.startsWith = function (searchString, position) {
                position = position || 0;
                return this.substr(position, searchString.length) === searchString;
            };
        }
        if (!String.prototype.endsWith) {
            String.prototype.endsWith = function (searchString, position) {
                var subjectString = this.toString();
                if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                    position = subjectString.length;
                }
                position -= searchString.length;
                var lastIndex = subjectString.indexOf(searchString, position);
                return lastIndex !== -1 && lastIndex === position;
            };
        }
    });
    //#endregion


    //#region Administrator consent
    var organizationSubscribed = window.location.hash.indexOf('admin_consent=True') > -1;
    core.value('organizationSubscribed', organizationSubscribed);
    core.run(['logger', 'organizationSubscribed', function (logger, organizationSubscribed) {
        if (organizationSubscribed) {
            logger.success("Application is successfully registered in Azure Active Directory.");
        }
    }]);
    //#endregion

    //#region Slider configuration
    core.run(function () {
        $(window).on('resize', function () {
            $('.sly-frame').each(function () {
                $(this).sly('reload');
            });
        });
    });
    //#endregion

    //#region Css injection
    core.run(['$css', 'routeConfigurationV', function ($css, routeConfigurationV) {
        var subDomain = getSubDomain();
        var cssUrl = undefined;
        angular.forEach(routeConfigurationV, function (element, index) {
            if (element.key === subDomain && element.css) {
                cssUrl = "assets/css/tenants/" + subDomain + "/tenantStyle.css";
            }
        });


        if (cssUrl) {
            $css.add(cssUrl);
        }
    }]);
    //#endregion

    function getSubDomain() {
        var regexParse = new RegExp('[a-zA-Z0-9]+\.');
        var urlParts = regexParse.exec(window.location.hostname);
        if (urlParts) {
            return urlParts[0].substring(0, urlParts[0].length - 1);
        }
        else {
            return undefined;
        }
    }

    function getUserDefaultLanguage(supportedLocales) {
        //var languageKey = navigator.language || navigator.userLanguage;
        //switch (languageKey) {
        //    case 'en-US':
        //    case 'en-GB':
        //        return supportedLocales[0];
        //    case 'hr-HR':
        //    case 'hr':
        //        return supportedLocales[1];
        //    case 'it-IT':
        //    case 'it':
        //        return supportedLocales[2];
        //    default:
        //        return supportedLocales[0];
        //}
        return supportedLocales[1];
    }
})();
