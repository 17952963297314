(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('queuesDataService', queues);

    queues.$inject = ['$http', 'logger', 'locale', '$q', 'dateHelper'];

    function queues($http, logger, locale, $q, dateHelper) {
        var service = {
            getQueues: getQueues,
            reQueue: reQueue
        };

        return service;

        function getQueues(filter) {
            filter.addedAt.searchText1 = dateHelper.convertFromDateToString(filter.addedAt.searchText1);
            filter.addedAt.searchText2 = dateHelper.convertFromDateToString(filter.addedAt.searchText2);
            filter.startedProcessingAt.searchText1 = dateHelper.convertFromDateToString(filter.startedProcessingAt.searchText1);
            filter.startedProcessingAt.searchText2 = dateHelper.convertFromDateToString(filter.startedProcessingAt.searchText2);
            filter.finisfedAt.searchText1 = dateHelper.convertFromDateToString(filter.finisfedAt.searchText1);
            filter.finisfedAt.searchText2 = dateHelper.convertFromDateToString(filter.finisfedAt.searchText2);

            return $http.post('api/queues', filter).then(function (data) {
                angular.forEach(data.data.data, function (element, index) {
                    element.addedAt = dateHelper.convertFromStringToDate(element.addedAt);
                    element.startedProcessingAt = dateHelper.convertFromStringToDate(element.startedProcessingAt);
                    element.finisfedAt = dateHelper.convertFromStringToDate(element.finisfedAt);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get queues', data);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            })
        }

        function reQueue(data) {
            return $http.post('api/queues/requeue', data)
        }
    }
})();
