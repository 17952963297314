(function () {
    'use strict';

    angular
        .module('app.tenants')
        .controller('EditTenants', editTenants);

    editTenants.$inject = ['editTenantHelper', '$stateParams', '$state', '$scope', 'tenant'];

    function editTenants(editTenantHelper, $stateParams, $state, $scope, tenant) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'baseStep';
        vm.isEdit = $state.current.data.isEdit;
        vm.item = tenant;
        vm.navigation = [];
        editTenantHelper.clearData();

        if (vm.isEdit) {
            vm.navigation = editTenantHelper.getWizardSteps($stateParams.id, vm.isEdit);
            editTenantHelper.setData(vm.item);
        }
        else {            
            vm.navigation = editTenantHelper.getWizardSteps(null, vm.isEdit);
        }

        $scope.navigation = vm.navigation;
    }
})();
