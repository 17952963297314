(function () {
    'use strict';

    angular
        .module('app')
        .controller('sendHistory', sendHistory);

    sendHistory.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', '$http', '$q', 'sendHistory', '$sanitize'];

    function sendHistory(logger, $stateParams, $scope, i18nFilter, exceptionHelper, $http, $q, sendHistory, $sanitize) {
        var vm = this;
        vm.cancel = cancel;

        var columns = [
            {
                dataField: 'id',
                caption: i18nFilter('translations.general_gui_date'),
                visible: false
            },
            {
                dataField: 'sentOn',
                caption: i18nFilter('translations.general_gui_date'),
                alignment: 'left',
                dataType: 'date'
            },
            {
                dataField: 'body',
                caption: i18nFilter('translations.email_gui_body'),
                alignment: 'left',
                cellTemplate: 'cellTemplate'
            },
            {
                dataField: 'count',
                caption: i18nFilter('translations.general_gui_notifications_number_of_recepients'),
                alignment: 'left'
            }
        ];



        vm.dataGridOptions = {
            dataSource: sendHistory,
            columns: columns,
            filterRow: { visible: false },
            pager: { visible: true },
      //      hoverStateEnabled: true,
            paging: {
                pageSize: 12,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function cancel() {
            $scope.$dismiss();         
        }
    }
})();
