(function () {
    'use strict';

    angular
        .module('app.accessories')
        .controller('Accessories', accessories);

    accessories.$inject = ['$state', 'rolesDataService', 'logger', '$uibModal'];

    function accessories($state, rolesDataService, logger, $uibModal) {
        var vm = this;
        vm.showScholarships = rolesDataService.isUserInRoles($state.get('scholarships').data.roles);
        vm.showBulletinSubscriptions = rolesDataService.isUserInRoles($state.get('bulletinSubscriptions').data.roles);
        vm.showBulletins = rolesDataService.isUserInRoles($state.get('bulletins').data.roles);
        vm.truncateOptions = {
            watch: 'window',
            wrap: 'letter'
        };
    }
})();
