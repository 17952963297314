(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .controller('EditQuestion', editQuestion);

    editQuestion.$inject = ['logger', 'question', '$scope', '$state', 'questionTypes', 'surveyQuestionHelper', 'surveysDataService', 'guidGeneratorDataService', 'exceptionHelper', '$stateParams', 'i18nFilter'];

    function editQuestion(logger, question, $scope, $state, questionTypes, surveyQuestionHelper, surveysDataService, guidGeneratorDataService, exceptionHelper, $stateParams, i18nFilter) {
        var vm = this;
        vm.item = question;
        vm.isEdit = $state.current.data.isEdit;

        vm.formData = {
            tinymceOptions: {               
                toolbar1: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | forecolor backcolor'
            },
            questionTypes: [
                questionTypes.freeText,
                questionTypes.radio,
                questionTypes.checkbox,
                questionTypes.scalar,
                questionTypes.matrix,
                questionTypes.percent
            ]
        };

        vm.cancel = cancel;
        vm.save = save;
        vm.add = add;

        if (!vm.isEdit) {
            vm.item = {
                id: guidGeneratorDataService.getGuid()
            };
        }

        vm.item.surveyGroupId = $stateParams.questionGroupId;

        function cancel() {
            $state.go("surveys.questions", $stateParams);
        }

        function save() {
            if (vm.item.questionType !== vm.formData.questionTypes[0]) {
                var isValid = areAllCommponentsValid();

                if (!isValid) {
                    logger.error(i18nFilter('translations.survey_question_gui_form_validation_message'));
                    return;
                }
            }
            else {  //For Free-Text-Answer-Question make sure edit will check if possibleAnswers.id is set (allows edit old data & updates to new format)
                if (vm.item.subQuestions[0].possibleAnswers &&
                    vm.item.subQuestions[0].possibleAnswers.length === 0) {
                    vm.item.subQuestions[0].possibleAnswers.push({
                        id: guidGeneratorDataService.getGuid(), //must follow the same format (for client)
                        text: ''    //empty, can be ignored
                    });
                }
            }
            
            surveysDataService.editQuestion(angular.copy(vm.item)).then(function (result) {
                logger.success(i18nFilter('translations.survey_question_queue_edit'));
                $state.go("surveys.questions", $stateParams);
            }, function (error) {
                exceptionHelper.handleException(error);
                $state.go("surveys.questions", $stateParams);
            });
        }

        function add() {
            if (vm.item.questionType !== vm.formData.questionTypes[0]) {
                var isValid = areAllCommponentsValid();

                if (!isValid) {
                    logger.error(i18nFilter('translations.survey_question_gui_form_validation_message'));
                    return;
                }
            } else {    //Create the Free-Text-Answer-Question
                vm.item.subQuestions = [
                    {
                        subQuestionId: guidGeneratorDataService.getGuid(),
                        subQuestionName: '',
                        possibleAnswers: [
                            {
                                id: guidGeneratorDataService.getGuid(), //make sure all questions follow the same format (for client)
                                text: ''    //even if there is no additional answer really needed for the given situation
                            }
                        ]
                    }
                ];               
            }

            surveysDataService.addQuestion(angular.copy(vm.item)).then(function (result) {
                logger.success(i18nFilter('translations.survey_question_queue_add'));
                $state.go("surveys.questions", $stateParams);
            }, function (error) {
                exceptionHelper.handleException(error);
                $state.go("surveys.questions", $stateParams);
            });
        }

        function areAllCommponentsValid() {
            var isComponentValid = false;

            var apis = surveyQuestionHelper.getApisForKey(vm.item.id);

            isComponentValid = apis[0].checkIfFormIsValid();

            if (isComponentValid) {
                if (vm.item.questionType !== vm.formData.questionTypes[4]) {
                    vm.item.subQuestions = [];
                    vm.item.subQuestions.push({ possibleAnswers:apis[0].getData() });
                } else {
                    vm.item.subQuestions = apis[0].getData();
                }
            }
            return isComponentValid;
        }
    }
})();
