(function () {
    'use strict';

    angular
        .module('app.surveys.administration.edit')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys.administration.edit', {
                url: '/edit',
                templateUrl: 'app/surveys/administration/edit/edit.html',
                controller: 'EditSurvey',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    survey: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getSurveyById($stateParams.id);
                    }]
                }
            })
            .state('surveys.administration.add', {
                url: '/add',
                templateUrl: 'app/surveys/administration/edit/edit.html',
                controller: 'EditSurvey',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    survey: [function () {
                        return {};
                    }]
                }
            });
    }
})();
