(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('authDataService', auth);

    auth.$inject = ['$http', 'logger', 'locale', '$q'];

    function auth($http, logger, locale, $q) {
        var service = {
            isCurrentUserRealEducationOwner: isCurrentUserRealEducationOwner,
            isCurrentUserRealEducationInstructor: isCurrentUserRealEducationInstructor,
            isCurrentUserEducationLecturer: isCurrentUserEducationLecturer,
            isCurrentUserEducationOwnerOfAnyEducation: isCurrentUserEducationOwnerOfAnyEducation,
            isCurrentUserCoordinatorOfAnyExam: isCurrentUserCoordinatorOfAnyExam,
            isUserEnrolledInEducation: isUserEnrolledInEducation,
            isCurrentUserRealProgramOwner: isCurrentUserRealProgramOwner,
            isUserInstructorInSchedulePack: isUserInstructorInSchedulePack,
            isOwnerOfEntityInsideSchedulePack: isOwnerOfEntityInsideSchedulePack,
            isCurrentUserExamCreator: isCurrentUserExamCreator,
            isCurrentUserExamCoordinator: isCurrentUserExamCoordinator,
            isCurrentUserSchoolingOwnerOfAnySchooling: isCurrentUserSchoolingOwnerOfAnySchooling,
            isCurrentUserRealSchoolingOwner: isCurrentUserRealSchoolingOwner,
        };

        return service;


        function isCurrentUserRealEducationOwner(educationId) {
            return $http.get('api/auth/realEducationOwner/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserRealSchoolingOwner(schoolingId) {
            return $http.get('api/auth/realSchoolingOwner/' + schoolingId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function isCurrentUserRealEducationInstructor(educationId) {
            return $http.get('api/auth/realEducationInstructor/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserEducationLecturer(educationId) {
            return $http.get('api/auth/isEducationLecturer/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserEducationOwnerOfAnyEducation() {
            return $http.get('api/auth/isEducationOwner').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function isCurrentUserSchoolingOwnerOfAnySchooling() {
            return $http.get('api/auth/isSchoolingOwner').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function isCurrentUserCoordinatorOfAnyExam() {
            return $http.get('api/auth/isExamCoordinator').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isUserEnrolledInEducation(educationId) {
            return $http.get('api/auth/enrolledInEducation/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserRealProgramOwner(programId) {
            return $http.get('api/auth/realProgramOwner/' + programId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isUserInstructorInSchedulePack(schedulePackId) {
            return $http.get('api/auth/instructorInSchedulePack/' + schedulePackId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isOwnerOfEntityInsideSchedulePack(schedulePackId) {
            return $http.get('api/auth/ownerOfEnttiyInsideSchedulePack/' + schedulePackId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserExamCreator(examId) {
            return $http.get('api/auth/examCreator/' + examId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCurrentUserExamCoordinator(examId) {
            return $http.get('api/auth/examCoordinator/' + examId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
