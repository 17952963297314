(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('searchTrim', trimSearchFilter);

    trimSearchFilter.$inject = ['logger'];

    function trimSearchFilter(logger) {
        return function (input, searchCriteria) {
            var out = '';
            var numberOfCharsAllowed = 24;

            if (searchCriteria) {
                var startIndex = input.toLowerCase().indexOf(searchCriteria.toLowerCase());
                if (startIndex == -1) {
                    out = input.substring(startIndex, numberOfCharsAllowed) + '...';
                }
                else {
                    var firstStringStart = startIndex - 10;
                    if (firstStringStart < 0) {
                        firstStringStart = 0;
                    }

                    var firstString = input.substring(firstStringStart, startIndex);

                    var middleString = '<b>' + input.substring(startIndex, startIndex + searchCriteria.length) + '</b>';

                    var lastStringEnd = startIndex + searchCriteria.length + 10;
                    if (lastStringEnd > input.length) {
                        lastStringEnd = input.length;
                    }
                    var lastString = input.substring(startIndex + searchCriteria.length, lastStringEnd);
                    


                    out = '...' + firstString + middleString + lastString + '...';
                }
            }
            else {
                out = input.substring(startIndex, numberOfCharsAllowed) + '...';
            }

            return out;
        };
    }

})();