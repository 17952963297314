(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('templatesConfiguration', templatesConfiguration);

    templatesConfiguration.$inject = ['notificationTemplates', 'i18nFilter'];

    function templatesConfiguration(notificationTemplates, i18nFilter) {
        /* jshint validthis:true */
        var vm = this;
         vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit')
        };

        vm.notificationTemplates = notificationTemplates;

        var columns = [
            {
                dataField: 'notificationType',
                caption: i18nFilter('translations.email_gui_notification_template_identifier'),
                alignment: 'left',
                sortOrder: 'asc',
                width: 150
            },
            {
                dataField: 'subject',
                caption: i18nFilter('translations.email_gui_notification_template_name'),
                alignment: 'left'                
            },
            {
                dataField: 'classification',
                caption: i18nFilter('translations.email_gui_notification_templates_type'),
                width: 350,
                alignment: 'left',
                cellTemplate: 'notificationClassification'
            },
            {
                dataField: 'isActive',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'center',
                width: 150
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                width: 350,
                alignment: 'left',
                cellTemplate: 'notificationActionsTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.notificationTemplates,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 15,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
