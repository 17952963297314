(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingFourthStepPlan', EditSchoolingFourthStepPlan);

    EditSchoolingFourthStepPlan.$inject = ['plan', 'isEdit', 'editSchoolingHelper', 'decimalHelper', 'dateHelper', '$state', '$scope', 'i18nFilter', 'locations', 'exceptionHelper', 'logger'];

    function EditSchoolingFourthStepPlan(plan, isEdit, editSchoolingHelper, decimalHelper, dateHelper, $state, $scope, i18nFilter, locations, exceptionHelper, logger) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = plan;
        
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        }
        vm.formData = {
            locations: locations,
            isEdit: isEdit
        }

        if (!isEdit) {
            vm.item.allYear = false;
            vm.item.itemsInQ1 = 0;
            vm.item.itemsInQ2 = 0;
            vm.item.itemsInQ3 = 0;
            vm.item.itemsInQ4 = 0;
        }

        vm.ok = ok;
        vm.cancel = cancel;
        vm.allYearChecked = allYearChecked;
        vm.calculateNumOfExecutions = calculateNumOfExecutions;

        allYearChecked();

        function ok() {
            vm.formData.showYearUniquenessError = false;
            if (vm.formData.isEdit || editSchoolingHelper.isYearUnique(vm.item.year, vm.item.budgetLineItem)) {
                vm.item.location.sort((a, b) => a.name > b.name);

                var tmp = "";
                vm.item.location.forEach(function (lctn) {
                    if (tmp != "") tmp += ", ";
                    tmp += lctn.name + "\u00A0(" + lctn.price + ",\u00A0" + lctn.participantsCost + ")";
                });
                vm.item.allLocations = tmp;

                $scope.$close(vm.item);
            } else {
                vm.formData.showYearUniquenessError = true;
            }
        }

        function cancel() {
            $scope.$dismiss();
        }

        function allYearChecked() {
            vm.formData.numOfExecutionsEnabled = vm.item.allYear;
            calculateNumOfExecutions();
        }

        function calculateNumOfExecutions() {
            if (vm.item.allYear == undefined || vm.item.allYear == false) {
                vm.item.numOfExecutions = parseInt(vm.item.itemsInQ1) + parseInt(vm.item.itemsInQ2) + parseInt(vm.item.itemsInQ3) + parseInt(vm.item.itemsInQ4);
            }
        }
    }
})();
