(function () {
    "use strict";

    angular
        .module("app.dataservice")
        .factory("schedulesDataService", schedulesDataService);

    schedulesDataService.$inject = [
        "$http",
        "logger",
        "locale",
        "$q",
        "dateHelper",
        "decimalHelper",
    ];

    function schedulesDataService(
        $http,
        logger,
        locale,
        $q,
        dateHelper,
        decimalHelper
    ) {
        var service = {
            getScheduleLearnerInfo: getScheduleLearnerInfo,
            getSchedulePackLearners: getSchedulePackLearners,
            getPotentialLearnersForSchedule: getPotentialLearnersForSchedule,
            administrativeEnroll: administrativeEnroll,
            confirmPresence: confirmPresence,
            denyPresence: denyPresence,
            denayJustifiedPresence: denayJustifiedPresence,
            uploadAttendanceList: uploadAttendanceList,
            getInstructorRecordsForSchedule: getInstructorRecordsForSchedule,
            getInstructorsCertificateOfEmploymentsRecordsForSchedule:
                getInstructorsCertificateOfEmploymentsRecordsForSchedule,
            uploadInstructorCertificateOfEmployment:
                uploadInstructorCertificateOfEmployment,
            unlockSchedule: unlockSchedule,
            lockSchedule: lockSchedule,
            uploadInstructorRecord: uploadInstructorRecord,
            confirmApplication: confirmApplication,
            confirmOnlineApplication: confirmOnlineApplication,
            denyApplication: denyApplication,
            denyOnlineApplication: denyOnlineApplication,
            cancelApplication: cancelApplication,
            getLearnerStatistics: getLearnerStatistics
        };

        return service;

        function getScheduleLearnerInfo(learningPlanItemId) {
            return $http.get("api/schedules/learner/" + learningPlanItemId).then(
                function (data) {
                    return data.data;
                },
                function (data, status, headers, config) {
                    if (data) {
                        return $q.reject({
                            exception: data.data,
                            status: data.status,
                        });
                    } else {
                        return $q.reject();
                    }
                }
            );
        }

        function getSchedulePackLearners(schedulePackId) {
            return $http
                .get("api/schedules/" + schedulePackId + "/pack/learners")
                .then(
                    function (data) {
                        //Start & end time handle
                        angular.forEach(
                            data.data,
                            function (learnerSchedule, learnerScheduleIndex) {
                                angular.forEach(
                                    learnerSchedule.activities,
                                    function (activity, activityIndex) {
                                        if (activity.relatedSchedule) {
                                            activity.relatedSchedule.startTime =
                                                dateHelper.convertFromStringToDate(
                                                    activity.relatedSchedule
                                                        .startTime
                                                );
                                            activity.relatedSchedule.endTime =
                                                dateHelper.convertFromStringToDate(
                                                    activity.relatedSchedule
                                                        .endTime
                                                );
                                        }
                                        activity.scheduleConfirmedAt =
                                            dateHelper.convertFromStringToDate(
                                                activity.scheduleConfirmedAt
                                            );
                                    }
                                );
                            }
                        );

                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function getPotentialLearnersForSchedule(scheduleId, query) {
            return $http
                .get(
                    "api/schedules/" +
                        scheduleId +
                        "/potentialLearners/" +
                        query
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function administrativeEnroll(eformData) {
            return $http
                .post("api/schedules/administrativeEnroll", eformData)
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function confirmPresence(learningActivityScheduleIds, scheduleId) {
            return $http
                .post(
                    "api/schedules/" + scheduleId + "/confirm",
                    learningActivityScheduleIds
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function denyPresence(learningActivityScheduleIds, scheduleId) {
            return $http
                .post(
                    "api/schedules/" + scheduleId + "/deny",
                    learningActivityScheduleIds
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function denayJustifiedPresence(
            learningActivityScheduleIds,
            scheduleId
        ) {
            return $http
                .post(
                    "api/schedules/" + scheduleId + "/justifyDeny",
                    learningActivityScheduleIds
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function uploadAttendanceList(scheduleId, fileId) {
            return $http
                .post("api/schedules/attendanceList", {
                    scheduleId: scheduleId,
                    fileId: fileId,
                })
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function getInstructorRecordsForSchedule(scheduleId) {
            return $http.get("api/schedules/records/" + scheduleId).then(
                function (data) {
                    return data.data;
                },
                function (data, status, headers, config) {
                    if (data) {
                        return $q.reject({
                            exception: data.data,
                            status: data.status,
                        });
                    } else {
                        return $q.reject();
                    }
                }
            );
        }

        function getInstructorsCertificateOfEmploymentsRecordsForSchedule(
            scheduleId
        ) {
            return $http
                .get("api/schedules/" + scheduleId + "/instructorsCertificate")
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function getLearnerStatistics(scheduleId) {
            return $http
                .get(
                    "api/schedules/" +
                    scheduleId +
                    "/learnerStatistics"
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function uploadInstructorCertificateOfEmployment(
            instructorId,
            scheduleId,
            fileId
        ) {
            var record = {
                instructor: { id: instructorId },
                scheduleId: scheduleId,
                fileId: fileId,
            };

            return $http
                .post("api/schedules/certificatesOfEmployment", record)
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function unlockSchedule(scheduleId) {
            return $http.post("api/schedules/" + scheduleId + "/unlock").then(
                function (data) {
                    return data.data;
                },
                function (data, status, headers, config) {
                    if (data) {
                        return $q.reject({
                            exception: data.data,
                            status: data.status,
                        });
                    } else {
                        return $q.reject();
                    }
                }
            );
        }

        function lockSchedule(scheduleId) {
            return $http.post("api/schedules/" + scheduleId + "/lock").then(
                function (data) {
                    return data.data;
                },
                function (data, status, headers, config) {
                    if (data) {
                        return $q.reject({
                            exception: data.data,
                            status: data.status,
                        });
                    } else {
                        return $q.reject();
                    }
                }
            );
        }

        function uploadInstructorRecord(instructorId, scheduleId, fileId) {
            var record = {
                instructor: { id: instructorId },
                scheduleId: scheduleId,
                fileId: fileId,
            };

            return $http
                .post(
                    "api/schedules/" +
                        scheduleId +
                        "/instructorRecords/" +
                        instructorId,
                    record
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function confirmApplication(
            educationActivitySchedulePackIds,
            eFormIds,
            learnerIds,
            scheduleId
        ) {
            var data = [educationActivitySchedulePackIds, eFormIds, learnerIds];
            return $http
                .post(
                    "api/schedules/" + scheduleId + "/confirmApplications",
                    data
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function confirmOnlineApplication(
            eFormIds,
            learnerIds
        ) {
            var data = [eFormIds, learnerIds];
            return $http
                .post(
                    "api/schedules/confirmOnlineApplications",
                    data
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function denyApplication(
            learningActivityScheduleIds,
            eFormIds,
            learnerIds,
            scheduleId,
            rejectReason
        ) {
            var data = {
                learnerRejectReason: rejectReason,
                data: [learningActivityScheduleIds, eFormIds, learnerIds],
            };

            return $http
                .post("api/schedules/" + scheduleId + "/denyApplications", data)
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function denyOnlineApplication(
            eFormIds,
            learnerIds,
            rejectReason
        ) {
            var data = {
                learnerRejectReason: rejectReason,
                data: [eFormIds, learnerIds],
            };

            return $http
                .post("api/schedules/denyOnlineApplications", data)
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }

        function cancelApplication(
            educationActivitySchedulePackIds,
            eFormIds,
            learnerIds,
            scheduleId
        ) {
            let data = {
                data: [educationActivitySchedulePackIds, eFormIds, learnerIds],
            };

            return $http
                .post(
                    "api/schedules/" + scheduleId + "/cancelApplications",
                    data
                )
                .then(
                    function (data) {
                        return data.data;
                    },
                    function (data, status, headers, config) {
                        if (data) {
                            return $q.reject({
                                exception: data.data,
                                status: data.status,
                            });
                        } else {
                            return $q.reject();
                        }
                    }
                );
        }
    }
})();
