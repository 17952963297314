(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('learningPlanStatus', LearningPlanStatus);

    LearningPlanStatus.$inject = ['logger', 'learningPlanItemStatus', 'i18nFilter'];


    function LearningPlanStatus(logger, learningPlanItemStatus, i18nFilter) {
        return function (input) {
            if (input === undefined || input === null) {
                return '';
            }

            var value = Number(input);
            if (value === learningPlanItemStatus.removedFromPlan) {
                return i18nFilter('translations.filter_learning_plan_item_status_removed_from_plan');
            }
            else if(value === learningPlanItemStatus.notStarted){
                return i18nFilter('translations.filter_learning_plan_item_status_not_started');
            }
            else if(value === learningPlanItemStatus.started){
                return i18nFilter('translations.filter_learning_plan_item_status_started');
            }
            else if(value === learningPlanItemStatus.finished){
                return i18nFilter('translations.filter_learning_plan_item_status_finished');
            }
            return '';
        };
    }
})();
