(function () {
    "use strict";

    angular
        .module("app.schedulePacks")
        .controller(
            "schedulePackApprovalsController",
            schedulePackApprovalsController
        );

    schedulePackApprovalsController.$inject = [
        "logger",
        "i18nFilter",
        "schedulesDataService",
        "$stateParams",
        "exceptionHelper",
        "rolesDataService",
        "$uibModal",
        "learningActivityScheduleStatus",
        "learningPlanItemStatus",
        "learningPlanItemSuccess",
        "learningActivityState",
        "dateFilter",
        "usersDataService",
        "$state",
        "$http",
        "documentsDataService",
        "schedulePack",
        "$sessionStorage",
        "eFormsUrl",
        "eFormTypes",
        "schedulePacksDataService",
        "eFormStatus",
        "documentCertificationSign"
    ];

    function schedulePackApprovalsController(
        logger,
        i18nFilter,
        schedulesDataService,
        $stateParams,
        exceptionHelper,
        rolesDataService,
        $uibModal,
        learningActivityScheduleStatus,
        learningPlanItemStatus,
        learningPlanItemSuccess,
        learningActivityState,
        dateFilter,
        usersDataService,
        $state,
        $http,
        documentsDataService,
        schedulePack,
        $sessionStorage,
        eFormsUrl,
        eFormTypes,
        schedulePacksDataService,
        eFormStatus,
        documentCertificationSign
    ) {
        var vm = this;

        vm.confirmApplication = confirmApplication;
        vm.denyApplication = denyApplication;
        vm.openEForm = openEForm;
        vm.downloadCertificate = downloadCertificate;
        vm.canDownloadCertificate = canDownloadCertificate;
        vm.regenerateCetificate = regenerateCetificate;
        vm.canRegenerateCertificate = canRegenerateCertificate;
        vm.getFormTitle = getFormTitle;
        vm.openRejectLearnersModal = openRejectLearnersModal;
        vm.sendMailToSelectedLearners = sendMailToSelectedLearners;

        vm.formData = {
            selectedSchedule: $stateParams.schedulePackId,

            selectedPersons: [],

            showApplicationResponces: false,

            learningPlanItemStatus: learningPlanItemStatus,
            learningPlanItemSuccess: learningPlanItemSuccess,

            schedulePack: schedulePack,
            learnersInfo: {},
        };

        vm.localeMessages = {
            userConsentTooltip: i18nFilter(
                "translations.schedule_presence_gui_main_table_user_consent_tooltip"
            ),
            approveApplications: i18nFilter(
                "translations.exam_schedules_action_confirm_applications"
            ),
        };

        vm.i18nFilter = i18nFilter;

        vm.scheduleStatus = [
            {
                id: learningActivityScheduleStatus.ongoing,
                format: i18nFilter(
                    "translations.schedule_gui_label_user_ongoing"
                ),
            },
            {
                id: learningActivityScheduleStatus.enrolled,
                format: i18nFilter(
                    "translations.schedule_gui_label_user_enrolled"
                ),
            },
            {
                id: learningActivityScheduleStatus.missed,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_schedule_missed"
                ),
            },
            {
                id: learningActivityScheduleStatus.completed,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_finished_schedule"
                ),
            },
            {
                id: learningActivityScheduleStatus.cancelled,
                format: i18nFilter(
                    "translations.schedule_gui_label_status_cancelled"
                ),
            },
            {
                id: learningActivityScheduleStatus.justifiedMissed,
                format: i18nFilter(
                    "translations.schedule_presence_main_actions_justified_missed_schedule"
                ),
            },
        ];

        vm.activityStates = [
            {
                id: learningActivityState.started,
                format: i18nFilter(
                    "translations.filter_learning_activity_status_in_progress"
                ),
            },
            {
                id: learningActivityState.finished,
                format: i18nFilter(
                    "translations.filter_learning_activity_status_finished"
                ),
            },
        ];

        vm.scheduleEFormStatus = [
            {
                id: eFormStatus.inProgress,
                format: i18nFilter(
                    "translations.filter_eform_status_in_progress"
                ),
            },
            {
                id: eFormStatus.finished,
                format: i18nFilter("translations.filter_eform_status_finished"),
            },
            {
                id: eFormStatus.pendingPersonalData,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_personal_data"
                ),
            },
            {
                id: eFormStatus.pendingConfirmation,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_confirmation"
                ),
            },
            {
                id: eFormStatus.rejected,
                format: i18nFilter("translations.filter_eform_status_rejected"),
            },
        ];

        vm.showCancelApprovalButton = false;

        
        var columns = [
            {
                dataField: 'displayName',
                caption: i18nFilter(
                    "translations.schedule_presence_gui_main_table_user_educations"
                ),
                cellTemplate: "displayNameTemplate",
                alignment: 'left',
                width: 300,
                allowSorting: true,
                calculateCellValue: function (rowData) {
                    return rowData.displayName;
                },
                allowSorting: true,
                sortingMethod: function (value1, value2) {
                    if (!value1 && value2) return -1;
                    if (!value1 && !value2) return 0;
                    if (value1 && !value2) return 1;
                    return value1.localeCompare(value2, "sl-SI");
                },
            },
            {
                dataField: 'eFormPayerName',
                caption: i18nFilter(
                    "translations.examSchedulePack_application_payerName"
                ),
                alignment: 'left',
                width: '100%',
                allowSorting: true
            },
            {
                dataField: 'eFormStatus',
                caption: i18nFilter(
                    "translations.schedule_presence_gui_main_table_schedule_eform_status"
                ),
                alignment: 'left',
                width: '300px',
                allowSorting: true,
                lookup: {
                    dataSource: vm.scheduleEFormStatus,
                    valueExpr: "id",
                    displayExpr: "format",
                },
            }
        ];

        vm.dataGridOptions = {
            dataSource:  new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/SchedulePackLearnersOData/GetSchedulePackLearners",
                    beforeSend: function (e) {
                        e.url += "(schedulePackId=" + vm.formData.selectedSchedule + ")";

                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            };
                        }
                    }
                }
            }),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                showNavigationButtons: true
            },
            selection: {
                mode: 'multiple',
                selectAllMode: 'page'
            },
            wordWrapEnabled: false,
            sorting: {
                mode: 'single'
            },
            onRowExpanded: function (e) {
                const model = e.key;
                if (!vm.formData.learnersInfo[model.learningPlanItemId]) {
                    schedulesDataService.getScheduleLearnerInfo(model.learningPlanItemId)
                    .then(
                        function (data) {
                            vm.formData.learnersInfo[data.learningPlanItemId] = data;
                        },
                        function (error) {
                            exceptionHelper.handleException(error);
                        }
                    );
                }
            },
            onSelectionChanged: function (selectedItems) {
                logger.log("Selection occurred: ", selectedItems);

                vm.formData.selectedPersons = selectedItems.selectedRowsData;
                if (
                    vm.formData.selectedPersons &&
                    vm.formData.selectedPersons.length !== 0
                ) {
                    checkRequirementsForActions();
                    vm.formData.showSendMailToSelectedLearners = true;
                } else {
                    vm.formData.showApplicationResponces = false;
                    vm.formData.showSendMailToSelectedLearners = false;
                }
            },
            masterDetail: {
                enabled: true,
                template: "detail",
            },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
        };

        vm.cancelApplication = cancelApplication;
        vm.cancelApplicationForPerson = [];

        function getFormTitle(withDelimiter) {
            var name = null;
            if (vm.formData.schedulePack.programName == null)
                name = vm.formData.schedulePack.educationName;
            else name = vm.formData.schedulePack.programName;
            var from = dateFilter(
                vm.formData.schedulePack.startTime,
                "shortDate"
            );
            var to = dateFilter(vm.formData.schedulePack.endTime, "shortDate");
            if (from === to) {
                return name + (withDelimiter ? "," : "") + " " + from;
            } else {
                return (
                    name + (withDelimiter ? "," : "") + " " + from + " - " + to
                );
            }
        }

        function checkRequirementsForActions() {
            showApplicationResponces();
        }

        function showApplicationResponces() {
            let conditionIsSatisfied = true;
            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (
                        learner.eFormStatus !== eFormStatus.pendingConfirmation
                    ) {
                        conditionIsSatisfied = false;
                    }
                    let currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);
                    let endTime = new Date(schedulePack.endTime);
                    endTime.setHours(0, 0, 0, 0);

                    if (currentDate > endTime) {
                        checkLearningActivityScheduleStatus(learner);
                    }
                }
            );

            vm.formData.showApplicationResponces = conditionIsSatisfied;
        }

        function confirmApplication() {
            var educationActivitySchedulePackIds = [];
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    educationActivitySchedulePackIds.push(
                        learner.schedulePackId
                    );
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.id);
                }
            );

            schedulesDataService.confirmApplication(
                educationActivitySchedulePackIds,
                eFormIds,
                learnerIds,
                vm.formData.selectedSchedule
            );
        }

        function denyApplication(rejectReason) {
            var educationActivitySchedulePackId = [];
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    educationActivitySchedulePackId.push(
                        learner.schedulePackId
                    );
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.id);
                }
            );

            schedulesDataService.denyApplication(
                educationActivitySchedulePackId,
                eFormIds,
                learnerIds,
                vm.formData.selectedSchedule,
                rejectReason
            );
        }

        function openRejectLearnersModal() {
            var modalInstance = $uibModal.open({
                templateUrl:
                    "app/schedulePacks/rejectLearnerModal/rejectLearnerModal.html",
                controller: "RejectLearnersDialog",
                controllerAs: "modalvm",
                resolve: {
                    schedulePack: vm.formData.schedulePack,
                },
            });

            modalInstance.result.then(
                function (data) {
                    //confirm callback
                    denyApplication(data);
                },
                function () {
                    //close callback
                    console.info("reject eform learners modal closed");
                }
            );
        }

        function openEForm(eFormId, eFormType) {
            if (!eFormId || !eFormType) {
                return;
            }
            var tenantId = $sessionStorage.userInfo.tenantId;

            if (eFormType === eFormTypes.education) {
                window.open(
                    eFormsUrl[tenantId] + "/edit/education/" + eFormId,
                    "_blank"
                );
            } else if (eFormType === eFormTypes.program) {
                window.open(
                    eFormsUrl[tenantId] + "/edit/program/" + eFormId,
                    "_blank"
                );
            }
        }

        function cancelApplication() {
            let educationActivitySchedulePackIds = [];
            let eFormIds = [];
            let learnerIds = [];

            angular.forEach(
                vm.cancelApplicationForPerson,
                function (learner, index) {
                    educationActivitySchedulePackIds.push(
                        learner.schedulePackId
                    );
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.id);
                }
            );

            schedulesDataService.cancelApplication(
                educationActivitySchedulePackIds,
                eFormIds,
                learnerIds,
                vm.formData.selectedSchedule
            );
            vm.showCancelApprovalButton = false;
        }

        function downloadCertificate(learningPlanItemData) {
            if (canDownloadCertificate(learningPlanItemData)) {
                documentsDataService
                    .downloadDocument(
                        "api/learningPlans/" + learningPlanItemData.learningPlanItemId + "/certificate",
                        "Potrdilo-" + learningPlanItemData.learningPlanItemId + ".pdf"
                    )
                    .then(
                        function (data) { },
                        function (error) {
                            error = {
                                exception: {
                                    additionalData: {},
                                    result: {
                                        exceptionCode: -90007,
                                        operationCode: 0,
                                    },
                                },
                                status: 400,
                            };
                            exceptionHelper.handleException(error);
                        }
                    );
            }
        }

        function canDownloadCertificate(learningPlanItemData) {
            return learningPlanItemData.certificateExists
                && learningPlanItemData.learningPlanItemStatus == vm.formData.learningPlanItemStatus.finished
                && learningPlanItemData.learningPlanItemSuccessStatus === vm.formData.learningPlanItemSuccess.success;
        }

        function regenerateCetificate(learningPlanItemData, learningPlanItemSuccessStatus) {
            if (canRegenerateCertificate(learningPlanItemData, learningPlanItemSuccessStatus)) {
                documentsDataService.regenerateCertificate(learningPlanItemData.learningPlanItemId).then(
                    function (data) {
                        logger.success(
                            i18nFilter(
                                "translations.education_regenerate_certificate_queue_add"
                            )
                        );
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
            }
        }

        function canRegenerateCertificate(learningPlanItemData, learningPlanItemSuccessStatus) {
            return learningPlanItemData.templateGenerationEnabled
                && learningPlanItemData.learningPlanItemStatus == vm.formData.learningPlanItemStatus.finished
                && learningPlanItemSuccessStatus === vm.formData.learningPlanItemSuccess.success;
        }

        function sendMailToSelectedLearners() {
            var subject = undefined;
            var to = "";
            if (vm.formData.schedulePack.educationName) {
                subject = vm.getFormTitle(true);
            } else {
                subject = vm.getFormTitle(true);
            }

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (vm.formData.selectedPersons.length == index - 1) {
                        to += learner.email;
                    } else {
                        to += learner.email + ";";
                    }
                }
            );

            window.location.href = "mailto:?bcc=" + to + "&subject=" + subject;
        }

        function checkLearningActivityScheduleStatus(item) {
            if (item.eFormStatus === 4) {
                vm.showCancelApprovalButton = true;
                vm.cancelApplicationForPerson.push(item);
            } else {
                vm.showCancelApprovalButton = false;
                logger.log(
                    "vm.showCancelApprovalButton2",
                    vm.showCancelApprovalButton
                );
            }
        }
    }
})();
