(function () {
    'use strict';

    angular
        .module('app.tenants')
        .factory('editTenantHelper', editTenantHelper);

    editTenantHelper.$inject = ['$stateParams', 'logger', '$state', 'tenantDataService', 'exceptionHelper', 'i18nFilter'];

    function editTenantHelper($stateParams, logger, $state, tenantDataService, exceptionHelper, i18nFilter) {
        var internalData = undefined;
        var wizardSteps = undefined;        

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            setData: setData,
            clearData: clearData,
            done: done
        };

        return service;

        function getData() {
            if (!internalData) {
                internalData = {
                    id: undefined,
                    name: undefined,                    
                    externalId: undefined,
                    configuration: {                        
                        applicationConfig: {},
                        authenticationAndAuthorization: {
                            initialUser: undefined,
                            authorizationConfig: {
                                userManagementEnabled: false,
                                roleMappingEnabled: false,
                                roleMapping: []
                            }
                        },
                        dms: {                            
                            dmsProviderConfiguration: {                                
                                rootFolder: ''
                            },
                            digitalSignature: {
                                oldImageFileName: undefined,
                                deleteDigitalSignature: false,
                                certificateFileName: undefined,
                                imageFileName: undefined,
                                password: undefined
                            },
                            shareConfiguration: {
                                domain: undefined,
                                userName: undefined,
                                password: undefined
                            }
                        },                        
                        featureSettings: {
                            maintenancePageConfiguration: {
                                showMaintenancePage: false,
                                imageId: null
                            },
                            categories: {
                                access: true,
                                maxDepthLevel: undefined,
                                mode: 1
                            }
                        },
                        notificationServiceConfig: {
                            emailService: {
                                provider: 0,
                                smtpConfig: {
                                    host: undefined,
                                    port: 25,
                                    useDefaultCredentials: false,
                                    credentials: {
                                       Username: undefined,
                                       Password: undefined
                                       },
                                    enableSSL: false,
                                    spn: undefined
                                },
                                fromAddress: undefined,
                                fromTitle: undefined,
                                replyToAddress: undefined,
                                bcc: undefined,
                                defaultFont: 'Calibri',
                                defaultFontSize: 11,
                                defaultFontColor: '#000000'
                            }
                        }
                    }
                };
            }
            return internalData;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'tenants.baseEdit.firstStep',
                        text: 'General'
                    },
                    {
                        state: 'tenants.baseEdit.secondStep',
                        text: 'File management'
                    },
                    {
                        state: 'tenants.baseEdit.thirdStep',
                        text: 'Features & Options'
                    }
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'tenants.baseAdd.firstStep',
                        text: 'General'
                    },
                    {
                        state: 'tenants.baseAdd.secondStep',
                        text: 'File managementt'
                    },
                    {
                        state: 'tenants.baseAdd.thirdStep',
                        text: 'Features & Options'
                    }
                ];
            }

            return wizardSteps;
        }

        function setData(tenant) {
            internalData = tenant;
        }

        function clearData() {
            internalData = undefined;
        }

        function done(isEdit) {
            //Call right data service method

            if (isEdit) {
                return tenantDataService.editTenant(angular.copy(internalData)).then(function (additionalData) {
                    logger.success(i18nFilter('translations.tenant_operation_edit_success_message', additionalData.name));
                    $state.go("tenants", $stateParams, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
            else {
                tenantDataService.addTenant(angular.copy(internalData)).then(function (additionalData) {
                    logger.success(i18nFilter('translations.tenant_operation_add_success_message', additionalData.name));
                    $state.go("tenants");
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }
    }
})();
