(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schoolingSchedulePackApplications', schoolingSchedulePackApplications);

    schoolingSchedulePackApplications.$inject = ['$stateParams', '$state', '$scope', 'dateFilter', 'i18nFilter', 'exceptionHelper', 'logger', 'schoolingApplications', 'schoolingNameAndScheduleCode', 'examApplicationStatus', 'schoolingApplicationLearnerAttendance', '$http', 'schoolingSchedulePacksDataService', 'schoolingApplicationsDataService', 'documentsDataService', '$uibModal', "eFormStatus"];

    function schoolingSchedulePackApplications($stateParams, $state, $scope, dateFilter, i18nFilter, exceptionHelper, logger, schoolingApplications, schoolingNameAndScheduleCode, examApplicationStatus, schoolingApplicationLearnerAttendance, $http, schoolingSchedulePacksDataService, schoolingApplicationsDataService, documentsDataService, $uibModal, eFormStatus) {
        var vm = this;
        vm.nameAndCode = schoolingNameAndScheduleCode;
        vm.schoolingApplications = schoolingApplications;
        vm.updateLearnerAttendance = updateLearnerAttendance;
        vm.inspectApplication = inspectApplication;
        vm.sendFinishSchoolingEmailPerLearner = sendFinishSchoolingEmailPerLearner;
        vm.sendFinishSchoolingEmail = sendFinishSchoolingEmail;
        vm.downloadListOfApprovedLearners = downloadListOfApprovedLearners;
        vm.sendScheduleChangeNotification = sendScheduleChangeNotification;
        vm.sendScheduleChangeEmail = sendScheduleChangeEmail;
        vm.openAddApplicationForm = openAddApplicationForm;
        vm.downloadApplicationDocument = downloadApplicationDocument;
        vm.downloadAllApplicationsDocument = downloadAllApplicationsDocument;
        vm.downloadApplicationCertificate = downloadApplicationCertificate;
        vm.reGenerateSchoolingDocument = reGenerateSchoolingDocument;
        vm.deleteSchoolingDocument = deleteSchoolingDocument;
        vm.applicationActions = applicationActions;
        vm.sendMailToSelectedLearners = sendMailToSelectedLearners;
        vm.currentPageIndex = $stateParams.currentPageIndex ? $stateParams.currentPageIndex : 0;

        vm.gridInstance = null; // Create a variable to hold the DataGrid instance

        vm.formData = {
            examApplicationStatus: examApplicationStatus,
            schoolingApplicationLearnerAttendance: schoolingApplicationLearnerAttendance,
            selectedPersons: [],
            showConfirmButton: false,
            showReturnButton: false,
            showRejectButton: false,
            showAddToWaitingQueueButton: false,
            showSendEmail: false
        };

        vm.localeMessages = {
            inspectTooltip: i18nFilter('translations.schoolingSchedule_gui_applications_openApplication'),
            finishSchoolingEmailPerLearnerTooltip: i18nFilter('translations.schoolingSchedule_gui_applications_sendFinishSchoolingEmailPerLearner'),
            editResultTooltip: i18nFilter('translations.examSchedulePack_applications_gui_action_edit_result'),
            documentsTooltip: i18nFilter('translations.examSchedulePack_applications_gui_action_documents'),
            applicationDocument: i18nFilter('translations.examSchedulePack_applications_gui_action_applicationDocument'),
            allApplicationsDocument: i18nFilter('translations.examSchedulePack_applications_gui_action_allApplicationsDocument'),
            downloadSchoolingDocument: i18nFilter('translations.schoolingSchedule_gui_applications_downloadSchoolingDocument'),
            reGenerateSchoolingDocument: i18nFilter('translations.schoolingSchedule_gui_applications_reGenerateSchoolingDocument'),
            deleteSchoolingDocument: i18nFilter('translations.schoolingSchedule_gui_applications_deleteSchoolingDocument'),
        };

        vm.applicationStatus = [

            {
                id: 1,
                format: i18nFilter('translations.schoolingSchedulePack_applications_gui_label_finished')
            },
            {
                id: 2,
                format: i18nFilter('translations.schoolingSchedulePack_applications_gui_label_approved')
            },
            {
                id: 3,
                format: i18nFilter('translations.schoolingSchedulePack_applications_gui_label_statusRejected')
            },
            {
                id: 7,
                format: i18nFilter('translations.filter_eform_status_pending_personal_data')
            },
            {
                id: 8,
                format: i18nFilter('translations.schoolingSchedulePack_applications_gui_label_waiting')
            },
            {
                id: 9,
                format: i18nFilter('translations.schoolingSchedulePack_applications_gui_label_disenrolled')
            }
        ];

        vm.applicationLearnerAttendance = [
            {
                id: 1,
                format: i18nFilter('translations.schoolingSchedulePack_learnerAttendance_gui_label_yes')
            },
            {
                id: 0,
                format: i18nFilter('translations.schoolingSchedulePack_learnerAttendance_gui_label_no')
            },
            {
                id: 2,
                format: i18nFilter('translations.schoolingSchedulePack_learnerAttendance_gui_label_justifiedMissed')
            }
        ];

        vm.showCancelApprovalButton = false;

        var columns = [
            {
                dataField: 'firstName',
                caption: "Ime",
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                allowEditing: false,
                sortingMethod: function (value1, value2) {
                    if (!value1 && value2) return -1;
                    if (!value1 && !value2) return 0;
                    if (value1 && !value2) return 1;
                    return value1.localeCompare(value2, "sl-SI");
                }
            },
            {
                dataField: 'lastName',
                caption: "Priimek",
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                allowEditing: false,
                sortingMethod: function (value1, value2) {
                    if (!value1 && value2) return -1;
                    if (!value1 && !value2) return 0;
                    if (value1 && !value2) return 1;
                    return value1.localeCompare(value2, "sl-SI");
                }
            },
            {
                dataField: 'employer',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_employer'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: false,
                allowEditing: false
            },
            {
                dataField: 'workplace',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_workplace'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: false,
                allowEditing: false
            },
            {
                dataField: 'orderNumber',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_orderNumber'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                sortOrder: 'asc',   //options 'desc' or 'asc' || IMPORTANT!!! Only one sortOrder can be active out of all columns at once!!!
                allowEditing: false
            },
            {
                dataField: 'learnerAttended',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_schoolingAttendance'),
                alignment: 'center',
                filterOperations: ['contains'],
                allowFiltering: true,
                cssClass: 'hide-revert-button',
                lookup: {
                    dataSource: vm.applicationLearnerAttendance,
                    valueExpr: 'id',
                    displayExpr: 'format'
                },
                allowSorting: false,
                allowEditing: true
            },
            {
                dataField: 'applicationStatus',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_applicationStatus'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                lookup: {
                    dataSource: vm.applicationStatus,
                    valueExpr: 'id',
                    displayExpr: 'format'
                },
                allowSorting: false,
                allowEditing: false
            },
            {
                dataField: 'disenrollDate',
                caption: "Datum Odjave",
                datatype: "date",
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                allowEditing: false,
                //sortOrder: 'desc',    //causes BUG that automatically makes it prefered default sorted item
                customizeText: function (args) {
                    return dateFilter(args.value, 'medium');
                }
            },
            {
                dataField: 'createdOn',
                caption: "Datum Prijave",
                datatype: "date",
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                allowEditing: false,
                //sortOrder: 'desc',    //causes BUG that automatically makes it prefered default sorted item
                customizeText: function (args) {
                    return dateFilter(args.value, 'medium');
                }
            },
            {
                dataField: 'schoolingOwnerComment',
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_schoolingOwnerNote'),
                alignment: 'left',
                allowFiltering: false,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: false,
                allowEditing: false
            },
            {
                dataField: 'finishedSchoolingDate',
                datatype: "date",
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                allowEditing: false,
                //sortOrder: 'desc',    //causes BUG that automatically makes it prefered default sorted item
                caption: i18nFilter('translations.schoolingSchedule_gui_applications_finishedSchoolingDate'),
                customizeText: function (args) {
                    return dateFilter(args.value, 'medium');
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                cellTemplate: 'schoolingApplicationActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.schoolingApplications,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                pageIndex: vm.currentPageIndex,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            editing: {
                mode: "cell",
                allowUpdating: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            onRowUpdated: function (e) {
                vm.updateLearnerAttendance(e.key.id, e.data.learnerAttended);
            },
            sorting: {
                mode: 'multiple'
            },
            selection: {
                mode: "multiple",
            },
            onSelectionChanged: function (selectedItems) {
                logger.log("Selection occurred: ", selectedItems);

                vm.formData.selectedPersons = selectedItems.selectedRowsData;
                if (
                    vm.formData.selectedPersons &&
                    vm.formData.selectedPersons.length !== 0
                ) {
                    checkRequirementsForActions();
                    vm.formData.showSendEmail = true;
                } else {
                    vm.formData.showConfirmButton = false;
                    vm.formData.showReturnButton = false;
                    vm.formData.showRejectButton = false;
                    vm.formData.showAddToWaitingQueueButton = false;
                    vm.formData.showSendEmail = false;
                }
            },
        };

        function inspectApplication(applicationLearner) {
            $state.go("schoolingSchedulePacks.application", { "applicationId": applicationLearner.id });
        }

        function sendFinishSchoolingEmailPerLearner(learner) {

            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/applications/toggleApplicationFinish/toggleApplicationFinish.html',
                controller: 'toggleApplicationFinish',
                controllerAs: 'vm'
            })
                .result.then(function (result) {
                    schoolingSchedulePacksDataService.finishSchoolingEmailPerLearner(learner.id).then(function (data) {
                        logger.success(i18nFilter('translations.schoolingSchedule_gui_applications_finishQueue'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }, function () {
                });
        }

        function sendFinishSchoolingEmail() {

            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/applications/toggleApplicationFinish/toggleApplicationFinish.html',
                controller: 'toggleApplicationFinish',
                controllerAs: 'vm'
            })
                .result.then(function (result) {
                    schoolingSchedulePacksDataService.finishSchoolingEmail($stateParams.schedulePackId).then(function (data) {
                        logger.success(i18nFilter('translations.schoolingSchedule_gui_applications_finishQueue'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }, function () {
                });


        }

        function downloadListOfApprovedLearners() {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/' + $stateParams.schedulePackId + '/learnersList', 'Lista udeležencev.xlsx');
        }

        function downloadApplicationDocument(applicationData) {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/applicationDocument/' + applicationData.id, 'Izpis prijave št ' + applicationData.orderNumber + '.docx');
        }

        function downloadApplicationCertificate(applicationData) {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/applicationCertificate/' + applicationData.id, 'Potrdilo o udeležbi na izobraževanju.pdf');
        }


        function downloadAllApplicationsDocument() {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/allApplicationsDocument/' + $stateParams.schedulePackId, 'Izpis vseh prijav.docx');
        }

        function reGenerateSchoolingDocument(applicationData) {
            $http.post('api/schoolingApplications/regenerateApplicationCertificate/' + applicationData.id).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedule_gui_applications_successfullyReGeneratedSchoolingDocument'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function deleteSchoolingDocument(applicationData) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/applications/deleteSchoolingDocumentModal/deleteSchoolingDocumentModal.html',
                controller: 'deleteSchoolingDocumentModal',
                controllerAs: 'vm'
            })
                .result.then(function () {
                    // prepare the VM data
                    const basicSchoolingApplicationLearner = {
                        id: applicationData.id,
                        userId: applicationData.userId,
                        schoolingApplicationId: applicationData.schoolingApplicationId,
                        applicationStatus: applicationData.applicationStatus
                    }

                    // after clicking OK button
                    schoolingApplicationsDataService.deleteSchoolingCertificateDocument(basicSchoolingApplicationLearner);

                    // refresh here
                    $state.reload();
                }, function () {
                    // after clicking Cancel button or clicking background
                });
        }

        function updateLearnerAttendance(learnerId, isAttending) {
            var data = { 'learnerAttended': isAttending, 'id': learnerId };

            $http.post('api/schoolingApplications/changeLearnerAttedance', data)
                .then(function (data) {
                    if ($state.current.controller === 'schoolingSchedulePackApplication') {
                        $state.go('^', null, { reload: true });
                    }
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });


        }

        function sendScheduleChangeNotification() {
            schoolingSchedulePacksDataService.schoolingScheduleChange($stateParams.schedulePackId).then(function (data) {
                window.location.href = "mailto:\?bcc=" + data;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function sendScheduleChangeEmail() {
            schoolingSchedulePacksDataService.schoolingScheduleNotification($stateParams.schedulePackId).then(function (data) {
                window.location.href = "mailto:" + data.to + "?subject=" + data.subject + "&body=" + encodeURI(data.formattedBody);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function openAddApplicationForm() {
            $state.go("schoolingSchedulePacks.addApplication", { "schedulePackId": $stateParams.schedulePackId });
        }
        
        function checkRequirementsForActions() {
            showApplicationResponces();
        }

        function showApplicationResponces() {
            let confirmCondition = false;
            let returnCondition = false;
            let rejectCondition = false;
            let addToWaitingQueueCondition = false;

            let approved = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.approved);
            let finished = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.finished);
            let rejected = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.rejected);
            let pendingPersonalData = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.pendingPersonalData);
            let queued = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.waiting);
            let disenrolled = vm.formData.selectedPersons.filter(x => x.applicationStatus === examApplicationStatus.disenrolled);

            if ((finished.length > 0 || queued.length > 0) && rejected.length == 0 && pendingPersonalData.length == 0 && approved.length == 0 && disenrolled.length == 0)
            {
                confirmCondition = true;
                rejectCondition = true
                returnCondition = true;
                addToWaitingQueueCondition = true;
            }

            if ((pendingPersonalData.length > 0 || approved.length > 0) && rejected.length == 0 && pendingPersonalData.length == 0 && disenrolled.length == 0)
            {
                returnCondition = true;
                confirmCondition = false;
                rejectCondition = false;
                addToWaitingQueueCondition = false;
            }

            if (queued.length > 0 && approved.length == 0 && rejected.length == 0 && pendingPersonalData.length == 0  && disenrolled.length == 0)
            {
                addToWaitingQueueCondition = false;
                confirmCondition = false;
                returnCondition = false;
                rejectCondition = true;
            }

            vm.formData.showConfirmButton = confirmCondition;
            vm.formData.showReturnButton = returnCondition;
            vm.formData.showRejectButton = rejectCondition;
            vm.formData.showAddToWaitingQueueButton = addToWaitingQueueCondition;
        }

        function applicationActions(type) {
            var modalVm = {
                modalTitle: '',
                selectedPersons: vm.formData.selectedPersons,
                newApplicationStatus: undefined,
                schoolingSchedulePackId: $stateParams.schedulePackId,
                sendEmailNotification: true,
                returnToPageIndex: vm.gridInstance.pageIndex()
            };
            if (type === "confirm")
            {
                modalVm.modalTitle = i18nFilter('translations.schoolingSchedulePack_applications_action_modal_title_approve');
                modalVm.newApplicationStatus = 2;
            }
            else if (type === "return")
            {
                modalVm.modalTitle = i18nFilter('translations.schoolingSchedulePack_applications_action_modal_title_return');
                modalVm.newApplicationStatus = 7;
            }
            else if (type === "reject")
            {
                modalVm.modalTitle = i18nFilter('translations.schoolingSchedulePack_applications_action_modal_title_reject');
                modalVm.newApplicationStatus = 3;
            }
            else if (type === "waiting")
            {
                modalVm.modalTitle = i18nFilter('translations.schoolingSchedulePack_applications_action_modal_title_waiting_list');
                modalVm.newApplicationStatus = 8;
            }

            if (modalVm.modalTitle && modalVm.selectedPersons.length > 0){
                $uibModal.open({
                    templateUrl: 'app/schoolingSchedulePacks/applications/applicationsActionModal/applicationActionModal.html',
                    controller: 'applicationActionModal',
                    controllerAs: 'vm',
                    resolve: {
                        applicationData: function () {
                            return modalVm;
                        }                
                    }
                });
            }
        }

        function sendMailToSelectedLearners() {
            var startDate = ""
            var endDate = ""

            if (vm.nameAndCode.startDate){
                startDate = vm.nameAndCode.startDate;
            }
            if (vm.nameAndCode.endDate){
                endDate = vm.nameAndCode.endDate;
            }

            var subject = vm.nameAndCode.schoolingName + " " + startDate + " - " + endDate;
            var to = "";

            angular.forEach(
                vm.formData.selectedPersons,
                function (learner, index) {
                    if (vm.formData.selectedPersons.length == index - 1) {
                        to += learner.user.email;
                    } else {
                        to += learner.userEmail + ";";
                    }
                }
            );

            window.location.href = "mailto:?bcc=" + to + "&subject=" + subject;
        }
    }
})();
