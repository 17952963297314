(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('guidGeneratorDataService', guidGenerator);

    guidGenerator.$inject = ['$http'];

    function guidGenerator($http) {
        var service = {
            getGuid: getGuid,
            getEmptyGuid: getEmptyGuid
        };

        return service;

        function getGuid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                  .toString(16)
                  .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
              s4() + '-' + s4() + s4() + s4();
        }

        function getEmptyGuid() {
            return "00000000-0000-0000-0000-000000000000";
        }
    }
})();