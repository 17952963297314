(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('urlHelper', urlHelper);

    urlHelper.$inject = ['logger'];

    function urlHelper(logger) {
        var service = {
            getHostParts: getHostParts,
            parseUrlParams: parseUrlParams
        };

        return service;

        function getHostParts() {
            var result = {};
            var regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
            var urlParts = regexParse.exec(window.location.hostname);
            result.domain = urlParts[1];
            result.type = urlParts[2];
            result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);;
            return result;
        }

        function parseUrlParams(query) {
            var vars = query.split("&");
            var query_string = {};
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                // If first entry with this name
                if (typeof query_string[pair[0]] === "undefined") {
                    query_string[pair[0]] = decodeURIComponent(pair[1]);
                    // If second entry with this name
                } else if (typeof query_string[pair[0]] === "string") {
                    var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                    query_string[pair[0]] = arr;
                    // If third or later entry with this name
                } else {
                    query_string[pair[0]].push(decodeURIComponent(pair[1]));
                }
            }
            return query_string;
        }
    }
})();