(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('contractsDataService', contract);

    contract.$inject = ['$http', 'dateHelper', '$q'];

    function contract($http, dateHelper, $q) {

        var service = {
            getAllContracts: getAllContracts,
            getAllContractsExcel: getAllContractsExcel,
            getContractById: getContractById,
            addContract: addContract,
            editContract: editContract,
            deleteContract: deleteContract,
            getInstructorsContracts: getInstructorsContracts,
            addSchoolingScheduleContractCreate: addSchoolingScheduleContractCreate,
            addSchoolingScheduleContractLink: addSchoolingScheduleContractLink,
            SchoolingScheduleContractUnlink: SchoolingScheduleContractUnlink,
            getAllContractsSimple: getAllContractsSimple,
            editIntructorBillingExecutionCost: editIntructorBillingExecutionCost
        };

        return service;
        
        function getAllContracts(filter) {
            filter.start.searchText1 = dateHelper.convertFromDateToString(filter.start.searchText1);
            filter.start.searchText2 = dateHelper.convertFromDateToString(filter.start.searchText2);

            filter.end.searchText1 = dateHelper.convertFromDateToString(filter.end.searchText1);
            filter.end.searchText2 = dateHelper.convertFromDateToString(filter.end.searchText2);

            return $http.post('api/contracts/filter', filter)
                .then(function (data) {
                    angular.forEach(data.data.data, function (element, index) {
                        element.start = dateHelper.convertFromStringToDate(element.start);
                        element.end = dateHelper.convertFromStringToDate(element.end);                        
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getAllContractsExcel(filter) {
            filter.start.searchText1 = dateHelper.convertFromDateToString(filter.start.searchText1);
            filter.start.searchText2 = dateHelper.convertFromDateToString(filter.start.searchText2);

            filter.end.searchText1 = dateHelper.convertFromDateToString(filter.end.searchText1);
            filter.end.searchText2 = dateHelper.convertFromDateToString(filter.end.searchText2);

            return $http.post('api/contracts/export', filter, { headers: { 'Content-type': 'application/json' }, responseType: 'blob' })
                .then(function (data) {
                    angular.forEach(data.data.data, function (element, index) {
                        element.start = dateHelper.convertFromStringToDate(element.start);
                        element.end = dateHelper.convertFromStringToDate(element.end);
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }


        function getContractById(contractId) {
            return $http.get('api/contracts/' + contractId)
                .then(function (data) {
                    data.data.start = dateHelper.convertFromStringToDate(data.data.start);
                    data.data.end = dateHelper.convertFromStringToDate(data.data.end);                    
                    data.data.signingDate = dateHelper.convertFromStringToDate(data.data.signingDate);                    
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function addContract(contract) {       
            contract.start = dateHelper.convertToDateTime(contract.start);
            contract.end = dateHelper.convertToDateTime(contract.end);
            contract.signingDate = dateHelper.convertToDateTime(contract.signingDate);
            
            return $http.post('api/contracts', contract).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSchoolingScheduleContractCreate(contract, employeeId, scheduleId) {
            contract.start = dateHelper.convertToDateTime(contract.start);
            contract.end = dateHelper.convertToDateTime(contract.end);
            contract.signingDate = dateHelper.convertToDateTime(contract.signingDate);

            var transferData = {
                Contract: contract,
                SpeakerId: employeeId,
                ScheduleId: scheduleId
            };

            return $http.post('api/contracts/createSchoolingContract', transferData).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function addSchoolingScheduleContractLink(contractId, employeeId, scheduleId) {
            var transferData = {
                ContractId: contractId,
                SpeakerId: employeeId,
                ScheduleId: scheduleId
            };

            return $http.post('api/contracts/linkSchoolingContract', transferData).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllContractsSimple() {
            return $http.get('api/contracts/getAllContracts')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function SchoolingScheduleContractUnlink(contractId, employeeId, scheduleId) {
            return $http.delete('api/contracts/schoolingContract/' + contractId + '/' + employeeId + '/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editContract(contract) {
            contract.start = dateHelper.convertToDateTime(contract.start);
            contract.end = dateHelper.convertToDateTime(contract.end);
            contract.signingDate = dateHelper.convertToDateTime(contract.signingDate);
           
            return $http.put('api/contracts', contract).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteContract(contractId) {
            return $http.delete('api/contracts/' + contractId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getInstructorsContracts(instructorIds, educationId, startTime) {
            startTime = dateHelper.convertFromDateToString(startTime);
            var searchData = {
                instructorIds: instructorIds,
                educationId: educationId,
                startTime: startTime
            };
            return $http.post('api/contracts/instructors', searchData).then(function (data) {
                angular.forEach(data.data, function (element, index) {
                    element.start = dateHelper.convertFromStringToDate(element.start);
                    element.end = dateHelper.convertFromStringToDate(element.end);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editIntructorBillingExecutionCost(editedCost) {
            return $http.post('api/schoolingSchedulePacks/' + editedCost.scheduleId + '/editExecutionCost', editedCost).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
