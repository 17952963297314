(function () {
    'use strict';

    angular
        .module('app.schoolings')
        .controller('Schoolings', schoolings);

    schoolings.$inject = [
        'logger',
        'schooling',
        '$rootScope',
        '$location',
        'rolesDataService',
        'schoolingsDataService',
        'isSchoolingOwner',
        'i18nFilter',
        '$sessionStorage',
        '$state',
        '$stateParams',
        'eFormsUrl',
        'exceptionHelper'
    ];

    function schoolings(
        logger,
        schooling,
        $rootScope,
        $location,
        rolesDataService,
        schoolingsDataService,
        isSchoolingOwner,
        i18nFilter,
        $sessionStorage,
        $state,
        $stateParams,
        eFormsUrl,
        exceptionHelper
    ) {
        var vm = this;

        vm.schooling = schooling;
        vm.isSmallCover = $rootScope.isSmallCover;


        vm.status = status;

        vm.localeMessages = {
            schoolingsNumberOfDocuments: i18nFilter('translations.schooling_gui_tooltip_number_of_documents'),
        };

        vm.formData = {
            isSmallCover: $rootScope.isSmallCover,
            navAuth: {
                about: rolesDataService.isUserInRoles(['Learner']),
                documents: (rolesDataService.isUserInRoles(['Learner'])) || isSchoolingOwner,
                learnerDocuments: rolesDataService.isUserInRoles(['Learner']),
                discussion: rolesDataService.isUserInRoles(['Learner']),
                manage: isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin', 'SchoolingAdmin']),

                manageEdit: isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageDelete: rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageActivities: isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin', 'SchoolingAdmin']),
                managePointsCriteria: isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                managePoints: isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageLearnerPoints: false//isSchoolingOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),                
            }
        };


        vm.imageStyle = {
            "background-image": "url('api/schoolings/" + vm.schooling.id + "/defaultPicture')",
            "min-height": "340px"
        };

        $rootScope.$on('smallCoverChanged', function () {
            vm.formData.isSmallCover = $rootScope.isSmallCover;
        });

        vm.goToDocuments = goToDocuments;
        vm.openSchoolingPreview = openSchoolingPreview;
        vm.resetSchoolingEnrollment = resetSchoolingEnrollment;


        function goToDocuments() {

            return $state.go('schoolings.FSDocuments');
        }

        function openSchoolingPreview() {
            //Provjeri jesam li upisan u edukaciju
            schoolingsDataService.checkUserEnrollment($stateParams.id).then(function (data) {
                var index = eFormsUrl.indexOf('eForms');
                var baseUrlForNewApplication = eFormsUrl.substring(0, index);
                //ako jesam otvroi mi novu aplikaciju na stranicu edukacije
                if (data) {
                    window.open(baseUrlForNewApplication + 'schoolings/' + $stateParams.id + '/info', '_blank');
                }
                //ako nisam otvori mi e prijavu s odabranom edukacijom u DDLu
                else {
                    window.open(baseUrlForNewApplication + 'eForms/add/schooling/' + $stateParams.id, '_blank');
                }

            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function resetSchoolingEnrollment() {
            //Provjeri jesam li upisan u edukaciju
            schoolingsDataService.resetSchoolingEnrollment($stateParams.id).then(function (data) {
                logger.success(i18nFilter('translations.schooling_reset_queue'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        //Configuration for marking top level navigation properties if we are on sub level
        vm.isActive = function (view) {
            var path = $location.path();
            switch (view) {
                case '/home':
                    if (path.endsWith('/home')) {
                        return true;
                    }
                    break;
                case '/administration/activities':
                    if (path.endsWith('/administration/activities')) {
                        return true;
                    }
                    break;
                case '/documents':
                    if (path.endsWith('/documents/fs') || path.endsWith('/documents/sp')) {
                        return true;
                    }
                    break;
                case '/learnerDocuments':
                    if (path.endsWith('/learnerDocuments')) {
                        return true;
                    }
                    break;
                case '/discussionGroup':
                    if (path.endsWith('/group')) {
                        return true;
                    }
                    break;
                case '/administration':
                    if (
                        path.endsWith('/administration/edit') ||
                        path.endsWith('/administration/edit/about') ||
                        path.endsWith('/administration/points') ||
                        path.endsWith('/administration/learners')

                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
    }
})();
