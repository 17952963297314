(function () {
    'use strict';

    angular
        .module('app.categories')
        .controller('DeleteCategory', deleteCategory);

    deleteCategory.$inject = ['$scope', 'logger', '$state', '$stateParams', 'exceptionHelper', 'categoriesDataService', 'i18nFilter'];

    function deleteCategory($scope, logger, $state, $stateParams, exceptionHelper, categoriesDataService, i18nFilter) {
        var vm = this;
        vm.title = $state.current.data.title;
        vm.categoryName = $stateParams.categoryName;
        vm.cancel = cancel;
        vm.ok = ok;
               
        
        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            categoriesDataService.deleteCategory($stateParams.id).then(function (result) {
                logger.success(i18nFilter("translations.category_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
                $scope.$dismiss();
            });
        };
    }
})();
