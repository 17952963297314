(function () {
    'use strict';

    angular
        .module('app.exams.workplace')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('workplace', {
                parent: 'base',
                url: '/manage/workplace',
                templateUrl: 'app/manage/workplace/examWorkplace.html',
                controller: 'examWorkplace',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    workplaces: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveExamFieldNames();
                    }]
                },
                data: {
                    roles: ['ExamAdmin']
                }
            })
            .state('workplace.edit', {
                url: '/edit/:id',
                templateUrl: 'app/manage/workplace/edit/editExamWorkplace.html',
                controller: 'editExamWorkplace',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    workplace: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return {};
                    }]  
                },
                data: {
                    isEdit: true,
                    roles: ['ExamAdmin']
                }
            })
            .state('workplace.add', {
                url: '/add',
                templateUrl: 'app/manage/workplace/edit/editExamWorkplace.html',
                controller: 'editExamWorkplace',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    workplace: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return {};
                    }]  
                },
                data: {
                    isEdit: false,
                    roles: ['ExamAdmin']
                }
            });
    }
})();
