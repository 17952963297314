(function () {
    'use strict';

    angular
        .module('app')
        .controller('EducationDocumentsFileSystemAddLink', addLink);

    addLink.$inject = ['logger', 'documentsDataService', '$scope', 'path', 'exceptionHelper'];

    function addLink(logger, documentsDataService, $scope, path, exceptionHelper) {
        var vm = this;

        vm.cancel = cancel;
        vm.ok = ok;
        vm.name = undefined;
        vm.url = undefined;
        vm.noDots = noDots;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            documentsDataService.addLink(path, vm.url, vm.name).then(function (data) {
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };

        function noDots() {
            if (vm.name && vm.name.indexOf(".") != -1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    
})();



