(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('organizationLevel', organizationLevel);

    organizationLevel.$inject = ['logger', 'organizationLevels', 'i18nFilter'];

    function organizationLevel(logger, organizationLevels, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value == organizationLevels.companyGroup) {
                return i18nFilter('translations.filter_organization_level_company_group');
            }
            else if (value == organizationLevels.company) {
                return i18nFilter('translations.filter_organization_level_company');
            }
            else if (value == organizationLevels.organizationUnit) {
                return i18nFilter('translations.filter_organization_level_organization_unit');
            }
        };
    }
})();