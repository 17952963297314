(function () {
    'use strict';

    angular
        .module('app')
        .controller('EducationDocumentsFileSystemNewFolder', newFolder);

    newFolder.$inject = ['logger', 'documentsDataService', '$scope', 'path', 'exceptionHelper'];

    function newFolder(logger, documentsDataService, $scope, path, exceptionHelper) {
        var vm = this;

        vm.cancel = cancel;
        vm.ok = ok;
        vm.name = undefined;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            documentsDataService.newFolder(path, vm.name).then(function (data) {
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });            
        };
    }
})();
