(function () {
    'use strict';

    angular
        .module('app.exams.workplace')
        .controller('examWorkplace', examWorkplace);

    examWorkplace.$inject = ['i18nFilter', 'exceptionHelper', 'logger', 'examsDataService', 'workplaces'];

    function examWorkplace(i18nFilter, exceptionHelper, logger, examsDataService, workplaces) {

        var vm = this;
        vm.workplaces = workplaces.find(x => x.id === '3065a99a-0671-4824-bddf-4594f61cb749');
        vm.replace = replace;
        vm.modifiedDefinitions = vm.replace();
        var definitions = [];
        var arr = JSON.parse(vm.modifiedDefinitions);

        arr.forEach((item, i) => {
            var newitem = [];
            newitem.id = i + 1;
            newitem.definition = item;
            definitions.push(newitem);
        });

        vm.save = save;

        function replace() {
            if (vm.workplaces != undefined) {
                if (vm.workplaces.definition === null) {
                    return "[]";
                }
                else {
                    return vm.workplaces.definition.replace(/[']+/g, '"');
                }
            }
            else {
                return "[]";
            }
        }

        function save() {
            
            vm.workplaces.id = '3065a99a-0671-4824-bddf-4594f61cb749';
            var def = definitions.map(function (item) {
                return ( "'" + item['definition'] + "'");
            });
            vm.workplaces.definition = "[" + def.toString() + "]";

            examsDataService.updateExamFieldDefinition(vm.workplaces);
        }

        var columns = [
            {
                dataField: "definition",
                caption: "Ime organa zaposlitve",
                alignment: 'left',
                width: "100%",
                allowSorting: true
            }
        ];

        vm.dataGridOptions = {
            dataSource: definitions,
            columns: columns,
            editing: {
                allowUpdating: true,
                allowAdding: true,
                allowDeleting: true,
                mode: 'row'
            },
            filterRow: { visible: true },
            searchPanel: { visible: false },
            cellHintEnabled: true,
            hoverStateEnabled: true,
            wordWrapEnabled: false,
            sorting: {
                mode: 'single'
            },
            width:"100%"
        };

    }
})();
