(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('notificationSendingType', notificationSendingType);

    notificationSendingType.$inject = ['logger', 'i18nFilter'];

    function notificationSendingType(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }

            var value = Number(input);

            switch (value) {
                case 1:
                    return i18nFilter('translations.email_filter_notification_sending_type_every_day');
                case 2:
                    return i18nFilter('translations.email_filter_notification_sending_type_day_of_week');
                case 3:
                    return i18nFilter('translations.email_filter_notification_sending_type_day_of_month');
            }
        };
    }
})();