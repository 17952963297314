(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.delete')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolings.administration.delete', {
                url: '/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', 'schoolingsDataService', function ($stateParams, $state, $uibModal, schoolingsDataService) {
                    $uibModal.open({
                        templateUrl: 'app/schoolings/administration/delete/delete.html',
                        controller: 'DeleteSchooling',
                        controllerAs: 'vm',
                        resolve: {
                            canDeleteSchooling: function () {
                                return schoolingsDataService.checkCanDeleteSchooling($stateParams.id);
                            }
                        }
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('classicCatalog');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('schoolings.home', { id: $stateParams.id });
                        });
                }],
                params: {
                    schoolingName: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin']
                }
            });
    }
})();
