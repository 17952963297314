(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schoolingScheduleContract', schoolingScheduleContract);

    schoolingScheduleContract.$inject = [
        'logger',
        'i18nFilter',
        '$stateParams',
        'exceptionHelper',
        '$uibModal',
        '$state',
        '$http',
        '$timeout',
        '$sessionStorage',
        'toastr',
        'schoolingSchedulePacksDataService',
        'schoolingScheduleContractEmployees',
        'contractsDataService',
        'documentsDataService',
        'templates',
        'schoolingScheduleName'
    ];

    function schoolingScheduleContract(
        logger,
        i18nFilter,
        $stateParams,
        exceptionHelper,
        $uibModal,
        $state,
        $http,
        $timeout,
        $sessionStorage,
        toastr,
        schoolingSchedulePacksDataService,
        schoolingScheduleContractEmployees,
        contractsDataService,
        documentsDataService,
        templates,
        schoolingScheduleName
    ) {
        var vm = this;
        
        vm.localeMessages = {
            schoolingScheduleName: schoolingScheduleName,
            validationTooltip: i18nFilter('translations.schedule_gui_label_validation_error'),
            createContractTooltip: i18nFilter('translations.schedule_gui_label_create_contract'),
            linkContractTooltip: i18nFilter('translations.schedule_gui_label_link_contract'),
            unlinkContractTooltip: i18nFilter('translations.schedule_gui_label_unlink_contract'),
            filenameContractLegalPersonEUTooltip: i18nFilter('translations.schooling_gui_download_file_contract_legal_person_eu'),
            filenameContractPersonEUTooltip: i18nFilter('translations.schooling_gui_download_file_contract_person_eu'),
            filenameContractAnnualContractPersonTooltip: i18nFilter('translations.schooling_gui_download_file_contract_annual_contract_person'),
            filenameContractAnnualContractLegalPersonTooltip: i18nFilter('translations.schooling_gui_download_file_contract_annual_contract_legal_person'),
            filenameStatementZPIZTooltip: i18nFilter('translations.schooling_gui_download_file_statement_zpiz'),
            filenameStatementTravelExpensesTooltip: i18nFilter('translations.schooling_gui_download_file_statement_travel_expenses'),
            filenameStatementSchoolingExecutionTooltip: i18nFilter('translations.schooling_gui_download_file_statement_schooling_execution')
        };

        vm.schoolingScheduleContractEmployees = schoolingScheduleContractEmployees;
        vm.templates = templates;

        var columns = [
            {
                dataField: 'id',
                visible: false
            },
            {
                caption: "",
                alignment: 'right',
                allowSorting: false,
                allowFiltering: false,
                allowEditing: false,
                width: 40,
                cellTemplate: 'validationTemplate'
            },
            {
                dataField: 'displayName',
                caption: i18nFilter('translations.schedule_presence_gui_main_table_user'),
                alignment: 'left',
                allowSorting: true,
                allowEditing: false,
                sortOrder: 'asc',
                width: '35%',
                calculateCellValue: function (rowData) {
                    return rowData.displayName + (rowData.editedExecutionPrice ? "*" : "");
                }
            },
            {
                dataField: 'contractName', // Should contain a link
                caption: i18nFilter('translations.schedule_gui_label_contract'),
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                allowEditing: false,
                cellTemplate: 'contractNameTemplate'/*,
                calculateCellValue: function (rowData) {
                    return (rowData.contractName != null ? rowData.contractName : "");
                }*/
            },
            {
                dataField: 'contractPrice',
                caption: i18nFilter('translations.schedule_gui_label_contract_price'),
                alignment: 'left',
                allowSorting: false,
                allowFiltering: false,
                allowEditing: false,
                calculateCellValue: function (rowData) {
                    //return (rowData.contractPrice != null ? rowData.contractPrice + " €" : "");
                    return (rowData.contractPrice != null ? parseFloat(Math.round(rowData.contractPrice * 100) / 100).toFixed(2).replace('.', ',') + " €" : "");
                }
            },
            {
                dataField: 'executionPrice',
                caption: i18nFilter('translations.schedule_gui_label_execution_price'),
                alignment: 'left',
                allowSorting: false,
                allowFiltering: false,
                allowEditing: true,
                format: { currency: 'EUR', precision: 2}
                /*
                calculateCellValue: function (rowData) {
                    //return (rowData.executionPrice != null ? rowData.executionPrice + " €" : "");
                    return (rowData.executionPrice != null ? parseFloat(Math.round(rowData.executionPrice * 100) / 100).toFixed(2).replace('.', ',') + " €" : "");
                }
                */
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                allowEditing: false,
                width: 260,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }/*,{
                dataField: 'startDate',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                sortOrder: 'desc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'endDate',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            }*/
        ];

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(vm.schoolingScheduleContractEmployees.employees),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },/*
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },/*
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },*/
            hoverStateEnabled: true,/*
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },*/
            columnAutoWidth: false,
            wordWrapEnabled: true,
            cacheEnabled: false,
            sorting: {
                mode: "multiple"
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            editing: {
                allowUpdating: true,
                allowDeleting: false,
                allowAdding: false,
                mode: "row"
            },
            onRowUpdated: updateExecutionPrice
        };

        // Functions
        vm.createAndLinkContract = createAndLinkContract;
        vm.searchContracts = searchContracts;
        vm.unlinkContract = unlinkContract;
        vm.generateContractLegalPersonEUContract = generateContractLegalPersonEUContract;
        vm.generateContractPersonEUContract = generateContractPersonEUContract;
        vm.generateContractAnnualContractPersonContract = generateContractAnnualContractPersonContract;
        vm.generateContractAnnualContractLegalPersonContract = generateContractAnnualContractLegalPersonContract;
        vm.generateStatementZPIZContract = generateStatementZPIZContract;
        vm.generateStatementTravelExpensesContract = generateStatementTravelExpensesContract;
        vm.generateStatementSchoolingExecutionContract = generateStatementSchoolingExecutionContract;
        
        function createAndLinkContract(rowData) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/contract/create/employeeContractModal.html',
                controller: 'EmployeeContractModal',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static', //disable closing modal by clicking on background, so you don't accidentally undo your changes
                resolve: {
                    // pass rowData (usefull only executionPrice and userId)
                    employeeRow: angular.copy(rowData),
                    budgetEntities: ['budgetsDataService', function (budgetsDataService) {
                        return budgetsDataService.getBudgetEntities();
                    }]
                }
            })
            .result.then(function () {
                // after clicking OK button
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function unlinkContract(userId, contractId) {
            var scheduleId = $stateParams.schoolingScheduleId;

            // If there is a (valid) contract id linked to this employee, unlink it
            if (contractId != "00000000-0000-0000-0000-000000000000") {
                contractsDataService.SchoolingScheduleContractUnlink(contractId, userId, scheduleId).then(function (data) {
                    logger.success(i18nFilter('translations.contract_queue_link'));
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function searchContracts(employeeId) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/contract/linkContract/linkContractModal.html',
                controller: 'LinkContractModal',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'true', //disable closing modal by clicking on background, so you don't accidentally undo your changes
                resolve: {
                    allContracts: function() {
                        return contractsDataService.getAllContractsSimple();
                    },
                    employeeId: function () {
                        return employeeId;
                    }
                }
            })
            .result.then(function () {
                // after clicking OK button
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function generateContractPersonEUContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/contractPerson',
                i18nFilter('translations.schooling_download_file_name_contract_person_eu')
            );
        }

        function generateContractLegalPersonEUContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/contractLegalPerson',
                i18nFilter('translations.schooling_download_file_name_contract_legal_person_eu')
            );
        }

        function generateContractAnnualContractPersonContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/annualContractPerson',
                i18nFilter('translations.schooling_download_file_name_contract_annual_contract_person')
            );
        }

        function generateContractAnnualContractLegalPersonContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/annualContractLegalPerson',
                i18nFilter('translations.schooling_download_file_name_contract_annual_contract_legal_person')
            );
        }

        function generateStatementZPIZContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/zpiz',
                i18nFilter('translations.schooling_download_file_name_statement_zpiz')
            );
        }

        function generateStatementTravelExpensesContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/travelExpenses',
                i18nFilter('translations.schooling_download_file_name_statement_travel_expenses')
            );
        }

        function generateStatementSchoolingExecutionContract(speakerId) {
            documentsDataService.downloadDocument(
                'api/schoolingSchedulePacks/' + speakerId + '/' + vm.schoolingScheduleContractEmployees.scheduleId + '/schoolingExecution',
                i18nFilter('translations.schooling_download_file_name_statement_schooling_execution')
            );
        }

        function updateExecutionPrice(item) {
            if (!item)
                return;

            contractsDataService.editIntructorBillingExecutionCost({
                scheduleId: vm.schoolingScheduleContractEmployees.scheduleId,
                userId: item.key.id,
                executionPrice: parseFloat(item.key.executionPrice.toFixed(2))
            }).then(function (data) {
                //logger.success(i18nFilter('translations.contract_queue_link'));
                item.key.editedExecutionPrice = true;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
