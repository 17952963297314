(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('videosDataService', assignments);

    assignments.$inject = ['$http', 'logger', '$q', '$sessionStorage'];

    function assignments($http, logger, $q, $sessionStorage) {
        var service = {
            getVideoPortalUrl: getVideoPortalUrl
        };

        return service;

        function getVideoPortalUrl() {
            var siteCollectionRoot = $sessionStorage.userInfo.tenantConfig.dms.dmsProviderConfiguration.siteCollectionRoot;
            var host = siteCollectionRoot.substring(0, siteCollectionRoot.indexOf('.sharepoint.com/') + 16);
            
            return $http.get(host + '_api/VideoService.Discover').then(function (data) {
                if (!data.data.IsVideoPortalEnabled) {
                    return "NOT FOUND";
                }
                return data.data.VideoPortalUrl;
            }, function (data, status, heders, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        
    }
})();