(function () {
    'use strict';

    angular
        .module('app')
        .controller('deletePromotedEntities', deletePromotedEntitiesCtrl);

    deletePromotedEntitiesCtrl.$inject = ['logger', '$state', 'exceptionHelper', '$scope', 'promotedEntitiesDataService', '$stateParams','i18nFilter'];

    function deletePromotedEntitiesCtrl(logger, $state, exceptionHelper, $scope, promotedEntitiesDataService, $stateParams, i18nFilter) {
        var vm = this;

        vm.name = $stateParams.name;
        

        vm.cancel = cancel;
        vm.ok = ok;
        
        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            promotedEntitiesDataService.deletePromotedEntity($stateParams.id).then(function (result) {
                logger.success(i18nFilter("translations.manage_promoted_entities_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();
