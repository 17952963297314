(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('promotedEntity', promotedEntityFilter);

    promotedEntityFilter.$inject = ['logger', 'promotedEntityType', 'i18nFilter'];

    function promotedEntityFilter(logger, promotedEntityType, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value == promotedEntityType.education){
                return i18nFilter('translations.general_gui_education');
            }
            else if (value == promotedEntityType.program){
                return i18nFilter('translations.general_gui_program');
            }
            else if (value == promotedEntityType.schooling) {
                return i18nFilter('translations.general_gui_schooling');
            }
        };
    }
})();
