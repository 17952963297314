(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditAdministration', editAdministration);

    editAdministration.$inject = ['i18nFilter', 'editEducationHelper', '$stateParams', '$state', '$scope', 'education', 'emptyGuid'];

    function editAdministration(i18nFilter, editEducationHelper, $stateParams, $state, $scope, education, emptyGuid) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = education;
        vm.navigation = [];
        vm.containerStyle = {};

        editEducationHelper.clearData();


        if (vm.isEdit) {
            vm.containerStyle['margin-top'] = '-20px';
            vm.navigation = editEducationHelper.getWizardSteps($stateParams.id, vm.isEdit);

            if (vm.item.surveyEnabled) {
                var surveyGroup = {
                    id: emptyGuid,
                    name: i18nFilter('translations.education_gui_choose_survey_group_no_questions') 
                };

                var sg = vm.item.surveyGroups[0];

                if (sg.id===emptyGuid) {
                    vm.item.surveyGroups = surveyGroup;
                } else {
                    vm.item.surveyGroups = sg;
                }

            }

            editEducationHelper.setData(vm.item);
        }
        else {
            vm.containerStyle['margin'] = '10px';
            vm.navigation = editEducationHelper.getWizardSteps(null, vm.isEdit);
        }

        $scope.navigation = vm.navigation;


        $scope.goToStep = function (navItem) {
            $state.go(navItem.state);
        }
    }
})();
