(function () {
    'use strict';

    angular
        .module('app.exams.administration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('exams.administration', {
            url: '/administration',
                templateUrl: 'app/exams/administration/administration.html',            
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'ExamOwner', 'ExamAdmin']
            }
        });
    }
})();
