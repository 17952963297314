(function () {
    'use strict';

    angular
        .module('app')
        .factory('dateHelper', date);

    date.$inject = ['logger'];

    function date(logger) {
        var service = {
            convertFromDateToString: convertFromDateToString,
            convertFromStringToDate: convertFromStringToDate,
            convertToDateTime: convertToDateTime,
            createNewDateTimeWithoutTime: createNewDateTimeWithoutTime,
            createNewDateTimeWithoutSeconds: createNewDateTimeWithoutSeconds,
            getDateRangeDuration: getDateRangeDuration,
            formatDurationString: formatDurationString,
            onDateInputKeyDown: onDateInputKeyDown,
            onDateTimeInputKeyDown: onDateTimeInputKeyDown
        };

        return service;

        function convertFromDateToString(date) {
            if (date && date instanceof Date) {

                if (date.getHours() === 0 && date.getMinutes() === 0) {
                    //var currentEndTimeZoneOffsetInHours = Math.abs(date.getTimezoneOffset() / 60);
                    //date.setHours(date.getHours() + currentEndTimeZoneOffsetInHours);
                }

                date = date.toISOString();
            }
            return date;
        }
        
        function convertFromStringToDate(dateStr) {
            if (dateStr && (typeof dateStr === 'string' || dateStr instanceof String)) {
                if (dateStr[dateStr.length - 1] === 'Z' || dateStr[dateStr.length - 1] === 'z') {
                    dateStr = new Date(dateStr);
                }
                else {
                    dateStr = new Date(dateStr + 'Z');
                }
            }
            return dateStr;
        }

        function convertToDateTime(date) {
            var dateStr;
            if (date && date instanceof Date) {
                var month = date.getMonth() + 1;
                dateStr = date.getFullYear() + '-' + month + '-' + date.getDate();
            }
            return dateStr;
        }

        function createNewDateTimeWithoutTime(date) {
            if (date && date instanceof Date) {                
                var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            }
            return newDate;
        }

        function createNewDateTimeWithoutSeconds(date) {
            if (date && date instanceof Date) {
                var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
            }
            return newDate;
        }

        function formatDurationString(duration){
            let monthsString = '';
            let daysString = '';

            if (duration.motnhs && duration.days == 0)
                return '';

            if (duration.months) {
                monthsString = duration.months.toString();
                switch (duration.months) {
                    case 1:
                        monthsString += " mesec";
                        break;
                    case 2:
                        monthsString += " meseca";
                        break;
                    case 3:
                        monthsString += " meseci";
                        break;
                    case 4:
                        monthsString += " meseci";
                        break;
                    default:
                        monthsString += " mesecev";
                        break;
                }
            }

            if (duration.days) {
                daysString = ((duration.months ? " " : "") + duration.days.toString());
                switch (duration.days) {
                    case 1:
                        daysString += " dan";
                        break;
                    default:
                        daysString += " dni";
                }
            }

            return `${monthsString}${daysString}`;
        }


        function getDateRangeDuration(fromDate, toDate){
            var duration = {
                days: 0,
                months: 0
            };

            if (fromDate == null || toDate == null)
                return duration;

            let start = new Date(fromDate);
            let end = new Date(toDate);

            let convertToMonths = true;


            // calculates whole months between two dates
            duration.months = monthDiff(start, end);

            // handle special cases
            let lastDayOfStartDateMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate(),
                lastDayOfEndDateMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate();

            if (start.getFullYear() == end.getFullYear() && start.getMonth() == end.getMonth()) {
                if (start.getDate() == 1 && end.getDate() == lastDayOfEndDateMonth) 
                    duration.months += 1;
                else 
                    duration.days += end.getDate() - start.getDate() + 1;
            }

            else if (duration.months == 0) {
                if (start.getDate() == 1 && end.getDate() == lastDayOfEndDateMonth)
                    duration.months += 2;

                else if (start.getDate() == 1 && end.getDate() != lastDayOfEndDateMonth && start.getMonth() != end.getMonth()) {
                    duration.months += 1;
                    duration.days += end.getDate();
                }

                else if (start.getDate() != 1 && end.getDate() == lastDayOfEndDateMonth && start.getMonth() != end.getMonth()) {
                    duration.months += 1;
                    duration.days += lastDayOfStartDateMonth - start.getDate() + 1;
                }

                else if (start.getDate() != 1 && end.getDate() != lastDayOfEndDateMonth && start.getMonth() != end.getMonth()) {
                    duration.days += lastDayOfStartDateMonth - start.getDate() + 1;
                    duration.days += end.getDate();
                }

                else {
                    duration.days += lastDayOfStartDateMonth - start.getDate() + 1;
                    duration.days += end.getDate();
                }
            }

            else {
                if (start.getDate() == 1)
                    duration.months += 1;
                else
                    duration.days += lastDayOfStartDateMonth - start.getDate() + 1;

                if (end.getDate() == lastDayOfEndDateMonth)
                    duration.months += 1;
                else
                    duration.days += end.getDate();
            }

            if (convertToMonths && duration.days >= 30) {
                duration.months += Math.floor(duration.days / 30);
                duration.days = duration.days % 30;
            }

            return duration;
        }

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth() + 1;
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        function onDateInputKeyDown(event, input) {
            const key = event.key;
            const dateRegex = /^[0-9. ]$/; //framework only accepts the format DD. MM. YYYY (note the spaces)

            processDateInput(event, input, key, dateRegex);
        }

        function onDateTimeInputKeyDown(event, input) {
            const key = event.key;
            const dateRegex = /^[0-9.: ]$/; //framework only accepts the format DD. MM. YYYY HH:MM (note the spaces)

            processDateInput(event, input, key, dateRegex);
        }

        function processDateInput(event, input, key, dateRegex) {
            if (key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
                if (!dateRegex.test(key)) {
                    event.preventDefault();
                }
                else if (input.value.length === input.selectionStart) {
                    if (key === '.') {
                        input.value += '. ';
                        event.preventDefault();
                    }
                    else if (key !== ' ') {
                        if (input.value[input.value.length - 1] === '.') {
                            input.value += ' ' + key;
                            event.preventDefault();
                        }
                    }
                }
            }
        }
    }
})();
