(function () {
    "use strict";

    angular
        .module("app.schedulePacks")
        .controller("uploadCertificateModal", uploadCertificateModal);

    uploadCertificateModal.$inject = [
        "logger",
        "i18nFilter",
        "$scope",
        "documentsDataService",
        "exceptionHelper",
        "learningPlanItemId",
    ];

    function uploadCertificateModal(
        logger,
        i18nFilter,
        $scope,
        documentsDataService,
        exceptionHelper,
        learningPlanItemId
    ) {
        var vm = this;
        vm.fileId = undefined;

        vm.cancel = cancel;
        vm.ok = ok;
        vm.validate = false;

        vm.dzOptions = {
            url: "api/documents/upload",
            method: "post",
            dictDefaultMessage: i18nFilter(
                "translations.general_gui_frag_and_drop_default_message"
            ),
            dictFallbackMessage: i18nFilter(
                "translations.general_gui_frag_and_drop_fallback_message"
            ),
            dictRemoveFile: i18nFilter(
                "translations.general_gui_frag_and_drop_remove_file"
            ),
            dictResponseError: i18nFilter(
                "translations.general_gui_frag_and_drop_response_error"
            ),
            dictMaxFilesExceeded: i18nFilter(
                "translations.general_gui_frag_and_drop_max_files_exceeded"
            ),
            paramName: "file",
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: "10",
            acceptedFiles: ".pdf",
            addRemoveLinks: true,
        };

        vm.dzCallbacks = {
            addedfile: function (file) {
                logger.log("File added: ", file);
                vm.validate = true;
            },
            success: function (file, xhr) {
                logger.log("File successfully uploaded. Id: ", file);
                var id = xhr;
                vm.fileId = id;
            },
            removedfile: function (file) {
                vm.fileId = undefined;
                vm.validate = false;
            },
        };

        vm.dzMethods = {};
        vm.removeNewFile = function () {
            vm.dzMethods.removeFile(vm.newFile);
        };

        function cancel() {
            $scope.$dismiss();
        }

        function ok() {
            documentsDataService
                .uploadCertificateModal(learningPlanItemId, vm.fileId)
                .then(
                    function (result) {
                        logger.success(
                            i18nFilter(
                                "translations.schedule_presence_upload_certificate_modal_process_message"
                            )
                        );
                        $scope.$close(true);
                    },
                    function (error) {
                        exceptionHelper.handleException(error);
                    }
                );
        }
    }
})();
