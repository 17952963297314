(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('educationsDataService', educations);

    educations.$inject = ['$http', 'logger', '$q', 'dateHelper', 'blobHelper', 'decimalHelper'];

    function educations($http, logger, $q, dateHelper, blobHelper, decimalHelper) {
        var service = {
            getEducationById: getEducationById,
            getEducationInfo: getEducationInfo,
            getEducationCategories: getEducationCategories,
            getEducationOwners: getEducationOwners,
            getEducationContactPersons: getEducationContactPersons,
            getEducationLecturers: getEducationLecturers,
            getEducationInstructors: getEducationInstructors,
            getEducationLearnersForCurrentPeriod: getEducationLearnersForCurrentPeriod,
            getEditEducation: getEditEducation,
            getAllActiveEducations: getAllActiveEducations,
            getAllActiveEducationsWithSynchronousActivities: getAllActiveEducationsWithSynchronousActivities,

            addEducation: addEducation,
            editEducation: editEducation,
            deleteEducation: deleteEducation,

            getEducationLearnersAdvanced: getEducationLearnersAdvanced,


            getProgramEducations: getProgramEducations,
            getCurrentEducations: getCurrentEducations,

            getPotentialEducationsForPromotedEntities: getPotentialEducationsForPromotedEntities,

            sendSupportMail: sendSupportMail,

            checkUserEnrollment: checkUserEnrollment,
            resetEducationEnrollment: resetEducationEnrollment,

            getOnlineEducationLearners: getOnlineEducationLearners,
            getOnlineEducations: getOnlineEducations
        };

        return service;

        function sendSupportMail(educationId, mail) {
            return $http.post('api/educations/' + educationId + '/sendSupportMail', mail).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationById(educationId) {
            return $http.get('api/educations/' + educationId).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationInfo(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/info').then(function (data) {
                //TODO: datumi
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get education info');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationCategories(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/categories').then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationOwners(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/owners').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch education owners');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationContactPersons(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/contactPersons').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch education contact persons');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationLecturers(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/lecturers').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch education lecturers');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationInstructors(educationId) {
            return $http.get('api/educations/' + educationId + '/instructors').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch education instructors');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationLearnersForCurrentPeriod(educationId) {
            return $http.get('api/educations/' + educationId + '/sidebar/learners').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEditEducation(id) {
            return $http.get('api/educations/' + id + '/edit').then(function (data) {
                angular.forEach(data.data.prices, function (price, index) {
                    price.amount = decimalHelper.convertDecimalToLocaleString(price.amount);
                });
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch edit education");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveEducations() {
            return $http.get('api/educations/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllActiveEducationsWithSynchronousActivities() {
            return $http.get('api/educations/active/synchronous').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addEducation(education) {
            var fd = new FormData();
            var education_copy = angular.copy(education);

            if (education_copy.picture) {
                var blob = blobHelper.dataURItoBlob(education_copy.picture);
                education_copy.picture = null;
                fd.append('picture', blob);
            }

            if (education_copy.logotype) {
                var blob = blobHelper.dataURItoBlob(education_copy.logotype);
                education_copy.logotype = null;
                fd.append('logotype', blob);
            }
         
            if (education_copy.surveyEnabled) {
                var surveyGroup = education_copy.surveyGroups;
                education_copy.surveyGroups = [];
                education_copy.surveyGroups.push(surveyGroup);
            }

            fd.append('item', JSON.stringify(education_copy));

            return $http.post('api/educations', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editEducation(education) {
            var fd = new FormData();

            if (education.picture) {
                var blob = blobHelper.dataURItoBlob(education.picture);
                education.picture = null;
                fd.append('picture', blob);
            }

            if (education.logotype) {
                var blob = blobHelper.dataURItoBlob(education.logotype);
                education.logotype = null;
                fd.append('logotype', blob);
            }

            if (education.surveyEnabled) {
                var surveyGroup = education.surveyGroups;
                education.surveyGroups = [];
                education.surveyGroups.push(surveyGroup);
            }

            fd.append('item', JSON.stringify(education));

            return $http.put('api/educations/' + education.id, fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                logger.log('Failed to change edit education');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteEducation(id) {
            return $http.delete('api/educations/' + id).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEducationLearnersAdvanced(id, options) {
            options.enrollmentDate.searchText1 = dateHelper.convertFromDateToString(options.enrollmentDate.searchText1);
            options.enrollmentDate.searchText2 = dateHelper.convertFromDateToString(options.enrollmentDate.searchText2);

            options.deadline.searchText1 = dateHelper.convertFromDateToString(options.deadline.searchText1);
            options.deadline.searchText2 = dateHelper.convertFromDateToString(options.deadline.searchText2);

            return $http.post('api/educations/' + id + '/learners/advanced', options).then(function (data) {
                angular.forEach(data.data.data, function (element, index) {
                    element.enrollmentDate = dateHelper.convertFromStringToDate(element.enrollmentDate);
                    element.deadline = dateHelper.convertFromStringToDate(element.deadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCurrentEducations() {
            return $http.get('api/educations/current').then(function (data) {
                angular.forEach(data.data, function (education, index) {
                    education.learningPlanItemDeadline = dateHelper.convertFromStringToDate(education.learningPlanItemDeadline);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramEducations(programId) {
            return $http.get('api/programs/' + programId + '/educations').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPotentialEducationsForPromotedEntities() {
            return $http.get('api/promotedEntities/potential/educations').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function checkUserEnrollment(educationId) {
            return $http.get('api/educations/' + educationId + '/checkEnrollment').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function resetEducationEnrollment(educationId) {
            return $http.post('api/educations/' + educationId + '/resetEnrollment').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getOnlineEducationLearners(educationId, options) {
            return $http.post('api/educations/' + educationId + '/online/learners', options).then(function (data) {
                angular.forEach(data.data, function (item, index) {
                  //  item.learningPlanItemEnrollmentDate = dateHelper.convertFromStringToDate(item.learningPlanItemEnrollmentDate);
                 //   item.learningPlanItemFinishedAt = dateHelper.convertFromStringToDate(item.learningPlanItemFinishedAt);

             //       angular.forEach(item.activities, function (activity, activityIndex) {
             //           activity.learningActivityFinishedAt = dateHelper.convertFromStringToDate(activity.learningActivityFinishedAt);
             //       });
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getOnlineEducations() {
            return $http.get('api/educations/online').then(function (data) {
                //Data transformation here
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
