(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('viewCommisionMemberThemeQuestions', viewCommisionMemberThemeQuestions);

    viewCommisionMemberThemeQuestions.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper','question'];

    function viewCommisionMemberThemeQuestions(logger, $stateParams, $scope, i18nFilter, exceptionHelper, question) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.question = question;
        vm.tinymceOptions = {
            plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
            toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons',
            readonly: true
        }

        function ok() {
            $scope.$close(true);
        }



        function cancel() {
            $scope.$dismiss();
        }
    }
})();
