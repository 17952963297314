(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('budgetsDataService', budget);

    budget.$inject = ['$http', 'dateHelper', '$q', 'decimalHelper'];

    function budget($http, dateHelper, $q, decimalHelper) {

        var service = {
            getAllBudgets: getAllBudgets,
            getBudgetById: getBudgetById,
            addBudget: addBudget,
            editBudget: editBudget,
            deleteBudget: deleteBudget,
            getBudgetEntities: getBudgetEntities,
            getBudgetExamEntities: getBudgetExamEntities,
            getBudgetByEducationIdAndStartDate: getBudgetByEducationIdAndStartDate,
            searchActiveBudgets: searchActiveBudgets
        };

        return service;

        function getAllBudgets() {
            return $http.get('/api/budgets')
                .then(function (data) {
                    angular.forEach(data.data, function (element, index) {
                        element.start = dateHelper.convertFromStringToDate(element.start);
                        element.end = dateHelper.convertFromStringToDate(element.end);

                        if (element.publicOrder != null)
                            element.publicOrder.orderDate = dateHelper.convertFromStringToDate(element.publicOrder.orderDate);

                        var entitiesString = '';
                        for (var i = 0; i < element.entities.length; i++) {
                            if (i === element.entities.length - 1) {
                                entitiesString += element.entities[i].name;
                            } else {
                                entitiesString += element.entities[i].name + '; ';
                            }
                        }
                        element.entitiesString = entitiesString;

                        var examEntitiesString = '';
                        for (var i = 0; i < element.examEntities.length; i++) {
                            if (i === element.examEntities.length - 1) {
                                examEntitiesString += element.examEntities[i].name;
                            } else {
                                examEntitiesString += element.examEntities[i].name + '; ';
                            }
                        }
                        element.examEntitiesString = examEntitiesString;
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getBudgetById(budgetId) {
            return $http.get('/api/budgets/' + budgetId)
                .then(function (data) {
                    data.data.start = dateHelper.convertFromStringToDate(data.data.start);
                    data.data.end = dateHelper.convertFromStringToDate(data.data.end);

                    if (data.data.publicOrder != null)
                        data.data.publicOrder.orderDate = dateHelper.convertFromStringToDate(data.data.publicOrder.orderDate);

                    if (data.data.price !== undefined && data.data.price !== null) {
                        data.data.price.amount = decimalHelper.convertDecimalToLocaleString(data.data.price.amount);
                    }
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getBudgetByEducationIdAndStartDate(educationId, startDate) {
            startDate = dateHelper.convertFromDateToString(startDate);
            var postData = {
                educationId: educationId,
                startDate: startDate
            };            
            return $http.post('/api/budgets/education', postData)
                .then(function (data) {
                    if (data.data.budget) {
                        data.data.budget.start = dateHelper.convertFromStringToDate(data.data.budget.start);
                        data.data.budget.end = dateHelper.convertFromStringToDate(data.data.budget.end);
                        if (data.data.budget.price !== undefined && data.data.budget.price !== null) {
                            data.data.budget.price.amount = decimalHelper.convertDecimalToLocaleString(data.data.budget.price.amount);
                        }                        
                    }
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function addBudget(budget) {
            budget.start = dateHelper.convertToDateTime(budget.start);
            budget.end = dateHelper.convertToDateTime(budget.end);

            if (budget.publicOrder && budget.publicOrder.orderDate){
                budget.publicOrder.orderDate = dateHelper.convertToDateTime(budget.publicOrder.orderDate);
            }

            var fd = new FormData();

            fd.append('item', JSON.stringify(budget));
            return $http.post('api/budgets', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editBudget(budget) {
            budget.start = dateHelper.convertToDateTime(budget.start);
            budget.end = dateHelper.convertToDateTime(budget.end);

            if (budget.publicOrder && budget.publicOrder.orderDate){
                budget.publicOrder.orderDate = dateHelper.convertToDateTime(budget.publicOrder.orderDate);
            }

            var fd = new FormData();

            fd.append('item', JSON.stringify(budget));
            return $http.put('api/budgets', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteBudget(budgetId) {
            return $http.delete('api/budgets/' + budgetId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        } 

        function getBudgetEntities() {
            return $http.get('/api/budgets/budgetEntities')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getBudgetExamEntities() {
            return $http.get('/api/budgets/budgetExamEntities')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function searchActiveBudgets(query) {
            return $http.get('api/budgets/active/search/' + query).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch all active users that contains " + query);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
