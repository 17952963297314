(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .component('questionGroup', {
            templateUrl: 'app/surveys/questionGroups/components/questionGroup/questionGroup.html',
            controller: questionGroup,
            controllerAs: 'vm',
            bindings: {
                questionGroup: '=',
                questionGroupCount: '='
            }
        });

    questionGroup.$inject = ['surveysDataService', 'logger', 'exceptionHelper', 'i18nFilter'];

    function questionGroup(surveysDataService, logger, exceptionHelper, i18nFilter) {
        var vm = this;

        vm.orderUp = orderUp;
        vm.orderDown = orderDown;       

        vm.localeMessages = {
            questionList: i18nFilter('translations.survey_group_gui_question_list'),
            orderUpTooltip: i18nFilter('translations.general_action_move_up'),
            orderDownTooltip: i18nFilter('translations.general_action_move_down'),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        function orderUp() {
            surveysDataService.orderUpSurveyGroup(vm.questionGroup.id).then(function (data) {
                logger.success(i18nFilter('translations.survey_group_queue_ordering'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function orderDown() {
            surveysDataService.orderDownSurveyGroup(vm.questionGroup.id).then(function (data) {
                logger.success(i18nFilter('translations.survey_group_queue_ordering'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
