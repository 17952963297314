(function () {
    'use strict';

    angular
        .module('app.cities')
        .controller('deleteCity', deleteCity);

    deleteCity.$inject = ['logger', '$stateParams', 'citiesDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteCity(logger, $stateParams, citiesDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            citiesDataService.deleteCity($stateParams.id).then(function (data) {
                logger.success(i18nFilter("translations.city_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
