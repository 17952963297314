(function () {
    'use strict';

    angular
        .module('app')
        .controller('certificateHelp', certificateHelp);

    certificateHelp.$inject = ['$scope'];

    function certificateHelp($scope) {
        var vm = this;
        
        vm.ok = ok;
        
        

        function ok() {
            $scope.$dismiss();
           
        };
    }
})();
