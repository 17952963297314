(function () {
    'use strict';

    angular
        .module('app.budgets')
        .controller('DeleteBudget', deleteBudget);

    deleteBudget.$inject = ['logger', '$stateParams', '$scope', 'budgetsDataService', 'i18nFilter'];

    function deleteBudget(logger, $stateParams, $scope, budgetsDataService, i18nFilter) {
        var vm = this;
        var budget = $stateParams.budget;

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            budgetsDataService.deleteBudget($stateParams.budgetId).then(function (data) {
                logger.success(i18nFilter('translations.budget_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };
    }
})();
