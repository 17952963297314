(function () {
    'use strict';

    angular
        .module('app.tenants')
        .controller('BaseStepUser', baseStep);

    baseStep.$inject = ['editUserHelper', '$stateParams', '$state', '$scope', 'user'];

    function baseStep(editUserHelper, $stateParams, $state, $scope, user) {
        var vm = this;
        vm.title = 'baseStepUser';
        vm.isEdit = $state.current.data.isEdit;
        vm.item = user;
        vm.navigation = [];
        vm.selectedEventTypeRoles = [];

        editUserHelper.clearData();

        if (vm.isEdit) {
            vm.navigation = editUserHelper.getWizardSteps(vm.isEdit);
            editUserHelper.setData(vm.item);
        }
        else {
            vm.navigation = editUserHelper.getWizardSteps(vm.isEdit);
        }

        $scope.navigation = vm.navigation;
        $scope.selectedEventTypeRoles = vm.selectedEventTypeRoles;

        $scope.goToStep = function (navItem) {
            $state.go(navItem.state);
        }
    }
})();
