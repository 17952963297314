(function () {
    'use strict';

    angular
        .module('app.locations')
        .controller('editLocations', editLocations);

    editLocations.$inject = ['logger', 'location', 'locationsDataService', 'organizationsDataService', 'exceptionHelper', '$state', 'exceptions', '$scope', 'equipments', 'emptyGuid', 'locationType', 'i18nFilter', '$sanitize', 'guidGeneratorDataService'];

    function editLocations(logger, location, locationsDataService, organizationsDataService, exceptionHelper, $state, exceptions, $scope, equipments, emptyGuid, locationType, i18nFilter, $sanitize, guidGeneratorDataService) {
        var vm = this;
        vm.item = location;

        vm.isEdit = $state.current.data.isEdit;
        if (!vm.isEdit) {
            vm.item.active = true;
        }
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.formData = {
            organizations: [],
            equipments: equipments,
            showOrganizationDisabledMessage: false,
            oldOrganization: undefined,
            locationType: locationType
        };
        vm.inputAddress = inputAddress;
        vm.save = save;
        vm.add = add;

        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;

        function add() {
            vm.item.id = guidGeneratorDataService.getEmptyGuid();
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            if (vm.item.price === "")
                vm.item.price = 0;
            if (vm.item.participantsCost === "")
                vm.item.participantsCost = 0;
            locationsDataService.addLocation(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.location_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            if (vm.item.price === "")
                vm.item.price = 0;
            if (vm.item.participantsCost === "")
                vm.item.participantsCost = 0;
            locationsDataService.editLocation(vm.item).then(function (data) {
                logger.success(i18nFilter('translations.location_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
            vm.item.address = undefined;
            vm.item.locationDisplayString = undefined;
        }

        function inputAddress() {
            if (vm.item.organization == null) 
                return;
            
            var name = vm.item.organization.name;

            angular.forEach(vm.formData.organizations, function (object, index) {
                if (object.name === name) {
                    vm.item.address = object.address;
                    let splitAdress = object.address.split(", ")[1];
                    let splitLocation = splitAdress.indexOf(" ");
                    vm.item.locationDisplayString = splitAdress.substring(splitLocation + 1, splitAdress.length);
                }
            });
        }
    }
})();
