(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('EditGroup', editGroup);

    editGroup.$inject = ['logger', 'competenceGroup', '$scope', 'i18nFilter', 'isEdit', '$sanitize'];

    function editGroup(logger, competenceGroup, $scope, i18nFilter, isEdit, $sanitize) {
        var vm = this;
        vm.isEdit = isEdit;

        if (vm.isEdit) {
            vm.item = competenceGroup;
        } else {
            vm.item = {
                id: undefined,
                text: undefined,
                definition: undefined,
                status: undefined,
                competences: undefined,
                parentCompetenceGroup: undefined,
                items: undefined,
                expanded: undefined
            };
        }

        vm.formData = {};
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            vm.item.text = DOMPurify.sanitize(vm.item.text);
            vm.item.definition = DOMPurify.sanitize(vm.item.definition);
            $scope.$close(vm.item);
        };
    }
})();
