(function () {
    'use strict';

    angular
        .module('app.locations')
        .controller('deleteLocations', deleteLocations);

    deleteLocations.$inject = ['logger', 'locationsDataService', '$stateParams', '$scope', 'exceptionHelper', 'i18nFilter'];

    function deleteLocations(logger, locationsDataService, $stateParams, $scope, exceptionHelper, i18nFilter) {
        /* jshint validthis:true */
        var vm = this;

        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            locationsDataService.deleteLocation($stateParams.id).then(function (data) {
                logger.success(i18nFilter("translations.location_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
