(function () {
    'use strict';

    angular
        .module('app.educations')
        .controller('Educations', educations);

    educations.$inject = [
        'logger',
        'education',
        '$rootScope',
        '$location',
        'rolesDataService',
        'educationsDataService',
        'isEducationOwner',
        'isEducationInstructor',
        'i18nFilter',
        'isEnrolled',
        '$sessionStorage',
        '$state',
        '$stateParams',
        'eFormsUrl',
        'exceptionHelper'
    ];

    function educations(
        logger,
        education,
        $rootScope,
        $location,
        rolesDataService,
        educationsDataService,
        isEducationOwner,
        isEducationInstructor,
        i18nFilter,
        isEnrolled,
        $sessionStorage,
        $state,
        $stateParams,
        eFormsUrl,
        exceptionHelper
    ) {
        var vm = this;

        vm.education = education;
        vm.isSmallCover = $rootScope.isSmallCover;


        vm.status = status;

        vm.localeMessages = {
            educationsNumberOfDocuments: i18nFilter('translations.education_gui_tooltip_number_of_documents'),
        };

        vm.formData = {
            isSmallCover: $rootScope.isSmallCover,
            navAuth: {
                about: rolesDataService.isUserInRoles(['Learner']),
                documents: (rolesDataService.isUserInRoles(['Learner'])) || isEducationOwner || isEducationInstructor,
                learnerDocuments: rolesDataService.isUserInRoles(['Learner']),
                discussion: rolesDataService.isUserInRoles(['Learner']),
                manage: isEducationOwner || isEducationInstructor || rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationAdmin']),

                manageEdit: isEducationOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageDelete: rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageActivities: isEducationOwner || rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationAdmin']),
                managePointsCriteria: isEducationOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                managePoints: isEducationOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),
                manageLearnerPoints: false//isEducationOwner || rolesDataService.isUserInRoles(['CatalogAdmin']),                
            }
        };


        vm.imageStyle = {
            "background-image": "url('api/educations/" + vm.education.id + "/defaultPicture')",
            "min-height": "340px"
        };

        $rootScope.$on('smallCoverChanged', function () {
            vm.formData.isSmallCover = $rootScope.isSmallCover;
        });

        vm.goToDocuments = goToDocuments;
        vm.openEducationPreview = openEducationPreview;
        vm.resetEducationEnrollment = resetEducationEnrollment;


        function goToDocuments() {

            return $state.go('educations.FSDocuments');
        }

        function openEducationPreview() {
            //Provjeri jesam li upisan u edukaciju
            educationsDataService.checkUserEnrollment($stateParams.id).then(function (data) {
                var tenantId = $sessionStorage.userInfo.tenantId;
                var tenanteFormUrl = eFormsUrl[tenantId]; 
                var index = tenanteFormUrl.indexOf('eForms');
                var baseUrlForNewApplication = tenanteFormUrl.substring(0, index);
                //ako jesam otvroi mi novu aplikaciju na stranicu edukacije
                window.open(baseUrlForNewApplication + 'educations/' + $stateParams.id + '/info/coordinatorPreview', '_blank');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function resetEducationEnrollment() {
            //Provjeri jesam li upisan u edukaciju
            educationsDataService.resetEducationEnrollment($stateParams.id).then(function (data) {
                logger.success(i18nFilter('translations.education_reset_queue'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        //Configuration for marking top level navigation properties if we are on sub level
        vm.isActive = function (view) {
            var path = $location.path();

            switch (view) {
                case '/home':
                    if (path.endsWith('/home')) {
                        return true;
                    }
                    break;
                case '/administration/activities':
                    if (path.endsWith('/administration/activities')) {
                        return true;
                    }
                    break;
                case '/documents':
                    if (path.endsWith('/documents/fs') || path.endsWith('/documents/sp')) {
                        return true;
                    }
                    break;
                case '/learnerDocuments':
                    if (path.endsWith('/learnerDocuments')) {
                        return true;
                    }
                    break;
                case '/discussionGroup':
                    if (path.endsWith('/group')) {
                        return true;
                    }
                    break;
                case '/administration':
                    if (
                        path.endsWith('/administration/edit') ||
                        path.endsWith('/administration/edit/about') ||
                        path.endsWith('/administration/points') ||
                        path.endsWith('/administration/learners')

                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
    }
})();
