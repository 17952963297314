(function () {
    'use strict';

    angular
        .module('app.educations.home')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('programs.home', {
            url: '/home',
            templateUrl: 'app/programs/home/home.html',
            controller: 'ProgramsHome',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'ProgramOwner', 'Instructor']
            }
        })
        //.state('educations.home.enroll', {
        //    url: '/enroll',
        //    // trigger the modal to open when this route is active
        //    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        //        $uibModal.open({
        //            templateUrl: 'app/educations/sidebars/info/enroll.html',
        //            controller: 'EnrollToEducation',
        //            controllerAs: 'vm',
        //        })
        //        // change route after modal result
        //        .result.then(function () {
        //            // change route after clicking OK button
        //            $state.go('educations.home', { id: $stateParams.id }, { reload: true });
        //        }, function () {
        //            // change route after clicking Cancel button or clicking background
        //            $state.go('educations.home', { id: $stateParams.id }, { reload: true });
        //        });
        //    }],
        //    requireADLogin: true,
        //    data: {
        //        roles: ['Learner']
        //    }
        //})
        //.state('educations.home.removeFromPlan', {
        //    url: '/removeFromPlan',
        //    // trigger the modal to open when this route is active
        //    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        //        $uibModal.open({
        //            templateUrl: 'app/educations/sidebars/info/removeFromPlan.html',
        //            controller: 'RemoveFromUserLearningPlan',
        //            controllerAs: 'vm',
        //        })
        //        // change route after modal result
        //        .result.then(function () {
        //            // change route after clicking OK button
        //            $state.go('educations.home', { id: $stateParams.id }, { reload: true });
        //        }, function () {
        //            // change route after clicking Cancel button or clicking background
        //            $state.go('educations.home', { id: $stateParams.id }, { reload: true });
        //        });
        //    }],
        //    requireADLogin: true,
        //    data: {
        //        roles: ['Learner']
        //    }
            //})
            ;
    }
})();
