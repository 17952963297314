(function () {
    'use strict';

    angular
        .module('app')
        .controller('editUserApplication', editUserApplication);

    editUserApplication.$inject = ['$scope', '$filter', 'i18nFilter', 'enrolledLearnerData', 'programId', 'learnerSchedules', 'programSchedules'];

    function editUserApplication($scope, $filter, i18nFilter, enrolledLearnerData, programId, learnerSchedules, programSchedules) {
        var vm = this;

        vm.enrolledLearnerData = enrolledLearnerData;
        vm.programId = programId;
        vm.learnerSchedules = learnerSchedules;
        vm.programSchedules = programSchedules;
        vm.schedulePacksIsAvailable = false;
        vm.changedEnrollments = [];
        
        vm.schedulePackPlaceholder = schedulePackPlaceholder;
        vm.getSchedulePackItemFormat = getSchedulePackItemFormat;
        vm.schedulePackSelectionChanged = schedulePackSelectionChanged;
        vm.allowClear = allowClear;
        vm.tagHandler = tagHandler;

        vm.ok = ok;
        vm.cancel = cancel;

        function schedulePackPlaceholder(entityId) {
            if (!vm.programSchedules || !vm.programSchedules[entityId] || vm.programSchedules[entityId].length === 0) {
                vm.schedulePacksIsAvailable = false;
                return i18nFilter('translations.general_gui_schedule_placeholder_pick_date_no_items');
            }
            else {
                vm.schedulePacksIsAvailable = true;
                return i18nFilter('translations.general_gui_schedule_placeholder_pick_date');
            }
        };

        function getSchedulePackItemFormat(schedulePack) {
            if (schedulePack) {
                const startDateTime = new Date(schedulePack.start);
                const endDateTime = new Date(schedulePack.end);

                let start = new Date($filter('date')(startDateTime, 'y/M/d'))
                let end = new Date($filter('date')(endDateTime, 'y/M/d'))
                const difference = start - end;

                if (difference === 0) {
                    start = $filter('date')(startDateTime, 'd. M. y H:mm');
                    end = $filter('date')(endDateTime, 'H:mm');
                }
                else {
                    start = $filter('date')(startDateTime, 'd. M. y');
                    end = $filter('date')(endDateTime, 'd. M. y');
                }

                return `${start} - ${end} (${i18nFilter('translations.general_gui_schedule_free_seats', schedulePack.numberOfAvailableSeats)})`;
            }

            return;
        };

        function schedulePackSelectionChanged(learner) {
            if (learner) {
                let schedulePackChanged = learner.schedulePack && learner.schedulePackId !== learner.schedulePack.id;
                let alreadyIncluded = vm.changedEnrollments.find((enrollment) => {
                    return enrollment.learningPlanItemId === learner.learningPlanItemId;
                });

                if (schedulePackChanged && !alreadyIncluded)
                    vm.changedEnrollments.push(learner);

                else
                    vm.changedEnrollments = vm.changedEnrollments.filter((enrollment) => {
                        return enrollment.learningPlanItemId !== learner.learningPlanItemId;
                    });
            }

            return;
        }

        function allowClear(education) {
            return !education.mandatory;
        }

        function tagHandler() {
            return null;
        }

        function ok() {
            $scope.$close(vm.changedEnrollments);           
        };

        function cancel() {
            $scope.$dismiss();
        };
    }
})();
