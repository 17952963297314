(function () {
    'use strict';
    
    angular
        .module('app.exams.themes')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('themes', {
                parent: 'base',
                url: '/manage/themes',
                templateUrl: 'app/manage/themes/themes.html',
                controller: 'themes',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    themes: ['themesDataService', '$stateParams', function (themesDataService, $stateParams) {
                        return themesDataService.getAllThemes();
                    }]
                },
                data: {
                    roles: ['ExamAdmin']
                }
            })
            .state('themes.add', {
                url: '/add',
                templateUrl: 'app/manage/themes/editTheme/editTheme.html',
                controller: 'EditTheme',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    theme: ['themesDataService', '$stateParams', function (themesDataService, $stateParams) {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['ExamAdmin']
                }
            })
            .state('themes.edit', {
                url: '/edit/:themeId',
                templateUrl: 'app/manage/themes/editTheme/editTheme.html',
                controller: 'EditTheme',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    theme: ['themesDataService', '$stateParams', function (themesDataService, $stateParams) {
                        return themesDataService.getThemeById($stateParams.themeId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['ExamAdmin']
                }
            });
    }    
})();
