(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('TelerikReportDetailscontroller', telerikReportDetailscontroller);

    telerikReportDetailscontroller.$inject = ['logger', 'report', '$sessionStorage'];

    function telerikReportDetailscontroller(logger, report, $sessionStorage) {
        var vm = this;

        vm.report = report;
        vm.parameters = {
            tenantId: $sessionStorage.userInfo.tenantId,
            userId: $sessionStorage.userInfo.userId
        };

    }
})();
