(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.classroomEducation')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.activities.edit.classroomEducationSecondStep', {
                url: '/classroomEducationSecondStep',
                templateUrl: 'app/educations/administration/activities/edit/classroomEducation/secondStep.html',
                controller: 'ClassroomEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin'],
                    isActivityEdit: true
                },
                resolve: {
                }
            })
            .state('educations.administration.activities.add.classroomEducationSecondStep', {
                url: '/classroomEducationSecondStep',
                templateUrl: 'app/educations/administration/activities/edit/classroomEducation/secondStep.html',
                controller: 'ClassroomEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'EducationAdmin'],
                    isActivityEdit: false
                },
                resolve: {
                }
            });
    }
})();
