(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('Reports', reports);

    reports.$inject = ['logger', 'reports', 'reportGroup', 'reportType', '$state', '$timeout', '$scope'];

    function reports(logger, reports, reportGroup, reportType, $state, $timeout, $scope) {
        var vm = this;

        //#region Slider
        var $slyFrame = $('.reports .sly-frame');
        var $reportTiles = $('.reports .report-tiles');
        var $navPrev = $('.sly-nav-prev');
        var $navNext = $('.sly-nav-next');

        $slyFrame.sly({
            horizontal: true,
            itemNav: 'basic',
            smart: 1,
            activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt: 3,
            speed: 300,
            scrollBy: 1,
            prevPage: $navPrev,
            nextPage: $navNext,

            //slidee: $reportTiles
            
        });

        // Ako nema ovoga onda se pojavi bug - ne žele se prikazati strelice na slideru
        setTimeout(function () {
            $(window).resize();
        }, 100);

        //#endregion

        vm.reports = reports;
        vm.formData = {
            reportGroup: reportGroup,
            reportType: reportType,
            learnerReports: [],
            administratorReports: [],
            managerReports: [],

            selectedReportGroup: undefined
        };
        vm.openReport = openReport;
        vm.groupSelected = groupSelected;

        filterReports();

        //#region Select initial group
        if (vm.formData.learnerReports.length !== 0) {
            groupSelected(vm.formData.reportGroup.learner);
        }
        else if (vm.formData.managerReports.length !== 0) {
            groupSelected(vm.formData.reportGroup.manager);
        }
        else if (vm.formData.administratorReports.length !== 0) {
            groupSelected(vm.formData.reportGroup.admin);
        }
        //#endregion


        function groupSelected(group) {
            vm.formData.selectedReportGroup = group;
            if (group === vm.formData.reportGroup.manager) {
                vm.reports = vm.formData.managerReports;
            }
            else if (group === vm.formData.reportGroup.learner) {
                vm.reports = vm.formData.learnerReports;
            }
            else if (group === vm.formData.reportGroup.admin) {
                vm.reports = vm.formData.administratorReports;
            }
            $timeout(function() {
                $slyFrame.sly('reload', []);
            }, 100);
        }

        function filterReports() {
            vm.formData.managerReports = [];
            vm.formData.learnerReports = [];
            vm.formData.administratorReports = [];

            angular.forEach(reports, function (report, index) {
                if (report.reportGroup === vm.formData.reportGroup.manager) {
                    vm.formData.managerReports.push(report);
                }
                else if (report.reportGroup === vm.formData.reportGroup.learner) {
                    vm.formData.learnerReports.push(report);
                }
                else if (report.reportGroup === vm.formData.reportGroup.admin) {
                    vm.formData.administratorReports.push(report);
                }
            });
        }

        function openReport(report) {
            logger.log("Report: ", report);
            if (report.reportType === vm.formData.reportType.telerik) {
                $state.go("reports.telerikReportDetails", { reportId: report.id });
            }
        }

    }

})();
