(function () {
    'use strict';

    angular
        .module('app.locations')
        .controller('locations', locationsController);

    locationsController.$inject = ['logger', 'locations', '$sessionStorage', 'i18nFilter'];

    function locationsController(logger, locations, $sessionStorage, i18nFilter) {
        var vm = this;
        vm.locations = locations;  

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            calendar: i18nFilter('translations.location_gui_title_calendar')
        };
         
        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',                
                allowSorting: true
            },
            {
                dataField: 'organization.name',
                caption: i18nFilter('translations.general_gui_organization'),
                alignment: 'left',
                allowSorting: true
            },
            {
                dataField: 'type',
                caption: i18nFilter('translations.general_gui_type'),
                alignment: 'left',
                width: 120,
                allowSorting: true,
                cellTemplate: 'locationsTypeTemplate'
            },
            {
                dataField: 'capacity',
                caption: i18nFilter('translations.location_gui_seats_no_label'),
                alignment: 'left',
                width: 120,
                allowSorting: true
            },
            {
                dataField: 'price',
                caption: i18nFilter('translations.location_gui_price_label'),
                alignment: 'left',
                width: 120,
                allowSorting: true
            },
            {
                dataField: 'participantsCost',
                caption: i18nFilter('translations.location_gui_participantsCost_label'),
                alignment: 'left',
                width: 120,
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                width: 120,
                allowSorting: false,
                cellTemplate: 'locationsStatusTemplate'
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 120,
                allowSorting: false,
                cellTemplate: 'locationsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.locations,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion
                      
    }
})();
