(function () {
    'use strict';

    angular
        .module('app.surveys.administration')
        .controller('EditSurvey', editSurvey);

    editSurvey.$inject = ['logger', 'survey', '$state', 'usersDataService', 'i18nFilter', 'surveysDataService', 'surveyType', 'exceptionHelper', '$stateParams', '$sanitize', '$sce'];

    function editSurvey(logger, survey, $state, usersDataService, i18nFilter, surveysDataService, surveyType, exceptionHelper, $stateParams, $sanitize, $sce) {
        var vm = this;
        vm.item = survey;
        vm.isEdit = $state.current.data.isEdit;
        vm.containerStyle = {};

        vm.localeMessages = {
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            status: i18nFilter("translations.survey_edit_gui_tooltip_info_status"),
        };

        if (vm.isEdit) {

            vm.containerStyle['margin-top'] = '-20px';
        }
        else {
            vm.containerStyle['margin'] = '10px';
            vm.item.active = true;
        }

        vm.formData = {
            users: [],
            usersIsLoading: false,
            tinymceOptions: {               
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            surveyType: surveyType
        };

        vm.refreshUsers = refreshUsers;
        vm.hasRequiredData = hasRequiredData;
        vm.finish = finish;
        vm.add = add;
        vm.cancel = cancel;

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                var users = [];

                angular.forEach(data, function (element, index) {
                    var user = {
                        id: element.id,
                        displayName: element.displayName,
                        email:element.email
                    }
                    users.push(user);
                });

                vm.formData.users = users;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function add() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.description = DOMPurify.sanitize(vm.item.description);

            surveysDataService.addSurvey(vm.item).then(function (data) {
                logger.success(i18nFilter("translations.survey_queue_add"));
                $state.go("classicCatalog.survey", $stateParams);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function hasRequiredData() {
            if (!vm.item.name ||
                (vm.item.surveyType == null || vm.item.surveyType == surveyType.none) || //don't validate only as !vm.item.surveyType
                 !vm.item.owners || (vm.item.owners && vm.item.owners.length == 0))
                return false;

            return true;
        }

        function finish() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.description = DOMPurify.sanitize(vm.item.description);

            surveysDataService.editSurvey(vm.item).then(function (data) {
                logger.success(i18nFilter("translations.survey_queue_edit"));
                $state.go("surveys.home", $stateParams);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('surveys.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.survey');
            }
        }
    }
})();
