(function () {
    'use strict';

    angular
        .module('app.urgentMessagesConfiguration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('urgentMessagesConfiguration', {
                parent: 'base',
                url: '/manage/urgentMessagesConfiguration',
                templateUrl: 'app/manage/urgentMessagesConfiguration/urgentMessagesConfiguration.html',
                controller: 'UrgentMessagesConfiguration',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    urgentMessages: ['urgentMessagesConfigurationDataService', '$stateParams', function (urgentMessagesConfigurationDataService, $stateParams) {
                        return urgentMessagesConfigurationDataService.getUrgentMessagesConfiguration();
                    }]
                },
                data: {
                    roles: ['TenantAdmin']
                }
            });
    }
})();
