(function () {
    'use strict';

    angular
        .module('app.learningPeriod')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('learningPeriods', {
            parent: 'base',
            url: '/manage/periods',
            templateUrl: 'app/manage/learningPeriods/periods.html',
            controller: 'LearningPeriods',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['EducationAdmin']
            }
        })
        .state('learningPeriods.add', {
            url: '/add',
            templateUrl: 'app/manage/learningPeriods/editPeriod.html',
            controller: 'EditPeriod',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                isEdit: false,
                title: "Dodaj",
                roles: ['EducationAdmin']
            }
        })
        .state('learningPeriods.edit', {
            url: '/edit/:id',
            templateUrl: 'app/manage/learningPeriods/editPeriod.html',
            controller: 'EditPeriod',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                isEdit: true,
                title: "Uredi",
                roles: ['EducationAdmin']
            }
        });
    }
})();