(function () {
    "use strict";

    angular
        .module("app.helper")
        .factory("notificationService", notificationService);

    notificationService.$inject = [
        "logger",
        "jsonHelper",
        "$sessionStorage",
        "i18nFilter",
        "exceptions",
        "$state",
        "$stateParams",
        "toastr",
        "processType",
        "workerStatus",
        "$http",
        "documentsDataService",
        "schedulePacksDataService",
        "examApplicationStatus",
        "examApplicationsDataService"
    ];

    function notificationService(
        logger,
        jsonHelper,
        $sessionStorage,
        i18nFilter,
        exceptions,
        $state,
        $stateParams,
        toastr,
        processType,
        workerStatus,
        $http,
        documentsDataService,
        schedulePacksDataService,
        examApplicationStatus,
        examApplicationsDataService
    ) {
        var hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("notifications")
            .build();

        var hubIsConected = false;

        var notificationData = {
            notifications: [],
            unreadMessages: 0,
            isSidebarOpen: false,
        };

        var service = {
            notify: notify,
            activate: activate,
            getNotificationDataForSignalR: getNotificationDataForSignalR,
            toggleSidebar: toggleSidebar,

            getNotificationData: getNotificationData,
            getMessage: getMessage,
        };

        return service;

        function activate() {
            if (!hubIsConected) {
                hubConnection.on("send", function (workerResult) {
                    workerResult =
                        jsonHelper.convertKeysToCamelCase(workerResult);
                    logger.log("Result from signalR", workerResult);

                    if (
                        service.notify !== undefined &&
                        $sessionStorage.userInfo.userId === workerResult.userId
                    ) {
                        service.notify(workerResult);
                    }
                });

                hubConnection
                    .start()
                    .then(function () {
                        logger.log("Hub connection started");
                        hubIsConected = true;
                    })
                    .catch(function (err) {
                        logger.log(
                            "Error while establishing connection with server",
                            err
                        );
                    });
            }
        }

        function notify(workerResult) {
            var data = getNotificationData(workerResult);

            if (chackIfRequestIsalreadyInList(data.requestOueueId)) {
                return;
            }

            notificationData.notifications.push(data);

            //Logika oko broja
            notificationData.unreadMessages++;

            if (workerResult.workerStatus === workerStatus.fail) {
                //Log error
                toastr.error(data.message, {
                    onTap: function (e) {
                        closeToast(e);
                        toggleSidebar();
                    },
                });
            } else {
                //Log success
                toastr.success(data.message, {
                    onTap: function (e) {
                        closeToast(e);
                        toggleSidebar();
                    },
                });
            }
        }

        function closeToast(toast) {
            toastr.clear();
        }

        function toggleSidebar() {
            notificationData.isSidebarOpen = !notificationData.isSidebarOpen;
            if (notificationData.isSidebarOpen) {
                notificationData.unreadMessages = 0;
            }
        }

        function chackIfRequestIsalreadyInList(requestOueueId) {
            var alreadyIn = false;
            for (var i = 0; i < notificationData.notifications.length; i++) {
                if (
                    notificationData.notifications[i].requestOueueId ==
                    requestOueueId
                ) {
                    alreadyIn = true;
                }
            }
            return alreadyIn;
        }

        function getNotificationDataForSignalR() {
            return notificationData;
        }

        //#region DataConverter
        function getMessage(exceptionCode, additionalData) {
            var message = undefined;
            switch (exceptionCode) {
                //#region General exceptions
                case exceptions.nameToLong:
                    if (additionalData.ex_name && additionalData.ex_name.length > 100) {
                        message = i18nFilter(
                            "translations.exception_name_waay_too_long_message",
                            additionalData.ex_name.substring(0, 100)
                        );
                    }
                    else {
                        message = i18nFilter(
                            "translations.exception_name_too_long_message",
                            additionalData.ex_name
                        );
                    }
                    return message;
                case exceptions.externalIdToLong:
                    message = i18nFilter(
                        "translations.exception_external_id_too_long"
                    );
                    return message;
                case exceptions.duplicateNameExists:
                    message = i18nFilter(
                        "translations.exception_duplicate_name_exists_message",
                        additionalData.ex_name
                    );
                    return message;
                case exceptions.duplicateExternalIdExists:
                    message = i18nFilter(
                        "translations.exception_duplicate_external_id_exists"
                    );
                    return message;
                case exceptions.incorectFormatException:
                    message = i18nFilter(
                        "translations.exception_incorrect_format"
                    );
                    return message;
                case exceptions.endDateIsGreaterThanStartDate:
                    message = i18nFilter(
                        "translations.exception_end_date_is_greater_than_start_date"
                    );
                    return message;
                case exceptions.urlFormatException:
                    message = i18nFilter(
                        "translations.exception_url_format_exception"
                    );
                    return message;
                case exceptions.defaultPeriodMissing:
                    message = i18nFilter(
                        "translations.exception_default_period_missing"
                    );
                    return message;
                case exceptions.tenantNotDefined:
                    message = i18nFilter(
                        "translations.exception_tenant_not_defined"
                    );
                    return message;
                case exceptions.objectNotFoundException:
                    message = i18nFilter(
                        "translations.exception_object_not_found"
                    );
                    return message;
                case exceptions.businessLogicException:
                    message = i18nFilter(
                        "translations.exception_business_logic"
                    );
                    return message;

                //#endregion

                //#region sharepoint ShP
                case exceptions.somethingWrongWithSharePoint:
                    message = i18nFilter(
                        "translations.exception_something_went_wrong_with_sharepoint"
                    );
                    return message;

                //#endregion
                //#region ShP survey
                case exceptions.userDidNotCompletedSurvey:
                    message = i18nFilter(
                        "translations.exception_user_did_not_completed_survey"
                    );
                    return message;
                //#endregion

                //#region Cities
                case exceptions.cannotDeleteCityWithOrganizationRelation:
                    message = i18nFilter(
                        "translations.city_exception_cannot_delete_city_with_organization_relation",
                        additionalData.ex_cityName
                    );
                    return message;
                //#endregion

                //#region Countries
                case exceptions.cannotDeleteCountryWithCityRelation:
                    message = i18nFilter(
                        "translations.country_exception_cannot_delete_country_with_city_relation",
                        additionalData.ex_countryName
                    );
                    return message;
                //#endregion

                //#region Educations
                case exceptions.educationHasNoCategory:
                    message = i18nFilter(
                        "translations.education_exception_education_has_no_category"
                    );
                    return message;
                case exceptions.cannotDeleteEducationWhichIsPartOfAProgram:
                    message = i18nFilter(
                        "translations.education_exception_cannot_delete_education_which_is_part_of_a_program"
                    );
                    return message;
                case exceptions.educationHasNoOwners:
                    message = i18nFilter(
                        "translations.education_exception_education_has_no_owner"
                    );
                    return message;
                case exceptions.educationHasEnrolledUsersAndCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.education_exception_education_has_enrolled_users"
                    );
                    return message;
                case exceptions.educationHasEnrolledUsersByAssignment:
                    message = i18nFilter(
                        "translations.education_exception_education_has_enrolled_users_by_assignment"
                    );
                    return message;
                case exceptions.educationIsFinishedAndCannotBeDeleted:
                    message = i18nFilter(
                        "translations.education_exception_education_is_finished_and_cannot_be_deleted"
                    );
                    return message;
                case exceptions.educationSelfEnrollmentNotAllowed:
                    message = i18nFilter(
                        "translations.education_exception_self_enrollment_not_allowed"
                    );
                    return message;
                case exceptions.userIsEnrolledInEducation:
                    message = i18nFilter(
                        "translations.education_exception_user_is_enrolled_in_education"
                    );
                    return message;
                case exceptions.userCannotEnrollToUnfinishedEducation:
                    message = i18nFilter(
                        "translations.education_exception_user_cannot_enroll_to_unfinished_education"
                    );
                    return message;
                case exceptions.cannotReenrollEducationInSamePeriod:
                    message = i18nFilter(
                        "translations.education_exception_cannot_reenroll_education_in_same_period"
                    );
                    return message;
                case exceptions.userAlreadyDisenrolledFromEducation:
                    message = i18nFilter(
                        "translations.education_exception_user_already_disenrolled_from_education"
                    );
                    return message;
                case exceptions.educationAlreadyRated:
                    message = i18nFilter(
                        "translations.education_exception_education_already_rated"
                    );
                    return message;
                case exceptions.cannotFinishEducationBecauseLastEnrollmentIsNotStarted:
                    message = i18nFilter(
                        "translations.education_exception_cannot_finish_education_because_last_enrollment_is_not_started"
                    );
                    return message;
                case exceptions.thereIsRequiredActivitiesThatIsNotFinished:
                    message = i18nFilter(
                        "translations.education_exception_there_is_required_activities_that_is_not_finished"
                    );
                    return message;
                case exceptions.educationDuplicateNameExists:
                    message = i18nFilter(
                        "translations.education_exception_education_duplicate_name_exists",
                        additionalData.ex_educationName
                    );
                    return message;
                case exceptions.cannotFinishEducationLearnerHasInProcessActivity:
                    message = i18nFilter(
                        "translations.education_exception_cannot_finish_education_because_user_has_activity_in_process"
                    );
                    return message;
                case exceptions.duplicateCodeForEducationOrProgramException:
                    message = i18nFilter(
                        "translations.education_exception_duplicate_code_for_education_or_program",
                        additionalData.ex_code
                    );
                    return message;
                case exceptions.missingRequiredTags:
                    message = i18nFilter(
                        "translations.education_required_tags"
                    );
                    return message;
                case exceptions.cannotDeletePromotedEducation:
                    message = i18nFilter(
                        "translations.education_exception_cannot_delete_promoted_education",
                        additionalData.ex_educationName
                    );
                    return message;
                case exceptions.promotedEducationCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.education_exception_promoted_education_cannot_be_deactivated",
                        additionalData.name
                    );
                    return message;
                case exceptions.scheduleWithoutActiveActivity:
                    message = i18nFilter(
                        "translations.education_schedule_without_active_activity"
                    );
                    return message;
                case exceptions.scheduleWithoutActivity:
                    message = i18nFilter(
                        "translations.education_schedule_without_any_activity"
                    );
                    return message;
                //#endregion

                //#region Schoolings
                case exceptions.schoolingHasNoCategory:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_has_no_category"
                    );
                    return message;
                case exceptions.cannotDeleteSchoolingWhichIsPartOfAProgram:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_delete_schooling_which_is_part_of_a_program"
                    );
                    return message;
                case exceptions.schoolingHasNoOwners:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_has_no_owner"
                    );
                    return message;
                case exceptions.schoolingHasEnrolledUsersAndCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_has_enrolled_users"
                    );
                    return message;
                case exceptions.schoolingHasEnrolledUsersByAssignment:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_has_enrolled_users_by_assignment"
                    );
                    return message;
                case exceptions.schoolingIsFinishedAndCannotBeDeleted:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_is_finished_and_cannot_be_deleted"
                    );
                    return message;
                case exceptions.schoolingSelfEnrollmentNotAllowed:
                    message = i18nFilter(
                        "translations.schooling_exception_self_enrollment_not_allowed"
                    );
                    return message;
                case exceptions.userIsEnrolledInSchooling:
                    message = i18nFilter(
                        "translations.schooling_exception_user_is_enrolled_in_schooling", additionalData.learner
                    );
                    return message;
                case exceptions.userCannotEnrollToUnfinishedSchooling:
                    message = i18nFilter(
                        "translations.schooling_exception_user_cannot_enroll_to_unfinished_schooling"
                    );
                    return message;
                case exceptions.cannotReenrollSchoolingInSamePeriod:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_reenroll_schooling_in_same_period"
                    );
                    return message;
                case exceptions.userAlreadyDisenrolledFromSchooling:
                    message = i18nFilter(
                        "translations.schooling_exception_user_already_disenrolled_from_schooling"
                    );
                    return message;
                case exceptions.schoolingAlreadyRated:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_already_rated"
                    );
                    return message;
                case exceptions.cannotFinishSchoolingBecauseLastEnrollmentIsNotStarted:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_finish_schooling_because_last_enrollment_is_not_started"
                    );
                    return message;
                case exceptions.thereIsRequiredActivitiesThatIsNotFinished:
                    message = i18nFilter(
                        "translations.schooling_exception_there_is_required_activities_that_is_not_finished"
                    );
                    return message;
                case exceptions.schoolingDuplicateNameExists:
                    message = i18nFilter(
                        "translations.schooling_exception_schooling_duplicate_name_exists",
                        additionalData.ex_schoolingName
                    );
                    return message;
                case exceptions.cannotFinishSchoolingLearnerHasInProcessActivity:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_finish_schooling_because_user_has_activity_in_process"
                    );
                    return message;
                case exceptions.duplicateCodeForSchoolingOrProgramException:
                    message = i18nFilter(
                        "translations.schooling_exception_duplicate_code_for_schooling_or_program",
                        additionalData.ex_code
                    );
                    return message;
                case exceptions.missingRequiredTags:
                    message = i18nFilter(
                        "translations.schooling_required_tags"
                    );
                    return message;
                case exceptions.cannotDeletePromotedSchooling:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_delete_promoted_schooling",
                        additionalData.ex_schoolingName
                    );
                    return message;
                case exceptions.promotedSchoolingCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.schooling_exception_promoted_schooling_cannot_be_deactivated",
                        additionalData.name
                    );
                    return message;
                //#endregion

                //#region Assignments
                case exceptions.assignmentDoesNotHaveSelectedLearners:
                    message = i18nFilter(
                        "translations.assignments_exception_assignment_does_not_have_selected_learners"
                    );
                    return message;
                case exceptions.cannotAddEnrolledLearnersToAssigment:
                    message = i18nFilter(
                        "translations.assignments_exception_cannot_add_enrolled_learners_to_assigment"
                    );
                    return message;
                case exceptions.cannotAddAssignmentBecauseSomeUserHasBeenEnrolledInScheduleBefore:
                    message = i18nFilter(
                        "translations.assignments_exception_cannot_add_assignment_in_schedule_with_enrolled_user"
                    );
                    return message;
                //#endregion

                //#region Does not exist
                case exceptions.learningActivityScheduleDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_learning_activity_schedule_does_not_exist"
                    );
                    return message;
                case exceptions.educationActivityScheduleDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_education_activity_schedule_does_not_exist"
                    );
                    return message;
                case exceptions.educationActivityDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_education_activity_does_not_exist"
                    );
                    return message;
                case exceptions.schoolingActivityScheduleDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_schooling_activity_schedule_does_not_exist"
                    );
                    return message;
                case exceptions.schoolingActivityDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_schooling_activity_does_not_exist"
                    );
                    return message;
                case exceptions.learningPlanItemDoesNotExists:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_learning_plan_item_does_not_exists"
                    );
                    return message;
                case exceptions.learningActivityDoesNotExists:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_learning_activity_does_not_exists"
                    );
                    return message;
                case exceptions.locationDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_location_does_not_exist"
                    );
                    return message;
                case exceptions.fileDoesNotExist:
                    message = i18nFilter(
                        "translations.does_not_exist_exception_file_does_not_exist"
                    );
                    return message;
                //#endregion

                //#region Schedule
                case exceptions.instructorsScheduleException:
                    message = i18nFilter(
                        "translations.schedule_exception_instructors_schedule"
                    );
                    return message;
                case exceptions.overLappingSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_overlapping_schedule"
                    );
                    return message;
                case exceptions.cannotDeleteScheduleWithEnrolledLearners:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_delete_schedule_with_enrolled_learners"
                    );
                    return message;
                case exceptions.userDoesNotEnrolledInActivitySchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_user_does_not_enrolled_in_activity_schedule",
                        additionalData.ex_displayName
                    );
                    return message;
                case exceptions.userWithFinishedActivityCannotBeEnrolledInActivitySchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_user_with_finished_activity_cannot_be_enrolled_in_activity_schedule"
                    );
                    return message;
                case exceptions.scheduleIsFullException:
                    message = i18nFilter(
                        "translations.schedule_exception_schedule_is_full_exception"
                    );
                    return message;
                case exceptions.cannotConfirmedCompletedUserSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_confirm_user_if_schedule_completed"
                    );
                    return message;
                case exceptions.cannotDisenrolledUserFromCompletedSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_disenroll_user_from_completed_schedule"
                    );
                    return message;
                case exceptions.cannotDisenrolledUserFromMissedSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_disenroll_user_from_missed_schedule"
                    );
                    return message;
                case exceptions.cannotDisenrolledUserFromCancelledSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_disenroll_user_from_canceled_schedule"
                    );
                    return message;
                case exceptions.cannotDenyMissedUserSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_deny_missed_user_schedule"
                    );
                    return message;
                case exceptions.missedCheckOutDateForDisenrollSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_missed_check_out_date_for_disenroll_schedule"
                    );
                    return message;
                case exceptions.missedCheckInDateForEnrollSchedule:
                    message = i18nFilter(
                        "translations.schedule_exception_missed_check_in_date_for_enroll_schedule"
                    );
                    return message;
                case exceptions.cannotEnrollUserInScheduleWhosAlreadyEnrolled:
                    message = i18nFilter(
                        "translations.schedule_exception_cannot_enroll_user_in_schedule"
                    );
                    return message;
                //#endregion

                //#region Education activities
                case exceptions.totalPointsCannotBeNegative:
                    message = i18nFilter(
                        "translations.activity_exception_total_points_cannot_be_negative"
                    );
                    return message;
                case exceptions.estimatedTimeInWrongFormat:
                    message = i18nFilter(
                        "translations.activity_exception_estimated_time_in_wrong_format"
                    );
                    return message;
                case exceptions.educationActivityDuplicateNameExists:
                    message = i18nFilter(
                        "translations.activity_exception_duplicate_name_exists",
                        additionalData.ex_activityName
                    );
                    return message;
                case exceptions.cannotAddActivityBecauseOfEducationExecutionTypeConsistention:
                    message = i18nFilter(
                        "translations.activity_exception_education_execution_type_consistention",
                        additionalData.ex_educationExecutionType
                    );
                    return message;

                case exceptions.learnerHasEnrolledOrCompletedLearningActivitySchedule:
                    message = i18nFilter(
                        "translations.activity_exception_learner_has_enrolled_or_completed_learning_activity_schedule"
                    );
                    return message;
                case exceptions.learnerCapacityCannotBeNegative:
                    message = i18nFilter(
                        "translations.activity_exception_learner_capacity_cannot_be_negative"
                    );
                    return message;
                case exceptions.scheduleWithoutInstructorsException:
                    message = i18nFilter(
                        "translations.activity_exception_schedule_without_instructors_exception"
                    );
                    return message;
                case exceptions.unableToDeleteActivityWithExistingSchedules:
                    message = i18nFilter(
                        "translations.activity_exception_unable_to_delete_activity_with_existing_schedules"
                    );
                    return message;
                //#endregion

                //#region DMS
                case exceptions.dmsConfigMissingProperty:
                    message = i18nFilter(
                        "translations.dms_exception_dms_config_missing_property"
                    );
                    return message;
                case exceptions.invalidFileNameChars:
                    message = i18nFilter(
                        "translations.dms_exception_dms_invalid_file_name_chars"
                    );
                    return message;
                case exceptions.fileNameAlreadyExists:
                    message = i18nFilter(
                        "translations.dms_exception_dms_file_name_already_exists"
                    );
                    return message;
                case exceptions.fileIsNotSupported:
                    message = i18nFilter(
                        "translations.dms_exception_dms_file_with_this_extension_is_not_supported"
                    );
                    return message;
                //#endregion

                //#region Templates
                case exceptions.fileIsTooLarge:
                    message = i18nFilter(
                        "translations.templates_exception_file_too_large"
                    );
                    return message;
                case exceptions.fileFormatException:
                    message = i18nFilter(
                        "translations.templates_exception_file_format"
                    );
                    return message;
                case exceptions.duplicateTemplateNameExists:
                    message = i18nFilter(
                        "translations.templates_exception_duplicate_template_name_exists",
                        additionalData.ex_templateName
                    );
                    return message;
                case exceptions.cannotDeleteTemplateWithActiveEducationRelation:
                    message = i18nFilter(
                        "translations.templates_exception_cannot_delete_template_with_active_education_relation"
                    );
                    return message;
                case exceptions.cannotDeleteTemplateWithActiveSchoolingRelation:
                    message = i18nFilter(
                        "translations.templates_exception_cannot_delete_template_with_active_schooling_relation"
                    );
                    return message;
                case exceptions.templateCannotBeEmpty:
                    message = i18nFilter(
                        "translations.templates_exception_template_cannot_be_empty",
                        additionalData.ex_fileName
                    );
                    return message;
                case exceptions.missingTemplateException:
                    message = i18nFilter(
                        "translations.templates_gui_missing_template_exception"
                    );
                    return message;
                case exceptions.generateDocumentException:
                    message = i18nFilter(
                        "translations.templates_gui_generate_document_exception"
                    );
                    return message;
                //#endregion

                //#region Process service
                case exceptions.missingClientID:
                    message = i18nFilter(
                        "translations.process_service_exception_missing_client_id"
                    );
                    return message;
                case exceptions.dataIsNotDefined:
                    message = i18nFilter(
                        "translations.process_service_exception_data_is_not_defined"
                    );
                    return message;
                //#endregion

                //#region Locations
                case exceptions.locationHasActiveShedules:
                    message = i18nFilter(
                        "translations.location_exception_location_has_active_schedules"
                    );
                    return message;
                //#endregion

                //#region Organizations
                case exceptions.organizationHasDeletedOrDeactivatedParentException:
                    message = i18nFilter(
                        "translations.organization_exception_organization_has_deleted_or_deactivated_parent",
                        additionalData.ex_parentName
                    );
                    return message;
                case exceptions.organizationParentTypeLevelException:
                    message = i18nFilter(
                        "translations.organization_exception_organization_parent_type_level"
                    );
                    return message;
                case exceptions.organizationChildrenTypeLevelException:
                    message = i18nFilter(
                        "translations.organization_exception_organization_children_type_level"
                    );
                    return message;
                case exceptions.organizationHasActiveUsersAndItCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.organization_exception_organization_has_active_users"
                    );
                    return message;
                case exceptions.organizationHasActiveChildrenAndItCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.organization_exception_organization_has_active_children"
                    );
                    return message;
                case exceptions.organizationHasActiveLocationsAndItCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.organization_exception_organization_has_active_locations"
                    );
                    return message;
                //#endregion

                //#region LearningPeriod
                case exceptions.learningPeriodDateException:
                    message = i18nFilter(
                        "translations.learning_period_exception_date"
                    );
                    return message;
                //#endregion

                //#region Categories
                case exceptions.categoryExistsInEducation:
                    message = i18nFilter(
                        "translations.category_exception_exists_in_education",
                        additionalData.ex_categoryName
                    );
                    return message;
                case exceptions.categoryDeletedOrDeactivatedParentException:
                    message = i18nFilter(
                        "translations.category_exception_deleted_or_deactivated_parent"
                    );
                    return message;
                case exceptions.categoryHierarchyLevelException:
                    message = i18nFilter(
                        "translations.category_exception_hierarchy_level"
                    );
                    return message;
                case exceptions.categoryExistsInProgram:
                    message = i18nFilter(
                        "translations.category_exception_exists_in_education",
                        additionalData.ex_categoryName
                    );
                    return message;
                //#endregion

                //#region Tenants
                case exceptions.tenantExternalIdToLong:
                    message = i18nFilter(
                        "translations.tenant_exception_external_id_too_long"
                    );
                    return message;
                case exceptions.tenantSharepointconfigurationIsInvalid:
                    message = i18nFilter(
                        "translations.tenant_exception_sharepoint_configuration_invalid"
                    );
                    return message;
                case exceptions.tenantConfigurationInWrongFormat:
                    message = i18nFilter(
                        "translations.tenant_exception_configuration_in_wrong_format"
                    );
                    return message;
                //#endregion

                //#region Promoted Entities
                case exceptions.promotedEntityAlreadyExists:
                    message = i18nFilter(
                        "translations.promoted_entity_exception_promoted_entity_already_exists",
                        additionalData.ex_entityName
                    );
                    return message;
                //#endregion

                //#region Users
                case exceptions.cannotSelectDynamicRole:
                    message = i18nFilter(
                        "translations.user_exception_can_not_select_dyncamic_role"
                    );
                    return message;
                case exceptions.userWithTheSameNameLastNameAndEmailExists:
                    message = i18nFilter(
                        "translations.user_with_the_same_first_last_name_email_already_exists"
                    );
                    return message;
                case exceptions.emailNotUnique:
                    message = i18nFilter(
                        "translations.user_email_already_exists"
                    );
                    return message;
                //#endregion

                //#region Programs
                case exceptions.programDuplicateNameExists:
                    message = i18nFilter(
                        "translations.program_error_message_duplicate_name_exists",
                        additionalData.ex_programName
                    );
                    return message;
                case exceptions.programHasNoOwners:
                    message = i18nFilter(
                        "translations.program_error_message_has_no_owner"
                    );
                    return message;
                case exceptions.cannotDeletePromotedProgram:
                    message = i18nFilter(
                        "translations.program_error_message_can_not_delete_promoted_program"
                    );
                    return message;
                case exceptions.promotedProgramCannotBeDeactivated:
                    message = i18nFilter(
                        "translations.program_error_message_promoted_program_can_not_be_deactivated"
                    );
                    return message;
                case exceptions.programHasEducationsWithSchedules:
                    message = i18nFilter(
                        "translations.program_error_message_program_has_educations_with_schedules",
                        additionalData.ex_programName
                    );
                    return message;
                case exceptions.categoryExistsInSchooling:
                    message = i18nFilter(
                        "translations.category_exception_exists_in_schooling",
                        additionalData.ex_categoryName
                    );
                    return message;
                case exceptions.programHasSchoolingsWithSchedules:
                    message = i18nFilter(
                        "translations.program_error_message_program_has_schoolings_with_schedules",
                        additionalData.ex_programName
                    );
                    return message;
                case exceptions.cannotDeleteEnrolledProgram:
                    message = i18nFilter(
                        "translations.program_error_message_can_not_delete_enrolled_program",
                        additionalData.ex_programName
                    );
                    return message;
                //#endregion

                //#region Authentication
                case exceptions.userTenantNotFound:
                    message = i18nFilter(
                        "translations.authentication_error_message_user_tenant_not_found"
                    );
                    return message;
                case exceptions.tokenInvalid:
                    message = i18nFilter(
                        "translations.authentication_error_message_token_invalid"
                    );
                    return message;
                //#endregion

                //#region Budgets
                case exceptions.allRelatedEntitiesNeedsToBeActive:
                    message = i18nFilter(
                        "translations.budget_error_message_all_related_entities_active"
                    );
                    return message;
                case exceptions.budgetIsOverlapping:
                    message = i18nFilter(
                        "translations.budget_error_message_budget_overlapping"
                    );
                    return message;
                case exceptions.cannotDeleteBudgedBecauseItIsUsedBySomeSchedules:
                    message = i18nFilter(
                        "translations.budget_error_message_budget_used_by_some_schedules"
                    );
                    return message;
                case exceptions.removedEducationsHaveActiveSchedules:
                    message = i18nFilter(
                        "translations.budget_error_message_removed_educations_have_active_schedules"
                    );
                    return message;
                case exceptions.removedSchoolingsHaveActiveSchedules:
                    message = i18nFilter(
                        "translations.budget_error_message_removed_schoolings_have_active_schedules"
                    );
                    return message;
                case exceptions.cannotDeleteBudgetBecauseItIsUsedBySomeSchedules:
                    message = i18nFilter(
                        "translations.budget_error_can_not_delete_budget_because_it_is_used_by_some_schedules"
                    );
                    return message;
                //#endregion

                //#region Contracts
                case exceptions.ibanValidationFailed:
                    message = i18nFilter(
                        "translations.contract_error_message_iban_validation_failed"
                    );
                    return message;
                case exceptions.contractIsOverlapping:
                    message = i18nFilter(
                        "translations.contract_error_message_contract_overlapping"
                    );
                    return message;
                case exceptions.contractWithSameNumberAlreadyExists:
                    message = i18nFilter(
                        "translations.contract_error_message_contract_with_same_number_already_exists"
                    );
                    return message;
                case exceptions.cannotDeleteBudgetContractBecauseItIsUsedInSomeSchedules:
                    message = i18nFilter(
                        "translations.contract_error_message_budget_contract_used_by_some_schedules"
                    );
                    return message;
                case exceptions.removedContractorsHaveActiveSchedules:
                    message = i18nFilter(
                        "translations.contract_error_message_removed_contractors_have_active_schedules"
                    );
                    return message;
                //#endregion

                //#region Competence
                case exceptions.CompetenceGroupDuplicateNameExists:
                    message = i18nFilter(
                        "translations.competence_group_duplicate_name_exists"
                    );
                    return message;
                case exceptions.CompetenceSubgroupDuplicateNameExists:
                    message = i18nFilter(
                        "translations.competence_subgroup_duplicate_name_exists"
                    );
                    return message;
                case exceptions.CompetenceDuplicateNameExists:
                    message = i18nFilter(
                        "translations.competence_duplicate_name_exists"
                    );
                    return message;
                case exceptions.CompetenceLevelDuplicateNameExists:
                    message = i18nFilter(
                        "translations.competence_level_duplicate_name_exists"
                    );
                    return message;
                case exceptions.CompetenceLevelDuplicateOrderNumberExists:
                    message = i18nFilter(
                        "translations.competence_level_duplicate_order_number_exists"
                    );
                    return message;
                case exceptions.CompetenceSubgroupOrCompetenceExists:
                    message = i18nFilter(
                        "translations.competence_subgroup_or_competence_exists"
                    );
                    return message;
                //#endregion

                //#region Surveys
                case exceptions.surveyQuestionGroupDoesNotExist:
                    message = i18nFilter(
                        "translations.survey_exception_question_group_doesnt_exist"
                    );
                    return message;
                case exceptions.surveyQuestionDoesNotExist:
                    message = i18nFilter(
                        "translations.survey_exception_question_doesnt_exist"
                    );
                    return message;
                case exceptions.surveyDuplicateNameExists:
                    message = i18nFilter(
                        "translations.survey_exception_survey_name_already_exists"
                    );
                    return message;
                case exceptions.surveyGroupDuplicateNameExists:
                    message = i18nFilter(
                        "translations.survey_exception_survey_group_name_already_exists"
                    );
                    return message;
                case exceptions.surveyCanNotBeDeletedException:
                    message = i18nFilter(
                        "translations.survey_exception_survey_can_not_be_deleted"
                    );
                    return message;
                //#endregion

                //#region Exam
                case exceptions.examDuplicateName:
                    message = i18nFilter(
                        "translations.education_exception_education_duplicate_name_exists",
                        additionalData.name
                    );
                    return message;
                case exceptions.examHasNoCoordinators:
                    message = i18nFilter(
                        "translations.exams_error_message_has_no_coordinators"
                    );
                    return message;
                case exceptions.examHasNoCommitteeMembers:
                    message = i18nFilter(
                        "translations.exams_error_message_has_no_committee_members"
                    );
                    return message;
                case exceptions.examHasNoContactPerson:
                    message = i18nFilter(
                        "translations.exams_error_message_has_no_contact_person"
                    );
                    return message;
                //#endregion

                //#region ExamSchedule
                case exceptions.examScheduleInvalidTimespan:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_invalid_timespan"
                    );
                    return message;
                case exceptions.examScheduleInvalidDuration:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_invalid_duration"
                    );
                    return message;
                case exceptions.examScheduleDuplicateCode:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_code_duplicate"
                    );
                    return message;
                case exceptions.examScheduleInvalidIpRange:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_invalid_ip_range"
                    );
                    return message;
                case exceptions.examThemeIsAlreadyUsedOnApplication:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_exam_theme_already_used_on_application"
                    );
                    return message;
                case exceptions.examArrangementHasMembersWithFinalResults:
                    message = i18nFilter(
                        "translations.exam_schedules_error_message_exam_arrangement_has_members_with_final_results"
                    );
                    return message;
                //#endregion

                //#region Schooling
                case exceptions.cannotDeleteSchoolingWithSchedules:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_delete_schooling_with_schedules"
                    );
                    return message;
                case exceptions.cannotReturnFinishedOrAttendingSchoolingApplicationLearnercs:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_delete_schooling_with_schedules", additionalData.learner
                    );
                    return message;

                //#endregion

                //#region Schooling Certificate
                case exceptions.cannotDeleteSchoolingCertificateDocument:
                    message = i18nFilter(
                        "translations.schooling_exception_cannot_delete_schooling_certificate_document", additionalData.learner
                    );
                    return message;
                //#endregion Schooling Certificate


                //#region CertificateSign
                case exceptions.signingServiceNotAvailable:
                    message = i18nFilter(
                        "translations.general_certificate_sign_service_not_available_exception"
                    );
                    return message;                   
                //#endregion

                //#region ReleaseHistory
                case exceptions.releasesHistoryCouldNotAccessJSONData:
                    message = i18nFilter(
                        "translations.general_error_message_release_history_not_loaded"
                    );
                    return message;  
                //#endregion

                case exceptions.error:
                    message = i18nFilter(
                        "translations.general_error_message_something_went_wrong_description"
                    );
                    return message;
            }
        }

        function getNotificationData(data) {
            var result = undefined;

            switch (data.processType) {
                //#region Categories
                case processType.categoryAdd:
                    result = {
                        title: i18nFilter(
                            "translations.category_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.category_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCategories();
                    break;

                case processType.categoryEdit:
                    result = {
                        title: i18nFilter(
                            "translations.category_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.category_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCategories();
                    break;

                case processType.categoryDelete:
                    result = {
                        title: i18nFilter(
                            "translations.category_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.category_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.category_action_show_all_categories"
                                ),
                                iconClass: "fa fa-list",
                                route: "categories",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCategories();
                    break;
                //#endregion

                //#region Locations

                case processType.locationAdd:
                    result = {
                        title: i18nFilter(
                            "translations.location_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.location_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLocations();
                    break;

                case processType.locationEdit:
                    result = {
                        title: i18nFilter(
                            "translations.location_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.location_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLocations();
                    break;

                case processType.locationDelete:
                    result = {
                        title: i18nFilter(
                            "translations.location_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.location_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.location_action_show_all_locations"
                                ),
                                iconClass: "fa fa-list",
                                route: "locations",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLocations();
                    break;
                //#endregion

                //#region Educations

                case processType.selfEnrollment:
                    result = {
                        title: i18nFilter(
                            "translations.education_operation_enroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_enroll_success_message",
                            data.additionalData.educationName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.educations_action_show_all_educations"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.home",
                                params: {
                                    id: data.additionalData.newEducationId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshEducations(data.additionalData.newEducationId);
                    break;

                case processType.selfEnrollmentAgain:
                    result = {
                        title: i18nFilter(
                            "translations.education_operation_enroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_enroll_success_message",
                            data.additionalData.educationName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.educations_action_show_all_educations"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.home",
                                params: {
                                    id: data.additionalData.newEducationId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshEducations(data.additionalData.newEducationId);
                    break;

                case processType.resetEducationEnrollment:
                    result = {
                        title: i18nFilter(
                            "translations.education_operation_disenroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_reset_success_message",
                            data.additionalData.educationName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.educations_action_show_all_educations"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.home",
                                params: {
                                    id: data.additionalData.newEducationId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshEducations(data.additionalData.newEducationId);
                    break;

                case processType.educationRating:
                    result = {
                        title: i18nFilter(
                            "translations.education_operation_rating_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_rating_success_message",
                            data.additionalData.educationName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.educations_action_show_all_educations"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.home",
                                params: {
                                    id: data.additionalData.newEducationId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshEducations(data.additionalData.newEducationId);
                    break;

                case processType.administrativeFinishLearnersEducations:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_confirm_finish_education_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_confirm_finish_education",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    if (!data.additionalData.startedDocumentGeneration) {
                        refreshSchedulePresence();
                    }

                    break;

                case processType.administrativeCancelFinishLearnersEducations:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_cancel_finish_education_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_cancel_finish_education",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.learnerFinishEducation:
                    console.log(learnerFinishEducation);
                    result = {
                        title: i18nFilter(
                            "translations.education_operation_learner_finish_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_learner_finish_success_message",
                            data.additionalData.educationName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.educations_action_show_all_educations"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.home",
                                params: {
                                    id: data.additionalData.newEducationId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshEducations(data.additionalData.newEducationId);
                    break;
                //#endregion

                //#region Schoolings

                case processType.resetSchoolingEnrollment:
                    result = {
                        title: i18nFilter(
                            "translations.schooling_operation_disenroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schooling_operation_reset_success_message",
                            data.additionalData.schoolingName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schoolings_action_show_all_schoolings"
                                ),
                                iconClass: "fa fa-list",
                                route: "schoolings.home",
                                params: {
                                    id: data.additionalData.newSchoolingId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchoolings(data.additionalData.newSchoolingId);
                    break;

                case processType.schoolingRating:
                    result = {
                        title: i18nFilter(
                            "translations.schooling_operation_rating_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schooling_operation_rating_success_message",
                            data.additionalData.schoolingName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schoolings_action_show_all_schoolings"
                                ),
                                iconClass: "fa fa-list",
                                route: "schoolings.home",
                                params: {
                                    id: data.additionalData.newSchoolingId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchoolings(data.additionalData.newSchoolingId);
                    break;

                case processType.administrativeFinishLearnersSchoolings:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_confirm_finish_schooling_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_confirm_finish_schooling",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.administrativeCancelFinishLearnersSchoolings:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_cancel_finish_schooling_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_cancel_finish_schooling",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.learnerFinishSchooling:
                    result = {
                        title: i18nFilter(
                            "translations.schooling_operation_learner_finish_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schooling_operation_learner_finish_success_message",
                            data.additionalData.schoolingName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schoolings_action_show_all_schoolings"
                                ),
                                iconClass: "fa fa-list",
                                route: "schoolings.home",
                                params: {
                                    id: data.additionalData.newSchoolingId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchoolings(data.additionalData.newSchoolingId);
                    break;
                //#endregion

                //#region Organizations

                case processType.organizationAdd:
                    result = {
                        title: i18nFilter(
                            "translations.organization_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.organization_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.organization_action_show_all_organizations"
                                ),
                                iconClass: "fa fa-list",
                                route: "organizations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshOrganizations();
                    break;

                case processType.organizationEdit:
                    result = {
                        title: i18nFilter(
                            "translations.organization_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.organization_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.organization_action_show_all_organizations"
                                ),
                                iconClass: "fa fa-list",
                                route: "organizations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.organization_action_show_all_organizations"
                                ),
                                iconClass: "fa fa-list",
                                route: "organizations",
                                params: undefined,
                            },
                        ];
                    }

                    refreshOrganizations();
                    break;

                case processType.organizationSync:
                    result = {
                        title: i18nFilter(
                            "translations.organization_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.organization_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.organization_action_show_all_organizations"
                                ),
                                iconClass: "fa fa-list",
                                route: "organizations",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.organization_action_show_all_organizations"
                                ),
                                iconClass: "fa fa-list",
                                route: "organizations",
                                params: undefined,
                            },
                        ];
                    }

                    refreshOrganizations();
                    break;
                //#endregion

                //#region Learning period

                case processType.learningPeriodAdd:
                    result = {
                        title: i18nFilter(
                            "translations.learning_period_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.learning_period_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLearningPeriods();
                    break;

                case processType.learningPeriodEdit:
                    result = {
                        title: i18nFilter(
                            "translations.learning_period_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.learning_period_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLearningPeriods();
                    break;

                case processType.learningPeriodSetDefault:
                    result = {
                        title: i18nFilter(
                            "translations.learning_period_operation_setdefault_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.learning_period_operation_setdefault_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLearningPeriods();
                    break;

                case processType.learningPeriodMoveUp:
                    result = {
                        title: i18nFilter(
                            "translations.learning_period_operation_moveup_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.learning_period_operation_moveup_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLearningPeriods();
                    break;

                case processType.learningPeriodMoveDown:
                    result = {
                        title: i18nFilter(
                            "translations.learning_period_operation_movedown_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.learning_period_operation_movedown_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.learning_period_action_show_all_learning_periods"
                                ),
                                iconClass: "fa fa-list",
                                route: "learningPeriods",
                                params: undefined,
                            },
                        ];
                    }

                    refreshLearningPeriods();
                    break;
                //#endregion

                //#region Document templates

                case processType.addTemplate:
                    result = {
                        title: i18nFilter(
                            "translations.templates_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.templates_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    }

                    refreshTemplates();
                    break;

                case processType.editTemplate:
                    result = {
                        title: i18nFilter(
                            "translations.templates_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.templates_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    }

                    refreshTemplates();
                    break;

                case processType.deleteTemplate:
                    result = {
                        title: i18nFilter(
                            "translations.templates_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.templates_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.templates_action_show_all_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "templates",
                                params: undefined,
                            },
                        ];
                    }

                    refreshTemplates();
                    break;

                //#endregion

                //#region Activities

                case processType.activityMoveUp:
                    result = {
                        title: i18nFilter(
                            "translations.activity_operation_move_up_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.activity_operation_move_up_success_message",
                            data.additionalData.activityName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    }

                    refreshEducations(data.additionalData.educationId);
                    break;

                case processType.activityMoveDown:
                    result = {
                        title: i18nFilter(
                            "translations.activity_operation_move_down_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.activity_operation_move_down_success_message",
                            data.additionalData.activityName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    }

                    refreshEducations(data.additionalData.educationId);
                    break;

                case processType.activityDeactivate:
                    result = {
                        title: i18nFilter(
                            "translations.general_action_deactivate_activity"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.activity_operation_deactivate_success_message",
                            data.additionalData.activityName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    }
                    refreshActivities();
                    break;

                case processType.confirmGenericActivity:
                case processType.confirmHtml5Activity:
                case processType.confirmOfficeMixActivity:
                case processType.confirmVideoActivity:
                case processType.confirmSharePointSurveyActivity:
                    result = {
                        title: i18nFilter(
                            "translations.activity_operation_confirm_finish_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.activity_operation_confirm_finish_success_message",
                            data.additionalData.activityName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.activity_action_show_all_activities"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.activities",
                                params: { id: data.additionalData.educationId },
                            },
                        ];
                    }

                    refreshEducations(data.additionalData.educationId);
                    break;

                //#endregion

                //#region Document generation

                case processType.generateEducationFinishDocuments:
                case processType.regenerateEducationFinishDocuments:
                    result = {
                        title: i18nFilter(
                            "translations.diploms_operation_generate_document_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_regenerate_certificate_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    break;

                case processType.generateEducationFinishDocumentsGroup:
                case processType.regenerateEducationFinishDocumentsGroup:
                    result = {
                        title: i18nFilter(
                            "translations.diploms_operation_generate_document_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.education_operation_regenerate_certificate_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshSchedulePresence();
                    break;

                case processType.uploadCertificate:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_gui_upload_certificate"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.presence_upload_certificate_modal_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.generateSchoolingFinishDocuments:
                case processType.regenerateSchoolingFinishDocuments:
                    result = {
                        title: i18nFilter(
                            "translations.diploms_operation_generate_document_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schooling_operation_regenerate_certificate_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    break;
                //#endregion

                //#region Cities

                case processType.cityAdd:
                    result = {
                        title: i18nFilter(
                            "translations.city_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.city_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.city_action_show_all_cities"
                                ),
                                iconClass: "fa fa-list",
                                route: "cities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCities();
                    break;

                case processType.cityEdit:
                    result = {
                        title: i18nFilter(
                            "translations.city_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.city_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.city_action_show_all_cities"
                                ),
                                iconClass: "fa fa-list",
                                route: "cities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCities();
                    break;

                case processType.cityDelete:
                    result = {
                        title: i18nFilter(
                            "translations.city_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.city_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.city_action_show_all_cities"
                                ),
                                iconClass: "fa fa-list",
                                route: "cities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCities();
                    break;
                //#endregion

                //#region Countries
                case processType.countryAdd:
                    result = {
                        title: i18nFilter(
                            "translations.country_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.country_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCountries();
                    break;

                case processType.countryEdit:
                    result = {
                        title: i18nFilter(
                            "translations.country_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.country_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCountries();
                    break;

                case processType.countryDelete:
                    result = {
                        title: i18nFilter(
                            "translations.country_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.country_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.countries_action_show_all_countries"
                                ),
                                iconClass: "fa fa-list",
                                route: "countries",
                                params: undefined,
                            },
                        ];
                    }

                    refreshCountries();
                    break;
                //#endregion

                //#region Promoted entities
                case processType.promotedEntityAdd:
                    result = {
                        title: i18nFilter(
                            "translations.promoted_entity_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.promoted_entity_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    }

                    refreshPromotedEntities();
                    break;

                case processType.promotedEntityDelete:
                    result = {
                        title: i18nFilter(
                            "translations.promoted_entity_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.promoted_entity_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    }

                    refreshPromotedEntities();
                    break;

                case processType.promotedEntityMoveDown:
                    result = {
                        title: i18nFilter(
                            "translations.promoted_entity_operation_move_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.promoted_entity_operation_move_down_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    }

                    refreshPromotedEntities();
                    break;

                case processType.promotedEntityMoveUp:
                    result = {
                        title: i18nFilter(
                            "translations.promoted_entity_operation_move_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.promoted_entity_operation_move_up_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.promoted_entity_action_show_all_promoted_entities"
                                ),
                                iconClass: "fa fa-list",
                                route: "promotedEntities",
                                params: undefined,
                            },
                        ];
                    }

                    refreshPromotedEntities();
                    break;
                //#endregion

                //#region Users
                case processType.userAdd:
                    result = {
                        title: i18nFilter(
                            "translations.user_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.user_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_action_show_all_users"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_action_show_all_users"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    }

                    refreshUsers();
                    break;

                case processType.userEdit:
                    result = {
                        title: i18nFilter(
                            "translations.user_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.user_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_action_show_all_users"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_action_show_all_users"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    }

                    refreshUsers();
                    break;

                case processType.userTransfer:
                    result = {
                        title: i18nFilter(
                            "translations.transfer_user_data_header"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.user_operation_edit_transfer_success",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.transfer_user_data_header"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.transfer_user_data_header"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    }

                    break;

                case processType.UserEntityRoleTransfer:
                    result = {
                        title: i18nFilter(
                            "translations.user_operation_roles_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.user_operation_roles_success_message",
                            data.additionalData.displayName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_operation_roles_title"
                                ),
                                iconClass: "fa fa-list",
                                route: "users.baseEdit.secondStep",
                                params: { userId: data.additionalData.userId },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.user_action_show_all_users"
                                ),
                                iconClass: "fa fa-list",
                                route: "users",
                                params: undefined,
                            },
                        ];
                    }

                    break;

                //#endregion

                //#region Profile
                case processType.editUserProfile:
                    result = {
                        title: i18nFilter(
                            "translations.profile_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.profile_operation_edit_success_message",
                            data.additionalData.displayName
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.profile_action_show_my_profile"
                                ),
                                iconClass: "fa fa-list",
                                route: "profile.edit",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshProfile();
                    break;

                //#endregion

                //#region Schedules
                case processType.enrollInSchedule:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_operation_admin_enroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_operation_admin_enroll_success_message"
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schedule_action_show_learners_in_schedule"
                                ),
                                iconClass: "fa fa-list",
                                route: "schedulePacks.details",
                                params: {
                                    id: data.additionalData.educationId,
                                    activityId:
                                        data.additionalData.educationActivityId,
                                    locationId: data.additionalData.locationId,
                                    scheduleId:
                                        data.additionalData
                                            .educationActivityScheduleId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schedule_action_show_learners_in_schedule"
                                ),
                                iconClass: "fa fa-list",
                                route: "schedulePacks.details",
                                params: {
                                    id: data.additionalData.educationId,
                                    activityId:
                                        data.additionalData.educationActivityId,
                                    locationId: data.additionalData.locationId,
                                    scheduleId:
                                        data.additionalData
                                            .educationActivityScheduleId,
                                },
                            },
                        ];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.administrativeJustifiedDenySchedulePresence:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_justified_deny_presence_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_justified_deny_presence",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.administrativeConfirmSchedulePresence:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_confirm_presence_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_confirm_presence",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.administrativeDenySchedulePresence:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_deny_presence_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_deny_presence",
                            data.additionalData
                                .numberOfSucessfullyProcessedUsers +
                                "/" +
                                data.additionalData.totalNumberOfUsers
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.administrativeEnrollUserInSchedule:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_operation_admin_enroll_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_operation_admin_enroll_success_message"
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schedule_action_show_learners_in_schedule"
                                ),
                                iconClass: "fa fa-list",
                                route: "schedulePacks.details",
                                params: {
                                    id: data.additionalData.educationId,
                                    activityId:
                                        data.additionalData.educationActivityId,
                                    locationId: data.additionalData.locationId,
                                    scheduleId:
                                        data.additionalData
                                            .educationActivityScheduleId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.schedule_action_show_learners_in_schedule"
                                ),
                                iconClass: "fa fa-list",
                                route: "educations.administration.presence",
                                params: {
                                    id: data.additionalData.educationId,
                                    activityId:
                                        data.additionalData.educationActivityId,
                                    locationId: data.additionalData.locationId,
                                    scheduleId:
                                        data.additionalData
                                            .educationActivityScheduleId,
                                },
                            },
                        ];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.eFormLearnerReplace:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_operation_admin_replace_learner_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_operation_admin_replace_learner_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }
                    refreshSchedulePresence();
                    break;

                case processType.unlockSchedule:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_unlock_schedule_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_unlock_schedule"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.lockSchedule:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_action_lock_schedule_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_action_lock_schedule"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                //#endregion
                //#region Notification

                case processType.notificationConfigEdit:
                    result = {
                        title: i18nFilter(
                            "translations.email_gui_notification_configuration_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.email_gui_notification_configuration_update_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshNotificationConfiguration();
                    break;

                case processType.notificationTemplateConfigEdit:
                    result = {
                        title: i18nFilter(
                            "translations.email_gui_notification_template_configuration_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.email_gui_notification_template_configuration_update_message"
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.email_show_templates"
                                ),
                                iconClass: "fa fa-list",
                                route: "notifications.templatesConfiguration",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshNotificationTemplateConfiguration();
                    break;

                //#endregion

                //#region Budgets

                case processType.budgetAdd:
                    result = {
                        title: i18nFilter(
                            "translations.budget_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.budget_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.budget_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "budgets",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshBudgets();
                    break;

                case processType.budgetEdit:
                    result = {
                        title: i18nFilter(
                            "translations.budget_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.budget_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.budget_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "budgets",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshBudgets();
                    break;

                case processType.budgetDelete:
                    result = {
                        title: i18nFilter(
                            "translations.budget_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.budget_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.budget_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "budgets",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshBudgets();
                    break;

                //#endregion

                //#region Contract

                case processType.contractAdd:
                    result = {
                        title: i18nFilter(
                            "translations.contract_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_operation_add_success_message",
                            data.additionalData.contractNumber
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.contract_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "contracts",
                                params: undefined,
                            },
                        ];


                        if (data.additionalData.templateId != null) {
                            downloadContract(data.additionalData.contractId);
                        }

                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshContracts();
                    break;

                case processType.contractEdit:
                    result = {
                        title: i18nFilter(
                            "translations.contract_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_operation_edit_success_message",
                            data.additionalData.contractNumber
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.contract_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "contracts",
                                params: undefined,
                            },
                        ];

                        if (data.additionalData.templateId != null) {
                            downloadContract(data.additionalData.contractId);
                        }

                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshContracts();
                    break;

                case processType.contractDelete:
                    result = {
                        title: i18nFilter(
                            "translations.contract_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_operation_delete_success_message",
                            data.additionalData.contractNumber
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.contract_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "contracts",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshContracts();
                    break;

                //#endregion

                //#region Schedule documents

                case processType.uploadInstructorRecord:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_records_upload_record"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_records_modal_upload_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.uploadInstructorCertificateOfEmployment:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_instructor_certificate_of_employment"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_instructor_certificate_of_employment_modal_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                case processType.uploadAttendanceList:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_presence_documents_upload_attendance_list"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_presence_attendance_list_modal_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;

                //#endregion

                //#region Competences

                case processType.competenceGroupAdd:
                    result = {
                        title: i18nFilter(
                            "translations.competence_group_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_group_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                case processType.competenceGroupEdit:
                    result = {
                        title: i18nFilter(
                            "translations.competence_group_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_group_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                case processType.competenceGroupDelete:
                    result = {
                        title: i18nFilter(
                            "translations.competence_group_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_group_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                //#endregion

                //#region Competences
                case processType.competenceAdd:
                    result = {
                        title: i18nFilter("translations.competence_add_title"),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                case processType.competenceEdit:
                    result = {
                        title: i18nFilter("translations.competence_edit_title"),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                case processType.competenceDelete:
                    result = {
                        title: i18nFilter(
                            "translations.competence_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                //#endregion

                //#region Competence level
                case processType.competenceLevelAdd:
                    result = {
                        title: i18nFilter(
                            "translations.competence_level_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_level_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    break;

                case processType.competenceLevelEdit:
                    result = {
                        title: i18nFilter(
                            "translations.competence_level_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_level_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    break;

                case processType.competenceLevelDelete:
                    result = {
                        title: i18nFilter(
                            "translations.competence_level_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_level_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    break;

                //#endregion

                //#region Competence level template

                case processType.competenceLevelsTemplateAdd:
                    result = {
                        title: i18nFilter(
                            "translations.competence_levels_template_manage_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_levels_template_add_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;

                case processType.competenceLevelsTemplateEdit:
                    result = {
                        title: i18nFilter(
                            "translations.competence_levels_template_manage_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.competence_levels_template_edit_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshCompetences();
                    break;
                //#endregion

                //#region Surveys

                case processType.surveyAdd:
                    result = {
                        title: i18nFilter(
                            "translations.survey_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_survey"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.home",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_all_surveys"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.survey",
                                params: undefined,
                            },
                        ];
                    }

                    refreshSurveysCatalog();
                    break;

                case processType.surveyEdit:
                    result = {
                        title: i18nFilter(
                            "translations.survey_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_survey"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.home",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_all_surveys"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.survey",
                                params: undefined,
                            },
                        ];
                    }

                    refreshSurveys(data.additionalData.id);
                    break;

                case processType.surveyDelete:
                    result = {
                        title: i18nFilter(
                            "translations.survey_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_all_surveys"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.survey",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_action_show_all_surveys"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.survey",
                                params: undefined,
                            },
                        ];
                    }

                    refreshSurveys();
                    break;

                case processType.surveyGroupAdd:
                    result = {
                        title: i18nFilter(
                            "translations.survey_group_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_group_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    }

                    refreshSurveyGroups();
                    break;

                case processType.surveyGroupEdit:
                    result = {
                        title: i18nFilter(
                            "translations.survey_group_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_group_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    }

                    refreshSurveyGroups();
                    break;

                case processType.surveyGroupDelete:
                    result = {
                        title: i18nFilter(
                            "translations.survey_group_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_group_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    }

                    refreshSurveyGroups();
                    break;

                case processType.surveyGroupOrderUp:
                    result = {
                        title: i18nFilter(
                            "translations.survey_group_gui_ordering_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_group_operation_order_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    }

                    refreshSurveyGroups();
                    break;

                case processType.surveyGroupOrderDown:
                    result = {
                        title: i18nFilter(
                            "translations.survey_group_gui_ordering_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_group_operation_order_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questionGroups",
                                params: { surveyId: data.additionalData.id },
                            },
                        ];
                    }

                    refreshSurveyGroups();
                    break;

                case processType.questionAdd:
                    result = {
                        title: i18nFilter(
                            "translations.survey_question_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_question_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    }

                    refreshSurveyQuestions();
                    break;

                case processType.questionEdit:
                    result = {
                        title: i18nFilter(
                            "translations.survey_question_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_question_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    }

                    refreshSurveyQuestions();
                    break;

                case processType.questionDelete:
                    result = {
                        title: i18nFilter(
                            "translations.survey_question_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_question_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    }

                    refreshSurveyQuestions();
                    break;

                case processType.questionOrderUp:
                    result = {
                        title: i18nFilter(
                            "translations.survey_question_gui_ordering_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_question_operation_order_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    }

                    refreshSurveyQuestions();
                    break;

                case processType.questionOrderDown:
                    result = {
                        title: i18nFilter(
                            "translations.survey_question_gui_ordering_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.survey_question_operation_order_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.survey_group_action_show_survey_groups"
                                ),
                                iconClass: "fa fa-list",
                                route: "surveys.questions",
                                params: {
                                    questionGroupId: data.additionalData.id,
                                },
                            },
                        ];
                    }

                    refreshSurveyQuestions();
                    break;
                //#endregion

                //#region Exams
                case processType.examAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exams_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exams_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exam"
                                ),
                                iconClass: "fa fa-list",
                                route: "exams.home",
                                params: { id: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exams"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.exam",
                                params: undefined,
                            },
                        ];
                    }

                    //TODO
                    //refreshExams();
                    break;

                case processType.examEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exams_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exams_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exam"
                                ),
                                iconClass: "fa fa-list",
                                route: "exams.home",
                                params: { id: data.additionalData.id },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exams"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.exam",
                                params: undefined,
                            },
                        ];
                    }

                    //TODO
                    //refreshExams();
                    break;

                case processType.examDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_operation_delete_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exams"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.exam",
                                params: undefined,
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exams_action_show_all_exams"
                                ),
                                iconClass: "fa fa-list",
                                route: "classicCatalog.exam",
                                params: undefined,
                            },
                        ];
                    }

                    //TODO
                    //refreshExams();
                    break;

                //#endregion
                //#region ExamSchedules
                case processType.examScheduleAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_schedules_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_schedules_operation_add_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                                params: {
                                    entityId: data.additionalData.examId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                                params: {
                                    entityId: data.additionalData.examId,
                                },
                            },
                        ];
                    }

                    refreshExamSchedules(data.additionalData.examId);
                    break;

                case processType.examScheduleEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_schedules_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_schedules_operation_edit_success_message",
                            data.additionalData.name
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                                params: {
                                    entityId: data.additionalData.examId,
                                },
                            },
                        ];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                                params: {
                                    entityId: data.additionalData.examId,
                                },
                            },
                        ];
                    }

                    refreshExamSchedules(data.additionalData.examId);
                    break;
                //#endregion

                //#region Catalogues
                case processType.examCatalogueAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_catalogues_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_catalogues_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_catalogues_action_show_all_catalogues"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_catalogues_action_show_all_catalogues"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues",
                            },
                        ];
                    }

                    refreshCatalogues();
                    break;

                case processType.examCatalogueCopy:
                    result = {
                        title: i18nFilter(
                            "translations.exam_catalogues_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_catalogues_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_catalogues_action_show_all_catalogues"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_catalogues_action_show_all_catalogues"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues",
                            },
                        ];
                    }

                    refreshCatalogues();
                    break;

                case processType.examCatalogueEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_catalogues_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_catalogues_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_catalogues_action_show_all_catalogues"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_catalogues_action_show_all_catalogues"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues",
                            },
                        ];
                    }

                    refreshCatalogues();
                    break;

                case processType.examCatalogueDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_catalogues_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_catalogues_operation_delete_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_catalogues_action_show_all_catalogues"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_catalogues_action_show_all_catalogues"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues",
                            },
                        ];
                    }

                    refreshCatalogues();
                    break;
                //#endregion

                //#region Questions
                case processType.examQuestionAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_questions_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_questions_action_show_all_questions"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues.questions",
                                    params: {
                                        catalogueId:
                                            data.additionalData.examCatalogueId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_questions_action_show_all_questions"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues.questions",
                                params: {
                                    catalogueId:
                                        data.additionalData.examCatalogueId,
                                },
                            },
                        ];
                    }

                    refreshQuestions();
                    break;

                case processType.examQuestionEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_questions_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_questions_action_show_all_questions"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues.questions",
                                    params: {
                                        catalogueId:
                                            data.additionalData.examCatalogueId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_questions_action_show_all_questions"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues.questions",
                                params: {
                                    catalogueId:
                                        data.additionalData.examCatalogueId,
                                },
                            },
                        ];
                    }

                    refreshQuestions();
                    break;

                case processType.examQuestionDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_questions_operation_delete_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_questions_action_show_all_questions"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "catalogues.questions",
                                    params: {
                                        catalogueId:
                                            data.additionalData.examCatalogueId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_questions_action_show_all_questions"
                                ),
                                iconClass: "fa fa-list",
                                route: "catalogues.questions",
                                params: {
                                    catalogueId:
                                        data.additionalData.examCatalogueId,
                                },
                            },
                        ];
                    }

                    refreshQuestions();
                    break;

                case processType.examQuestionUp:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_questions_operation_move_up_success_message",
                            data.additionalData.questionName
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshQuestions();
                    break;

                case processType.examQuestionDown:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_questions_operation_move_down_success_message",
                            data.additionalData.questionName
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshQuestions();
                    break;
                //#endregion

                //#region Scholarships
                case processType.examScholarshipAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_scholarships_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_scholarships_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_scholarships_action_show_all_scholarships"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "scholarships",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_scholarships_action_show_all_scholarships"
                                ),
                                iconClass: "fa fa-list",
                                route: "scholarships",
                            },
                        ];
                    }

                    refreshScholarships();
                    break;

                case processType.examScholarshipEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_scholarships_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_scholarships_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_scholarships_action_show_all_scholarships"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "scholarships",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_scholarships_action_show_all_scholarships"
                                ),
                                iconClass: "fa fa-list",
                                route: "scholarships",
                            },
                        ];
                    }

                    refreshScholarships();
                    break;

                case processType.examScholarshipDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_scholarships_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_scholarships_operation_delete_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_scholarships_action_show_all_scholarships"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "scholarships",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_scholarships_action_show_all_scholarships"
                                ),
                                iconClass: "fa fa-list",
                                route: "scholarships",
                            },
                        ];
                    }

                    refreshScholarships();
                    break;
                //#endregion

                //#region Bulletin
                case processType.examBulletinAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletin_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletin_operation_add_success_message",
                            data.additionalData.bulletinCode
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletin_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletins",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletin_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletins",
                            },
                        ];
                    }

                    refreshBulletins();
                    break;

                case processType.examBulletinEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletin_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletin_operation_edit_success_message",
                            data.additionalData.bulletinCode
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletin_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletins",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletin_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletins",
                            },
                        ];
                    }

                    refreshBulletins();
                    break;

                case processType.examBulletinDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletin_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletin_operation_delete_success_message",
                            data.additionalData.bulletinCode
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletin_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletins",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletin_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletins",
                            },
                        ];
                    }

                    refreshBulletins();
                    break;
                //#endregion

                //#region BulletinSubscription
                case processType.examBulletinSubscriptionAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_add_success_message",
                            data.additionalData.bulletinSubscriberName
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletinSubscriptions_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletinSubscriptions",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletinSubscriptions_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletinSubscriptions",
                            },
                        ];
                    }

                    refreshBulletinSubscriptions();
                    break;

                case processType.examBulletinSubscriptionEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_edit_success_message",
                            data.additionalData.bulletinSubscriberName
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletinSubscriptions_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletinSubscriptions",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletinSubscriptions_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletinSubscriptions",
                            },
                        ];
                    }

                    refreshBulletinSubscriptions();
                    break;

                case processType.examBulletinSubscriptionDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_bulletinSubscriptions_operation_delete_success_message",
                            data.additionalData.bulletinSubscriberName
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_bulletinSubscriptions_action_show_all"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "bulletinSubscriptions",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_bulletinSubscriptions_action_show_all"
                                ),
                                iconClass: "fa fa-list",
                                route: "bulletinSubscriptions",
                            },
                        ];
                    }

                    refreshBulletinSubscriptions();
                    break;
                //#endregion

                //#region Themes
                case processType.examThemeAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_themes_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_themes_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_themes_action_show_all_themes"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "themes",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_themes_action_show_all_themes"
                                ),
                                iconClass: "fa fa-list",
                                route: "themes",
                            },
                        ];
                    }

                    refreshThemes();
                    break;

                case processType.examThemeEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_themes_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_themes_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_themes_action_show_all_themes"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "themes",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_themes_action_show_all_themes"
                                ),
                                iconClass: "fa fa-list",
                                route: "themes",
                            },
                        ];
                    }

                    refreshThemes();
                    break;
                //#endregion

                //#region Arrangements
                case processType.examCandidateQuestionAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_candidate_question_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_candidate_question_operation_add_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshExamQuestions();
                    break;

                case processType.examCandidateQuestionEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_candidate_question_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_candidate_question_operation_edit_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshExamQuestions();
                    break;

                case processType.examCandidateQuestionDelete:
                    result = {
                        title: i18nFilter(
                            "translations.exam_candidate_question_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_candidate_question_operation_delete_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshExamQuestions();
                    break;
                //#endregion

                //#region Arrangements
                case processType.examArrangementAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_arrangements_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_arrangements_operation_add_success_message"
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_schedules_action_show_all_schedulePacks"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "examSchedulePacks",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                            },
                        ];
                    }

                    refreshExamSchedules(data.additionalData.examId);
                    break;

                case processType.examArrangementEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_arrangements_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_arrangements_operation_edit_success_message"
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.exam_schedules_action_show_all_schedulePacks"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "examSchedulePacks",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.exam_schedules_action_show_all_schedulePacks"
                                ),
                                iconClass: "fa fa-list",
                                route: "examSchedulePacks",
                            },
                        ];
                    }

                    refreshExamArrangements();

                    break;
                //#endregion

                //#region Applications
                case processType.examApplicationUpdate:
                    result = {
                        title: i18nFilter(
                            "translations.examSchedulePack_application_exam_queue_update_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.examSchedulePack_application_exam_queue_update_success_message"
                        );
                        setExamApplicationsState()
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                   
                    break;
                case processType.examApplicationReviewUpdate:
                    result = {
                        title: i18nFilter(
                            "translations.examSchedulePack_application_exam_queue_update_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.examSchedulePack_application_exam_queue_update_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshApplications();
                    break;

                case processType.examApplicationLearnerTogglePaymentConfirmation:
                    result = {
                        title: i18nFilter(
                            "translations.exam_questions_and_payments_operation_toggle_payment_confirmation_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_questions_and_payments_operation_toggle_payment_confirmation_success_message",
                            data.additionalData.name
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshQuestionsAndPayments(
                        data.additionalData.examScheduleId
                    );
                    break;
                //#endregion

                //#region Results
                case processType.examResultAdd:
                    result = {
                        title: i18nFilter(
                            "translations.exam_results_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_results_operation_add_success_message"
                        );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }

                    refreshApplications();
                    break;

                case processType.examResultEdit:
                    result = {
                        title: i18nFilter(
                            "translations.exam_results_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.exam_results_operation_edit_success_message"
                        );

                        // Post request to examschedulecontroler to edit the exam and upload file
                        if (data.additionalData.fileId != "")
                            uploadAttachmentPostRequest(
                                data.additionalData.examResultId,
                                data.additionalData.fileId
                            );
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );
                    }
                    break;
                //#endregion

                //#region TargetGroups
                case processType.targetGroupAdd:
                    result = {
                        title: i18nFilter(
                            "translations.targetGroups_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetGroups_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetGroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetGroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups",
                            },
                        ];
                    }

                    refreshTargetGroups();
                    break;

                case processType.targetGroupEdit:
                    result = {
                        title: i18nFilter(
                            "translations.targetGroups_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetGroups_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetGroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetGroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups",
                            },
                        ];
                    }

                    refreshTargetGroups();
                    break;

                case processType.targetGroupDelete:
                    result = {
                        title: i18nFilter(
                            "translations.targetGroups_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetGroups_operation_delete_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetGroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups",
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetGroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups",
                            },
                        ];
                    }

                    refreshTargetGroups();
                    break;
                //#endregion

                //#region TargetSubgroups
                case processType.targetSubgroupAdd:
                    result = {
                        title: i18nFilter(
                            "translations.targetSubgroups_operation_add_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetSubgroups_operation_add_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetSubgroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups.targetSubgroups",
                                    params: {
                                        parentId: data.additionalData.parentId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetSubgroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups.targetSubgroups",
                                params: {
                                    parentId: data.additionalData.parentId,
                                },
                            },
                        ];
                    }

                    refreshTargetSubgroups();
                    break;

                case processType.targetSubgroupEdit:
                    result = {
                        title: i18nFilter(
                            "translations.targetSubgroups_operation_edit_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetSubgroups_operation_edit_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetSubgroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups.targetSubgroups",
                                    params: {
                                        parentId: data.additionalData.parentId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetSubgroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups.targetSubgroups",
                                params: {
                                    parentId: data.additionalData.parentId,
                                },
                            },
                        ];
                    }

                    refreshTargetSubgroups();
                    break;

                case processType.targetSubgroupDelete:
                    result = {
                        title: i18nFilter(
                            "translations.targetSubgroups_operation_delete_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.targetSubgroups_operation_delete_success_message",
                            data.additionalData.name
                        )),
                            (result.actions = [
                                {
                                    labelText: i18nFilter(
                                        "translations.targetGroups_action_show_all_targetSubgroups"
                                    ),
                                    iconClass: "fa fa-list",
                                    route: "targetGroups.targetSubgroups",
                                    params: {
                                        parentId: data.additionalData.parentId,
                                    },
                                },
                            ]);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [
                            {
                                labelText: i18nFilter(
                                    "translations.targetGroups_action_show_all_targetSubgroups"
                                ),
                                iconClass: "fa fa-list",
                                route: "targetGroups.targetSubgroups",
                                params: {
                                    parentId: data.additionalData.parentId,
                                },
                            },
                        ];
                    }

                    refreshTargetSubgroups();
                    break;

                case processType.programConfirmSchedulePresence:
                    result = {
                        title: i18nFilter('translations.program_learners_action_confirm_acknowledge_education_title'),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode, workerStatus: data.workerStatus
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter('translations.program_learners_action_confirm_acknowledge_education');

                        result.actions = [];
                    }
                    else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(data.exceptionCode, data.additionalData);

                        result.actions = [];
                    }

                    //refresh the page here (if needed)
                    break;

                case processType.confirmEducationApplication:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_application_action_confirm_application_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_application_action_confirm_application",
                            data.additionalData.numberOfSuccessfullChanges +
                                "/" +
                                data.additionalData.numberOfRequestedChanges
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshScheduleApprovals();
                    break;

                case processType.confirmOnlineEducationApplication:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_application_action_confirm_application_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_application_action_confirm_application",
                            data.additionalData.numberOfSuccessfullChanges +
                            "/" +
                            data.additionalData.numberOfRequestedChanges
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshOnlineEducationApprovals();
                    break;

                case processType.denyEducationApplication:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_application_action_deny_application_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_application_action_deny_application",
                            data.additionalData.numberOfSuccessfullChanges +
                                "/" +
                                data.additionalData.numberOfRequestedChanges
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshScheduleApprovals();
                    break;

                case processType.denyOnlineEducationApplication:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_application_action_deny_application_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_application_action_deny_application",
                            data.additionalData.numberOfSuccessfullChanges +
                            "/" +
                            data.additionalData.numberOfRequestedChanges
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshOnlineEducationApprovals();
                    break;

                case processType.cancelEducationApplication:
                    result = {
                        title: i18nFilter('translations.schedule_application_action_cancel_application_title'),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode, workerStatus: data.workerStatus
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter('translations.schedule_application_action_cancel_application', data.additionalData.numberOfSuccessfullChanges + '/' + data.additionalData.numberOfRequestedChanges);

                        result.actions = [];
                    }
                    else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(data.exceptionCode, data.additionalData);

                        result.actions = [];
                    }

                    refreshScheduleApprovals();
                    break;
                case processType.schoolingApplicationUpdate:
                    result = {
                        title: "",
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        switch (data.additionalData.applicationStatus) {
                            case "Approved":
                                result.message = i18nFilter(
                                    "translations.schooling_schedules_application_confirm_success_message"
                                );
                                break;
                            case "Rejected":
                                result.message = i18nFilter(
                                    "translations.schooling_schedules_application_deny_success_message"
                                );
                                break;
                            case "Waiting":
                                result.message = i18nFilter(
                                    "translations.schooling_schedules_application_addToWaiting_success_message"
                                );
                                break;
                            case "Disenrolled":
                                result.message = i18nFilter(
                                    "translations.schooling_schedules_application_disenroll_success_message"
                                );
                                break;
                            case "PendingPersonalData":
                                result.message = i18nFilter(
                                    "translations.schooling_schedules_application_return_success_message"
                                );
                                break;
                        }
                        refreshSchoolingApplications(data.additionalData.returnToPageIndex);

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;

                case processType.schoolingApplicationDeleteCertificate:
                    result = {
                        title: "",
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schooling_schedules_application_delete_certificate_document_confirm_success_message"
                        );

                        refreshSchoolingApplications();

                        result.actions = [];
                    }
                    else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;

                case processType.schoolingApplicationsUpdate:
                        result = {
                            title: "",
                            date: data.date,
                            requestOueueId: data.requestOueueId,
                            operationCode: data.operationCode,
                            processType: data.processType,
                            exceptionCode: data.exceptionCode,
                            workerStatus: data.workerStatus,
                    };
                        if (data.workerStatus === workerStatus.success) {
                            switch (data.additionalData.applicationStatus) {
                                case examApplicationStatus.approved:
                                    if (data.numberOfSuccessfullChanges == 0)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_faill_message"
                                        );
                                    }
                                    else if (data.numberOfSuccessfullChanges == 1)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schooling_schedules_application_confirm_success_message"
                                        );
                                    }
                                    else
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_confirm_success_message"
                                        );
                                    }
                                    break;
                                case examApplicationStatus.rejected:
                                    if (data.numberOfSuccessfullChanges == 0)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_faill_message"
                                        );
                                    }
                                    else if (data.numberOfSuccessfullChanges == 1)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schooling_schedules_application_deny_success_message"
                                        );
                                    }
                                    else
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_deny_success_message"
                                        );
                                    }
                                    break;
                                case examApplicationStatus.waiting:
                                    if (data.numberOfSuccessfullChanges == 0)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_faill_message"
                                        );
                                    }
                                    else if (data.numberOfSuccessfullChanges == 1)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schooling_schedules_application_addToWaiting_success_message"
                                        );
                                    }
                                    else
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_addToWaiting_success_message"
                                        );
                                    }
                                    break;
                                case examApplicationStatus.pendingPersonalData:
                                    if (data.numberOfSuccessfullChanges == 0)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_faill_message"
                                        );
                                    }
                                    else if (data.numberOfSuccessfullChanges == 1)
                                    {
                                        result.message = i18nFilter(
                                            "translations.schooling_schedules_application_return_success_message"
                                        );
                                    }
                                    else
                                    {
                                        result.message = i18nFilter(
                                            "translations.schoolingSchedulePack_schooling_queue_return_success_message"
                                        );
                                    }
                                    break;
                            }
                            refreshSchoolingApplications(data.additionalData.returnToPageIndex);
    
                            result.actions = [];
                        } else if (data.workerStatus === workerStatus.fail) {
                            result.message = getMessage(
                                data.exceptionCode,
                                data.additionalData
                            );
    
                            result.actions = [];
                        }
                    break;
                    
                case processType.schoolingApplicationTransfer:
                    result = {
                        title: "",
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedulePack_application_action_application",
                            data.additionalData.numberOfSuccessfullChanges +
                                "/" +
                                data.additionalData.numberOfRequestedChanges
                        );
                        refreshSchoolingApplications()
                        result.actions = [];
                    }

                    break;

                case processType.denySchoolingApplication:
                    result = {
                        title: i18nFilter(
                            "translations.schedule_application_action_deny_application_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schedule_application_action_deny_application",
                            data.additionalData.numberOfSuccessfullChanges +
                                "/" +
                                data.additionalData.numberOfRequestedChanges
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchedulePresence();
                    break;
                case processType.updateCommissionPaymentOrder:
                    result = {
                        title: i18nFilter(
                            "translations.exam_paymentOrder_operation_save_title"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                    };

                    if (data.workerStatus === workerStatus.success) {
                        (result.message = i18nFilter(
                            "translations.exam_paymentOrder_operation_save_success_message"
                        )),
                            (result.actions = []);
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;
                case processType.LockSurveyEducation:
                    result = {
                        title: i18nFilter('translations.schedule_surveys_notification_locked_survey_title'),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter('translations.schedule_surveys_notification_locked_survey', data.additionalData.numberOfSuccessfullChanges + '/' + data.additionalData.numberOfRequestedChanges);

                        if (data.additionalData.surveyType){
                            if (data.additionalData.surveyType == 1){
                                schedulePacksDataService.participantLockedSurvey = true;
                            }
                            else if (data.additionalData.surveyType == 3){
                                schedulePacksDataService.contractorLockedSurvey = true;
                            }
                            else if (data.additionalData.surveyType == 2){
                                schedulePacksDataService.coordinatorLockedSurvey = true;
                            }
                        }
                        result.actions = [];
                    }
                    else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(data.exceptionCode, data.additionalData);

                        result.actions = [];
                    }

                    break;
                case processType.SendSurveyEducation:
                    result = {
                        title: i18nFilter('translations.schedule_surveys_notification_sent_survey_title'),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter('translations.schedule_surveys_notification_sent_survey', data.additionalData.numberOfSuccessfullChanges + '/' + data.additionalData.numberOfRequestedChanges);

                        if (data.additionalData.surveyType){
                            if (data.additionalData.surveyType == 1){
                                schedulePacksDataService.participantLockedSurvey = false;
                            }
                            else if (data.additionalData.surveyType == 3){
                                schedulePacksDataService.contractorLockedSurvey = false;
                            }
                            else if (data.additionalData.surveyType == 2){
                                schedulePacksDataService.coordinatorLockedSurvey = false;
                            }
                        }
                        result.actions = [];
                    }
                    else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(data.exceptionCode, data.additionalData);

                        result.actions = [];
                    }

                    break;
                case processType.schoolingScheduleProgramEdit:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_program_notificationTitle"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_program_saved"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    $state.reload();
                    break;
                case processType.schoolingScheduleBillingDirectCostEdit:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_billing_direct_costs_success_message"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_billing_direct_costs_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;
                case processType.schoolingScheduleBillingIndirectCostEdit:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_billing_indirect_costs_success_message"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_billing_indirect_costs_success_message"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;
                //#endregion
                case processType.schoolingScheduleContractAdd:
                    result = {
                        title: i18nFilter(
                            "translations.contract_schooling_addedAndLinked"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_schooling_addedAndLinked"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshSchoolingContracts();
                    break;
                case processType.schoolingScheduleContractLink:
                    result = {
                        title: i18nFilter(
                            "translations.contract_schooling_linked"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_schooling_linked"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshSchoolingContracts();
                    break;
                case processType.schoolingScheduleContractDelete:
                    result = {
                        title: i18nFilter(
                            "translations.contract_schooling_unlinked"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.contract_schooling_unlinked"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshSchoolingContracts();
                    break;
                case processType.finishSchooling:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_applications_finished"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_applications_finished"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];

                    }
                    refreshApplications()
                    break;
                case processType.schoolingScheduleIssueUserCertificate:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_billing_issue_certificates_notify_finished"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_billing_issue_certificates_notify_finished"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchoolingCertificates();

                    break;
                case processType.schoolingScheduleIssueCertificates:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_billing_issue_certificates_all_notify_finished"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_billing_issue_certificates_all_notify_finished"
                        );

                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }

                    refreshSchoolingCertificates();

                    break;
                case processType.schoolingApplicationAddApproved:
                    result = {
                        title: i18nFilter(
                            "translations.schoolingSchedule_gui_applications_direct_application_finished"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.schoolingSchedule_gui_applications_direct_application_finished"
                        );
                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshSchoolingApplications();
                    break;
                case processType.urgentMessagesConfigurationUpdate:
                    result = {
                        title: i18nFilter(
                            "translations.urgentMessages_configuration_queue_edit_update_finished"
                        ),
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.urgentMessages_configuration_queue_edit_update_finished"
                        );
                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    refreshUrgentMessagesConfiguration();
                    break;

                case processType.certificateSign:
                    result = {
                        date: data.date,
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.manage_sign_popup_redirecting"
                        );
                        setTimeout(function () { window.location.href = data.additionalData.redirectUrl }, 3000);
                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;

                case processType.updateSignDocumentData:
                    result = {
                        requestOueueId: data.requestOueueId,
                        operationCode: data.operationCode,
                        processType: data.processType,
                        exceptionCode: data.exceptionCode,
                        workerStatus: data.workerStatus,
                    };
                    if (data.workerStatus === workerStatus.success) {
                        result.message = i18nFilter(
                            "translations.manage_sign_popup_signing_success"
                        );
                        $state.reload();
                        result.actions = [];
                    } else if (data.workerStatus === workerStatus.fail) {
                        result.message = getMessage(
                            data.exceptionCode,
                            data.additionalData
                        );

                        result.actions = [];
                    }
                    break;
                /*{{PLACEHOLDER}}*/
            }

            return result;
        }
        //#endregion

        function refreshExamArrangements() {
            if (
                $state.current.name == "examSchedulePacks.arrangementsAdd" ||
                $state.current.name == "examSchedulePacks.arrangementsEdit" ||
                $state.current.name == "examSchedulePacks.arrangementsDocuments"
            ) {
                $state.reload();
            }
        }

        function refreshTilesConfiguration() {
            if ($state.current.name == "tilesConfiguration") {
                $state.reload();
            }
        }

        function refreshUrgentMessagesConfiguration() {
            if ($state.current.name == "urgentMessagesConfiguration") {
                $state.reload();
            }
        }

        function refreshCompetences() {
            if ($state.current.name == "competences") {
                $state.reload();
            }
        }

        function refreshSchedulePresence() {
            if ($state.current.name == "schedulePacks.details") {
                $state.go("schedulePacks.details", $stateParams, {
                    reload: true,
                });
            }
        }

        function refreshScheduleApprovals() {
            if ($state.current.name == "schedulePacks.approvals") {
                $state.go("schedulePacks.approvals", $stateParams, {
                    reload: true,
                });
            }
        }

        function refreshOnlineEducationApprovals() {
            if ($state.current.name == "eLearningEducationEnrollments") {
                $state.reload();
                /*$state.go("eLearningEducationEnrollments", $stateParams, {
                    reload: true,
                });*/
            }
        }

        function refreshScheduleRecords() {
            if ($state.current.name == "schedulePacks.records") {
                $state.go("schedulePacks.records", $stateParams, {
                    reload: true,
                });
            }
        }

        function refreshCategories() {
            if ($state.current.name == "categories") {
                $state.reload();
            }
        }

        function refreshActivities() {
            if (
                $state.current.name ==
                "educations.administration.activities.deactivate"
            ) {
                $state.go("^", { id: $stateParams.id }, { reload: true });
            }
        }

        function refreshSchedules(entityId) {
            if ($state.current.name == "schedulePacks") {
                $state.go(
                    "schedulePacks",
                    { entityId: entityId },
                    { reload: true }
                );
            }
        }

        function refreshLearningPeriods() {
            if ($state.current.name == "learningPeriods") {
                $state.reload();
            }
        }

        function refreshTenants() {
            if ($state.current.name == "tenants") {
                $state.reload();
            }
        }

        function refreshOrganizations() {
            if ($state.current.name == "organizations") {
                $state.reload();
            }
        }

        function refreshLocations() {
            if ($state.current.name == "locations") {
                $state.reload();
            }
        }

        function refreshCertificates() {
            if ($state.current.name == "certificates") {
                $state.reload();
            }
        }

        function refreshEducations(educationId) {
            if (
                $state.current.name.startsWith("educations") &&
                $stateParams.id === educationId
            ) {
                $state.reload();
            }
        }

        function refreshSchoolings(schoolingId) {
            if (
                $state.current.name.startsWith("schoolings") &&
                $stateParams.id === schoolingId
            ) {
                $state.reload();
            }
        }

        function refreshSurveys(surveyId) {
            if (
                $state.current.name.startsWith("surveys") &&
                $stateParams.id == surveyId
            ) {
                $state.reload();
            }
        }

        function refreshSurveyGroups() {
            if ($state.current.name.startsWith("surveys.questionGroups")) {
                $state.reload();
            }
        }

        function refreshSurveyQuestions() {
            if ($state.current.name.startsWith("surveys.questions")) {
                $state.reload();
            }
        }

        function refreshPrograms(programId) {
            if (
                $state.current.name.startsWith("programs") &&
                $stateParams.programId == programId
            ) {
                $state.reload();
            }
        }

        function refreshProgramsCatalog() {
            if ($state.current.name == "classicCatalog.program") {
                $state.reload();
            }
        }

        function refreshSurveysCatalog() {
            if ($state.current.name === "classicCatalog.survey") {
                $state.reload();
            }
        }

        function refreshTemplates() {
            if ($state.current.name == "templates") {
                $state.reload();
            }
        }

        function refreshAssignments() {
            if (
                $state.current.name == "educations.administration.assignments"
            ) {
                $state.reload();
            }
        }

        function refreshSchoolingAssignments() {
            if (
                $state.current.name == "schoolings.administration.assignments"
            ) {
                $state.reload();
            }
        }

        function refreshCities() {
            if ($state.current.name == "cities") {
                $state.reload();
            }
        }

        function refreshCountries() {
            if ($state.current.name == "countries") {
                $state.reload();
            }
        }

        function refreshPromotedEntities() {
            if ($state.current.name == "promotedEntities") {
                $state.reload();
            }
        }

        function refreshUsers() {
            if ($state.current.name == "users") {
                $state.reload();
            }
        }

        function refreshProfile() {
            if ($state.current.name == "profile.edit") {
                $state.reload();
            }
        }

        function refreshNotificationConfiguration() {
            if (
                $state.current.name == "notifications.notificationConfiguration"
            ) {
                $state.reload();
            }
        }

        function refreshNotificationTemplateConfiguration() {
            if ($state.current.name == "notifications.templatesConfiguration") {
                $state.reload();
            }
        }

        function refreshBudgets() {
            if ($state.current.name == "budgets") {
                $state.reload();
            }
        }

        function refreshContracts() {
            if ($state.current.name == "contracts") {
                $state.reload();
            }
        }

        function refreshExams(examId) {
            if (
                $state.current.name.startsWith("exams") &&
                $stateParams.id == examId
            ) {
                $state.reload();
            }
        }

        function refreshExamSchedules(examId) {
            if (
                $state.current.name.startsWith("examSchedulePacks") &&
                $stateParams.entityId == examId
            ) {
                $state.reload();
            }
        }

        function refreshCatalogues() {
            if ($state.current.name == "catalogues") {
                $state.reload();
            }
        }

        function refreshQuestions() {
            if ($state.current.name == "catalogues.questions") {
                $state.reload();
            }
        }

        function refreshScholarships() {
            if ($state.current.name == "scholarships") {
                $state.reload();
            }
        }

        function refreshBulletinSubscriptions() {
            if ($state.current.name == "bulletinSubscriptions") {
                $state.reload();
            }
        }

        function refreshBulletins() {
            if ($state.current.name == "bulletins") {
                $state.reload();
            }
        }

        function refreshThemes() {
            if ($state.current.name == "themes") {
                $state.reload();
            }
        }

        function refreshApplications() {
            if ($state.current.name == "examSchedulePacks.applications" || $state.current.name == "examSchedulePacks.application" || $state.current.name=="schoolingSchedulePacks.applications" 
            || $state.current.name=="schoolingSchedulePacks.application") {
                $state.reload();
            }
        }

        function setExamApplicationsState(){
            examApplicationsDataService.newApplicationData = true
        }

        function refreshQuestionsAndPayments(examScheduleId) {
            if (
                $state.current.name.startsWith(
                    "examSchedulePacks.questionsAndPayments"
                ) &&
                $stateParams.schedulePackId == examScheduleId
            ) {
                $state.reload();
            }
        }

        function refreshTargetGroups() {
            if ($state.current.name == "targetGroups") {
                $state.reload();
            }
        }

        function refreshTargetSubgroups() {
            if ($state.current.name == "targetGroups.targetSubgroups") {
                $state.reload();
            }
        }

        function refreshSchoolingContracts() {
            if ($state.current.name == "schoolingSchedulePacks.contract") {
                $state.reload();
            }
        }

        function refreshSchoolingCertificates() {
            if ($state.current.name == "schoolingSchedulePacks.billing") {
                $state.reload();
            }
        }

        function refreshSchoolingApplications(returnToPageIndex = null) {
            $stateParams.currentPageIndex = returnToPageIndex;

            if (
                $state.current.name ==
                    "schoolingSchedulePacks.addApplication" ||
                $state.current.name == "schoolingSchedulePacks.applications"
            ) {
                $state
                    .go("schoolingSchedulePacks.applications", $stateParams, {
                        reload: true,
                    })
                    .then(function () {
                        setTimeout(function () {
                            $("html").animate(
                                {
                                    scrollTop:
                                        $("#applicationBoxes").offset().top,
                                },
                                500
                            );
                        }, 500);
                    });
            }
            else if ($state.current.name == "schoolingSchedulePacks.application") {
                $state.go("schoolingSchedulePacks.application", $stateParams, {
                    reload: true,
                })
            }
        }
        
        function downloadContract(contractId) {
            if(contractId){
                var date = new Date().getDate().toString();
                documentsDataService.downloadDocument(
                    "api/contracts/contractDownload/" + contractId,
                    "Pogodba_" + date + ".docx"
                );
            }
        }
    }
})();
