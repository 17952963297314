(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('catalogues', catalogues);

    catalogues.$inject = ['catalogues', '$state', '$sessionStorage', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'cataloguesDataService', 'rolesDataService'];

    function catalogues(catalogues, $state, $sessionStorage, i18nFilter, dateFilter, exceptionHelper, logger, cataloguesDataService, rolesDataService) {
        /* jshint validthis:true */
        var vm = this;
        vm.showAddButton = rolesDataService.isUserInRoles($state.get('catalogues.add').data.roles);
        
        vm.catalogues = catalogues;
        vm.catalogues.forEach(function (catalogue) {
            var tmp = "", allowActions=false;
            catalogue.coordinators.forEach(function (coordinator) {
                if (tmp != "") tmp += ", ";
                tmp += coordinator.displayName + " (" + coordinator.email + ")";
                if (!allowActions && coordinator.id == $sessionStorage.userInfo.userId) allowActions = true;
            });
            catalogue.coordinators = tmp;
            catalogue.allowActions = allowActions;
        });

        vm.copy = copy;

        function copy(catalogue) {
            cataloguesDataService.copyCatalogues(angular.copy(catalogue)).then(function (data) {
                logger.success(i18nFilter('translations.exam_catalogues_queue_add'));
                $state.reload();
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        };

        vm.nameOfRequiredRole = i18nFilter('translations.filter_role_exam_owner');

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            questionsTooltip: i18nFilter('translations.exam_catalogues_action_see_questions'),
            copyTooltip: i18nFilter('translations.general_action_copy'),
        };

        vm.examTypes = [
            {
                id: 1,
                format: i18nFilter('translations.exams_edit_gui_label_exam_type_automatic')
            },
            {
                id: 2,
                format: i18nFilter('translations.exams_edit_gui_label_exam_type_manual')
            }
        ];

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'coordinators',
                caption: i18nFilter('translations.exam_catalogues_gui_label_coordinators'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'type',
                caption: i18nFilter('translations.exams_edit_gui_label_exam_type'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 150,
                lookup: {
                    dataSource: vm.examTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'cataloguesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.catalogues,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
