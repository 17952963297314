(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('rolesDataService', roles);

    roles.$inject = ['$http', '$q', '$sessionStorage', 'logger', '$state', 'exceptions', 'applicationRoles', 'i18nFilter'];

    function roles($http, $q, $sessionStorage, logger, $state, exceptions, applicationRoles, i18nFilter) {
        var service = {
            isUserInRoles: isUserInRoles,
            userIsNotInRoles: userIsNotInRoles,            
            getPotentialUserRoles: getPotentialUserRoles,
            getAllApplicationRoles: getAllApplicationRoles,
            getAllApplicationStaticRolesId: getAllApplicationStaticRolesId
        };

        return service;
        


        function isUserInRoles(requiredRoles) {
            if ($sessionStorage.userInfo && $sessionStorage.userInfo.roles) {
                
                var userRoles = $sessionStorage.userInfo.roles;
                var isOk = false;
                angular.forEach(userRoles, function (valueRole, keyRole) {
                    angular.forEach(requiredRoles, function (valueRoleRequired, keyRequired) {
                        if (valueRole.name === valueRoleRequired) {
                            isOk = true;
                        }
                    });
                });
            }
            return isOk;
        }


        function userIsNotInRoles(requiredRoles) {
            if ($sessionStorage.userInfo && $sessionStorage.userInfo.roles) {
                var userRoles = $sessionStorage.userInfo.roles;

                var notInRoles = true;
                angular.forEach(userRoles, function (valueRole, keyRole) {
                    angular.forEach(requiredRoles, function (valueRoleRequired, keyRequired) {
                        if (valueRole.name === valueRoleRequired) {
                            notInRoles = false;
                        }
                    });
                });
            }
            return notInRoles;
        }

        

        function getPotentialUserRoles(excludeDynamicRoles, excludeGlobalAdminRole) {
            var allRoles = getAllApplicationRoles();

            if (excludeDynamicRoles) {
                allRoles = _.filter(allRoles, function (role) {
                    return role.id !== applicationRoles.kbAdmin && role.id !== applicationRoles.learner && role.id !== applicationRoles.educationOwner && role.id !== applicationRoles.programOwner && role.id !== applicationRoles.instructor && role.id !== applicationRoles.manager && role.id !== applicationRoles.surveyOwner && role.id !== applicationRoles.examOwner;
                });
            }

            if (excludeGlobalAdminRole) {
                allRoles = _.filter(allRoles, function (role) {
                    return role.id !== applicationRoles.globalAdmin;
                });
            }

            return allRoles;
        }

        function getAllApplicationRoles() {
            var result = [
                {
                    id: applicationRoles.globalAdmin,
                    name: i18nFilter('translations.filter_role_global_admin')
                },
                {
                    id: applicationRoles.tenantAdmin,
                    name: i18nFilter('translations.filter_role_tenant_admin')
                },
                {
                    id: applicationRoles.educationAdmin,
                    name: i18nFilter('translations.filter_role_education_admin')
                },
                {
                    id: applicationRoles.catalogAdmin,
                    name: i18nFilter('translations.filter_role_catalog_admin')
                },                
                {
                    id: applicationRoles.kbAdmin,
                    name: i18nFilter('translations.filter_role_knowledge_base_admin')
                },
                {
                    id: applicationRoles.educationOwner,
                    name: i18nFilter('translations.filter_role_education_owner')
                },
                {
                    id: applicationRoles.instructor,
                    name: i18nFilter('translations.filter_role_instructor')
                },
                {
                    id: applicationRoles.learner,
                    name: i18nFilter('translations.filter_role_learner')
                },
                {
                    id: applicationRoles.programOwner,
                    name: i18nFilter('translations.filter_role_program_owner')
                },
                {
                    id: applicationRoles.manager,
                    name: i18nFilter('translations.filter_role_manager')
                },
                {
                    id: applicationRoles.surveyAdmin,
                    name: i18nFilter('translations.filter_role_survey_admin')
                },
                {
                    id: applicationRoles.surveyOwner,
                    name: i18nFilter('translations.filter_role_survey_owner')
                },
                {
                    id: applicationRoles.examAdmin,
                    name: i18nFilter('translations.filter_role_exam_admin')
                },
                {
                    id: applicationRoles.examOwner,
                    name: i18nFilter('translations.filter_role_exam_owner')
                },
                {
                    id: applicationRoles.schoolingAdmin,
                    name: i18nFilter('translations.filter_role_schooling_admin')
                },
                {
                    id: applicationRoles.schoolingOwner,
                    name: i18nFilter('translations.filter_role_schooling_owner')
                },
                {
                    id: applicationRoles.analyticsAdmin,
                    name: i18nFilter('translations.filter_role_analytics_admin')
                },
                {
                    id: applicationRoles.certificateSigner,
                    name: i18nFilter('translations.filter_role_certificate_signer')
                },
                {
                    id: applicationRoles.proficiencyExamAdmin,
                    name: i18nFilter('translations.filter_role_proficiency_exam_admin')
                },
                {
                    id: applicationRoles.proficiencyExamOwner,
                    name: i18nFilter('translations.filter_role_proficiency_exam_owner')
                }
            ];

            return result;
        }    

        function getAllApplicationStaticRolesId() {
            var result = [
                applicationRoles.globalAdmin,
                applicationRoles.tenantAdmin,
                applicationRoles.educationAdmin,
                applicationRoles.catalogAdmin,
                applicationRoles.kbAdmin,
                applicationRoles.learner,
                applicationRoles.surveyAdmin,
                applicationRoles.examAdmin,
                applicationRoles.schoolingAdmin,
                applicationRoles.analyticsAdmin,
                applicationRoles.certificateSigner,
                applicationRoles.proficiencyExamAdmin,
                applicationRoles.proficiencyExamOwner
            ];

            return result;
        }
    }
})();
