(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('Users', users);

    users.$inject = ['logger', 'usersDataService', '$rootScope', 'editUserHelper', 'exceptionHelper', 'i18nFilter'];

    function users(logger, usersDataService, $rootScope, editUserHelper, exceptionHelper, i18nFilter) {
        var vm = this;

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit'),
            detailsTooltip: i18nFilter('translations.general_gui_details')
        };
        vm.dtOptions = {};
        vm.users = [];
        vm.userDetails = {
            subjects: [],
            type: undefined
        };

        vm.statuses = [            
            {
                id: 1,
                format: i18nFilter('translations.general_status_active')
            },
            {
                id: 0,
                format: i18nFilter('translations.general_status_deactiveted')
            },
            {
                id: 2,
                format: i18nFilter('translations.general_status_processing')
            }
        ];
                

        //#region Method binding to vm        
        vm.getDetails = getDetails;
        vm.openUsers = openUsers;
        vm.openGroups = openGroups;
        vm.cancel = cancel;
        vm.undefineRemoteUser = undefineRemoteUser;
        //#endregion


        vm.options = {
            firstName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            lastName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            displayName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            email: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            status: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: 10,
            skip: undefined
        };

        
        var columns = [
            {
                dataField: 'firstName',
                alignment: 'left',
                caption: i18nFilter('translations.general_gui_first_name'),
                allowSorting: true
            },
            {
                dataField: 'lastName',
                alignment: 'left',
                caption: i18nFilter('translations.general_gui_last_name'),
                allowSorting: true
            },
            {
                dataField: 'displayName',
                alignment: 'left',
                caption: i18nFilter('translations.general_gui_display_name'),
                allowSorting: true
            },
            {
                dataField: 'email',
                alignment: 'left',
                caption: i18nFilter('translations.general_gui_email'),
                allowSorting: true
            },
            {
                dataField: 'status',
                alignment: 'left',
                caption: i18nFilter('translations.general_gui_status'),
                allowSorting: true,
                lookup: {
                    dataSource: vm.statuses,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];

        function clearFilterAndSort() {
            vm.options.firstName.filterType1 = undefined;
            vm.options.firstName.searchText1 = undefined;
            vm.options.firstName.filterType2 = undefined;
            vm.options.firstName.searchText2 = undefined;
            vm.options.firstName.sortType = undefined;

            vm.options.lastName.filterType1 = undefined;
            vm.options.lastName.searchText1 = undefined;
            vm.options.lastName.filterType2 = undefined;
            vm.options.lastName.searchText2 = undefined;
            vm.options.lastName.sortType = undefined;

            vm.options.displayName.filterType1 = undefined;
            vm.options.displayName.searchText1 = undefined;
            vm.options.displayName.filterType2 = undefined;
            vm.options.displayName.searchText2 = undefined;
            vm.options.displayName.sortType = undefined;

            vm.options.email.filterType1 = undefined;
            vm.options.email.searchText1 = undefined;
            vm.options.email.filterType2 = undefined;
            vm.options.email.searchText2 = undefined;
            vm.options.email.sortType = undefined;

            vm.options.status.filterType1 = undefined;
            vm.options.status.searchText1 = undefined;
            vm.options.status.filterType2 = undefined;
            vm.options.status.searchText2 = undefined;
            vm.options.status.sortType = undefined;
        }

        function sortApplayer(sort) {
            if (sort[0].selector === "firstName") {
                vm.options.firstName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "lastName") {
                vm.options.lastName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "displayName") {
                vm.options.displayName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "email") {
                vm.options.email.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "status") {
                vm.options.status.sortType = sort[0].desc ? 'desc' : 'asc';
            }
        }

        function simpleFilterApplayer(element) {
            if (element[0] === "firstName") {
                vm.options.firstName.filterType1 = element[1];
                vm.options.firstName.searchText1 = element[2];
            }
            if (element[0] === "lastName") {
                vm.options.lastName.filterType1 = element[1];
                vm.options.lastName.searchText1 = element[2];
            }
            if (element[0] === "displayName") {
                vm.options.displayName.filterType1 = element[1];
                vm.options.displayName.searchText1 = element[2];
            }
            if (element[0] === "email") {
                vm.options.email.filterType1 = element[1];
                vm.options.email.searchText1 = element[2];
            }
            if (element[0] === "status") { //TODO: dodaj drugi filter ako ga ima
                vm.options.status.filterType1 = element[1];
                vm.options.status.searchText1 = element[2];
            }
        }

        function dateFilterApplayer(element) {
            if (element[0][0] === 'status' && element[2][0] === 'status') {
                vm.options.status.filterType1 = element[0][1];
                vm.options.status.searchText1 = element[0][2];
                vm.options.status.filterType2 = element[2][1];
                vm.options.status.searchText2 = element[2][2];
            }
        }

        var dataSource = {
            load: function (loadOptions) {
                logger.log("LoadOptions: ", loadOptions);
                var d = new $.Deferred();
                clearFilterAndSort();

                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterApplayer(loadOptions.filter);
                        }
                            //Filter za datume
                        else {
                            dateFilterApplayer(loadOptions.filter)
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterApplayer(filterElement);
                                }
                                    //Filter za datume
                                else {
                                    dateFilterApplayer(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    sortApplayer(loadOptions.sort);
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken

                logger.log("vm.options: ", vm.options);

                usersDataService.getAllUsersAdvanced(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.manage_users_exception_message_error_fetching_users'));
                    d.reject();
                });

                return d.promise();
            }
        };

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            columnMinWidth: 50,
            columnAutoWidth: true,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function reloadData() {
            //TODO: refresh grid
        }

       
        
        //#region Sidebar other actions
        function openUsers() {
            var link = 'https://manage.windowsazure.com#Workspaces/ActiveDirectoryExtension/Directory/' + $rootScope.userInfo.profile.tid + '/users';
            window.open(link, '_blank');
        }

        function openGroups() {
            var link = 'https://manage.windowsazure.com#Workspaces/ActiveDirectoryExtension/Directory/' + $rootScope.userInfo.profile.tid + '/groups';
            window.open(link, '_blank');
        }
        //#endregion

        //#region Details
        function typeChanged() {
            vm.userDetails.subjects = [];
        }

        

        function getDetails(id) {
            logger.log("GetDetails: ", id);
            vm.currentUser = undefined;
            usersDataService.getUserById(id).then(function (data) {
                vm.currentUser = data;                
            }, function (error) {
                logger.error(i18nFilter('translations.manage_users_exception_message_error_fetching_users_data'));
            });
        }

        function cancel() {
            vm.currentUser = undefined;
        }

        function undefineRemoteUser() {
            editUserHelper.clearRemoteUser();
        }
    }
})();
