(function () {
    'use strict';

    angular
        .module('app.educations.sidebar.categories')
        .component('educationCategories', {
            templateUrl: 'app/educations/sidebars/categories/categories.html',
            controller: categoriesCtrl,
            controllerAs: 'vm',
            bindings: {
                educationId: '='
            }
        });

    categoriesCtrl.$inject = ['logger', 'exceptionHelper', 'educationsDataService'];

    function categoriesCtrl(logger, exceptionHelper, educationsDataService) {        
        var vm = this;

        vm.formData = {
            loading: true
        };

        //TODO: logic for showing

        
        educationsDataService.getEducationCategories(vm.educationId).then(function (data) {
            vm.categories = data.categories;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });
    }
})();




