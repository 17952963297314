(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('editModerators', editModerators);

    editModerators.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', '$uibModal', 'moderator', 'schoolingSchedulePacksDataService', 'adding'];

    function editModerators(logger, $stateParams, $scope, i18nFilter, $uibModal, moderator, schoolingSchedulePacksDataService, adding) {
        var vm = this;

        vm.adding = adding;
        vm.moderator = moderator;
        vm.moderators = [];
        vm.refreshModerators = refreshModerators;
        vm.ok = ok;
        vm.cancel = cancel;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select")
        }


        function ok() {
            $scope.$close(vm.moderator);
        }

        function cancel() {
            $scope.$dismiss();
        }


        function refreshModerators(query) {
            if (query === undefined || query === "") {
                return;
            }
            schoolingSchedulePacksDataService.getSchoolingScheduleProgramModerators($stateParams.schoolingScheduleId, query).then(function (data) {
                vm.moderators = data;
            }, function (error) {
                logger.error(i18nFilter("translations.schoolingSchedule_gui_program_exception_message_fetching_moderators"));
            });
        }

    }
})();














