(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('learningActivityScheduleStatusFilter', learningActivityScheduleStatus);

    learningActivityScheduleStatus.$inject = ['logger', 'learningActivityScheduleStatus', 'i18nFilter'];


    function learningActivityScheduleStatus(logger, learningActivityScheduleStatus, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value == learningActivityScheduleStatus.enrolled) {
                return i18nFilter('translations.schedule_gui_label_user_enrolled');
            }
            else if (value == learningActivityScheduleStatus.missed) {
                return i18nFilter('translations.schedule_gui_label_status_schedule_missed');
            }
            else if (value == learningActivityScheduleStatus.completed) {
                return i18nFilter('translations.schedule_gui_label_status_finished_schedule');
            }
            else if (value == learningActivityScheduleStatus.cancelled) {
                return i18nFilter('translations.schedule_gui_label_status_cancelled');
            }
            return i18nFilter('translations.schedule_gui_label_user_not_enrolled');
        };
    }
})();
