(function () {
    'use strict';

    angular
        .module('app.schoolings.activities')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('schoolings.activities', {
            url: '/activities',
            templateUrl: 'app/schoolings/activities/activities.html',
            controller: 'SchoolingActivities',
            controllerAs: 'vm',            
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'SchoolingOwner', 'Instructor']
            },
            params: {
                learningActivityId: null
            },
            resolve: {
                activities: ['activitiesDataService', '$stateParams', 'exceptions', 'logger', function (activitiesDataService, $stateParams, exceptions, logger) {
                    return activitiesDataService.getActivitiesForSchooling($stateParams.id);
                }]
            }
        });
    }
})();