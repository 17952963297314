(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProgramCancel', ProgramCancel);

    ProgramCancel.$inject = ['logger', '$scope', '$state', '$stateParams', 'programsDataService', 'exceptionHelper', 'i18nFilter'];

    function ProgramCancel(logger, $scope, $state, $stateParams, programsDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close();
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
