(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('programs.administration.edit', {
                url: '/edit',
                templateUrl: 'app/programs/administration/edit/baseStep/edit.html',
                controller: 'EditProgramBaseStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner', 'Instructor']
                },
                resolve: {
                    program: ['programsDataService', '$stateParams', function (programsDataService, $stateParams) {
                        return programsDataService.getProgramById($stateParams.programId);
                    }]
                }
            })

            .state('programs.administration.edit.firstStep', {
                url: '/about',
                templateUrl: 'app/programs/administration/edit/firstStep/firstStep.html',
                controller: 'EditProgramFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {                    
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('programs.administration.edit.secondStep', {
                url: '/templates',
                templateUrl: 'app/programs/administration/edit/secondStep/secondStep.html',
                controller: 'EditProgramSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            })
            .state('programs.administration.edit.thirdStep', {
                url: '/prices',
                templateUrl: 'app/programs/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditProgramThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {  
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]               
                }
            })
            .state('programs.administration.edit.fourthStep', {
                url: '/educations',
                templateUrl: 'app/programs/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditProgramFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {
                    educations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getAllActiveEducations();
                    }]
                }
            })
            .state('programs.administration.edit.fifthStep', {
                url: '/eform',
                templateUrl: 'app/programs/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditProgramFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealProgramOwner']
                },
                resolve: {
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveProgramFieldNames();
                    }]
                }
            })

            .state('addProgram', {
                url: '/addProgram',
                parent: 'base',
                templateUrl: 'app/programs/administration/edit/baseStep/edit.html',
                controller: 'EditProgramBaseStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    program: ['programsDataService', '$stateParams', function (programsDataService, $stateParams) {
                        return {};
                    }]
                }
            })

            .state('addProgram.firstStep', {
                url: '/about',
                templateUrl: 'app/programs/administration/edit/firstStep/firstStep.html',
                controller: 'EditProgramFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevels();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('addProgram.secondStep', {
                url: '/templates',
                templateUrl: 'app/programs/administration/edit/secondStep/secondStep.html',
                controller: 'EditProgramSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            })
            .state('addProgram.thirdStep', {
                url: '/prices',
                templateUrl: 'app/programs/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditProgramThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]
                }
            })
            .state('addProgram.fourthStep', {
                url: '/educations',
                templateUrl: 'app/programs/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditProgramFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    educations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getAllActiveEducations();
                    }]
                }
            })
            .state('addProgram.fifthStep', {
                url: '/educations',
                templateUrl: 'app/programs/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditProgramFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealEducationOwner']
                },
                resolve: {
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveProgramFieldNames();
                    }]
                }
            })
            ;
    }
})();
