(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('removeSection', removeSection);

    removeSection.$inject = ['logger', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper','section'];

    function removeSection(logger, $stateParams, $scope, i18nFilter, exceptionHelper, section) {
        var vm = this;

        vm.section = section;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $scope.$close(true);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
