(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('schoolings.administration.activities', {
            url: '/activities',
            templateUrl: 'app/schoolings/administration/activities/list/activities.html',
            controller: 'ActivitiesAdministration',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner', 'SchoolingAdmin']
            },
            resolve: {
                activities: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                    return activitiesDataService.getAllActiveSchoolingActivitiesSummaryForSchooling($stateParams.id);
                }]
            }
        })
        .state('schoolings.administration.activities.edit', {
            url: '/edit/:activityId',
            templateUrl: 'app/schoolings/administration/activities/edit/edit.html',
            controller: 'EditActivity',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isEdit: true
            },
            resolve: {
                activity: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                    return activitiesDataService.getActivityById($stateParams.activityId);
                }]
            },
            onEnter: ['$state', function ($state) {
                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $('#editSchooling').offset().top
                    }, 400);
                }, 100);
            }]
        })
        .state('schoolings.administration.activities.add', {
            url: '/add',
            templateUrl: 'app/schoolings/administration/activities/edit/edit.html',
            controller: 'EditActivity',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                isEdit: false
            },
            resolve: {
                activity: function () {
                    return undefined;
                }
            },
            onEnter: ['$state', function ($state) {
                // scroll to edityyx
                // Koristi se setTimeout jer u trenutnku klikanja #editSchooling još ne postoji
                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $('#editSchooling').offset().top
                    }, 400);
                }, 100);
            }]
        })
        .state('schoolings.administration.activities.delete', {
            url: '/delete/:activityId',
            // trigger the modal to open when this route is active
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/schoolings/administration/activities/delete/delete.html',
                    controller: 'DeleteActivity',
                    controllerAs: 'vm'
                })
                // change route after modal result
                .result.then(function () {
                    // change route after clicking OK button
                    $state.go('^', { id: $stateParams.id }, { reload: true });
                }, function () {
                    // change route after clicking Cancel button or clicking background
                    $state.go('^', { id: $stateParams.id });
                });
            }],
            requireADLogin: true,
            params: {
                schoolingName: undefined
            },
            data: {
                title: 'Obriši',
                roles: ['CatalogAdmin', 'RealSchoolingOwner']
            }
        });
    }
})();
