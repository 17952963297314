(function () {
    'use strict';

    angular
        .module('app.targetGroups')
        .controller('targetSubgroups', targetSubgroups);

    targetSubgroups.$inject = ['targetGroup', 'targetSubgroups', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'targetGroupsDataService'];

    function targetSubgroups(targetGroup, targetSubgroups, i18nFilter, dateFilter, exceptionHelper, logger, targetGroupsDataService) {
        ///* jshint validthis:true */
        var vm = this;
        vm.targetSubgroups = targetSubgroups;
        vm.parentName = targetGroup.name;

        vm.localeMessages2 = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        vm.statusTypes = [
            {
                id: 0,
                format: i18nFilter('translations.general_status_deactiveted')
            },
            {
                id: 1,
                format: i18nFilter('translations.general_status_active')
            }
        ];

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'status',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 150,
                lookup: {
                    dataSource: vm.statusTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'targetSubgroupsActionTemplate'
            }
        ];

        vm.dataGridOptions2 = {
            dataSource: vm.targetSubgroups,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
