(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('programsDataService', activities);

    activities.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage', 'blobHelper', 'decimalHelper'];

    function activities($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage, blobHelper, decimalHelper) {
        var service = {
            getProgramById: getProgramById,

            addProgram: addProgram,
            editProgram: editProgram,
            deleteProgram: deleteProgram,
            finishProgram: finishProgram,
            cancelProgram: cancelProgram,
            regenerateCertificate: regenerateCertificate,
            getProgramLearners: getProgramLearners,
            getLearnerFinishedLinkedProgramEducations: getLearnerFinishedLinkedProgramEducations,
            confirmLinkedProgramEducationsPresence: confirmLinkedProgramEducationsPresence,
            getProgramLearnerSchedulePacks: getProgramLearnerSchedulePacks,
            getProgramLearnerSchedules: getProgramLearnerSchedules,
            editProgramLearnersSchedules: editProgramLearnersSchedules,
            getPotentialProgramsForPromotedEntities: getPotentialProgramsForPromotedEntities,
            getProgramsForSchedulePacks: getProgramsForSchedulePacks
        };

        return service;

        function getProgramById(id) {
            return $http.get('api/programs/' + id).then(function (data) {
                angular.forEach(data.data.prices, function (price, index) {
                    price.amount = decimalHelper.convertDecimalToLocaleString(price.amount);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addProgram(program) {
            var fd = new FormData();

            if (program.picture) {
                var blob = blobHelper.dataURItoBlob(program.picture);         
                program.picture = null;
                fd.append('picture', blob);
            }

            if (program.surveyEnabled) {
                var surveyGroup = program.surveyGroups;
                program.surveyGroups = [];
                program.surveyGroups.push(surveyGroup);
            }
            
            fd.append('item', JSON.stringify(program));

            return $http.post('api/programs', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editProgram(program) {
            var fd = new FormData();

            if (program.picture) {
                var blob = blobHelper.dataURItoBlob(program.picture);
                program.picture = null;
                fd.append('picture', blob);
            }

            if (program.surveyEnabled) {
                var surveyGroup = program.surveyGroups;
                program.surveyGroups = [];
                program.surveyGroups.push(surveyGroup);
            }

            fd.append('item', JSON.stringify(program));

            return $http.put('api/programs', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteProgram(programId) {
            return $http.delete('api/programs/' + programId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function finishProgram(programEnrollmentId) {
            return $http.post('api/programs/finish/' + programEnrollmentId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function cancelProgram(programEnrollmentId) {
            return $http.post('api/programs/cancel/'+ programEnrollmentId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function regenerateCertificate(programEnrollmentId) {
            return $http.post('api/programs/certificates/' + programEnrollmentId + '/regenerate').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramLearners(programId, tableOptions) {
            return $http.post('api/programs/' + programId + '/learners', tableOptions).then(function (data) {
                //Ispravak datuma
                angular.forEach(data.data.data, function (learner) {
                    learner.schedulePackStartDateTime = dateHelper.convertFromStringToDate(learner.schedulePackStartDateTime);
                    learner.schedulePackEndDateTime = dateHelper.convertFromStringToDate(learner.schedulePackEndDateTime);
                    if (learner.enrolledEducations) {
                        angular.forEach(learner.enrolledEducations, function (education) {
                            education.scheduleStart = dateHelper.convertFromStringToDate(education.scheduleStart);
                            education.scheduleEnd = dateHelper.convertFromStringToDate(education.scheduleEnd);
                        });
                    }
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getLearnerFinishedLinkedProgramEducations(selectedLearnerProgramEducationsData) {
            return $http.post('api/programs/learner/finish/programEducations', selectedLearnerProgramEducationsData).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function confirmLinkedProgramEducationsPresence(programId, userLearningPlans) {
            return $http.post('api/programs/' + programId + '/confirm', userLearningPlans).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramLearnerSchedulePacks(programId, eFormId) {
            return $http.post('api/programs/' + programId + '/learner/' + eFormId + '/schedulePacks').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramLearnerSchedules(eFormId, userId) {
            return $http.get('api/programs/learner/' + eFormId + '/schedules/' + userId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editProgramLearnersSchedules(eFormId, learnerEnrollments) {
            return $http.post('api/programs/learner/' + eFormId + '/schedules/', learnerEnrollments).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPotentialProgramsForPromotedEntities() {
            return $http.get('api/promotedEntities/potential/programs').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getProgramsForSchedulePacks() {
            return $http.get('api/programs/schedulePacks').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
