(function () {
    'use strict';

    angular
        .module('app.schoolings.home')
        .controller('SchoolingHome', schoolingHome);

    schoolingHome.$inject = ['logger', 'schooling', '$sce'];

    function schoolingHome(logger, schooling, $sce) {
        var vm = this;
        vm.title = 'schooling home';
        vm.schooling = schooling;

        vm.trustAsHtml = trustAsHtml;

        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        };
    }
})();
