(function () {
    'use strict';

    angular
        .module('app.categories')
        .controller('Categories', categoriesCtrl);

    categoriesCtrl.$inject = ['logger', 'categories', '$state', 'i18nFilter'];

    function categoriesCtrl(logger, categories, $state, i18nFilter) {
        var vm = this;
        vm.categories = categories;
        vm.deleteCategory = deleteCategory;

        vm.localeMessages = {
            title: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
        };

        //#region table
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                sortOrder: 'asc',
                allowSorting: true
            },
            {
                dataField: 'parent.name',
                caption: i18nFilter('translations.category_gui_parent'),
                alignment: 'left',
                allowSorting: true
            },
            {
                dataField: 'status',
                caption: i18nFilter('translations.general_gui_status'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'categoriesStatusTemplate'
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 300,
                allowSorting: false,
                cellTemplate: 'categoriesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.categories,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        function deleteCategory(id, name) {            
            $state.go('categories.delete', {id: id, categoryName: name});
        }
                
    }
})();
