(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('questionTypeFilter', questionType);

    questionType.$inject = ['logger', 'i18nFilter'];

    function questionType(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }         

            var value = Number(input);

            switch (value) {
                case 1:
                    return i18nFilter('translations.survey_question_gui_type_of_question_free_text');
                case 2:
                    return i18nFilter('translations.survey_question_gui_type_of_question_radio');
                case 3:
                    return i18nFilter('translations.survey_question_gui_type_of_question_checkbox');
                case 4:
                    return i18nFilter('translations.survey_question_gui_type_of_question_scalar');
                case 5:
                    return i18nFilter('translations.survey_question_gui_type_of_question_matrix');
                case 6:
                    return i18nFilter('translations.survey_question_gui_type_of_question_percent');
            }
        };
    }
})();
