(function () {
    'use strict';
    
    angular
        .module('app.exams.scholarships')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('scholarships', {
                parent: 'base',
                url: '/accessories/scholarships',
                templateUrl: 'app/accessories/scholarships/scholarships.html',
                controller: 'scholarships',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    scholarships: ['scholarshipsDataService', '$stateParams', function (scholarshipsDataService, $stateParams) {
                        return scholarshipsDataService.getAllScholarships();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('scholarships.add', {
                url: '/add',
                templateUrl: 'app/accessories/scholarships/editScholarship/editScholarship.html',
                controller: 'EditScholarship',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    scholarship: ['scholarshipsDataService', '$stateParams', function (scholarshipsDataService, $stateParams) {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('scholarships.edit', {
                url: '/edit/:scholarshipId',
                templateUrl: 'app/accessories/scholarships/editScholarship/editScholarship.html',
                controller: 'EditScholarship',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    scholarship: ['scholarshipsDataService', '$stateParams', function (scholarshipsDataService, $stateParams) {
                        return scholarshipsDataService.getScholarshipById($stateParams.scholarshipId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('scholarships.delete', {
                url: '/delete/:scholarshipId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/accessories/scholarships/deleteScholarship/deleteScholarship.html',
                        controller: 'deleteScholarship',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('scholarships');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('scholarships');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            });
    }    
})();
