(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('addCommissions', addCommissions);

    addCommissions.$inject = ['$location', '$scope', 'i18nFilter', '$stateParams', 'examNameAndScheduleCode', 'scheduleActivity', 'examSchedulesDataService', 'locationCount', 'dateHelper'];

    function addCommissions($location, $scope, i18nFilter, $stateParams, examNameAndScheduleCode, scheduleActivity, examSchedulesDataService, locationCount, dateHelper) {
        var vm = this;
        vm.item = {
            scheduleActivityId: scheduleActivity.examScheduleActivityId,
            allCandidates: false,
            numberOfCandidates: 0,
            numberOfCommissions: 1,
            suggestCommissionMembers: false,
            locationCount: locationCount.length
        };
   
        vm.ok = ok;
        vm.cancel = cancel;

        vm.numberOfCandidatesChanged = numberOfCandidatesChanged;
        vm.numberOfCommissionsChanged = numberOfCommissionsChanged;

        vm.startDatesValid = startDatesValid;

        vm.formData = {
            startDateConfig : {
                type: 'datetime',
                width: '100%',
                acceptCustomValue: true,
                placeholder: "DD.MM.YYYY HH:MM",
                min: new Date(1900, 0, 1),
                max: new Date(3000, 0, 1),
                onKeyDown: function (e) {
                    const input = document.getElementById('startDateTimeInput');
                    dateHelper.onDateTimeInputKeyDown(e.event, input);
                },
                inputAttr: {
                    'class': 'commissions-start-date',
                    'name': 'startDate',
                    'id': 'startDateTimeInput'
                },
                bindingOptions: {
                    value: 'vm.item.startDate'
                },
                calendarOptions: {
                    min: examNameAndScheduleCode.startDate
                }
            }
        };

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        function numberOfCommissionsChanged(){
        }

        function numberOfCandidatesChanged() {
        }

        function startDatesValid() {
            // date fields require custom validation
            var dateFields = $(".commissions-start-date");
            var isValid = true;
            dateFields.each(function (index, input) {
                if (!input.value)
                    isValid = false;
            });
            return isValid;
        }

        function ok() {
            $scope.$close(vm.item);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
