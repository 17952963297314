(function () {
    'use strict';

    var app = angular
        .module('app.personRecords')
        .controller('personRecords', personRecordsCtrl);

    personRecordsCtrl.$inject = ['$rootScope', 'logger', 'i18nFilter', 'areas', 'subAreas', '$compile', 'languages'];

    function personRecordsCtrl($rootScope, logger, i18nFilter, areas, subAreas, $compile, languages) {
        var vm = this;
        vm.areas = areas;
        vm.subAreas = subAreas;
        vm.onClickGenerateEmails = onClickGenerateEmails;
        vm.emptyTextareaDiv = emptyTextareaDiv;
        vm.languages = languages;
        vm.emailNum = 0;
        vm.textAreaNum = 0;

        var filters = {
            "namings": undefined,
            "areas": undefined,
            "subAreas": undefined,
            "personStatus": undefined,
            "languages": undefined
        };

        vm.localeMessages = {
            detailsTooltip: i18nFilter('translations.person_records_action_show_details'),
            filterLabelAll: i18nFilter('translations.person_records_filter_all')
        };
        
        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.person_records_gui_label_person'),
                alignment: 'left',

                allowFiltering: false
            },
            {
                dataField: 'professionalTitle',
                caption: i18nFilter('translations.person_records_gui_label_pro_title'),
                alignment: 'left',

                allowFiltering: false
            },
            {
                dataField: 'scientificTitle',
                caption: i18nFilter('translations.person_records_gui_label_sci_title'),
                alignment: 'left',

                allowFiltering: false
            },
            {
                dataField: 'contactNumber',
                caption: i18nFilter('translations.person_records_gui_label_contact_number'),
                alignment: 'left',

                allowFiltering: false
            },
            {
                dataField: 'namings',
                caption: i18nFilter('translations.person_records_gui_label_titles'),
                alignment: 'left',

                allowFiltering: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                cellTemplate: 'personRecordsActionTemplate',
                allowSorting: false,
                allowFiltering: false
            },
            {
                dataField: 'areas',
                allowFiltering: true,
                visible: false
            },
            {
                dataField: 'subAreas',
                allowFiltering: true,
                visible: false
            },
            {
                dataField: 'personStatus',
                allowFiltering: true,
                visible: false
            },
            {
                dataField: 'lastName',
                allowFiltering: true,
                visible: false,
                sortIndex: 0,
                sortOrder: "asc"
            },
            {
                dataField: 'languages',
                visible: false
            }
        ];

        vm.personRecordsDataSource = new DevExpress.data.DataSource({
            store: {
                type: "odata",
                version: 4,
                url: "odata/PersonRecordsOData",
                key: "Id",
                keyType: "Guid",
                beforeSend: function (e) {
                    var token = localStorage['adal.idtoken'];
                    if (token) {
                        e.headers = {
                            "Authorization": "Bearer " + token
                        }
                    }
                }
            }
        });

        vm.dataGridOptions = {
            dataSource: vm.personRecordsDataSource,
            columns: columns,
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true
        };

        // Namings filtering array (hardcoded)
        var namingsArray = [vm.localeMessages.filterLabelAll, "Sodni cenilec", "Sodni izvedenec", "Sodni tolmač"];
        
        // Parsing area arrays
        var areaObjectArray = [];
        var areaStringArray = [vm.localeMessages.filterLabelAll];
        vm.areas.forEach(function (area) {
            areaObjectArray.push(area);
            areaStringArray.push(area.name);
        });

        // Prepared empty subarea array
        var subAreaStringArray = [vm.localeMessages.filterLabelAll];

        // Person status filtering array (hardcoded)
        var personStatusArray = [vm.localeMessages.filterLabelAll, "Neaktiven", "Razrešen", "Zaprisežen"];
        
        // Parsing language array
        var languageArray = [vm.localeMessages.filterLabelAll];
        vm.languages.forEach(function (language) {
            if (language != null && language != "") {
                languageArray.push(language);
            }
        });

        // Namings dropdown
        vm.selectNamingsOptions = {
            dataSource: namingsArray,
            value: namingsArray[0],
            onValueChanged: function (data) {
                // Clear emailTextareaDiv
                vm.emptyTextareaDiv();

                // Assign filters and refresh
                // When choosing a naming 'Sodni tolmač', the language dropdown is enabled
                if (data.value != "Sodni tolmač") {
                    // Set the language dropdown to default value and disable it
                    $("#languageDropdown").dxSelectBox({
                        value: vm.localeMessages.filterLabelAll
                    });
                    $("#languageDropdown").dxSelectBox("instance").option('disabled', true);
                } else {
                    // Set the language dropdown to default value and enable it
                    $("#languageDropdown").dxSelectBox("instance").option('disabled', false);
                }

                filters["languages"] = undefined;

                if (data.value == vm.localeMessages.filterLabelAll) {
                    filters["namings"] = undefined;
                } else {
                    filters["namings"] = data.value;
                }

                refreshDxGridCombinedFilter();
            }
        };

        // Area dropdown
        vm.selectAreaOptions = {
            dataSource: areaStringArray,
            value: areaStringArray[0],
            onValueChanged: function (data) {
                // Clear emailTextareaDiv
                vm.emptyTextareaDiv();

                // Assign filters and refresh
                // When choosing area, it's guid is memorized for the sub area dropdown
                if (data.value == vm.localeMessages.filterLabelAll) {
                    // By selecting the default value on the area select box, you disable the sub area select box
                    $("#subAreaDropdown").dxSelectBox("instance").option('disabled', true);

                    filters["areas"] = undefined;
                    filters["subAreas"] = undefined;
                } else {
                    // Filling subarea array according to the chosen area guid
                    var chosenArea = areaObjectArray.filter(area => { return area.name == data.value });

                    subAreaStringArray = [vm.localeMessages.filterLabelAll];
                    vm.subAreas.forEach(function (subArea) {
                        if (chosenArea != null && subArea.areaId == chosenArea[0].id) {
                            subAreaStringArray.push(subArea.name === "" ? "Podatek ni vpisan ali pa še ni bil pridobljen." : subArea.name);
                        }
                    });

                    var newDataSource = new DevExpress.data.DataSource({
                        store: {
                            type: 'array',
                            data: subAreaStringArray
                        }
                    });
                    $("#subAreaDropdown").dxSelectBox({
                        dataSource: newDataSource,
                        value: newDataSource[0]
                    });
                    $("#subAreaDropdown").dxSelectBox("instance")._dataSource.reload();

                    $("#subAreaDropdown").dxSelectBox("instance").option('disabled', false);

                    filters["areas"] = data.value;
                    filters["subAreas"] = undefined;
                }

                refreshDxGridCombinedFilter();
            }
        };

        // SubArea dropdown
        vm.selectSubAreaOptions = {
            dataSource: subAreaStringArray,
            value: subAreaStringArray[0],
            onInitialized: function (e) {
                vm.subAreaSelectInstance = e.component;
            },
            onValueChanged: function (data) {
                // Clear emailTextareaDiv
                vm.emptyTextareaDiv();

                // Assign filters and refresh
                if (data.value == vm.localeMessages.filterLabelAll) {
                    filters["subAreas"] = undefined;
                } else {
                    filters["subAreas"] = data.value;
                }
                refreshDxGridCombinedFilter();
            },
            disabled: true
        };

        // Person status dropdown
        vm.selectPersonStatusOptions = {
            dataSource: personStatusArray,
            value: personStatusArray[0],
            onValueChanged: function (data) {
                // Clear emailTextareaDiv
                vm.emptyTextareaDiv();

                // Assign filters and refresh
                if (data.value == vm.localeMessages.filterLabelAll) {
                    filters["personStatus"] = undefined;
                } else {
                    filters["personStatus"] = data.value;
                }
                refreshDxGridCombinedFilter();
            }
        };

        // Language dropdown
        vm.selectLanguageOptions = {
            dataSource: languageArray,
            value: languageArray[0],
            onValueChanged: function (data) {
                // Clear emailTextareaDiv
                vm.emptyTextareaDiv();

                // Assign filters and refresh
                if (data.value == vm.localeMessages.filterLabelAll) {
                    filters["languages"] = undefined;
                } else {
                    filters["languages"] = data.value;
                }
                refreshDxGridCombinedFilter();
            },
            disabled: true
        };

        function onClickGenerateEmails() {
            var emailTextareaBreakNum = 100; // Number of emails before a new textarea is created
            var emails = "";
            vm.emailNum = 0;
            vm.textAreaNum = 0;
            var combinedFilterExpression = $("#gridContainer")
                .dxDataGrid("instance").getCombinedFilter();

            // Clear emailTextareaDiv of previous email lists
            vm.emptyTextareaDiv();

            // Creating unpaginated data source in order to get all the emails, not just first 10 (page size)
            var unpaginatedDataSource = new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/PersonRecordsOData",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            }
                        }
                    }
                },
                paginate: false,
                filter: combinedFilterExpression
            });

            // Asynchronous load method (promise), because there may be many person records
            unpaginatedDataSource.load()
            .then((data) => {
                data.forEach(function (personRecord) {
                    if (vm.emailNum >= emailTextareaBreakNum) {
                        // Create a new textarea and a button for it after 'emailTextareaBreakNum' mails are buffered
                        var textareaDiv = angular.element(document.querySelector('#emailTextareaDiv'));

                        var newTextarea = document.createElement("TEXTAREA");
                        var emailsTextNode = document.createTextNode(emails);
                        newTextarea.appendChild(emailsTextNode);
                        
                        newTextarea.id = "emailTextarea".concat(vm.textAreaNum);
                        newTextarea.rows = "3";
                        newTextarea.style = "width:100%; resize:vertical;";

                        // Directive usage (copy)
                        var btnHtml = '<div class="copy-button"><button copy class="btn btn-default" data-textAreaNum="' + vm.textAreaNum + '" i18n="translations.person_records_copy_to_clipboard"></button></div>';
                        var newCopyButton = $compile(btnHtml)($rootScope);

                        angular.element(textareaDiv).append(newCopyButton);
                        angular.element(textareaDiv).append(newTextarea);

                        emails = "";
                        vm.textAreaNum++;
                        vm.emailNum = 0;
                    } else if (personRecord.email != "Podatek ni vpisan ali pa še ni bil pridobljen.") {
                        // Building email string
                        emails = (emails != "" ? emails.concat(";", personRecord.email) : personRecord.email);
                        vm.emailNum++;
                    }
                });

                if (vm.textAreaNum == 0) {
                    if (vm.emailNum > 0) {
                        // Create the first textarea, there are currently none if this if statement executed
                        var textareaDiv = angular.element(document.querySelector('#emailTextareaDiv'));

                        var newTextarea = document.createElement("TEXTAREA");
                        var emailsTextNode = document.createTextNode(emails);
                        newTextarea.appendChild(emailsTextNode);

                        newTextarea.id = "emailTextarea".concat(vm.textAreaNum);
                        newTextarea.rows = "3";
                        newTextarea.style = "width:100%; resize:vertical;";

                        // Directive usage (copy)
                        var btnHtml = '<div class="copy-button"><button copy class="btn btn-default" data-textAreaNum="' + vm.textAreaNum + '" i18n="translations.person_records_copy_to_clipboard"></button></div>';
                        var newCopyButton = $compile(btnHtml)($rootScope);

                        angular.element(textareaDiv).append(newCopyButton);
                        angular.element(textareaDiv).append(newTextarea);

                        emails = "";
                        vm.textAreaNum++;
                        vm.emailNum = 0;
                    } else if (vm.emailNum == 0) {
                        // Show warning; there are no emails to generate
                        var textareaDiv = angular.element(document.querySelector('#emailTextareaDiv'));
                        alert("Filtrom ne ustreza nobena oseba. Poizkusite z drugačnimi filtri."); // Hardcoded
                        textareaDiv.innerHTML = "<span i18n='person_records_no_emails'></span>";
                    }
                }

                $rootScope.$apply();
            });
        }

        function refreshDxGridCombinedFilter() {
            // Check which filters are active
            var activeFilters = [];
            
            Object.keys(filters).forEach(function (key) {
                if (filters[key] != undefined) {
                    activeFilters.push({ type: key, value: filters[key] });
                }
            });

            // Assume case by the number of filters in the active filter object array
            switch (activeFilters.length) {
                case 0:
                    // clear filters
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .clearFilter();
                    break;
                case 1:
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .filter([activeFilters[0].type, "contains", activeFilters[0].value]);
                    break;
                case 2:
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .filter([[activeFilters[0].type, "contains", activeFilters[0].value],
                            "and",
                            [activeFilters[1].type, "contains", activeFilters[1].value]
                        ]);
                    break;
                case 3:
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .filter([[activeFilters[0].type, "contains", activeFilters[0].value],
                            "and",
                            [activeFilters[1].type, "contains", activeFilters[1].value],
                            "and",
                            [activeFilters[2].type, "contains", activeFilters[2].value]
                        ]);
                    break;
                case 4:
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .filter([[activeFilters[0].type, "contains", activeFilters[0].value],
                            "and",
                            [activeFilters[1].type, "contains", activeFilters[1].value],
                            "and",
                            [activeFilters[2].type, "contains", activeFilters[2].value],
                            "and",
                            [activeFilters[3].type, "contains", activeFilters[3].value]
                        ]);
                    break;
                case 5:
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .filter([[activeFilters[0].type, "contains", activeFilters[0].value],
                            "and",
                            [activeFilters[1].type, "contains", activeFilters[1].value],
                            "and",
                            [activeFilters[2].type, "contains", activeFilters[2].value],
                            "and",
                            [activeFilters[3].type, "contains", activeFilters[3].value],
                            "and",
                            [activeFilters[4].type, "contains", activeFilters[4].value]
                        ]);
                    break;
                default:
                    // clear filters
                    $("#gridContainer")
                        .dxDataGrid("instance")
                        .clearFilter();
            }
        }
    }

    // Directive for copying from textarea using a button
    app.directive("copy", function () {
        return function (scope, element, attrs) {
            element.bind("click", function () {
                var textArea = document.getElementById('emailTextarea'.concat(attrs.textareanum));
                textArea.select();
                document.execCommand('copy');
            });
        };
    });

    function emptyTextareaDiv() {
        var myNode = document.getElementById("emailTextareaDiv");;
        while (myNode.firstChild) {
            myNode.removeChild(myNode.firstChild);
        }
    }

})();
