(function () {
    'use strict';

    angular
        .module('app.targetGroups')
        .controller('EditTargetGroup', editTargetGroup);

    editTargetGroup.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'status', 'targetGroup', 'targetGroupsDataService', 'usersDataService', 'exceptionHelper', 'logger', '$sanitize',"emptyGuid"];

    function editTargetGroup(decimalHelper, dateHelper, $state, i18nFilter, status, targetGroup, targetGroupsDataService, usersDataService, exceptionHelper, logger, $sanitize, emptyGuid) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = targetGroup;
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {}
        vm.formData = {
            status: status
        }

        vm.add = add;
        vm.save = save;

        function add() {
            vm.item.id = emptyGuid;
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            targetGroupsDataService.addTargetGroup(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.targetGroups_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            targetGroupsDataService.editTargetGroup(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.targetGroups_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
