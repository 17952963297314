(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('questionsDataService', questions);

    questions.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function questions($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getQuestionsByCatalogueId: getQuestionsByCatalogueId,
            getQuestionById: getQuestionById,
            addQuestion: addQuestion,
            editQuestion: editQuestion,
            deleteQuestion: deleteQuestion,
            moveDownQuestion: moveDownQuestion,
            moveUpQuestion: moveUpQuestion
        };

        return service;
        
        function getQuestionsByCatalogueId(id) {
            return $http.get('api/questions/byCatalogue/' + id).then(function (data) {
                data.data.forEach(function (item, index) {
                    item.definition = JSON.parse(item.definition).Name.replace(/(<([^>]+)>)/ig,"");
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getQuestionById(id) {
            return $http.get('api/questions/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function addQuestion(question) {
            question.type = question.type.id;
            var fd = new FormData();

            fd.append('item', JSON.stringify(question));
            return $http.post('api/questions', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editQuestion(question) {
            question.type = question.type.id;
            var fd = new FormData();

            fd.append('item', JSON.stringify(question));
            return $http.put('api/questions', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteQuestion(id) {
            return $http.delete('api/questions/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function moveUpQuestion(id) {
            return $http.post('api/questions/' + id + '/moveUp').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveDownQuestion(id) {
            return $http.post('api/questions/' + id + '/moveDown').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
