(function () {
    'use strict';

    angular
        .module('app.targetGroups')
        .controller('deleteTargetGroup', deleteTargetGroup);

    deleteTargetGroup.$inject = ['logger', '$stateParams', 'targetGroupsDataService', '$scope', 'i18nFilter', 'exceptionHelper'];

    function deleteTargetGroup(logger, $stateParams, targetGroupsDataService, $scope, i18nFilter, exceptionHelper) {
        var vm = this;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.name = $stateParams.name;

        function ok() {
            targetGroupsDataService.deleteTargetGroup($stateParams.targetGroupId).then(function (data) {
                logger.success(i18nFilter("translations.targetGroups_queue_delete"));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
