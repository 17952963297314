(function () {
    'use strict';

    angular
        .module('app.users')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('users', {
                parent: 'base',
                url: '/manage/users',
                templateUrl: 'app/manage/users/users.html',
                controller: 'Users',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['TenantAdmin']
                }
            })
            .state('users.baseAdd', {
                url: '/add',
                templateUrl: 'app/manage/users/baseStep/baseStepUser.html',
                controller: 'BaseStepUser',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add',
                    roles: ['TenantAdmin']
                },
                resolve: {
                    user: ['usersDataService', '$stateParams', function (usersDataService, $stateParams) {
                        return {};
                    }],
                    organizations: ['organizationsDataService', function (organizationsDataService) {
                        return organizationsDataService.getAllActiveOrganizationsAndDepartments();
                    }]
                }
            })
            .state('users.baseAdd.firstStep', {
                url: '/firstStep',
                templateUrl: 'app/manage/users/firstStep/firstStepUser.html',
                controller: 'FirstStepUser',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    title: 'Add: User data',
                    roles: ['TenantAdmin']
                }
            })
            .state('users.baseEdit', {
                url: '/:userId/edit',
                templateUrl: 'app/manage/users/baseStep/baseStepUser.html',
                controller: 'BaseStepUser',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit',
                    roles: ['TenantAdmin']
                },
                resolve: {
                    user: ['usersDataService', '$stateParams', function (usersDataService, $stateParams) {
                        return usersDataService.getUserById($stateParams.userId);
                    }],
                    userEntityRoles: ['usersDataService', '$stateParams', function (usersDataService, $stateParams) {
                        return usersDataService.getUserEntityRoles($stateParams.userId);
                    }],
                    organizations: ['organizationsDataService', function (organizationsDataService) {
                        return organizationsDataService.getAllActiveOrganizationsAndDepartments();
                    }]
                }
            })
            .state('users.baseEdit.firstStep', {
                url: '/firstStep',
                templateUrl: 'app/manage/users/firstStep/firstStepUser.html',
                controller: 'FirstStepUser',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit: User data',
                    roles: ['TenantAdmin']
                }
            })
            .state('users.baseEdit.secondStep', {
                url: '/secondStep',
                templateUrl: 'app/manage/users/secondStep/secondStepUser.html',
                controller: 'SecondStepUser',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    title: 'Edit: User roles',
                    roles: ['TenantAdmin']
                }
            });
    }
})();
