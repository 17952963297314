(function () {
    'use strict';

    angular
        .module('app.surveys.administration')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys.administration', {
                url: '/administration',
                templateUrl: 'app/surveys/administration/administration.html',
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.administration.edit', {
                url: '/edit',
                templateUrl: 'app/surveys/administration/edit/edit.html',
                controller: 'EditSurvey',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                },
                resolve: {
                    survey: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getEditSurveyById($stateParams.surveyId);
                    }]
                }
            })
            .state('addSurvey', {
                url: '/addSurvey',
                parent: 'base',
                templateUrl: 'app/surveys/administration/edit/edit.html',
                controller: 'EditSurvey',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['SurveyAdmin']
                },
                resolve: {
                    survey: [function () {
                        return {};                        
                    }]
                }
            })
            .state('surveys.administration.delete', {
                url: '/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/administration/delete/delete.html',
                        controller: 'DeleteSurvey',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('classicCatalog.survey');
                        }, function () {
                            // change route after clicking OK button
                            $state.go('surveys.home');
                        });
                }],
                params: {
                    surveyName: undefined,
                    surveyId: undefined
                },
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            });
    }
})();
