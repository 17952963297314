(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('exceptionHelper', exceptionHelper);

    exceptionHelper.$inject = ['logger', 'exceptions', '$state', 'locale', '$q', 'notificationService'];

    function exceptionHelper(logger, exceptions, $state, locale, $q, notificationService) {
        var service = {
            handleException: handleException,
            getFailImportRecordsString: getFailImportRecordsString
        };

        return service;


        function handleException(error, forceErrorPage) {
            if (!error) {
                return;
            }
            if (error && error.exception 
                && error.exception.exceptionCode) {
                var message = notificationService.getMessage(error.exception.exceptionCode, error.exception.additionalData);
                if (message === undefined || forceErrorPage) {
                    $state.go('error', { details: error });
                }
                else {
                    logger.error(message);
                }
            }
            else {
                $state.go('error', { details: error });
            }
        }

        function getFailImportRecordsString(recordList) {
            var resultString = "";
            for (var i = 0; i < recordList.length - 1; i++) {
                resultString += recordList[i] + ", ";
            }
            resultString += recordList[recordList.length - 1];
            return resultString;
        }
    }
})();
