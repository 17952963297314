(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('categoriesCatalogFilter', {
            templateUrl: 'app/catalogs/filters/categories/categoriesCatalogFilter.html',
            controller: categoriesCatalogFilter,
            controllerAs: 'vm',
            bindings: {   
                data: '=',                
                onChanged: '='
            }
        });

    categoriesCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function categoriesCatalogFilter(logger, i18nFilter) {
        var vm = this;        

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.catalog_gui_categories_filter_placeholder")
        };
            
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 
    }
})();
