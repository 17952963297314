(function () {
    'use strict';

    angular
        .module('app.learningPeriod')
        .controller('EditPeriod', editPeriod);
    editPeriod.$inject = ['logger', '$state', '$stateParams', 'periodsDataService', '$locale', '$scope', 'exceptionHelper', 'i18nFilter', '$sanitize'];

    function editPeriod(logger, $state, $stateParams, periodsDataService, $locale, $scope, exceptionHelper, i18nFilter, $sanitize) {
        var vm = this;
        vm.item = {};
        vm.save = save;
        vm.add = add;
        vm.isEdit = $state.current.data.isEdit;
        

        vm.formData = {
            endDateConfig: {
                type: 'date',
                width: '50%',
                inputAttr: {
                    'name': 'endDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.dueDatePicker',
                    min: 'vm.item.startDatePicker'
                }
            },
            startDateConfig: {
                type: 'date',
                width: '50%',
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.startDatePicker',
                    max: 'vm.item.dueDatePicker'
                }
            }
        }

        


        if (vm.isEdit) {
            periodsDataService.getLarningPeriodById($stateParams.id).then(function (result) {
                vm.item = result;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function add() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            periodsDataService.addLearningPeriod(vm.item).then(function () {
                logger.success(i18nFilter("translations.learning_period_queue_add"));
                $state.go('learningPeriods');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            vm.item.id = DOMPurify.sanitize(vm.item.id);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            periodsDataService.editLearningPeriod(vm.item).then(function () {
                logger.success(i18nFilter("translations.learning_period_queue_edit"));
                $state.go('learningPeriods');
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

    }
})();
