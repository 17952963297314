(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .component('schedulePackEducationWrapper', {
            templateUrl: 'app/schedulePacks/components/educationWrapper/educationWrapper.html',
            controller: schedulePackEducationWrapper,
            controllerAs: 'vm',
            bindings: {
                schedules: '=',
                isOpen: '=',
                isEdit: '='
            }
        });

    schedulePackEducationWrapper.$inject = ['logger', 'educationsDataService', 'locationsDataService', 'exceptionHelper', 'programSchedulePackHelper'];

    function schedulePackEducationWrapper(logger, educationsDataService, locationsDataService, exceptionHelper, programSchedulePackHelper) {
        var vm = this;

        vm.formData = {
            expanded: vm.isOpen,
            scheduleDataReady: false,

            possibleInstructors: [],
            possibleLocations: [],
            education: undefined,
            educationId: undefined,
            educationName: undefined,
        };

        vm.$data = null;
        vm.programSchedulePackHelper = programSchedulePackHelper;

        //#region Method binding
        vm.toggleEducation = toggleEducation;
        vm.loadClassromScheduleData = loadClassromScheduleData;
        //#endregion

        function loadClassromScheduleData(){
            if (vm.schedules && vm.schedules.length !== 0) {
                vm.formData.educationId = vm.schedules[0].educationId;
                vm.formData.educationName = vm.schedules[0].educationName;

                let $locations = locationsDataService.getRegularLocations().then(function (data) {
                    vm.formData.possibleLocations = data;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });

                let $instructors = educationsDataService.getEducationInstructors(vm.formData.educationId).then(function (data) {
                    vm.formData.possibleInstructors = data;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });


                let $education = educationsDataService.getEducationById(vm.formData.educationId).then(function (data) {
                    vm.formData.education = data;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });


                vm.$data = Promise.all([$locations, $instructors, $education]).then((values) => {
                    vm.formData.scheduleDataReady = true;
                });
            }
        //#endregion
        }

        //#region Toggle
        function toggleEducation() {
            vm.formData.expanded = !vm.formData.expanded;
            if (vm.formData.expanded)
                vm.programSchedulePackHelper.scheduleExpanded = true;
            if (vm.$data == null)
                vm.loadClassromScheduleData();
        }
        //#endregion

    }
})();
