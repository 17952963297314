(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingFifthStep', fifthStep);

    fifthStep.$inject = ['logger', 'i18nFilter', '$state', '$scope', 'editSchoolingHelper', '$stateParams', 'fieldNames'];

    function fifthStep(logger, i18nFilter, $state, $scope, editSchoolingHelper,  $stateParams, fieldNames) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.item = editSchoolingHelper.getData();
        vm.item.showCheckboxAtStatement = !vm.isEdit ? true : vm.item.showCheckboxAtStatement;

        vm.formData = {
            fields: fieldNames,
            tinymceOptions: {
                setup: function (e) {
                    e.on('change', vm.checkStatement);
                    e.on('redo', vm.checkStatement);
                    e.on('undo', vm.checkStatement);
                },
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            }
        };

        vm.done = done;
        vm.cancel = cancel;
        vm.back = back;
        vm.checkStatement = checkStatement;


        function checkStatement(e) {
            var statementText = e.target.contentDocument.body.innerText;
            if (!statementText || !statementText.trim()) 
                vm.item.showCheckboxAtStatement = false;
        }

        function done() {
            var item_copy = angular.copy(vm.item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                });
            }

            // Should be a single value (when saving/editing schooling)
            item_copy.schoolingExecutionStyle = item_copy.schoolingExecutionStyle.id;   

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id != null) {
                    price.instructorType = price.instructorType.id;
                }
            });
            editSchoolingHelper.setData(item_copy);
            editSchoolingHelper.done(vm.isEdit);
        }

        function back() {
            $state.go($scope.navigation[3].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('schoolings.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.schooling');
            }
        }
    }
})();
