(function () {
  "use strict";

  angular
    .module("app.examSchedulePacks")
    .controller("examSchedulePackApplications", examSchedulePackApplications);

  examSchedulePackApplications.$inject = [
    "$stateParams",
    "$state",
    "$scope",
    "dateFilter",
    "i18nFilter",
    "exceptionHelper",
    "logger",
    "examApplications",
    "examApplicationStatus",
    "examNameAndScheduleCode",
    "documentsDataService",
    "examSchedulesDataService",
    "examSchedule",
    "examApplicationsDataService",
  ];

  function examSchedulePackApplications(
    $stateParams,
    $state,
    $scope,
    dateFilter,
    i18nFilter,
    exceptionHelper,
    logger,
    examApplications,
    examApplicationStatus,
    examNameAndScheduleCode,
    documentsDataService,
    examSchedulesDataService,
    examSchedule,
    examApplicationsDataService
  ) {
    var vm = this;

    vm.item = examSchedule;
    vm.gradesSended = false;

    vm.nameAndCode =
      examNameAndScheduleCode.examCode + "-" + examNameAndScheduleCode.code;
    setInterval(() => {
      if (examApplicationsDataService.newApplicationData) {
        examApplicationsDataService
          .getExamApplicationsByScheduleId($stateParams.schedulePackId)
          .then((v) => {
            if (v && examApplicationsDataService.newApplicationData) {
              $("#gridContainer").dxDataGrid("option", "dataSource", v);
              examApplicationsDataService.newApplicationData = false
            }
          });
      }
    }, 10000);

    vm.examApplications = examApplications;
    vm.inspectApplication = inspectApplication;
    vm.editResult = editResult;
    vm.documents = documents;
    vm.downloadAllApplicationsDocument = downloadAllApplicationsDocument;
    vm.downloadApplicationDocument = downloadApplicationDocument;
    vm.sendGradeNotification = sendGradeNotification;
    vm.clearGradesSended = clearGradesSended;
    vm.downloadCandidateListDocument = downloadCandidateListDocument;
    vm.downloadCodeDocument = downloadCodeDocument;

    vm.formData = {
      examApplicationStatus: examApplicationStatus,
    };

    vm.localeMessages = {
      inspectTooltip: i18nFilter(
        "translations.examSchedulePack_applications_gui_action_inspect_application"
      ),
      /*addResultTooltip: i18nFilter('translations.examSchedulePack_applications_gui_action_add_result'),*/
      editResultTooltip: i18nFilter(
        "translations.examSchedulePack_applications_gui_action_edit_result"
      ),
      documentsTooltip: i18nFilter(
        "translations.examSchedulePack_applications_gui_action_documents"
      ),
      applicationDocument: "Izpis prijave",
      gradesNotificationSended: "Elektronska sporočila so že bila poslana!",
    };

    vm.applicationStatus = [
      {
        id: 0,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_inProgress"
        ),
      },
      {
        id: 1,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_finished"
        ),
      },
      {
        id: 2,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_approved"
        ),
      },
      {
        id: 3,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusRejected"
        ),
      },
      {
        id: 4,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusApproached"
        ),
      },
      {
        id: 5,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusAbsentJustified"
        ),
      },
      {
        id: 6,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusAbsentUnjustified"
        ),
      },
      {
        id: 7,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusPendingPersonalData"
        ),
      },
      {
        id: 9,
        format: i18nFilter(
          "translations.examSchedulePack_applications_gui_label_statusDisenrolled"
        ),
      },
    ];

    var columns = [
      {
        dataField: "candidateName",
        caption: i18nFilter(
          "translations.examSchedulePack_applications_candidate"
        ),
        alignment: "left",
        allowFiltering: true,
        cssClass: "table-column-space-format wrapped-column",
        allowSorting: true,
        sortingMethod: function (value1, value2) {
          if (!value1 && value2) return -1;
          if (!value1 && !value2) return 0;
          if (value1 && !value2) return 1;
          return value1.localeCompare(value2, "sl-SI");
        },
      },
      {
        dataField: "applicationStatus",
        caption: i18nFilter(
          "translations.examSchedulePack_applications_status"
        ),
        alignment: "left",
        filterOperations: ["contains"],
        allowFiltering: true,
        width: 150,
        lookup: {
          dataSource: vm.applicationStatus,
          valueExpr: "id",
          displayExpr: "format",
        },
      },
      {
        dataField: "disenrolledDate",
        dataType: "date",
        caption: i18nFilter(
          "translations.examSchedulePack_applications_date_disenrolled"
        ),
        alignment: "left",
        width: 120,
        allowSorting: true,
        customizeText: function (args) {
          return dateFilter(args.value, "shortDate");
        },
      },
      {
        dataField: "enrolledDate",
        dataType: "date",
        caption: i18nFilter(
          "translations.examSchedulePack_applications_date_enrolled"
        ),
        alignment: "left",
        width: 120,
        allowSorting: true,
        customizeText: function (args) {
          return dateFilter(args.value, "shortDate");
        },
      },
      {
        caption: i18nFilter("translations.general_gui_actions"),
        alignment: "left",
        width: 200,
        allowSorting: false,
        cellTemplate: "examApplicationActionTemplate",
      },
    ];
    vm.dataGridOptions = {
      dataSource: examApplications,
      columns: columns,
      filterRow: { visible: true },
      searchPanel: { visible: false },
      pager: { visible: true },
      hoverStateEnabled: true,
      paging: {
        pageSize: 10,
        showNavigationButtons: true,
      },
      wordWrapEnabled: true,
      sorting: {
        mode: "single",
      },
    };

    function inspectApplication(application) {
      $stateParams.applicationId = application.id;
      $state.go("examSchedulePacks.application", $stateParams);
    }

    /*function addResult(application) {
            $stateParams.applicationId = application.id;
            $state.go('examSchedulePacks.resultsAdd', $stateParams);
        }*/

    function editResult(application) {
      $stateParams.applicationId = application.id;
      $state.go("examSchedulePacks.resultsEdit", $stateParams);
    }

    function documents(application) {
      $stateParams.applicationId = application.id;
      $state.go("examSchedulePacks.documents", $stateParams);
    }

    function sendGradeNotification() {
      if (vm.gradesSended === false) {
        vm.gradesSended = true;
        var ScheduleId = $stateParams.schedulePackId;
        examSchedulesDataService.sendGradeResultNotification(ScheduleId);
        logger.success(
          i18nFilter(
            "translations.examSchedulePack_applications_gui_notification_notificationOnGrades"
          )
        );
        setTimeout(() => clearGradesSended(), 7000);
      }
    }

    function clearGradesSended() {
      vm.gradesSended = false;
    }

    function downloadAllApplicationsDocument() {
      documentsDataService.downloadDocument(
        "api/examSchedules/allApplicationsDocument/" +
          $stateParams.schedulePackId,
        "Izpis vseh prijav.docx"
      );
    }

    function downloadApplicationDocument(applicationData) {
      documentsDataService.downloadDocument(
        "api/examSchedules/applicationDocument/" + applicationData.id,
        "Izpis prijave za uporabnika " + applicationData.candidateName + ".docx"
      );
    }

    function downloadCandidateListDocument() {
      documentsDataService.downloadDocument(
        "api/examSchedules/candidateListDocument/" +
          $stateParams.schedulePackId,
        "Seznam kandidatov.xlsx"
      );
    }

    function downloadCodeDocument() {
      documentsDataService.downloadDocument(
        "api/examSchedules/codeDocument/" + $stateParams.schedulePackId,
        "Šifrant " + vm.nameAndCode + ".xlsx"
      );
    }
  }
})();
