(function () {
    'use strict';

    angular
        .module('app.profile')
        .controller('termsOfUse', termsOfUse);

    termsOfUse.$inject = ['$scope'];

    function termsOfUse($scope) {
        /* jshint validthis:true */
        var vm = this;
        vm.cancel = cancel;

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
