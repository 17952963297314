(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamFistStep', EditExamFistStep);

    EditExamFistStep.$inject = ['targetSubgroups', 'logger', 'categories', 'tags', 'levels', 'fieldNames', 'editExamsHelper', 'examAccessMode', 'examsDataService', '$state', '$stateParams', '$scope', 'i18nFilter', 'guidGeneratorDataService', 'rolesDataService', 'contentTypeTags', '$sessionStorage', '$uibModal', '$sanitize'];

    function EditExamFistStep(targetSubgroups, logger, categories, tags, levels, fieldNames, editExamsHelper, examAccessMode, examsDataService, $state, $stateParams, $scope, i18nFilter, guidGeneratorDataService, rolesDataService, contentTypeTags, $sessionStorage, $uibModal, $sanitize) {
        var vm = this;
        vm.item = editExamsHelper.getData();
        vm.isEdit = $state.current.data.isEdit;
        //vm.item.id = $stateParams.Id;

        if ($sessionStorage.userInfo.tenantResourceKey == "eua") {
            vm.tenantEUA = true;
        } else {
            vm.tenantEUA = false;
        }

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),            
            status: i18nFilter("translations.exams_edit_gui_tooltip_info_status"),
            public: i18nFilter("translations.exams_edit_gui_tooltip_info_visible_available"),
            displayOnly: i18nFilter("translations.exams_edit_gui_tooltip_info_visible_not_available"),
            enrolledAndOwnersOnly: i18nFilter("translations.exams_edit_gui_tooltip_info_visible_to_enrolled_users_and_owners"),
            allowPartialRetake: i18nFilter("translations.exams_edit_gui_tooltip_info_partial_retake"),
            applicantChoosesTheme: i18nFilter("translations.exams_edit_gui_tooltip_info_applicant_chooses_theme"),
            mandatoryCheckboxAtStatement: i18nFilter("translations.exams_edit_gui_tooltip_info_mandatory_checkbox_at_statement")
        };
                
        vm.formData = {            
            examAccessMode: examAccessMode,
            categories: categories,
            levels: levels,
            fields: fieldNames,
            tinymceOptions: {                
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            targetSubgroups: targetSubgroups
        };
        
        vm.showStatus = rolesDataService.isUserInRoles(['CatalogAdmin']);
        vm.openInstructorStatementHelp = openInstructorStatementHelp;
        vm.logData = logData;
        vm.next = next;        
        vm.cancel = cancel;
        vm.save = save;
        vm.estDurationValidation = estDurationValidation;
        vm.levelTransform = levelTransform;
        vm.dateTime = new Date().getTime();
        if (!vm.isEdit) {
            vm.item.status = true;
        }
      
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);

        function levelTransform(level) {
            return {
                name: level,
                id: guidGeneratorDataService.getGuid()
            };
        }

        function save() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.code = DOMPurify.sanitize(vm.item.code);
            vm.item.description = DOMPurify.sanitize(vm.item.description);

            var item_copy = editExamsHelper.setCodeListData(vm.item);
            editExamsHelper.setData(item_copy);
            editExamsHelper.done(vm.isEdit, true);
        } 

        function next() {
            if (vm.isEdit) {
                $state.go($scope.navigation[1].state);
            } else {
                vm.item.showCodeUniquenessError = false;
                examsDataService.isCodeAvailable(angular.copy(vm.item.code)).then(function (data) {
                    if (data) {
                        vm.item.showCodeUniquenessError = true;
                    } else {
                        $state.go($scope.navigation[1].state);
                    }
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('exams.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.exam');
            }
        }

        function estDurationValidation() {
            var days = !vm.item.estDurationDay ? 0 : parseInt(vm.item.estDurationDay);
            var hours = !vm.item.estDurationHour ? 0 : parseInt(vm.item.estDurationHour);

            var sum = days + hours;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function openInstructorStatementHelp() {
            $uibModal.open({
                templateUrl: 'app/exams/administration/edit/firstStep/instructorStatementHelp/instructorStatementHelp.html',
                controller: 'examsInstructorStatementHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: function () {
                        return [
                            { name: "Ime za prikaz (iz profila)", variableName: "{{DisplayName}}" },
                            { name: "Naziv izpita", variableName: "{{ExamName}}" },
                            { name: "Stopnja izpita", variableName: "{{ExamLevel}}" },
                            { name: "Stopnja/raven izobrazbe (osebni podatek)", variableName: "{{EducationLevel}}" },
                            { name: "Pridobljeni strokovni naziv izobrazbe (osebni podatek)", variableName: "{{LearnerEducationTitleDisplay}}" }
                        ];
                    }
                }
            });
        }
        
        function logData() {
            logger.log("Data: ", vm.item);
        }

    }
})();
