(function () {
    'use strict';
    
    angular
        .module('app.targetGroups')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('targetGroups', {
                parent: 'base',
                url: '/manage/targetGroups',
                templateUrl: 'app/manage/targetGroups/targetGroups.html',
                controller: 'targetGroups',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetGroups: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return targetGroupsDataService.getAllTargetGroups();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.add', {
                url: '/add',
                templateUrl: 'app/manage/targetGroups/editTargetGroup/editTargetGroup.html',
                controller: 'EditTargetGroup',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetGroup: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return {};
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.edit', {
                url: '/edit/:targetGroupId',
                templateUrl: 'app/manage/targetGroups/editTargetGroup/editTargetGroup.html',
                controller: 'EditTargetGroup',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetGroup: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return targetGroupsDataService.getTargetGroupById($stateParams.targetGroupId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.delete', {
                url: '/delete/:targetGroupId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/targetGroups/deleteTargetGroup/deleteTargetGroup.html',
                        controller: 'deleteTargetGroup',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('targetGroups');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('targetGroups');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.targetSubgroups', {
                url: '/:parentId/targetSubgroups',
                templateUrl: 'app/manage/targetGroups/targetSubgroups/targetSubgroups.html',
                controller: 'targetSubgroups',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetGroup: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return targetGroupsDataService.getTargetGroupById($stateParams.parentId);
                    }],
                    targetSubgroups: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return targetGroupsDataService.getTargetSubgroupsByParentGroupId($stateParams.parentId);
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.targetSubgroups.add', {
                url: '/add',
                templateUrl: 'app/manage/targetGroups/targetSubgroups/editTargetSubgroup/editTargetSubgroup.html',
                controller: 'editTargetSubgroup',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetSubgroup: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return {
                            parentId: $stateParams.parentId
                        };
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.targetSubgroups.edit', {
                url: '/edit/:targetSubgroupId',
                templateUrl: 'app/manage/targetGroups/targetSubgroups/editTargetSubgroup/editTargetSubgroup.html',
                controller: 'editTargetSubgroup',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    targetSubgroup: ['targetGroupsDataService', '$stateParams', function (targetGroupsDataService, $stateParams) {
                        return targetGroupsDataService.getTargetSubgroupById($stateParams.targetSubgroupId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            })
            .state('targetGroups.targetSubgroups.delete', {
                url: '/delete/:targetSubgroupId',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/targetGroups/targetSubgroups/deleteTargetSubgroup/deleteTargetSubgroup.html',
                        controller: 'deleteTargetSubgroup',
                        controllerAs: 'vm'
                    })
                    // change route after modal result
                    .result.then(function () {
                        // change route after clicking OK button
                        $state.go('targetGroups.targetSubgroups');
                    }, function () {
                        // change route after clicking Cancel button or clicking background
                        $state.go('targetGroups.targetSubgroups');
                    });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            });
    }    
})();
