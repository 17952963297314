(function () {
    'use strict';

    angular
        .module('app.promotedEntities')
        .controller('promotedEntities', promotedEntitiesCtrl);

    promotedEntitiesCtrl.$inject = ['logger', 'promotedEntities', 'promotedEntitiesDataService', 'i18nFilter'];

    function promotedEntitiesCtrl(logger, promotedEntities, promotedEntitiesDataService, i18nFilter) {
        var vm = this;

        vm.promotedEntities = promotedEntities;
        

        vm.localeMessagess = {
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down'),
        };


        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.findHighestOrderNo = findHighestOrderNo;
        vm.findLowestOrderNo = findLowestOrderNo;

        //#region table
        var columns = [
            {
                dataField: 'orderNo',
                caption: i18nFilter('translations.general_gui_order'),
                alignment: 'left',
                width:200,
                allowSorting: true
            },
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowSorting: true
            },            
            {
                dataField: 'entityType',
                caption: i18nFilter('translations.general_gui_type'),
                alignment: 'left',
                width: 300,
                allowSorting: true,
                cellTemplate: 'promotedEntitiesTypeTemplate'
            },            
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 300,
                allowSorting: false,
                cellTemplate: 'promotedEntitiesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.promotedEntities,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        function moveUp(id) {
            promotedEntitiesDataService.moveUpEntity(id).then(function (data) {
                logger.success(i18nFilter('translations.promoted_entities_queue_move_up'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function moveDown(id) {
            promotedEntitiesDataService.moveDownEntity(id).then(function (data) {
                logger.success(i18nFilter('translations.promoted_entities_queue_move_down'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function findLowestOrderNo() {
            var lowest = vm.promotedEntities[0].orderNo;
            angular.forEach(vm.promotedEntities, function (e, index) {
                if (e.orderNo < lowest) {
                    lowest = e.orderNo;
                }
            });
            return lowest;
        }

        function findHighestOrderNo() {
            var highest = vm.promotedEntities[0].orderNo;
            angular.forEach(vm.promotedEntities, function (e, index) {
                if (e.orderNo > highest) {
                    highest = e.orderNo;
                }
            });
            return highest;
        }

        function reloadData() {
            promotedEntitiesDataService.getPromotedEntities().then(function (data) {
                vm.promotedEntities = data;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
       
    }
})();
