(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('periodsDataService', periods);

    periods.$inject = ['$http', 'logger', '$q', 'dateHelper'];

    function periods($http, logger, $q, dateHelper) {
        var service = {
            getAllLearningPeriods: getAllLearningPeriods,
            getLarningPeriodById: getLarningPeriodById,
            addLearningPeriod: addLearningPeriod,
            editLearningPeriod: editLearningPeriod,
            makeDefualt: makeDefualt,
            moveUp: moveUp,
            moveDown: moveDown,
        };

        return service;

        function moveUp(id) {
            return $http.post('api/learningPeriods/' + id + '/moveUp').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to move period up');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveDown(id) {
            return $http.post('api/learningPeriods/' + id + '/moveDown').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to move period down');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllLearningPeriods() {
            return $http.get('/api/learningPeriods/active').then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                })
        }

        function getLarningPeriodById(id) {
            return $http.get('/api/learningPeriods/' + id)
                .then(function (data) {
                    data.data.startDate = dateHelper.convertFromStringToDate(data.data.startDate);
                    data.data.dueDate = dateHelper.convertFromStringToDate(data.data.dueDate);

                    data.data.startDatePicker = data.data.startDate;
                    data.data.dueDatePicker = data.data.dueDate;
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                })
        }

        function addLearningPeriod(period) {
            period.startDatePicker = dateHelper.convertFromDateToString(period.startDatePicker);
            period.dueDatePicker = dateHelper.convertFromDateToString(period.dueDatePicker);

            period.startDate = period.startDatePicker;
            period.dueDate = period.dueDatePicker;

            return $http.post('/api/learningPeriods', period)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                })
        }

        function editLearningPeriod(period) {
            period.startDatePicker = dateHelper.convertFromDateToString(period.startDatePicker);
            period.dueDatePicker = dateHelper.convertFromDateToString(period.dueDatePicker);

            period.startDate = period.startDatePicker;
            period.dueDate = period.dueDatePicker;

            return $http.put('/api/learningPeriods', period)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                })
        }

        function makeDefualt(id) {
            return $http.put('/api/learningPeriods/' + id + '/default')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                })
        }

    }
})();