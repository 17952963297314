(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingFirstStep', EditSchoolingFirstStep);

    EditSchoolingFirstStep.$inject = ['targetSubgroups', 'competenceLevels', 'logger', 'editSchoolingHelper', 'schoolingAccessMode', 'categories', 'usersDataService', '$state', '$stateParams', '$scope', 'schoolingExecutionStyle', 'i18nFilter', 'guidGeneratorDataService', 'rolesDataService', 'schoolingEstDurationDays', '$sanitize'];

    function EditSchoolingFirstStep(targetSubgroups, competenceLevels, logger, editSchoolingHelper, schoolingAccessMode, categories, usersDataService, $state, $stateParams, $scope, schoolingExecutionStyle, i18nFilter, guidGeneratorDataService, rolesDataService, schoolingEstDurationDays, $sanitize) {
        var vm = this;
        vm.item = editSchoolingHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            selectDaysPlaceholder: i18nFilter("translations.general_gui_placeholder_select_or_type_number_of_days"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            generateDocuments: i18nFilter("translations.schooling_edit_gui_tooltip_info_generate_documents"),
            status: i18nFilter("translations.schooling_edit_gui_tooltip_info_status"),
            public: i18nFilter("translations.schooling_edit_gui_tooltip_info_visible_available"),
            displayOnly: i18nFilter("translations.schooling_edit_gui_tooltip_info_visible_not_available"),
            enrolledAndOwnersOnly: i18nFilter("translations.schooling_edit_gui_tooltip_info_visible_to_enrolled_users_and_owners")
        };

        vm.formData = {
            schoolingAccessMode: schoolingAccessMode,
            schoolingExecutionStyle: translateCollections(schoolingExecutionStyle),
            estDurationDays: translateCollections(schoolingEstDurationDays),
            users: [],
            categories: categories,
            usersIsLoading: false,
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            competenceLevels: competenceLevels,
            targetSubgroups: targetSubgroups            
        };

        vm.showStatus = rolesDataService.isUserInRoles(['CatalogAdmin']);
        vm.logData = logData;
        vm.refreshUsers = refreshUsers;
        vm.showInputHours = showInputHours;
        vm.next = next;
        vm.cancel = cancel;
        vm.estDurationValidation = estDurationValidation;
        vm.changeDurationUnit = changeDurationUnit;
        vm.selectDuration = selectDuration;
        vm.refreshDurations = refreshDurations;
        vm.dateTime = new Date().getTime();
        vm.removePicture = removePicture;
        vm.removeContactPerson = removeContactPerson;
        vm.save = save;

        function save() {
            vm.item.code = DOMPurify.sanitize(vm.item.code);
            vm.item.name = DOMPurify.sanitize(vm.item.name);

            var item_copy = angular.copy(vm.item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                });
            }

            item_copy.schoolingExecutionStyle = item_copy.schoolingExecutionStyle.id;

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id != null) {
                    price.instructorType = price.instructorType.id;
                }
            });
            editSchoolingHelper.setData(item_copy);
            editSchoolingHelper.done(vm.isEdit);
        }

        if (vm.item.schoolingExecutionStyle && vm.item.schoolingExecutionStyle != null &&
            (vm.item.schoolingExecutionStyle === parseInt(vm.item.schoolingExecutionStyle, 10))) {
            vm.item.schoolingExecutionStyle = schoolingExecutionStyle.filter(function (x) {
                return x.id == vm.item.schoolingExecutionStyle;
            })[0];
        }

        if (vm.isEdit) {
            vm.isPartOfAnyActiveProgramAndStatusActive = vm.item.isPartOfAnyActiveProgramAndStatusActive;
                switch (vm.item.estDurationDay) {
                    case 1:
                        vm.item.estDurationDays = vm.formData.estDurationDays[1];
                        break;

                    case -1:
                        vm.item.estDurationDays = vm.formData.estDurationDays[0];
                        break;

                    case -2:
                        vm.item.estDurationDays = vm.formData.estDurationDays[3];
                        break;

                    default:
                        vm.item.estDurationDays = { name: vm.item.estDurationDay + '-dnevna', value: vm.item.estDurationDay };
                }
            
        }

        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////
        //#endregion

        //#region Logotype
        /////////////////////////// LOGOTYPE RESIZE ///////////////////////////////
        vm.myLogotype = '';

        var handleFileSelect2 = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.logotypeName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myLogotype = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput2')).on('change', handleFileSelect2);
        /////////////////////////// END LOGOTYPE RESIZE ///////////////////////////////
        //#endregion

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });

            return data;
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.schooling_exception_message_fetching_schooling_owners"));
            });
        }
        
        function showInputHours(show) {
            if (!show) {
                vm.item.estDurationHour = null; 
            }       
        }

        function next() {
            $state.go($scope.navigation[1].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('schoolings.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.schooling');
            }
        }

        function refreshDurations(query) {
            vm.formData.estDurationDays = /^\d+$/.test(query) ? [{ name: query + '-dnevno', value: parseInt(query) }] : schoolingEstDurationDays;
        }

        function selectDuration() {
            vm.item.estDurationDay = vm.item.estDurationDays.value;
        }

        function changeDurationUnit(isHour) {
            if (isHour) {
                vm.item.estDurationDay = null;
                vm.item.estDurationDays = null;
            }
            else
                vm.item.estDurationHour = null;
        }

        function estDurationValidation() {
            var days = !vm.item.estDurationDay ? 0 : parseInt(vm.item.estDurationDay);
            var hours = !vm.item.estDurationHour ? 0 : parseInt(vm.item.estDurationHour);

            var sum = days + hours;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }

        function removePicture() {
            vm.item.deletePicture = true;
        }

        function removeContactPerson() {
            vm.item.contactPerson = undefined;
        }
    }
})();
