(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('daysOfWeek', daysOfWeek);

    daysOfWeek.$inject = ['logger', 'i18nFilter'];

    function daysOfWeek(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }
            
            var value = Number(input);

            switch (value) {
                case 1:
                    return i18nFilter('translations.email_filter_days_of_week_monday');
                case 2:    
                    return i18nFilter('translations.email_filter_days_of_week_tuesday');
                case 3:    
                    return i18nFilter('translations.email_filter_days_of_week_wednesday');
                case 4:   
                    return i18nFilter('translations.email_filter_days_of_week_thursday');
                case 5:    
                    return i18nFilter('translations.email_filter_days_of_week_friday');
                case 6:   
                    return i18nFilter('translations.email_filter_days_of_week_saturday');
                case 7:   
                    return i18nFilter('translations.email_filter_days_of_week_sunday');
            }
        };
    }
})();