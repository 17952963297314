(function () {
    'use strict';

    angular
        .module('app.reports')
        .directive('telerikReport', function () {
            //#region sdf
            return {
                restrict: 'EA',
                transclude: 'true',
                scope: {
                    name: '@',
                    parameters: '@'
                },
                template: "",
                link: function (scope, element, attrs) {
                    //create the viewer object first, can be done in index.html as well
                    var reportViewer = $("#reportViewer1").data("telerik_ReportViewer");
                    if (!reportViewer) {

                        $("#reportViewer1").toggle();

                        $(document).ready(function () {
                            $("#reportViewer1").telerik_ReportViewer({
                                error: function (e, args) {
                                    if (args.indexOf("Missing or invalid parameter value") != -1) {
                                        return;
                                    }
                                    alert('Error from report directive:' + args);
                                },
                                reportSource: {
                                    report: scope.name,
                                    parameters: JSON.parse(scope.parameters)

                                },
                                serviceUrl: '/api/reports/',
                                scale: 1.0,
                                ready: function () {

                                }
                            });
                        });
                    }
                    //on state change update the report source
                    scope.$watch('name', function () {

                        var reportViewer = $("#reportViewer1").data("telerik_ReportViewer");

                        if (reportViewer) {
                            var rs = reportViewer.reportSource();
                            if (rs && rs.report)
                                if (rs.report !== scope.name &&
                                    rs.parameters !== scope.parameters) {

                                    reportViewer.reportSource({
                                        report: scope.name,
                                        parameters: JSON.parse(scope.parameters)
                                    });
                                }
                        }
                    });
                }
            };
            //#endregion
        });

})();
