(function () {
    'use strict';

    angular
        .module('app')
        .factory('competenceLevelsDataService', competenceLevels);

    competenceLevels.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function competenceLevels($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getDdlCompetenceLevels: getDdlCompetenceLevels,
            deleteCompetenceLevel: deleteCompetenceLevel,
            addCompetenceLevel: addCompetenceLevel,
            editCompetenceLevel: editCompetenceLevel,
            getDdlCompetenceLevelsForSchooling: getDdlCompetenceLevelsForSchooling
        };

        return service;

        function getDdlCompetenceLevels() {
            return $http.get('api/competenceLevels').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getDdlCompetenceLevelsForSchooling() {
            return $http.get('api/competenceLevels/schooling').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteCompetenceLevel(id) {
            return $http.delete('api/competenceLevels/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCompetenceLevel(competenceLevel) {
            return $http.post('api/competenceLevels/', competenceLevel).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCompetenceLevel(competenceLevel) {
            return $http.put('api/competenceLevels/', competenceLevel).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
