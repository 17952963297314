(function () {
    'use strict';

    angular
        .module('app.exams.catalogues')
        .controller('EditCatalogue', editCatalogue);

    editCatalogue.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'examType', 'catalogue', 'cataloguesDataService', 'usersDataService', 'exceptionHelper', 'logger', '$sanitize'];

    function editCatalogue(decimalHelper, dateHelper, $state, i18nFilter, examType, catalogue, cataloguesDataService, usersDataService, exceptionHelper, logger, $sanitize) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = catalogue;
        vm.isEdit = $state.current.data.isEdit;
        vm.localeMessages = {
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select")
        }
        
        vm.formData = {
            examType: examType,
            users: []
        }

        vm.add = add;
        vm.save = save;
        vm.refreshUsers = refreshUsers;

        function add() {

            vm.item.name = DOMPurify.sanitize(vm.item.name);

            cataloguesDataService.addCatalogue(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_catalogues_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {

            vm.item.name = DOMPurify.sanitize(vm.item.name);

            cataloguesDataService.editCatalogue(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_catalogues_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }
    }
})();
