(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('examsDataService', exams);

    exams.$inject = ['$http', 'logger', '$sessionStorage', '$rootScope', '$q', 'dateHelper', 'blobHelper', 'decimalHelper', 'i18nFilter'];

    function exams($http, logger, $sessionStorage, $rootScope, $q, dateHelper, blobHelper, decimalHelper, i18nFilter) {
        var service = {
            getExamById: getExamById,
            getExamForScheduleById: getExamForScheduleById,
            getExamInfo: getExamInfo,
            getExamContactPersons: getExamContactPersons,
            getEditExam: getEditExam,
            addExam: addExam,
            editExam: editExam,
            getActiveExams: getActiveExams,
            getActiveExamsByCoordinator: getActiveExamsByCoordinator,
            deleteExam: deleteExam,
            searchExamFieldNames: searchExamFieldNames,
            getActiveExamFieldNames: getActiveExamFieldNames,
            getActiveEducationFieldNames: getActiveEducationFieldNames,
            getActiveProgramFieldNames: getActiveProgramFieldNames,
            getExamActivitiesByExamId: getExamActivitiesByExamId,
            getThemesByExamId: getThemesByExamId,
            getExamRecordersByExamId: getExamRecordersByExamId,
            getExamChairmenByExamId: getExamChairmenByExamId,
            getExamIndependentExpertsByExamId: getExamIndependentExpertsByExamId,
            getExamPreparersByExamId: getExamPreparersByExamId,
            getExamEvaluatorsByExamId: getExamEvaluatorsByExamId,
            getExamThemesGroupedByCommissionMemberByExamId: getExamThemesGroupedByCommissionMemberByExamId,
            getExamCommissionMembersGroupedByThemeByExamId: getExamCommissionMembersGroupedByThemeByExamId,
            getDistinctCommissionMembersByExamId: getDistinctCommissionMembersByExamId,
            isCodeAvailable: isCodeAvailable,
            getExamsWithEexamActivity: getExamsWithEexamActivity,
            updateExamFieldDefinition: updateExamFieldDefinition
        };

        return service;

        function getExamById(examId) {
            return $http.get('api/exams/' + examId).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamForScheduleById(examId) {
            return $http.get('api/exams/forSchedule/' + examId).then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamInfo(examId) {
            return $http.get('api/exams/' + examId + '/sidebar/info').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam info');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamContactPersons(examId) {
            return $http.get('api/exams/' + examId + '/sidebar/contactPersons').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to fetch exam contact persons');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEditExam(examId) {
            return $http.get('api/exams/' + examId + '/edit').then(function (data) {
                angular.forEach(data.data.customerPrices, function (price, index) {
                    price.price = decimalHelper.convertDecimalToLocaleString(price.price);
                });
                data.data.presidentAmount = decimalHelper.convertDecimalToLocaleString(data.data.presidentAmount);
                data.data.committeMemberAmount= decimalHelper.convertDecimalToLocaleString(data.data.committeMemberAmount);
                data.data.recorderAmount= decimalHelper.convertDecimalToLocaleString(data.data.recorderAmount);
                data.data.processingAmount= decimalHelper.convertDecimalToLocaleString(data.data.processingAmount);
                data.data.reviewExamAmount= decimalHelper.convertDecimalToLocaleString(data.data.reviewExamAmount);
                data.data.verbalExamAmount= decimalHelper.convertDecimalToLocaleString(data.data.verbalExamAmount);
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch edit exam");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addExam(exam) {
            var fd = new FormData();

            if (exam.picture) {
                var blob = blobHelper.dataURItoBlob(exam.picture);
                exam.picture = null;
                fd.append('picture', blob);
            }


            fd.append('item', JSON.stringify(exam));

            return $http.post('api/exams', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editExam(exam) {
            var fd = new FormData();

            if (exam.picture) {
                var blob = blobHelper.dataURItoBlob(exam.picture);
                exam.picture = null;
                fd.append('picture', blob);
            }

            fd.append('item', JSON.stringify(exam));

            return $http.put('api/exams/' + exam.id, fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                logger.log('Failed to change edit exam');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveExams() {
            return $http.get('api/exams/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to load all active exams.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveExamsByCoordinator() {
            return $http.get('api/exams/active/byCoordinator').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to load active exams by coordinator.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteExam(examId) {
            return $http.delete('api/exams/' + examId).then(function (data) {
                return data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function searchExamFieldNames(query) {
            return $http.get('api/exams/fieldNames/search/' + query).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to fetch field names that contain " + query);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveExamFieldNames() {
            return $http.get('api/exams/examFieldNames/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log(i18nFilter('translations.general_active_application_fields_exception'));
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveEducationFieldNames() {
            return $http.get('api/exams/educationFieldNames/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log(i18nFilter('translations.general_active_application_fields_exception'));
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveProgramFieldNames() {
            return $http.get('api/exams/programFieldNames/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log(i18nFilter('translations.general_active_application_fields_exception'));
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function updateExamFieldDefinition(field) {
            var fd = new FormData();
            fd.append('item', JSON.stringify(field));

            return $http.put('api/exams/fieldName/definition', fd, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to update field definition");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamActivitiesByExamId(examId,scheduleId) {
            return $http.get('api/exams/' + examId + '/activities/'+scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam activities for the exam id " + examId + " and schedule id " + scheduleId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getThemesByExamId(examId) {
            return $http.get('api/exams/' + examId + '/themes').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam activities for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamRecordersByExamId(examId) {
            return $http.get('api/exams/' + examId + '/recorders').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam recorders for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamChairmenByExamId(examId) {
            return $http.get('api/exams/' + examId + '/chairmen').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam chairmen for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamIndependentExpertsByExamId(examId) {
            return $http.get('api/exams/' + examId + '/independentExperts').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam independentExperts for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamPreparersByExamId(examId) {
            return $http.get('api/exams/' + examId + '/preparers').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam preparers for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamEvaluatorsByExamId(examId) {
            return $http.get('api/exams/' + examId + '/evaluators').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam evaluators for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getExamThemesGroupedByCommissionMemberByExamId(examId, examScheduleId) {
            return $http.get('api/exams/' + examId + '/ThemesGroupedByCommissionMember/' + examScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam ThemesGroupedByCommissionMember for the exam id " + examId + " and schedule id " + examScheduleId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamCommissionMembersGroupedByThemeByExamId(examId) {
            return $http.get('api/exams/' + examId + '/CommissionMembersGroupedByTheme').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam CommissionMembersGroupedByTheme for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getDistinctCommissionMembersByExamId(examId) {
            return $http.get('api/exams/' + examId + '/DistinctCommissionMembers').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get all exam DistinctCommissionMembers for the exam id " + examId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function isCodeAvailable(code) {
            return $http.get('api/exams/code/' + code + '/availability/').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get availability of exam code');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getExamsWithEexamActivity() {
            return $http.get('api/exams/eexams').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log("Failed to get eexams.");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
