(function () {
    'use strict';

    angular
        .module('app.programs.administration.learners')
        .controller('ProgramLearners', programLearners);

    programLearners.$inject = ['logger', 'learningPlanItemStatus', 'i18nFilter', 'dateFilter', 'programsDataService', '$stateParams', 'learningPlanItemSuccess', '$state', '$filter', 'eFormsUrl', 'exceptionHelper', 'documentsDataService', '$sessionStorage', '$uibModal', 'rolesDataService', 'program', 'documentCertificationSign'];

    function programLearners(logger, learningPlanItemStatus, i18nFilter, dateFilter, programsDataService, $stateParams, learningPlanItemSuccess, $state, $filter, eFormsUrl, exceptionHelper, documentsDataService, $sessionStorage, $uibModal, rolesDataService, program, documentCertificationSign) {
        var vm = this;        
        vm.i18nFilter = i18nFilter;
        vm.formData = {
            selectedLearner: undefined,
            selectedRows: [],
            selectedSubRows: new Map(),
            showOpenCertificate: false,
            showRegenerateCertificate: false,
            showFinishProgram: false,
            showOpenEForm: false,
            showCancelProgram: rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationAdmin', 'ProgramOwner'])
        };

        vm.getSubDataGridOptions = getSubDataGridOptions;
        vm.programHasDedicatedShcedules = program.hasDedicatedSchedules;

        //#region  Method binding
        vm.finishProgram = finishProgram;
        vm.cancelProgram = cancelProgram;
        vm.openCertificate = openCertificate;
        vm.regenerateCertificate = regenerateCertificate;
        vm.openEForm = openEForm;

        vm.dateFilter = dateFilter;

        vm.formatSchedules = formatSchedules;
        vm.editUserApplication = editUserApplication;
        vm.finishUserEducation = finishUserEducation;

        vm.displayName = displayName;
        vm.disableEditApplication = disableEditApplication;
        vm.disableFinishEducation = disableFinishEducation;
        //#endregion

        //#region Table
        vm.ProgramsEnrollmentStatus = [
            {
                id: learningPlanItemStatus.notStarted,  //applies also to removedFromPlan & started
                format: i18nFilter('translations.filter_learning_activity_status_not_finished')
            },
            {
                id: learningPlanItemStatus.finished,
                format: i18nFilter('translations.filter_learning_activity_status_finished')
            }
        ];

        vm.learningPlanItemStatuses = [
            {
                id: learningPlanItemStatus.removedFromPlan,
                format: i18nFilter('translations.filter_learning_plan_item_status_removed_from_plan')
            },
            {
                id: learningPlanItemStatus.notStarted,
                format: i18nFilter('translations.filter_learning_plan_item_status_not_started')
            },
            {
                id: learningPlanItemStatus.started,
                format: i18nFilter('translations.filter_learning_plan_item_status_started')
            },
            {
                id: learningPlanItemStatus.finished,
                format: i18nFilter('translations.filter_learning_activity_status_finished')
            }
        ];

        vm.learningPlanItemSuccesses = [
            {
                id: learningPlanItemSuccess.unknown,
                format: i18nFilter('translations.general_gui_success_unknown')
            },
            {
                id: learningPlanItemSuccess.success,
                format: i18nFilter('translations.general_gui_success_pass')
            },
            {
                id: learningPlanItemSuccess.fail,
                format: i18nFilter('translations.general_gui_success_fail')
            }
        ];

        vm.options = {
            displayName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            organization: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },    
            programEnrollmentStatus: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            programScheduleStartEndDate: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined                
            },            
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'displayName',
                caption: i18nFilter('translations.general_gui_last_first_name'),
                alignment: 'left',
                dataType: 'string',
                allowSorting: true
            },
            {
                dataField: 'organization',
                caption: i18nFilter('translations.general_gui_organization'),
                alignment: 'left',                
                dataType: 'string',
                allowSorting: true
            },            
            {
                dataField: 'programEnrollmentStatus',
                caption: i18nFilter('translations.program_learners_table_status'),
                alignment: 'left',
                dataType: 'number',
                allowSorting: true,
                lookup: {
                    dataSource: vm.ProgramsEnrollmentStatus,    //group display for calculateCellValues
                    valueExpr: 'id',
                    displayExpr: 'format'
                },
                calculateCellValue:
                    function (rowData) {    //override to show correct column labels for upper lookup search
                        if (rowData.programEnrollmentStatus == learningPlanItemStatus.finished)
                            return learningPlanItemStatus.finished;     //only for finished programsEnrollmentStatus
                        else
                            return learningPlanItemStatus.notStarted;   //care, also used for, removedFromPlan & started
                    }
            },            
            {
                dataField: 'programScheduleStartEndDate',
                caption: i18nFilter('translations.general_gui_schedule_date'),
                alignment: 'left',
                allowSorting: true,
                cellTemplate: 'schedulePackStartEndTemplate'
            }
        ];

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();
                clearFilterAndSort();

                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterApplayer(loadOptions.filter);
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterApplayer(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    sortApplayer(loadOptions.sort);
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken


                programsDataService.getProgramLearners($stateParams.programId, angular.copy(vm.options)).then(function (data) {                    
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter("translations.exception_message_fetching_attendees"));
                    d.reject();
                    });

                return d.promise();
            }
        };
        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            selection: {
                mode: "multiple"
            },
            onSelectionChanged: function (selectedItems) {
                logger.log("Selection occurred: ", selectedItems);
                vm.formData.selectedRows = selectedItems.selectedRowsData;

                if (selectedItems.selectedRowsData && selectedItems.selectedRowsData.length > 0) {
                    vm.formData.selectedLearner = selectedItems.selectedRowsData[0];
                }
                else {
                    vm.formData.selectedLearner = undefined;
                }
                checkActionsRequirements();
            },
            sorting: {
                mode: 'single'
            },
            masterDetail: {
                enabled: true,
                template: "detail"
            }
        };

        function getSubDataGridOptions(enrolledLearnerData) {
            vm.formData.selectedSubRows.set(enrolledLearnerData.userId, []);

            var subDataGrid = {
                filterRow: { visible: false },
                dataSource: enrolledLearnerData.enrolledEducations,
                searchPanel: { visible: false },
                columns: [
                    {
                        dataField: "educationName",
                        caption: vm.i18nFilter("translations.program_learners_table_education_name"),
                        cssClass: "hideOverflow"
                    },
                    {
                        dataField: "required",
                        dataType: "boolean",
                        caption: vm.i18nFilter("translations.program_learners_table_education_required")
                    },
                    {
                        caption: vm.i18nFilter("translations.program_learners_table_education_enrollment_status"),
                        dataField: "learningPlanItemStatus",
                        lookup: {
                            dataSource: vm.learningPlanItemStatuses,
                            valueExpr: "id",
                            displayExpr: "format"
                        }
                    },
                    {
                        caption: vm.i18nFilter("translations.program_learners_table_education_enrollment_success"),
                        dataField: "learningPlanItemSuccess",
                        lookup: {
                            dataSource: vm.learningPlanItemSuccesses,
                            valueExpr: "id",
                            displayExpr: "format"
                        }
                    },
                    {
                        caption: vm.i18nFilter("translations.program_learners_table_education_schedule"),
                        cellTemplate: "scheduleTemplate"
                    }
                ],
                selection: {
                    mode: "multiple"
                },
                onSelectionChanged: function (selectedItems) {
                    vm.formData.selectedSubRows.set(enrolledLearnerData.userId, selectedItems.selectedRowsData);
                }
            };

            return subDataGrid;
        }

        function clearFilterAndSort() {
            vm.options.displayName.filterType1 = undefined;
            vm.options.displayName.searchText1 = undefined;
            vm.options.displayName.filterType2 = undefined;
            vm.options.displayName.searchText2 = undefined;
            vm.options.displayName.sortType = undefined;

            vm.options.organization.filterType1 = undefined;
            vm.options.organization.searchText1 = undefined;
            vm.options.organization.filterType2 = undefined;
            vm.options.organization.searchText2 = undefined;
            vm.options.organization.sortType = undefined;

            vm.options.programEnrollmentStatus.filterType1 = undefined;
            vm.options.programEnrollmentStatus.searchText1 = undefined;
            vm.options.programEnrollmentStatus.filterType2 = undefined;
            vm.options.programEnrollmentStatus.searchText2 = undefined;
            vm.options.programEnrollmentStatus.sortType = undefined;
            
            vm.options.programScheduleStartEndDate.sortType = undefined;
            vm.options.programScheduleStartEndDate.filterType1 = undefined;
            vm.options.programScheduleStartEndDate.searchText1 = undefined;
            vm.options.programScheduleStartEndDate.filterType2 = undefined;
            vm.options.programScheduleStartEndDate.searchText2 = undefined;
        }

        function sortApplayer(sort) {
            if (sort[0].selector === "displayName") {
                vm.options.displayName.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "programEnrollmentStatus") {
                vm.options.programEnrollmentStatus.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "programScheduleStartEndDate") {
                vm.options.programScheduleStartEndDate.sortType = sort[0].desc ? 'desc' : 'asc';
            }
            if (sort[0].selector === "organization") {
                vm.options.organization.sortType = sort[0].desc ? 'desc' : 'asc';
            }            
        }

        function simpleFilterApplayer(element) {
            if (element[0] === "displayName") {
                vm.options.displayName.filterType1 = element[1];
                vm.options.displayName.searchText1 = element[2];
            }
            if (element[0] === "organization") {
                vm.options.organization.filterType1 = element[1];
                vm.options.organization.searchText1 = element[2];
            }
            
            if (element[0] === 'programEnrollmentStatus') {
                vm.options.programEnrollmentStatus.filterType1 = element[1];
                vm.options.programEnrollmentStatus.searchText1 = element[2];
            }

            if (element[0] === 'programScheduleStartEndDate') {
                vm.options.programScheduleStartEndDate.filterType1 = element[1];
                vm.options.programScheduleStartEndDate.searchText1 = element[2];
            }
        }
        //#endregion

        //#region Schedule format
        function formatSchedules(startDate, endDate) {
            if (!startDate || !endDate) {
                return '-';
            }

            const start = new Date($filter('date')(startDate, 'y/M/d'));
            const end = new Date($filter('date')(endDate, 'y/M/d'));

            const numberOfDays = start - end;

            if (numberOfDays === 0) {
                const startFormatted = dateFilter(startDate, 'short');
                const endFormatted = dateFilter(endDate, 'shortTime');
                return startFormatted + ' - ' + endFormatted;
            }
            else {
                const startFormatted = dateFilter(startDate, 'shortDate');
                const endFormatted = dateFilter(endDate, 'shortDate');
                return startFormatted + ' - ' + endFormatted;
            }
        }
        //#endregion

        //#region Methods for showing actions
        function checkActionsRequirements() {
            vm.formData.showOpenCertificate = vm.formData.selectedLearner && vm.formData.selectedLearner.hasCertificate;
            vm.formData.showRegenerateCertificate = vm.formData.selectedLearner && vm.formData.selectedLearner.programHasCertificateGenerationEnabled && vm.formData.selectedLearner.programEnrollmentStatus === learningPlanItemStatus.finished;
            vm.formData.showFinishProgram = vm.formData.selectedLearner && vm.formData.selectedLearner.finishEnabled;
            vm.formData.showOpenEForm =  vm.formData.selectedLearner;            
        }
        //#endregion

        //#region Actions
        function finishProgram() {
            programsDataService.finishProgram(vm.formData.selectedLearner.programEnrollmentId).then(function (data) {
                logger.success(i18nFilter('translations.program_queue_finish'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function openCertificate() {
            var titleName = vm.formData.selectedLearner.titleName ? vm.formData.selectedLearner.titleName + '_' : '';
            documentsDataService.downloadDocument('/api/programs/certificates/' + vm.formData.selectedLearner.programEnrollmentId, 'Certificate ' + vm.formData.selectedLearner.lastName + '_' + titleName + vm.formData.selectedLearner.firstName + '.pdf');
        }

        function regenerateCertificate() {
            programsDataService.regenerateCertificate(vm.formData.selectedLearner.programEnrollmentId).then(function (data) {
                logger.success(i18nFilter('translations.program_queue_regenerate_certificate'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function openEForm() {
            var tenantId = $sessionStorage.userInfo.tenantId;
            window.open(eFormsUrl[tenantId] + '/edit/program/' + vm.formData.selectedLearner.eFormId, '_blank');
        }

        function cancelProgram() {

            $uibModal.open({
                templateUrl: 'app/programs/administration/learners/cancelProgram/cancelProgram.html',
                controller: 'ProgramCancel',
                controllerAs: 'vm',
                size: 'lg'
            })
            .result.then(function () {
                logger.success(i18nFilter('translations.program_queue_cancel'));

                programsDataService.cancelProgram(vm.formData.selectedLearner.programEnrollmentId).then(function (data) {
                    $state.reload();
                    logger.success(i18nFilter('translations.program_queue_cancel_success'));

                }, function (error) {
                    exceptionHelper.handleException(error);
                });

                }, function () {

            });
        }
        //#endregion

        //#region Modals dialog
        function editUserApplication(enrolledLearner) {
            var editApplication = $uibModal.open({
                templateUrl: 'app/programs/administration/learners/editUserApplication/editUserApplication.html',
                controller: 'editUserApplication',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    enrolledLearnerData: function () {
                        return enrolledLearner.data;
                    },
                    programId: function () {
                        return $stateParams.programId;
                    },
                    learnerSchedules: ['programsDataService', function (programsDataService) {
                        return programsDataService.getProgramLearnerSchedules(enrolledLearner.data.eFormId, enrolledLearner.data.userId);
                    }],
                    programSchedules: ['programsDataService', function (programsDataService) {
                        return programsDataService.getProgramLearnerSchedulePacks($stateParams.programId, enrolledLearner.data.eFormId);
                    }]
                },
                backdrop: 'static'
            });

            editApplication.result.then(function (result) {
                if (Array.isArray(result) && result.length > 0) {
                    programsDataService.editProgramLearnersSchedules(enrolledLearner.data.eFormId, result).then(function (data) {
                        vm.dataGridOptions.dataSource.load();
                        logger.success(i18nFilter("translations.program_learners_success_message_fetching_attendees_update", vm.displayName(enrolledLearner.data)));
                    }, function (error) {
                        logger.error(i18nFilter("translations.exception_message_fetching_attendees_update", vm.displayName(enrolledLearner.data)));
                    });
                }
                else {
                    logger.warning(i18nFilter("translations.program_learners_info_message_fetching_attendees_update", vm.displayName(enrolledLearner.data)));
                }
            }, function () {
                // when users clicks cancel button
            });
        }

        function finishUserEducation(enrolledLearner) {
            let selectedData = vm.formData.selectedSubRows.get(enrolledLearner.data.userId);   //all table data

            if (Array.isArray(selectedData) && selectedData.length > 0) {   //tmp FIX  change to DISABLE THE BUTTON IF NOTHING SELECTED

                //FinishedLearnerProgramEducationsVM data
                let selectedLearnerProgramEducationsData = {
                    learnerId: enrolledLearner.data.userId,
                    eFormId: enrolledLearner.data.eFormId,
                    programId: $stateParams.programId,
                    programLearnerEducations: selectedData
                }

                var finishEducation = $uibModal.open({
                    templateUrl: 'app/programs/administration/learners/finishUserEducation/finishUserEducation.html',
                    controller: 'finishUserEducation',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        enrolledLearnerData: function () {
                            return enrolledLearner.data;
                        },
                        programId: function () {
                            return $stateParams.programId;
                        },
                        selectedEducationsData: function () {
                            return selectedData;
                        },
                        learnerFinishedLinkedProgramEducations: ['programsDataService', function (programsDataService) {
                            return programsDataService.getLearnerFinishedLinkedProgramEducations(selectedLearnerProgramEducationsData);
                        }],
                    },
                    backdrop: 'static'
                });

                finishEducation.result.then(function (result) {
                    programsDataService.confirmLinkedProgramEducationsPresence($stateParams.programId, result).then(function (data) {
                        logger.success(i18nFilter("translations.program_learners_queue_confirm_program_learning_plans"));
                    }, function (error) {
                        //error for service (query, so not used)
                    });
                }, function () {
                    // when users clicks cancel button
                });
            }
        }
        //#endregion Modals dialog

        function displayName(enrollerLearnerData) {
            return enrollerLearnerData.displayName;
        }

        function disableEditApplication(enrolledLearnerData) {
            let enrollmentStatus = enrolledLearnerData.programEnrollmentStatus;
            let finishEnabled = enrolledLearnerData.finishEnabled;

            if (enrollmentStatus == learningPlanItemStatus.finished || finishEnabled)
                return true;
            else
                return false;
        }

        function disableFinishEducation(enrolledLearnerData) {
            let subrow = vm.formData.selectedSubRows.get(enrolledLearnerData.userId);
            let enrollmentStatus = enrolledLearnerData.programEnrollmentStatus;
            let subrowHasFinished = false;

            if (angular.isArray(subrow))    //make sure it's an array, considering we're using map
                subrowHasFinished = subrow.some(x => x.learningPlanItemStatus === learningPlanItemStatus.finished);

            if (enrollmentStatus == learningPlanItemStatus.finished ||
                !angular.isArray(subrow) || angular.equals(subrow, []) || subrowHasFinished)
                return true;
            else
                return false;
        }
    }
})();
