(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackCreate', examSchedulePackCreate);

    examSchedulePackCreate.$inject = ['$state', '$stateParams', '$scope', 'i18nFilter', 'exceptionHelper', 'scheduleVisibility', 'examScheduleApplicationType', 'organizationsDataService', 'exam', 'examSchedule', 'themes', 'examSchedulesDataService', 'dateHelper', 'logger'];

    function examSchedulePackCreate($state, $stateParams, $scope, i18nFilter, exceptionHelper, scheduleVisibility, examScheduleApplicationType, organizationsDataService, exam, examSchedule, themes, examSchedulesDataService, dateHelper, logger) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.examAllowPartialRetake = exam.allowPartialRetake;
        vm.searchOrganizations = searchOrganizations;
        vm.checkIfFormIsValid = checkIfFormIsValid;
        vm.item = vm.isEdit ? examSchedule : {
            id: undefined,
            examId: undefined,
            code: undefined,
            startDate: undefined,
            joinDeadline: undefined,
            leaveDeadline: undefined,
            visibility: undefined,
            location: undefined,
            organizations: [],
            themes: []
        };

        vm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            selectThemePlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        vm.formData = {
            organizations: [],
            organizationsIsLoading: false,
            scheduleVisibility: scheduleVisibility,
            examScheduleApplicationType: examScheduleApplicationType,
            startDateConfig: {
                type: 'date',
                width: '100%',
                showClearButton: true,
                acceptCustomValue: true,
                placeholder: "DD.MM.YYYY",
                min: new Date(1900, 0, 1),
                max: new Date(3000, 0, 1),
                onKeyDown: function (e) {
                    const input = document.getElementById('startDateInput');
                    dateHelper.onDateInputKeyDown(e.event, input);
                },
                onValueChanged: function (e) {
                    vm.item.startDate = dateHelper.createNewDateTimeWithoutSeconds(vm.item.startDate);
                    calculateDeadline();
                },
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true',
                    'id': 'startDateInput'
                },
                bindingOptions: {
                    value: 'vm.item.startDate'
                }
            },
            themes: themes,
            validationTriggered: false
        };

        //#region Functions
        vm.save = save;
        vm.joinDeadlineChanged = joinDeadlineChanged;
        vm.leaveDeadlineChanged = leaveDeadlineChanged;
        //#endregion

        //#region Initialization
        if (!vm.isEdit) {
            vm.item.exam = exam;
            vm.item.visibility = vm.formData.scheduleVisibility.public;
            vm.item.joinDeadline = exam.defaultJoinDeadline;
            vm.item.leaveDeadline = exam.defaultLeaveDeadline;
        } else {
            calculateDeadline();
        }
        //#endregion

        function save() {
            var allFormsAreValid = checkIfFormIsValid();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }
            vm.item.showCodeUniquenessError = false;
            if (vm.isEdit) {
                return examSchedulesDataService.editExamSchedule(angular.copy(vm.item)).then(function (data) {
                    logger.success(i18nFilter('translations.tenant_queue_edit'), data);
                    $state.go("examSchedulePacks");
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
            else {
                examSchedulesDataService.isCodeAvailable(angular.copy(vm.item.code), $stateParams.entityId).then(function (data) {
                    if (data) {
                        vm.item.showCodeUniquenessError = true;
                    } else {
                        examSchedulesDataService.addExamSchedule(angular.copy(vm.item)).then(function (data) {
                            logger.success(i18nFilter('translations.tenant_queue_add'), data);
                            $state.go("examSchedulePacks");
                        }, function (error) {
                            exceptionHelper.handleException(error);
                        });
                    }
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function checkIfFormIsValid() {
            vm.formData.validationTriggered = true;
            if (vm.item.startDate == "" || vm.item.startDate == null) {
                return false;
            } else {
                return true;
            }
        }

        function calculateDeadline() {
            joinDeadlineChanged();
            leaveDeadlineChanged();
        }

        function joinDeadlineChanged() {
            // join deadline calculation
            if (vm.item.startDate != "" && vm.item.startDate != null) {
                var joinDateInText = new Date(vm.item.startDate);
                vm.item.joinDeadlineText = joinDateInText.setDate(joinDateInText.getDate() - vm.item.joinDeadline);
            } else {
                vm.item.joinDeadlineText = "";
            }
        }
        function leaveDeadlineChanged() {
            // leave deadline calculation
            if (vm.item.startDate != "" && vm.item.startDate != null) {
                var leaveDateInText = new Date(vm.item.startDate);
                vm.item.leaveDeadlineText = leaveDateInText.setDate(leaveDateInText.getDate() - vm.item.leaveDeadline);
            } else {
                vm.item.leaveDeadlineText = "";
            }
        }
        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }
    }
})();
