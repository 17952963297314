(function () {
    "use strict";

    angular.module("app").controller("EditContract", editContract);

    editContract.$inject = [
        "dateHelper",
        "budgetEntities",
        "usersDataService",
        "logger",
        "contractsDataService",
        "exceptionHelper",
        "contract",
        "organizationsDataService",
        "i18nFilter",
        "$state",
        "contractingPartyType",
        "budgetsDataService",
        "templatesDataService",
        "$uibModal",
        '$sessionStorage',
    ];

    function editContract(
        dateHelper,
        budgetEntities,
        usersDataService,
        logger,
        contractsDataService,
        exceptionHelper,
        contract,
        organizationsDataService,
        i18nFilter,
        $state,
        contractingPartyType,
        budgetsDataService,
        templatesDataService,
        $uibModal,
        $sessionStorage
    ) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = contract;
        vm.refreshUsers = refreshUsers;
        vm.add = add;
        vm.save = save;
        //vm.areDatesValid = areDatesValid;
        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.clearContractingPartyAjpesSelection =
            clearContractingPartyAjpesSelection;
        vm.isEdit = $state.current.data.isEdit;
        vm.startDateValidate = startDateValidate;
        vm.endDateValidate = endDateValidate;
        vm.onPartySelect = onPartySelect;
        vm.monetaryStringFormat = monetaryStringFormat;
        vm.searchBudgets = searchBudgets;
        vm.clearBudgetSelection = clearBudgetSelection;
        vm.onBudgetSelected = onBudgetSelected;
        vm.orderDateValidate = orderDateValidate;
        vm.clearContractingPartyFromDbSelection =
            clearContractingPartyFromDbSelection;
        vm.onPartyFromDbSelected = onPartyFromDbSelected;
        vm.searchContractorsFromDb = searchContractorsFromDb;
        vm.searchContractTemplates = searchContractTemplates;
        vm.clearContractTemplateSelection = clearContractTemplateSelection;
        vm.openContractRecordHelp = openContractRecordHelp;

        if (!vm.isEdit) {
            vm.item.publicContractOrder = true;
        }

        if ($sessionStorage.userInfo.tenantResourceKey == "eua") {
            vm.tenantEUA = true;
        } else {
            vm.tenantEUA = false;
        }

        if (vm.item.amount != null)
            vm.item.amount = parseFloat(Math.round(vm.item.amount * 100) / 100)
                .toFixed(2)
                .replace(".", ",");

        if (vm.item.depletedSum != null) {
            for (var i = 0; i < vm.item.depletedSum.length; i++) {
                vm.item.depletedSum[i].depletedSum =
                    parseFloat(
                        Math.round(vm.item.depletedSum[i].depletedSum * 100) /
                            100
                    )
                        .toFixed(2)
                        .replace(".", ",") + " €";
            }
        }

        if (vm.item.availableSum != null)
            vm.item.availableSum = parseFloat(
                Math.round(vm.item.availableSum * 100) / 100
            )
                .toFixed(2)
                .replace(".", ",");

        vm.contractingPartyType = translateCollections(contractingPartyType);
        if (vm.item.contractingPartyType != null)
            vm.item.contractingPartyType =
                vm.contractingPartyType[vm.item.contractingPartyType];

        vm.localeMessages = {
            asyncSelectPlaceholder: i18nFilter(
                "translations.general_gui_async_ddl_placeholder_select"
            ),
            selectPlaceholder: i18nFilter(
                "translations.general_gui_placeholder_select"
            ),
        };

        vm.formData = {
            signingTimeConfig: {
                type: "date",
                width: "100%",
                inputAttr: {
                    name: "signingDate",
                    "ng-required": "false",
                },
                bindingOptions: {
                    value: "vm.item.signingDate",
                },
            },
            startTimeConfig: {
                type: "date",
                width: "100%",
                onValueChanged: function (e) {
                    vm.endDatevalidator.validate();
                },
                inputAttr: {
                    name: "startDate",
                    "ng-required": "true",
                },
                bindingOptions: {
                    value: "vm.item.start",
                },
            },
            startDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_start_date_is_either_blank_or_invalid"
                        ),
                    },
                    {
                        type: "custom",
                        validationCallback: vm.startDateValidate,
                        reevaluate: true,
                    },
                ],
                onInitialized: function (e) {
                    vm.startDatevalidator = e.component;
                },
            },
            endTimeConfig: {
                type: "date",
                width: "100%",
                onValueChanged: function (e) {
                    vm.startDatevalidator.validate();
                },
                inputAttr: {
                    name: "endDate",
                    "ng-required": "true",
                },
                bindingOptions: {
                    value: "vm.item.end",
                },
            },
            endDateValidationOps: {
                validationRules: [
                    {
                        type: "required",
                        message: i18nFilter(
                            "translations.general_validation_message_end_date_is_either_blank_or_invalid"
                        ),
                    },
                    {
                        type: "custom",
                        validationCallback: vm.endDateValidate,
                        reevaluate: true,
                    },
                ],
                onInitialized: function (e) {
                    vm.endDatevalidator = e.component;
                },
            },

            organizations: [],
            entities: budgetEntities,
            usersIsLoading: false,
            organizationsIsLoading: false,
            budgets: [],
            budgetsIsLoading: false,
            contractorsFromDbIsLoading: false,
            contractTemplates: [],
            contractTemplatesIsLoading: false,
        };

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];

            usersDataService.searchForActiveUsers(query, userIds).then(
                function (data) {
                    vm.formData.users = data;
                    vm.formData.usersIsLoading = false;
                },
                function (error) {
                    vm.formData.usersIsLoading = false;
                    logger.error(
                        i18nFilter(
                            "translations.contract_exception_message_fetching_users"
                        )
                    );
                }
            );
        }

        if (!vm.isEdit) {
            if (!vm.item.start) {
                vm.item.start = new Date();
            }

            if (!vm.item.end) {
                vm.item.end = new Date();
            }
        } else if (vm.item.amount != null) {
            vm.item.amount = vm.item.amount.toString().replace(".", ",");
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });

            return data;
        }

        function onPartySelect() {
            vm.item.contractingParty = vm.item.contractingPartyAjpes.name;
            vm.item.address = vm.item.contractingPartyAjpes.address;
            vm.item.taxId = vm.item.contractingPartyAjpes.taxId;
            vm.item.iban = vm.item.contractingPartyAjpes.iban;

            if (vm.item.contractingPartyAjpes.vatPayer === true) {
                vm.item.taxPayer = true;
            } else {
                vm.item.taxPayer = false;
            }
        }

        function startDateValidate(options) {
            if (
                vm.item.end != "" &&
                vm.item.end != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) >
                    dateHelper.createNewDateTimeWithoutTime(vm.item.end)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_start_date_should_be_less_than_end_date"
                );
                return false;
            }

            return true;
        }

        function endDateValidate(options) {
            if (
                vm.item.start != "" &&
                vm.item.start != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) <
                    dateHelper.createNewDateTimeWithoutTime(vm.item.start)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_end_date_should_be_greater_than_start_date"
                );
                return false;
            }

            return true;
        }

        // function areDatesValid() {
        //     if (!vm.item.start || !vm.item.end) {
        //         return false;
        //     }
        //     var start = vm.item.start.getTime();
        //     var end = vm.item.end.getTime();

        //     return start <= end;
        // }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(
                function (data) {
                    vm.formData.organizations = data;
                    vm.formData.organizationsIsLoading = false;
                },
                function (error) {
                    vm.formData.organizationsIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function clearOrganizationSelection() {
            vm.item.organization = undefined;
        }

        function clearContractingPartyAjpesSelection() {
            vm.item.contractingPartyAjpes = undefined;
        }

        function add() {
            logger.log("Add: ", vm.item);
            var itemCopy = angular.copy(vm.item);

            if (itemCopy.amount != null)
                if (itemCopy.amount.toString().includes(",")) {
                    itemCopy.amount = parseFloat(
                        itemCopy.amount.toString().replace(",", ".")
                    );
                } else {
                    itemCopy.amount.toString().concat(".00");
                }

            itemCopy.contractingPartyType = itemCopy.contractingPartyType.id;

            contractsDataService.addContract(itemCopy).then(
                function (data) {
                    logger.success(
                        i18nFilter("translations.contract_queue_add")
                    );
                    $state.go("^");
                },
                function (error) {
                    exceptionHelper.handleException(error);
                }
            );
        }

        function save() {
            logger.log("Save: ", vm.item);
            var itemCopy = angular.copy(vm.item);
            if (itemCopy.amount != null)
                if (itemCopy.amount.toString().includes(",")) {
                    itemCopy.amount = parseFloat(
                        itemCopy.amount.toString().replace(",", ".")
                    );
                } else {
                    itemCopy.amount.toString().concat(".00");
                }

            itemCopy.contractingPartyType = itemCopy.contractingPartyType.id;

            delete itemCopy.availableSum;
            delete itemCopy.depletedSum;

            contractsDataService.editContract(itemCopy).then(
                function (data) {
                    logger.success(
                        i18nFilter("translations.contract_queue_edit")
                    );
                    $state.go("^");
                },
                function (error) {
                    exceptionHelper.handleException(error);
                }
            );
        }

        function monetaryStringFormat(amount) {
            return parseFloat(Math.round(amount * 100) / 100)
                .toFixed(2)
                .replace(".", ",");
        }

        function searchBudgets(query) {
            if (!query || query === "") {
                vm.formData.budgets = [];
                return;
            }

            vm.formData.budgetsIsLoading = true;
            budgetsDataService.searchActiveBudgets(query).then(
                function (data) {
                    vm.formData.budgets = data;
                    vm.formData.budgetsIsLoading = false;
                },
                function (error) {
                    vm.formData.budgetsIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function onBudgetSelected() {
            vm.item.budgetAmount = vm.item.budget.budgetAmount;
            vm.item.budgetAmountInWords = vm.item.budget.budgetAmountInWords;
            vm.item.orderDate = parseDate(vm.item.budget.orderDate);
            vm.item.amount = vm.item.budget.budgetAmount;
            vm.item.orderNumber = vm.item.budget.orderNumber;
        }

        function clearBudgetSelection() {
            vm.item.budget = undefined;
            vm.item.budgetAmount = undefined;
            vm.item.budgetAmountInWords = undefined;
            vm.item.amount = undefined;
            vm.item.orderDate = null;
            vm.item.orderNumber = undefined;
        }

function orderDateValidate(options) {
            if (
                vm.item.orderDate != "" &&
                vm.item.orderDate != null &&
                dateHelper.createNewDateTimeWithoutTime(options.value) >
                    dateHelper.createNewDateTimeWithoutTime(vm.item.orderDate)
            ) {
                options.rule.message = i18nFilter(
                    "translations.general_validation_message_start_date_should_be_less_than_end_date"
                );
                return false;
            }

            return true;
        }

        function searchContractorsFromDb(query, selected) {
            if (!query || query === "") {
                vm.formData.contractorsFromDb = [];
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.contractorsFromDbIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(
                function (data) {
                    vm.formData.contractorsFromDb = data;
                    vm.formData.contractorsFromDbIsLoading = false;
                },
                function (error) {
                    vm.formData.contractorsFromDbIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function onPartyFromDbSelected() {
            vm.item.contractorFromDb =
                vm.item.contractingPartyFromDb.displayName;

            if (vm.item.address !== undefined && vm.item.address.length !== 0) {
                vm.item.address = "";
            }

            if (vm.item.contractingPartyFromDb.address != undefined) {
                vm.item.address =
                    vm.item.contractingPartyFromDb.address +
                    ", " +
                    vm.item.contractingPartyFromDb.city.postalCode +
                    " " +
                    vm.item.contractingPartyFromDb.city.name;
            }
            vm.item.taxIdFromDbUser = vm.item.contractingPartyFromDb.taxId;
            vm.item.nationalId = vm.item.contractingPartyFromDb.nationalId;
            vm.item.telephoneNumber =
                vm.item.contractingPartyFromDb.contactNumber;
            vm.item.email = vm.item.contractingPartyFromDb.email;
        }

        function clearContractingPartyFromDbSelection() {
            vm.item.contractingPartyFromDb = undefined;
        }

        function searchContractTemplates(query) {
            if (!query || query === "") {
                vm.formData.contractTemplates = [];
                return;
            }

            vm.formData.contractTemplatesIsLoading = true;
            templatesDataService.getAllActiveTemplates(query).then(
                function (data) {
                    vm.formData.contractTemplates = data;
                    vm.formData.contractTemplatesIsLoading = false;
                },
                function (error) {
                    vm.formData.contractTemplatesIsLoading = false;
                    exceptionHelper.handleException(error);
                }
            );
        }

        function clearContractTemplateSelection() {
            vm.item.contractTemplate = undefined;
        }

        function openContractRecordHelp(documentEventTrigger) {
            $uibModal.open({
                url: "/:documentEventTrigger/contracts/editContract?documentEventTrigger",
                templateUrl:
                    "app/manage/contracts/editContract/contractRecordHelp/contractRecordHelp.html",
                controller: "instructorRecordHelp",
                controllerAs: "vm",
                resolve: {
                    templateVariables: [
                        "templatesDataService",
                        "$stateParams",
                        function (templatesDataService, $stateParams) {
                            return templatesDataService.getTemplateVariables(
                                documentEventTrigger
                            );
                        },
                    ],
                },
            });
        }




        function setAmount() {
            vm.item.amount = vm.item.budgetAmount;
            vm.item.budgetAmountInWords = null;
        }

        var init = function () {
            if (vm.isEdit) {
                if (
                    vm.item.budget != null &&
                    vm.item.budget.budgetAmount != null
                ) {
                    vm.item.budgetAmount = vm.item.budget.budgetAmount;
                    vm.item.amount = vm.item.budget.budgetAmount;
                    vm.item.budgetAmountInWords =
                        vm.item.budget.budgetAmountInWords;
                }
                if (
                    vm.item.budget != null &&
                    vm.item.budget.orderNumber != null
                )
                    vm.item.orderNumber = vm.item.budget.orderNumber;
                if (vm.item.budget != null && vm.item.budget.orderDate)
                    vm.item.orderDate = vm.item.budget.orderDate;
                if (
                    vm.item.budget != null &&
                    vm.item.budget.orderDate != null
                ) {
                    vm.item.orderDate = parseDate(vm.item.budget.orderDate);
                }
                if (
                    vm.item.contractingPartyFromDb != null &&
                    vm.item.contractingPartyFromDb.taxId != null
                )
                    vm.item.taxIdFromDbUser =
                        vm.item.contractingPartyFromDb.taxId;

                if (
                    vm.item.contractingPartyFromDb != null &&
                    vm.item.contractingPartyFromDb.nationalId != null
                )
                    vm.item.nationalId =
                        vm.item.contractingPartyFromDb.nationalId;

                if (
                    vm.item.telephoneNumber != null &&
                    vm.item.telephoneNumber != null
                )
                    vm.item.telephoneNumber = vm.item.telephoneNumber;
            }
        };
        init();

        function parseDate(date) {
            if (date){
                var date = new Date(date);
    
                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
    
                return day + ". " + month + ". " + year;
            }
            else{
                return null;
            }
        }
    }
})();
