(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('registrationController', registrationController);

    registrationController.$inject = ['logger'];

    function registrationController(logger) {
        var vm = this;
        
    }
})();
