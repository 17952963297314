(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('schoolingSchedulePackApplication', schoolingSchedulePackApplication);

    schoolingSchedulePackApplication.$inject = ['$state', '$stateParams', '$timeout', 'examApplicationStatus', 'examApplicationFieldType', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', 'schoolingApplication', 'schoolingApplicationsDataService','schoolingSchedulePacksDataService', '$uibModal'];

    function schoolingSchedulePackApplication($state, $stateParams, $timeout, examApplicationStatus, examApplicationFieldType, $scope, i18nFilter, exceptionHelper, logger, schoolingApplication, schoolingApplicationsDataService, schoolingSchedulePacksDataService, $uibModal) {
        var vm = this;
        vm.done = done;
        vm.save = save;
        vm.downloadFile = downloadFile;
        vm.previousApplication = previousApplication;
        vm.nextApplication = nextApplication;
        vm.openTransferApplicationModal = openTransferApplicationModal;
        vm.scrollToTop = scrollToTop;
        vm.reject = reject;
        vm.returnToParentPageIndex = 0;

        if ($scope && $scope.$parent && $scope.$parent.vm && $scope.$parent.vm.gridInstance)
            vm.returnToParentPageIndex = $scope.$parent.vm.gridInstance.pageIndex();

        vm.formData = {
            customerPrices: [],
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons',
                readonly: schoolingApplication.readOnly
            },
            examApplicationFieldType: examApplicationFieldType,
            examApplicationStatus: examApplicationStatus,
            payer: {Person: 1, Organization: 2}
        };

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            yesOption: i18nFilter("translations.general_gui_yes"),
            noOption: i18nFilter("translations.general_gui_no"),
            nextApplication: i18nFilter("translations.examSchedulePack_application_nextApplication"),
            previousApplication: i18nFilter("translations.examSchedulePack_application_previousApplication"),
            responseDisabled: i18nFilter("translations.examSchedulePack_application_responseDisabled")
        };


        vm.schoolingApplication = schoolingApplication;
        vm.schoolingApplication.schoolingScheduleId = $stateParams.schedulePackId;
        
        scrollToTop();

        function downloadFile() {
            if (vm.schoolingApplication.paymentProofPath !== "") {
                schoolingApplicationsDataService.downloadFile(vm.schoolingApplication.paymentProofPath);
            }
        }

        function previousApplication() {
            $stateParams.applicationId = vm.schoolingApplication.previousApplicationId;
            $state.go('schoolingSchedulePacks.application', $stateParams);
            $state.reload('schoolingSchedulePacks.application', $stateParams);
        }

        function nextApplication() {
            $stateParams.applicationId = vm.schoolingApplication.nextApplicationId;
            $state.go('schoolingSchedulePacks.application', $stateParams);
            $state.reload('schoolingSchedulePacks.application', $stateParams);
        }

        function openTransferApplicationModal(applicationId) {
            if(vm.schoolingApplication.attendance === 1){
                logger.error(i18nFilter("translations.schoolingSchedulePack_transfer_user_is_attending",vm.schoolingApplication.learnerName))
                return
            }
            
            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: 'app/schoolingSchedulePacks/transferApplicationModal/transferApplicationModal.html',
                controller: 'TransferApplicationDialog',
                controllerAs: 'modalvm',
                resolve: {
                    schedulePacks: function () { return schoolingSchedulePacksDataService.getSchoolingSchedulePacksForApplicationTransfer(vm.schoolingApplication.schoolingSchedulePackId); },
                    application: function () { return vm.schoolingApplication.learnerId; },
                    parentScope: function () { return vm; }
                },
                windowClass: 'transferModal',
                backdrop: 'static',
                keyboard: false,
                size: 'md'
            });

            modalInstance.result.then(function (result) {

            }, function () {
                // when users clicks cancel button
            });
        }

        function scrollToTop() {
            $('html').animate({
                scrollTop: $('#applicationBox').offset().top
            }, 700);
        }

        function reject() {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/application/toggleApplicationRejection/toggleApplicationRejection.html',
                controller: 'toggleApplicationRejection',
                controllerAs: 'vm'
            })
                .result.then(function (result) {
                    done(vm.formData.examApplicationStatus.rejected)
                }, function () {
                });

        }

        function done(status) {
            vm.schoolingApplication.returnToPageIndex = vm.returnToParentPageIndex;

            switch (status) {
                case vm.formData.examApplicationStatus.approved:
                    schoolingApplicationsDataService.saveSchoolingApplicationResponse(angular.copy(vm.schoolingApplication), vm.formData.examApplicationStatus.approved).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                case vm.formData.examApplicationStatus.rejected:
                    schoolingApplicationsDataService.saveSchoolingApplicationResponse(angular.copy(vm.schoolingApplication), vm.formData.examApplicationStatus.rejected).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                case vm.formData.examApplicationStatus.waiting:
                    schoolingApplicationsDataService.saveSchoolingApplicationResponse(angular.copy(vm.schoolingApplication), vm.formData.examApplicationStatus.waiting).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                case vm.formData.examApplicationStatus.disenrolled:
                    schoolingApplicationsDataService.saveSchoolingApplicationResponse(angular.copy(vm.schoolingApplication), vm.formData.examApplicationStatus.disenrolled).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                case vm.formData.examApplicationStatus.pendingPersonalData:
                    schoolingApplicationsDataService.saveSchoolingApplicationResponse(angular.copy(vm.schoolingApplication), vm.formData.examApplicationStatus.pendingPersonalData).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;                  
            }

            if (vm.schoolingApplication.nextApplicationId !== null) {
                nextApplication();
            }
            else if (vm.schoolingApplication.previousApplicationId !== null) {
                previousApplication();
            } else {
                $state.go('schoolingSchedulePacks.applications', $stateParams);
            }
        }

        function save() {
            var itemCopy = angular.copy(vm.schoolingApplication);
        }
    }
})();
