(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('organizationsDataService', organizations);

    organizations.$inject = ['$http', 'logger', '$q', 'organizationLevels'];

    function organizations($http, logger, $q, organizationLevels) {
        var service = {
            getAllOrganizations: getAllOrganizations,
            getOrganizationById: getOrganizationById,
            //searchOrganizations: searchOrganizations,
            searchForOrganizations: searchForOrganizations,
            searchSubOrganizations: searchSubOrganizations,
            getAllActiveOrganizations: getAllActiveOrganizations,
            getAllActiveCompaniesAndCompanyGroups: getAllActiveCompaniesAndCompanyGroups,
            getAllActiveOrganizationsAndDepartments: getAllActiveOrganizationsAndDepartments,            
            deleteOrganization: deleteOrganization,
            syncOrganization: syncOrganization,
            editOrganization: editOrganization,
            searchOrganizationsByExternalIdOrTaxId: searchOrganizationsByExternalIdOrTaxId
        };

        return service;

        function searchOrganizationsByExternalIdOrTaxId(query) {
            return $http.get('/api/organizations/searchByExternalIdOrTaxId/' + query).then(function (data) {
                return data.data;
            }, function (data) {
                logger.log("Failed to search for external id or tax id " + query + ".");
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getAllOrganizations() {
            return $http.get('/api/organizations')
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getOrganizationById(id) {
            return $http.get('/api/organizations/' + id)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        //@Deprecated - old function that returned everything (didn't allow constrained searches)
        /*function searchOrganizations(query) {
            return $http.get('/api/organizations/search/' + query)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }*/

        //@New - replaces searchOrganizations
        function searchForOrganizations(query, organizationIds, searchType) {
            let organizationTaxIds = [];
            let search = 1; //Update to enums

            if (organizationIds != null && Array.isArray(organizationIds)) {
                organizationTaxIds = organizationIds;
            }

            if (searchType != null && Number.isInteger(searchType)) {
                search = searchType;
            }

            const simpleSearch = {
                query: query,
                taxIds: organizationTaxIds,
                searchType: search
            }
            return $http.post('/api/organizations/search/', simpleSearch)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function searchSubOrganizations(externalId) {
            return $http.get('/api/organizations/subSearch/' + externalId)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getAllActiveOrganizations() {
            return $http.get('/api/organizations/active')
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getAllActiveCompaniesAndCompanyGroups() {
            return getAllActiveOrganizations()
            .then(function (data) {
                angular.forEach(data, function (org, index) {
                    if (org.level && org.level.id === organizationLevels.organizationUnit) {
                        data.splice(index, 1);
                    }
                });
                return data;
            }, function (error) {
                $q.reject(Error);
            });
        }

        function getAllActiveOrganizationsAndDepartments() {
            return getAllActiveOrganizations()
                .then(function (data) {
                    angular.forEach(data, function (org, index) {
                        if (org.level && org.level.id === organizationLevels.companyGroup) {
                            data.splice(index, 1);
                        }
                    });
                    return data;
                }, function (error) {
                    $q.reject(Error);
                });
        }
                
        function deleteOrganization(id) {
            return $http.delete('/api/organizations/' + id)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editOrganization(organizationData) {
            return $http.post('/api/organizations/edit', organizationData)
                .then(function (data, status, headers, config) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        
        function syncOrganization(organizationId) {
            return $http.put('/api/organizations/' + organizationId + '/sync')
            .then(function (data, status, headers, config) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
