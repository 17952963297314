(function () {
    'use strict';

    angular
        .module('app.exams.themes')
        .controller('EditTheme', editTheme);

    editTheme.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'theme', 'themesDataService', 'status', 'exceptionHelper', 'logger'];

    function editTheme(decimalHelper, dateHelper, $state, i18nFilter, theme, themesDataService, status, exceptionHelper, logger) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = theme;
        vm.isEdit = $state.current.data.isEdit;
        vm.localeMessages = {}
        vm.formData = {
            status: status
        }

        vm.add = add;
        vm.save = save;
        
        function add() {
            themesDataService.addTheme(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_themes_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            themesDataService.editTheme(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_themes_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
