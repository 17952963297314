(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .factory('editEducationHelper', editEducationHelper);

    editEducationHelper.$inject = ['logger', 'educationsDataService', 'exceptionHelper', '$state', '$stateParams', 'i18nFilter', 'educationAccessMode'];

    function editEducationHelper(logger, educationsDataService, exceptionHelper, $state, $stateParams, i18nFilter, educationAccessMode) {

        var internalData = undefined;
        var wizardSteps = undefined;

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            setData: setData,
            clearData: clearData,
            addPlan: addPlan,
            editPlan: editPlan,
            removePlan: removePlan,
            isYearUnique: isYearUnique,
            done: done,
            setCodeListData: setCodeListData
            };

        return service;

        function getData() {
            if (!internalData) {
                internalData = {
                    //Set default properties
                    prices: [],
                    plans: [],
                    canOwnersCreateAssignments: true,
                    hasSequentialActivities: true,
                    certificationEnabled: true,
                    accessMode: educationAccessMode.publicAccess,
                    catalogVisibility: true,
                    active: true,
                    allActivitiesDone: true,
                    allActivitiesSuccessfullyDone: true,
                    successCriteriaPercantageEnabled: false,
                    fieldNames: [],
                    automaticApplicationConfirmation: false
                };
            }
            return internalData;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'educations.administration.edit.firstStep',
                        text: i18nFilter('translations.education_edit_wizard_first_step_title')
                    },
                    {
                        state: 'educations.administration.edit.secondStep',
                        text: i18nFilter('translations.education_edit_wizard_second_step_title')
                    },
                    {
                        state: 'educations.administration.edit.thirdStep',
                        text: i18nFilter('translations.education_edit_wizard_third_step_title')
                    },
                    {
                        state: 'educations.administration.edit.fourthStep',
                        text: i18nFilter('translations.education_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'educations.administration.edit.fifthStep',
                        text: i18nFilter('translations.education_edit_wizard_fifth_step_title')
                    },
                    {
                        state: 'educations.administration.edit.sixthStep',
                        text: i18nFilter('translations.education_edit_wizard_sixth_step_title')
                    }
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'addEducation.firstStep',
                        text: i18nFilter('translations.education_edit_wizard_first_step_title')
                    },
                    {
                        state: 'addEducation.secondStep',
                        text: i18nFilter('translations.education_edit_wizard_second_step_title')
                    },
                    {
                        state: 'addEducation.thirdStep',
                        text: i18nFilter('translations.education_edit_wizard_third_step_title')
                    },
                    {
                        state: 'addEducation.fourthStep',
                        text: i18nFilter('translations.education_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'addEducation.fifthStep',
                        text: i18nFilter('translations.education_edit_wizard_fifth_step_title')
                    },
                    {
                        state: 'addEducation.sixthStep',
                        text: i18nFilter('translations.education_edit_wizard_sixth_step_title')
                    }
                ];
            }


            return wizardSteps;
        }

        function setData(education) {
            internalData = education;
        }

        function clearData() {
            internalData = undefined;
        }

        function addPlan(plan) {
            internalData.plans.push(plan);
        }

        function editPlan(plan) {
            var found = false;
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == plan.year) {
                    internalData.plans[i] = plan;
                    found = true;
                    break;
                }
            }
            if (!found) {
                addPlan(plan);
            }
        }

        function removePlan(year) {
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == year) {
                    internalData.plans.splice(i, 1);
                    break;
                }
            }
        }

        function isYearUnique(year) {
            for (var i = 0, len = internalData.plans.length; i < len; i++) {
                if (internalData.plans[i].year == year) {
                    return false;
                }
            }
            return true;
        }

        function done(isEdit, partialSave) {
            //Call right data service method
            if (isEdit) {
                return educationsDataService.editEducation(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.education_operation_edit_success_message', additionalData.data.educationName));
                    $state.go(partialSave ? $state.current : "educations.home", {}, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
            else {
                educationsDataService.addEducation(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.education_operation_add_success_message', additionalData.educationName));
                    $state.go("classicCatalog.education");
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }


        function setCodeListData(item) {
            var item_copy = angular.copy(item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                });
            }

            if (item_copy.educationExecutionStyle == null) {
                item_copy.educationExecutionStyle = 1;
            } else if (item_copy.educationExecutionStyle.id != null){
                item_copy.educationExecutionStyle = item_copy.educationExecutionStyle.id;
            }

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id != null) {
                    price.instructorType = price.instructorType.id;
                }
            });

            return item_copy;
        }
    }
})();
