(function () {
    'use strict';

    angular
        .module('app.surveys')
        .controller('SurveyGroupQuestions', surveyGroupQuestions);

    surveyGroupQuestions.$inject = ['logger', 'questions', 'surveysDataService', '$stateParams', '$state', 'exceptionHelper', 'i18nFilter', 'dateFilter'];

    function surveyGroupQuestions(logger, questions, surveysDataService, $stateParams, $state, exceptionHelper, i18nFilter, dateFilter) {
        var vm = this;

        vm.questions = questions;
            
        vm.localeMessagess = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down')
        };

        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.findLowestOrderNo = findLowestOrderNo;
        vm.findHighestOrderNo = findHighestOrderNo;

        
        //#region table
        var columns = [
            {
                dataField: 'orderNo',
                caption: i18nFilter('translations.general_gui_order'),
                alignment: 'left',
                width: 100,
                sortOrder: 'asc',
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'name',
                caption: i18nFilter('translations.survey_question_gui_name'),
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                cellTemplate: 'questionTextTemplate'
            },
            {
                dataField: 'questionType',
                caption: i18nFilter('translations.survey_question_gui_type_of_question'),
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                width: 150,
                cellTemplate: 'questionTypeActionTemplate'
            },
            {
                dataField: 'lastUpdatedAt',
                caption: i18nFilter('translations.survey_question_gui_last_updated_at'),
                dataType: "date",
                alignment: 'left',
                sortOrder: "desc",
                allowSorting: true,
                allowFiltering: true,
                width: 150,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                }
            },
            {
                dataField: 'id',
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                allowSorting: false,
                width: 150,
                cellTemplate: 'questionsActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: questions,
            columns: columns,
            filterRow: { visible: false },
            searchPanel: { visible: true },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
        //#endregion

        function moveUp(id) {
            surveysDataService.orderUpQuestion(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_up'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function moveDown(id) {
            surveysDataService.orderDownQuestion(id).then(function (data) {
                logger.success(i18nFilter('translations.activity_queue_move_down'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function findLowestOrderNo() {
            var lowest = vm.questions[0].orderNo;
            angular.forEach(vm.questions, function (e, index) {
                if (e.orderNo < lowest) {
                    lowest = e.orderNo;
                }
            });
            return lowest;
        }

        function findHighestOrderNo() {
            var highest = vm.questions[0].orderNo;
            angular.forEach(vm.questions, function (e, index) {
                if (e.orderNo > highest) {
                    highest = e.orderNo;
                }
            });
            return highest;
        }
    }
})();

