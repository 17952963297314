(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('cancelFinishEducation', cancelFinishEducation);

    cancelFinishEducation.$inject = ['logger', 'i18nFilter', 'learnersDataService', '$scope', 'exceptionHelper', 'learners', 'schedule'];

    function cancelFinishEducation(logger, i18nFilter, learnersDataService, $scope, exceptionHelper, learners, schedule) {
        var vm = this;        

        vm.learners = learners;
        vm.schedule = schedule;


        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            var learningPlanItemIds = [];

            angular.forEach(vm.learners, function (learner, index) {                
                learningPlanItemIds.push(learner.learningPlanItemId);
            });

            learnersDataService.cancelAdministrativeFinish(learningPlanItemIds).then(function (data) {
                logger.success(i18nFilter('translations.schedule_presence_cancel_finish_education_modal_success_message'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
                $scope.$close(true);
            });
        }

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
