 
(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.eExamForEducation')
        .config(routeConfig);
   
    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('educations.administration.activities.edit.eExamForEducationSecondStep', {
                url: '/eExamForEducationSecondStep',
                templateUrl: 'app/educations/administration/activities/edit/eexamForEducation/secondStep.html',
                controller: 'EExamForEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner'],
                    isActivityEdit: true
                }
            })
            .state('educations.administration.activities.add.eExamForEducationSecondStep', {
                url: '/eExamForEducationSecondStepAdd',
                templateUrl: 'app/educations/administration/activities/edit/eexamForEducation/secondStep.html',
                controller: 'EExamForEducationSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealEducationOwner'],
                    isActivityEdit: false
                }
            });
    }
})();

