(function () {
    'use strict';

    angular
        .module('app.analytics')
        .component('excelExport', {
            templateUrl: 'app/analytics/components/excelExport.component.html',
            controller: excelExport,
            controllerAs: 'vm'
        });

    excelExport.$inject = ['$location', 'documentsDataService', 'logger', 'i18nFilter', '$uibModal'];

    function excelExport($location, documentsDataService, logger, i18nFilter, $uibModal) {
        /* jshint validthis:true */
        var vm = this;

        vm.exportData = exportData;
        vm.year = [];
        vm.selectedYear = undefined;
        vm.documentsDataService = documentsDataService;

        function exportData() {
            documentsDataService.downloadDocument('api/analytics/export/' + vm.selectedYear, 'Analitika izvoz.xlsx');
        }

        var min = 2018,
            max = new Date().getFullYear();

        for (var i = min; i <= max; i++) {
            vm.year.push(i);
        }
    }
})();
