(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.scorm')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('schoolings.administration.activities.edit.scormSecondStep', {
            url: '/scormSecondStep',
            templateUrl: 'app/schoolings/administration/activities/scorm/secondStep.html',
            controller: 'ScormSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'SchoolingOwner', 'Instructor'],
                isActivityEdit: true
            }
        })
        .state('schoolings.administration.activities.add.scormSecondStep', {
            url: '/scormSecondStep',
            templateUrl: 'app/schoolings/administration/activities/scorm/secondStep.html',
            controller: 'ScormSecondStep',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['CatalogAdmin', 'SchoolingOwner', 'Instructor'],
                isActivityEdit: false
            }
        });
    }
})();