(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('schedulePacks', schedulePacks);

    schedulePacks.$inject = ['emptyGuid', 'educations', 'programs', 'dateFilter', 'i18nFilter', 'logger', 'schedulePacksDataService', 'schedulePacksSearchOptions', 'schedulePacksUnlockedSearchOptions','$stateParams', '$state', '$uibModal', 'status'];

    function schedulePacks(emptyGuid, educations, programs, dateFilter, i18nFilter, logger, schedulePacksDataService, schedulePacksSearchOptions, schedulePacksUnlockedSearchOptions, $stateParams, $state, $uibModal, status) {
        /* jshint validthis:true */
        var vm = this;

        vm.formData = {
            schedulePackEntities: [],
            searchOptions: {
                futureSchedulePacks: schedulePacksSearchOptions.future,
                lastYearSchedulePacks: schedulePacksSearchOptions.lastYear,
                allSchedulePacks: schedulePacksSearchOptions.all
            },
            searchOptionsUnlocked: {
                mine: schedulePacksUnlockedSearchOptions.mine,
                all: schedulePacksUnlockedSearchOptions.all
            },
            emptyGuid: emptyGuid
        };
        vm.searchOptionsUnlockedAll = false;
        vm.refreshTable = refreshTable;
        vm.refreshTableUnlocked = refreshTableUnlocked;
        vm.searchOption = vm.formData.searchOptions.futureSchedulePacks;
        vm.searchOptionUnlocked = vm.formData.searchOptionsUnlocked.mine;
        vm.addSchedulePack = addSchedulePack;
        vm.editSchedulePack = editSchedulePack;
        vm.editSchedulePack2 = editSchedulePack2;
        vm.openLearnersBillModal = openLearnersBillModal;
        vm.openCancelSchedulePackModal = openCancelSchedulePackModal;
        vm.isSchedulePackState = isSchedulePackState;

        angular.forEach(educations, function (education, index) {
            education.name += ' (' + i18nFilter('translations.general_gui_education') + ')';
            education.type = 'education';
            vm.formData.schedulePackEntities.push(education);
        });

        angular.forEach(programs, function (program, index) {
            program.name += ' (' + i18nFilter('translations.general_gui_program') + ')';
            program.type = 'program';
            vm.formData.schedulePackEntities.push(program);
        });

        if ($stateParams.entityId) {
            vm.schedulePackEntity = _.find(vm.formData.schedulePackEntities, function (entity) { return entity.id === $stateParams.entityId; });
        }

        vm.localeMessages = {
            edit: i18nFilter('translations.general_action_edit'),
            details: i18nFilter('translations.general_gui_details'),
            records: i18nFilter('translations.navigation_working_time_documents'),
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            hasDifferenceBetweenEnrolledUsers: i18nFilter('translations.schedule_packs_gui_has_difference_between_enrolled_users'),
            learnersBill: i18nFilter('translations.schedule_gui_action_learners_bill'),
            approveApplications: i18nFilter('translations.exam_schedules_action_confirm_applications'),
            cancelSchedulePack: i18nFilter('translations.education_schedules_action_cancel_schedulePack')
        };

        function refreshTable() {
            if (vm.schedulePackEntity && vm.schedulePackEntity.id) {
                $stateParams.entityId = vm.schedulePackEntity.id;
                if ($state.current.name !== "schedulePacks") {
                    $state.go('schedulePacks', $stateParams);
                }
                else {
                    $state.go('schedulePacks', $stateParams, { reload: false, notify: false });
                }
                vm.gridInstance.refresh();
            }
        }

        function refreshTableUnlocked() {              
            if ($state.current.name !== "schedulePacks") {
                $state.go('schedulePacks', $stateParams);
            }
            else {
                $state.go('schedulePacks', $stateParams, { reload: false, notify: false });
            }
            vm.gridInstanceUnlocked.refresh();
        }

        vm.options = {
            startTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            endTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            visibility: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            orderNo: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            organizationName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            capacity: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'startTime',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                allowFiltering: false,
                sortOrder: 'asc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'endTime',
                dataType: "date",
                alignment: 'left',
                width: 150,
                allowSorting: true,
                allowFiltering: false,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                caption: i18nFilter('translations.schedule_gui_label_visibility'),
                alignment: 'center',
                allowSorting: true,
                width: 150,
                cellTemplate: 'schedulePackVisibilityTemplate'
            },
            {
                caption: i18nFilter('translations.general_gui_ordinal_number'),
                alignment: 'center',
                allowSorting: false,
                width: 150,
                cellTemplate: 'orderNoTemplate'
            },
            {
                dataField: 'organizationName',
                caption: i18nFilter('translations.schedule_gui_reserved_for'),
                alignment: 'left',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.schedule_packs_gui_capacity'),
                alignment: 'center',
                allowSorting: false,
                width: 250,
                cellTemplate: 'capacityTemplate'
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                width: 200,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];

        var columnsUnlocked = [
            {
                dataField: 'name',
                dataType: "string",
                alignment: 'left',
                //width: 250,
                allowSorting: true,
                //sortOrder: 'desc',
                caption: i18nFilter('translations.general_gui_educations_and_programs')
            },
            {
                dataField: 'startTime',
                dataType: "date",
                alignment: 'left',
                width: 125,
                allowSorting: true,
                sortOrder: 'asc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'endTime',
                dataType: "date",
                alignment: 'left',
                width: 125,
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                caption: i18nFilter('translations.schedule_gui_label_visibility'),
                alignment: 'center',
                allowSorting: true,
                width: 125,
                cellTemplate: 'schedulePackVisibilityTemplate',
                calculateCellValue: function (rowData) {
                    if (rowData.visibility === 'Public') {
                        return i18nFilter('translations.schedule_gui_label_visibility_public');
                    }
                    return i18nFilter('translations.schedule_gui_label_visibility_private');
                }
            },
            {
                dataField: 'organizationName',
                caption: i18nFilter('translations.schedule_gui_reserved_for'),
                alignment: 'left',
                //width: 200,
                allowSorting: true,
                allowFiltering: true
            },
            {
                caption: i18nFilter('translations.schedule_packs_gui_capacity'),
                alignment: 'center',
                allowSorting: false,
                width: 175,
                cellTemplate: 'capacityTemplate'
            },
            {
                dataField: 'coordinators',
                allowSorting: true,
                allowFiltering: true,
                //width: 200,
                alignment: 'left',
                cellTemplate: 'actionTemplate',
                headerCellTemplate: 'actionHeaderTemplate',
                calculateCellValue: function (rowData) {
                    return rowData.coordinators;
                }
            }
        ];

        function onRowPrepared(e) {
            if (e.rowType !== "data")
                return;

            if (e.data.status == status.cancelled)
                e.rowElement[0].classList.add("disabledSchedulePack");
        }

        function simpleFilterExtruder(element) {
            if (element[0] === "startTime") {
                vm.options.startTime.filterType1 = element[1];
                vm.options.startTime.searchText1 = element[2];
            }
            if (element[0] === "endTime") {
                vm.options.endTime.filterType1 = element[1];
                vm.options.endTime.searchText1 = element[2];
            }
            if (element[0] === "visibility") {
                vm.options.visibility.filterType1 = element[1];
                vm.options.visibility.searchText1 = element[2];
            }
            if (element[0] === "orderNo") {
                vm.options.capacity.filterType1 = element[1];
                vm.options.capacity.searchText1 = element[2];
            }
            if (element[0] === "organizationName") {
                vm.options.organizationName.filterType1 = element[1];
                vm.options.organizationName.searchText1 = element[2];
            }
            if (element[0] === "capacity") {
                vm.options.capacity.filterType1 = element[1];
                vm.options.capacity.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'startTime' && element[2][0] === 'startTime') {
                vm.options.startTime.filterType1 = element[0][1];
                vm.options.startTime.searchText1 = element[0][2];
                vm.options.startTime.filterType2 = element[2][1];
                vm.options.startTime.searchText2 = element[2][2];
            }
            if (element[0][0] === 'endTime' && element[2][0] === 'endTime') {
                vm.options.endTime.filterType1 = element[0][1];
                vm.options.endTime.searchText1 = element[0][2];
                vm.options.endTime.filterType2 = element[2][1];
                vm.options.endTime.searchText2 = element[2][2];
            }
        }

        function clearFilter() {

            vm.options.startTime.filterType1 = undefined;
            vm.options.startTime.searchText1 = undefined;
            vm.options.startTime.filterType2 = undefined;
            vm.options.startTime.searchText2 = undefined;

            vm.options.endTime.filterType1 = undefined;
            vm.options.endTime.searchText1 = undefined;
            vm.options.endTime.filterType2 = undefined;
            vm.options.endTime.searchText2 = undefined;

            vm.options.visibility.filterType1 = undefined;
            vm.options.visibility.searchText1 = undefined;
            vm.options.visibility.filterType2 = undefined;
            vm.options.visibility.searchText2 = undefined;

            vm.options.orderNo.filterType1 = undefined;
            vm.options.orderNo.searchText1 = undefined;
            vm.options.orderNo.filterType2 = undefined;
            vm.options.orderNo.searchText2 = undefined;

            vm.options.organizationName.filterType1 = undefined;
            vm.options.organizationName.searchText1 = undefined;
            vm.options.organizationName.filterType2 = undefined;
            vm.options.organizationName.searchText2 = undefined;

            vm.options.capacity.filterType1 = undefined;
            vm.options.capacity.searchText1 = undefined;
            vm.options.capacity.filterType2 = undefined;
            vm.options.capacity.searchText2 = undefined;
        }

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                        //Filter za datume
                        else {
                            dateFilterExtruder(loadOptions.filter);
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterExtruder(filterElement);
                                }
                                //Filter za datume
                                else {
                                    dateFilterExtruder(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.startTime.sortType = undefined;
                    vm.options.endTime.sortType = undefined;
                    vm.options.visibility.sortType = undefined;
                    vm.options.orderNo.sortType = undefined;
                    vm.options.organizationName.sortType = undefined;
                    vm.options.capacity.sortType = undefined;

                    if (loadOptions.sort[0].selector === "startTime") {
                        vm.options.startTime.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "endTime") {
                        vm.options.endTime.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "visibility") {
                        vm.options.visibility.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "orderNo") {
                        vm.options.orderNo.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "organizationName") {
                        vm.options.organizationName.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector === "capacity") {
                        vm.options.capacity.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken
                vm.options.searchOption = vm.searchOption;

                if (vm.schedulePackEntity.id) {
                    vm.options.entityId = vm.schedulePackEntity.id;
                }

                schedulePacksDataService.getAllSchedulePacksAdvanced(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.schedule_packs_error_message_fetching_schedule_packs'));
                    d.reject();
                });

                return d.promise();
            }
        };        

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            onRowPrepared: onRowPrepared,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            columnAutoWidth: true,
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        vm.unlockedDataGridOptions = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/SchedulePacksOData/GetSchedulePacks",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        var coordinatorOnly = vm.searchOptionUnlocked == vm.formData.searchOptionsUnlocked.mine;
                        e.url += "(coordinatorOnly=" + coordinatorOnly 
                        e.url += ",all=" + vm.searchOptionsUnlockedAll + ")";

                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            };
                        }
                    }
                }
            }),
            columns: columnsUnlocked,
            onRowPrepared: onRowPrepared,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: undefined,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstanceUnlocked = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            columnAutoWidth: true,
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            },
            customizeColumns: function (columns) {
                $.each(columns, function (index, column) {
                    var defaultCalculateFilterExpression = column.calculateFilterExpression;
                    column.calculateFilterExpression = function (value, selectedFilterOperation) {
                        if (this.dataType === 'string' && !this.lookup && value) {
                            return ['tolower(' + this.dataField + ')',
                            selectedFilterOperation || 'contains',
                            value.toLowerCase()]
                        }
                        else {
                            return defaultCalculateFilterExpression.apply(this, arguments);
                        }
                    }
                });
            }
        };

        function addSchedulePack() {
            if (vm.schedulePackEntity.type == 'education') {
                $stateParams.educationId = vm.schedulePackEntity.id;
                $state.go('schedulePacks.educationSchedulePackAdd', $stateParams);
            } else if (vm.schedulePackEntity.type == 'program') {
                $stateParams.programId = vm.schedulePackEntity.id;
                $state.go('schedulePacks.programSchedulePackAdd', $stateParams);
            }
        }

        function editSchedulePack(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            if (vm.schedulePackEntity.type == 'education') {
                $stateParams.educationId = vm.schedulePackEntity.id;
                $state.go('schedulePacks.educationSchedulePackEdit', $stateParams);
            } else if (vm.schedulePackEntity.type == 'program') {
                $stateParams.programId = vm.schedulePackEntity.id;
                $state.go('schedulePacks.programSchedulePackEdit', $stateParams);
            }
        }

        function editSchedulePack2(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            if (schedulePack.type == 'Education') {
                $stateParams.educationId = schedulePack.educationId;
                $state.go('schedulePacks.educationSchedulePackEdit', $stateParams);
            } else if (schedulePack.type == 'Program') {
                $stateParams.programId = schedulePack.programId;
                $state.go('schedulePacks.programSchedulePackEdit', $stateParams);
            }
        }

        function openLearnersBillModal(schedulePackId) {
            $uibModal.open({
                templateUrl: 'app/schedulePacks/learnersBill/learnersBill.html',
                controller: 'learnersBill',
                controllerAs: 'vm',
                resolve: {
                    schedulePackId: function () {
                        return schedulePackId;
                    }
                }
            });
        }

        function openCancelSchedulePackModal(schedulePack) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/schedulePacks/cancelSchedulePack/cancelSchedulePack.html',
                controller: 'cancelSchedulePack',
                controllerAs: 'vm',
                resolve: {
                    schedulePack: function () {
                        return schedulePack;
                    }
                }
            });

            modalInstance.result.then(function (data) {
                //confirm callback
                console.info('canceling schedulePack ' + data.schedulePackId);
                schedulePacksDataService.cancelSchedulePack(data).then((result) => {
                    $state.go('schedulePacks', {}, { reload: true });
                    logger.success(i18nFilter('translations.education_schedules_cancel_success_notification_message'));
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });

            }, function () {
                //close callback
                console.info('cancel SchedulePack modal closed');
            });
        }

        function isSchedulePackState() {
            return $state.current.name === "schedulePacks";
        }
    }
})();
