(function () {
    "use strict";

    angular.module("app.analytics").component("learningRecords", {
        templateUrl:
            "app/analytics/components/learningRecords/learningRecords.component.html",
        controller: learningRecords,
        controllerAs: "vm",
    });

    learningRecords.$inject = [
        "$location",
        "logger",
        "i18nFilter",
        "analyticsDataService",
        "dateFilter",
        "documentsDataService",
        "exceptionHelper",
    ];

    function learningRecords(
        $location,
        logger,
        i18nFilter,
        analyticsDataService,
        dateFilter,
        documentsDataService,
        exceptionHelper
    ) {
        /* jshint validthis:true */
        var vm = this;
        vm.canDownload = false;
        vm.downloadCertificate = downloadCertificate;

        vm.options = {
            programOrEducationName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            budgetName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            startTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            endTime: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            learnerName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            learnerLastName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            learnerNationalId: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            email: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            payer: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            certificateNumber: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined,
            },
            take: undefined,
            skip: undefined,
        };

        var columns = [
            {
                dataField: "programOrEducationName",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_learning_and_program"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "budgetName",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_budget"
                ),
                dataType: "string",
                alignment: "left",
                allowSorting: true,
                allowFiltering: true,
            },
            {
                dataField: "startTime",
                caption: i18nFilter("translations.general_gui_start"),
                dataType: "date",
                alignment: "left",
                width: 125,
                filterOperations: ["<", "<=", "=", ">", ">=", "between"],
                allowFiltering: true,
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, "shortDate");
                },
            },
            {
                dataField: "endTime",
                caption: i18nFilter("translations.general_gui_end"),
                dataType: "date",
                alignment: "left",
                width: 125,
                filterOperations: ["<", "<=", "=", ">", ">=", "between"],
                allowFiltering: true,
                allowSorting: true,
                customizeText: function (args) {
                    return dateFilter(args.value, "shortDate");
                },
            },
            {
                dataField: "learnerName",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_first_name"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "learnerLastName",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_last_name"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "learnerNationalId",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_nationalId"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "email",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_email"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "payer",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_payer"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                dataField: "certificateNumber",
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_certificate_number"
                ),
                dataType: "string",
                alignment: "left",
                allowFiltering: true,
                allowSorting: true,
            },
            {
                caption: i18nFilter(
                    "translations.analytics_gui_learning_records_table_header_title_action"
                ),
                allowFiltering: false,
                allowSorting: false,
                alignment: "center",
                width: 65,
                cellTemplate: "actionTemplate",
            },
        ];

        function simpleFilterExtruder(element) {
            if (element[0] === "programOrEducationName") {
                vm.options.programOrEducationName.filterType1 = element[1];
                vm.options.programOrEducationName.searchText1 = element[2];
            }
            if (element[0] === "budgetName") {
                vm.options.budgetName.filterType1 = element[1];
                vm.options.budgetName.searchText1 = element[2];
            }
            if (element[0] == "startTime") {
                vm.options.startTime.filterType1 = element[1];
                vm.options.startTime.searchText1 = element[2];
            }
            if (element[0] == "endTime") {
                vm.options.endTime.filterType1 = element[1];
                vm.options.endTime.searchText1 = element[2];
            }
            if (element[0] === "learnerName") {
                vm.options.learnerName.filterType1 = element[1];
                vm.options.learnerName.searchText1 = element[2];
            }
            if (element[0] === "learnerLastName") {
                vm.options.learnerLastName.filterType1 = element[1];
                vm.options.learnerLastName.searchText1 = element[2];
            }
            if (element[0] === "learnerNationalId") {
                vm.options.learnerNationalId.filterType1 = element[1];
                vm.options.learnerNationalId.searchText1 = element[2];
            }
            if (element[0] === "email") {
                vm.options.email.filterType1 = element[1];
                vm.options.email.searchText1 = element[2];
            }
            if (element[0] === "payer") {
                vm.options.payer.filterType1 = element[1];
                vm.options.payer.searchText1 = element[2];
            }
            if (element[0] === "certificateNumber") {
                vm.options.certificateNumber.filterType1 = element[1];
                vm.options.certificateNumber.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (
                element[0][0] === "startTime" &&
                element[2][0] === "startTime"
            ) {
                vm.options.startTime.filterType1 = element[0][1];
                vm.options.startTime.searchText1 = element[0][2];
                vm.options.startTime.filterType2 = element[2][1];
                vm.options.startTime.searchText2 = element[2][2];
            }
            if (element[0][0] === "endTime" && element[2][0] === "endTime") {
                vm.options.endTime.filterType1 = element[0][1];
                vm.options.endTime.searchText1 = element[0][2];
                vm.options.endTime.filterType2 = element[2][1];
                vm.options.endTime.searchText2 = element[2][2];
            }
        }

        function clearFilter() {
            vm.options.programOrEducationName.filterType1 = undefined;
            vm.options.programOrEducationName.searchText1 = undefined;
            vm.options.programOrEducationName.filterType2 = undefined;
            vm.options.programOrEducationName.searchText2 = undefined;

            vm.options.budgetName.filterType1 = undefined;
            vm.options.budgetName.searchText1 = undefined;
            vm.options.budgetName.filterType2 = undefined;
            vm.options.budgetName.searchText2 = undefined;

            vm.options.startTime.filterType1 = undefined;
            vm.options.startTime.searchText1 = undefined;
            vm.options.startTime.filterType2 = undefined;
            vm.options.startTime.searchText2 = undefined;

            vm.options.endTime.filterType1 = undefined;
            vm.options.endTime.searchText1 = undefined;
            vm.options.endTime.filterType2 = undefined;
            vm.options.endTime.searchText2 = undefined;

            vm.options.learnerName.filterType1 = undefined;
            vm.options.learnerName.searchText1 = undefined;
            vm.options.learnerName.filterType2 = undefined;
            vm.options.learnerName.searchText2 = undefined;

            vm.options.learnerLastName.filterType1 = undefined;
            vm.options.learnerLastName.searchText1 = undefined;
            vm.options.learnerLastName.filterType2 = undefined;
            vm.options.learnerLastName.searchText2 = undefined;

            vm.options.learnerNationalId.filterType1 = undefined;
            vm.options.learnerNationalId.searchText1 = undefined;
            vm.options.learnerNationalId.filterType2 = undefined;
            vm.options.learnerNationalId.searchText2 = undefined;

            vm.options.email.filterType1 = undefined;
            vm.options.email.searchText1 = undefined;
            vm.options.email.filterType2 = undefined;
            vm.options.email.searchText2 = undefined;

            vm.options.payer.filterType1 = undefined;
            vm.options.payer.searchText1 = undefined;
            vm.options.payer.filterType2 = undefined;
            vm.options.payer.searchText2 = undefined;

            vm.options.certificateNumber.filterType1 = undefined;
            vm.options.certificateNumber.searchText1 = undefined;
            vm.options.certificateNumber.filterType2 = undefined;
            vm.options.certificateNumber.searchText2 = undefined;
        }

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Check for simple filter (string & integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                        //Filter for dates
                        else {
                            dateFilterExtruder(loadOptions.filter);
                        }
                    } else {
                        angular.forEach(
                            loadOptions.filter,
                            function (filterElement, filterIndex) {
                                //if there is more than 1 filter
                                if (filterElement.constructor === Array) {
                                    //Check for simple filter (string & integer)
                                    if (
                                        filterElement[0].constructor !== Array
                                    ) {
                                        simpleFilterExtruder(filterElement);
                                    }
                                    //Filter for dates
                                    else {
                                        dateFilterExtruder(filterElement);
                                    }
                                }
                            }
                        );
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.programOrEducationName.sortType = undefined;
                    vm.options.budgetName.sortType = undefined;
                    vm.options.startTime.sortType = undefined;
                    vm.options.endTime.sortType = undefined;
                    vm.options.learnerName.sortType = undefined;
                    vm.options.learnerLastName.sortType = undefined;
                    vm.options.learnerNationalId.sortType = undefined;
                    vm.options.email.sortType = undefined;
                    vm.options.payer.sortType = undefined;
                    vm.options.certificateNumber.sortType = undefined;

                    if (
                        loadOptions.sort[0].selector ===
                        "programOrEducationName"
                    ) {
                        vm.options.programOrEducationName.sortType = loadOptions
                            .sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "budgetName") {
                        vm.options.budgetName.sortType = loadOptions.sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "startTime") {
                        vm.options.startTime.sortType = loadOptions.sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "endTime") {
                        vm.options.endTime.sortType = loadOptions.sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "learnerName") {
                        vm.options.learnerName.sortType = loadOptions.sort[0]
                            .desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "learnerLastName") {
                        vm.options.learnerLastName.sortType = loadOptions
                            .sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "learnerNationalId") {
                        vm.options.learnerNationalId.sortType = loadOptions
                            .sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "email") {
                        vm.options.email.sortType = loadOptions.sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "payer") {
                        vm.options.payer.sortType = loadOptions.sort[0].desc
                            ? "desc"
                            : "asc";
                    }

                    if (loadOptions.sort[0].selector === "certificateNumber") {
                        vm.options.certificateNumber.sortType = loadOptions
                            .sort[0].desc
                            ? "desc"
                            : "asc";
                    }
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken
                vm.options.searchOption = vm.searchOption;

                analyticsDataService
                    .getLearningRecords(angular.copy(vm.options))
                    .then(
                        function (data) {
                            d.resolve(data.data, {
                                totalCount: data.totalItems,
                            });
                        },
                        function (error) {
                            logger.error(
                                i18nFilter(
                                    "translations.schedule_packs_error_message_fetching_schedule_packs"
                                )
                            );
                            d.reject();
                        }
                    );

                return d.promise();
            },
        };

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true,
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true,
            },
            columnAutoWidth: true,
            wordWrapEnabled: true,
            sorting: {
                mode: "single",
            },
        };

        function downloadCertificate(documentId) {
            if (documentId != undefined) {
                documentsDataService
                    .downloadDocument(
                        "api/documentsDb/download/" + documentId,
                        "Potrdilo-" + documentId + ".pdf"
                    )
                    .then(
                        function (data) {},
                        function (error) {
                            error = {
                                exception: {
                                    additionalData: {},
                                    result: {
                                        exceptionCode: -90007,
                                        operationCode: 0,
                                    },
                                },
                                status: 400,
                            };
                            exceptionHelper.handleException(error);
                        }
                    );
            }
        }
    }
})();
