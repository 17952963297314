(function () {
    'use strict';

    angular
        .module('app.manage')
        .controller('Manage', manage);

    manage.$inject = ['$state', 'rolesDataService', 'logger', '$uibModal', 'tenantInfo', 'newAdminPortalUrl'];

    function manage($state, rolesDataService, logger, $uibModal, tenantInfo, newAdminPortalUrl) {
        var vm = this;
        vm.showTenants = rolesDataService.isUserInRoles($state.get('tenants').data.roles);
        vm.showTemplates = rolesDataService.isUserInRoles($state.get('templates').data.roles);
        vm.showLearningPeriods = rolesDataService.isUserInRoles($state.get('learningPeriods').data.roles);
        vm.showOrganizations = rolesDataService.isUserInRoles($state.get('organizations').data.roles);
        vm.showLocations = rolesDataService.isUserInRoles($state.get('locations').data.roles);
        vm.showUsers = rolesDataService.isUserInRoles($state.get('users').data.roles);
        vm.showCategories = rolesDataService.isUserInRoles($state.get('categories').data.roles);        
        vm.showCountries = rolesDataService.isUserInRoles($state.get('countries').data.roles);;
        vm.showCities = rolesDataService.isUserInRoles($state.get('cities').data.roles);;
        vm.showNotificationsSettings = rolesDataService.isUserInRoles($state.get('notifications').data.roles);
        vm.showLogs = rolesDataService.isUserInRoles($state.get('logs').data.roles);
        vm.showPromotedEntities = rolesDataService.isUserInRoles($state.get('promotedEntities').data.roles);
        vm.showBudgets = rolesDataService.isUserInRoles($state.get('budgets').data.roles);
        vm.showConctracts = rolesDataService.isUserInRoles($state.get('contracts').data.roles);
        vm.showCompetences = rolesDataService.isUserInRoles($state.get('competences').data.roles);
        vm.showSchedulePacks = rolesDataService.isUserInRoles($state.get('schedulePacks').data.roles);
        vm.showCatalogues = rolesDataService.isUserInRoles($state.get('catalogues').data.roles);
        vm.showThemes = rolesDataService.isUserInRoles($state.get('themes').data.roles);
        vm.showExamWorkplace = rolesDataService.isUserInRoles($state.get('workplace').data.roles);
        vm.showTargetGroups = rolesDataService.isUserInRoles($state.get('targetGroups').data.roles);
        vm.showTilesConfiguration = rolesDataService.isUserInRoles($state.get('tilesConfiguration').data.roles);
        vm.showProcessQueue = rolesDataService.isUserInRoles($state.get('queue').data.roles);
        vm.showCertificateTable = rolesDataService.isUserInRoles($state.get('certificateSigner').data.roles);
        //vm.showUrgentMessagesConfiguration = rolesDataService.isUserInRoles($state.get('urgentMessagesConfiguration').data.roles);
        vm.showRecords = rolesDataService.isUserInRoles($state.get('personRecords').data.roles);
        vm.showProficiencyExamBudgets = rolesDataService.isUserInRoles($state.get('proficiencyExamBudgets').data.roles);
        vm.showProficiencyExamLevels = rolesDataService.isUserInRoles($state.get('proficiencyExamLevels').data.roles);
        vm.tenantInfo = tenantInfo;
        vm.truncateOptions = {
            watch: 'window',
            wrap: 'letter'
        };
        vm.tenantConfigurationUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/tenants/' + tenantInfo.info.resourcesKey;
        vm.templatesUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/templates';
        vm.organizationsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/organizations';
        vm.proficiencyExamBudgetsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/proficiencyExams/budgets';
        vm.proficiencyExamLevelsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/proficiencyExams/levels';
        vm.usersNewAdminUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/users';
        vm.locationsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/locations';
        vm.categoriesUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/categories';
        vm.citiesUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/cities';
        vm.logsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/logs';
        vm.releaseHistoryUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/releaseHistory';
        vm.targetGroupsUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/educations/targetGroups';
        vm.tileConfigurationUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/tilesConfiguration';
        vm.urgentMessagesUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/urgentMessages';
        vm.processQueueUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/processQueue';
        vm.notificationsNewAdminUrl = newAdminPortalUrl[tenantInfo.info.resourcesKey] + 'manage/systemSettings/emailNotifications';
    }
})();
