(function () {
    'use strict';

    angular
        .module('app.base')
        .controller('base', base);

    base.$inject = ['$location', 'rolesDataService', 'logger', '$state', 'tmhDynamicLocale', 'locale', 'appLocaleSupported', 'localeEvents', '$sessionStorage', '$localStorage', '$scope', 'notificationService', 'i18nFilter', 'toastr', 'documentsDataService', 'eFormsUrl', 'workerStatus', 'tenantDataService', 'tabs', 'applicationTabs', 'newAdminPortalUrl', '$interval', 'adalAuthenticationService', '$uibModal', '$window'];

    function base($location, rolesDataService, logger, $state, tmhDynamicLocale, locale, appLocaleSupported, localeEvents, $sessionStorage, $localStorage, $scope, notificationService, i18nFilter, toastr, documentsDataService, eFormsUrl, workerStatus, tenantDataService, tabs, applicationTabs, newAdminPortalUrl, $interval, adalAuthenticationService, $uibModal, $window) {
        var vm = this;
        //#region Navigation
        vm.showCatalog = rolesDataService.isUserInRoles($state.get('classicCatalog').data.roles);
        vm.showReports = rolesDataService.isUserInRoles($state.get('reports').data.roles) && tabs.filter(t => { return t.code == applicationTabs.reports })[0].showTab;
        vm.showManage = rolesDataService.isUserInRoles($state.get('manage').data.roles);
        vm.showSchedulePacks = rolesDataService.isUserInRoles($state.get('schedulePacks').data.roles) && tabs.filter(t => { return t.code == applicationTabs.educationSchedulePack })[0].showTab;
        vm.showProficiencyExamSchedules = rolesDataService.isUserInRoles($state.get('proficiencyExamSchedules').data.roles) && tabs.filter(t => { return t.code == applicationTabs.proficiencyExamsSchedulePack })[0].showTab;
        vm.showExamSchedulePacks = rolesDataService.isUserInRoles($state.get('examSchedulePacks').data.roles) && tabs.filter(t => { return t.code == applicationTabs.examSchedulePack })[0].showTab;
        vm.showSchoolingSchedulePacks = rolesDataService.isUserInRoles($state.get('schoolingSchedulePacks').data.roles) && tabs.filter(t => { return t.code == applicationTabs.schoolingSchedulePack })[0].showTab;
        vm.showEForm = rolesDataService.isUserInRoles(['Learner']) && tabs.filter(t => { return t.code == applicationTabs.eForms })[0].showTab;
        vm.eLearningEducationEnrollments = rolesDataService.isUserInRoles($state.get('eLearningEducationEnrollments').data.roles) && tabs.filter(t => { return t.code == applicationTabs.onlineEducations })[0].showTab;
        vm.showAccessories = rolesDataService.isUserInRoles($state.get('accessories').data.roles) && tabs.filter(t => { return t.code == applicationTabs.extras })[0].showTab;
        vm.showAnalytics = rolesDataService.isUserInRoles($state.get('analytics').data.roles) && tabs.filter(t => { return t.code == applicationTabs.analytics })[0].showTab;

        vm.renewSession = renewSession;

        vm.isActive = isActive;

        if ($sessionStorage.userInfo.tenantResourceKey == "eua") {
            vm.tenantEUA = true;
        } else {
            vm.tenantEUA = false;
        }

        vm.newAdminPortalUrl = newAdminPortalUrl[$sessionStorage.userInfo.tenantResourceKey] + 'schedules/proficiencyExams';

        function isActive(viewLocation) {
            if (viewLocation === '/') {
                return viewLocation === $location.path();
            }
            return $location.path().startsWith(viewLocation);
        };
        //#endregion

        //#region EForms
        vm.openEForms = openEForms;

        function openEForms() {
            var tenantId = $sessionStorage.userInfo.tenantId;
            documentsDataService.openInNewTab(eFormsUrl[tenantId]);
        }
        //#endregion

        //#region Localization
        var firstTime = true;

        vm.formData = {
            appLocaleSupported: appLocaleSupported,
            workerStatus: workerStatus
        };
                
        if ($localStorage.locale) {
            loadTinymceLocaleFile($localStorage.locale.tinymce);
        }



        function loadTinymceLocaleFile(fileName) {
            var type = 'text/javascript';
            var charset = 'utf-8';

            var url = '/assets/js/tinymce/js/tinymce/langs/' + fileName + '.js';

            if (fileName) {
                var heads = document.getElementsByTagName("head");
                if (heads && heads.length) {
                    var head = heads[0];
                    if (head) {
                        var script = document.createElement('script');
                        script.setAttribute('src', url);
                        script.setAttribute('type', type);
                        if (charset) script.setAttribute('charset', charset);
                        head.appendChild(script);
                    }
                }

                return script;
            }
        }
               
        //#endregion

        //#region Notifications
        notificationService.activate();

        vm.notificationDataForSignalR = notificationService.getNotificationDataForSignalR();

        vm.toggleSidebar = notificationService.toggleSidebar;
        vm.actionClicked = actionClicked;
        vm.updateNotificationBarStyle = updateNotificationBarStyle;
        
        // Anywhere you click, except .right-sidebar and .count-info, 
        // will trigger toggleSidebar(), if the side bar is open
        $(document).click(function (e) {
            if (vm.notificationDataForSignalR.isSidebarOpen) {
                vm.toggleSidebar();
                vm.updateNotificationBarStyle();
            }
        });

        $('.right-sidebar').click(function (e) {
            e.stopPropagation();
        });

        $('.count-info').click(function (e) {
            e.stopPropagation();
        });

        function updateNotificationBarStyle() {
            vm.notificationDataForSignalR = notificationService.getNotificationDataForSignalR();
            var right = vm.notificationDataForSignalR.isSidebarOpen == true ? "0px" : "-350px";
            $(".right-sidebar").css("right", right);
        }

        function actionClicked(action) {
            $state.go(action.route, action.params, { reload: true });
            vm.toggleSidebar();
        }

        //#endregion

        //#region Session expiration
        vm.sessionExpirationDate = { minutes: 0 };
        calculateSessionExpirationDate();

        var calculateSessionExpiration = $interval(calculateSessionExpirationDate, 60000);

        function renewSession() {

            //adalAuthenticationService.clearCache()
            //adalAuthenticationService.acquireToken( ,function (token) {
            //})
            localStorage.setItem('adal.access.token.key' + adalAuthenticationService.config.loginResource, '');
            localStorage.setItem('adal.expiration.key' + adalAuthenticationService.config.loginResource, 0);

            //adalAuthenticationService.clearCacheForResource(adalAuthenticationService.config.loginResource)
            //adalAuthenticationService.acquireToken(adalAuthenticationService.config.loginResource, function (token) {
            //    debugger;
            //})

            $window.location.reload();
        }

        function openRenewSessionDialog() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/renewSession/renewSession.html',
                controller: 'renewSessionModal',
                controllerAs: 'modalvm',
                resolve: {
                }
            });

            modalInstance.result.then(function (data) {
                //confirm callback
                renewSession();

            }, function () {
                //close callback
            });
        }

        function getSessionExpirationDate() {
            let tokeyKey = localStorage['adal.token.keys'].split('|')[0]; // take first token key
            let expirationKey = 'adal.expiration.key' + tokeyKey;
            let expirationDateTime = localStorage[expirationKey];

            return new Date(expirationDateTime * 1000);
        }

        function calculateSessionExpirationDate() {
            let untilSessionExpiration = getSessionExpirationDate() - new Date();
            vm.sessionExpirationDate.minutes = Math.round(untilSessionExpiration / 1000 / 60);

            // open dialog to renew session 15 min before session expiration
            if (vm.sessionExpirationDate.minutes == 15) {
                openRenewSessionDialog();
            }
        }

        $scope.$on('$destroy', function () {
            // Make sure that the interval is destroyed too
            if (angular.isDefined(calculateSessionExpiration))
                $interval.cancel(calculateSessionExpiration);
        });
        //#endregion
    }
})();
