(function () {
    'use strict';

    angular
        .module('app.profile')
        .controller('Profile', profile);

    profile.$inject = ['logger', 'user', 'i18nFilter', '$location'];

    function profile(logger, user, i18nFilter, $location) {
        var vm = this;
        vm.user = user;
        vm.currentTime = new Date().getTime();
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        vm.isActive = function (view) {
            var path = $location.path();

            switch (view) {
                case '/edit':
                    if (path.endsWith('/edit')) {
                        return true;
                    }
                    break;
                case '/certificates':
                    if (path.endsWith('/certificates')) {
                        return true;
                    }
                    break;
              
                default:
                    return false;
            }

            return false;
        };

        
    }
})();