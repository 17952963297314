(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditEducationSecondStep', EditEducationSecondStep);

    EditEducationSecondStep.$inject = ['logger', 'editEducationHelper', 'usersDataService', '$state', '$stateParams', '$scope', 'templates', 'i18nFilter', 'prerequisiteEducations', '$sessionStorage', '$uibModal', 'languages', 'surveysDataService', 'surveys', 'emptyGuid', 'rolesDataService', 'surveyType'];

    function EditEducationSecondStep(logger, editEducationHelper, usersDataService, $state, $stateParams, $scope, templates, i18nFilter, prerequisiteEducations, $sessionStorage, $uibModal, languages, surveysDataService, surveys, emptyGuid, rolesDataService, surveyType) {
        var vm = this;
        vm.item = editEducationHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.canGatheringEvidence = rolesDataService.isUserInRoles(['GlobalAdmin', 'EducationAdmin']);

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            ownersCanCreateAssignments: i18nFilter("translations.education_edit_gui_tooltip_info_owners_can_create_assignments"),
            sequentialActivities: i18nFilter("translations.education_edit_gui_tooltip_info_sequential_activities"),
            generateDocuments: i18nFilter("translations.education_edit_gui_tooltip_info_generate_documents"),
            generateDocumentStamp: i18nFilter("translations.education_edit_gui_tooltip_info_generate_document_stamp"),
            generateDocumentSign: i18nFilter("translations.education_edit_gui_tooltip_info_generate_document_sign"),   
            attendeeConfirms: i18nFilter("translations.education_edit_gui_tooltip_info_attendee_confirms_education_finish"),
            ownerConfirms: i18nFilter("translations.education_edit_gui_tooltip_info_owner_confirms_education_finish"),
            survey: i18nFilter("translations.education_gui_tooltip_survey"),
            gatheringEvidence: i18nFilter("translations.education_edit_gui_tooltip_info_gathering_evidence"),
            deleteTooltip: i18nFilter("translations.education_gui_table_delete_tooltip")
        };

        vm.formData = {   
            languages: languages,
            templates: templates,            
            users: [],            
            usersIsLoading: false,
            prerequisiteEducations: prerequisiteEducations,
            surveys: surveys,
            surveyGroups: [],
            surveyTypeTranslations:{
                0: 'translations.education_gui_survey_type_None',
                1: 'translations.survey_gui_radio_label_survey_type_for_learning_participants',
                2: 'translations.survey_gui_radio_label_survey_type_for_coordinators',
                3: 'translations.survey_gui_radio_label_survey_type_for_lecture_contractors'
            }
        };

        removeSurveysWithoutSurveyType();

        vm.logData = logData;
        vm.refreshUsers = refreshUsers;
        vm.next = next;
        vm.back = back;
        vm.cancel = cancel;
        vm.estDurationValidation = estDurationValidation;
        vm.openCertificateHelp = openCertificateHelp;
        vm.openAttendanceListHelp = openAttendanceListHelp;
        vm.openDoorScheduleHelp = openDoorScheduleHelp;
        vm.openInstructorRecordHelp = openInstructorRecordHelp;
        vm.openInstructorFeeHelp = openInstructorFeeHelp;
        vm.openEFormSelfPayerHelp = openEFormSelfPayerHelp;
        vm.openEFormOrganizationHelp = openEFormOrganizationHelp;
        vm.openAttendanceListAggHelp = openAttendanceListAggHelp;
        vm.ownersCreateAssignments = true;
        vm.removeCertificateTemplate = removeCertificateTemplate;
        vm.removeAttendanceListTemplate = removeAttendanceListTemplate;
        vm.removeDoorAttendanceListTemplate = removeDoorAttendanceListTemplate;
        vm.removeInstructorRecordTemplate = removeInstructorRecordTemplate;
        vm.removeInstructorFeeTemplate = removeInstructorFeeTemplate;
        vm.removeEFormSelfPayerTemplate = removeEFormSelfPayerTemplate;
        vm.removeEFormOrganizationTemplate = removeEFormOrganizationTemplate;
        vm.removeAttendanceListAggTemplate = removeAttendanceListAggTemplate;

        vm.surveyChange = surveyChange;
        vm.removeSurvey = removeSurvey;
        vm.removeSurveyGroup = removeSurveyGroup;
        vm.surveyEnabledChange = surveyEnabledChange;
        vm.save = save;
        vm.addSurveyEducation = addSurveyEducation;
        vm.deleteSurveyEducation = deleteSurveyEducation;
        vm.validateSurveyEducations = validateSurveyEducations;
        vm.addedSurveys = [];

        if (vm.isEdit) {
            var indexToDelete = -1;
            angular.forEach(vm.formData.prerequisiteEducations, function (education, index) {
                if (vm.item.id === education.id) {
                    indexToDelete = index;
                }
            });

            if (indexToDelete !== -1) {
                vm.formData.prerequisiteEducations.splice(indexToDelete, 1);
            }

            if (vm.item.surveyEnabled) {
                vm.formData.surveyGroups = undefined;
            }

            removeAlreadyAddedSurveys();
        }

        function removeCertificateTemplate() {
            vm.item.certificateTemplate = undefined;
        }

        function removeAttendanceListTemplate() {
            vm.item.attendanceListTemplate = undefined;
        }

        function removeDoorAttendanceListTemplate() {
            vm.item.doorAttendanceListTemplate = undefined;
        }

        function removeInstructorRecordTemplate() {
            vm.item.instructorRecordTemplate = undefined;
        }

        function removeInstructorFeeTemplate() {
            vm.item.instructorFeeTemplate = undefined;
        }

        function removeLearnersBillTemplate() {
            vm.item.learnersBillTemplate = undefined;
        }

        function removeEFormSelfPayerTemplate() {
            vm.item.eFormSelfPayerTemplate = undefined;
        }

        function removeEFormOrganizationTemplate() {
            vm.item.eFormOrganizationTemplate = undefined;
        }

        function removeAttendanceListAggTemplate() {
            vm.item.attendanceListAggTemplate = undefined;
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function estDurationValidation() {                        
            var days = !vm.item.estDurationDay ? 0 : parseInt(vm.item.estDurationDay);
            var hours = !vm.item.estDurationHour ? 0 : parseInt(vm.item.estDurationHour);
            
            var sum = days + hours;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function next() {            
            $state.go($scope.navigation[2].state);
        }

        function save() {
            var item_copy = editEducationHelper.setCodeListData(vm.item);
            editEducationHelper.setData(item_copy);
            editEducationHelper.done(vm.isEdit,true);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('educations.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.education');
            }
        }

        function back() {
            $state.go($scope.navigation[0].state);
        }


        function logData() {
            logger.log("Data: ", vm.item);
        }

        function openCertificateHelp(documentEventTrigger) {
            $uibModal.open({
                templateUrl: 'app/educations/administration/edit/secondStep/certificateHelp/certificateHelp.html',
                controller: 'certificateHelpEducations',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function openAttendanceListHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/educations/administration/edit/secondStep/attendanceListHelp/attendanceListHelp.html',
                controller: 'attendanceListHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function openDoorScheduleHelp() {
            $uibModal.open({
                templateUrl: 'app/educations/administration/edit/secondStep/doorScheduleHelp/doorScheduleHelp.html',
                controller: 'doorScheduleHelp',
                controllerAs: 'vm'
            });
        }

        function openInstructorRecordHelp(documentEventTrigger) {
                $uibModal.open({
                    url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                    templateUrl: 'app/educations/administration/edit/secondStep/instructorRecordHelp/instructorRecordHelp.html',
                    controller: 'instructorRecordHelp',
                    controllerAs: 'vm',
                    resolve: {
                        templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                            return templatesDataService.getTemplateVariables(documentEventTrigger);
                        }]
                    }
                });           
        }

        function openInstructorFeeHelp() {
            $uibModal.open({
                templateUrl: 'app/educations/administration/edit/secondStep/instructorFeeHelp/instructorFeeHelp.html',
                controller: 'instructorFeeHelp',
                controllerAs: 'vm'
            });
        }

        function openEFormSelfPayerHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/educations/administration/edit/secondStep/eFormEducationSelfPayerHelp/eFormEducationSelfPayerHelp.html',
                controller: 'eFormEducationSelfPayerHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function openEFormOrganizationHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/educations/administration/edit/secondStep/eFormEducationOrganizationHelp/eFormEducationOrganizationHelp.html',
                controller: 'eFormEducationOrganizationHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function openAttendanceListAggHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/educations/administration/edit/secondStep/attendanceListAggHelp/attendanceListAggHelp.html',
                controller: 'attendanceListAggHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }

        function surveyChange() {
            if (vm.item.survey) {
                surveysDataService.getDdlSurveyQuestionGroupsBySurveyId(vm.item.survey.id).then(function (data) {
                    vm.formData.surveyGroups = data;
                    vm.item.surveyGroups = undefined;
                }, function (error) {
                    logger.error(i18nFilter("translations.education_exception_message_fetching_survey_groups"));
                });
            }
        }

        function removeSurvey() {
            vm.item.survey = undefined;
            vm.item.surveyGroups = undefined;
        }

        function removeSurveyGroup() {
            vm.item.surveyGroups = undefined;
        }

        function surveyEnabledChange() {
            if (!vm.item.surveyEnabled) {
                vm.item.survey = undefined;
                vm.item.surveyGroups = undefined;
            }
        }

        function addSurveyEducation() {
            var surveyEducation = {
                id: emptyGuid,
                surveyId: vm.item.survey.id,
                surveyName: vm.item.survey.name,
                surveyType: vm.item.survey.surveyType,
                educationId: vm.item.id
            }

            if(vm.item.surveyGroups){
                surveyEducation.questionGroupId = vm.item.surveyGroups.id;
                surveyEducation.questionGroupName = vm.item.surveyGroups.name;
            }

            var orderNumber = setSurveyEducationOrderNumber();

            surveyEducation.orderNo = orderNumber;
            
            vm.item.surveyEducations.push(surveyEducation);
            removeFromAvailableSurveys();
            removeSurvey();
        }

        function deleteSurveyEducation(surveyEducation) {
            if (surveyEducation){
                const index = vm.item.surveyEducations.indexOf(surveyEducation);
                if (index > -1) { 
                    vm.item.surveyEducations.splice(index, 1);
                    
                    addToAvailableSurveys(surveyEducation.surveyId);
                    recalculateSurveyEducationOrderNumber();
                }
            }
        }

        function setSurveyEducationOrderNumber() {
            if (!vm.item.surveyEducations)
                vm.item.surveyEducations = [];

            if (vm.item.surveyEducations.length == 0){
                return 1;
            }
            else if (vm.item.surveyEducations.length > 0) {
                return vm.item.surveyEducations.length + 1;
            }
            else {
                return 0;
            }
        }

        function recalculateSurveyEducationOrderNumber() {
            angular.forEach(vm.item.surveyEducations, function (surveyEducation, Index) {
                surveyEducation.orderNo = Index + 1;
            });
        }

        function removeFromAvailableSurveys() {
            if (vm.formData.surveys){
                const index = vm.formData.surveys.indexOf(vm.item.survey);
                if (index > -1) { 
                    vm.formData.surveys.splice(index, 1);
                    vm.addedSurveys.push(vm.item.survey);
                }
            }
        }

        function addToAvailableSurveys(surveyId){
            var found = vm.addedSurveys.find(function (survey) {
                return survey.id == surveyId;
            });

            if (found){
                vm.formData.surveys.push(found);
            }
        }

        function removeAlreadyAddedSurveys() {
            if (vm.item.surveyEducations) {
                angular.forEach(vm.item.surveyEducations, function (surveyEducation, Index) {
                    var found = vm.formData.surveys.find(function (survey) {
                        return survey.id == surveyEducation.surveyId;
                    });
        
                    if (found){
                        const index = vm.formData.surveys.indexOf(found);
                        if (index > -1) { 
                            vm.formData.surveys.splice(index, 1);
                            vm.addedSurveys.push(found);
                        }
                    }
                });
            }
        }

        function removeSurveysWithoutSurveyType(){
            if (vm.formData.surveys) {
                var item_copy = angular.copy(vm.formData.surveys)

                angular.forEach(item_copy, function (surveyCopy, Index) {
                    if (surveyCopy.surveyType == 0){
                        var found = vm.formData.surveys.find(function (survey) {
                            return survey.id == surveyCopy.id;
                        });

                        if (found){
                            const index = vm.formData.surveys.indexOf(found);
                            if (index > -1) { 
                                vm.formData.surveys.splice(index, 1);
                            }
                        }
                    }
                });
            }
        }

        function validateSurveyEducations() {
            if (!vm.item.surveyEnabled || (vm.item.surveyEnabled && vm.item.surveyEducations && vm.item.surveyEducations.length > 0))
                return true;

            return false;
        }
        
    }
})();
