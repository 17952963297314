(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('EditSubCompetenceGroup', editSubCompetenceGroup);

    editSubCompetenceGroup.$inject = ['logger', 'competenceGroup', 'competenceGroups', '$scope', 'i18nFilter', 'isEdit', '$sanitize'];

    function editSubCompetenceGroup(logger, competenceGroup, competenceGroups, $scope, i18nFilter, isEdit, $sanitize) {
        var vm = this;
        vm.competenceGroups = competenceGroups;
        vm.isEdit = isEdit;

        if (vm.isEdit) {
            vm.item = competenceGroup;
            for (var i = 0; i < competenceGroups.length; i++) {
                if (vm.item.parentCompetenceGroupId === vm.competenceGroups[i].id) {
                    vm.item.parentCompetenceGroup = competenceGroups[i];
                }
            }
        } else {
            vm.item = {
                id: undefined,
                text: undefined,
                definition: undefined,
                status: undefined,
                competences: undefined,
                parentCompetenceGroup: undefined,
                items: undefined,
                expanded: undefined
            };
        }
        vm.formData = {};
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            vm.item.text = DOMPurify.sanitize(vm.item.text);
            vm.item.definition = DOMPurify.sanitize(vm.item.definition);
            $scope.$close(vm.item);
        };
    }
})();
