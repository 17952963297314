(function () {
    'use strict';

    angular
        .module('app.contracts')
        .controller('contracts', contracts);

    contracts.$inject = ['dateFilter', 'contractsDataService', 'logger', 'i18nFilter', 'exceptionHelper', 'documentsDataService'];

    function contracts(dateFilter, contractsDataService, logger, i18nFilter, exceptionHelper, documentsDataService) {
        var vm = this;

        vm.contracts = contracts;
        vm.localeMessages = {
            edit: i18nFilter('translations.general_action_edit'),
            delete: i18nFilter('translations.general_action_delete'),
            cannotExport: i18nFilter('translations.contract_tooltip_export_disabled'),
            downloadContract: i18nFilter('translations.contract_tooltip_download_contract')
        };
        vm.exportToExcel = exportToExcel;
        vm.exportEnabled = false;
        vm.downloadContract = downloadContract;
        vm.emptyGuid = '00000000-0000-0000-0000-000000000000'
        
        vm.contractStatus = [
            {
                id: 1,
                contractStatusName: i18nFilter('translations.general_status_active')
            },
            {
                id: 0,
                contractStatusName: i18nFilter('translations.general_status_deactiveted')
            }
        ];          

        vm.options = {
            contractNumber: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            contractingParty: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            contractors: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            start: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            end: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            amount: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            entities: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },

            importNumber: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            publicContractOrder: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            taxPayer: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            status: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined,
            excelExport: undefined
        };

        var columns = [
            {
                dataField: 'contractNumber',
                caption: i18nFilter('translations.contract_gui_contract_number'),
                alignment: 'left',
                cssClass: 'table-column-space-format',
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'contractingParty',
                caption: i18nFilter('translations.contract_gui_contracting_party'),
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true
            },
            {
                dataField: 'contractors',
                caption: i18nFilter('translations.contract_gui_contractors'),
                alignment: 'left',
                allowSorting: true,
                allowFiltering: true,
                filterOperations: ['contains'],
                cellTemplate: 'contractorsTemplate'
            },
            {
                dataField: 'start',
                dataType: 'date',
                alignment: 'left',
                width: 150,
                allowFiltering: true,
                filterOperations: ['<', '<=', '=', '>', '>=', 'between'],
                allowSorting: true,
                sortOrder: 'desc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_start')
            },
            {
                dataField: 'end',
                dataType: 'date',
                alignment: 'left',
                width: 150,
                allowSorting: true,
                allowFiltering: true,
                filterOperations: ['<', '<=', '=', '>', '>=', 'between'],
                customizeText: function (args) {
                    return dateFilter(args.value, 'shortDate');
                },
                caption: i18nFilter('translations.general_gui_end')
            },
            {
                dataField: 'amount',
                alignment: 'left',
                width: 100,
                allowSorting: true,
                allowFiltering: true,
                filterOperations: ['=', '<>'],
                caption: i18nFilter('translations.general_gui_amount_euro')
            },
            {
                dataField: 'entities',
                caption: i18nFilter('translations.budget_gui_educations_and_exams'),
                alignment: 'left',
                allowSorting: false,
                allowFiltering: true,
                filterOperations: ['contains'],
                cellTemplate: 'entitiesTemplate'
            },

            {
                dataField: 'importNumber',
                alignment: 'left',
                width: 100,
                allowSorting: true,
                allowFiltering: false,
                caption: i18nFilter('translations.contract_gui_import_number')
            },
            {
                dataField: 'publicContractOrder',
                alignment: 'center',
                width: 80,
                allowSorting: true,
                allowFiltering: false,
                caption: i18nFilter('translations.contract_gui_public_contract_order')
            },
            {
                dataField: 'taxPayer',
                alignment: 'center',
                width: 80,
                allowSorting: true,
                allowFiltering: false,
                caption: i18nFilter('translations.contract_gui_tax_payer')
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                allowSorting: false,
                allowFiltering: false,
                width: 125,
                alignment: 'left',
                cellTemplate: 'actionTemplate'
            }
        ];

        function simpleFilterExtruder(element) {
            if (element[0] === "contractNumber") {
                vm.options.contractNumber.filterType1 = element[1];
                vm.options.contractNumber.searchText1 = element[2];
            }
            if (element[0] === "contractingParty") {
                vm.options.contractingParty.filterType1 = element[1];
                vm.options.contractingParty.searchText1 = element[2];
            }
            if (element[0] === "contractors") {
                vm.options.contractors.filterType1 = element[1];
                vm.options.contractors.searchText1 = element[2];
            }
            if (element[0] === "entities") {
                vm.options.entities.filterType1 = element[1];
                vm.options.entities.searchText1 = element[2];
            }
            if (element[0] === "start") {
                vm.options.start.filterType1 = element[1];
                vm.options.start.searchText1 = element[2];
            }
            if (element[0] === "end") {
                vm.options.end.filterType1 = element[1];
                vm.options.end.searchText1 = element[2];
            }
            if (element[0] === "amount") {
                vm.options.amount.filterType1 = element[1];
                vm.options.amount.searchText1 = element[2];
            }
            if (element[0] === "status") {
                vm.options.status.filterType1 = element[1];
                vm.options.status.searchText1 = element[2];
            }            
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'start' && element[2][0] === 'start') {
                vm.options.start.filterType1 = element[0][1];
                vm.options.start.searchText1 = element[0][2];
                vm.options.start.filterType2 = element[2][1];
                vm.options.start.searchText2 = element[2][2];
            }
            if (element[0][0] === 'end' && element[2][0] === 'end') {
                vm.options.end.filterType1 = element[0][1];
                vm.options.end.searchText1 = element[0][2];
                vm.options.end.filterType2 = element[2][1];
                vm.options.end.searchText2 = element[2][2];
            }            
        }

        function clearFilter() {
            vm.options.contractNumber.filterType1 = undefined;
            vm.options.contractNumber.searchText1 = undefined;
            vm.options.contractNumber.filterType2 = undefined;
            vm.options.contractNumber.searchText2 = undefined;

            vm.options.contractingParty.filterType1 = undefined;
            vm.options.contractingParty.searchText1 = undefined;
            vm.options.contractingParty.filterType2 = undefined;
            vm.options.contractingParty.searchText2 = undefined;
            
            vm.options.contractors.filterType1 = undefined;
            vm.options.contractors.searchText1 = undefined;
            vm.options.contractors.filterType2 = undefined;
            vm.options.contractors.searchText2 = undefined;

            vm.options.entities.filterType1 = undefined;
            vm.options.entities.searchText1 = undefined;
            vm.options.entities.filterType2 = undefined;
            vm.options.entities.searchText2 = undefined;

            vm.options.start.filterType1 = undefined;
            vm.options.start.searchText1 = undefined;
            vm.options.start.filterType2 = undefined;
            vm.options.start.searchText2 = undefined;

            vm.options.end.filterType1 = undefined;
            vm.options.end.searchText1 = undefined;
            vm.options.end.filterType2 = undefined;
            vm.options.end.searchText2 = undefined;

            vm.options.amount.filterType1 = undefined;
            vm.options.amount.searchText1 = undefined;
            vm.options.amount.filterType2 = undefined;
            vm.options.amount.searchText2 = undefined;


            vm.options.status.filterType1 = undefined;
            vm.options.status.searchText1 = undefined;
            vm.options.status.filterType2 = undefined;
            vm.options.status.searchText2 = undefined;

            vm.options.excelExport = undefined;
        }

        var dataSource = {
            load: setFilterOptions
        };

        function setFilterOptions(loadOptions){
            var d = new $.Deferred();
            clearFilter();
            //Getting filter options
            if (loadOptions.filter) {
                if (loadOptions.filter.columnIndex !== undefined) {
                    //Provjera za obični filter (string i integer)
                    if (loadOptions.filter[0].constructor !== Array) {
                        simpleFilterExtruder(loadOptions.filter);
                    }
                    //Filter za datume
                    else {
                        dateFilterExtruder(loadOptions.filter)
                    }
                }
                else {
                    angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                        //Ukoliko je više od jedan filter u primjeni
                        if (filterElement.constructor === Array) {
                            //Provjera za obični filter (string i integer)
                            if (filterElement[0].constructor !== Array) {
                                simpleFilterExtruder(filterElement);
                            }
                            //Filter za datume
                            else {
                                dateFilterExtruder(filterElement);
                            }
                        }
                    });
                }
                vm.exportEnabled = true;
            }
            else 
                vm.exportEnabled = false;

            //Getting sort options
            if (loadOptions.sort) {
                vm.options.contractNumber.sortType = undefined;
                vm.options.contractingParty.sortType = undefined;
                vm.options.contractors.sortType = undefined;
                vm.options.entities.sortType = undefined;
                vm.options.start.sortType = undefined;
                vm.options.end.sortType = undefined;
                vm.options.status.sortType = undefined;
                vm.options.amount.sortType = undefined;

                if (loadOptions.sort[0].selector === "contractNumber") {
                    vm.options.contractNumber.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "contractingParty") {
                    vm.options.contractingParty.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "contractors") {
                    vm.options.contractors.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "entities") {
                    vm.options.entities.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "start") {
                    vm.options.start.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "end") {
                    vm.options.end.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "amount") {
                    vm.options.amount.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
                if (loadOptions.sort[0].selector === "status") {
                    vm.options.status.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                }
            }

            //skip and take are used for paging
            vm.options.skip = loadOptions.skip; //A number of records that should be skipped
            vm.options.take = loadOptions.take; //A number of records that should be taken

            vm.options.excelExport = loadOptions.excelExport;

            logger.log("vm.options: ", vm.options);

            if (loadOptions.excelExport) {
                contractsDataService.getAllContractsExcel(angular.copy(vm.options)).then(function (data) {
                    var file = new Blob([data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    //trick to download store a file having its URL
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'pogodbe.xlsx';
                    document.body.appendChild(a); //create the link "a"
                    a.click(); //click the link "a"
                    document.body.removeChild(a); //remove the link "a"

                    d.resolve({});
                }, function (error) {
                    logger.error(i18nFilter('translations.contract_error_message_fetching_contracts'));
                    d.reject();
                });
            }

            else {
                contractsDataService.getAllContracts(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.contract_error_message_fetching_contracts'));
                    d.reject();
                });
            }

            return d.promise();
        }

        function exportToExcel() {
            let loadOptions = angular.copy(vm.contractsGridInstance.getDataSource().loadOptions());
            loadOptions.filter = vm.contractsGridInstance.getCombinedFilter();
            loadOptions.excelExport = true;

            setFilterOptions(loadOptions);
        }

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            columnAutoWidth: true,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            onInitialized: function (obj) {
                vm.contractsGridInstance = obj.component;
            },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function downloadContract(contractId) {
            var date = new Date().getDate().toString();
            documentsDataService.downloadDocument(
                "api/contracts/contractDownload/" + contractId,
                "Pogodba_" + date + ".docx"
            );
        }
                
    }
})();

