(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('surveysDataService', activities);

    activities.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function activities($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllActiveSurveys: getAllActiveSurveys,
            getSurveyById: getSurveyById,
            getEditSurveyById: getEditSurveyById,

            addSurvey: addSurvey,
            editSurvey: editSurvey,
            deleteSurvey: deleteSurvey,

            getSurveyQuestionGroupById: getSurveyQuestionGroupById,
            getSurveyQuestionGroupsBySurveyId: getSurveyQuestionGroupsBySurveyId,
            getDdlSurveyQuestionGroupsBySurveyId: getDdlSurveyQuestionGroupsBySurveyId,

            addSurveyGroup: addSurveyGroup,
            editSurveyGroup: editSurveyGroup,
            deleteSurveyGroup: deleteSurveyGroup,
            orderUpSurveyGroup: orderUpSurveyGroup,
            orderDownSurveyGroup: orderDownSurveyGroup,

            getQuestionsForQuestionGroup: getQuestionsForQuestionGroup,
            getQuestionById: getQuestionById,
            getSurveyOwners: getSurveyOwners,

            addQuestion: addQuestion,
            editQuestion: editQuestion,
            deleteQuestion: deleteQuestion,
            orderUpQuestion: orderUpQuestion,
            orderDownQuestion: orderDownQuestion,
            getSurveysForEducation: getSurveysForEducation,
        };

        return service;

        //#region Survey
        function getAllActiveSurveys(surveyId) {
            return $http.get('api/surveys/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSurveyById(surveyId) {
            return $http.get('api/surveys/' + surveyId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getEditSurveyById(surveyId) {
            return $http.get('api/surveys/edit/' + surveyId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteSurvey(surveyId) {
            return $http.delete('api/surveys/' + surveyId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSurvey(survey) {
            return $http.post('api/surveys', survey)
                .then(function (data) {                   
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editSurvey(survey) {
            return $http.put('api/surveys', survey)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        //#endregion

        //#region SurveyGroup
        function getSurveyQuestionGroupById(surveyQuestionGroupId) {
            return $http.get('api/surveys/questionGroups/' + surveyQuestionGroupId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSurveyQuestionGroupsBySurveyId(surveyId) {
            return $http.get('api/surveys/' + surveyId + '/questionGroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getDdlSurveyQuestionGroupsBySurveyId(surveyId) {
            return $http.get('api/surveys/' + surveyId + '/ddlQuestionGroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteSurveyGroup(surveyQuestionGroupId) {
            return $http.delete('api/surveys/questionGroups/' + surveyQuestionGroupId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSurveyGroup(surveyQuestionGroup) {
            return $http.post('api/surveys/questionGroups', surveyQuestionGroup)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editSurveyGroup(surveyQuestionGroup) {
            return $http.put('api/surveys/questionGroups', surveyQuestionGroup)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function orderUpSurveyGroup(surveyQuestionGroupId) {
            return $http.post('api/surveys/questionGroups/' + surveyQuestionGroupId + '/orderUp')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function orderDownSurveyGroup(surveyQuestionGroupId) {
            return $http.post('api/surveys/questionGroups/' + surveyQuestionGroupId + '/orderDown')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        //#endregion

        //#region Question
        function getQuestionsForQuestionGroup(surveyQuestionGroupId) {
            return $http.get('api/surveys/questionGroups/' + surveyQuestionGroupId + '/questions').then(function (data) {
                angular.forEach(data.data, function (question, index) {
                    question.lastUpdatedAt = dateHelper.convertFromStringToDate(question.lastUpdatedAt);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getQuestionById(questionId) {
            return $http.get('api/surveys/questionGroups/questions/' + questionId).then(function (data) {               
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }


        function addQuestion(question) {
            return $http.post('api/surveys/questionGroups/questions', question)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function editQuestion(question) {
            return $http.put('api/surveys/questionGroups/questions', question)
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function deleteQuestion(questionId) {
            return $http.delete('api/surveys/questionGroups/questions/' + questionId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function orderUpQuestion(questionId) {
            return $http.post('api/surveys/questionGroups/questions/' + questionId + '/orderUp' )
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function orderDownQuestion(questionId) {
            return $http.post('api/surveys/questionGroups/questions/' + questionId + '/orderDown')
                .then(function (data) {
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }
        //#endregion

        function getSurveyOwners(surveyId) {
            return $http.get('api/surveys/surveyOwners/' + surveyId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        //#region SurveyEducation
        function getSurveysForEducation(educationId) {
            return $http.get('api/surveys/surveysEducation/' + educationId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        //$endregion
    }
})();
