(function () {
    'use strict';

    angular
        .module('app.helper')
        .factory('jsonHelper', jsonHelper);

    jsonHelper.$inject = ['logger'];

    function jsonHelper(logger) {
        var service = {
            convertKeysToCamelCase: convertKeysToCamelCase
        };

        return service;
    }

    function convertKeysToCamelCase(obj) {
        if (!obj || typeof obj !== "object") return null;

        if (obj instanceof Array) {
            return $.map(obj, function (value) {
                return convertKeysToCamelCase(value);
            });
        }

        var newObj = {};
        $.each(obj, function (key, value) {
            key = key.charAt(0).toLowerCase() + key.slice(1);
            if (typeof value == "object" && !(value instanceof Array)) {
                value = convertKeysToCamelCase(value);
            }
            newObj[key] = value;
        });

        return newObj;
    }
})();
