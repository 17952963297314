(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('schoolingScheduleProgram', schoolingScheduleProgram);

    schoolingScheduleProgram.$inject = [
        'logger',
        'i18nFilter',
        '$stateParams',
        'exceptionHelper',
        '$uibModal',
        '$state',
        '$http',
        '$timeout',
        '$sessionStorage',
        'toastr',
        'schoolingSchedulePacksDataService',
        'SchoolingActivityScheduleInstructorRoles',
        'program',
        'paidInstructorRoles',
        'documentsDataService',
        'dateHelper'
    ];

    function schoolingScheduleProgram(
        logger,
        i18nFilter,
        $stateParams,
        exceptionHelper,
        $uibModal,
        $state,
        $http,
        $timeout,
        $sessionStorage,
        toastr,
        schoolingSchedulePacksDataService,
        SchoolingActivityScheduleInstructorRoles,
        program,
        paidInstructorRoles,
        documentsDataService,
        dateHelper
    ) {
        var vm = this;

        vm.program = program;
        vm.selectedSectionIndex = schoolingSchedulePacksDataService.selectedSectionIndex;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            copyProgramMessage: i18nFilter('translations.schoolingSchedule_gui_program_copyProgramMessage'),
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down'),
            addModeratorTooltip: i18nFilter('translations.schoolingSchedule_gui_program_addModerator'),
            editModeratorTooltip: i18nFilter('translations.schoolingSchedule_gui_program_edit_moderator'),
            deleteModeratorTooltip: i18nFilter('translations.schoolingSchedule_gui_program_delete_moderator')
        };

        vm.roles = translateCollections(SchoolingActivityScheduleInstructorRoles);

        vm.addSection = addSection;
        vm.removeSection = removeSection;
        vm.addLecture = addLecture;
        vm.editLecture = editLecture;
        vm.removeLecture = removeLecture;
        vm.copyProgram = copyProgram;

        vm.formatDateTime = formatDateTime;
        vm.concatSpeakers = concatSpeakers;
        vm.showDocuments = showDocuments;
        vm.downloadListofLecturers = downloadListofLecturers;

        vm.moveUp = moveUp;
        vm.moveDown = moveDown;

        vm.showMoveUp = showMoveUp;
        vm.showMoveDown = showMoveDown;
        vm.setLectureDates = setLectureDates;
        vm.validateSectionStartDates = validateSectionStartDates;

        vm.addSectionModerator = addSectionModerator;
        vm.editSectionModerator = editSectionModerator;
        vm.deleteSectionModerator = deleteSectionModerator;

        vm.changeSectionIndex = changeSectionIndex;

        vm.save = save;

        vm.dobValidationRules = {
            validationRules: [{
                type: 'required',
                message: 'Začetek je obvezen podatek!',
            }],
        };

        vm.program.sections.forEach(function (section, sectionIndex) {
            section.sectionIndex = sectionIndex;
            section.lectures.forEach(function (lecture) {
                lecture.speakers.forEach(function (speaker){
                    speaker.role = vm.roles[speaker.role];
                    speaker.duration = speaker.duration.toString().replace(".", ",");   
                });
            });

            section.startDateConfig = {
                type: 'datetime',
                width: '100%',
                acceptCustomValue: true,
                placeholder: "DD.MM.YYYY HH:MM",
                min: new Date(1900, 0, 1),
                max: new Date(3000, 0, 1),
                onKeyDown: function (e) {
                    const input = document.getElementById('startDateTimeInput' + sectionIndex);
                    dateHelper.onDateTimeInputKeyDown(e.event, input);
                },
                bindingOptions: {
                    value: 'section.dateStart'
                },
                onValueChanged: function (obj) {
                    vm.setLectureDates(obj.model.section.sortOrder);
                },
                inputAttr: {
                    'name': 'section-' + sectionIndex + '-dateStart',
                    'ng-required': 'true',
                    'id': 'startDateTimeInput' + sectionIndex
                }
            };
        });

        function addSection() {
            let sectionIndex = vm.program.sections.length;
            let section = {
                name: 'Sekcija '+(sectionIndex+1).toString(),
                sortOrder: sectionIndex,
                moderators: [],
                lectures: [],
                startDateConfig : {
                    type: 'datetime',
                    width: '100%',
                    acceptCustomValue: true,
                    placeholder: "DD.MM.YYYY HH:MM",
                    min: new Date(1900, 0, 1),
                    max: new Date(3000, 0, 1),
                    onKeyDown: function (e) {
                        const input = document.getElementById('startDateTimeInput' + sectionIndex);
                        dateHelper.onDateTimeInputKeyDown(e.event, input);
                    },
                    inputAttr: {
                        name: 'section-' + sectionIndex + '-dateStart',
                        'ng-required': 'true',
                        'id': 'startDateTimeInput' + sectionIndex
                    },
                    bindingOptions: {
                        value: 'section.dateStart'
                    },
                    onValueChanged: function (obj) {
                        vm.setLectureDates(obj.model.section.sortOrder);
                    }
                }
            };
            vm.program.sections.push(section);

            // dynamically change active section tab
            $timeout(function () {
                vm.selectedSectionIndex = sectionIndex;
            });
        }

        function validateSectionStartDates(section) {
            if (section) 
                return section.dateStart;

            var valid = true;
            vm.program.sections.forEach(function (section, sectionIndex) {
                if (!section.dateStart) {
                    valid = false;
                }
            })

            return valid;
        }

        function removeSection(sectionIndex) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/removeSection/removeSection.html',
                controller: 'removeSection',
                controllerAs: 'vm',
                resolve: {
                    // do a deep copy so the original section isn't changed until user clicks OK
                    section: angular.copy(vm.program.sections[sectionIndex])                    
                }
            })
            .result.then(function () {
                // after clicking OK button
                // remove section at given index
                vm.program.sections.splice(sectionIndex, 1);

                // recalculate sections sort order
                vm.program.sections.forEach(function (section, index) {
                    section.sortOrder = index;
                });

            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function addSectionModerator(sectionIndex) {
            let newModerator = {
                id: null,
                user: null,
                duration: 0
            };

            vm.editSectionModerator(true, newModerator, sectionIndex, null);
        }

        function editSectionModerator(adding, data, sectionIndex, moderatorIndex) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/editModerator/editModerator.html',
                controller: 'editModerators',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static', //disable closing modal by clicking on background, so you don't accidentally undo your changes
                resolve: {
                    // do a deep copy so the original lecture isn't changed until user clicks OK
                    moderator: angular.copy(data),
                    adding: adding
                }
            })
            .result.then(function (modifiedModerator) {
                // after clicking OK button
                if (adding) {
                    vm.program.sections[sectionIndex].moderators.push(modifiedModerator);
                }
                else {
                    vm.program.sections[sectionIndex].moderators[moderatorIndex] = modifiedModerator;
                }

            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function deleteSectionModerator(sectionIndex, moderatorIndex) {
            vm.program.sections[sectionIndex].moderators.splice(moderatorIndex, 1);
        }

        function addLecture(sectionIndex) {
            let lectureIndex = vm.program.sections[sectionIndex].lectures.length;
            let lecture = {
                name: 'Predavanje '+(lectureIndex+1).toString(),
                speakers: [],
                sortOrder: lectureIndex,
                from: vm.program.startDate,
                to: vm.program.endDate,
                duration: null
            };
            editLecture(sectionIndex, lecture,true);
        }
        function editLecture(sectionIndex,lecture,adding) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/editLecture/editLecture.html',
                controller: 'editLecture',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static', //disable closing modal by clicking on background, so you don't accidentally undo your changes
                resolve: {
                    // do a deep copy so the original lecture isn't changed until user clicks OK
                    lecture: angular.copy(lecture),
                    paidInstructorRoles: function () {
                        return paidInstructorRoles;
                    }
                }
            })
            .result.then(function (modifiedLecture) {
                // after clicking OK button
                if (adding)
                    vm.program.sections[sectionIndex].lectures.push(modifiedLecture);
                else
                    vm.program.sections[sectionIndex].lectures[modifiedLecture.sortOrder] = modifiedLecture;

                vm.setLectureDates(sectionIndex);

            }, function () {
                // after clicking Cancel button or clicking background
            });
        }
        function removeLecture(sectionIndex, lectureIndex) {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/removeLecture/removeLecture.html',
                controller: 'removeLecture',
                controllerAs: 'vm',
                resolve: {
                    // do a deep copy so the original lecture isn't changed until user clicks OK
                    lecture: angular.copy(vm.program.sections[sectionIndex].lectures[lectureIndex])
                }
            })
            .result.then(function () {
                // after clicking OK button
                // remove lecture at given index
                vm.program.sections[sectionIndex].lectures.splice(lectureIndex, 1);

                // recalculate lectures sort order
                vm.program.sections[sectionIndex].lectures.forEach(function (lecture, index) {
                    lecture.sortOrder = index;
                });

                vm.setLectureDates(sectionIndex);
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }


        function setLectureDates(sectionIndex) {
            let sectionStartDate = new Date(vm.program.sections[sectionIndex].dateStart);

            // recalculate lectures sort order and dates
            vm.program.sections[sectionIndex].lectures.forEach(function (lecture, index) {
                lecture.from = sectionStartDate.toISOString();
                sectionStartDate = new Date(sectionStartDate.getTime() + ((lecture.duration) * 60000));
                lecture.to = sectionStartDate.toISOString();
                lecture.sortOrder = index;
            });
        }


        function copyProgram() {
            $uibModal.open({
                templateUrl: 'app/schoolingSchedulePacks/program/copyProgram/copyProgram.html',
                controller: 'copyProgram',
                controllerAs: 'vm',
                resolve: {
                    pastSchedules: function () {
                        return schoolingSchedulePacksDataService.getPastSchedulesForSchooling($stateParams.entityId,$stateParams.schoolingScheduleId);
                    },
                    roles: function () {
                        return vm.roles;
                    }
                }
            })
                .result.then(function (copiedProgram) {
                // after clicking OK button
                    const numCopiedSections = copiedProgram.sections.length;
                    const sectionsToAdd = numCopiedSections - vm.program.sections.length;
                    const sectionsToRemove = vm.program.sections.length - numCopiedSections;

                    for (let i = sectionsToAdd; i > 0; i--) {
                        addSection();
                    }

                    for (let i = sectionsToRemove; i > 0; i--) {
                        vm.program.sections.splice(numCopiedSections + i - 1, 1);

                        // recalculate sections sort order
                        vm.program.sections.forEach(function (section, index) {
                            section.sortOrder = index;
                        });
                    }
                    
                    copiedProgram.scheduleName = vm.program.scheduleName;
                    vm.program.endDate = copiedProgram.endDate;
                    vm.program.startDate = copiedProgram.startDate;
                    vm.program.scheduleId = copiedProgram.scheduleId;
                    vm.program.scheduleName = copiedProgram.scheduleName;

                    for (let i = 0; i < vm.program.sections.length; i++) {
                        vm.program.sections[i].dateStart = copiedProgram.sections[i].dateStart;
                        vm.program.sections[i].lectures = copiedProgram.sections[i].lectures;
                        vm.program.sections[i].location = copiedProgram.sections[i].location;
                        vm.program.sections[i].moderator = copiedProgram.sections[i].moderator;
                        vm.program.sections[i].moderators = copiedProgram.sections[i].moderators;
                        vm.program.sections[i].name = copiedProgram.sections[i].name;
                        vm.program.sections[i].sortOrder = copiedProgram.sections[i].sortOrder;
                    }
                toastr.info(vm.localeMessages.copyProgramMessage);

                // dynamically change active section tab
                $timeout(function () {
                    vm.selectedSectionIndex = 0;
                });
            }, function () {
                // after clicking Cancel button or clicking background
            });
        }

        function downloadListofLecturers() {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/' + $stateParams.schoolingScheduleId + '/lecturersList', 'Lista predavateljev termina.xlsx');
        }

        function showDocuments() {
            $state.go('schoolingSchedulePacks.programDocuments', $stateParams);
        }

        function refreshModerators(query) {
            if (query === undefined || query === "") {
                return;
            }
            schoolingSchedulePacksDataService.getSchoolingScheduleProgramModerators($stateParams.schoolingScheduleId,query).then(function (data) {
                vm.moderators = data;
            }, function (error) {
                logger.error(i18nFilter("translations.schoolingSchedule_gui_program_exception_message_fetching_moderators"));
            });
        }
        function formatDateTime(dateTime) {
            return moment(dateTime).format('DD.MM.YYYY HH:mm');
        }
        function concatSpeakers(speakers) {
            let speakerNames = speakers.map(function (speaker) { return speaker.speaker.displayName; }); 
            return speakerNames.join(', ');
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            });
            return data;
        }

        function moveUp(id, index, sectionIndex) {

            var sectionToMoveUp = vm.program.sections[sectionIndex].lectures[index];
            var sectionToMoveDown = vm.program.sections[sectionIndex].lectures[index-1];

            sectionToMoveUp.sortOrder = index - 1;
            sectionToMoveDown.sortOrder = index;

            sortLectures(sectionIndex);
            vm.setLectureDates(sectionIndex)
        }

        function moveDown(id, index, sectionIndex) {

            var sectionToMoveUp = vm.program.sections[sectionIndex].lectures[index+1];
            var sectionToMoveDown = vm.program.sections[sectionIndex].lectures[index];

            sectionToMoveUp.sortOrder = index;
            sectionToMoveDown.sortOrder = index + 1;

            sortLectures(sectionIndex);
            vm.setLectureDates(sectionIndex);
        }

        function sortLectures(sectionIndex) {
            vm.program.sections[sectionIndex].lectures.sort(function (a, b) {
                return a.sortOrder - b.sortOrder;
            });
        }

        function showMoveUp(section, lecture) {
            return (lecture.sortOrder == 0 || section.lectures.length < 2);
        }

        function showMoveDown(section, lecture) {
            return (lecture.sortOrder == (section.lectures.length - 1) || section.lectures.length < 2);
        }

        function changeSectionIndex(selectedSectionIndex) {
            //filter out null or undefined parameter values
            if (selectedSectionIndex) {
                vm.selectedSectionIndex = selectedSectionIndex;
            }
        }

        function save() {
            if (!vm.validateSectionStartDates()) 
                return;

            //speaker role needs to be an id
            let programCopy = angular.copy(vm.program);
            programCopy.sections.forEach(function (section) {
                section.lectures.forEach(function (lecture) {
                    lecture.speakers.forEach(function (speaker) {
                        speaker.role = speaker.role.id;
                        speaker.duration = parseFloat(speaker.duration.toString().replace(",", "."));
                    });
                });
            });

            schoolingSchedulePacksDataService.selectedSectionIndex = vm.selectedSectionIndex;

            schoolingSchedulePacksDataService.editSchoolingScheduleProgram(programCopy).then(function (data) {
                logger.success(i18nFilter('translations.schoolingSchedule_gui_program_saving'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
