(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditEducationFistStep', EditEducationFistStep);

    EditEducationFistStep.$inject = ['targetSubgroups', 'competenceLevels', 'logger', 'editEducationHelper', 'educationAccessMode', 'categories', 'usersDataService', '$state', '$stateParams', '$scope', 'educationExecutionStyle', 'i18nFilter', 'tags', 'guidGeneratorDataService', 'rolesDataService', 'contentTypeTags', 'estDurationDays', 'organizationsDataService', 'onlineEducationVisibility', '$sanitize'];

    function EditEducationFistStep(targetSubgroups, competenceLevels, logger, editEducationHelper, educationAccessMode, categories, usersDataService, $state, $stateParams, $scope, educationExecutionStyle, i18nFilter, tags, guidGeneratorDataService, rolesDataService, contentTypeTags, estDurationDays, organizationsDataService, onlineEducationVisibility, $sanitize) {
        var vm = this;
        vm.item = editEducationHelper.getData();
        vm.isEdit = $state.current.data.isEdit;
        //vm.item.id = $stateParams.Id;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            selectDaysPlaceholder: i18nFilter("translations.general_gui_placeholder_select_or_type_number_of_days"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            generateDocuments: i18nFilter("translations.education_edit_gui_tooltip_info_generate_documents"),
            catalogVisibility: i18nFilter("translations.education_edit_gui_tooltip_info_catalog_visibility"),
            status: i18nFilter("translations.education_edit_gui_tooltip_info_status"),
            sendMailToCoordinators: i18nFilter("translations.education_edit_gui_tooltip_info_send_mail_to_coordinators"),
            tags: i18nFilter("translations.education_required_tags"),
            public: i18nFilter("translations.education_edit_gui_tooltip_info_visible_available"),
            displayOnly: i18nFilter("translations.education_edit_gui_tooltip_info_visible_not_available"),
            enrolledAndOwnersOnly: i18nFilter("translations.education_edit_gui_tooltip_info_visible_to_enrolled_users_and_owners"),
            forOrganizationOnly: i18nFilter("translations.education_edit_gui_tooltip_info_visible_to_organization"),
            forEverybody: i18nFilter("translations.education_edit_gui_tooltip_info_visible_to_all")
        };

        vm.formData = {
            visibility: onlineEducationVisibility,
            educationAccessMode: educationAccessMode,
            educationExecutionStyle: translateCollections(educationExecutionStyle),
            estDurationDays: translateCollections(estDurationDays),
            users: [],
            krpanAssociates: [],
            categories: categories,
            tags: tags,
            usersIsLoading: false,
            organizationsIsLoading: false,
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            competenceLevels: competenceLevels,
            targetSubgroups: targetSubgroups            
        };

        vm.showStatus = rolesDataService.isUserInRoles(['CatalogAdmin']);
        vm.logData = logData;
        vm.refreshUsers = refreshUsers;
        vm.refreshKrpanAssociates = refreshKrpanAssociates;
        vm.refreshDurations = refreshDurations;
        vm.changeDurationUnit = changeDurationUnit;
        vm.next = next;
        vm.save = save;
        vm.tagTransform = tagTransform;
        vm.cancel = cancel;
        vm.estDurationValidation = estDurationValidation;
        vm.tagValidation = tagValidation;
        vm.dateTime = new Date().getTime();
        vm.durationInHours = true;
        vm.selectDuration = selectDuration;
        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.isCatalogVisibilityInvalid = isCatalogVisibilityInvalid;

        if (vm.item.educationExecutionStyle && vm.item.educationExecutionStyle != null &&
            (vm.item.educationExecutionStyle === parseInt(vm.item.educationExecutionStyle, 10))) {
            vm.item.educationExecutionStyle = educationExecutionStyle.filter(function (x) {
                return x.id == vm.item.educationExecutionStyle;
            })[0];
        }

        if (vm.isEdit) {
            vm.isPartOfAnyActiveProgramAndStatusActive = vm.item.isPartOfAnyActiveProgramAndStatusActive;
            vm.durationInHours = vm.item.estDurationHour != null ? true : false;

            if (!vm.durationInHours) {
                switch (vm.item.estDurationDay) {
                    case 1:
                        vm.item.estDurationDays = vm.formData.estDurationDays[1];
                        break;

                    case -1:
                        vm.item.estDurationDays = vm.formData.estDurationDays[0];
                        break;

                    case -2:
                        vm.item.estDurationDays = vm.formData.estDurationDays[2];
                        break;

                    default:
                        vm.item.estDurationDays = { name: vm.item.estDurationDay + '-dnevna', value: vm.item.estDurationDay };
                }
            }
        }

        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////
        //#endregion

        //#region Logotype
        /////////////////////////// LOGOTYPE RESIZE ///////////////////////////////
        vm.myLogotype = '';

        var handleFileSelect2 = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.logotypeName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myLogotype = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput2')).on('change', handleFileSelect2);
        /////////////////////////// END LOGOTYPE RESIZE ///////////////////////////////
        //#endregion

        function tagTransform(tag) {
            return {
                name: tag,
                id: guidGeneratorDataService.getGuid()
            };
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            })

            return data;
        }

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function refreshKrpanAssociates(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];

            usersDataService.searchForActiveUsers(query, userIds, 2).then(function (data) {
                vm.formData.krpanAssociates = data;
            }, function (error) {
                logger.error(i18nFilter("translations.education_exception_message_fetching_krpan_associates"));
            });
        }

        function refreshDurations(query) {
            vm.formData.estDurationDays = /^\d+$/.test(query) ? [{ name: query + '-dnevno', value: parseInt(query) }] : estDurationDays;
        }

        function selectDuration() {
            vm.item.estDurationDay = vm.item.estDurationDays.value;
        }

        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.item.organizations = [];
        }

        function changeDurationUnit(isHour) {
            if (isHour) {
                vm.item.estDurationDay = null;
                vm.item.estDurationDays = null;
            }
            else 
                vm.item.estDurationHour = null;          
        }

        function next() {
            $state.go($scope.navigation[1].state);
        }

        function save() {
            vm.item.name = DOMPurify.sanitize(vm.item.name);
            vm.item.code = DOMPurify.sanitize(vm.item.code);
            vm.item.description = DOMPurify.sanitize(vm.item.description);

            var item_copy = editEducationHelper.setCodeListData(vm.item);
            editEducationHelper.setData(item_copy);
            editEducationHelper.done(vm.isEdit, true);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('educations.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.education');
            }
        }

        function estDurationValidation() {
            var days = !vm.item.estDurationDay ? 0 : parseInt(vm.item.estDurationDay);
            var hours = !vm.item.estDurationHour ? 0 : parseInt(vm.item.estDurationHour);

            var sum = days + hours;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }

        function tagValidation() {
            var isValid = false;
            angular.forEach(vm.item.tags, function (tag, index) {
                if (tag.id === contentTypeTags.educationTag || tag.id === contentTypeTags.educationExam) {
                    isValid = true;
                }
            });
            return isValid;
        }

        function isCatalogVisibilityInvalid() {
            if (vm.item.accessMode != vm.formData.educationAccessMode.enrolledUsersAndOwnersOnly) {
                return true;
            }

            return false;
        }
    }
})();
