(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('countriesDataService', countries);

    countries.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function countries($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getCountries: getCountries,
            getActiveCountries: getActiveCountries,
            getCountryById: getCountryById,
            deleteCountry: deleteCountry,
            addCountry: addCountry,
            editCountry: editCountry            
        };

        return service;

        function getCountries() {
            return $http.get('api/countries').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getActiveCountries() {
            return $http.get('api/countries/active').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCountryById(id) {
            return $http.get('api/countries/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function deleteCountry(id) {
            return $http.delete('api/countries/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCountry(country) {
            return $http.post('api/countries', country).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCountry(country) {
            return $http.put('api/countries', country).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();