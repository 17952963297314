(function() {
    'use strict';

    angular
        .module('app.exams.sidebar.info')
        .component('examInfo', {
            templateUrl: 'app/exams/sidebars/info/info.html',
            controller: info,
            controllerAs: 'vm',
            bindings: {
                examId: '='
            }
        });
    
    info.$inject = ['logger', 'exceptionHelper', 'examsDataService', '$uibModal'];

    function info(logger, exceptionHelper, examsDataService, $uibModal) {
        var vm = this;

        vm.info = undefined;

        vm.formData = {
            loading: true
        };

        examsDataService.getExamInfo(vm.examId).then(function (data) {
            vm.info = data;
            vm.formData.loading = false;
        }, function (error) {
            vm.formData.loading = false;
            exceptionHelper.handleException(error, true);
        });
    }
})();
