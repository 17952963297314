(function () {
    'use strict';

    angular
        .module('app.organizations')
        .controller('OrganizationDetails', details);

    details.$inject = ['logger', 'organization', 'organizationsDataService', 'i18nFilter', '$state'];

    function details(logger, organization, organizationsDataService, i18nFilter, $state) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = organization;
        vm.save = save;

        function save() {
            organizationsDataService.editOrganization(vm.item).then(function (additionalData) {
                $state.go( '^', {}, { reload: true });
            }, function (err) { });
        }
    }
})();
