(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .factory('editProgramHelper', editProgramHelper);

    editProgramHelper.$inject = ['logger', 'programsDataService', 'exceptionHelper', '$state', '$stateParams', 'i18nFilter', 'educationAccessMode'];

    function editProgramHelper(logger, programsDataService, exceptionHelper, $state, $stateParams, i18nFilter, educationAccessMode) {
        var internalData = undefined;
        var wizardSteps = undefined;

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            setData: setData,
            clearData: clearData,
            done: done
        };

        return service;


        function getData() {
            if (!internalData) {
                internalData = {
                    accessMode: educationAccessMode.publicAccess,
                    prices: [
                      
                    ],
                    educations: [],
                    categories: [],
                    catalogVisibility: true,
                    active: true,
                    fieldNames: [],
                    automaticApplicationConfirmation: false
                };
            }
            return internalData;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'programs.administration.edit.firstStep',
                        text: i18nFilter('translations.program_edit_wizard_first_step_title')
                    },
                    {
                        state: 'programs.administration.edit.secondStep',
                        text: i18nFilter('translations.program_edit_wizard_second_step_title')
                    },
                    {
                        state: 'programs.administration.edit.thirdStep',
                        text: i18nFilter('translations.program_edit_wizard_third_step_title')
                    },
                    {
                        state: 'programs.administration.edit.fourthStep',
                        text: i18nFilter('translations.program_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'programs.administration.edit.fifthStep',
                        text: i18nFilter('translations.program_edit_wizard_fifth_step_title')
                    }
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'addProgram.firstStep',
                        text: i18nFilter('translations.program_edit_wizard_first_step_title')
                    },
                    {
                        state: 'addProgram.secondStep',
                        text: i18nFilter('translations.program_edit_wizard_second_step_title')
                    },
                    {
                        state: 'addProgram.thirdStep',
                        text: i18nFilter('translations.program_edit_wizard_third_step_title')
                    },
                    {
                        state: 'addProgram.fourthStep',
                        text: i18nFilter('translations.program_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'addProgram.fifthStep',
                        text: i18nFilter('translations.program_edit_wizard_fifth_step_title')
                    }
                ];
            }


            return wizardSteps;
        }

        function setData(education) {
            internalData = education;
        }

        function clearData() {
            internalData = undefined;
        }

        function done(isEdit, isPartialSave) {
            //Call right data service method
            if (isEdit) {
                return programsDataService.editProgram(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.program_operation_edit_success_message', additionalData.name));
                    $state.go(isPartialSave ? $state.current : "programs.home", $stateParams, { reload: true });
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
            else {
                return programsDataService.addProgram(internalData).then(function (additionalData) {
                    logger.success(i18nFilter('translations.program_operation_add_success_message', additionalData.name));
                    $state.go("classicCatalog.program");
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }

    }
})();
