(function () {
    'use strict';

    angular
        .module('app.educations.activities')
        .component('virtualClassroomActivity', {
            templateUrl: 'app/educations/activities/components/virtual/virtualClassroomActivity.html',
            controller: virtualClassroom,
            controllerAs: 'vm',
            bindings: {
                activity: '=', //access via vm.activity
                learningActivityId: '=',
                ignoreFirstOpenRule: '='
            }
        });

    virtualClassroom.$inject = ['logger', 'learningActivitySuccess', 'learningActivityState', 'activitiesDataService', 'exceptionHelper', 'i18nFilter', '$state', '$interval', '$scope', 'dateHelper', 'learningPlanItemStatus'];

    function virtualClassroom(logger, learningActivitySuccess, learningActivityState, activitiesDataService, exceptionHelper, i18nFilter, $state, $interval, $scope, dateHelper, learningPlanItemStatus) {
        var vm = this;
        var ignoreNextToggle = false;

        //Dohvat lokalizacije koje su potrebne za GUI (većinom one koje se nalaze u uib-tooltipu)
        vm.localeMessages = {
            activityStart: i18nFilter('translations.activity_gui_tooltip_activity_classroom_start'),
            activityFinish: i18nFilter('translations.activity_gui_tooltip_activity_classroom_finish'),
            activityEstimatedDuration: i18nFilter('translations.activity_gui_tooltip_activity_estimated_duration'),
            activityScore: i18nFilter('translations.activity_gui_tooltip_activity_score'),
            activityPass: i18nFilter('translations.general_gui_success_pass'),
            activityFail: i18nFilter('translations.general_gui_success_fail'),
            activityTypeTooltip: i18nFilter('translations.filter_education_activity_type_virtual')
        };

        vm.formData = {
            //Flags
            isStarted: isActivityDateStarted(),
            isExpanded: vm.ignoreFirstOpenRule ? false : angular.copy(vm.activity.isOpen),
            isLate: isUserLate(),
            //Data
            learningActivitySuccess: learningActivitySuccess,
            learningActivityState: learningActivityState,
            showJoinMeetingAction: false,
            learningPlanItemStatus: learningPlanItemStatus
        };

        vm.toggle = toggle;
        vm.goToChoosingSchedules = goToChoosingSchedules;
        vm.startMeeting = startMeeting;

        // Otvori aktivnost ukoliko je prethodno bila odabrana
        if (vm.learningActivityId && vm.activity.learningActivityId === vm.learningActivityId) {
            vm.formData.isExpanded = true;
        }

        //Otvori dostupne termine
        function goToChoosingSchedules() {
            $state.go('educations.schedules', { learningActivityId: vm.activity.learningActivityId, activityName: vm.activity.name });
        }

        // Handlanje klika za expand
        function toggle() {
            if (!ignoreNextToggle) {
                vm.formData.isExpanded = !vm.formData.isExpanded;
            }
            else {
                ignoreNextToggle = false;
            }
        }

        function isActivityDateStarted() {
            if (vm.activity.data.startDate !== undefined) {
                vm.activity.data.startDate = dateHelper.convertFromStringToDate(vm.activity.data.startDate);
                var date = angular.copy(vm.activity.data.startDate);
                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "true" tako da se može pokrenuti
                return true;
            }
        }

        function isUserLate() {
            if (vm.activity.deadLine) {
                vm.activity.deadLine = dateHelper.convertFromStringToDate(vm.activity.deadLine);
                var date = angular.copy(vm.activity.deadLine);
                date.setHours(0, 0, 0, 0);
                return date.getTime() < new Date().getTime();
            }
            else {
                // Ako aktivnost nema definiran početak, vraćamo "false" tako da ispadne da ne kasni
                return false;
            }
        }

        function startMeeting() {
            ignoreNextToggle = true;
            window.open(vm.activity.additionalData.enrolledSchedule.meetingId, "_blank");
        }

        var interval = $interval(function () {
            logger.log("Ušao u interval");
            //Dohvati početno vrijeme termina umanji ga za 30 min i provjeri je li manje ili jednako trenutnom vremenu
            if (vm.activity.additionalData.enrolledSchedule && vm.activity.additionalData.enrolledSchedule.startTime) {
                var startTime = new Date(vm.activity.additionalData.enrolledSchedule.startTime.getTime() - 30 * 60000);
                if (startTime.getTime() <= new Date().getTime()) {
                    vm.formData.showJoinMeetingAction = true;
                    $interval.cancel(interval);
                }
            }
        }, 1000);

        $scope.$on('$destroy', function () {
            if (interval)
                $interval.cancel(interval);
        });
    }
})();
