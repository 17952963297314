(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('exams.administration.edit', {
                url: '/edit',
                templateUrl: 'app/exams/administration/edit/baseStep/edit.html',
                controller: 'EditExamBaseStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                    exam: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return examsDataService.getEditExam($stateParams.id);
                    }]
                }
            })
            .state('exams.administration.edit.firstStep', {
                url: '/about',
                templateUrl: 'app/exams/administration/edit/firstStep/firstStep.html',
                controller: 'EditExamFistStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    levels: ['levelsDataService', function (levelsDataService) {
                        return levelsDataService.getAllLevels();
                    }],
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveExamFieldNames();
                    }],
                    
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('exams.administration.edit.secondStep', {
                url: '/templates',
                templateUrl: 'app/exams/administration/edit/secondStep/secondStep.html',
                controller: 'EditExamSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    tenantInfo: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenantInfo();
                    }]
                }
            })
            .state('exams.administration.edit.thirdStep', {
                url: '/exam',
                templateUrl: 'app/exams/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditExamThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                    automaticCatalogues: ['cataloguesDataService', function (cataloguesDataService) {
                        return cataloguesDataService.getAutomaticCatalogues();
                    }],
                    themes: ['themesDataService', function (themesDataService) {
                        return themesDataService.getActiveThemes();
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }]
                }
            })
            .state('exams.administration.edit.fourthStep', {
                url: '/participants',
                templateUrl: 'app/exams/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditExamFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                }
            })
            .state('exams.administration.edit.fifthStep', {
                url: '/prices',
                templateUrl: 'app/exams/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditExamFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'ExamAdmin', 'ExamOwner']
                },
                resolve: {
                }
            })
            .state('addExam', {
                url: '/addExam',
                parent: 'base',
                templateUrl: 'app/exams/administration/edit/baseStep/edit.html',
                controller: 'EditExamBaseStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                    exam: ['examsDataService', '$stateParams', function (examsDataService, $stateParams) {
                        return {};
                    }]
                }
            })

            .state('addExam.firstStep', {
                url: '/about',
                templateUrl: 'app/exams/administration/edit/firstStep/firstStep.html',
                controller: 'EditExamFistStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    levels: ['levelsDataService', function (levelsDataService) {
                        return levelsDataService.getAllLevels();
                    }],
                    fieldNames: ['examsDataService', function (examsDataService) {
                        return examsDataService.getActiveExamFieldNames();
                    }],

                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('addExam.secondStep', {
                url: '/templates',
                templateUrl: 'app/exams/administration/edit/secondStep/secondStep.html',
                controller: 'EditExamSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    tenantInfo: ['tenantDataService', function (tenantDataService) {
                        return tenantDataService.getTenantInfo();
                    }]
                }
            })
            .state('addExam.thirdStep', {
                url: '/exam',
                templateUrl: 'app/exams/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditExamThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                    automaticCatalogues: ['cataloguesDataService', function (cataloguesDataService) {
                        return cataloguesDataService.getAutomaticCatalogues();
                    }],
                    themes: ['themesDataService', function (themesDataService) {
                        return themesDataService.getActiveThemes();
                    }],
                    locations: ['locationsDataService', function (locationsDataService) {
                        return locationsDataService.getRegularLocations();
                    }]
                }
            })
            .state('addExam.fourthStep', {
                url: '/participants',
                templateUrl: 'app/exams/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditExamFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                }
            })
            .state('addExam.fifthStep', {
                url: '/prices',
                templateUrl: 'app/exams/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditExamFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'ExamAdmin']
                },
                resolve: {
                }
            })
            ;
    }
})();
