(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackArrangements', examSchedulePackArrangements);

    examSchedulePackArrangements.$inject = ['examApplicationLearnersActivites', 'examArrangement', 'scheduleActivities', 'locations', 'recorders', 'chairmen', 'preparers', 'evaluators', 'activityType', 'examApplicationLearners', 'themesGroupedByCommissionMember', 'examSchedulesDataService', 'commissionMemberType', 'examNameAndScheduleCode', '$scope', '$state', '$stateParams', '$uibModal', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'documentsDataService', 'toastr', 'workerStatus', 'notificationService', 'dateHelper'];

    function examSchedulePackArrangements(examApplicationLearnersActivites, examArrangement, scheduleActivities, locations, recorders, chairmen, preparers, evaluators, activityType, examApplicationLearners, themesGroupedByCommissionMember, examSchedulesDataService, commissionMemberType, examNameAndScheduleCode, $scope, $state, $stateParams, $uibModal, i18nFilter, dateFilter, exceptionHelper, logger, documentsDataService, toastr, workerStatus, notificationService, dateHelper) {
        /* jshint validthis:true */
        var vm = this;

        vm.workerStatus = workerStatus;

        vm.noTravelOrderSet = false;
        vm.saveCommissionError = false;
        vm.entityId = $stateParams.entityId;
        vm.alreadyGradedLearnersIds = examApplicationLearnersActivites.filter(eal => eal.examApplicationLearnerAlreadyGraded == false).map(eal => eal.examApplicationLearnerId);

        vm.formData = {
            noCandidatesData: examApplicationLearners.length == 0,
            locations: locations,
            recorders: recorders,
            chairmen: chairmen,
            preparers: preparers,
            evaluators: evaluators,
            activityType: activityType,
            activityTypes: [
                {
                    id: 1,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_written')
                },
                {
                    id: 2,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_verbal')
                },
                {
                    id: 3,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_eExam')
                }
            ],
            commissionMemberTypes: [
                {
                    id: 0,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_president')
                },
                {
                    id: 1,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_member')
                },
                {
                    id: 2,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_independent_expert')
                }
            ],
            commissionMemberColumns: [
                i18nFilter('translations.exam_arrangements_gui_label_commission_member_name'),
                i18nFilter('translations.exam_arrangements_gui_label_commission_member_type'),
                i18nFilter('translations.exam_arrangements_gui_label_commission_member_themes'),
                i18nFilter('translations.general_gui_actions')
            ],
            noData: i18nFilter('translations.exam_arrangements_gui_warning_no_data')
        };

        vm.lookup = {};
        for (var i = 0, len = vm.formData.commissionMemberTypes.length; i < len; i++) {
            vm.lookup[vm.formData.commissionMemberTypes[i].id] = vm.formData.commissionMemberTypes[i].format;
        }

        vm.isEdit = $state.current.data.isEdit;
        if (vm.isEdit) {
            vm.item = examArrangement;
            vm.item.scheduleActivities.forEach(function (activity, indexActivity) {
                activity.commissionMembersValueStatus = [];
                activity.candidatesValueStatus = [];
                // Pool of ids of active candidates for this activity
                activity.activeLearners = new Set();
                if (activity.activityType != vm.formData.activityType.eExam) {                    
                    var activityThemeIds = activity.themes.map(x => x.id);
                    activity.commissions.forEach(function (commission, indexCommission) {
                        commission.startDateConfig = {
                            type: 'datetime',
                            width: '100%',
                            acceptCustomValue: true,
                            placeholder: "DD.MM.YYYY HH:MM",
                            min: new Date(1900, 0, 1),
                            max: new Date(3000, 0, 1),
                            onKeyDown: function (e) {
                                const input = document.getElementsByName('examActivity[' + indexActivity + '].commission[' + indexCommission + '].startDate')[1];
                                dateHelper.onDateTimeInputKeyDown(e.event, input);
                            },
                            inputAttr: {
                                'class': 'commission-start-date',
                                'name': 'examActivity[' + indexActivity + '].commission[' + indexCommission + '].startDate'
                            },
                            bindingOptions: {
                                value: 'vm.item.scheduleActivities[' + indexActivity + '].commissions[' + indexCommission + '].startDate'
                            },
                            calendarOptions: {
                                min: examNameAndScheduleCode.startDate
                            }
                        };

                        ///// compute commission members /////
                        commission.commissionMembers.forEach(function (commissionMemberVM) {
                            commissionMemberVM.commissionMember.active = true;
                        });
                        var tmpThemesGroupedByCommissionMember = angular.copy(themesGroupedByCommissionMember);
                        tmpThemesGroupedByCommissionMember.forEach(function (themeCommissionMemberVM) {
                            var found = false;
                            commission.commissionMembers.forEach(function (commissionMemberVM) {
                                if (commissionMemberVM.commissionMember.id == themeCommissionMemberVM.commissionMember.id) {
                                    found = true;
                                }
                            });
                            var memberThemeIds = themeCommissionMemberVM.themes.map(x => x.id);
                            if (!found && (memberThemeIds.some(x => activityThemeIds.includes(x)) || themeCommissionMemberVM.isIndependentExpert)) {
                                themeCommissionMemberVM.commissionMember.active = false;
                                themeCommissionMemberVM.commissionMember.toBeAdded = true;
                            }
                        });
                        tmpThemesGroupedByCommissionMember.forEach(function (themeCommissionMemberVM) {
                            if (themeCommissionMemberVM.commissionMember.toBeAdded) {
                                commission.commissionMembers.push(themeCommissionMemberVM);
                            }
                        });
                        commission.commissionMembers.sort((a, b) => a.commissionMember.displayName.localeCompare(b.commissionMember.displayName));
                        updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
                        updateCommissionMembersGrid(indexActivity, indexCommission);
                        updateCommissionMembersValueStatus(indexActivity, indexCommission, true);
                        ///// compute commission members /////


                        ///// compute candidates /////
                        commission.candidates.forEach(function (candidateVM) {
                            candidateVM.candidate.active = true;
                            // Add an active candidate to the pool of active learners
                            activity.activeLearners.add(candidateVM.candidate.id);
                        });
                        var tmpExamApplicationLearners = angular.copy(examApplicationLearners);
                        tmpExamApplicationLearners.forEach(function (examApplicationLearner) {
                            var found = false;
                            commission.candidates.forEach(function (candidateVM) {
                                if (candidateVM.candidate.id == examApplicationLearner.id) {
                                    found = true;
                                }
                            });
                            if (!found) {
                                examApplicationLearner.active = false;
                                examApplicationLearner.toBeAdded = true;

                                // Remove an inactive candidate from the pool of active learners
                                activity.activeLearners.delete(examApplicationLearner.id);
                            }
                        });
                        tmpExamApplicationLearners.forEach(function (examApplicationLearner) {
                            if (examApplicationLearner.toBeAdded) {
                                delete examApplicationLearner.toBeAdded;
                                commission.candidates.push({ 'candidate': examApplicationLearner });
                            }
                        });
                        commission.candidates.sort((a, b) => a.candidate.userDisplayName.localeCompare(b.candidate.userDisplayName));
                        updateCandidatesGrid(indexActivity, indexCommission);
                        updateCandidatesValueStatus(indexActivity, indexCommission, true);
                        ///// compute candidates /////
                    });
                } else if (activity.activityType == vm.formData.activityType.eExam) {
                    if (activity.preparersAndEvaluators != null && activity.preparersAndEvaluators.length > 0) {
                        if (examApplicationLearners != null && examApplicationLearners.length > 0) {
                            examApplicationLearners.forEach(function (candidate) {
                                var found = false;
                                activity.preparersAndEvaluators.forEach(function (preparerAndEvaluator) {
                                    if (preparerAndEvaluator.candidate.id == candidate.id) {
                                        found = true;
                                    }
                                });
                                if (!found) {
                                    activity.preparersAndEvaluators.push({
                                        candidate: candidate,
                                        preparer: preparers[Math.floor(Math.random() * preparers.length)],
                                        evaluator: evaluators[Math.floor(Math.random() * evaluators.length)]
                                    });
                                }
                            });
                            activity.preparersAndEvaluators.sort((a, b) => a.candidate.userDisplayName.localeCompare(b.candidate.userDisplayName));
                        }
                    } else {
                        addPreparersAndEvaluators(activity);
                    }
                }
            });
        } else {
            vm.item = {
                examScheduleId: $stateParams.schedulePackId,
                scheduleActivities: scheduleActivities
            };
            vm.item.scheduleActivities.forEach(function (activity) {
                activity.commissions = [];
                activity.commissionMembersValueStatus = [];
                activity.candidatesValueStatus = [];
                addPreparersAndEvaluators(activity);
                // Pool of active candidates for this activity
                activity.activeLearners = new Set();
            });
        }
        computeCandidateColumns();

        vm.item.nameAndCode = examNameAndScheduleCode.examCode + "-" + examNameAndScheduleCode.code;
        vm.item.examCode = examNameAndScheduleCode.examCode;

        vm.addCommission = addCommission;
        vm.addCommissions = addCommissions;
        vm.copyCommissions = copyCommissions;
        vm.removeCommission = removeCommission;

        vm.addCommissionMembers = addCommissionMembers;
        vm.editCommissionMembers = editCommissionMembers;
        vm.removeCommissionMember = removeCommissionMember;

        vm.addIndependentExperts = addIndependentExperts;

        vm.addCandidates = addCandidates;
        vm.editCandidates = editCandidates;
        vm.removeCandidate = removeCandidate;

        vm.updateCommissionMembersValueStatus = updateCommissionMembersValueStatus;
        vm.suggestCommissionMembers = suggestCommissionMembers;
        vm.suggestCommissionMembersDisabled = suggestCommissionMembersDisabled;
        vm.updateCandidatesValueStatus = updateCandidatesValueStatus;
        vm.suggestCandidates = suggestCandidates;
        vm.generateTravelOrderDocument = generateTravelOrderDocument;

        vm.emailCommissionMembers = emailCommissionMembers;

        vm.emailAllMembers = emailAllMembers;

        vm.save = save;
        vm.showDocuments = showDocuments;
        vm.showCommissionDocuments = showCommissionDocuments;
        vm.exitDocumentState = exitDocumentState;
        vm.updateCapacity = updateCapacity;

        vm.item.listOfDeleted = [];

        vm.startDatesValid = startDatesValid;
        vm.missingCellsInCandidatesTable = missingCellsInCandidatesTable;

        vm.creatingCommissionsInProgress = false;

        vm.localeMessages2 = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            downloadTravelOrderTooltip: i18nFilter('translations.exams_commission_gui_label_download_travel_order'),
            inappropriateCommMembWarning: i18nFilter('translations.exams_commission_inappropriate_commission_members_warning'),
            notAllThemesProcessed: i18nFilter('translations.exams_commission_not_all_themes_processed')
        };

        vm.item.scheduleActivities.forEach(function (activity, indexActivity) {
            var type = vm.formData.activityTypes.find(function (element) {
                return element.id == activity.activityType;
            });
            activity.activityTypeName = type.format;
        });

        function addPreparersAndEvaluators(activity) {
            activity.preparersAndEvaluators = [];
            examApplicationLearners.forEach(function (candidate) {
                activity.preparersAndEvaluators.push({
                    candidate: candidate,
                    preparer: preparers[Math.floor(Math.random() * preparers.length)],
                    evaluator: evaluators[Math.floor(Math.random() * evaluators.length)]
                });
            });
            activity.preparersAndEvaluators.sort((a, b) => a.candidate.userDisplayName.localeCompare(b.candidate.userDisplayName));
        }

        function startDatesValid() {
            // date fields require custom validation
            var dateFields = $(".commission-start-date");
            var isValid = true;
            dateFields.each(function (index, input) {
                if (!input.value)
                    isValid = false;
            });
            return isValid;
        }

        function addCommission(indexActivity) {
            if (!vm.item.scheduleActivities[indexActivity].commissions) {
                vm.item.scheduleActivities[indexActivity].commissions = [];
            }
            var indexCommission = vm.item.scheduleActivities[indexActivity].commissions.length;
            var obj = {
                startDateConfig: {
                    type: 'datetime',
                    width: '100%',
                    acceptCustomValue: false,
                    inputAttr: {
                        'name': 'examActivity[' + indexActivity + '].commission[' + indexCommission + '].startDate',
                        'class': 'commission-start-date'
                    },
                    bindingOptions: {
                        value: 'vm.item.scheduleActivities[' + indexActivity + '].commissions[' + indexCommission + '].startDate'
                    },
                    calendarOptions: {
                        min: examNameAndScheduleCode.startDate
                    }
                },
                intervalOrDuration: vm.item.scheduleActivities[indexActivity].examDuration,
                commissionMembers: [],
                commissionMembersGroupedByTheme: [],
                candidates: [],
                candidateColumns: [i18nFilter('translations.exam_arrangements_gui_label_candidate_name')],
                candidatesHideSuggButton: true,
                commissionMembersHideSuggButton: true
            };

            // we need to set it's candidates and commission members, otherwise suggest- buttons won't work
            // add possible commission members to commission
            let commissionMembersCopy = angular.copy(themesGroupedByCommissionMember);
            commissionMembersCopy.forEach(function (commissionMember) {
                commissionMember.active = false;
                obj.commissionMembers.push(commissionMember);
            });

            // add possible candidates to commission
            let learnersCopy = angular.copy(examApplicationLearners);
            learnersCopy.forEach(function (learner) {
                learner.active = false;
                obj.candidates.push({ 'candidate': learner});
            });

            vm.item.scheduleActivities[indexActivity].commissions.push(obj);
        }

        function addCommissions(indexActivity) {

            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/addCommissions/addCommissions.html',
                controller: 'addCommissions',
                controllerAs: 'vm',
                resolve: {
                    'examNameAndScheduleCode': function () {
                        return examNameAndScheduleCode;
                    },
                    'scheduleActivity': function () {
                        return vm.item.scheduleActivities[indexActivity];
                    },
                    locationCount: ['examSchedulesDataService', function (examSchedulesDataService) {
                        return examSchedulesDataService.getActivityLocations(vm.item.scheduleActivities[indexActivity].activityId);
                    }]
                }
            })
            .result.then(function (result) {
                // after clicking OK button
                var otherCommisions = vm.item.scheduleActivities[indexActivity].commissions.filter(function (commission, index) {
                    return !commission.deleted;
                });

                // Existing candidates on other commissions
                var existingCandidatesOnOtherCommissions = [];

                for (var commission of otherCommisions) {
                    for (var candidate of commission.candidates) {
                        // If the candidate doesen't already exist in the pool of existing candidates and he's active
                        if (existingCandidatesOnOtherCommissions.indexOf(candidate.candidate.id) === -1 && candidate.candidate.active == true) {
                            existingCandidatesOnOtherCommissions.push(candidate.candidate.id);
                        }
                    }
                }

                result.activeLearners = existingCandidatesOnOtherCommissions;

                examSchedulesDataService.addCommissionsForActivity(result).then(function (res) {
                    var commissionsCount = vm.item.scheduleActivities[indexActivity].commissions.length;
                    if (res) {
                        res.forEach(function (commission, indexCommission) {
                            commission.startDateConfig = {
                                type: 'datetime',
                                width: '100%',
                                acceptCustomValue: true,
                                placeholder: "DD.MM.YYYY HH:MM",
                                min: new Date(1900, 0, 1),
                                max: new Date(3000, 0, 1),
                                onKeyDown: function (e) {
                                    const input = document.getElementsByName('examActivity[' + indexActivity + '].commission[' + (commissionsCount + indexCommission) + '].startDate')[1];
                                    dateHelper.onDateTimeInputKeyDown(e.event, input);
                                },
                                inputAttr: {
                                    'class': 'commission-start-date',
                                    'name': 'examActivity[' + indexActivity + '].commission[' + (commissionsCount + indexCommission) + '].startDate'
                                },
                                bindingOptions: {
                                    value: 'vm.item.scheduleActivities[' + indexActivity + '].commissions[' + (commissionsCount + indexCommission) + '].startDate'
                                },
                                calendarOptions: {
                                    min: examNameAndScheduleCode.startDate
                                }
                            };

                            ///// compute candidates /////
                            commission.candidates.forEach(function (candidateVM) {
                                candidateVM.candidate.active = true;
                                // Add an active candidate to the pool of active learners
                                vm.item.scheduleActivities[indexActivity].activeLearners.add(candidateVM.candidate.id);
                            });

                            vm.item.scheduleActivities[indexActivity].commissions.push(commission);
                            updateCandidatesGrid(indexActivity, commissionsCount + indexCommission);

                            updateCandidatesValueStatus(indexActivity, indexCommission, true);
                        });

                        if (result.suggestCommissionMembers) 
                            suggestCommissionMembers(indexActivity);
                        
                    }
                })

            }, function () {
                // after clicking Cancel button or clicking background

            });
        }

        function removeCommission(indexActivity, indexCommission) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/removeCommission/removeCommission.html',
                controller: 'removeCommission',
                controllerAs: 'vm'
            })
            .result.then(function (results) {
                // after clicking OK button
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].deleted = true;

                if (vm.item.scheduleActivities[indexActivity].DeletedCommissionSendEmails === undefined) {
                    vm.item.scheduleActivities[indexActivity].DeletedCommissionSendEmails = []
                }
                vm.item.scheduleActivities[indexActivity].DeletedCommissionSendEmails.push({
                    id: vm.item.scheduleActivities[indexActivity].commissions[indexCommission].id,
                    sendEmail: results
                });

            }, function () {
                // after clicking Cancel button or clicking background

            });
            themesGroupedByCommissionMember.forEach(function (themeCommissionMemberVM) {
                if (themeCommissionMemberVM.commissionMember.toBeAdded) {
                    commission.commissionMembers.push(themeCommissionMemberVM);
                }
            });
        }

        function addCommissionMembers(indexActivity, indexCommission) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/addCommissionMembers/addCommissionMembers.html',
                controller: 'addCommissionMembers',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'themesGroupedByCommissionMember': function () {
                        // Getting a copy of all commission members on this exam
                        var themesGroupedByCommissionMemberCopy = angular.copy(themesGroupedByCommissionMember);
                        themesGroupedByCommissionMemberCopy.forEach(function (themesGroupedByCommissionMemberVM) {
                            themesGroupedByCommissionMemberVM.themes = themesGroupedByCommissionMemberVM.themes.filter(function (theme) {
                                if (!vm.isEdit) return theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].id);
                                return theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].activityId);
                            });
                        });
                        return themesGroupedByCommissionMemberCopy.filter(function (themesGroupedByCommissionMemberVM) {
                            return themesGroupedByCommissionMemberVM.themes.length > 0 || themesGroupedByCommissionMemberVM.isIndependentExpert;
                        });
                    },
                    'existingCommissionMembers': function () {
                        // Getting a copy of relevant commission members (tied to this commission)
                        var themesGroupedByCommissionMemberCopy = angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers);
                        themesGroupedByCommissionMemberCopy.forEach(function (themesGroupedByCommissionMemberVM) {
                            themesGroupedByCommissionMemberVM.themes = themesGroupedByCommissionMemberVM.themes.filter(function (theme) {
                                if (vm.item.scheduleActivities[indexActivity].activityId != null) {
                                    return theme.activityIds != null && theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].activityId);
                                } else {
                                    return theme.activityIds != null && theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].id);
                                }
                            });
                        });
                        return themesGroupedByCommissionMemberCopy.filter(function (themesGroupedByCommissionMemberVM) {
                            return themesGroupedByCommissionMemberVM.themes.length > 0 || themesGroupedByCommissionMemberVM.isIndependentExpert;
                        });
                    }
                }
            })
            .result.then(function (results) {
                // after clicking OK button
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers = results.commissionMembersThemes;

                updateCandidatesWithCommissionMember(indexActivity, indexCommission, results.commissionMembersThemes[results.index]);

                updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
                updateCommissionMembersGrid(indexActivity, indexCommission);

                updateCandidatesGrid(indexActivity, indexCommission);

                updateCommissionMembersValueStatus(indexActivity, indexCommission, true);
                updateCandidatesValueStatus(indexActivity, indexCommission, false);
            }, function () {
                // after clicking Cancel button or clicking background
                });

            //save(false);
        }

        function addIndependentExperts(indexActivity, indexCommission) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/addIndependentExperts/addIndependentExperts.html',
                controller: 'addIndependentExperts',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'commissionMembers': function () {
                        let existingCommissionMembers = angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers);
                        // commission members for commission will be empty when it's a new commission
                        if (existingCommissionMembers == null || existingCommissionMembers.length === 0)
                            // so we use the other table of commission members which is populated on ADD (but not updated afterwards)
                            existingCommissionMembers = angular.copy(themesGroupedByCommissionMember);

                        return existingCommissionMembers;
                    }
                }
            })
                .result.then(function (results) {
                    // after clicking OK button
                    vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers = results.commissionMembersThemes;

                    updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
                    updateCommissionMembersGrid(indexActivity, indexCommission);

                    updateCandidatesGrid(indexActivity, indexCommission);

                    updateCommissionMembersValueStatus(indexActivity, indexCommission, true);
                }, function () {
                    // after clicking Cancel button or clicking background

                });
        }

        function removeCommissionMember(indexActivity, indexCommission, commissionMember) {
            var commission = vm.item.scheduleActivities[indexActivity].commissions[indexCommission];

            for (var i = 0, len = commission.commissionMembers.length; i < len; i++) {
                let memberVM = commission.commissionMembers[i];
                if (commissionMember.id == memberVM.commissionMember.id) {
                    memberVM.commissionMember.active = false;
                    if (memberVM.themes.length > 0) {
                        memberVM.themes.forEach(function (theme) {
                            // Find and remove any 'theme' entries in candidates themes
                            for (var candidateVM of commission.candidates) {
                                // If the candidate doesen't already exist in the pool of existing candidates and he's active
                                if (candidateVM.candidate.active && candidateVM.themesCommissionMembers != null) {
                                    for (let j = 0; j < candidateVM.themesCommissionMembers.length; j++) {
                                        var themeCommMemb = candidateVM.themesCommissionMembers[j];
                                        // Find the theme on a candidate, that the removed commission member was covering
                                        if (themeCommMemb.theme.id == theme.id) {
                                            themeCommMemb.commissionMembers.forEach(function (commMemb) {
                                                if (commissionMember.id == commMemb.id) {
                                                    // Found a commission member and his theme for this candidate
                                                    // => delete it's commission member array
                                                    // (may have more comm memb entires than one! although it's intended use is single comm memb)
                                                    themeCommMemb.commissionMembers = [];
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                    break;
                }
            }



            updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
            updateCommissionMembersGrid(indexActivity, indexCommission);
            updateCommissionMembersValueStatus(indexActivity, indexCommission, false);

            updateCandidatesGrid(indexActivity, indexCommission);
            updateCandidatesValueStatus(indexActivity, indexCommission, false);

            save(false);
        }

        function addCandidates(indexActivity, indexCommission) {
            var examApplicationLearnersCopy = angular.copy(examApplicationLearners);
            var existingCandidatesCopy = angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates);

            var otherCommisions = vm.item.scheduleActivities[indexActivity].commissions.filter(function (commission, index) {
                return index != indexCommission && !commission.deleted;
            });

            // Existing candidates on other commissions
            var existingCandidatesOnOtherCommissions = [];

            for (var commission of otherCommisions) {
                for (var candidate of commission.candidates) {
                    // If the candidate doesen't already exist in the pool of existing candidates and he's active
                    if (existingCandidatesOnOtherCommissions.indexOf(candidate.candidate.userId) === -1 && candidate.candidate.active == true) {
                        existingCandidatesOnOtherCommissions.push(candidate.candidate.userId);
                    }
                }
            }

            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/addCandidates/addCandidates.html',
                controller: 'addCandidates',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'commissionMembersGroupedByTheme': function () {
                        let cMembersGroupedByTheme = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme;
                        // above is undefined in case we open with edit a commission without commission members
                        if (cMembersGroupedByTheme == null)
                            cMembersGroupedByTheme = [];
                        return cMembersGroupedByTheme;
                    },
                    'examApplicationLearners': function () {
                        return examApplicationLearners.filter(eal => vm.alreadyGradedLearnersIds.indexOf(eal.id) === -1);
                    },
                    'existingCandidates': function () {
                        return vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates.filter(eal => vm.alreadyGradedLearnersIds.indexOf(eal.candidate.id) === -1);
                    },
                    'existingCandidatesOnOtherCommissions': function () {
                        return existingCandidatesOnOtherCommissions;
                    },
                    'examSchedulesDataService': function () {
                        return examSchedulesDataService;
                    },
                    'examScheduleId': function () {
                        return vm.item.examScheduleId;
                    },
                    'activity': function () {
                        return vm.item.scheduleActivities[indexActivity];
                    },
                    'commission': function () {
                        return vm.item.scheduleActivities[indexActivity].commissions[indexCommission];
                    }
                }
            })
            .result.then(function (result) {
                // after clicking OK button
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates = result;

                updateCandidatesGrid(indexActivity, indexCommission);
                updateCandidatesValueStatus(indexActivity, indexCommission, true);

                updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
                updateCommissionMembersGrid(indexActivity, indexCommission);
                updateCommissionMembersValueStatus(indexActivity, indexCommission, false);
            }, function () {
                // after clicking Cancel button or clicking background

                // reset all changes
                examApplicationLearners = angular.copy(examApplicationLearnersCopy);
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates = angular.copy(existingCandidatesCopy);

                updateCandidatesGrid(indexActivity, indexCommission);
                });

           // save(false);
        }

        vm.cells = new Array(0);
        function missingCellsInCandidatesTable(headerLength, cellLength) {
            // this function generates however many empty cells are needed to fill in the candidates table

            var x = headerLength - cellLength - 2;
            if (x != vm.cells.length)
                vm.cells = Array.from(Array(x).keys());

            return vm.cells;            
        }

        // Removing a candidate from a commission
        function removeCandidate(indexActivity, indexCommission, candidate) {
            for (let i = 0; i < vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates.length; i++) {
                if (vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates[i].candidate.id == candidate.id) {
                    vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates[i].candidate.active = false;
                    // Remove candidate from activity active pool
                    vm.item.scheduleActivities[indexActivity].activeLearners.delete(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates[i].candidate.id);
                    delete vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates[i].themesCommissionMembers;
                    break;
                }
            }

            updateCandidatesGrid(indexActivity, indexCommission);
            updateCandidatesValueStatus(indexActivity, indexCommission, false);
            updateCommissionMembersValueStatus(indexActivity, indexCommission, false);

            save(false);
        }

        function editCommissionMembers(indexActivity, indexCommission, commissionMember) {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/editCommissionMembers/editCommissionMembers.html',
                controller: 'editCommissionMembers',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'existingCommissionMembers': function () {
                        var themesGroupedByCommissionMemberCopy = angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers);
                        themesGroupedByCommissionMemberCopy.forEach(function (themesGroupedByCommissionMemberVM) {
                            themesGroupedByCommissionMemberVM.themes = themesGroupedByCommissionMemberVM.themes.filter(function (theme) {
                                if (vm.item.scheduleActivities[indexActivity].activityId != null) {
                                    return theme.activityIds != null && theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].activityId);
                                } else {
                                    return theme.activityIds != null && theme.activityIds.includes(vm.item.scheduleActivities[indexActivity].id);
                                }
                            });
                        });
                        return themesGroupedByCommissionMemberCopy.filter(function (themesGroupedByCommissionMemberVM) {
                            return themesGroupedByCommissionMemberVM.themes.length > 0 || themesGroupedByCommissionMemberVM.isIndependentExpert;
                        });
                    },
                    'chosenCommissionMember': function () {
                        return commissionMember;
                    }
                }
            })
            .result.then(function (results) {
                // after clicking OK button
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers = results.commissionMembersThemes;

                updateCommissionMembersGroupedByTheme(indexActivity, indexCommission);
                updateCommissionMembersGrid(indexActivity, indexCommission);

                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates.forEach(function (candidateVM) {
                    delete candidateVM.themesCommissionMembers;
                });

                updateCandidatesGrid(indexActivity, indexCommission);
            }, function () {
                // after clicking Cancel button or clicking background

            });
        }

        function editCandidates(indexActivity, indexCommission, candidate) {
            var examApplicationLearnersCopy = angular.copy(examApplicationLearners);
            var existingCandidatesCopy = angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates);

            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/editCandidates/editCandidates.html',
                controller: 'editCandidates',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    'commissionMembersGroupedByTheme': function () {
                        let cMembersGroupedByTheme = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme;
                        // above is undefined in case we open with edit a commission without commission members
                        if (cMembersGroupedByTheme == null)
                            cMembersGroupedByTheme = [];
                        return cMembersGroupedByTheme;
                    },
                    'existingCandidates': function () {
                        return vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates;
                    },
                    'chosenCandidate': function () {
                        return candidate;
                    },
                    'examScheduleId': function () {
                        return vm.item.examScheduleId;
                    },
                    'activityId': function () {
                        return vm.item.scheduleActivities[indexActivity].activityId;
                    },
                    'examSchedulesDataService': function () {
                        return examSchedulesDataService;
                    }
                }
            })
            .result.then(function (results) {
                // after clicking OK button
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates = results;
                updateCandidatesGrid(indexActivity, indexCommission);
                updateCandidatesValueStatus(indexActivity, indexCommission, true);
            }, function () {
                // after clicking Cancel button or clicking background

                // reset all changes
                examApplicationLearners = angular.copy(examApplicationLearnersCopy);
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates = angular.copy(existingCandidatesCopy);

                // Updating candidates grid
                updateCandidatesGrid(indexActivity, indexCommission);
                updateCandidatesValueStatus(indexActivity, indexCommission, true);
            });
        }

        function copyCommissions(indexCurrentActivity) { //TODO
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/arrangements/copyCommissions/copyCommissions.html',
                controller: 'copyCommissions',
                controllerAs: 'vm',
                resolve: {
                    'choices': function () {
                        var choices = [];
                        vm.item.scheduleActivities.forEach(function (scheduleActivity, indexActivity) {
                            if (indexActivity != indexCurrentActivity && scheduleActivity.activityType != vm.formData.activityType.eExam && scheduleActivity.commissions.length != 0) {
                                choices.push({
                                    indexActivity: indexActivity,
                                    typeActivity: scheduleActivity.activityTypeName,
                                    nameActivity: scheduleActivity.name
                                });
                            }
                        });
                        return choices;
                    },
                    'currentActivity': function () {
                        return {
                            indexActivity: indexCurrentActivity,
                            typeActivity: vm.item.scheduleActivities[indexCurrentActivity].activityTypeName,
                            nameActivity: vm.item.scheduleActivities[indexCurrentActivity].name
                        };
                    }
                }
            })
                .result.then(function (indexChosenActivity) {
                    // after clicking OK button

                    var equal = true;
                    for (var i = 0, len = vm.item.scheduleActivities[indexChosenActivity].themes.length; i < len; i++) {
                        var found = false;
                        for (var j = 0, len2 = vm.item.scheduleActivities[indexCurrentActivity].themes.length; j < len2; j++) {
                            if (vm.item.scheduleActivities[indexChosenActivity].themes[i].id == vm.item.scheduleActivities[indexCurrentActivity].themes[j].id) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            equal = false;
                            break;
                        }
                    }

                    vm.item.scheduleActivities[indexCurrentActivity].commissions = angular.copy(vm.item.scheduleActivities[indexChosenActivity].commissions);
                    vm.item.scheduleActivities[indexCurrentActivity].commissions.forEach(function (commission, indexCommission) {
                        delete commission.id;
                        delete commission.startDate;
                        commission.startDateConfig.bindingOptions.value.dataPath = 'vm.item.scheduleActivities[' + indexCurrentActivity + '].commissions[' + indexCommission + '].startDate';

                    if (!equal) {
                        commission.commissionMembers = [];
                        commission.commissionMembersGroupedByTheme = [];
                        commission.candidates = [];
                        commission.candidateColumns = [i18nFilter('translations.exam_arrangements_gui_label_candidate_name')]; // Column name of the candidate row for candidate table
                        delete commission.chairman;
                        delete commission.dataGridCommissionMembers;
                        delete commission.dataGridCandidates;

                            commission.copyCommossionShowWarning = true;
                        }
                    });
                }, function () {
                    // after clicking Cancel button or clicking background

                });
        }

        function exitDocumentState() {
            if (vm.isEdit)
                $state.go("examSchedulePacks.arrangementsEdit", $stateParams);
        }

        function updateCommissionMembersValueStatus(indexActivity, indexCommission, adding) {
            var indexOf = vm.item.scheduleActivities[indexActivity].commissionMembersValueStatus.indexOf(indexCommission);
            var commission = vm.item.scheduleActivities[indexActivity].commissions[indexCommission];

            if (!adding && vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCommissionMembers.length == 0) {
                if (indexOf >= 0) {
                    vm.item.scheduleActivities[indexActivity].commissionMembersValueStatus.splice(indexOf, 1);
                }
            } else if (adding && vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCommissionMembers.length != 0) {
                if (indexOf < 0) {
                    vm.item.scheduleActivities[indexActivity].commissionMembersValueStatus.push(indexCommission);
                }
            }
            
            var foundInactiveCommissionMember = false;
            var foundActiveCommissionMember = false;
            var foundActiveCandidate = false;

            // Looking for inactive commission members, and making sure, there are none already in the grid (active)
            if (commission.commissionMembers != undefined) {
                for (let i = 0; i < commission.commissionMembers.length; i++) {
                    if (commission.commissionMembers[i].commissionMember.active != undefined && commission.commissionMembers[i].commissionMember.active) {
                        foundActiveCommissionMember = true;
                    } else {
                        foundInactiveCommissionMember = true;
                    }
                    if (foundActiveCommissionMember && foundInactiveCommissionMember) {
                        break;
                    }
                }
            }

            // Looking for an active candidate
            for (let i = 0; i < commission.candidates.length; i++) {
                if (commission.candidates[i].candidate.active) {
                    foundActiveCandidate = true;
                    break;
                }
            }

            // If we found an inactive commission member and an active candidate for this commission, and there was
            // no active commission members found, set the hide suggest commission members button flag to false, otherwise set it to true
            if (foundInactiveCommissionMember && foundActiveCandidate && !foundActiveCommissionMember) {
                commission.commissionMembersHideSuggButton = false;
            } else {
                commission.commissionMembersHideSuggButton = true;
            }
        }

        function suggestCommissionMembers(indexActivity) {
            var activity = vm.item.scheduleActivities[indexActivity];
            save(false, false).then(function () {
                examSchedulesDataService.suggestCommissionMembers(activity.examScheduleActivityId).then(function (data) {
                    logger.success(i18nFilter('translations.exam_arrangements_operation_edit_success_message'), data);
                    $state.go("examSchedulePacks.arrangementsEdit", $stateParams, { reload: true });               
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            });
        }
        function suggestCommissionMembersDisabled(indexActivity) {
            var activity = vm.item.scheduleActivities[indexActivity];

            // commissions are null OR commissions are empty OR all commissions are deleted
            let activityDoesNotHaveCommissions = activity.commissions == null || activity.commissions.length == 0 || activity.commissions.every(x => x.deleted);

            let notAllCommissionsHaveCandidates = true;
            // activity has commissions AND they all have candidates
            if (!activityDoesNotHaveCommissions)
                notAllCommissionsHaveCandidates = activity.commissions.some(x => (x.dataGridCandidates == null || x.dataGridCandidates.length === 0) && (x.deleted==false || x.deleted==null));

            return activityDoesNotHaveCommissions || notAllCommissionsHaveCandidates;
        }

        function updateCandidatesValueStatus(indexActivity, indexCommission, adding) {
            var indexOf = vm.item.scheduleActivities[indexActivity].candidatesValueStatus.indexOf(indexCommission);
            var activity = vm.item.scheduleActivities[indexActivity];

            // Process and update the suggest button on the commission, that just got suggested candidates from it's button
            if (!adding && vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCandidates.length == 0) {
                if (indexOf >= 0) {
                    vm.item.scheduleActivities[indexActivity].candidatesValueStatus.splice(indexOf, 1);
                }
            } else if (adding && vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCandidates.length != 0) {
                if (indexOf < 0) {
                    vm.item.scheduleActivities[indexActivity].candidatesValueStatus.push(indexCommission);
                }
            }
            
            // Process commissions
            activity.commissions.forEach(function (commission, index) {
                var foundActiveCommissionMember = false;
                var foundActiveCandidate = false;
                var foundInactiveCandidate = false;

                // Looking for inactive candidates, and making sure, there are none already in the grid (active)
                if (commission.candidates != null) {
                    for (let i = 0; i < commission.candidates.length; i++) {
                        if (commission.candidates[i].candidate.active != null && commission.candidates[i].candidate.active) {
                            foundActiveCandidate = true;
                        } else {
                            foundInactiveCandidate = true;
                        }
                        if (foundActiveCandidate && foundInactiveCandidate) {
                            break;
                        }
                    }
                }

                // Looking for active commission members (in the grid)
                if (commission.commissionMembers != null) {
                    for (let i = 0; i < commission.commissionMembers.length; i++) {
                        if (commission.commissionMembers[i].commissionMember.active != null && commission.commissionMembers[i].commissionMember.active) {
                            foundActiveCommissionMember = true;
                            break;
                        }
                    }
                }

                // If we haven't found an active commission member, set the suggest flag to true
                if (foundActiveCommissionMember) {
                    // if candidatesDifference is higher than 0, set the hide button flag to false.
                    // If we found one, set the suggest flag to true
                    // activeLearnersArray contains ids of all active learners from this activity (all commissions)
                    var activeLearnersArray = [...activity.activeLearners];
                    // candidatesDifference is an array of candidates, that have not been assigned to a commission for this activity yet
                    var candidatesDifference = commission.candidates.filter(candidate => !activeLearnersArray.includes(candidate.candidate.id));
                    // If difference is equal 0 (there are no inactive candidates)
                    if (candidatesDifference.length > 0 && !foundActiveCandidate && foundInactiveCandidate) {
                        vm.item.scheduleActivities[indexActivity].commissions[index].candidatesHideSuggButton = false;
                    } else {
                        vm.item.scheduleActivities[indexActivity].commissions[index].candidatesHideSuggButton = true;
                    }
                } else {
                    vm.item.scheduleActivities[indexActivity].commissions[index].candidatesHideSuggButton = true;
                }
            });
        }

        function suggestCandidates(indexActivity, indexCommission) {
            var candidateIds = [], inactiveCandidates = [], activeCommMemberIds = [];
            var commission = vm.item.scheduleActivities[indexActivity].commissions[indexCommission];
            var activeLearnersArray = [...vm.item.scheduleActivities[indexActivity].activeLearners];
            var nonGradedLearners = examApplicationLearners.filter(eal => vm.alreadyGradedLearnersIds.indexOf(eal.id) === -1);

            // Gathering active candidate ids for applied themes searching
            nonGradedLearners.forEach(function (candidate) {
                // Check if the candidate is already active in another commission
                if (!activeLearnersArray.includes(candidate.id)) {
                    candidateIds.push(candidate.id);
                    inactiveCandidates.push(candidate);
                }
            });

            commission.commissionMembers.forEach(function (commMember) {
                if (commMember.commissionMember.active != null && commMember.commissionMember.active) {
                    activeCommMemberIds.push(commMember.commissionMember.id);
                }
            });

            var commissionMembersWithLowestParticipationVM = [];
            var getCommissionMemberWithLowestParticipationPromise = examSchedulesDataService.getCommissionMemberWithLowestParticipation(vm.item.examScheduleId, activeCommMemberIds).then(function (commissionMemberWithLowestParticipation) {
                commissionMembersWithLowestParticipationVM = commissionMemberWithLowestParticipation;
            }, function (error) {
                exceptionHelper.handleException(error);
                });

            
            var u = 0, commissionCandidatesCopy = []; // Last candidate update counter
            inactiveCandidates.forEach(function (examApplicationLearner) {
                examApplicationLearnersActivites.forEach(function (examApplicationLearnerActivites) {

                    if (examApplicationLearnerActivites.examApplicationLearnerId == examApplicationLearner.id) {
                        examApplicationLearnerActivites.examApplicationLearnerActivityThemes.forEach(function (examApplicationLearnerActivityTheme) {

                            if (examApplicationLearnerActivityTheme.examApplicationLearnerActivityId == vm.item.scheduleActivities[indexActivity].activityId) {
                                // Getting applied themes for the exam learner
                                var appliedThemesLearnerPromise = examSchedulesDataService.getCandidatesActivityAppliedThemes(vm.item.examScheduleId, candidateIds, vm.item.scheduleActivities[indexActivity].activityId).then(function (candidatesAppliedThemes) {
                                    // Adding all candidates applied themes to the set 'appliedThemeIdSet' and to their attribute
                                    //examApplicationLearner.appliedThemes = (candidatesAppliedThemes != undefined ? candidatesAppliedThemes : []);
                                    candidatesAppliedThemes.forEach(function (candidateThemes) {
                                        inactiveCandidates.forEach(function (candidateVM) {
                                            if (examApplicationLearner.id == candidateVM.id && candidateVM.id == candidateThemes.learnerId) {
                                                candidateVM.appliedThemes = (candidateThemes.appliedThemes != null ? candidateThemes.appliedThemes : []);
                                                examApplicationLearner.appliedThemes = candidateVM.appliedThemes;
                                            }
                                        });
                                    });
                                }, function (error) {
                                    exceptionHelper.handleException(error);
                                });

                                // Initializing the candidate variable for inappropriate commission members
                                examApplicationLearner.foundActiveInappropriateCommMember = false;
                                var inappropriateCommMembIdsSet = new Set();
                                // include Innapropriate Users for appLearner only on verbal type activities
                                var includeAppLearnerInnapropriateUsers = vm.item.scheduleActivities[indexActivity].activityType == 2;
                                //get inappropriate commission members
                                var inappropriateLinkedPromise = examSchedulesDataService.getCandidatesInappropriateCommissionMembersLinked(vm.item.examScheduleId, [examApplicationLearner.id], includeAppLearnerInnapropriateUsers).then(function (inappropriateCommMembsLinked) {
                                    // Look for any active inappropriate commission members for the current candidate to be added
                                    // If the flag turnt true, the candidate isn't going to be added to the active pool
                                    commission.commissionMembers.forEach(function (commissionMemberVM) {
                                        if (commissionMemberVM.commissionMember.active && inappropriateCommMembsLinked.includes(commissionMemberVM.commissionMember.id)) {
                                            examApplicationLearner.foundActiveInappropriateCommMember = true;
                                        }
                                    });

                                    // All inappropriate previous commission members, for all candidates at once
                                    inappropriateCommMembsLinked.forEach(function (commissionMemberId) {
                                        inappropriateCommMembIdsSet.add(commissionMemberId);
                                    });
                                }, function (error) {
                                    exceptionHelper.handleException(error);
                                });

                                var completeWarning = "";
                                // Fulfilling promise
                                appliedThemesLearnerPromise.finally(function () {
                                    inappropriateLinkedPromise.finally(function () {
                                        getCommissionMemberWithLowestParticipationPromise.finally(function () {
                                            // Find a themeGroupedByCommMemb object for every theme (column) in the table for candidates
                                            // Can be a valid theme if it's applied for this candidate, or it could be a theme with empty commission member
                                            var themesCommissionMembers = [];
                                            for (let i = 0; i < commission.commissionMembersGroupedByTheme.length; i++) {
                                                var foundTheme = false;
                                                examApplicationLearner.appliedThemes.forEach(function (theme) {
                                                    // If we found out this theme is applied for this candidate
                                                    if (!foundTheme && theme.id == commission.commissionMembersGroupedByTheme[i].theme.id) {
                                                        var commMembTheme = commission.commissionMembersGroupedByTheme[i];
                                                        var commMembThemeCopy = angular.copy(commMembTheme);
                                                        commMembThemeCopy.commissionMembers = [];
                                                        commMembThemeCopy.appliedTheme = true;
                                                        vm.item.scheduleActivities[indexActivity].activeLearners.add(examApplicationLearner.id);
                                                        var commissionMemberFoundBreak = false;
                                                        commissionMembersWithLowestParticipationVM.forEach(function (commissionMembersWithLowestParticipation) {
                                                            if (commissionMemberFoundBreak == false) {
                                                                commMembTheme.commissionMembers.forEach(function (commissionMemberVM) {
                                                                    if (inappropriateCommMembIdsSet.has(commissionMemberVM.id) == false && commissionMembersWithLowestParticipation.id == commissionMemberVM.id) {
                                                                        commMembThemeCopy.commissionMembers.push(commissionMemberVM);
                                                                        commissionMembersWithLowestParticipation.participation += 1;
                                                                        commissionMembersWithLowestParticipationVM.sort(compare);
                                                                        commissionMemberFoundBreak = true;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                        themesCommissionMembers.push(commMembThemeCopy);
                                                        examApplicationLearner.active = true;
                                                        foundTheme = true;
                                                    }
                                                });

                                                // Check if the theme was applied or not
                                                if (!foundTheme) {
                                                    // Add a theme with no comm member (if no comm member is chosen, the display will be an empty string)
                                                    var commMembThemeCopy = angular.copy(commission.commissionMembersGroupedByTheme[i]);
                                                    commMembThemeCopy.commissionMembers = [];
                                                    commMembThemeCopy.appliedTheme = false;
                                                    themesCommissionMembers.push(commMembThemeCopy);
                                                }
                                            }

                                            // If there was a conflict, put out a warning toast
                                            if (examApplicationLearner.foundActiveInappropriateCommMember) {
                                                completeWarning += i18nFilter('translations.exams_commission_inappropriate_commission_members_conflict_warning', examApplicationLearner.userDisplayName);
                                                examApplicationLearner.active = false;
                                                vm.item.scheduleActivities[indexActivity].activeLearners.delete(examApplicationLearner.id);
                                            }

                                            // Push a learner as a candidate with propper themes and their commission members
                                            commissionCandidatesCopy.push({
                                                'candidate': angular.copy(examApplicationLearner),
                                                'themesCommissionMembers': angular.copy(themesCommissionMembers)
                                            });

                                            // Updating grid and value status after last candidate was processed,
                                            // because of promise use (finally statement firing multiple times)
                                            if (u >= inactiveCandidates.length - 1) {
                                                // Show warning if something went wrong
                                                if (completeWarning.length > 0) {
                                                    toastr.warning(completeWarning);
                                                }

                                                commission.candidates = angular.copy(commissionCandidatesCopy);

                                                updateCandidatesGrid(indexActivity, indexCommission);
                                                updateCandidatesValueStatus(indexActivity, indexCommission, true);
                                            } else {
                                                u++;
                                            }
                                        });
                                    });
                                });
                            }
                        });
                    }
                });
            });
        }

        function compare(a, b) {
            if (a.participation < b.participation) {
                return -1;
            }
            if (a.participation > b.participation) {
                return 1;
            }
            return 0;
        }

        function updateCommissionMembersGroupedByTheme(indexActivity, indexCommission) {
            var dict = {}, dictThemeIds = {};
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers.forEach(function (commissionMember) {
                commissionMember.themes.forEach(function (theme) {
                    if (dict.hasOwnProperty(theme.name)) {
                        if (commissionMember.commissionMember.active) {
                            dict[theme.name].add(angular.copy(commissionMember.commissionMember));
                        }
                    } else {
                        dict[theme.name] = new Set();
                        dictThemeIds[theme.name] = theme.id;
                        if (commissionMember.commissionMember.active) {
                            dict[theme.name].add(angular.copy(commissionMember.commissionMember));
                        }
                    }
                });
            });

            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme = [];
            for (var item in dict) {
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme.push({
                    'theme': {
                        'id': dictThemeIds[item],
                        'name': item
                    },
                    'commissionMembers': angular.copy(Array.from(dict[item]))
                });
            }

            /*vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme =
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembersGroupedByTheme.filter(function (commissionMembersGroupedByThemeVM) {
                    return commissionMembersGroupedByThemeVM.commissionMembers.length > 0;
                });*/
        }

        function updateCommissionMembersGrid(indexActivity, indexCommission) {
            // Assigning active commission members to the data grid output variable
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCommissionMembers =
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers.filter(function (commissionMemberVM) {
                    return commissionMemberVM.commissionMember.active;
                });

            // For every active commission member add all of their themes to the SET of candidate columns 
            // and generate a display string for this commission members role column
            var candidateColumnsTmp = new Set();
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCommissionMembers.forEach(function (commissionMemberThemes) {
                var tmpArray = [];
                commissionMemberThemes.themes.forEach(function (theme) {
                    var themeNameFirstUpper = theme.name[0].toUpperCase() + theme.name.substring(1).toLowerCase();
                    tmpArray.push(themeNameFirstUpper);
                    candidateColumnsTmp.add(themeNameFirstUpper);
                });
                tmpArray.sort();

                var displayString = "";
                tmpArray.forEach(function (themeName) {
                    if (displayString != "") displayString += ", ";
                    displayString += themeName;
                });

                commissionMemberThemes.chosenThemes = displayString;
                if (commissionMemberThemes.isIndependentExpert)
                    commissionMemberThemes.commissionMember.commissionMemberType = vm.lookup[commissionMemberType.independentExpert];
                else
                    commissionMemberThemes.commissionMember.commissionMemberType = vm.lookup[commissionMemberType.member];
            });

            // Transfor the set of unique columns to an array
            var candidateColumnNameArray = (Array.from(candidateColumnsTmp));

            for (let x = 0; x < candidateColumnNameArray.length; x++) {
                candidateColumnNameArray[x] = candidateColumnNameArray[x][0].toUpperCase() + candidateColumnNameArray[x].substring(1).toLowerCase();
            }

            candidateColumnNameArray = candidateColumnNameArray.sort();

            var candidateColumnsArray = [i18nFilter('translations.exam_arrangements_gui_label_candidate_name')]
                .concat(candidateColumnNameArray, i18nFilter('translations.general_gui_actions'));

            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidateColumns = candidateColumnsArray;
        }

        function emailCommissionMembers(indexActivity, indexCommission) {
            var subject = undefined;

            if (examNameAndScheduleCode) {
                subject = examNameAndScheduleCode.examName + ' - ' + vm.item.scheduleActivities[indexActivity].name;
            }

            var emails = [];

            angular.forEach(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCommissionMembers, function (element) {
                emails.push(element.commissionMember.email);
            });

            if (vm.item.scheduleActivities[indexActivity].commissions[indexCommission].chairman !== undefined) {
                if (!emails.includes(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].chairman.email)) {
                    emails.push(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].chairman.email);
                }
            }
            if (vm.item.scheduleActivities[indexActivity].commissions[indexCommission].recorder !== null && vm.item.scheduleActivities[indexActivity].activityType == '2') {
                if (!emails.includes(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].recorder.email)) {
                    emails.push(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].recorder.email);
                }
            }

            window.location.href = 'mailto:' + emails.join(';') + "\?subject=" + subject;
        }

        function emailAllMembers() {
            var subject = undefined;

            if (examNameAndScheduleCode) {
                subject = examNameAndScheduleCode.examName + ' - ' + examNameAndScheduleCode.examCode + '-' + examNameAndScheduleCode.code;
            }

            var emails = [];

            angular.forEach(vm.item.scheduleActivities, function (activity) {
                if (activity.activityType === vm.formData.activityType.eExam) {
                    angular.forEach(activity.preparersAndEvaluators, function (element) {

                        if (!emails.includes(element.candidate.userEmail)) {
                            emails.push(element.candidate.userEmail);
                        }

                        if (element.evaluator !== undefined && element.evaluator !== null && !emails.includes(element.evaluator.email)) {
                            emails.push(element.evaluator.email);
                        }

                    });
                }
                else {
                    angular.forEach(activity.commissions, function (commission) {
                        angular.forEach(commission.dataGridCommissionMembers, function (element) {
                            if (!emails.includes(element.commissionMember.email)) {
                                emails.push(element.commissionMember.email);
                            }
                        });
                        angular.forEach(commission.dataGridCandidates, function (element) {
                            if (!emails.includes(element.candidate.userEmail)) {
                                emails.push(element.candidate.userEmail);
                            }
                        });

                        if (commission.chairman !== undefined) {
                            if (!emails.includes(commission.chairman.email)) {
                                emails.push(commission.chairman.email);
                            }
                        }

                        if (commission.recorder !== undefined && activity.activityType === vm.formData.activityType.verbalExam) {
                            if (!emails.includes(commission.recorder.email)) {
                                emails.push(commission.recorder.email);
                            }
                        }
                    });
                }
            });

            window.location.href = 'mailto:' + emails.join(';') + "\?subject=" + subject;
        }

        function updateCandidatesGrid(indexActivity, indexCommission) {
            let activity = vm.item.scheduleActivities[indexActivity];
            // sort candidate commission members by theme name
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates.forEach(function (candidateVM, index) {
                if (candidateVM.themesCommissionMembers != null)
                    candidateVM.themesCommissionMembers.sort((a, b) => a.theme.name.localeCompare(b.theme.name));
            });

            // Filter inactive candidates
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCandidates =
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates.filter(function (candidateVM) {
                    return candidateVM.candidate.active;
                });

            // For each active candidate (active == in commission)
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].dataGridCandidates.forEach(function (candidateVM) {
                // If candidate has a commission member and his assigned theme
                if (candidateVM.themesCommissionMembers != null) {
                    // For each theme create a display string of assigned commission members
                    candidateVM.themesCommissionMembers.forEach(function (themeCommissionMembers) {
                        var displayCommMembArray = [];
                        themeCommissionMembers.commissionMembers.forEach(function (commissionMember) {
                            displayCommMembArray.push(commissionMember.displayName);
                        });
                        displayCommMembArray.sort((a, b) => a.localeCompare(b));

                        var displayString = "";
                        displayCommMembArray.forEach(function (commissionMemberDisplayName) {
                            if (displayString != "") displayString += ", ";
                            displayString += commissionMemberDisplayName;
                        });
                        themeCommissionMembers.chosenCommissionMembers = displayString;
                    });
                }
            });
        }

        function updateCandidatesWithCommissionMember(indexActivity, indexCommission, commissionMemeber) {
            let candidates = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].candidates;
            let commissionMemberThemes = commissionMemeber.themes.map((x) => {
                return x.id;
            });

            candidates.forEach(function (item) {
                if (item.candidate.active) {
                    item.themesCommissionMembers.forEach(function (member) {
                        if (commissionMemberThemes.includes(member.theme.id)) {
                            member.commissionMembers.push(commissionMemeber.commissionMember);
                        }
                    })
                }
            })
        }

        function generateTravelOrderDocument(commissionId, commissionMemberId) {
            if (typeof commissionId === 'undefined') {
                vm.saveCommissionError = true;
                $('html').animate({
                    scrollTop: $('#commissionAlertsDiv').offset().top
                }, 500);
            } else {
                examSchedulesDataService.isTravelOrderTemplateSet($stateParams.schedulePackId).then(function (data) {
                    if (data.data.result) {
                        documentsDataService.downloadDocument('/api/examSchedules/travelOrderDocument/' + $stateParams.schedulePackId + '/' + commissionId + '/' + commissionMemberId, i18nFilter('translations.exams_documents_label_for_travelOrderTemplate') + '.docx').then(function (data) {

                        }, function (error) {
                            error = {
                                exception: {
                                    additionalData: {},
                                    result: { exceptionCode: -150000, operationCode: 0 }
                                },
                                status: 400
                            };
                            exceptionHelper.handleException(error);
                        });
                    } else {
                        vm.noTravelOrderSet = true;
                        $('html').animate({
                            scrollTop: $('#commissionAlertsDiv').offset().top
                        }, 500);
                    }
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function save(notify, reload) {
            vm.item.scheduleActivities.forEach(function (activity) {
                // if the activity has any commissions
                if (activity.commissions != null && activity.commissions.length > 0) {
                    // find active comm members and candidates
                    activity.commissions.forEach(function (commission) {
                        commission.commissionMembers = commission.commissionMembers.filter(function (commissionMemberVM) {
                            return commissionMemberVM.commissionMember.active;
                        });
                        commission.candidates = commission.candidates.filter(function (candidateVM) {
                            return candidateVM.candidate.active; // && candidateVM.themesCommissionMembers != null && candidateVM.themesCommissionMembers.length > 0;
                        });
                    });
                }
            });

            // filter deleted commissions
            var itemCopy = angular.copy(vm.item);
            itemCopy.scheduleActivities.forEach(function (activity) {
                if (activity.commissions != null) {
                    activity.commissions = activity.commissions.filter(function (commission) { return commission.deleted !== true; });
                }
            });

            if (vm.isEdit) {
                return examSchedulesDataService.editExamScheduleCommissions(itemCopy).then(function (data) {
                    if (data.workerStatus === vm.workerStatus.fail) {
                            notificationService.notify(data);
                        }
                        else {
                            if (notify) {
                                logger.success(i18nFilter('translations.exam_arrangements_operation_edit_success_message'), data);
                            }
                            if (reload != false) {
                                $state.go("examSchedulePacks.arrangementsEdit", $stateParams);
                            }
                        }
                    vm.saveCommissionError = false;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            } else {
                return examSchedulesDataService.addExamScheduleCommissions(itemCopy).then(function (data) {
                    if (notify) {
                        logger.success(i18nFilter('translations.exam_arrangements_operation_edit_success_message'), data);
                    }

                    if (reload != false) {
                        $state.go("examSchedulePacks.arrangementsEdit", $stateParams);
                    }
                    vm.saveCommissionError = false;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
            
        }

        function showDocuments(indexActivity, indexCommission) {
            $stateParams.activityId = vm.item.scheduleActivities[indexActivity].activityId;
            $state.go('examSchedulePacks.arrangementsDocuments', $stateParams);
        }

        function showCommissionDocuments(indexActivity, indexCommission) {
            $stateParams.activityId = vm.item.scheduleActivities[indexActivity].activityId;
            $stateParams.commissionId = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].id;
            $state.go('examSchedulePacks.arrangementsCommissionDocuments', $stateParams);
        }

        function updateCapacity(indexActivity, indexCommission) {
            vm.item.scheduleActivities[indexActivity].commissions[indexCommission].capacity = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].location.capacity;

            var activity = vm.item.scheduleActivities[indexActivity];
            var location = vm.item.scheduleActivities[indexActivity].commissions[indexCommission].location;
            examSchedulesDataService.examActivityDate(activity.activityId, location.id).then(function (data) {
                var date = new Date(data.data)
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var seconds = date.getSeconds();
                var commissionDate = new Date(vm.item.scheduleActivities[indexActivity].commissions[indexCommission].startDate);
                var day = commissionDate.getDate(); 
                var month = commissionDate.getMonth(); 
                var year = commissionDate.getFullYear()
                var newDate = new Date(year, month, day, hours, minutes, seconds);
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].startDate = newDate;
            }, function (error) {
                exceptionHelper.handleException(error);
            });
                
        }

        function computeCandidateColumns() {
            vm.item.scheduleActivities.forEach(function (activity) {
                activity.candidateColumns = [];
                activity.themes = [];
                activity.themeIds = new Set();
                themesGroupedByCommissionMember.forEach(function (item) {
                    item.themes.forEach(function (theme) {
                        if (theme.activityIds.includes(activity.activityId) && !activity.themeIds.has(theme.id)) {
                            activity.themeIds.add(theme.id);
                            activity.themes.push({ id : theme.id, name : capitalize(theme.name) });
                        }
                    });
                });
                activity.themes.sort((a, b) => a.name.localeCompare(b.name));

                activity.candidateColumns.push({ id : '0', name : 'Ime kandidata' });
                activity.candidateColumns = activity.candidateColumns.concat(activity.themes);
                activity.candidateColumns.push({ id : '99', name : 'Dejanja' });
            });
        }

        function capitalize(name) {
            return name[0].toUpperCase() + name.substring(1).toLowerCase();
        }
    }
})();
