(function () {
    'use strict';

    angular
        .module('app')
        .controller('EditTenantSecondStep', secondStep);

    secondStep.$inject = ['$state', '$http', 'editTenantHelper', 'dmsProviders', 'i18nFilter', 'logger', '$uibModal', 'documentsDataService', 'templatesDataService'];

    function secondStep($state, $http, editTenantHelper, dmsProviders, i18nFilter, logger, $uibModal, documentsDataService, templatesDataService) {
        /* jshint validthis:true */
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = editTenantHelper.getData();
        templatesDataService.getAllActiveTemplates().then(function (data) {
            vm.templates = data;
        });
        vm.dowloadDigitalSignature = dowloadDigitalSignature;
        vm.dowloadCertificate = dowloadCertificate;
        vm.uploadDigitalSignature = uploadDigitalSignature;
        vm.uploadCertificate = uploadCertificate;
        vm.deleteDigitalSignature = deleteDigitalSignature;
        vm.removeWorkProgramTemplate = removeWorkProgramTemplate;
        vm.localeMessages = {
            editTenantInfo: i18nFilter('translations.tenants_gui_edit_tooltip_info')
        };

        if (vm.isEdit) {
            if (!vm.item.configuration.dms.digitalSignature) {
                vm.item.configuration.dms.digitalSignature = {
                    imageFileName: undefined,
                    certificateFileName: undefined,
                    deleteDigitalSignature: false,
                    password: undefined,
                    oldImageFileName: undefined,
                };
            } else {
                vm.item.oldImageFileName = vm.item.configuration.dms.digitalSignature.imageFileName;
            }
        }

        function removeWorkProgramTemplate() {
            vm.item.workProgramTemplate = undefined;
        }

        function dowloadDigitalSignature() {
            documentsDataService.downloadDocument('/api/tenants/digitalSignature/' + vm.item.configuration.dms.digitalSignature.imageFileName, vm.item.configuration.dms.digitalSignature.imageFileName);
        }

        function dowloadCertificate() {
            documentsDataService.downloadDocument('/api/tenants/certificate/' + vm.item.configuration.dms.digitalSignature.certificateFileName, vm.item.configuration.dms.digitalSignature.certificateFileName);
        }

        function uploadDigitalSignature() {
            var digitalSignature = $uibModal.open({
                templateUrl: 'app/manage/tenants/secondStep/uploadDigitalSignature/uploadDigitalSignature.html',
                controller: 'uploadDigitalSignature',
                controllerAs: 'vm'
            });
            digitalSignature.result.then(function (selectedItem) {
                if (selectedItem) {
                    vm.item.digitalCertificateImageFileId = selectedItem.imageFileId;
                    vm.item.configuration.dms.digitalSignature.imageFileName = selectedItem.imageFileName;
                }
            }, function () {
                logger.log('Upload digital signature dismissed at: ' + new Date());
            });
        }

        function uploadCertificate() {
            var certificate = $uibModal.open({
                templateUrl: 'app/manage/tenants/secondStep/uploadCertificate/uploadCertificate.html',
                controller: 'uploadCertificate',
                controllerAs: 'vm'
            });
            certificate.result.then(function (selectedItem) {
                if (selectedItem) {
                    vm.item.digitalCertificateFileId = selectedItem.imageFileId;
                    vm.item.configuration.dms.digitalSignature.certificateFileName = selectedItem.imageFileName;
                }
            }, function () {
                logger.log('Upload certificate dismissed at: ' + new Date());
            });
        }

        function deleteDigitalSignature() {
            var deleteDigitalSignature = $uibModal.open({
                templateUrl: 'app/manage/tenants/secondStep/deleteDigitalSignature/deleteDigitalSignature.html',
                controller: 'deleteDigitalSignature',
                controllerAs: 'vm',
                resolve: {
                    fileName: [function () {
                        return vm.item.configuration.dms.digitalSignature.imageFileName;
                    }]
                }
            });
            deleteDigitalSignature.result.then(function (selectedItem) {
                if (selectedItem) {
                    vm.item.configuration.dms.digitalSignature.deleteDigitalSignature = true;
                    vm.item.configuration.dms.digitalSignature.imageFileName = undefined;
                    vm.item.digitalCertificateImageFileId = undefined;
                }
            }, function () {
                logger.log('Delete digital signature dismissed at: ' + new Date());
            });
        }
    }
})();
