(function () {
    'use strict';

    angular
        .module('blocks.router')
        .controller('Error', errorController);

    errorController.$inject = ['$location', 'logger', '$stateParams', 'exceptionHelper', 'i18nFilter', 'notificationService'];

    function errorController($location, logger, $stateParams, exceptionHelper, i18nFilter, notificationService) {
        var vm = this;
        logger.log("Error controller: ", $stateParams.details);

        var errorDetails = $stateParams.details;
        if (errorDetails) {
            vm.status = errorDetails.status ? errorDetails.status : 400;

            vm.message = i18nFilter('translations.general_error_message_something_went_wrong');
            if (errorDetails.exception && errorDetails.exception.exceptionCode) {
                vm.description = notificationService.getMessage(errorDetails.exception.exceptionCode, errorDetails.exception.additionalData);
                vm.description += i18nFilter('translations.general_error_message_something_went_wrong_code') + errorDetails.exception.exceptionCode;
            }
            else {
                vm.description = i18nFilter('translations.general_error_message_something_went_wrong_description');

            }
        }
        else {
            vm.status = 400;
            vm.description = i18nFilter('translations.general_error_message_something_went_wrong_description');
        }
    }
})();
