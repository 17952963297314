(function () {
    'use strict';

    angular
        .module('app')
        .controller('schoolingLearnersBill', schoolingLearnersBill);

    schoolingLearnersBill.$inject = ['$scope', 'schedulePackId', 'documentsDataService'];

    function schoolingLearnersBill($scope, schedulePackId, documentsDataService) {
        var vm = this;
        vm.percentage = 100;
        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            documentsDataService.downloadDocument('api/schoolingSchedulePacks/' + schedulePackId + '/learnersBill/' + vm.percentage, 'IZOBRAŽEVANJA IZSTAVITEV RAČUNOV.xlsx');
            $scope.$dismiss();           
        };

        function cancel() {
            $scope.$dismiss();
        };
    }
})();
