(function () {
    'use strict';

    angular
        .module('app.contracts')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('contracts', {
                parent: 'base',
                url: '/manage/contracts',
                templateUrl: 'app/manage/contracts/contracts.html',
                controller: 'contracts',
                controllerAs: 'vm',
                requireADLogin: true,                
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('contracts.delete', {
                url: '/delete/:contractId',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/contracts/deleteContract/deleteContract.html',
                        controller: 'DeleteContract',
                        controllerAs: 'vm',
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        });
                }],
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                },
                params: {
                    contract: undefined
                }
            })

            .state('contracts.add', {
                url: '/add',
                templateUrl: 'app/manage/contracts/editContract/editContract.html',
                controller: 'EditContract',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    contract: ['contractsDataService', '$stateParams', function (contractsDataService, $stateParams) {
                        return {};
                    }],
                    budgetEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetEntities();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('contracts.edit', {
                url: '/edit/:contractId',
                templateUrl: 'app/manage/contracts/editContract/editContract.html',
                controller: 'EditContract',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    contract: ['contractsDataService', '$stateParams', function (contractsDataService, $stateParams) {
                        return contractsDataService.getContractById($stateParams.contractId);
                    }],
                    budgetEntities: ['budgetsDataService', '$stateParams', function (budgetsDataService, $stateParams) {
                        return budgetsDataService.getBudgetEntities();
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['EducationAdmin']
                }
            });
    }
})();
