(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('schoolingSchedulePackAddApplication', schoolingSchedulePackAddApplication);

    schoolingSchedulePackAddApplication.$inject = ['$state', '$stateParams', '$sessionStorage', '$timeout', 'examApplicationStatus', 'examApplicationFieldType', '$scope', 'i18nFilter', 'logger', 'schoolingApplicationsDataService', 'usersDataService', 'citiesDataService', 'organizationsDataService','schooling', 'userInfoFields', 'schoolingLearnerPrice'];

    function schoolingSchedulePackAddApplication($state, $stateParams, $sessionStorage, $timeout, examApplicationStatus, examApplicationFieldType, $scope, i18nFilter, logger, schoolingApplicationsDataService, usersDataService, citiesDataService, organizationsDataService, schooling, userInfoFields, schoolingLearnerPrice) {
        
        var vm = this;
        vm.save = save;
        vm.userInfoFields = userInfoFields;
        vm.scrollToTop = scrollToTop;
        vm.schooling = schooling; 
        vm.applicantName = $sessionStorage.userInfo.displayName;
        vm.searchAJPES = searchAJPES;
        vm.refreshUsers = refreshUsers;
        vm.removeApplyingPerson = removeApplyingPerson;
        vm.customValidation = customValidation;
        vm.schoolingLearnerPrice = schoolingLearnerPrice;
        vm.searchCities = searchCities;
        vm.clearCitySelection = clearCitySelection;
        vm.searchOrganizations = searchOrganizations;
        vm.clearOrganizationSelection = clearOrganizationSelection;
        vm.applyingPersonChanged = applyingPersonChanged;
        vm.organizationChanged = organizationChanged;
        vm.loadProfileData = vm.userInfoFields.filter(function (x) { return x.code == "employer";}).length > 0;     
        vm.debounce = false;

        vm.formData = {
            examApplicationFieldType: examApplicationFieldType,
            payer: { person: 1, organization: 2 },
            userInfoValues: {},
            startDateConfig: {
                type: 'date',
                width: '100%',
                showClearButton: true,
                acceptCustomValue: false,
                inputAttr: {
                    'id': 'dateOfBirth',
                    'name': 'dateOfBirth',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.dateOfBirth'
                }
            }
        };

        function searchCities(filter) {
            if (!filter || filter === "") {
                vm.formData.cities = [];
                return;
            }
            vm.formData.citiesIsLoading = true;
            citiesDataService.getAllFilteredActiveCities(filter).then(function (data) {
                vm.formData.cities = data;
                vm.formData.citiesIsLoading = false;
            }, function (error) {
                vm.formData.citiesIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearCitySelection(fieldCode) {
            vm.formData.userInfoValues[fieldCode] = undefined;
        }

        //#region Organizations
        function searchOrganizations(query, selected) {
            if (!query || query === "") {
                vm.formData.organizations = [];
                return;
            }

            //For selections that come from multieselect, extract organization external id's, else create empty array
            const orgTaxIds = (selected && Array.isArray(selected)) ? selected.map((org) => org.taxId) : [];
            vm.formData.organizationsIsLoading = true;

            organizationsDataService.searchForOrganizations(query, orgTaxIds).then(function (data) {
                vm.formData.organizations = data;
                vm.formData.organizationsIsLoading = false;
            }, function (error) {
                vm.formData.organizationsIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearOrganizationSelection() {
            vm.formData.userInfoValues['organization'] = undefined;
        }
        //#endregion

        function scrollToTop() {
            $('html').animate({
                scrollTop: $('#addApplicationBox').offset().top
            }, 700);
        }        
        
        function customValidation() {
            // Return true, if we want the submit button disabled
            
            // User data
            var userDataValid = true;

            // Checking date picker user info field
            var datePickerElementArray = $("#datePicker :input");
            datePickerElementArray.each(function (index, input) {
                if (input.className.includes("dx-texteditor-input") && !input.value) {
                    userDataValid = false;
                }
            });

            //Check if user is already enrolled & do not allow to proceed with saving
            if (vm.formData.learnerEnrolled != null && vm.formData.learnerEnrolled === true)
                userDataValid = false;

            return !userDataValid;
        }

        function searchAJPES() {
            organizationsDataService.searchOrganizationsByExternalIdOrTaxId(vm.taxIdOrNationalIdOrganization)
                .then(function (data) {
                    if (data != null) {
                        vm.titleOrganization = data.name;
                        vm.taxIdOrganization = data.taxId;
                        vm.nationalId = data.externalId;
                        vm.addressOrganization = data.address;
                    } else {
                        vm.titleOrganization = "";
                        vm.taxIdOrganization = "";
                        vm.nationalId = "";
                        vm.addressOrganization = "";
                    }
                });
        }

        function refreshUsers(query) {
            if (!query) {
                return;
            }
            usersDataService.searchActiveUsers2(query).then(function (data) {
                vm.formData.users = data;
            }, function (error) {
            });
        }

        function applyingPersonChanged() {
            checkIfLearnerIsEnrolled();

            if (!vm.loadProfileData)
                return;

            if (vm.applyingPerson) {
                vm.formData.userInfoValues.organization = vm.applyingPerson.organization;
                vm.formData.userInfoValues.employer = vm.applyingPerson.organization ? vm.applyingPerson.organization.name : null;
                vm.formData.userInfoValues.employerExternalId = vm.applyingPerson.organization ? vm.applyingPerson.organization.externalId : null;
                vm.formData.userInfoValues.workplace = vm.applyingPerson.jobTitle;
            }
            else {
                vm.formData.userInfoValues = null;
            }
        }

        function organizationChanged() {
            if (vm.formData.userInfoValues.organization) {
                vm.formData.userInfoValues.employer = vm.formData.userInfoValues.organization.name;
                vm.formData.userInfoValues.employerExternalId = vm.formData.userInfoValues.organization.externalId;
            }
            else {
                vm.formData.userInfoValues.employer = null;
                vm.formData.userInfoValues.employerExternalId = null;
            }
        }

        function checkIfLearnerIsEnrolled() {
            schoolingApplicationsDataService.learnerEnrolledSchoolingApplicationSchedule($stateParams.schedulePackId, vm.applyingPerson.id).then(function (data) {
                vm.formData.learnerEnrolled = data;

                if (vm.formData.learnerEnrolled == null || vm.formData.learnerEnrolled === true)
                    logger.warning(i18nFilter('translations.schoolingSchedule_notification_application_enrolled_learner'));
            }, function (error) {
                exceptionHelper.handleException(error, false);
            });
        }

        function removeApplyingPerson() {
            vm.applyingPerson = undefined;
            vm.formData.userInfoValues = {};
        }
        
        function save() {
            if (!vm.debounce) {
                vm.debounce = true;

                var learner = vm.formData.userInfoValues;
                learner["userId"] = vm.applyingPerson.id;
                learner["userDisplayName"] = vm.applyingPerson.displayName;
                learner["price"] = vm.schoolingLearnerPrice;
                learner["coordinatorComment"] = vm.ownerNote;
                learner["dateOfBirth"] = vm.dateOfBirth;

                var application = {
                    schedulePackId: $stateParams.schedulePackId, 
                    schoolingApplicationLearners: [learner],  // List containing one ExamApplicationLearner
                    payer: vm.payerType,  // Person == 1, Organization == 2
                    organizationPayerName: vm.titleOrganization,
                    organizationPayerTaxId: vm.taxIdOrganization,
                    organizationPayerNationalId: vm.nationalId,
                    organizationPayerAddress: vm.addressOrganization,
                    personPayerAddress: vm.addressPerson,
                    personPayerTaxId: vm.taxIdPerson,
                    personPayerCity: vm.cityPerson,
                    numberOfLearners: 1,
                    applicationType: 0,  // Single == 0, Group == 1
                    userConsent: true
                };

                schoolingApplicationsDataService.saveSchoolingApplicationApproved(application).then(function (data) {
                    logger.success(i18nFilter('translations.schooling_enroll_queue'));
                    $timeout(function () { vm.debounce = false; }, 1000);
                    $state.reload();
                }, function (error) {
                    exceptionHelper.handleException(error, false);
                });
            }
        }
    }
})();
