(function () {
    'use strict';

    angular
        .module('app.analytics')
        .controller('reportViwerDialog', ReportViwerDialog);

    ReportViwerDialog.$inject = ['$scope', 'report', '$sessionStorage'];

    function ReportViwerDialog($scope, report, $sessionStorage) {
        var modalvm = this;
        modalvm.cancel = cancel;
        modalvm.report = report;
        modalvm.parameters = {
            tenantId: $sessionStorage.userInfo.tenantId,
            userId: $sessionStorage.userInfo.userId
        };

        function cancel() {
            $scope.$dismiss();
        }
    }
})();
