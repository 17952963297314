(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('releaseHistoryDataService', releaseHistory);

    releaseHistory.$inject = ['$http', 'logger', 'locale', '$q'];

    function releaseHistory($http, logger, locale, $q) {
        var service = {
            getReleaseHistory: getReleaseHistory
        };

        return service;

        function getReleaseHistory() {
            return $http.get('api/releaseHistory').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get release history', data);
                if (data) {
                    return $q.reject({ exception: data.data, status: data.status });
                } else {
                    return $q.reject();
                }
            });
        }
    }
})();