(function () {
    'use strict';

    angular
        .module('app.educations.home')
        .controller('EducationHome', educationHome);

    educationHome.$inject = ['logger', 'education', '$sce'];

    function educationHome(logger, education, $sce) {
        var vm = this;
        vm.title = 'education home';
        vm.education = education;

        vm.trustAsHtml = trustAsHtml;

        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        };
    }
})();
