(function () {
    'use strict';

    angular
        .module('app.schoolingSchedulePacks')
        .controller('editSchoolingSchedulePack', editSchoolingSchedulePack);

    editSchoolingSchedulePack.$inject = ['exceptionHelper', '$state', 'logger', 'i18nFilter', '$stateParams', 'priceCategories', 'currencies', 'locations', 'schooling', 'schoolingSchedulePack', 'schoolingSchedulePacksDataService', 'examScheduleApplicationType', 'scheduleVisibility', 'locationsDataService', 'dateHelper', 'citiesDataService', 'schedulePackType', '$uibModal'];

    function editSchoolingSchedulePack(exceptionHelper, $state, logger, i18nFilter, $stateParams, priceCategories, currencies, locations, schooling, schoolingSchedulePack, schoolingSchedulePacksDataService, examScheduleApplicationType, scheduleVisibility, locationsDataService, dateHelper, citiesDataService, schedulePackType, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.schoolingSchedulePack = schoolingSchedulePack;
        vm.schoolingSchedulePack.sendCancelationEmail = false;

        vm.isEdit = $state.current.data.isEdit;
        //#region Locale messages
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        //#endregion


        vm.joinDeadlineChanged = joinDeadlineChanged;
        vm.leaveDeadlineChanged = leaveDeadlineChanged;
        vm.refreshCities = refreshCities;

        vm.formData = {
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            examScheduleApplicationType: examScheduleApplicationType,
            schedulePackType: schedulePackType,
            organizations: [],
            scheduleVisibility: scheduleVisibility,
            locations: [],
            startDateConfig: {
                type: 'date',
                width: '100%',
                showClearButton: true,
                acceptCustomValue: true,
                placeholder: "DD.MM.YYYY",
                min: new Date(1900, 0, 1),
                max: new Date(3000, 0, 1),
                onKeyDown: function (e) {
                    const input = document.getElementById('startDateInput');
                    dateHelper.onDateInputKeyDown(e.event, input);
                },
                onValueChanged: function (e) {
                    var startDate = vm.schoolingSchedulePack.startDate;
                    vm.schoolingSchedulePack.startDate = dateHelper.createNewDateTimeWithoutSeconds(startDate);
                    if (vm.schoolingSchedulePack.startDate == undefined) {
                        vm.schoolingSchedulePack.startDate = dateHelper.convertFromStringToDate(startDate);
                    }
                    calculateDeadline();
                },
                inputAttr: {
                    'name': 'startDate',
                    'ng-required': 'true',
                    'id': 'startDateInput'
                },
                bindingOptions: {
                    value: 'vm.schoolingSchedulePack.startDate'                    
                }
            },
            endDateConfig: {
                type: 'date',
                width: '100%',
                showClearButton: true,
                acceptCustomValue: true,
                placeholder: "DD.MM.YYYY",
                min: new Date(1900, 0, 1),
                max: new Date(3000, 0, 1),
                onKeyDown: function (e) {
                    const input = document.getElementById('endDateInput');
                    dateHelper.onDateInputKeyDown(e.event, input);
                },
                onValueChanged: function (e) {
                    var endDate = vm.schoolingSchedulePack.endDate;
                    vm.schoolingSchedulePack.endDate = dateHelper.createNewDateTimeWithoutSeconds(endDate);
                    if (vm.schoolingSchedulePack.endDate == undefined) {
                        vm.schoolingSchedulePack.endDate = dateHelper.convertFromStringToDate(endDate);
                    }
                    calculateDeadline();
                },
                inputAttr: {
                    'name': 'endDate',
                    'ng-required': 'true',
                    'id': 'endDateInput'
                },
                bindingOptions: {
                    value: 'vm.schoolingSchedulePack.endDate',
                    min: 'vm.schoolingSchedulePack.startDate'
                }
            }
        };

        locationsDataService.getLocations().then(function (result) {
            vm.formData.locations = result;

        });

        //#region Method binding   
        vm.saveChanges = saveChanges;
        vm.add = add;
        vm.checkIfFormIsValid = checkIfFormIsValid;
        //#endregion


        //#region Initial setup
        if (!vm.isEdit) {
            vm.schoolingSchedulePack.name = "";
            vm.schoolingSchedulePack.startDate = "";
            vm.schoolingSchedulePack.endDate = "";
            vm.schoolingSchedulePack.checkinDays = 7;
            vm.schoolingSchedulePack.checkoutDays = 3;
            vm.schoolingSchedulePack.visibility = vm.formData.scheduleVisibility.public;
            vm.schoolingSchedulePack.schedulePackType = vm.formData.schedulePackType.onsite; 
            vm.schoolingSchedulePack.applicationAvailable = true;
            vm.schoolingSchedulePack.city = "";
            vm.schoolingSchedulePack.location = "";
        }
        calculateDeadline();
        //#endregion


        function add() {
            var allFormsAreValid = checkIfFormIsValid();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }
            var itemCopy = angular.copy(vm.schoolingSchedulePack);
            schoolingSchedulePacksDataService.addSchoolingSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_operation_add_success_message'));
                $state.go("^", {}, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function saveChanges() {
            if (vm.schoolingSchedulePack.eventCanceled) {
                $uibModal.open({
                    templateUrl: 'app/schoolingSchedulePacks/editSchoolingSchedulePack/editSchoolingSchedulePack/notification/notification.html',
                    controller: 'notification',
                    controllerAs: 'vm'
                })
                .result.then(
                    function (result) {
                        if (result) {
                            saveSchoolingSchedulePacks(true);
                        }
                        else {
                            saveSchoolingSchedulePacks(false);
                        }
                    },
                    function () {
                    }
                );
            }
            else {
                saveSchoolingSchedulePacks(false);
            }
        }

        function saveSchoolingSchedulePacks(sendCancelationEmail) {
            vm.schoolingSchedulePack.sendCancelationEmail = sendCancelationEmail;
            var allFormsAreValid = checkIfFormIsValid();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }
            var itemCopy = angular.copy(vm.schoolingSchedulePack);
            schoolingSchedulePacksDataService.editSchoolingSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_operation_edit_success_message'));
                $state.go("^", {}, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function checkIfFormIsValid() {
            if (vm.schoolingSchedulePack.city == "" || vm.schoolingSchedulePack.city == null
                || vm.schoolingSchedulePack.location == "" || vm.schoolingSchedulePack.location == null
                || vm.schoolingSchedulePack.startDate == "" || vm.schoolingSchedulePack.startDate == null
                || vm.schoolingSchedulePack.endDate == "" || vm.schoolingSchedulePack.endDate == null) {
                return false;
            } else {
                return true;
            }
        }


        function calculateDeadline() {
            joinDeadlineChanged();
            leaveDeadlineChanged();
        }        

        function joinDeadlineChanged() {
            // join deadline calculation
            var joinDateInText = new Date(vm.schoolingSchedulePack.startDate);
            if (isNaN(joinDateInText)) {
                vm.schoolingSchedulePack.joinDeadlineText = "";
            }
            else {
                vm.schoolingSchedulePack.joinDeadlineText = joinDateInText.setDate(joinDateInText.getDate() - vm.schoolingSchedulePack.checkinDays);
            }
        }
        function leaveDeadlineChanged() {
            // leave deadline calculation
            var leaveDateInText = new Date(vm.schoolingSchedulePack.startDate);
            if (isNaN(leaveDateInText)) {
                vm.schoolingSchedulePack.leaveDeadlineText = "";
            }
            else {
                vm.schoolingSchedulePack.leaveDeadlineText = leaveDateInText.setDate(leaveDateInText.getDate() - vm.schoolingSchedulePack.checkoutDays);
            }
        }

        function refreshCities(filter) {
            if (!filter || filter === "") {
                vm.formData.cities = [];
                return;
            }
            vm.formData.citiesIsLoading = true;
            citiesDataService.getAllFilteredActiveCities(filter).then(function (data) {
                vm.formData.cities = data;
                vm.formData.citiesIsLoading = false;
            }, function (error) {
                vm.formData.citiesIsLoading = false;
                exceptionHelper.handleException(error);
            });
        }
    }
})();
