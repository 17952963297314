(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePacks', examSchedulePacks);

    examSchedulePacks.$inject = ['emptyGuid', 'exams', 'dateFilter', 'i18nFilter', 'logger', '$stateParams', '$state', 'documentsDataService', 'examSearchOptions', 'examSchedulesDataService'];

    function examSchedulePacks(emptyGuid, exams, dateFilter, i18nFilter, logger, $stateParams, $state, documentsDataService, examSearchOptions, examSchedulesDataService) {

        /* jshint validthis:true */
        var vm = this;

        vm.formData = {
            schedulePackEntities: [],
            searchOptions: {
                activeExams: examSearchOptions.active,
                finishedExams: examSearchOptions.finished,
                allExams: examSearchOptions.all
            }
        };

        vm.searchOption = vm.formData.searchOptions.activeExams;

        vm.refreshTable = refreshTable;
        vm.addSchedulePack = addSchedulePack;
        vm.editSchedulePack = editSchedulePack;
        vm.showTermApplications = showTermApplications;
        vm.addArrangement = addArrangement;
        vm.editArrangement = editArrangement;
        vm.exportCandidateBill = exportCandidateBill;
        vm.questionsAndPayments = questionsAndPayments;
        vm.showCommissionPaymentOrder = showCommissionPaymentOrder;
        vm.i18nFilter = i18nFilter;

        vm.finishExam = finishExam;
        vm.reopenExam = reopenExam;

        function finishExam(examSchedule) {
            examSchedule.status = 0;
            examSchedulesDataService.editExamScheduleStatus(examSchedule);
        }

        function reopenExam(examSchedule) {
            examSchedule.status = 1;
            examSchedulesDataService.editExamScheduleStatus(examSchedule);
        }

        angular.forEach(exams, function (exam, index) {
            exam.type = 'exam';
            vm.formData.schedulePackEntities.push(exam);
        });

        if ($stateParams.entityId) {
            vm.schedulePackEntity = _.find(vm.formData.schedulePackEntities, function (entity) { return entity.id === $stateParams.entityId; });
        }

        vm.localeMessages = {
            edit: i18nFilter('translations.general_action_edit'),
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            hasDifferenceBetweenEnrolledUsers: i18nFilter('translations.schedule_packs_gui_has_difference_between_enrolled_users'),
            learnersBill: i18nFilter('translations.schedule_gui_action_learners_bill'),
            approveApplications: i18nFilter('translations.exam_schedules_action_confirm_applications'),
            arrangementAddTooltip: i18nFilter("translations.exam_arrangements_gui_action_add_arrangement"),
            arrangementEditTooltip: i18nFilter("translations.exam_arrangements_gui_action_edit_arrangement"),
            questionsAndPaymentsTooltip: i18nFilter("translations.exam_questions_and_payments_gui_action_review"),
            commissionPaymentOrder: i18nFilter("translations.exam_questions_and_payments_gui_action_commission_payment_orders"),
            paymentExcelExport: i18nFilter("translations.examSchedulePack_applications_gui_payment_export"),
            finishExam: i18nFilter("translations.exam_finish"),
            reOpenExam: i18nFilter("translations.exam_reopen")
        };

        function refreshTable() {
            if (vm.schedulePackEntity.id) {
                $stateParams.entityId = vm.schedulePackEntity.id;
                if ($state.current.name !== "examSchedulePacks") {
                    $state.go('examSchedulePacks', $stateParams);
                }
                else {
                    $state.go('examSchedulePacks', $stateParams, { reload: false, notify: false });
                }
                vm.gridInstance.refresh();
            }
        }

        vm.dataGridOptionsExam = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/ExamSchedulesOData/GetExamSchedules",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        e.url += "(examId=" + vm.schedulePackEntity.id
                        e.url += ",examSearchOptions=" + vm.searchOption + ")";

                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            }
                        }
                    }
                },
            }),
            columns: [
                {
                    dataField: 'startDate',
                    dataType: "date",
                    alignment: 'left',
                    width: 150,
                    allowSorting: true,
                    sortOrder: 'desc',
                    customizeText: function (args) {
                        return dateFilter(args.value, 'shortDate');
                    },
                    caption: i18nFilter('translations.examSchedulePack_edit_startDate')
                },
                {
                    caption: i18nFilter('translations.schedule_gui_label_visibility'),
                    dataField : "visibilityString",
                    dataType : 'string',
                    alignment: 'center',
                    allowSorting: true,
                    width: 150,
                },
                {
                    caption: i18nFilter('translations.general_gui_ordinal_number'),
                    alignment: 'center',
                    allowSorting: false,
                    width: 100,
                    cellTemplate: 'orderNoTemplate'
                },
                {
                    caption: i18nFilter('translations.schedule_gui_label_schedule_status'),
                    dataField : "scheduleStatusString",
                    alignment: 'center',
                    dataType: "string",
                    allowSorting: true,
                    width: 100,
                },
                {
                    dataField: 'applicationCount',
                    caption: i18nFilter('translations.accepted_approved'),
                    alignment: 'center',
                    allowSorting: false,
                    allowFiltering: false,
                    width: 150
                },
                {
                    caption: i18nFilter('translations.general_gui_actions'),
                    allowSorting: false,
                    allowFiltering: false,
                    alignment: 'left',
                    width: '100%',
                    cellTemplate: 'actionTemplate'
                }
            ],
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: undefined,
                showNavigationButtons: true
            },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };

        function addSchedulePack() {
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.add', $stateParams);
        }

        function editSchedulePack(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.edit', $stateParams);
        }

        function showTermApplications(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.applications', $stateParams);
        }

        function addArrangement(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.arrangementsAdd', $stateParams);
        }

        function editArrangement(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.arrangementsEdit', $stateParams);
        }

        function questionsAndPayments(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.questionsAndPayments', $stateParams);
        }

        function showCommissionPaymentOrder(schedulePack) {
            $stateParams.schedulePackId = schedulePack.id;
            $stateParams.examId = vm.schedulePackEntity.id;
            $state.go('examSchedulePacks.paymentOrder', $stateParams);
        }

        function exportCandidateBill(schedulePack) {
            documentsDataService.downloadDocument('api/examSchedules/export/bill/' + schedulePack.id, 'Račun za kandidate.xlsx');
        }

    }
})();
