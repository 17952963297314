(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingSecondStep', EditSchoolingSecondStep);

    EditSchoolingSecondStep.$inject = ['logger', 'editSchoolingHelper', 'usersDataService', '$state', '$stateParams', '$scope', 'templates', 'i18nFilter', 'prerequisiteSchoolings', '$sessionStorage', '$uibModal', 'languages', 'surveysDataService', 'surveys', 'emptyGuid', 'documentsDataService'];

    function EditSchoolingSecondStep(logger, editSchoolingHelper, usersDataService, $state, $stateParams, $scope, templates, i18nFilter, prerequisiteSchoolings, $sessionStorage, $uibModal, languages, surveysDataService, surveys, emptyGuid, documentsDataService) {
        var vm = this;
        vm.item = editSchoolingHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            ownersCanCreateAssignments: i18nFilter("translations.schooling_edit_gui_tooltip_info_owners_can_create_assignments"),
            sequentialActivities: i18nFilter("translations.schooling_edit_gui_tooltip_info_sequential_activities"),
            generateDocuments: i18nFilter("translations.schooling_edit_gui_tooltip_info_generate_documents"),            
            attendeeConfirms: i18nFilter("translations.schooling_edit_gui_tooltip_info_attendee_confirms_schooling_finish"),
            ownerConfirms: i18nFilter("translations.schooling_edit_gui_tooltip_info_owner_confirms_schooling_finish"),
            survey: i18nFilter("translations.schooling_gui_tooltip_survey"),
            download: i18nFilter("translations.general_action_download")
        };

        vm.formData = {   
            languages: languages,
            templates: templates,            
            users: [],            
            usersIsLoading: false,
            prerequisiteSchoolings: prerequisiteSchoolings,
            surveys: surveys,
            surveyGroups: []
        };
        
        vm.logData = logData;
        vm.refreshUsers = refreshUsers;
        vm.next = next;
        vm.back = back;
        vm.cancel = cancel;
        vm.estDurationValidation = estDurationValidation;
        vm.openTemplateHelp = openTemplateHelp;
        vm.ownersCreateAssignments = true;
        vm.removeEducationProgramTemplate = removeEducationProgramTemplate;
        vm.removeContractPersonEUTemplate = removeContractPersonEUTemplate;
        vm.removeContractAnnualContractPersonTemplate = removeContractAnnualContractPersonTemplate;
        vm.removeContractLegalPersonEUTemplate = removeContractLegalPersonEUTemplate;
        vm.removeContractAnnualContractLegalPersonTemplate = removeContractAnnualContractLegalPersonTemplate;
        vm.removeStatementZPISTemplate = removeStatementZPISTemplate;
        vm.removeStatementTravelExpensesTemplate = removeStatementTravelExpensesTemplate;
        vm.removeStatementSchoolingExecutionTemplate = removeStatementSchoolingExecutionTemplate;
        vm.removeCertificateOfAttendenceTemplate = removeCertificateOfAttendenceTemplate;
        vm.removeCertificateOfSchoolingExecutionTemplate = removeCertificateOfSchoolingExecutionTemplate;
        vm.removeProgramDocumentTemplate = removeProgramDocumentTemplate;
        vm.removeInvitationDocumentTemplate = removeInvitationDocumentTemplate;
        vm.removeZziTemplateTemplate = removeZziTemplateTemplate;
        vm.removeApplicationDocumentTemplate = removeApplicationDocumentTemplate;
        vm.save = save;
        vm.downloadFile = downloadFile;

        function save() {
            var item_copy = angular.copy(vm.item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                });
            }

            item_copy.schoolingExecutionStyle = item_copy.schoolingExecutionStyle.id;

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id != null) {
                    price.instructorType = price.instructorType.id;
                }
            });
            editSchoolingHelper.setData(item_copy);
            editSchoolingHelper.done(vm.isEdit);
        }

        if (vm.isEdit) {
            var indexToDelete = -1;
            angular.forEach(vm.formData.prerequisiteSchoolings, function (schooling, index) {
                if (vm.item.id === schooling.id) {
                    indexToDelete = index;
                }
            });

            if (indexToDelete !== -1) {
                vm.formData.prerequisiteSchoolings.splice(indexToDelete, 1);
            }
        }
        
        function removeEducationProgramTemplate() {
            vm.item.educationProgramTemplate = undefined;
        }
        function removeContractPersonEUTemplate() {
            vm.item.contractPersonEUTemplate = undefined;
        }
        function removeContractAnnualContractPersonTemplate() {
            vm.item.contractAnnualContractPersonTemplate = undefined;
        }
        function removeContractLegalPersonEUTemplate() {
            vm.item.contractLegalPersonEUTemplate = undefined;
        }
        function removeContractAnnualContractLegalPersonTemplate() {
            vm.item.contractAnnualContractLegalPersonTemplate = undefined;
        }
        function removeStatementZPISTemplate() {
            vm.item.statementZPISTemplate = undefined;
        }
        function removeStatementTravelExpensesTemplate() {
            vm.item.statementTravelExpensesTemplate = undefined;
        }
        function removeStatementSchoolingExecutionTemplate() {
            vm.item.statementSchoolingExecutionTemplate = undefined;
        }
        function removeCertificateOfAttendenceTemplate() {
            vm.item.certificateOfAttendenceTemplate = undefined;
        }
        function removeCertificateOfSchoolingExecutionTemplate() {
            vm.item.certificateOfSchoolingExecutionTemplate = undefined;
        }
        function removeZziTemplateTemplate() {
            vm.item.zziTemplateTemplate = undefined;
        }

        function removeProgramDocumentTemplate() {
            vm.item.programDocumentTemplate = undefined;
        }

        function removeInvitationDocumentTemplate() {
            vm.item.invitationDocumentTemplate = undefined;
        }

        function removeApplicationDocumentTemplate() {
            vm.item.applicationDocumentTemplate = undefined;
        }

        //@Deprecated, function not being used
        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.schooling_exception_message_fetching_schooling_owners"));
            });
        }

        function estDurationValidation() {
            var days = !vm.item.estDurationDay ? 0 : parseInt(vm.item.estDurationDay);
            var hours = !vm.item.estDurationHour ? 0 : parseInt(vm.item.estDurationHour);

            var sum = days + hours;
            if (sum > 0) {
                return false;
            }
            else {
                return true;
            }
        }

        function next() {            
            $state.go($scope.navigation[2].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('schoolings.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.schooling');
            }
        }

        function back() {
            $state.go($scope.navigation[0].state);
        }


        function logData() {
            logger.log("Data: ", vm.item);
        }

        function openTemplateHelp(documentEventTrigger) {
            $uibModal.open({
                url: '/:documentEventTrigger/edit/secondStep?documentEventTrigger',
                templateUrl: 'app/schoolings/administration/edit/secondStep/templateHelp/templateHelp.html',
                controller: 'templateHelp',
                controllerAs: 'vm',
                resolve: {
                    templateVariables: ['templatesDataService', '$stateParams', function (templatesDataService, $stateParams) {
                        return templatesDataService.getTemplateVariables(documentEventTrigger);
                    }]
                }
            });
        }
        function downloadFile(file) {
            documentsDataService.downloadDocument('/api/templates/' + file.id + '/download', file.fileName);
        }
    }
})();
