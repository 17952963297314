(function () {
    'use strict';

    angular
        .module('app.schoolings')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('schoolings', {
            url: '/schoolings/{id}',
            parent: 'base',
            templateUrl: 'app/schoolings/schoolings.html',
            controller: 'Schoolings',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['Learner', 'CatalogAdmin', 'SchoolingOwner', 'Instructor']
            },
            resolve: {
                isSchoolingOwner: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    return authDataService.isCurrentUserRealSchoolingOwner($stateParams.id);
                }],
                schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                    return schoolingsDataService.getSchoolingById($stateParams.id);
                }]
            }
        });
    }
})();
