(function () {
    'use strict';

    angular
        .module('app')
        .controller('EditTenantThirdStep', thirdStep);

    thirdStep.$inject = ['editTenantHelper', '$state', '$scope'];

    function thirdStep(editTenantHelper, $state, $scope) {
        /* jshint validthis:true */
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = editTenantHelper.getData();
        vm.done = done;
        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.item.uploadedMaintenancePageImage = null;

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.item.uploadedMaintenancePageImage = evt.target.result;
                    vm.item.deleteMaintenancePageImageFile = true;
                });
            };
            reader.readAsDataURL(file);
        };
       
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////

        vm.formData = {
            maxDepthLevels: [1, 2, 3, 4],
            showReportsTabs: false,
            showCatalogsTabs: false,
            showEformsTabs: false,
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code insertdatetime nonbreaking table contextmenu directionality template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            }
        }    

        function done() {
            editTenantHelper.done(vm.isEdit);
        }
    }
})();
