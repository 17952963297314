(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('tile', tile);

    tile.$inject = ['$state', 'logger'];
    
    function tile ($state, logger) {
        // Usage:
        //     <tile></tile>
        // Creates:
        // 
        var directive = {
            restrict: 'E',
            templateUrl: 'app/blocks/directives/tile/tile.html',
            scope: {
                icon: '@',
                name: '@',
                navigate: '@'
            },
            link: link
        };
        return directive;

        function link(scope, element, attrs) {
            scope.icon = attrs.icon;
            scope.tileName = attrs.name;
            scope.navigateTo = function () {
                if (attrs.navigate) {
                    $state.go(attrs.navigate);
                }
            };
        }
    }

})();