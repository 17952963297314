(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('schoolingSchedulePacksDataService', schoolingSchedulePacksDataService);

    schoolingSchedulePacksDataService.$inject = ['$http', 'logger', 'locale', '$q', 'dateHelper', 'decimalHelper'];

    function schoolingSchedulePacksDataService($http, logger, locale, $q, dateHelper, decimalHelper) {
        var service = {
            getAllSchoolingSchedulePacksAdvanced: getAllSchoolingSchedulePacksAdvanced,        
            getEmptySchoolingSchedulePackForSchooling: getEmptySchoolingSchedulePackForSchooling,
            addSchoolingSchedulePack: addSchoolingSchedulePack,
            editSchoolingSchedulePack: editSchoolingSchedulePack,
            sendMailToLearners: sendMailToLearners,
            getSchoolingSchedulePackById: getSchoolingSchedulePackById,
            getSchoolingScheduleProgramByScheduleId: getSchoolingScheduleProgramByScheduleId,
            getSchoolingScheduleProgramModerators: getSchoolingScheduleProgramModerators,
            editSchoolingScheduleProgram: editSchoolingScheduleProgram,
            getPaidInstructorRolesForSchooling: getPaidInstructorRolesForSchooling,
            getPastSchedulesForSchooling: getPastSchedulesForSchooling,
            getSchoolingScheduleEmployeesByScheduleId: getSchoolingScheduleEmployeesByScheduleId,
            getSchoolingScheduleNameByScheduleId: getSchoolingScheduleNameByScheduleId,
            checkCanDeleteSchedule: checkCanDeleteSchedule,
            removeSchedule: removeSchedule,
            getSchoolingScheduleBillingByScheduleId: getSchoolingScheduleBillingByScheduleId,
            saveSchoolingScheduleBillingPack: saveSchoolingScheduleBillingPack,
            saveSchoolingScheduleIndirectBillingPack: saveSchoolingScheduleIndirectBillingPack,
            getSchoolingNameAndScheduleCodeByScheduleId: getSchoolingNameAndScheduleCodeByScheduleId,
            getSchoolingSchedulePacksForApplicationTransfer: getSchoolingSchedulePacksForApplicationTransfer,
            transferSchoolingApplication: transferSchoolingApplication,
            finishSchoolingEmail: finishSchoolingEmail,
            finishSchoolingEmailPerLearner: finishSchoolingEmailPerLearner,
            schoolingScheduleChange: schoolingScheduleChange,
            schoolingScheduleNotification: schoolingScheduleNotification,
            regenerateSchoolingCertificateForUser: regenerateSchoolingCertificateForUser,
            issueSchoolingCertificateForUser: issueSchoolingCertificateForUser,
            issueSchoolingCertificates: issueSchoolingCertificates,
            getSchoolingSchedulePackLectureFiles: getSchoolingSchedulePackLectureFiles,
            verifyLectureSpeakerFile: verifyLectureSpeakerFile,
            deleteLectureSpeakerFile: deleteLectureSpeakerFile,
            toggleDoNotSendMailToLecturers: toggleDoNotSendMailToLecturers,
            moveLectureUp: moveLectureUp,
            moveLectureDown: moveLectureDown,
            selectedSectionIndex: 0
        };

        return service;

        function getAllSchoolingSchedulePacksAdvanced(filter) {
            filter.startTime.searchText1 = dateHelper.convertFromDateToString(filter.startTime.searchText1);
            filter.startTime.searchText2 = dateHelper.convertFromDateToString(filter.startTime.searchText2);

            filter.endTime.searchText1 = dateHelper.convertFromDateToString(filter.endTime.searchText1);
            filter.endTime.searchText2 = dateHelper.convertFromDateToString(filter.endTime.searchText2);
            
            return $http.post('api/schoolingSchedulePacks/filter', filter)
                .then(function (data) {
                    angular.forEach(data.data.data, function (element, index) {
                        element.startTime = dateHelper.convertFromStringToDate(element.startTime);
                        element.endTime = dateHelper.convertFromStringToDate(element.endTime);
                    });
                    return data.data;
                }, function (data, status, headers, config) {
                    if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
                });
        }

        function getSchoolingSchedulePackById(schoolingSchedulePackId) {
            return $http.get('api/schoolingSchedulePacks/' + schoolingSchedulePackId).then(function (data) {
                data.startDate = dateHelper.convertFromStringToDate(data.startDate);
                data.endDate = dateHelper.convertFromStringToDate(data.endDate);
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSchoolingSchedulePack(schoolingSchedulePack) {
            //angular.forEach(schedulePack.schedules, function (schedule, index) {
            //    schedule.startTime = dateHelper.convertFromDateToString(schedule.startTime);
            //    schedule.endTime = dateHelper.convertFromDateToString(schedule.endTime);
            //});
            
            return $http.post('api/schoolingSchedulePacks', schoolingSchedulePack).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editSchoolingSchedulePack(schoolingSchedulePack) {
                //schedule.startTime = dateHelper.convertFromDateToString(schedule.startTime);
                //schedule.endTime = dateHelper.convertFromDateToString(schedule.endTime);

            return $http.put('api/schoolingSchedulePacks', schoolingSchedulePack).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getEmptySchoolingSchedulePackForSchooling(schoolingId) {
            return $http.get('api/schoolingSchedulePacks/empty/schoolings/' + schoolingId).then(function (data) {
                _.each(data.data.schedules, function (schedule) {
                    schedule.startTime = undefined;
                    schedule.endTime = undefined;
                })
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function sendMailToLearners(schedulePackId) {
            return $http.get('api/schoolingSchedulePacks/' + schedulePackId + '/mail').then(function (data) {                
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingScheduleProgramByScheduleId(scheduleId){
            return $http.get('api/schoolingSchedulePacks/' + scheduleId + '/program').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingScheduleProgramModerators(scheduleId,query) {
            return $http.get('api/schoolingSchedulePacks/' + scheduleId + '/program/moderators/'+query).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editSchoolingScheduleProgram(program) {
            return $http.post('api/schoolingSchedulePacks/program', program).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPaidInstructorRolesForSchooling(schoolingId) {
            return $http.get('api/schoolings/' + schoolingId + '/paidInstructorRoles').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getPastSchedulesForSchooling(schoolingId, scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + schoolingId + '/pastSchedules/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingScheduleEmployeesByScheduleId(scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + scheduleId + '/contract').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingScheduleNameByScheduleId(scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + scheduleId + '/name').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function checkCanDeleteSchedule(scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + scheduleId + '/canDelete').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function getSchoolingScheduleBillingByScheduleId(schoolingId, scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + schoolingId + '/' + scheduleId + '/billing').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function saveSchoolingScheduleBillingPack(billingDirectCosts) {
            return $http.post('api/schoolingSchedulePacks/billing/directCosts', billingDirectCosts).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function saveSchoolingScheduleIndirectBillingPack(billingIndirectCosts) {
            return $http.post('api/schoolingSchedulePacks/billing/indirectCosts', billingIndirectCosts).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function removeSchedule(scheduleId) {
            return $http.delete('api/schoolingSchedulePacks/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingNameAndScheduleCodeByScheduleId(schoolingScheduleId) {
            return $http.get('api/schoolingSchedulePacks/nameandcode/' + schoolingScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get exam name and schedule code.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingSchedulePacksForApplicationTransfer(schoolingScheduleId) {
            return $http.get('api/schoolingSchedulePacks/transfer/' + schoolingScheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get schooling schedule pack for application transfer');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        
        function transferSchoolingApplication(schoolingApplication) {
            return $http.post('api/schoolingSchedulePacks/transferApplication', schoolingApplication).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to get schooling schedule pack for application transfer');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function finishSchoolingEmailPerLearner(learnerId) {
            return $http.get('api/schoolingApplications/finishSchoolingPerLearner/' + learnerId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to finish schooling for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function finishSchoolingEmail(scheduleId) {
            return $http.get('api/schoolingApplications/finishSchooling/'+ scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to finish schooling for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function schoolingScheduleChange(scheduleId) {
            return $http.get('api/schoolingApplications/schoolingScheduleChange/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Schooling schedule change');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function schoolingScheduleNotification(scheduleId) {
            return $http.get('api/schoolingApplications/changedScheduleNotification/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Schooling schedule change');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function issueSchoolingCertificateForUser(scheduleId, userId) {
            return $http.get('api/schoolingSchedulePacks/issueUserCertificate/' + scheduleId + '/' + userId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to finish schooling for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function issueSchoolingCertificates(scheduleId) {
            return $http.get('api/schoolingSchedulePacks/issueCertificates/' + scheduleId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to finish schooling for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function regenerateSchoolingCertificateForUser(scheduleId, userId) {
            return $http.post('api/schoolingSchedulePacks/regenerateUserCretificate/' + scheduleId + '/' + userId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to finish regenerating certificate schooling for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getSchoolingSchedulePackLectureFiles(scheduleId) {
            return $http.get('api/schoolingSchedulePacks/' + scheduleId +'/lectureSpeakerFiles').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to got schooling schedule lecuture files for schedule');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function verifyLectureSpeakerFile(speakerFileId, isVerified) {
            return $http.post('api/schoolingSchedulePacks/verifySpeakerFile/' + speakerFileId, isVerified).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to verify lecture speaker file.');
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteLectureSpeakerFile(speakerFileId) {
            return $http.delete('api/schoolings/speaker/delete/' + speakerFileId).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to delete speake file ' + speakerFileId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function toggleDoNotSendMailToLecturers(schoolingScheduleSectionLectureId, doNotSendMail) {
            return $http.post('api/schoolingSchedulePacks/toggleLectureDoNotSendNotification/' + schoolingScheduleSectionLectureId, doNotSendMail).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Failed to toggle doNotSendMail setting for lecture id: ' + schoolingScheduleSectionLectureId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveLectureUp(lectureId, scheduleId) {
            return $http.post('api/schoolingSchedulePacks/moveLectureUp/' + lectureId, { scheduleId }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Movin up lecture id: ' + lectureId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function moveLectureDown(lectureId, scheduleId) {
            return $http.post('api/schoolingSchedulePacks/moveLectureDown/' + lectureId, { scheduleId }).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                logger.log('Movin down lecture id: ' + lectureId);
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
