(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('myEntitiesCatalogFilter', {
            templateUrl: 'app/catalogs/filters/myEntities/myEntitiesCatalogFilter.html',
            controller: myEntitiesCatalogFilter,
            controllerAs: 'vm',
            bindings: {                
                onChanged: '=',
                value: '=',
                allLangKey: '=',
                mineLangKey: '='
            }
        });

    myEntitiesCatalogFilter.$inject = ['logger', 'i18nFilter', 'myEntitiesCatalogFilter'];

    function myEntitiesCatalogFilter(logger, i18nFilter, myEntitiesCatalogFilter) {
        var vm = this;
        
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed                 

        vm.formData = {
            myEntitiesCatalogFilter: myEntitiesCatalogFilter
        };
    }
})();
