(function () {
    'use strict';

    angular
        .module('app')
        .controller('SchoolingActivities', schoolingActivities);

    schoolingActivities.$inject = ['logger', 'activities', 'activityTypes', '$stateParams', 'rolesDataService', 'isSchoolingOwner'];

    function schoolingActivities(logger, activities, activityTypes, $stateParams, rolesDataService, isSchoolingOwner) {
        var vm = this;
        vm.activities = activities;
        vm.activityTypes = activityTypes;
        vm.learningActivityId = $stateParams.learningActivityId;
        vm.ignoreFirstOpenRule = false;
        if (vm.learningActivityId) {
            vm.ignoreFirstOpenRule = true;
        }

        vm.formData = {
            isUserAdmin: rolesDataService.isUserInRoles(['CatalogAdmin', 'SchoolingOwner']) || isSchoolingOwner
        };
    }
})();
