(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .component('languagesCatalogFilter', {
            templateUrl: 'app/catalogs/filters/languages/languagesCatalogFilter.html',
            controller: languagesCatalogFilter,
            controllerAs: 'vm',
            bindings: {
                data: '=',
                onChanged: '='
            }
        });

    languagesCatalogFilter.$inject = ['logger', 'i18nFilter'];

    function languagesCatalogFilter(logger, i18nFilter) {
        var vm = this;
        //vm.data --> all data required for ddl        
        //vm.onCahnged --> event that is fired when dll selection is changed 

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.catalog_gui_language_filter_placeholder")
        };
    }
})();
