(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .controller('DeleteSurveyQuestionGroup', deleteSurveyQuestionGroup);

    deleteSurveyQuestionGroup.$inject = ['logger', '$stateParams', '$scope', 'surveysDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteSurveyQuestionGroup(logger, $stateParams, $scope, surveysDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.surveyQuestionGroupName = $stateParams.questionGroupName;

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            surveysDataService.deleteSurveyGroup($stateParams.questionGroupId).then(function (result) {
                logger.success(i18nFilter('translations.survey_group_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
                $scope.$dismiss();
            });
        };

    }
})();
