(function () {
    'use strict';

    angular
        .module('app.exams')
        .controller('Exams', exams);

    exams.$inject = ['logger', 'exam', '$rootScope', '$location', 'rolesDataService', 'isExamCreator', 'isExamCoordinator', 'i18nFilter', '$sessionStorage', '$state'];

    function exams(logger, exam, $rootScope, $location, rolesDataService, isExamCreator, isExamCoordinator, i18nFilter, $sessionStorage, $state) {
        var vm = this;

        vm.exam = exam;
        vm.isSmallCover = $rootScope.isSmallCover;
        
        
        vm.status = status;

        vm.formData = {
            isSmallCover: $rootScope.isSmallCover,
            navAuth: {
                manage: isExamCreator || isExamCoordinator || rolesDataService.isUserInRoles(['CatalogAdmin', 'ExamAdmin']),

                manageEdit: isExamCreator || isExamCoordinator || rolesDataService.isUserInRoles(['CatalogAdmin', 'ExamAdmin']),
                manageDelete: rolesDataService.isUserInRoles(['CatalogAdmin', 'ExamAdmin'])
            }
        };
        

        vm.imageStyle = {
            "background-image": "url('api/exams/" + vm.exam.id + "/defaultPicture')",
            "min-height": "400px"
        };
               
        $rootScope.$on('smallCoverChanged', function () {
            vm.formData.isSmallCover = $rootScope.isSmallCover;
        });

        vm.isActive = function (view) {
            var path = $location.path();

            switch (view) {
                case '/home':
                    if (path.endsWith('/home')) {
                        return true;
                    }
                    break;
                case '/administration':
                    if (
                        path.endsWith('/administration/edit') ||
                        path.endsWith('/administration/edit/about')
                        
                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
    }
})();
