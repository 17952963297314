(function () {
    'use strict';

    angular
        .module('app.programs')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('programs', {
                url: '/programs/:programId',
                parent: 'base',
                templateUrl: 'app/programs/programs.html',
                controller: 'ProgramsCtrl',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner', 'CatalogAdmin', 'ProgramOwner', 'Instructor']
                },
                resolve: {
                    isProgramOwner: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                        return authDataService.isCurrentUserRealProgramOwner($stateParams.programId);
                    }],                    
                    //isEnrolled: ['authDataService', '$stateParams', function (authDataService, $stateParams) {
                    //    return authDataService.isUserEnrolledInEducation($stateParams.id);
                    //}],
                    program: ['programsDataService', '$stateParams', function (programsDataService, $stateParams) {
                        return programsDataService.getProgramById($stateParams.programId);
                    }]
                }
            });
    }
})();
