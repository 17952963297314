(function () {
    'use strict';

    angular
        .module('app')
        .factory('competenceGroupsDataService', competencesGroups);

    competencesGroups.$inject = ['$http', '$q'];

    function competencesGroups($http, $q) {
        var service = {
            getCompetencesGroups: getCompetencesGroups,
            getCompetenceGroupsAndSubGroups: getCompetenceGroupsAndSubGroups,
            getRootCompetenceGroups: getRootCompetenceGroups,            
            deleteCompetenceGroup: deleteCompetenceGroup,
            addCompetenceGroup: addCompetenceGroup,
            editCompetenceGroup: editCompetenceGroup
        };

        return service;

        function getCompetencesGroups() {
            return $http.get('api/competenceGroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getCompetenceGroupsAndSubGroups() {
            return $http.get('api/competenceGroups/subgroups').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function getRootCompetenceGroups() {
            return $http.get('api/competenceGroups/root').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
        function deleteCompetenceGroup(id) {
            return $http.delete('api/competenceGroups/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addCompetenceGroup(competenceGroup) {
            return $http.post('api/competenceGroups', competenceGroup).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editCompetenceGroup(competenceGroup) {
            return $http.put('api/competenceGroups', competenceGroup).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

    }
})();
