(function () {
    'use strict';

    angular
        .module('app.promotedEntities')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('promotedEntities', {
                parent: 'base',
                url: '/manage/promotedEntities',
                templateUrl: 'app/manage/promotedEntities/list/promotedEntities.html',
                controller: 'promotedEntities',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    promotedEntities: ['promotedEntitiesDataService', function (promotedEntitiesDataService) {
                        return promotedEntitiesDataService.getPromotedEntities();
                    }]
                },
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('promotedEntities.delete', {
                url: '/delete/:id',
                // trigger the modal to open when this route is active
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/manage/promotedEntities/delete/deletePromotedEntities.html',
                        controller: 'deletePromotedEntities',
                        controllerAs: 'vm'
                    })
                        // change route after modal result
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('promotedEntities');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('promotedEntities');
                        });
                }],
                params: {
                    name: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['EducationAdmin']
                }
            })
            .state('promotedEntities.addEducation', {
                url: '/addEducation',
                templateUrl: 'app/manage/promotedEntities/addEducation/addEducationPromotedEntities.html',
                controller: 'addEducationPromotedEntities',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    educations: ['educationsDataService', function (educationsDataService) {
                        return educationsDataService.getPotentialEducationsForPromotedEntities();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('promotedEntities.addProgram', {
                url: '/addProgram',
                templateUrl: 'app/manage/promotedEntities/addProgram/addProgramPromotedEntities.html',
                controller: 'addProgramPromotedEntities',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {                    
                    programs: ['programsDataService', function (programsDataService) {
                        return programsDataService.getPotentialProgramsForPromotedEntities();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            })
            .state('promotedEntities.addSchooling', {
                url: '/addSchooling',
                templateUrl: 'app/manage/promotedEntities/addSchooling/addSchoolingPromotedEntities.html',
                controller: 'addSchoolingPromotedEntities',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    schoolings: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getPotentialSchoolingsForPromotedEntities();
                    }]
                },
                data: {
                    isEdit: false,
                    roles: ['EducationAdmin']
                }
            });
    }
})();
