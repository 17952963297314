(function () {
    'use strict';

    angular
        .module('app.logs')
        .controller('Logs', logs);

    logs.$inject = ['dateFilter', 'logsDataService', 'logger', 'i18nFilter'];

    function logs(dateFilter, logsDataService, logger, i18nFilter) {
        var vm = this;

        vm.levels = [
            {
                id: 0,
                format: i18nFilter('translations.log_level_trace')
            },
            {
                id: 1,
                format: i18nFilter('translations.log_level_debug')
            },
            {
                id: 2,
                format: i18nFilter('translations.log_level_info')
            },
            {
                id: 3,
                format: i18nFilter('translations.log_level_warning')
            },
            {
                id: 4,
                format: i18nFilter('translations.log_level_error')
            },
            {
                id: 5,
                format: i18nFilter('translations.log_level_critical')
            },
            {
                id: 6,
                format: i18nFilter('translations.log_level_none')
            }
        ];

        vm.options = {
            id: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            errorCode: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            message: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            userId: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            level: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            date: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined
        };

        var columns = [
            {
                dataField: 'id',
                caption: "Id",
                alignment: 'left',
                width: 100,
                allowSorting: true
            },
            {
                dataField: 'date',
                dataType: "date",
                alignment: 'left',
                width: 150,
                format: "dd.MM.yyyy",
                allowSorting: true,
                sortOrder: 'desc',
                customizeText: function (args) {
                    return dateFilter(args.value, 'short');
                },
                caption: i18nFilter('translations.general_gui_time')
            },
            {
                dataField: 'level',
                caption: i18nFilter('translations.general_gui_error_level'),
                alignment: 'left',
                width: 100,
                allowSorting: true,
                lookup: {
                    dataSource: vm.levels,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                dataField: 'errorCode',
                caption: i18nFilter('translations.general_gui_error_code'),
                alignment: 'left',
                width: 100,
                allowSorting: true
            },
            {
                dataField: 'message',
                caption: i18nFilter('translations.general_gui_error_message'),
                alignment: 'left',
                minWidth: 600,
                allowSorting: true
            },
            {
                dataField: 'userId',
                caption: i18nFilter('translations.general_gui_error_userId'),
                alignment: 'left',
                width: 300,
                allowSorting: true
            }
        ];

        function simpleFilterExtruder(element) {
            if (element[0] == "message") {
                vm.options.message.filterType1 = element[1];
                vm.options.message.searchText1 = element[2];
            }
            if (element[0] == "userId") {
                vm.options.userId.filterType1 = element[1];
                vm.options.userId.searchText1 = element[2];
            }
            if (element[0] == "id") {
                vm.options.id.filterType1 = element[1];
                vm.options.id.searchText1 = element[2];
            }
            if (element[0] == "errorCode") {
                vm.options.errorCode.filterType1 = element[1];
                vm.options.errorCode.searchText1 = element[2];
            }
            if (element[0] == "date") {
                vm.options.date.filterType1 = element[1];
                vm.options.date.searchText1 = element[2];
            }
            if (element[0] == "level") {
                vm.options.level.filterType1 = element[1];
                vm.options.level.searchText1 = element[2];
            }
        }

        function dateFilterExtruder(element) {
            if (element[0][0] === 'level' && element[2][0] === 'level') {
                vm.options.level.filterType1 = element[0][1];
                vm.options.level.searchText1 = element[0][2];
                vm.options.level.filterType2 = element[2][1];
                vm.options.level.searchText2 = element[2][2];
            }
            if (element[0][0] === 'date' && element[2][0] === 'date') {
                vm.options.date.filterType1 = element[0][1];
                vm.options.date.searchText1 = element[0][2];
                vm.options.date.filterType2 = element[2][1];
                vm.options.date.searchText2 = element[2][2];
            }
            if (element[0][0] === 'id' && element[2][0] === 'id') {
                vm.options.id.filterType1 = element[0][1];
                vm.options.id.searchText1 = element[0][2];
                vm.options.id.filterType2 = element[2][1];
                vm.options.id.searchText2 = element[2][2];
            }
            if (element[0][0] === 'errorCode' && element[2][0] === 'errorCode') {
                vm.options.errorCode.filterType1 = element[0][1];
                vm.options.errorCode.searchText1 = element[0][2];
                vm.options.errorCode.filterType2 = element[2][1];
                vm.options.errorCode.searchText2 = element[2][2];
            }
        }

        function clearFilter() {
            vm.options.message.filterType1 = undefined;
            vm.options.message.searchText1 = undefined;
            vm.options.message.filterType2 = undefined;
            vm.options.message.searchText2 = undefined;

            vm.options.userId.filterType1 = undefined;
            vm.options.userId.searchText1 = undefined;
            vm.options.userId.filterType2 = undefined;
            vm.options.userId.searchText2 = undefined;

            vm.options.id.filterType1 = undefined;
            vm.options.id.searchText1 = undefined;
            vm.options.id.filterType2 = undefined;
            vm.options.id.searchText2 = undefined;

            vm.options.errorCode.filterType1 = undefined;
            vm.options.errorCode.searchText1 = undefined;
            vm.options.errorCode.filterType2 = undefined;
            vm.options.errorCode.searchText2 = undefined;

            vm.options.level.filterType1 = undefined;
            vm.options.level.searchText1 = undefined;
            vm.options.level.filterType2 = undefined;
            vm.options.level.searchText2 = undefined;

            vm.options.date.filterType1 = undefined;
            vm.options.date.searchText1 = undefined;
            vm.options.date.filterType2 = undefined;
            vm.options.date.searchText2 = undefined;
        }

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        //Provjera za obični filter (string i integer)
                        if (loadOptions.filter[0].constructor !== Array) {
                            simpleFilterExtruder(loadOptions.filter);
                        }
                            //Filter za datume
                        else {
                            dateFilterExtruder(loadOptions.filter)
                        }
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                if (filterElement[0].constructor !== Array) {
                                    simpleFilterExtruder(filterElement);
                                }
                                    //Filter za datume
                                else {
                                    dateFilterExtruder(filterElement);
                                }
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.message.sortType = undefined;
                    vm.options.userId.sortType = undefined;
                    vm.options.id.sortType = undefined;
                    vm.options.level.sortType = undefined;
                    vm.options.date.sortType = undefined;

                    if (loadOptions.sort[0].selector == "message") {
                        vm.options.message.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "userId") {
                        vm.options.userId.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "id") {
                        vm.options.id.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "errorCode") {
                        vm.options.errorCode.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "level") {
                        vm.options.level.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    if (loadOptions.sort[0].selector == "date") {
                        vm.options.date.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                }

                //skip and take are used for paging
                vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                vm.options.take = loadOptions.take; //A number of records that should be taken

                logger.log("vm.options: ", vm.options);

                logsDataService.getLogs(angular.copy(vm.options)).then(function (data) {
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.manage_logs_error_message_fetching_logs'));
                    d.reject();
                });

                return d.promise();
            }
        };


        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource(dataSource),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: vm.options.itemsPerPage,
                showNavigationButtons: true
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true                
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
