(function () {
    'use strict';

    angular
        .module('app.exams.themes')
        .controller('themes', themes);

    themes.$inject = ['themes', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'themesDataService'];

    function themes(themes, i18nFilter, dateFilter, exceptionHelper, logger, themesDataService) {
        /* jshint validthis:true */
        var vm = this;
        
        vm.themes = themes;
        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit')
        };

        vm.statusTypes = [
            {
                id: 0,
                format: i18nFilter('translations.themes_edit_gui_label_theme_status_type_deactivated')
            },
            {
                id: 1,
                format: i18nFilter('translations.themes_edit_gui_label_theme_status_type_active')
            }
        ];

        var columns = [
            {
                dataField: 'name',
                caption: i18nFilter('translations.general_gui_name'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'status',
                caption: i18nFilter('translations.themes_edit_gui_label_theme_status_type'),
                alignment: 'left',
                filterOperations: ['contains'],
                allowFiltering: true,
                width: 150,
                lookup: {
                    dataSource: vm.statusTypes,
                    valueExpr: 'id',
                    displayExpr: 'format'
                }
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 200,
                allowSorting: false,
                cellTemplate: 'themesActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: vm.themes,
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            hoverStateEnabled: true,
            paging: {
                pageSize: 10,
                showNavigationButtons: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
