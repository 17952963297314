(function() {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('touchSpin', touchSpin);

    touchSpin.$inject = ['logger'];
    
    function touchSpin (logger) {
        return {
            restrict: 'A',
            scope: {
                spinOptions: '='
            },
            link: function (scope, element, attrs) {
                scope.$watch(scope.spinOptions, function () {
                    render();
                });
                var render = function () {
                    $(element).TouchSpin(scope.spinOptions);
                };
            }
        }
    }

})();