(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('learningPlanItemsDataService', activities);

    activities.$inject = ['$http', 'logger', '$q', 'dateHelper'];

    function activities($http, logger, $q, dateHelper) {
        var service = {
            getAllLearningPlanItems: getAllLearningPlanItems
        };

        return service;

        function getAllLearningPlanItems(filter) {
            return $http.post('api/learningPlans', filter).then(function (data) {
                angular.forEach(data.data.learningPlanItems, function (element, index) {
                    element.enrollmentDate = dateHelper.convertFromStringToDate(element.enrollmentDate);
                    element.dueDate = dateHelper.convertFromStringToDate(element.dueDate);
                });
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();