(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .controller('EditSchoolingAdministration', editSchoolingAdministration);

    editSchoolingAdministration.$inject = ['i18nFilter', 'editSchoolingHelper', '$stateParams', '$state', '$scope', 'schooling', 'emptyGuid'];

    function editSchoolingAdministration(i18nFilter, editSchoolingHelper, $stateParams, $state, $scope, schooling, emptyGuid) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = schooling;
        vm.navigation = [];
        vm.containerStyle = {};

        editSchoolingHelper.clearData();
        editSchoolingHelper.clearMyImage();


        if (vm.isEdit) {
            vm.containerStyle['margin-top'] = '-20px';
            vm.navigation = editSchoolingHelper.getWizardSteps($stateParams.id, vm.isEdit);
            editSchoolingHelper.setData(vm.item);
        }
        else {
            vm.containerStyle['margin'] = '10px';
            vm.navigation = editSchoolingHelper.getWizardSteps(null, vm.isEdit);
        }

        $scope.navigation = vm.navigation;


        $scope.goToStep = function (navItem) {
            $state.go(navItem.state);
        };
    }
})();
