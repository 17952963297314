(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamFifthStep', fifthStep);

    fifthStep.$inject = ['logger', 'editExamsHelper','$scope', '$state', '$stateParams', 'i18nFilter'];

    function fifthStep(logger, editExamsHelper ,$scope, $state, $stateParams, i18nFilter) {
        var vm = this;

        //Inherit from parent scope
        vm.navigation = $scope.navigation;

        vm.item = editExamsHelper.getData();
        vm.isEdit = $state.current.data.isEdit;
        
        vm.logData = logData;
        vm.previous = previous;
        vm.done = done;
        vm.cancel = cancel;
        vm.addCustomerPrice = addCustomerPrice;
        vm.removeCustomerPrice = removeCustomerPrice;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.exams_edit_gui_label_category")
        };

        function addCustomerPrice() {
            if (!vm.item.customerPrices) {
                vm.item.customerPrices = [];
            }

            vm.item.customerPrices.push('');
        }

        function removeCustomerPrice(index) {
            vm.item.customerPrices.splice(index, 1);
        }

        function previous() {
            $state.go(vm.navigation[3].state, $stateParams);
        }

        function done() {
            if (vm.item.targetSubgroups != null) {
                vm.item.targetSubgroups.forEach(function (targetSubgroupItem, index) {
                    targetSubgroupItem.sortOrder = index;
                });
            }

            var article19Application = vm.item.fieldNames.find((field) => {
                return field.code == "isArticle19Application"
            });

            if (article19Application)
                article19Application.sortOrder = 0;
           
            vm.item.fieldNames.forEach((field, index) => {
                if (field.code != "isArticle19Application") 
                    field.sortOrder = article19Application ? index + 1 : index;
            });


            editExamsHelper.done(vm.isEdit);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('exams.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.exam');
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }
    }
})();
