(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('ManageCompetenceLevelsTemplate', manageCompetenceLevelsTemplate);

    manageCompetenceLevelsTemplate.$inject = ['logger', 'competenceLevelsTemplate', '$scope', 'i18nFilter', 'competenceLevelsTemplateDataService', 'exceptionHelper'];

    function manageCompetenceLevelsTemplate(logger, competenceLevelsTemplate, $scope, i18nFilter, competenceLevelsTemplateDataService, exceptionHelper) {
        var vm = this;
        
        if (!competenceLevelsTemplate.competenceLevels || competenceLevelsTemplate.competenceLevels.length === 0) {
            vm.isEdit = false;
            vm.competenceLevels = [];
        } else {
            vm.isEdit = true;
            vm.competenceLevels = angular.copy(competenceLevelsTemplate.competenceLevels);
        }

        vm.dataGridOptions = {
            dataSource: vm.competenceLevels,
            keyExpr: "id",
            paging: {
                enabled: false
            },
            editing: {
                mode: "row",
                allowUpdating: true,
                allowDeleting: true,
                allowAdding: true,
                texts: {
                    confirmDeleteMessage: ''
                }
            },
            columns: [
                {
                    dataField: "orderNo",
                    caption: i18nFilter('translations.general_gui_ordinal_number'),
                    dataType: "number",
                    validationRules: [
                        { type: "required" }
                    ]
                },
                {
                    dataField: "name",
                    caption: i18nFilter('translations.general_gui_name'),
                    validationRules: [
                        { type: "required" },
                        { type: 'stringLength', max: 255, message: i18nFilter('translations.competence_name_validation_message') }
                    ]
                }
            ]
        };
        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            vm.competenceLevels = competenceLevelsTemplate.competenceLevels;
            $scope.$dismiss();
        };

        function ok() {
            var competenceLevelsTemplate = {
                competenceLevels:[]
            };            

            for (var i = 0; i < vm.competenceLevels.length; i++) {
                var temp = {
                    name: vm.competenceLevels[i].name,
                    orderNo: vm.competenceLevels[i].orderNo
                };
                
                competenceLevelsTemplate.competenceLevels.push(temp);
            }

            if (!vm.isEdit) {
                competenceLevelsTemplateDataService.addCompetenceLevelsTemplate(competenceLevelsTemplate).then(function (data) {
                    logger.success(i18nFilter('translations.competence_levels_template_queue_add'));
                    $scope.$close();
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            } else {
                competenceLevelsTemplateDataService.editCompetenceLevelsTemplate(competenceLevelsTemplate).then(function (data) {
                    logger.success(i18nFilter('translations.competence_levels_template_queue_edit'));
                    $scope.$close();
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        };
    }
})();
