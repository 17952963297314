(function () {
    'use strict';

    angular
        .module('app')
        .controller('finishUserEducation', finishUserEducation);

    finishUserEducation.$inject = ['$scope', '$filter', 'i18nFilter', 'enrolledLearnerData', 'programId', 'selectedEducationsData', 'learnerFinishedLinkedProgramEducations'];

    function finishUserEducation($scope, $filter, i18nFilter, enrolledLearnerData, programId, selectedEducationsData, learnerFinishedLinkedProgramEducations) {
        var vm = this;

        vm.programId = programId;
        vm.enrolledLearnerData = {
            firstName: enrolledLearnerData.firstName,
            lastName: enrolledLearnerData.lastName,
            userId: enrolledLearnerData.userId
        };
        vm.selectedEducations = selectedEducationsData;
        vm.finishedLinkedEducatons = learnerFinishedLinkedProgramEducations;

        //model data
        vm.modelData = {
            linkedEducationList: []
        };

        
        vm.linkedPEModelIsAvailable = [];
        vm.confirmedChanges = {
            userId: enrolledLearnerData.userId,
            learningPlanEducations: new Map()
        };
        
        vm.finishUserEducationPlaceholder = finishUserEducationPlaceholder;
        vm.linkedProgramEducationSelectionChanged = linkedProgramEducationSelectionChanged;
        vm.isDissabled = isDissabled;
        vm.disableOkButton = disableOkButton;
        vm.tagHandler = tagHandler;

        vm.ok = ok;
        vm.cancel = cancel;

        //fill model data based on the amount of selected educations
        angular.forEach(vm.selectedEducations, function (education, index) {
            vm.modelData.linkedEducationList.push(null);

            if (!education) {
                vm.linkedPEModelIsAvailable.push(false);
            }
            else {
                vm.linkedPEModelIsAvailable.push(true);
            }
        });

        function finishUserEducationPlaceholder(EducationId, index) {
            let linkedProgramEducationModel = vm.modelData.linkedEducationList[index];
            if ((angular.isUndefined(linkedProgramEducationModel) || linkedProgramEducationModel === null || angular.equals(linkedProgramEducationModel, {})) &&
                (Array.isArray(vm.finishedLinkedEducatons[EducationId]) && vm.finishedLinkedEducatons[EducationId].length === 0)) {
                vm.linkedPEModelIsAvailable[index] = false;
                return i18nFilter('translations.general_gui_finished_linked_program_education_no_items');
            }
            else {
                vm.linkedPEModelIsAvailable[index] = true;
                return i18nFilter('translations.general_gui_finished_linked_program_education');
            }
        };

        function linkedProgramEducationSelectionChanged(linkedProgramEducation, education) {
            if (linkedProgramEducation) {
                vm.confirmedChanges.learningPlanEducations.set(education.learningPlanItemId, linkedProgramEducation);
                return true;
            }

            if (vm.confirmedChanges.learningPlanEducations.has(education.learningPlanItemId)) {
                vm.confirmedChanges.learningPlanEducations.delete(education.learningPlanItemId);
            }

            return false;
        }

        function isDissabled(linkedProgramEducation) {
            if (Array.isArray(linkedProgramEducation) && linkedProgramEducation.length > 0)
                return false;
            else
                return true;
        }

        function tagHandler() {
            return null;
        }

        function disableOkButton() {
            let disable = true;

            for (let i = 0; i < vm.modelData.linkedEducationList.length; i++) {
                if (vm.modelData.linkedEducationList[i] && vm.modelData.linkedEducationList[i].id) {
                    disable = false;
                    break;
                }
            }

            return disable;
        }

        function ok() {
            const result = Object.fromEntries(vm.confirmedChanges.learningPlanEducations)
            vm.confirmedChanges.learningPlanEducations = result;
            $scope.$close(vm.confirmedChanges);
        };

        function cancel() {
            $scope.$dismiss();
        };
    }
})();
