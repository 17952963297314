(function () {
    'use strict';

    angular
        .module('app.exams.administration.delete')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('exams.administration.delete', {
                url: '/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/exams/administration/delete/delete.html',
                        controller: 'DeleteExam',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            // redirect to main page for all exams after deletion
                            $state.go('classicCatalog.exam');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('exams.home', { id: $stateParams.id });
                        });
                }],
                params: {
                    examName: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'ExamAdmin']
                }
            });
    }
})();
