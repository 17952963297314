(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.edit')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolings.administration.edit', {
                url: '/edit',
                templateUrl: 'app/schoolings/administration/edit/baseStep/edit.html',
                controller: 'EditSchoolingAdministration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return schoolingsDataService.getEditSchooling($stateParams.id);
                    }]
                }
            })

            .state('schoolings.administration.edit.firstStep', {
                url: '/about',
                templateUrl: 'app/schoolings/administration/edit/firstStep/firstStep.html',
                controller: 'EditSchoolingFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {                    
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevelsForSchooling();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('schoolings.administration.edit.secondStep', {
                url: '/options',
                templateUrl: 'app/schoolings/administration/edit/secondStep/secondStep.html',
                controller: 'EditSchoolingSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    prerequisiteSchoolings: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getAllActiveSchoolings();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            })
            
            .state('schoolings.administration.edit.thirdStep', {
                url: '/prices',
                templateUrl: 'app/schoolings/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditSchoolingThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]
                }
            })

            .state('schoolings.administration.edit.fourthStep', {
                url: '/plans',
                templateUrl: 'app/schoolings/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditSchoolingFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                }
            })

            .state('schoolings.administration.edit.fifthStep', {
                url: '/eForms',
                templateUrl: 'app/schoolings/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditSchoolingFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    fieldNames: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getActiveSchoolingFieldNames();
                    }]
                }
            })

            .state('addSchooling', {
                url: '/addSchooling',
                parent: 'base',
                templateUrl: 'app/schoolings/administration/edit/baseStep/edit.html',
                controller: 'EditSchoolingAdministration',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    schooling: ['schoolingsDataService', '$stateParams', function (schoolingsDataService, $stateParams) {
                        return {};
                    }],
                }
            })

            .state('addSchooling.firstStep', {
                url: '/about',
                templateUrl: 'app/schoolings/administration/edit/firstStep/firstStep.html',
                controller: 'EditSchoolingFirstStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {                   
                    categories: ['categoriesDataService', function (categoriesDataService) {
                        return categoriesDataService.getAllActiveFlatCategories(false);
                    }],
                    tags: ['tagsDataService', function (tagsDataService) {
                        return tagsDataService.getAllTags();
                    }],
                    competenceLevels: ['competenceLevelsDataService', function (competenceLevelsDataService) {
                        return competenceLevelsDataService.getDdlCompetenceLevelsForSchooling();
                    }],
                    targetSubgroups: ['targetGroupsDataService', function (targetGroupsDataService) {
                        return targetGroupsDataService.getAllTargetSubgroups();
                    }]
                }
            })
            .state('addSchooling.secondStep', {
                url: '/options',
                templateUrl: 'app/schoolings/administration/edit/secondStep/secondStep.html',
                controller: 'EditSchoolingSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin']
                },
                resolve: {
                    languages: ['languagesDataService', function (languagesDataService) {
                        return languagesDataService.getAllLanguages();
                    }],
                    templates: ['templatesDataService', function (templatesDataService) {
                        return templatesDataService.getAllActiveTemplates();
                    }],
                    prerequisiteSchoolings: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getAllActiveSchoolings();
                    }],
                    surveys: ['surveysDataService', function (surveysDataService) {
                        return surveysDataService.getAllActiveSurveys();
                    }]
                }
            })
            .state('addSchooling.thirdStep', {
                url: '/prices',
                templateUrl: 'app/schoolings/administration/edit/thirdStep/thirdStep.html',
                controller: 'EditSchoolingThirdStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }]            
                }
            })
            .state('addSchooling.fifthStep', {
                url: '/eForms',
                templateUrl: 'app/schoolings/administration/edit/fifthStep/fifthStep.html',
                controller: 'EditSchoolingFifthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                },
                resolve: {
                    priceCategories: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getPriceCategories();
                    }],
                    currencies: ['pricesDataService', function (pricesDataService) {
                        return pricesDataService.getCurrencies();
                    }],
                    fieldNames: ['schoolingsDataService', function (schoolingsDataService) {
                        return schoolingsDataService.getActiveSchoolingFieldNames();
                    }]
                }
            })
            .state('addSchooling.fourthStep', {
                url: '/plans',
                templateUrl: 'app/schoolings/administration/edit/fourthStep/fourthStep.html',
                controller: 'EditSchoolingFourthStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['CatalogAdmin', 'RealSchoolingOwner']
                }
            })
            ;
    }
})();
