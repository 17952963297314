(function () {
    'use strict';

    angular
        .module('app.deactevatedUser')
        .controller('DeactevatedUser', deactevatedUser);

    deactevatedUser.$inject = ['logger'];

    function deactevatedUser(logger) {
        var vm = this;
        
    }
})();
