(function () {
    'use strict';

    angular
        .module('app.educations.administration.delete')
        .controller('DeleteEducation', deleteEducation);

    deleteEducation.$inject = ['logger', '$scope', '$stateParams', '$state', 'educationsDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteEducation(logger, $scope, $stateParams, $state, educationsDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        
        vm.cancel = cancel;
        vm.ok = ok;
        vm.educationName = $stateParams.educationName;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            educationsDataService.deleteEducation($stateParams.id).then(function (additionalData) {
                logger.success(i18nFilter('translations.education_operation_delete_success_message', additionalData.data.educationName));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error, false);
            });

        };
    }
})();
