(function () {
    'use strict';

    angular
        .module('app.targetGroups')
        .controller('editTargetSubgroup', editTargetSubgroup);

    editTargetSubgroup.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'status', 'targetSubgroup', 'targetGroupsDataService', 'exceptionHelper', 'logger'];

    function editTargetSubgroup(decimalHelper, dateHelper, $state, i18nFilter, status, targetSubgroup, targetGroupsDataService, exceptionHelper, logger) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = targetSubgroup;
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {}
        vm.formData = {
            status: status
        }

        vm.add = add;
        vm.save = save;

        function add() {
            targetGroupsDataService.addTargetSubgroup(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.targetSubgroups_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            targetGroupsDataService.editTargetSubgroup(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.targetSubgroups_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
