(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackApplication', examSchedulePackApplication);

    examSchedulePackApplication.$inject = ['$state', '$stateParams', '$timeout', 'examApplicationStatus', 'examApplicationFieldType', 'customerPrices', '$scope', 'i18nFilter', 'exceptionHelper', 'logger', 'examApplication', 'commissionMembers', 'examApplicationsDataService', '$uibModal', 'dateHelper'];

    function examSchedulePackApplication($state, $stateParams, $timeout, examApplicationStatus, examApplicationFieldType, customerPrices, $scope, i18nFilter, exceptionHelper, logger, examApplication, commissionMembers, examApplicationsDataService, $uibModal, dateHelper) {
        var vm = this;
        vm.firstExamTry = firstExamTry;
        vm.done = done;
        vm.save = save;
        vm.toggleActivity = toggleActivity;
        vm.toggleTheme = toggleTheme;
        vm.refreshTable = refreshTable;
        vm.downloadFile = downloadFile;
        vm.previousApplication = previousApplication;
        vm.nextApplication = nextApplication;
        vm.scrollToTop = scrollToTop;
        vm.getFillInTextPart = getFillInTextPart;
        vm.toLocalFormatString = toLocalFormatString;
        vm.themeChaned = themeChaned;
        vm.reject = reject;
        vm.formatArticle19Date = formatArticle19Date;
        vm.getPeriodDuration = getPeriodDuration;
        vm.sumDurations = sumDurations;
        vm.generateExamCertificateNumber = generateExamCertificateNumber;
        vm.formData = {
            customerPrices: [],
            users: commissionMembers,
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons',
                readonly: examApplication.readOnly
            },
            activityThemes: {},
            examApplicationFieldType: examApplicationFieldType,
            examApplicationStatus: examApplicationStatus
        };

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            yesOption: i18nFilter("translations.general_gui_yes"),
            noOption: i18nFilter("translations.general_gui_no"),
            nextApplication: i18nFilter("translations.examSchedulePack_application_nextApplication"),
            previousApplication: i18nFilter("translations.examSchedulePack_application_previousApplication"),
            responseDisabled: i18nFilter("translations.examSchedulePack_application_responseDisabled")
        };

        angular.forEach(customerPrices, function (price, index) {
            vm.formData.customerPrices.push(price);
        });

        vm.examApplication = examApplication;
        vm.disabled = (vm.examApplication.applicationStatus == 0 || vm.examApplication.applicationStatus == 1 || vm.examApplication.applicationStatus == 2) ? false : true;

        vm.examApplication.examScheduleId = $stateParams.schedulePackId;
        vm.isArticle19Application = null;

        vm.examCoordinatorCertificateNumber = vm.examApplication.examCoordinatorCertificateNumber;
        vm.certificateNumberManual = true;


        for (var i = 0; i < vm.examApplication.optionalFields.length; i++) {
            if (vm.examApplication.optionalFields[i].code == 'degreeDate' && vm.examApplication.degreeDateBologne) {
                vm.examApplication.optionalFields[i].name += "-prva stopnja";
                vm.examApplication.optionalFields.splice(i+1,0, {
                    id: 1,
                    name: "Datum diplomiranja-druga stopnja",
                    fieldType: 0,
                    code: "degreeDateBologne",
                    parentId: null
                });
            }

            if (vm.examApplication.optionalFields[i].code == 'degreeNumber' && vm.examApplication.degreeNumberBologne) {
                vm.examApplication.optionalFields[i].name += "-prva stopnja";
                vm.examApplication.optionalFields.splice(i+1,0, {
                    id: 2,
                    name: "Številka diplome-druga stopnja",
                    fieldType: 0,
                    code: "degreeNumberBologne",
                    parentId: null
                });
            }

            else if (vm.examApplication.optionalFields[i].code == 'isArticle19Application') {
                vm.isArticle19Application = vm.examApplication.isArticle19Application;
            }

        }

        vm.examApplication.examActivities.forEach(x => {
            if (x.canOnlyHaveOneTheme) {
                var selectedOnlyTheme = x.activityThemes.filter(y => { return y.checked });
                vm.formData.activityThemes[x.id] = selectedOnlyTheme.length == 1 ? selectedOnlyTheme[0] : null;

                }
        })

        if (!vm.examApplication.readOnly && vm.examApplication.examPrice == 0) {
            vm.examApplication.examPrice = null;
        }
        vm.examApplication.selectedActivities = [];
        for (var i = 0, len = vm.examApplication.examActivities.length; i < len; i++) {
            vm.examApplication.examActivities[i].selectedThemes = [];
        }

        scrollToTop();

        $("#examRequirements").html(vm.examApplication.examRequirements);

        function firstExamTry() {
            return vm.examApplication.examTries === 0;
        }

        function toggleActivity(act) {

            act.activityThemes.forEach(function (x) {
                x.checked = act.checked;
            });
        }

        function formatArticle19Date(field) {
            if (!field) 
                return "/";

            return new Date(field).toLocaleDateString("Sl-si");
        }

        function getPeriodDuration(from, to) {
            var duration = dateHelper.getDateRangeDuration(from, to);
            var durationString = dateHelper.formatDurationString(duration);
            return duration.months || duration.days ? ("(" + durationString + ")") : "";
        }

        function sumDurations(field){
            var durationsSum = { months: 0, days: 0 };

            let childFields = vm.examApplication.optionalFields.filter((x) => {
                return x.parentId == field.id && x.name != "do";
            });

            childFields.forEach((x) => {
                let key = x.code;

                vm.examApplication.article19ApplicationFields[x.code].forEach((y, index) => {

                    let fromDate = vm.examApplication.article19ApplicationFields[key][index].from;
                    let toDate = y.to;

                    let rowDuration = dateHelper.getDateRangeDuration(fromDate, toDate);
                    durationsSum.days += rowDuration.days;
                    durationsSum.months += rowDuration.months;
                });
            });

            if (durationsSum.days >= 30) {
                durationsSum.months += Math.floor(durationsSum.days / 30);
                durationsSum.days = durationsSum.days % 30;
            }

            return dateHelper.formatDurationString(durationsSum);
        }

        function toggleTheme(theme, activity) {

            if (activity.activityThemes.filter(x => x.checked == true).length == 0) {
                activity.checked = false;
            }

            else {
                activity.checked = true;
            }
        }

        function refreshTable() {
            if (vm.customerPrice.price) {
                vm.examApplication.examPrice = vm.customerPrice.price;
            }
        }

        function downloadFile() {
            if (vm.examApplication.paymentProofPath !== "") {
                examApplicationsDataService.downloadFile(vm.examApplication.paymentProofPath);
            }
        }

        function previousApplication() {
            $stateParams.applicationId = vm.examApplication.previousApplicationId;
            $state.go('examSchedulePacks.application', $stateParams);
            $state.reload('examSchedulePacks.application', $stateParams);
        }

        function nextApplication() {
            $stateParams.applicationId = vm.examApplication.nextApplicationId;
            $state.go('examSchedulePacks.application', $stateParams);
            $state.reload('examSchedulePacks.application', $stateParams);
        }

        function scrollToTop() {
            $('html').animate({
                scrollTop: $('#applicationBox').offset().top
            }, 700);
        }

        function getFillInTextPart(fieldName, index) {
            var split = fieldName.split('_');
            split = split.filter(s => {return s != "";});

            if (split.length > index) 
                return split[index];          
        }


        function toLocalFormatString(date) {
            var newDate = new Date(date);
            return newDate.toLocaleDateString('sl-SI');
        }

        function themeChaned(activity, newSelectedTheme) {
            if (!newSelectedTheme) {
                activity.checked = false;
                return;
            }

            if (!newSelectedTheme.checked) {
                vm.formData.activityThemes[activity.id] = newSelectedTheme
                activity.activityThemes.forEach(x => {
                    if (x.id == newSelectedTheme.id) {
                        x.checked = true;
                        activity.checked = true;
                    }
                    else {
                        x.checked = false;
                    }
                });
            }
        }

        function reject() {
            $uibModal.open({
                templateUrl: 'app/examSchedulePacks/application/toggleApplicationRejection/toggleApplicationRejection.html',
                controller: 'toggleApplicationRejection',
                controllerAs: 'vm'
            })
                .result.then(function (result) {
                    done(vm.formData.examApplicationStatus.rejected)
                }, function () {
                });

        }


        function done(status) {
            vm.examApplication.updateExamCertificateOrderNumber = vm.examApplication.examCoordinatorCertificateNumber != vm.examCoordinatorCertificateNumber; // check if coordinator changed certificateNumber
            vm.examApplication.optionalFields = vm.examApplication.optionalFields.filter(function (optionalField){return !optionalField.code.endsWith('Bologne');})
            vm.examApplication.examActivities.forEach(x => {
                if (x.canOnlyHaveOneTheme) {
                    var selectedOnlyTheme = x.activityThemes.filter(y => { return y.checked });
                    if (selectedOnlyTheme.length == 1) {
                        x.checked = true;
                    }
                    else if (selectedOnlyTheme.length == 0) {
                        x.checked = false;
                    }
                }
            })
            switch (status) {
                case vm.formData.examApplicationStatus.approved:
                    if (($scope.applicationCheckForm.$invalid)) {
                        angular.forEach($scope.applicationCheckForm.$error, function (field) {
                            angular.forEach(field, function (errorField) {
                                errorField.$setTouched();
                            });
                        });
                        return;
                    }
                    examApplicationsDataService.saveExamApplicationResponce(angular.copy(vm.examApplication), vm.formData.examApplicationStatus.approved).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
                case vm.formData.examApplicationStatus.rejected:
                    examApplicationsDataService.saveExamApplicationResponce(angular.copy(vm.examApplication), vm.formData.examApplicationStatus.rejected).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });

                    break;
                case vm.formData.examApplicationStatus.pendingPersonalData:
                    examApplicationsDataService.saveExamApplicationResponce(angular.copy(vm.examApplication), vm.formData.examApplicationStatus.pendingPersonalData).then(function (data) {
                        logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                    break;
            }

            if (vm.examApplication.nextApplicationId !== null) {
                nextApplication();
            }
            else if (vm.examApplication.previousApplicationId !== null) {
                previousApplication();
            }
            else {
                $state.reload();
            }
        }

        function generateExamCertificateNumber() {
            examApplicationsDataService.getNextExamCertificateNumberByScheduleId(vm.examApplication.examScheduleId).then(function (data) {
                vm.examApplication.examCoordinatorCertificateNumber = data.generateNextExamCertificateNumber;
            })
        }

        function save() {
            vm.examApplication.updateExamCertificateOrderNumber = vm.examApplication.examCoordinatorCertificateNumber != vm.examCoordinatorCertificateNumber; // check if coordinator changed certificateNumber
            var itemCopy = angular.copy(vm.examApplication);
            itemCopy.optionalFields = itemCopy.optionalFields.filter(function (x) {
                return x.code != "degreeDateBologne" && x.code != "degreeNumberBologne";
            });
            examApplicationsDataService.saveReviewedExamApplicationResponce(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.examSchedulePack_application_exam_queue_update'), data);
                $timeout(function () { $scope.displayErrorMsg = false; }, 1000);
                $state.go('examSchedulePacks.applications', $stateParams, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
