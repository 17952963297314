(function () {
    'use strict';

    angular
        .module('app.programs.administration.edit')
        .controller('EditProgramFourthStep', fourthStep);

    fourthStep.$inject = ['logger', '$state', '$scope', 'educations', 'editProgramHelper', '$stateParams', 'orderingHelper', 'i18nFilter'];

    function fourthStep(logger, $state, $scope, educations, editProgramHelper, $stateParams, orderingHelper, i18nFilter) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;
        vm.item = editProgramHelper.getData();  //DOESN'T COME PROPERLY SORTED, CAN CAUSE VERY STRANGE BUGS!!!

        vm.localeMessages = {
            moveUpTooltip: i18nFilter('translations.general_action_move_up'),
            moveDownTooltip: i18nFilter('translations.general_action_move_down')
        }

        vm.formData = {
            educations: angular.copy(educations),
            educationsYearsList: []
        };

        //Linked Educations data
        vm.newEducationProgram = null;        //selected item
        vm.linkedEducationsListData = [];   //dropdown items lists
        vm.linkedEducationsStores = [];     //stores for gridData
        vm.dataGridOptions = [];            //grid dataSource options
        vm.isCollapsed = [];    //carret info (show/hide linked tables)

        vm.next = next;
        vm.cancel = cancel;
        vm.back = back;

        vm.deleteEducationProgram = deleteEducationProgram;
        vm.addEducationProgram = addEducationProgram;

        vm.deleteLinkedEducationProgram = deleteLinkedEducationProgram;
        vm.addLinkedEducationProgram = addLinkedEducationProgram;

        vm.getDataGridOptions = getDataGridOptions;

        vm.carret = carret;
        vm.collapse = collapse;
        vm.isLinkedEducationSelected = isLinkedEducationSelected;
        vm.linkedEduFormName = linkedEduFormName;

        vm.resetListSelections = resetListSelections;
        vm.findLowestOrderNo = findLowestOrderNo;
        vm.findHighestOrderNo = findHighestOrderNo;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.save = save;

        prepareStepData();  //mandatory step
        fillCollapse();

        function prepareStepData() {
            //IF THERE IS A PROBLEM, CHECK SORTING FIRST!!!
            vm.item.educations.sort(compareEducationProgramsByOrderNo);
            angular.forEach(vm.item.educations, function (education, index) {
                removePotentialEducationFromList(education.id);
                vm.linkedEducationsListData.push({});   //add the store for each dropdown

                //fill in existing tables data
                angular.forEach(vm.item.educations[index].linkedEducations, function (linkedEducation, linkIndex) {
                    removePotentialEducationFromList(linkedEducation.id);
                });

                vm.linkedEducationsStores.push(new DevExpress.data.ArrayStore(education.linkedEducations));   //create ArrayStore for griddata
            });
        }

        //#region Education functions
        function deleteEducationProgram(educationProgram) {
            var indexToDelete = -1;
            angular.forEach(vm.item.educations, function (edu, index) {
                if (edu.id === educationProgram.id) {
                    indexToDelete = index;
                }
            });

            if (indexToDelete !== -1) {
                //first clear the linked programs educations data
                vm.linkedEducationsListData.splice(indexToDelete, 1);  //1. remove the dropdown list
                
                let educationsToAdd = educationProgram.linkedEducations;   //2. save existing sub table data
                educationProgram.linkedEducations = [];                    //3. reset selected linked data
                angular.forEach(educationsToAdd, function (education, index) {
                    addPotentialEducationToList(education.id);  //4. fill back existing table data into vm.formData.educations
                });

                vm.linkedEducationsStores.splice(indexToDelete, 1);   //5. remove ArrayStore for devx dataGrid -> dataSource
                vm.dataGridOptions.splice(indexToDelete, 1);    //6. remove the devx dataGrid as well
                vm.isCollapsed.splice(indexToDelete, 1);        //7. remove carret info

                //finally remove the education item
                vm.item.educations.splice(indexToDelete, 1);        //8. remove main table program education
                addPotentialEducationToList(educationProgram.id);   //9. add back program educations to formData educations
            }

            var start = 1;
            angular.forEach(vm.item.educations, function (educationProgram, index) {
                educationProgram.orderNo = start;
                start++;
            });

        }

        function addEducationProgram() {

            var educationProgram = {
                id: vm.newEducationProgram.education.id,
                isMandatory: vm.newEducationProgram.isMandatory,
                name: vm.newEducationProgram.education.name,
                orderNo: vm.findHighestOrderNo() + 1,
                linkedEducations: []    //create data tables for each of the education
            };

            vm.item.educations.push(educationProgram);
            removePotentialEducationFromList(educationProgram.id);

            //update the table for LinkedProgramEducations
            vm.linkedEducationsListData.push({});   //add the store for each dropdown
            let lastIndex = vm.item.educations.length - 1;
            vm.linkedEducationsStores.push(new DevExpress.data.ArrayStore(vm.item.educations[lastIndex].linkedEducations));   //create ArrayStore for griddata
            vm.isCollapsed.push(true);

            //Reset form & lists
            vm.resetListSelections();
        }
        //#endregion Education functions

        //#region Linked Education functions
        function deleteLinkedEducationProgram(linkedEducation, num) {
            var indexToDelete = -1;
            angular.forEach(vm.item.educations[num].linkedEducations, function (edu, index) {
                if (edu.id === linkedEducation.id) {
                    indexToDelete = index;
                }
            });

            if (indexToDelete !== -1) {
                vm.item.educations[num].linkedEducations.splice(indexToDelete, 1);  //linked education
                addPotentialEducationToList(linkedEducation.id);    //care!! uses formData.educations
                vm.dataGridOptions[num].dataSource.reload();
            }
        }

        function addLinkedEducationProgram(education, num) {
            //prepare data for LinkedProgramEducationVM
            var linkedEucationProgram = {
                id: vm.linkedEducationsListData[num].education.id,
                name: vm.linkedEducationsListData[num].education.name,
            };

            //add to the list and remove from existing lists
            vm.item.educations[num].linkedEducations.push(linkedEucationProgram);   //structure for the program VM
            removePotentialEducationFromList(linkedEucationProgram.id);

            //Reset the holder of the added item
            vm.resetListSelections();
            vm.dataGridOptions[num].dataSource.reload();
        }
        //#endregion Linked Education functions

        function resetListSelections() {
            //reset the selection and focus of Programs Education
            vm.newEducationProgram = null;
            $scope.educationsForm.educations.$touched = false;

            //reset the selection of Linked Educations
            angular.forEach(vm.linkedEducationsListData, function (selection, index) {
                vm.linkedEducationsListData[index] = {};
            });
        }

        function removePotentialEducationFromList(educationId) {
            var indexToRemove = -1;
            angular.forEach(vm.formData.educations, function (potentialEducation, index) {
                if (potentialEducation.id === educationId) {
                    indexToRemove = index;
                }
            });

            if (indexToRemove !== -1) {
                vm.formData.educations.splice(indexToRemove, 1);
            }
        }

        function addPotentialEducationToList(educationId) {
            var educationToAdd = undefined;
            angular.forEach(educations, function (education, index) {
                if (education.id === educationId) {
                    educationToAdd = education;
                }
            });

            if (educationToAdd)
                vm.formData.educations.push(angular.copy(educationToAdd));
        }

        function moveUp(target, index) {    //min index: 1
            if (vm.item.educations.length > 1) {
                //IF THERE IS A PROBLEM, CHECK SORTING FIRST!!!
                vm.item.educations.sort(compareEducationProgramsByOrderNo);

                let collapsed = vm.isCollapsed[index];  //start at 1
                vm.isCollapsed[index] = vm.isCollapsed[index - 1];
                vm.isCollapsed[index - 1] = collapsed;

                let tmpItemEdus = vm.item.educations[index];    //start at 1, OrderNum 2
                let tempOrderNo = vm.item.educations[index - 1].orderNo;    //OrderNum 1
                vm.item.educations[index] = vm.item.educations[index - 1];
                vm.item.educations[index].orderNo = tmpItemEdus.orderNo;
                vm.item.educations[index - 1] = tmpItemEdus;
                vm.item.educations[index - 1].orderNo = tempOrderNo;

                let tmpListData = vm.linkedEducationsListData[index];    //start at 1
                vm.linkedEducationsListData[index] = vm.linkedEducationsListData[index - 1];
                vm.linkedEducationsListData[index - 1] = tmpListData;

                let tmpStoreData = vm.linkedEducationsStores[index];    //start at 1
                vm.linkedEducationsStores[index] = vm.linkedEducationsStores[index - 1];
                vm.linkedEducationsStores[index - 1] = tmpStoreData;

                let tempGridData = vm.dataGridOptions[index];
                vm.dataGridOptions[index] = vm.dataGridOptions[index - 1];
                vm.dataGridOptions[index - 1] = tempGridData;

                vm.dataGridOptions[index].dataSource.reload();
                vm.dataGridOptions[index - 1].dataSource.reload();
            }
        }

        function moveDown(target, index) {  //min index: 0
            if (vm.item.educations.length > index + 1) {
                //IF THERE IS A PROBLEM, CHECK SORTING FIRST!!!
                vm.item.educations.sort(compareEducationProgramsByOrderNo);

                let collapsed = vm.isCollapsed[index];  //start at 0
                vm.isCollapsed[index] = vm.isCollapsed[index + 1];
                vm.isCollapsed[index + 1] = collapsed;

                let tmpItemEdus = vm.item.educations[index];    //start at 0, OrderNum 1
                let tempOrderNo = vm.item.educations[index + 1].orderNo;    //OrderNum 2
                vm.item.educations[index] = vm.item.educations[index + 1];
                vm.item.educations[index].orderNo = tmpItemEdus.orderNo;
                vm.item.educations[index + 1] = tmpItemEdus;
                vm.item.educations[index + 1].orderNo = tempOrderNo;

                let tmpListData = vm.linkedEducationsListData[index];    //start at 0
                vm.linkedEducationsListData[index] = vm.linkedEducationsListData[index + 1];
                vm.linkedEducationsListData[index + 1] = tmpListData;

                let tmpStoreData = vm.linkedEducationsStores[index];    //start at 0
                vm.linkedEducationsStores[index] = vm.linkedEducationsStores[index + 1];
                vm.linkedEducationsStores[index + 1] = tmpStoreData;

                let tempGridData = vm.dataGridOptions[index];
                vm.dataGridOptions[index] = vm.dataGridOptions[index + 1];
                vm.dataGridOptions[index + 1] = tempGridData;

                vm.dataGridOptions[index].dataSource.reload();
                vm.dataGridOptions[index + 1].dataSource.reload();
            }
        }
        function sortEducations() {

        }

        function findLowestOrderNo() {
            return orderingHelper.findLowestOrderNo(vm.item.educations);
        }

        function findHighestOrderNo() {
            return orderingHelper.findHighestOrderNo(vm.item.educations);
        }

        function save() {
            var item_copy = angular.copy(vm.item);
            editProgramHelper.setData(item_copy);
            editProgramHelper.done(vm.isEdit, true);
        }

        function next() {
            $state.go($scope.navigation[4].state);
        }

        function back() {
            $state.go($scope.navigation[2].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('programs.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.program');
            }
        }

        function compareEducationProgramsByOrderNo(a, b) {
            if (a.orderNo < b.orderNo)
                return -1;
            if (a.orderNo > b.orderNo)
                return 1;
            return 0;
        }


        //#region connected Educations

        //#region List data
        function carret(num) {
            if (vm.isCollapsed[num] === true)
                return '\u25B6';
            else if (vm.isCollapsed[num] === false)
                return '\u25BC';
            else
                return '\u25B6';
        }

        function fillCollapse() {
            vm.item.educations.forEach(education => { vm.isCollapsed.push(true) });
        }

        function collapse(index) {
            vm.isCollapsed[index] = !vm.isCollapsed[index];
        }

        function isLinkedEducationSelected(num) {
            if (vm.linkedEducationsListData[num] && Object.entries(vm.linkedEducationsListData[num]).length > 0)
                return true;
            else
                return false;
        }

        function linkedEduFormName(num) {
            return "linkedEducation" + num;
        }
        //#endregion List data


        //#region Table data
        function getDataGridOptions(num) {
            let dataGridOptions = {
                dataSource: new DevExpress.data.DataSource(vm.linkedEducationsStores[num]),
                showBorders: false,
                paging: {
                    enabled: false,
                },
                columns: [
                    {
                        dataField: 'name',
                        caption: i18nFilter('translations.program_gui_linked_education'),
                        alignment: 'left',
                        dataType: 'string',
                        allowSorting: true
                    },
                    {
                        name: "delete-icon",
                        caption: i18nFilter('translations.general_gui_actions'),
                        width: 75,
                        allowFiltering: false,
                        allowSorting: false,
                        cellTemplate: 'deleteIconTemplate'  //is defined in view
                    }
                ]
            };


            vm.dataGridOptions.push(dataGridOptions);
            return vm.dataGridOptions[num];
        }
        //#endregion Table data

        //#endregion connected Educations
    }
})();
