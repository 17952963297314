(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('bulletinSubscriptionsDataService', bulletinSubscriptions);

    bulletinSubscriptions.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function bulletinSubscriptions($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getSubscriptionById: getSubscriptionById,
            addSubscription: addSubscription,
            editSubscription: editSubscription,
            deleteSubscription: deleteSubscription
        };

        return service;

        function getSubscriptionById(id) {
            return $http.get('api/bulletin/subscription/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function editSubscription(subscription) {
            subscription.subscribedOn = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(subscription.subscribedOn));
            subscription.subscribedTo = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(subscription.subscribedTo));

            return $http.put('api/bulletin/subscription', subscription).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function addSubscription(subscription) {
            subscription.subscribedOn = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(subscription.subscribedOn));
            subscription.subscribedTo = dateHelper.convertToDateTime(dateHelper.convertFromStringToDate(subscription.subscribedTo));

            return $http.post('api/bulletin/subscription', subscription).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }

        function deleteSubscription(id) {
            return $http.delete('api/bulletin/subscription/' + id).then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
    }
})();
