(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('EditProgramSchedulePack', editProgramSchedulePack);

    editProgramSchedulePack.$inject = ['dateFilter', 'logger', 'i18nFilter', '$stateParams', '$state', 'schedulePack', 'priceCategories', 'currencies', 'programSchedulePackHelper', 'schedulePacksDataService', 'exceptionHelper'];

    function editProgramSchedulePack(dateFilter, logger, i18nFilter, $stateParams, $state, schedulePack, priceCategories, currencies, programSchedulePackHelper, schedulePacksDataService, exceptionHelper) {
        var vm = this;
        programSchedulePackHelper.clear();

        vm.item = schedulePack;

        //#region Locale messages
        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };
        //#endregion

        vm.formData = {
            programId: undefined,
            educations: [],

            isEdit: $state.current.data.isEdit,

            priceCategories: priceCategories,
            currencies: currencies
        };

        //#region Method binding
        vm.save = save;
        vm.add = add;
        vm.getSchedulePackDuration = getSchedulePackDuration;
        //#endregion

        //#region Initial setup
        if (!$state.current.data.isEdit) {
            //Add
            vm.formData.programId = $stateParams.programId;
        }
        else {
            //Edit
            vm.formData.programId = vm.item.programId;
        }
        convertSchedulePackInBetterFormat();
        //#endregion

        function getSchedulePackDuration() {
            var from = dateFilter(vm.item.startTime, 'shortDate');
            var to = dateFilter(vm.item.endTime, 'shortDate');
            if (from === to) {
                return from;
            } else {
                return from + ' - ' + to;
            }
        }


        function save() {
            var allFormsAreValid = checkIfSchedulesAreValid() && checkIfPackFormIsValid() && checkIfThereIsValidBudget();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }

            var itemCopy = angular.copy(vm.item);

            itemCopy.schedules.forEach(function (schedule) {
                if (schedule.instructors != null) {
                    schedule.instructors.forEach(function (scheduleInstructor) {
                        scheduleInstructor.role = scheduleInstructor.role.id;
                        scheduleInstructor.extraCost = parseFloat(scheduleInstructor.extraCost.toString().replace(',', '.'));
                        scheduleInstructor.travelCost = parseFloat(scheduleInstructor.travelCost.toString().replace(',', '.'));
                    })
                }
            })

            schedulePacksDataService.editSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_packs_gui_operation_edit_success_message'));
                $state.go("schedulePacks", { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function add() {
            var allFormsAreValid = checkIfSchedulesAreValid() && checkIfPackFormIsValid() && checkIfThereIsValidBudget();
            if (!allFormsAreValid) {
                logger.error(i18nFilter('translations.schedule_packs_from_validation_failed'));
                return;
            }

            var itemCopy = angular.copy(vm.item);

            itemCopy.schedules.forEach(function (schedule) {
                if (schedule.instructors != null) {
                    schedule.instructors.forEach(function (scheduleInstructor) {
                        scheduleInstructor.role = scheduleInstructor.role.id;
                        scheduleInstructor.extraCost = parseFloat(scheduleInstructor.extraCost.toString().replace(',', '.'));
                        scheduleInstructor.travelCost = parseFloat(scheduleInstructor.travelCost.toString().replace(',', '.'));
                    })
                }
            })

                schedulePacksDataService.addSchedulePack(itemCopy).then(function (data) {
                logger.success(i18nFilter('translations.schedule_packs_gui_operation_add_success_message'));
                $state.go("schedulePacks", { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function checkIfSchedulesAreValid() {
            var allFormsAreValid = true;

            var allEducationIds = vm.formData.educations.map(function (schedules) { return schedules[0].educationId; });

            _.each(allEducationIds, function (educationId) {
                _.each(programSchedulePackHelper.getApisForKey('schedule-' + educationId), function (api) {
                    var formIsValid = api.checkIfFormIsValid();
                    if (!formIsValid) {
                        allFormsAreValid = false;
                    }
                });
            });

            return allFormsAreValid;
        }

        function checkIfPackFormIsValid() {
            var allFormsAreValid = true;

            _.each(programSchedulePackHelper.getApisForKey('pack'), function (api) {
                var formIsValid = api.checkIfFormIsValid();
                if (!formIsValid) {
                    allFormsAreValid = false;
                }
            });
            return allFormsAreValid;
        }

        function checkIfThereIsValidBudget() {
            var allFormsAreValid = true;

            var allEducationIds = vm.formData.educations.map(function (schedules) { return schedules[0].educationId; });

            _.each(allEducationIds, function (educationId) {
                _.each(programSchedulePackHelper.getApisForKey('pie-' + educationId), function (api) {
                    var formIsValid = api.checkIfThereIsValidBudget();
                    if (!formIsValid) {
                        allFormsAreValid = false;
                    }
                });
            });

            return allFormsAreValid;
        }

        //#region Utils
        function convertSchedulePackInBetterFormat() {
            var temp = _.groupBy(vm.item.schedules, function (schedule) {
                return schedule.educationId;
            });

            vm.formData.educations = _.values(temp);
        }
        //#endregion
    }
})();
