(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('success', successFilter);

    successFilter.$inject = ['logger', 'learningActivitySuccess', 'i18nFilter'];

    function successFilter(logger, learningActivitySuccess, i18nFilter) {
        return function (input) {
            
            var value = Number(input)

            switch (value) {
                case learningActivitySuccess.unknown:
                    return i18nFilter('translations.general_gui_success_unknown');
                case learningActivitySuccess.success:
                    return i18nFilter('translations.general_gui_success_pass');
                case learningActivitySuccess.fail:
                    return i18nFilter('translations.general_gui_success_fail');
            }
        };
    }
})();