(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('surveys.questionGroups', {
                url: '/questionGroups',
                templateUrl: 'app/surveys/questionGroups/questionGroups.html',
                controller: 'QuestionGroups',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                },
                resolve: {
                    questionGroups: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getSurveyQuestionGroupsBySurveyId($stateParams.surveyId);
                    }]
                }
            })
            .state('surveys.questionGroups.delete', {
                url: '/delete/{questionGroupId}',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/questionGroups/delete/delete.html',
                        controller: 'DeleteSurveyQuestionGroup',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        }, function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        });
                }],
                params: {
                    questionGroupName: undefined,
                    questionGroupId: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questionGroups.edit', {
                url: '/edit/{questionGroupId}',
                onEnter: ['$stateParams', '$state', '$uibModal', 'surveyQuestionGroup', function ($stateParams, $state, $uibModal, surveyQuestionGroup) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/questionGroups/edit/edit.html',
                        controller: 'EditSurveyQuestionGroup',
                        controllerAs: 'vm',
                        resolve: {
                            surveyQuestionGroup: [function () {
                                return surveyQuestionGroup;
                            }]
                        }

                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        }, function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        });
                }],
                resolve: {
                    surveyQuestionGroup: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getSurveyQuestionGroupById($stateParams.questionGroupId);
                    }]
                },
                requireADLogin: true,
                data: {
                    isEdit: true,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questionGroups.add', {
                url: '/add',
                onEnter: ['$stateParams', '$state', '$uibModal', 'surveyQuestionGroup', function ($stateParams, $state, $uibModal, surveyQuestionGroup) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/questionGroups/edit/edit.html',
                        controller: 'EditSurveyQuestionGroup',
                        controllerAs: 'vm',
                        resolve: {
                            surveyQuestionGroup: function () {
                                return surveyQuestionGroup;
                            }
                        }
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        }, function () {
                            // change route after clicking OK button
                            $state.go('surveys.questionGroups');
                        });
                }],
                resolve: {
                    surveyQuestionGroup: function () {
                        return {};
                    }
                },
                requireADLogin: true,
                data: {
                    isEdit: false,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questions', {
                url: '/questionGroups/{questionGroupId}/questions',
                templateUrl: 'app/surveys/questionGroups/questions/questions.html',
                controller: 'SurveyGroupQuestions',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    questions: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getQuestionsForQuestionGroup($stateParams.questionGroupId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questions.add', {
                url: '/add',
                templateUrl: 'app/surveys/questionGroups/questions/edit/edit.html',
                controller: 'EditQuestion',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    question: function () {
                        return {};
                    }
                },
                data: {
                    isEdit: false,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questions.edit', {
                url: '/edit/{questionId}',
                templateUrl: 'app/surveys/questionGroups/questions/edit/edit.html',
                controller: 'EditQuestion',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    question: ['surveysDataService', '$stateParams', function (surveysDataService, $stateParams) {
                        return surveysDataService.getQuestionById($stateParams.questionId);
                    }]
                },
                data: {
                    isEdit: true,
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            })
            .state('surveys.questions.delete', {
                url: '/delete/{questionId}',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/surveys/questionGroups/questions/delete/delete.html',
                        controller: 'DeleteQuestion',
                        controllerAs: 'vm'
                    })
                        .result.then(function () {
                            // change route after clicking OK button
                            $state.go('surveys.questions');
                        }, function () {
                            // change route after clicking cancel button
                            $state.go('surveys.questions');
                        });
                }],
                params: {
                    questionName: undefined,
                    questionId: undefined
                },
                requireADLogin: true,
                data: {
                    roles: ['SurveyAdmin', 'SurveyOwner']
                }
            });
    }
})();
