(function () {
    'use strict';

    angular
        .module('app.surveys.questionGroups')
        .controller('DeleteQuestion', deleteQuestion);

    deleteQuestion.$inject = ['logger', '$stateParams', '$scope', 'surveysDataService', 'exceptionHelper', 'i18nFilter'];

    function deleteQuestion(logger, $stateParams, $scope, surveysDataService, exceptionHelper, i18nFilter) {
        var vm = this;
        vm.questionName = $stateParams.questionName;

        vm.cancel = cancel;
        vm.ok = ok;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            surveysDataService.deleteQuestion($stateParams.questionId).then(function (result) {
                logger.success(i18nFilter('translations.survey_question_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error);
                $scope.$dismiss();
            });
        };

    }
})();
