(function () {
    'use strict';

    angular
        .module('app.educations.administration.activities.edit.scorm')
        .controller('ScormSecondStep', scormSecondStep);

    scormSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', 'status'];

    function scormSecondStep(logger, activitiesWizardDataService, $state, $scope, status) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.end = end;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;
        vm.status = status;
        function end() {
            activitiesWizardDataService.done(vm.isEdit);
        }

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
