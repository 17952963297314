(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('sendNotificationStatus', sendNotificationStatus);

    sendNotificationStatus.$inject = ['i18nFilter'];

    function sendNotificationStatus(i18nFilter) {
        return function (input) {            
            var value = Number(input);
            if (value === 1) {
                return i18nFilter('translations.email_filter_send_notification_status_sent');
            }
            return i18nFilter('translations.email_filter_send_notification_status_error');
        };
    }

})();