(function () {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('nationalId', nationalId);

    nationalId.$inject = ['$locale', 'logger'];

    function nationalId($locale, logger) {
        // Usage:
        //     <nationalId></nationalId>
        // Creates:
        // 
        var directive = {
            link: link,
            require: 'ngModel',
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            ctrl.$validators.nationalId = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                var number = modelValue;
                var output = [];
                var sNumber = number.toString();

                for (var i = 0; i < sNumber.length; i += 1) {
                    output.push(+sNumber.charAt(i));
                }

                /*var vsota = (7 * (output[0] + output[6]) +
                    6 * (output[1] + output[7]) +
                    5 * (output[2] + output[8]) +
                    4 * (output[3] + output[9]) +
                    3 * (output[4] + output[10]) +
                    2 * (output[5] + output[11]));

                var ostanek = vsota % 11;

                var v = 11 - ostanek;*/

                var v = 11 - ((7 * (output[0] + output[6]) +
                    6 * (output[1] + output[7]) +
                    5 * (output[2] + output[8]) +
                    4 * (output[3] + output[9]) +
                    3 * (output[4] + output[10]) +
                    2 * (output[5] + output[11])) % 11);

                if (v > 9) {
                    v = 0;
                }

                if (v === output[12]) {
                    return true;
                } else {
                    return false;
                }
            };
        }
    }

})();
