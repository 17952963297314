(function () {
    'use strict';

    angular
        .module('app.eLearningEducationEnrollments')
        .controller('ELearningEducationEnrollments', eLearningEducationEnrollments);

    eLearningEducationEnrollments.$inject = ['logger', '$rootScope', 'educations', 'selectedEducation', 'educationsDataService', 'schedulesDataService', 'exceptionHelper', 'i18nFilter', "$uibModal", 'learningActivityScheduleStatus', 'learningActivityState', "eFormStatus", 'learningPlanItemStatus', 'learningPlanItemSuccess', 'documentsDataService', 'eFormTypes', 'eFormsUrl', '$sessionStorage', '$state', '$stateParams'];

    function eLearningEducationEnrollments(logger, $rootScope, educations, selectedEducation, educationsDataService, schedulesDataService, exceptionHelper, i18nFilter, $uibModal, learningActivityScheduleStatus, learningActivityState, eFormStatus, learningPlanItemStatus, learningPlanItemSuccess, documentsDataService, eFormTypes, eFormsUrl, $sessionStorage, $state, $stateParams) {
        var vm = this;
        //#region Setup main data
        vm.selectedEducation = selectedEducation;
        vm.showAll = $rootScope.showAll || false;
        vm.pageIndex = $stateParams.pageIndex || 0;
        vm.newSelection = false;

        vm.formData = {
            educations: educations,
            learners: [],
            eFormStatus: eFormStatus,
            learningPlanItemStatus: learningPlanItemStatus,
            learningPlanItemSuccess: learningPlanItemSuccess,
            selectedLearners: [],
            totalLearners: 0
        };
        //#endregion Setup main data

        vm.i18nFilter = i18nFilter;

        //#region Setup global functions
        vm.refreshTable = refreshTable;
        vm.downloadCertificate = downloadCertificate;
        vm.regenerateCetificate = regenerateCetificate;
        vm.openEForm = openEForm;
        vm.select = select;

        //for buttons
        vm.disableApplicationButtons = disableApplicationButtons;
        vm.disableSendEmail = disableSendEmail;

        vm.confirmApplication = confirmApplication;
        vm.denyApplication = denyApplication;
        vm.openRejectedELearnerModal = openRejectedELearnerModal;
        vm.sendMailToSelectedLearners = sendMailToSelectedLearners;
        vm.downloadLearnersList = downloadLearnersList;
        //#endregion Setup global functions

        function refreshTable() {
            if (!vm.selectedEducation || !vm.selectedEducation.id)
                return;

            $rootScope.showAll = false; //prevents showAll being automatically ON when returning to eLearningEducations

            $stateParams.id = vm.selectedEducation.id;
            if (vm.newSelection) {
                $stateParams.pageIndex = 0; //set page to 0 when selecting new education
                vm.pageIndex = $stateParams.pageIndex;
                vm.newSelection = false;
                $state.go('eLearningEducationEnrollments', $stateParams, { reload: true, notify: true });
            }
            else {
                vm.gridInstance.refresh();
            }
        }

        function downloadCertificate(learningData) {
            if (learningData.certificateUrl) {
                documentsDataService.downloadDocument("api/learningPlans/" + learningData.learningPlanItemId + "/certificate", "Potrdilo-" + learningData.learningPlanItemId + ".pdf").then(function (data) {
                }, function (error) {
                    error = {
                        exception: {
                            additionalData: {},
                            result: { exceptionCode: -90007, operationCode: 0 }
                        },
                        status: 400
                    };
                    exceptionHelper.handleException(error);
                });
            }
        }

        function regenerateCetificate(learningData) {
            if (learningData.templateGenerationEnabled && learningData.learningPlanItemStatus == vm.formData.learningPlanItemStatus.finished && learningData.learningPlanItemSuccess !== vm.formData.learningPlanItemSuccess.fail) {
                documentsDataService.regenerateCertificate(learningData.learningPlanItemId).then(function (data) {
                    logger.success(i18nFilter('translations.education_regenerate_certificate_queue_add'));
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        function openEForm(eFormId, eFormType) {
            if (!eFormId) {
                return;
            }

            //if (eFormType === eFormTypes.education) {
            //    window.open(eFormsUrl[$sessionStorage.userInfo.tenantId] + '/edit/education/' + eFormId, '_blank');
            //}
            //else if (eFormType === eFormTypes.program) {
            //    window.open(eFormsUrl[$sessionStorage.userInfo.tenantId] + '/edit/program/' + eFormId, '_blank');
            //}
            //else if (eFormType === eFormTypes.exams) {
            window.open(eFormsUrl[$sessionStorage.userInfo.tenantId] + '/edit/onlineEducation/' + eFormId, '_blank');
            // }
        }

        //#region Table
        //#region Filter lists
        vm.activityStates = [
            {
                id: learningActivityState.started,
                format: i18nFilter('translations.filter_learning_activity_status_in_progress')
            },
            {
                id: learningActivityState.finished,
                format: i18nFilter('translations.filter_learning_activity_status_finished')
            }
        ];
        vm.scheduleEFormStatus = [
            {
                id: eFormStatus.inProgress,
                format: i18nFilter(
                    "translations.filter_eform_status_in_progress"
                ),
            },
            {
                id: eFormStatus.finished,
                format: i18nFilter("translations.filter_eform_status_finished"),
            },
            {
                id: eFormStatus.pendingPersonalData,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_personal_data"
                ),
            },
            {
                id: eFormStatus.pendingConfirmation,
                format: i18nFilter(
                    "translations.filter_eform_status_pending_confirmation"
                ),
            },
            {
                id: eFormStatus.rejected,
                format: i18nFilter("translations.filter_eform_status_rejected"),
            },
        ];

        vm.activityDisplayValue = function (data) {
            var activityStateTranslation = "";
            if (data.learningActivityState == learningActivityState.finished)
                activityStateTranslation = data.isSucessfull ? 'translations.filter_learning_activity_status_finished' : 'translations.filter_learning_activity_status_finished_unsucessfull';
            else
                activityStateTranslation = 'translations.filter_learning_activity_status_in_progress';

            return i18nFilter(activityStateTranslation);
        }
        //#endregion

        vm.options = {
            displayName: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            organization: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            enrollmentDate: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            finishedDate: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            eFormStatus: {
                sortType: undefined,
                filterType1: undefined,
                searchText1: undefined,
                filterType2: undefined,
                searchText2: undefined
            },
            take: undefined,
            skip: undefined,
            showAll: false
        };

        var dataSource = {
            load: function (loadOptions) {
                var d = new $.Deferred();

                clearFilter();
                //Getting filter options
                if (loadOptions.filter) {
                    if (loadOptions.filter.columnIndex !== undefined) {
                        simpleFilterExtruder(loadOptions.filter);
                    }
                    else {
                        angular.forEach(loadOptions.filter, function (filterElement, filterIndex) {
                            //Ukoliko je više od jedan filter u primjeni
                            if (filterElement.constructor === Array) {
                                //Provjera za obični filter (string i integer)
                                simpleFilterExtruder(filterElement);
                            }
                        });
                    }
                }
                //Getting sort options
                if (loadOptions.sort) {
                    vm.options.displayName.sortType = undefined;
                    vm.options.organization.sortType = undefined;
                    vm.options.enrollmentDate.sortType = undefined;
                    vm.options.finishedDate.sortType = undefined;
                    vm.options.eFormStatus.sortType = undefined;


                    if (typeof loadOptions.sort[0].selector === 'function') {
                        vm.options.displayName.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    else if (loadOptions.sort[0].selector == "organization") {
                        vm.options.organization.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    else if (loadOptions.sort[0].selector == "learningPlanItemEnrollmentDate") {
                        vm.options.enrollmentDate.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    else if (loadOptions.sort[0].selector == "learningPlanItemFinishedAt") {
                        vm.options.finishedDate.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    else if (loadOptions.sort[0].selector == "eFormStatus") {
                        vm.options.eFormStatus.sortType = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                }

                //skip and take are used for paging ()
                if (loadOptions.skip != null && loadOptions.take != null) {
                    vm.options.skip = loadOptions.skip; //A number of records that should be skipped
                    vm.options.take = loadOptions.take; //A number of records that should be taken
                    vm.options.showAll = vm.showAll;
                }
                else {
                    //the select all messes up the skip and take loadOptions as undefined (due to dataSource load call)
                    if (vm.gridOptions.selection.selectAllMode == "allPages") {
                        vm.options.skip = 0;    //so we make sure we select all the records
                        vm.options.take = vm.formData.totalLearners;    //we need to outsource the saved max amount of learners
                        //NOTE: Care! Debugging is messed up an will not enable to deselect anything
                    }
                }

                educationsDataService.getOnlineEducationLearners(vm.selectedEducation ? vm.selectedEducation.id : null, angular.copy(vm.options)).then(function (data) {
                    vm.formData.totalLearners = data.totalItems;
                    d.resolve(data.data, { totalCount: data.totalItems });
                }, function (error) {
                    logger.error(i18nFilter('translations.manage_logs_error_message_fetching_logs'));
                    d.reject();
                });

                return d.promise();
            }
        };

        vm.gridOptions = {
            dataSource: dataSource,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            onInitialized: function (e) {
                vm.gridInstance = e.component;
            },
            paging: {
                enabled: true,
                pageSize: 15,    //defines the "take" size
                showNavigationButtons: true,
                pageIndex: vm.pageIndex
            },
            pager: {
                visible: true
            },
            remoteOperations: {
                paging: true,
                filtering: true,
                sorting: true
            },
            selection: {
                mode: "multiple",
                selectAllMode: "allPages"
            },
            onSelectionChanged: function (selectedItems) {
                vm.formData.selectedLearners = selectedItems.selectedRowsData;
            },
            columns: [
                {
                    caption: i18nFilter("translations.schedule_presence_gui_main_table_user_educations"),
                    cellTemplate: 'displayNameTemplate',
                    allowSorting: true,
                    allowFiltering: true,
                    filterOperations: ["contains", "="],
                    calculateCellValue: function (rowData) {
                        //CAUTION: LastName TitleName? FirstName
                        if (rowData.learner.displayName)
                            return rowData.learner.displayName;
                        else if(rowData.learner.titleName)
                            return rowData.learner.lastName + " " + rowData.learner.titleName + " " + rowData.learner.firstName;
                        else
                            return rowData.learner.lastName + " " + rowData.learner.firstName;
                    }
                },
                {
                    dataField: "learner.organizationName",
                    filterOperations: ["contains", "="],
                    caption: i18nFilter("translations.schedule_presence_gui_main_table_payer"),
                    allowSorting: false,
                    allowFiltering: true,
                    minWidth: '300px'
                },
                {
                    caption: i18nFilter("translations.online_education_learners_enrollment_date"),
                    dataField: 'learningPlanItemEnrollmentDate',
                    allowSorting: true,
                    allowFiltering: true,
                    filterOperations: ["=", ">", "<"],
                    dataType: "datetime"
                },
                {
                    caption: i18nFilter("translations.online_education_learners_finished_date"),
                    dataField: 'learningPlanItemFinishedAt',
                    dataType: "datetime",
                    allowSorting: true,
                    allowFiltering: true,
                    filterOperations: ["=", ">", "<"]
                },
                {
                    caption: i18nFilter("translations.schedule_presence_gui_main_table_schedule_eform_status"),
                    dataField: "eFormStatus",
                    allowSorting: true,
                    allowFiltering: true,
                    //  width: 'auto',
                    lookup: {
                        dataSource: vm.scheduleEFormStatus,
                        valueExpr: "id",
                        displayExpr: "format",
                    }
                }
            ],
            masterDetail: {
                enabled: true,
                template: "detail"
            },
            onContentReady: function (e) {
                e.element.find(".dx-page").on("click", function (e) {
                    $stateParams.pageIndex = parseInt(e.currentTarget.innerHTML) - 1;
                    vm.pageIndex = $stateParams.pageIndex;
                    $state.transitionTo("eLearningEducationEnrollments", $stateParams, {
                        reload: false,
                        notify: false
                    });
                });
            }
        };
        //#endregion

        //#region Buttons logic

        //Disable logic
        function disableApplicationButtons() {
            if ((vm.selectedEducation != null && vm.selectedEducation.automaticApplicationConfirmation === false) &&
                anyLearnersSelected()) {

                if (vm.formData.selectedLearners != null && Array.isArray(vm.formData.selectedLearners)) {
                    for (let i = 0; i < vm.formData.selectedLearners.length; i++) {
                        if (vm.formData.selectedLearners[i].eFormStatus !== vm.formData.eFormStatus.pendingConfirmation)
                            return true;    //do not allow buttons to be enabled if one learner in selection has status finished
                    }
                }

                return false;   //enable button
            }
            else
                return true;    //disable button
        }
        function disableSendEmail() {
            if (anyLearnersSelected())
                return false;   //enable button
            else
                return true;    //disable button
        }
        //helper functions for disable logic
        function anyLearnersSelected() {
            if (vm.formData.selectedLearners != null && Array.isArray(vm.formData.selectedLearners) && vm.formData.selectedLearners.length > 0)
                return true;
            else
                return false;
        }

        //On-click logic
        function confirmApplication() {
            $rootScope.showAll = vm.showAll;
            $stateParams.pageIndex = vm.pageIndex;
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedLearners,
                function (learner, index) {
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.learner.id);
                }
            );

            schedulesDataService.confirmOnlineApplication(
                eFormIds,
                learnerIds,
            );
        }

        function denyApplication(rejectReason) {
            $rootScope.showAll = vm.showAll;
            $stateParams.pageIndex = vm.pageIndex;
            var eFormIds = [];
            var learnerIds = [];

            angular.forEach(
                vm.formData.selectedLearners,
                function (learner, index) {
                    eFormIds.push(learner.eFormId);
                    learnerIds.push(learner.learner.id);
                }
            );

            schedulesDataService.denyOnlineApplication(
                eFormIds,
                learnerIds,
                rejectReason
            );
        }

        function openRejectedELearnerModal() {
            var modalInstance = $uibModal.open({
                templateUrl: "app/eLearningEducationEnrollments/modals/rejectedLearnerModal/rejectedELearnerModal.html",
                controller: "RejectedELearnerModalDialog",
                controllerAs: "modalvm",
                resolve: {
                    selectedEducation: vm.selectedEducation,
                },
            });

            modalInstance.result.then(
                function (data) {
                    denyApplication(data);
                },
                function () {
                    //close callback
                }
            );
        }

        function sendMailToSelectedLearners() {
            var subject = vm.selectedEducation.name;
            var to = "";

            const toRecipients = vm.formData.selectedLearners.length - 1;

            angular.forEach(
                vm.formData.selectedLearners,
                function (selected, index) {
                    if (index < toRecipients) {
                        to += selected.learner.email + ";";
                    }
                    else {
                        to += selected.learner.email;
                    }
                }
            );

            window.location.href = "mailto:?bcc=" + to + "&subject=" + subject;
        }

        function downloadLearnersList() {
            documentsDataService.downloadDocument('api/educations/' + vm.selectedEducation.id + '/online/learnersList/', 'Seznam udeležencev.xlsx');
        };
        //#endregion Buttons logic

        function simpleFilterExtruder(element) {
            switch (element.columnIndex) {
                case 0:
                    vm.options.displayName.filterType1 = element[1];
                    vm.options.displayName.searchText1 = element[2];
                    break;
                case 1:
                    vm.options.organization.filterType1 = element[1];
                    vm.options.organization.searchText1 = element[2];
                    break;
                case 2:
                    if (element[0].constructor === Array) {
                        vm.options.enrollmentDate.filterType1 = "=";
                        vm.options.enrollmentDate.searchText1 = element[0][2];
                    }
                    else {
                        vm.options.enrollmentDate.filterType1 = element[1];
                        vm.options.enrollmentDate.searchText1 = element[2];
                    }
                    break;
                case 3:
                    if (element[0].constructor === Array) {
                        vm.options.finishedDate.filterType1 = "=";
                        vm.options.finishedDate.searchText1 = element[0][2];
                    }
                    else {
                        vm.options.finishedDate.filterType1 = element[1];
                        vm.options.finishedDate.searchText1 = element[2];
                    }
                    break;
                case 4:
                    vm.options.eFormStatus.filterType1 = element[1];
                    vm.options.eFormStatus.searchText1 = element[2];
                    break;
            }
        }

        function clearFilter() {
            vm.options.displayName.filterType1 = undefined;
            vm.options.displayName.searchText1 = undefined;
            vm.options.displayName.filterType2 = undefined;
            vm.options.displayName.searchText2 = undefined;

            vm.options.organization.filterType1 = undefined;
            vm.options.organization.searchText1 = undefined;
            vm.options.organization.filterType2 = undefined;
            vm.options.organization.searchText2 = undefined;

            vm.options.enrollmentDate.filterType1 = undefined;
            vm.options.enrollmentDate.searchText1 = undefined;
            vm.options.enrollmentDate.filterType2 = undefined;
            vm.options.enrollmentDate.searchText2 = undefined;

            vm.options.finishedDate.filterType1 = undefined;
            vm.options.finishedDate.searchText1 = undefined;
            vm.options.finishedDate.filterType2 = undefined;
            vm.options.finishedDate.searchText2 = undefined;

            vm.options.eFormStatus.filterType1 = undefined;
            vm.options.eFormStatus.searchText1 = undefined;
            vm.options.eFormStatus.filterType2 = undefined;
            vm.options.eFormStatus.searchText2 = undefined;
        }

        function select() {
            vm.newSelection = true;
            vm.refreshTable()
        }
    }

})();
