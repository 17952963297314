(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .factory('editExamsHelper', editExamsHelper);

    editExamsHelper.$inject = ['logger', 'examsDataService', 'exceptionHelper', '$state', '$stateParams', 'i18nFilter', 'examAccessMode'];

    function editExamsHelper(logger, examsDataService, exceptionHelper, $state, $stateParams, i18nFilter, examAccessMode) {

        var internalData = undefined;
        var wizardSteps = undefined;

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            setData: setData,
            clearData: clearData,
            done: done,
            setCodeListData: setCodeListData
        };

        return service;

        function getData() {
            if (!internalData) {
                internalData = {
                    accessMode: examAccessMode.publicAccess,
                };
            }
            return internalData;
        }

        function getWizardSteps(isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            wizardSteps = [];

            if (isEdit) {
                wizardSteps = [
                    {
                        state: 'exams.administration.edit.firstStep',
                        text: i18nFilter('translations.exams_edit_wizard_first_step_title')
                    },
                    {
                        state: 'exams.administration.edit.secondStep',
                        text: i18nFilter('translations.exams_edit_wizard_second_step_title')
                    },
                    {
                        state: 'exams.administration.edit.thirdStep',
                        text: i18nFilter('translations.exams_edit_wizard_third_step_title')
                    },
                    {
                        state: 'exams.administration.edit.fourthStep',
                        text: i18nFilter('translations.exams_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'exams.administration.edit.fifthStep',
                        text: i18nFilter('translations.exams_edit_wizard_fifth_step_title')
                    }
                ];
            }
            else {
                wizardSteps = [
                    {
                        state: 'addExam.firstStep',
                        text: i18nFilter('translations.exams_edit_wizard_first_step_title')
                    },
                    {
                        state: 'addExam.secondStep',
                        text: i18nFilter('translations.exams_edit_wizard_second_step_title')
                    },
                    {
                        state: 'addExam.thirdStep',
                        text: i18nFilter('translations.exams_edit_wizard_third_step_title')
                    },
                    {
                        state: 'addExam.fourthStep',
                        text: i18nFilter('translations.exams_edit_wizard_fourth_step_title')
                    },
                    {
                        state: 'addExam.fifthStep',
                        text: i18nFilter('translations.exams_edit_wizard_fifth_step_title')
                    }
                ];
            }


            return wizardSteps;
        }

        function setData(exam) {
            internalData = exam;
        }

        function setCodeListData(exam) {
            var item_copy = angular.copy(exam);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem, index) {
                    targetSubgroupItem.sortOrder = index;
                });
            }

            var article19Application = item_copy.fieldNames.find((field) => {
                return field.code == "isArticle19Application"
            });

            if (article19Application)
                article19Application.sortOrder = 0;

            item_copy.fieldNames.forEach((field, index) => {
                if (field.code != "isArticle19Application")
                    field.sortOrder = article19Application ? index + 1 : index;
            });

            return item_copy;
        }

        function clearData() {
            internalData = undefined;
        }

        function done(isEdit, partialSave) {
            var resultCC = ValidateEmails(internalData.emailCC);
            var resultBCC = ValidateEmails(internalData.emailBCC);
            if (resultCC && resultBCC) {
                if (isEdit) {
                    return examsDataService.editExam(internalData).then(function (data) {
                        logger.success(i18nFilter("translations.exams_queue_edit"));
                        $state.go(partialSave ? $state.current : "exams.home", $stateParams);
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
                else {
                    examsDataService.addExam(internalData).then(function (data) {
                        logger.success(i18nFilter("translations.exams_queue_add"));
                        $state.go("classicCatalog.exam");
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            }
            else {
                logger.error(i18nFilter("translations.exam_gui_catalogue_email_wrong_format"));
            }
        }

        function IsValidEmail(elementValue) {
            var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailPattern.test(elementValue);
        }

        function ValidateEmails(emails) {
            var result = true;
            if (emails) {
                var inputsArr = emails.split(';');
                inputsArr.forEach(function (email) {
                    email = email.replace(/\s/g, '');
                    if (IsValidEmail(email)) {
                    } else {
                        result = false;
                    }
                }
                );
            }
            return result;
        }
    }
})();
