(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('catalogueType', catalogueType);

    catalogueType.$inject = ['logger', 'i18nFilter'];

    function catalogueType(logger, i18nFilter) {
        return function (input) {
            if (input === undefined) {
                return input;
            }
            
            var value = Number(input);

            switch (value) {
                case 1:
                    return i18nFilter('translations.exams_edit_gui_label_exam_type_automatic');
                case 2:    
                    return i18nFilter('translations.exams_edit_gui_label_exam_type_manual');
            }
        };
    }
})();
