(function () {
    'use strict';

    angular
        .module('app.notifications')
        .controller('notificationConfiguration', notificationConfiguration);

    notificationConfiguration.$inject = ['notifications'];

    function notificationConfiguration(notifications) {
        /* jshint validthis:true */
        var vm = this;
        vm.item = notifications;   
        vm.edit = edit;

        function edit() {
            // Animacija scrollanja na element #edit-user
            setTimeout(function () {
                $('html').animate({
                    scrollTop: $('#edit-configuration').offset().top
                }, 500);
            }, 100);
        }
    }
})();


