(function () {
    'use strict';

    angular
        .module('app.exams.bulletin')
        .controller('bulletinsController', bulletinsController);

    bulletinsController.$inject = ['i18nFilter', 'dateFilter', 'exceptionHelper', 'logger'];

    function bulletinsController(i18nFilter, dateFilter, exceptionHelper, logger) {
        var vm = this;

        vm.localeMessages = {
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete')
        };

        var columns = [
            {
                dataField: 'code',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_code'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'publishedOn',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_published_on'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true,
                sortOrder: 'desc',
            },
            {
                dataField: 'totalProductionPrice',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_total_production_price'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'numberOfCopies',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_number_of_copies'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'copyProductionPrice',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_copy_production_price'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                dataField: 'marketPrice',
                caption: i18nFilter('translations.exam_bulletin_edit_gui_market_price'),
                alignment: 'left',
                allowFiltering: true,
                cssClass: 'table-column-space-format wrapped-column',
                allowSorting: true
            },
            {
                caption: i18nFilter('translations.general_gui_actions'),
                alignment: 'left',
                width: 100,
                allowSorting: false,
                cellTemplate: 'bulletinActionTemplate'
            }
        ];

        vm.dataGridOptions = {
            dataSource: new DevExpress.data.DataSource({
                store: {
                    type: "odata",
                    version: 4,
                    url: "odata/BulletinOData",
                    key: "Id",
                    keyType: "Guid",
                    beforeSend: function (e) {
                        var token = localStorage['adal.idtoken'];
                        if (token) {
                            e.headers = {
                                "Authorization": "Bearer " + token
                            }
                        }
                    }
                },
            }),
            columns: columns,
            filterRow: { visible: true },
            searchPanel: { visible: false },
            pager: { visible: true },
            paging: {
                pageSize: undefined,
                showNavigationButtons: true
            },
            hoverStateEnabled: true,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true
            },
            wordWrapEnabled: true,
            sorting: {
                mode: 'single'
            }
        };
    }
})();
