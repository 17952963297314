(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('documentCertificationSign', documentCertificationSignFilter);

    documentCertificationSignFilter.$inject = ['logger', 'documentCertificationSign', 'i18nFilter'];

    function documentCertificationSignFilter(logger, documentCertificationSign, i18nFilter) {
        return function (input) {
            var value = Number(input);
            if (value == documentCertificationSign.notApplicable){
                return i18nFilter('translations.schedule_process_gui_certificate_status_not_applicable');
            }
            else if (value == documentCertificationSign.waiting){
                return i18nFilter('translations.schedule_process_gui_certificate_status_waiting');
            }
            else if (value == documentCertificationSign.signed) {
                return i18nFilter('translations.schedule_process_gui_certificate_status_signed');
            }
            else{
                return i18nFilter('translations.schedule_process_gui_certificate_status_no_sign');
            }
        };
    }
})();
