(function () {
    'use strict';

    angular
        .module('app.examSchedulePacks')
        .controller('examSchedulePackPaymentOrders', examSchedulePackPaymentOrders);

    examSchedulePackPaymentOrders.$inject = ['examArrangement', 'activityType', 'examNameAndScheduleCode', 'examSchedulesDataService', '$scope', '$state', '$stateParams', '$uibModal', 'i18nFilter', 'dateFilter', 'exceptionHelper', 'logger', 'documentsDataService'];

    function examSchedulePackPaymentOrders(examArrangement, activityType, examNameAndScheduleCode, examSchedulesDataService, $scope, $state, $stateParams, $uibModal, i18nFilter, dateFilter, exceptionHelper, logger, documentsDataService) {
        /* jshint validthis:true */
        var vm = this;

        vm.noTravelOrderSet = false;
        vm.saveCommissionError = false;
        vm.entityId = $stateParams.entityId;

        vm.formData = {
            activityType: activityType,
            activityTypes: [
                {
                    id: 1,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_written')
                },
                {
                    id: 2,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_verbal')
                },
                {
                    id: 3,
                    format: i18nFilter('translations.exams_edit_gui_label_activity_type_eExam')
                }
            ],
            commissionMemberTypes: [
                {
                    id: 0,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_president')
                },
                {
                    id: 1,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_member')
                },
                {
                    id: 2,
                    format: i18nFilter('translations.exam_arrangements_gui_label_commission_member_type_independent_expert')
                }
            ],
            commissionMemberColumnsWritten: [
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_name'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_type'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_written_exam_preprations'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_written_exam_evaluations'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_kilometers'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_other'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_absent'),
            ],
            commissionMemberColumnsVerbal: [
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_name'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_type'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_verbal_exam_active'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_verbal_exam_passive'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_kilometers'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_other'),
                i18nFilter('translations.exam_paymentOrders_gui_label_commission_member_absent'),
            ],
            noData: i18nFilter('translations.exam_arrangements_gui_warning_no_data')
        };

        vm.lookup = {};
        for (var i = 0, len = vm.formData.commissionMemberTypes.length; i < len; i++) {
            vm.lookup[vm.formData.commissionMemberTypes[i].id] = vm.formData.commissionMemberTypes[i].format;
        }

        vm.item = examArrangement;
        //vm.item.scheduleActivities.forEach(function (activity, indexActivity) {

        //    if (activity.activityType !== vm.formData.activityType.eExam) {
        //        activity.commissions.forEach(function (commission, indexCommission) {
                        
        //            if (commission.commissionMembers !== null && commission.commissionMembers.length > 0) {
        //                commission.commissionMembers.forEach(function (commissionMemberVM) {
        //                    if (commissionMemberVM.id === commission.chairman.id) {
        //                        commissionMemberVM.commissionMemberType = vm.formData.commissionMemberTypes[0].format;
        //                    }
        //                    else {
        //                        commissionMemberVM.commissionMemberType = vm.formData.commissionMemberTypes[1].format;
        //                    }
        //                });

        //            } else {
        //                commission.commissionMembers = [];
        //            }

        //        });
        //    } else if (activity.activityType == vm.formData.activityType.eExam) {
        //        //if (activity.preparersAndEvaluators != null && activity.preparersAndEvaluators.length > 0) {
        //        //    if (examApplicationLearners != null && examApplicationLearners.length > 0) {
        //        //        examApplicationLearners.forEach(function (candidate) {
        //        //            var found = false;
        //        //            activity.preparersAndEvaluators.forEach(function (preparerAndEvaluator) {
        //        //                if (preparerAndEvaluator.candidate.id == candidate.id) {
        //        //                    found = true;
        //        //                }
        //        //            });
        //        //            if (!found) {
        //        //                activity.preparersAndEvaluators.push({
        //        //                    candidate: candidate,
        //        //                    preparer: preparers[Math.floor(Math.random() * preparers.length)],
        //        //                    evaluator: evaluators[Math.floor(Math.random() * evaluators.length)]
        //        //                });
        //        //            }
        //        //        });
        //        //        activity.preparersAndEvaluators.sort((a, b) => a.candidate.userDisplayName.localeCompare(b.candidate.userDisplayName));
        //        //    }
        //        //} else {
        //        //    addPreparersAndEvaluators(activity);
        //        //}
        //    }
        //});

        vm.item.nameAndCode = examNameAndScheduleCode.examCode + "-" + examNameAndScheduleCode.code;
        vm.item.examCode = examNameAndScheduleCode.examCode;
        vm.getCalculatedData = getCalculatedData;
        vm.downloadPaymentOrderDocument = downloadPaymentOrderDocument;
        vm.save = save;

        vm.localeMessages2 = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            editTooltip: i18nFilter('translations.general_action_edit'),
            deleteTooltip: i18nFilter('translations.general_action_delete'),
            downloadTravelOrderTooltip: i18nFilter('translations.exams_commission_gui_label_download_travel_order')
        };

        vm.item.scheduleActivities.forEach(function (activity, indexActivity) {
            var type = vm.formData.activityTypes.find(function (element) {
                return element.id == activity.activityType;
            });
            activity.activityTypeName = type.format;
        });

        function getCalculatedData(commissionId, indexActivity, indexCommission) {
            examSchedulesDataService.getCalculatedPaymentOrderDataAsync(commissionId).then(function (data) {
                vm.item.scheduleActivities[indexActivity].commissions[indexCommission].commissionMembers.forEach(function (commissionMember) {

                    var calculatedData = data.commissionMembers.filter(x => x.id === commissionMember.id)[0];

                    commissionMember.evaluationsCount = calculatedData.evaluationsCount;
                    commissionMember.preparationCount = calculatedData.preparationCount;
                    commissionMember.verbalCandidatesActive = calculatedData.verbalCandidatesActive;
                    commissionMember.verbalCandidatesPassive = calculatedData.verbalCandidatesPassive;

                });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function downloadPaymentOrderDocument(commission, indexCommission) {
            var year = commission.startDateTime.slice(0, -5);
            documentsDataService.downloadDocument('api/examSchedules/paymentOrder/commission/' + commission.id + '/download', 'Izplačilni nalog_' + (indexCommission + 1) + ' Izpitna komisija ' + year + '.xlsx');
        }

        function save(indexActivity, indexCommission) {
            examSchedulesDataService.updateExamScheduleAsync(angular.copy(vm.item.scheduleActivities[indexActivity].commissions[indexCommission])).then(function (data) {
                logger.success(i18nFilter('translations.exam_paymentOrder_queue_save'), data);
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

    }


    angular
    .module('app.examSchedulePacks')
        .directive('blurCurrency', blurCurrency);

    function blurCurrency($filter) {

        function link(scope, el, attrs, ngModelCtrl) {

            function formatter(value) {
                if (isInt(value) || isFloat(value)) {
                    ngModelCtrl.$setViewValue(value);
                    value = value.toFixed(2).replace(".", ",");

                    el.val(value);
                }

                else {
                    value = value ? parseFloat(value.toString().replace(/[^0-9,]/g, '').replace(',', '.')) || 0.0 : 0.0;
                    value = value.toFixed(2);
                    ngModelCtrl.$setViewValue(parseFloat(value));

                    el.val(value.toString().replace(".",","));
                }

                if (!scope.$$phase) {
                    //$digest or $apply
                    scope.$apply();
                }

                return value;
            }

            function isInt(n) {
                return Number(n) === n && n % 1 === 0;
            }

            function isFloat(n) {
                return Number(n) === n && n % 1 !== 0;
            }

            ngModelCtrl.$formatters.push(formatter);

            el.bind('focus', function () {
                //el.val('');
            });

            el.bind('blur', function () {
                formatter(el.val());
            });

            el.on('keydown', function (event) {
                var val = el.val();
                var selectedText = window.getSelection().toString();

                if (event.shiftKey) { event.preventDefault(); return false; }
                //console.log(event.which);
                if ([8, 13, 27, 37, 38, 39, 40, 46].indexOf(event.which) > -1) {
                    // backspace, enter, escape, arrows, delete
                    return true;
                }
                else if (event.which >= 48 && event.which <= 57) {
                    // numbers 0 to 9
                    if (val.indexOf(",") != -1) {
                        var split = val.split(",");
                        if (split.length == 2 && split[1].length < 2) {
                            return true;
                        }
                        else if (selectedText == val) {
                            return true;
                        }

                        return false;
                    }

                    return true;
                }
                else if (event.which >= 96 && event.which <= 105) {
                    // numpad numbers 0-9
                    if (val.indexOf(",") != -1) {
                        var split = val.split(",");
                        if (split.length == 2 && split[1].length < 2) {
                            return true;
                        }
                        else if (selectedText == val) {
                            return true1
                        }

                        return false;
                    }

                    return true;
                }
                else if (event.which == 188 || event.which == 110) {
                    // comma
                    return !isNaN(parseFloat(val)) && !isNaN(val - 0);
                }

                else {
                    event.preventDefault();
                    return false;
                }
            });
        }

        return {
            require: '^ngModel',
            scope: true,
            link: link
        };
    }
    blurCurrency.$inject = ['$filter'];

})();
