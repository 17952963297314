(function () {
    'use strict';
    angular
        .module('app.users')
        .controller('editUserTransferModal', editUserTransferModal);

    editUserTransferModal.$inject = ['$scope', 'currentUser', 'remoteUser'];
    
    function editUserTransferModal($scope, currentUser, remoteUser) {
        var modalvm = this;
        modalvm.cancel = cancel;
        modalvm.confirm = confirm;

        modalvm.formData = {

            currentUser: currentUser.id,
            remoteUser: remoteUser.id
        }
        
        function confirm() {
            $scope.$close();
        }


        function cancel() {
            $scope.$dismiss();
        }
    }
})();


