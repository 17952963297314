(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.eExam')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('schoolings.administration.activities.edit.eExamSecondStep', {
                url: '/eExamSecondStep',
                templateUrl: 'app/schoolings/administration/activities/edit/eExam/secondStep.html',
                controller: 'EExamSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                    isActivityEdit: true
                },
                resolve: {
                    eexams: ['examsDataService', function (examsDataService) {
                        return examsDataService.getExamsWithEexamActivity();
                    }]
                }
            })
            .state('schoolings.administration.activities.add.eExamSecondStep', {
                url: '/eExamSecondStep',
                templateUrl: 'app/schoolings/administration/activities/edit/eExam/secondStep.html',
                controller: 'EExamSecondStep',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['CatalogAdmin', 'RealSchoolingOwner'],
                    isActivityEdit: false
                },
                resolve: {
                    eexams: ['examsDataService', function (examsDataService) {
                        return examsDataService.getExamsWithEexamActivity();
                    }]
                }
            });
    }
})();
