(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities.edit.virtualClassroom')
        .controller('VirtualClassroomSecondStep', virtualClassroomSecondStep);

    virtualClassroomSecondStep.$inject = ['logger', 'activitiesWizardDataService', '$state', '$scope', '$stateParams'];

    function virtualClassroomSecondStep(logger, activitiesWizardDataService, $state, $scope, $stateParams) {
        var vm = this;
        vm.item = activitiesWizardDataService.getData();
        vm.next = next;
        vm.previous = previous;
        vm.isEdit = $state.current.data.isActivityEdit;

        if (!vm.isEdit) {
            vm.item.totalPoints = 1;
        }

        function next() {
            $state.go($scope.navigation[2].state);
        }

        function previous() {
            $state.go($scope.navigation[0].state);
        }
    }
})();
