(function () {
    'use strict';

    angular
        .module('app')
        .directive('emsoCheck', emsoCheck);

    emsoCheck.$inject = ['$window'];

    function emsoCheck($window) {
        // Usage: emso-check
        //     <emso></emso>
        // Creates:
        // 
        var directive = {
            link: link,
            require: 'ngModel',
            restrict: 'A'
        };
        return directive;

        function link(scope, elm, attrs, ctrl) {

            ctrl.required = attrs.required == undefined ? false : true;

            ctrl.$parsers.unshift(function (viewValue) {

                var numberArray = [];
                viewValue.split('').forEach(character => {
                    if (!isNaN(character)) {
                        numberArray.push(+character);
                    }
                });

                if (numberArray.length > 13) {
                    ctrl.$setValidity('emso', false);
                    return viewValue;
                }

                let v = 11 - ((7 * (numberArray[0] + numberArray[6]) +
                    6 * (numberArray[1] + numberArray[7]) +
                    5 * (numberArray[2] + numberArray[8]) +
                    4 * (numberArray[3] + numberArray[9]) +
                    3 * (numberArray[4] + numberArray[10]) +
                    2 * (numberArray[5] + numberArray[11])) % 11);
                if (v > 9) {
                    v = 0;
                }
                if (v === numberArray[12]) {
                    ctrl.$setValidity('emso', true);
                    return viewValue;
                } else {
                    ctrl.$setValidity('emso', false);
                    return viewValue;
                }
            });

            ctrl.$validate();
        }
    }

})();
