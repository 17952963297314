(function () {
    'use strict';

    angular
        .module('app.competences')
        .controller('competences', competencesCtrl);

    competencesCtrl.$inject = ['$sce', 'logger', 'competencesGroups', 'i18nFilter', 'competenceGroupsDataService', '$uibModal', 'competenceDataService', 'competences', 'competencesLevelsTemplate', 'competenceLevelsDataService', 'exceptionHelper', 'emptyGuid'];

    function competencesCtrl($sce, logger, competencesGroups, i18nFilter, competenceGroupsDataService, $uibModal, competenceDataService, competences, competencesLevelsTemplate, competenceLevelsDataService, exceptionHelper, emptyGuid) {
        var vm = this;       
        vm.item = {
            id: undefined,
            text: undefined,
            definition: undefined,
            status: undefined,
            parentCompetenceGroupId: undefined,
            isCompetenceGroup: undefined,
            competenceGroups: [],
            competenceLevels: []
        };
        vm.items = competencesGroups;
        vm.competencesLevelsTemplate = competencesLevelsTemplate;
        vm.localeMessages = {
            noCompetenceLevelsTemplate: i18nFilter('translations.competence_no_levels_template')
        };
        vm.treeViewOptions = {
            items: vm.items,
            width: 300,
            expandedExpr: "closed",
            searchEnabled: true,
            onItemClick: function (e) {
                var item = e.itemData;  
                
                vm.item.id = item.id;
                vm.item.text = item.text;
                vm.item.definition = item.definition;
                vm.item.status = item.status;
                vm.item.isCompetenceGroup = item.isCompetenceGroup;

                if (item.parentCompetenceGroupId !== emptyGuid) {
                    vm.item.parentCompetenceGroupId = item.parentCompetenceGroupId;
                }

                if (!item.isCompetenceGroup) {                    
                    for (var i = 0; i < competences.length; i++) {
                        if (item.id === competences[i].id) {
                            vm.item.competenceLevels = competences[i].competenceLevels;                            
                            setCompetenceLevelsTable(); 

                            break;
                        }                        
                    }                    
                } else {
                    vm.dataGridOptions = undefined;
                    vm.item.competenceLevels = undefined;                   
                }
            }
        };

        vm.addCompetence = addCompetence;
        vm.editCompetence = editCompetence;
        vm.deleteCompetence = deleteCompetence;
        vm.addCompetenceGroup = addCompetenceGroup;
        vm.editCompetenceGroup = editCompetenceGroup;
        vm.addSubCompetenceGroup = addSubCompetenceGroup;
        vm.editSubCompetenceGroup = editSubCompetenceGroup;
        vm.deleteCompetenceGroup = deleteCompetenceGroup;
        vm.deleteCompetenceLevel = deleteCompetenceLevel;
        vm.manageCompetenceLevelsTemplate = manageCompetenceLevelsTemplate;
        vm.trustAsHtml = trustAsHtml;

        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        }

        function addCompetence() {
            var competence = $uibModal.open({
                templateUrl: 'app/manage/competences/editCompetence/editCompetence.html',
                controller: 'EditCompetence',
                controllerAs: 'vm',
                resolve: {
                    competence: function () {},
                    competenceGroups: ['competenceGroupsDataService', function (competenceGroupsDataService) {
                        return competenceGroupsDataService.getCompetenceGroupsAndSubGroups();
                    }],
                    isEdit: function () {
                        return false;
                    }
                }
            });

            competence.result.then(function (competence) {
                if (competence.text) {
                    competenceDataService.addCompetence(competence).then(function (data) {
                        logger.success(i18nFilter('translations.competence_queue_add'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function editCompetence(item) {
            var competence = $uibModal.open({
                templateUrl: 'app/manage/competences/editCompetence/editCompetence.html',
                controller: 'EditCompetence',
                controllerAs: 'vm',
                resolve: {
                    competence: ['competenceDataService', function (competenceDataService) {
                        return competenceDataService.getCompetenceById(item.id);
                    }],
                    competenceGroups: ['competenceGroupsDataService', function (competenceGroupsDataService) {
                        return competenceGroupsDataService.getCompetenceGroupsAndSubGroups();
                    }],
                    isEdit: function () {
                        return true;
                    }
                }
            });

            competence.result.then(function (competence) {
                if (competence.text) {
                    competenceDataService.editCompetence(competence).then(function (data) {
                        logger.success(i18nFilter('translations.competence_queue_edit'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function deleteCompetence(item) {
            $uibModal.open({
                templateUrl: 'app/manage/competences/deleteCompetence/deleteCompetence.html',
                controller: 'DeleteCompetence',
                controllerAs: 'vm',
                resolve: {
                    competence: function () {
                        return item;
                    }
                }
            });
        }

        function addCompetenceGroup() { 
            var competenceGroup = $uibModal.open({
                templateUrl: 'app/manage/competences/editGroup/editGroup.html',
                controller: 'EditGroup',
                controllerAs: 'vm',
                resolve: {                    
                    competenceGroup: function () {},
                    isEdit: function () {
                        return false;
                    }
                }
            });

            competenceGroup.result.then(function (competenceGroup) {
                if (competenceGroup.text) {
                    competenceGroupsDataService.addCompetenceGroup(competenceGroup).then(function (data) {
                        logger.success(i18nFilter('translations.competence_group_queue_add'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function editCompetenceGroup(item) {
            var competenceGroup = $uibModal.open({
                templateUrl: 'app/manage/competences/editGroup/editGroup.html',
                controller: 'EditGroup',
                controllerAs: 'vm',
                resolve: {
                    competenceGroup: function () {
                        return item;
                    },
                    isEdit: function () {
                        return true;
                    }
                }
            });

            competenceGroup.result.then(function (competenceGroup) {
                if (competenceGroup.text) {
                    competenceGroupsDataService.editCompetenceGroup(competenceGroup).then(function (data) {
                        logger.success(i18nFilter('translations.competence_group_queue_edit'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function addSubCompetenceGroup() {
            var subCompetenceGroup = $uibModal.open({
                templateUrl: 'app/manage/competences/editSubCompetenceGroup/editSubCompetenceGroup.html',
                controller: 'EditSubCompetenceGroup',
                controllerAs: 'vm',
                resolve: {
                    competenceGroups: ['competenceGroupsDataService', function (competenceGroupsDataService) {
                        return competenceGroupsDataService.getRootCompetenceGroups();
                    }],
                    competenceGroup: function () {},
                    isEdit: function () {
                        return false;
                    }
                }
            });

            subCompetenceGroup.result.then(function (competenceGroup) {
                if (competenceGroup.text) {
                    competenceGroupsDataService.addCompetenceGroup(competenceGroup).then(function (data) {
                        logger.success(i18nFilter('translations.competence_group_queue_add'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function editSubCompetenceGroup(item) {
            var subCompetenceGroup = $uibModal.open({
                templateUrl: 'app/manage/competences/editSubCompetenceGroup/editSubCompetenceGroup.html',
                controller: 'EditSubCompetenceGroup',
                controllerAs: 'vm',
                resolve: {
                    competenceGroups: ['competenceGroupsDataService', function (competenceGroupsDataService) {
                        return competenceGroupsDataService.getRootCompetenceGroups();
                    }],
                    competenceGroup: function () {
                        return item;
                    },
                    isEdit: function () {
                        return true;
                    }
                }
            });

            subCompetenceGroup.result.then(function (competenceGroup) {
                if (competenceGroup.text) {
                    competenceGroupsDataService.editCompetenceGroup(competenceGroup).then(function (data) {
                        logger.success(i18nFilter('translations.competence_group_queue_edit'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            });
        }

        function deleteCompetenceGroup(item) {
            $uibModal.open({
                templateUrl: 'app/manage/competences/deleteGroup/deleteGroup.html',
                controller: 'DeleteCompetenceGroup',
                controllerAs: 'vm',
                resolve: {
                    competenceGroup: function () {
                        return item;
                    }
                }
            });            
        }

        function manageCompetenceLevelsTemplate() {
            $uibModal.open({
                templateUrl: 'app/manage/competences/manageCompetenceLevelsTemplate/manageCompetenceLevelsTemplate.html',
                controller: 'ManageCompetenceLevelsTemplate',
                controllerAs: 'vm',
                resolve: {
                    competenceLevelsTemplate: function () {
                        return vm.competencesLevelsTemplate;
                    }
                }
            });
        }

        function deleteCompetenceLevel(id) {
            competenceLevelsDataService.deleteCompetenceLevel(id).then(function (data) {
                logger.success(i18nFilter('translations.competence_level_queue_delete'));
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function setCompetenceLevelsTable() {
            vm.dataGridOptions = {
                bindingOptions: { dataSource: 'vm.item.competenceLevels' },
                keyExpr: "id",
                paging: {
                    enabled: false
                },
                editing: {
                    mode: "row",
                    allowUpdating: true,
                    allowDeleting: true,
                    allowAdding: true,
                    texts: {
                        confirmDeleteMessage: ''
                    }
                },
                columns: [
                    {
                        dataField: "orderNo",
                        caption: i18nFilter('translations.general_gui_ordinal_number'),
                        dataType: "number",
                        width: 200,
                        validationRules: [{ type: "required" }]
                    },
                    {
                        dataField: "name",
                        caption: i18nFilter('translations.general_gui_name'),
                        width: 250,
                        validationRules: [
                            { type: "required" },
                            { type: 'stringLength', max: 255, message: i18nFilter('translations.competence_name_validation_message') }
                        ]
                    },
                    {
                        dataField: "definition",
                        caption: i18nFilter('translations.competence_gui_definition'),
                        validationRules: [
                            { type: 'stringLength', max: 4096, message: i18nFilter('translations.competence_definition_validation_message') }
                        ]
                    }
                ],
                onRowInserted: function (e) {
                    var competenceLevel = {
                        definition : e.key.definition,
                        name : e.key.name,
                        orderNo : e.key.orderNo,
                        competenceId : vm.item.id
                    };

                    competenceLevelsDataService.addCompetenceLevel(competenceLevel).then(function (data) {
                        logger.success(i18nFilter('translations.competence_level_queue_add'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                },
                onRowUpdated: function (e) {
                    var competenceLevel = {
                        id: e.key.id,
                        definition: e.key.definition,
                        name: e.key.name,
                        orderNo: e.key.orderNo,
                        competenceId: vm.item.id
                    };

                    competenceLevelsDataService.editCompetenceLevel(competenceLevel).then(function (data) {
                        logger.success(i18nFilter('translations.competence_level_queue_edit'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                },
                onRowRemoved: function (e) {
                    competenceLevelsDataService.deleteCompetenceLevel(e.key.id).then(function (data) {
                        logger.success(i18nFilter('translations.competence_level_queue_edit'));
                    }, function (error) {
                        exceptionHelper.handleException(error);
                    });
                }
            };            
        }
    }
})();
