(function () {
    'use strict';

    angular
        .module('app')
        .controller('EducationActivities', educationActivities);

    educationActivities.$inject = ['logger', 'activities', 'activityTypes', '$stateParams', 'rolesDataService', 'isEducationOwner'];

    function educationActivities(logger, activities, activityTypes, $stateParams, rolesDataService, isEducationOwner) {
        var vm = this;
        vm.activities = activities;
        vm.activityTypes = activityTypes;
        vm.learningActivityId = $stateParams.learningActivityId;
        vm.ignoreFirstOpenRule = false;
        if (vm.learningActivityId) {
            vm.ignoreFirstOpenRule = true;
        }

        vm.formData = {
            isUserAdmin: rolesDataService.isUserInRoles(['CatalogAdmin', 'EducationOwner']) || isEducationOwner
        };
    }
})();
