(function () {
    'use strict';

    angular
        .module('app.profile')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('profile', {
                parent: 'base',
                url: '/users/:id',
                templateUrl: 'app/profile/profile.html',
                controller: 'Profile',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    user: ['usersDataService', '$stateParams', function (usersDataService, $stateParams) {
                        return usersDataService.getUserById($stateParams.id);
                    }]
                }
            })

            .state('profile.edit', {
                url: '/edit',
                templateUrl: 'app/profile/edit/edit.html',
                controller: 'EditUserController',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner'],
                    type: 'profileEdit'
                },
                resolve: {
                    user: ['usersDataService', '$stateParams', function (usersDataService, $stateParams) {
                        return usersDataService.getUserById($stateParams.id);
                    }]
                }
            })
            .state('profile.certificateList', {
                url: '/certificates',
                templateUrl: 'app/profile/list/list.html',
                controller: 'UserCertificateList',
                controllerAs: 'vm',
                requireADLogin: true,
                data: {
                    roles: ['Learner']
                },
                resolve: {
                    certificates: ['activitiesDataService', '$stateParams', function (activitiesDataService, $stateParams) {
                        return activitiesDataService.getUserActivityEvents($stateParams.id);
                    }]
                }
            });
    }
})();