(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .controller('RejectLearnersDialog', RejectLearnersDialog);

    RejectLearnersDialog.$inject = ['logger', 'i18nFilter', '$scope', 'exceptionHelper', 'schedulePacksDataService', 'schedulePack'];

    function RejectLearnersDialog(logger, i18nFilter, $scope, exceptionHelper, schedulePacksDataService, schedulePack) {
        var modalvm = this;
        
        modalvm.item = { rejectLearnerReason: null };
        modalvm.confirmEFormLearnerRejection = confirmEFormLearnerRejection;
        modalvm.cancel = cancel;
        modalvm.defaultText = schedulePack.defaultRejectionText;

        if (!modalvm.defaultText || modalvm.defaultText.length === 0) {
            modalvm.item.rejectLearnerReason = 'Usposabljanje je namenjeno zaposlenim v organih državne uprave in lokalne skupnosti. Glede na oddano prijavo vaš organ zaposlitve ni razviden. Če ste del omenjene ciljne skupine, lahko prijavo ponovno oddate, pri prijavitelju pa vpišite vašo organizacijo.';
        }
        else {
            modalvm.item.rejectLearnerReason = modalvm.defaultText;
        }


        function confirmEFormLearnerRejection() {
            $scope.$close(modalvm.item.rejectLearnerReason);
        }

        function cancel() {
            $scope.$dismiss();
        }
    }

})();
