(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.delete')
        .controller('DeleteSchooling', deleteSchooling);

    deleteSchooling.$inject = ['logger', '$scope', '$stateParams', '$state', 'schoolingsDataService', 'exceptionHelper', 'i18nFilter','canDeleteSchooling'];

    function deleteSchooling(logger, $scope, $stateParams, $state, schoolingsDataService, exceptionHelper, i18nFilter,canDeleteSchooling) {
        var vm = this;
        
        vm.cancel = cancel;
        vm.ok = ok;
        vm.schoolingName = $stateParams.schoolingName;
        vm.canDeleteSchooling = canDeleteSchooling;

        function cancel() {
            $scope.$dismiss();
        };

        function ok() {
            schoolingsDataService.deleteSchooling($stateParams.id).then(function (additionalData) {
                logger.success(i18nFilter('translations.schooling_queue_delete'));
                $scope.$close(true);
            }, function (error) {
                exceptionHelper.handleException(error, false);
            });

        };
    }
})();
