(function () {
    'use strict';

    angular
        .module('app.exams.administration.edit')
        .controller('EditExamThirdStep', thirdStep);

    thirdStep.$inject = ['logger', 'automaticCatalogues', 'themes', 'activityType', 'editExamsHelper', 'guidGeneratorDataService', '$scope', '$state', '$stateParams', 'i18nFilter', '$sessionStorage', 'locations'];

    function thirdStep(logger, automaticCatalogues, themes, activityType, editExamsHelper, guidGeneratorDataService, $scope, $state, $stateParams, i18nFilter, $sessionStorage, locations) {
        var vm = this;

        if ($sessionStorage.userInfo.tenantResourceKey == "eua") {
            vm.tenantEUA = true;
        } else {
            vm.tenantEUA = false;
        }
       
        //Inherit from parent scope
        vm.navigation = $scope.navigation;

        vm.item = editExamsHelper.getData();
        vm.isEdit = $state.current.data.isEdit;

        vm.logData = logData;
        vm.previous = previous;
        vm.next = next;
        vm.cancel = cancel;
        vm.addCatalogue = addCatalogue;
        vm.removeCatalogue = removeCatalogue;
        vm.addActivity = addActivity;
        vm.addLocation = addLocation;
        vm.removeLocation = removeLocation;
        vm.resetActivitys = resetActivitys;
        vm.removeActivity = removeActivity;
        vm.filterCatalogues = filterCatalogues;
        vm.save = save;
        
        vm.onClickSelect = onClickSelect;

        if (typeof vm.item.automaticExamType === 'undefined') {
            vm.item.automaticExamType = true;
        }

        vm.formData = {
            automaticCatalogues: automaticCatalogues,
            activityType: activityType,
            themes: themes,
            locations: locations

        };

        vm.localeMessages = {
            selectThemePlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            selectPlaceholder: i18nFilter("translations.exams_edit_gui_label_question_catalogue"),
            automaticExam: i18nFilter("translations.exams_edit_gui_label_exam_tooltip_info_type_automatic"),
            manualExam: i18nFilter("translations.exams_edit_gui_label_exam_tooltip_info_type_manual"),
            canOnlyPickOneThemeTooltip: i18nFilter("translations.exams_edit_gui_label_exam_tooltip_info_only_one_theme"),
            pickLocation: i18nFilter("translations.exams_edit_gui_label_exam_locations")
        };

        if (vm.isEdit && typeof (vm.item.activity) !== "undefined") {
            vm.item.activity.forEach(function (activity, activityIndex) {
                activity.questionCatalogues.forEach(function (el) {
                    vm.formData.automaticCatalogues.forEach(function (e) {
                        if (typeof (el.catalogue) !== "undefined" && el.catalogue.id === e.id) {
                            el.catalogue = e;
                        }
                    });
                });

                activity.activityLocations.forEach(function (activityLocation, index) {
                    activityLocation.startHourConfig = {
                        type: 'time',
                        width: '100%',
                        pickerType: "list",
                        acceptCustomValue: true,
                        value: activityLocation.startHour,
                        bindingOptions: {
                            value: 'vm.item.activity[' + activityIndex + '].activityLocations[' + index + '].startHour'
                        },
                        startHour: 'vm.item.activity[' + activityIndex + '].activityLocations[' + index + '].startHour'
                    };
                });

            });
        }



        function filterCatalogues(i) {
            if (vm.item.activity[i] && vm.item.activity[i].questionCatalogues.length > 0) {
                var objects = [];
                for (var n = 0; n < vm.item.activity[i].questionCatalogues.length; n++ ) {
                    if (vm.item.activity[i].questionCatalogues[n].catalogue) {
                        objects.push(vm.item.activity[i].questionCatalogues[n].catalogue);
                    }
                }
                return vm.formData.automaticCatalogues.filter(function (x) { return objects.indexOf(x) < 0; });
            }
            return vm.formData.automaticCatalogues;
        }

        function removeActivity(index) {
            vm.item.activity.splice(index, 1);
        }

        function resetActivitys() {
            vm.item.activity = [];
        }

        function addActivity() {
            if (!vm.item.activity) {
                vm.item.activity = [];
            }
            
            var activity = { activityType: 1, examDuration: undefined, examMinimumGrade: undefined, name: "", questionCatalogues: [], themes: [], activityLocations: [] };
            vm.item.activity.push(activity);

            var activityIndex = vm.item.activity.indexOf(activity);
            vm.addCatalogue(activityIndex);
        }

        function addLocation(inx) {

            if (!vm.item.activity[inx].activityLocations) {
                vm.item.activity[inx].activityLocations = [];
            }

            var location = { 
                Id: undefined, location: undefined, startHourConfig: {
                    type: 'time',
                    width: '100%',
                    acceptCustomValue: true,
                    pickerType: "list",
                    value: new Date(1, 1, 1, 15),
                    bindingOptions: {
                        value: 'vm.item.activity[' + inx + '].activityLocations[$index].startHour'
                    },
                    startHour: undefined
                }};
            vm.item.activity[inx].activityLocations.push(location);
        }

        function removeLocation(inx, locationInx) {

            vm.item.activity[inx].activityLocations.splice(locationInx, 1);
        }

        function addCatalogue(inx) {
            if (!vm.item.activity[inx].questionCatalogues) {
                vm.item.activity[inx].questionCatalogues = [];
            }
            if (vm.item.activity[inx].questionCatalogues.length < vm.formData.automaticCatalogues.length) {
                vm.item.activity[inx].questionCatalogues.push('');
            }
        }

        function removeCatalogue(index, inx) {
            vm.item.activity[inx].questionCatalogues.splice(index, 1);
        }

        function previous() {
            $state.go(vm.navigation[1].state, $stateParams);
        }

        function next() {            
            $state.go(vm.navigation[3].state, $stateParams);
        }

        function save() {
            var item_copy = editExamsHelper.setCodeListData(vm.item);
            editExamsHelper.setData(item_copy);
            editExamsHelper.done(vm.isEdit, true);
        } 

        function cancel() {
            if (vm.isEdit) {
                $state.go('exams.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.exam');
            }
        }

        function logData() {
            logger.log("Data: ", vm.item);
        }

        function onClickSelect(item, activityIndex, catalogueIndex) {
            $("[name='activity[" + activityIndex + "].questionCatalogues[" + catalogueIndex + "].numQuestions']").trigger("touchspin.updatesettings", { max: item.maxQuestions });
        }
    }
})();
