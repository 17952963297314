(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('executionStyle', executionStyle);

    executionStyle.$inject = ['logger', 'educationExecutionStyle', 'i18nFilter'];

    function executionStyle(logger, educationExecutionStyle, i18nFilter) {

        return function (input) {
            var value = Number(input);
            var result = educationExecutionStyle.filter(function (executionStyle) {
                return executionStyle.id == value;
            })

            if (result.length == 1)
                return i18nFilter(result[0].name);
            else
                return input;
        };
    }
})();
