(function () {
    'use strict';

    angular
        .module('blocks.controls')
        .directive('iban', iban);

    iban.$inject = ['$locale', 'logger'];

    function iban($locale, logger) {
        // Usage:
        //     <iban></iban>
        // Creates:
        // 
        var directive = {
            link: link,
            require: 'ngModel',
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            ctrl.$validators.iban = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                var number = modelValue;
                var sNumber = number.toString();

                return IBAN.isValid(sNumber);
            };
        }
    }

})();