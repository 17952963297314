(function () {
    'use strict';

    angular
        .module('app.manage')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
        .state('manage', {
            parent: 'base',
            url: '/manage',
            templateUrl: 'app/manage/manage.html',
            controller: 'Manage',
            controllerAs: 'vm',
            requireADLogin: true,
            data: {
                roles: ['GlobalAdmin', 'EducationAdmin', 'TenantAdmin', 'ExamAdmin', 'ExamOwner', 'CertificateSigner']
            },
            resolve: {
                tenantInfo: ['tenantDataService', function (tenantDataService) {
                    return tenantDataService.getTenantInfo();
                }]
            }
        });
    }
})();
