(function () {
    'use strict';

    angular
        .module('app.schoolings.administration.activities')
        .factory('activitiesWizardDataService', wizard);

    wizard.$inject = ['$http', 'logger', 'guidGeneratorDataService', 'activitiesDataService', '$state', '$stateParams', 'exceptionHelper', 'activityTypes', '$q', 'i18nFilter'];

    function wizard($http, logger, guidGeneratorDataService, activitiesDataService, $state, $stateParams, exceptionHelper, activityTypes, $q, i18nFilter) {

        var internalData = undefined;
        var wizardSteps = undefined;

        var service = {
            getData: getData,
            getWizardSteps: getWizardSteps,
            clearData: clearData,
            setData: setData,
            done: done
        };

        return service;


        function getData() {
            if (!internalData) {
                //Fill default values
                internalData = {
                    isRequiredForSchoolingPass: true,
                    active: true,
                    data: {},
                    month: 0,
                    day: 0,
                    hour: 0,
                    minute: 0
                };
            }
            return internalData;
        }

        function setData(activity) {
            //TODO: Set activity data into internalData;
            internalData = activity;
        }

        function clearData() {
            internalData = undefined;
            wizardSteps = undefined;
        }


        function getWizardSteps(activityTypeId, isEdit) {
            var typeOfOperation = 'add';
            if (isEdit) {
                typeOfOperation = 'edit';
            }

            if (!wizardSteps) {
                wizardSteps = [
                    {
                        state: 'schoolings.administration.activities.' + typeOfOperation + '.firstStep',
                        number: 1,
                        text: i18nFilter('translations.activity_wizard_title_first_step_about_acitivity')
                    }
                ];
            }
            else {
                wizardSteps.splice(1, wizardSteps.length - 1);
            }


            if (activityTypeId) {
                switch (activityTypeId) {
                    case activityTypes.genericAcitvityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.genericSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.officeMixActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.officeMixSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.surveyAcitvityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.surveySecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.videoActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.videoSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.classroomActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.classroomSchoolingSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.virtualClassroomAcitvityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.virtualClassroomSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;                    
                    case activityTypes.examAcitvityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.examSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    
                    case activityTypes.scormActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.scormSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.html5ActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.html5SecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                    case activityTypes.eExamActivityType:
                        wizardSteps.push({
                            state: 'schoolings.administration.activities.' + typeOfOperation + '.eExamSecondStep',
                            number: 2,
                            text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_configuration')
                        });
                        break;
                }
            }

            wizardSteps.push({
                state: 'schoolings.administration.activities.' + typeOfOperation + '.lastStep',
                number: 3,
                text: i18nFilter('translations.activity_wizard_title_second_step_acitivity_saving')
            });


            return wizardSteps;
        }



        function done(isEdit) {
            internalData.surveyId = internalData.surveyObj ? internalData.surveyObj.id : null;
            if (internalData.examId) {
                internalData.examId = internalData.examId.id;
            }
            var schoolingId = $stateParams.id;
            if (isEdit) {
                activitiesDataService.editActivity(internalData).then(function (data) {
                    clearData();
                    $state.go("schoolings.administration.activities", { id: schoolingId }, { reload: true });
                }, function (error) {
                    if (error.exceptionCode) {
                        exceptionHelper.handleException(error);
                    }
                    else {
                        logger.error(errorWhileEditingActivity);
                    }
                });
            }
            else {
                activitiesDataService.addActivity(internalData).then(function (data) {
                    clearData();
                    $state.go("schoolings.administration.activities", { id: schoolingId }, { reload: true });
                }, function (error) {
                    if (error.exceptionCode) {
                        exceptionHelper.handleException(error);
                    }
                    else {
                        logger.error(errorWhileAddingActivity);
                    }
                });
            }
        }
    }
})();
