(function () {
    'use strict';

    angular
        .module('app.personRecords')
        .config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];
    function routeConfig($stateProvider) {
        $stateProvider
            .state('personRecords', {
                parent: 'base',
                url: '/manage/personRecords',
                templateUrl: 'app/manage/personRecords/personRecords.html',
                controller: 'personRecords',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    areas: ['areasDataService', function (areasDataService) {
                        return areasDataService.getAreas();
                    }],
                    subAreas: ['subAreasDataService', function (subAreasDataService) {
                        return subAreasDataService.getSubAreas();
                    }],
                    languages: ['personRecordsDataService', function (personRecordsDataService) {
                        return personRecordsDataService.getLanguages();
                    }]
                },
                data: {
                    roles: ['ExamAdmin']
                }
            })
            .state('personRecords.details', {
                url: '/details/:id',
                templateUrl: 'app/manage/personRecords/details/showPersonRecord.html',
                controller: 'showPersonRecord',
                controllerAs: 'vm',
                requireADLogin: true,
                resolve: {
                    personRecord: ['personRecordsDataService', '$stateParams', function (personRecordsDataService, $stateParams) {
                        return personRecordsDataService.getPersonRecordById($stateParams.id);
                    }]
                },
                data: {
                    roles: ['ExamAdmin']
                }
            });
    }
})();
