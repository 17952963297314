(function () {
    'use strict';

    angular
        .module('app.exams.bulletinSubscriptions')
        .controller('editBulletinSubscriptionsController', editBulletinSubscriptions);

    editBulletinSubscriptions.$inject = ['decimalHelper', 'dateHelper', '$state', 'i18nFilter', 'examType', 'subscription', 'bulletinSubscriptionsDataService', 'usersDataService', 'exceptionHelper', 'logger'];

    function editBulletinSubscriptions(decimalHelper, dateHelper, $state, i18nFilter, examType, subscription, bulletinSubscriptionsDataService, usersDataService, exceptionHelper, logger) {
        var vm = this;
        vm.item = subscription;
        vm.isEdit = $state.current.data.isEdit;
        
        vm.form = {
            subscribedOn: {
                type: 'date',
                width: '100%',
                inputAttr: {
                    'id': 'subscribedOn',
                    'name': 'subscribedOn',
                    'ng-required': 'true'
                },
                bindingOptions: {
                    value: 'vm.item.subscribedOn'
                },
                placeholder: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscribedOn')
            },
            subscribedTo: {
                type: 'date',
                width: '100%',
                inputAttr: {
                    'id': 'subscribedTo',
                    'name': 'subscribedTo'
                },
                bindingOptions: {
                    value: 'vm.item.subscribedTo'
                },
                placeholder: i18nFilter('translations.exam_bulletinSubscriptions_edit_gui_subscribedTo')
            }
        };

        vm.add = add;
        vm.save = save;
        
        function add() {
            bulletinSubscriptionsDataService.addSubscription(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_bulletinSubscriptions_queue_add'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }

        function save() {
            bulletinSubscriptionsDataService.editSubscription(angular.copy(vm.item)).then(function (data) {
                logger.success(i18nFilter('translations.exam_bulletinSubscriptions_queue_edit'));
                $state.go('^', null, { reload: true });
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
