(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .controller('ClassicCatalogSurvey', ClassicCatalogSurvey);

    ClassicCatalogSurvey.$inject = ['logger', 'catalogsDataService', 'exceptionHelper', 'i18nFilter', '$scope', 'rolesDataService'];

    function ClassicCatalogSurvey(logger, catalogsDataService, exceptionHelper, i18nFilter, $scope, rolesDataService) {
        var vm = this;
       
        vm.onStatusChanged = onStatusChanged;

        vm.refresh = refresh;
        vm.clearSearchQuery = clearSearchQuery;
        vm.sortClick = sortClick;
        vm.modeClick = modeClick;
        vm.toggleFilters = toggleFilters;


        vm.formData = {
            showAddSurvey: rolesDataService.isUserInRoles(['SurveyAdmin']),
            showStatusFilter: rolesDataService.isUserInRoles(['SurveyAdmin', 'SurveyOwner']),
            loading: false,
            surveys: [],
            totalCount: 0,
            filter: {
                showDeactevated: false,
                sortOrder: undefined,
                showingMode: undefined,
                searchQuery: undefined,
                page: 1,
                itemsPerPage: 8                
            },
            isFiltersShowing: false,

            sortOrders: [
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_alphabetical_asc'),
                    value: 'name asc'
                },
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_alphabetical_desc'),
                    value: 'name desc'
                }
            ],

            showingModes: [
                {
                    text: i18nFilter('translations.catalog_gui_view_option_icons'),
                    value: 'tiles',
                    icon: 'fa-th'
                },
                {
                    text: i18nFilter('translations.catalog_gui_view_option_list'),
                    value: 'list',
                    icon: 'fa-list'
                }
            ]
        };

        setInitialState();

        function setInitialState() {
            vm.formData.filter.sortOrder = vm.formData.sortOrders[0];
            vm.formData.filter.showingMode = vm.formData.showingModes[0];
            refresh();
        }

        function refresh() {
            vm.formData.loading = true;
            catalogsDataService.getSurveys(vm.formData.filter).then(function (data) {
                vm.formData.surveys = data.surveys;
                vm.formData.totalCount = data.totalCount;
                vm.formData.loading = false;
            }, function (error) {
                vm.formData.loading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearSearchQuery() {
            vm.formData.filter.searchQuery = '';
            refresh();
        }

        function onStatusChanged(value) {
            vm.formData.filter.showDeactevated = value;
            refresh();
        }

        function sortClick(sortItem) {
            vm.formData.filter.sortOrder = sortItem;
            vm.refresh();
        }

        function modeClick(modeItem) {
            vm.formData.filter.showingMode = modeItem;
            vm.refresh();
        }

        function toggleFilters() {
            $('.filters').toggleClass('hide-filters');

            if (!vm.formData.isFiltersShowing) {
                vm.formData.isFiltersShowing = true;
            } else {
                setTimeout(function () {
                    vm.formData.isFiltersShowing = false;
                    $scope.$apply();
                }, 300);
            }
        }
    }
})();

