(function () {
  "use strict";

  angular.module("app.analytics").component("userAnalytics", {
    templateUrl:
      "app/analytics/components/userAnalytics/userAnalytics.component.html",
    controller: userAnalytics,
    controllerAs: "vm",
  });

  userAnalytics.$inject = [
    "$location",
    "logger",
    "i18nFilter",
    "exceptionHelper",
    "analyticsDataService",
  ];

  function userAnalytics(
    $location,
    logger,
    i18nFilter,
    exceptionHelper,
    analyticsDataService
  ) {
    /* jshint validthis:true */
    var vm = this;

    vm.formData = {
      activeUsersNumber: undefined,
      inactiveUsersNumber: undefined,
      waitingForConfirmationUsersNumber: undefined,
    };

    vm.userStatusData = undefined;

    vm.localeMessages = {
      selectPlaceholder: i18nFilter(
        "translations.general_gui_placeholder_select"
      ),
    };

    vm.showData = false;

    analyticsDataService.getUserStatusData().then(
      function (data) {
        vm.userStatusData = data;
        if (vm.userStatusData) {
          vm.activeUsersNumber = vm.userStatusData.activeUsersNumber;
          vm.inactiveUsersNumber = vm.userStatusData.inactiveUsersNumber;
          vm.waitingForConfirmationUsersNumber =
            vm.userStatusData.waitingForConfirmationUsersNumber;
        } else {
          vm.activeUsersNumber = 0;
          vm.inactiveUsersNumber = 0;
          vm.waitingForConfirmationUsersNumber = 0;
        }
      },
      function (error) {
        exceptionHelper.handleException(error);
        vm.formData.years.push(0);
      }
    );
  }
})();
