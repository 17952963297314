(function () {
    'use strict';

    angular
        .module('app.educations.administration.edit')
        .controller('EditEducationSixthStep', sixthStep);

    sixthStep.$inject = ['logger', 'i18nFilter', '$state', '$scope', 'editEducationHelper', '$stateParams', 'fieldNames'];

    function sixthStep(logger, i18nFilter, $state, $scope, editEducationHelper,  $stateParams, fieldNames) {
        var vm = this;
        vm.isEdit = $state.current.data.isEdit;

        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select")
        };

        vm.item = editEducationHelper.getData();
        vm.item.automaticApplicationConfirmation = !vm.isEdit ? true : vm.item.automaticApplicationConfirmation;

        vm.formData = {
            fields: fieldNames
        };

        vm.done = done;
        vm.cancel = cancel;
        vm.back = back;

        function done() {
            var item_copy = angular.copy(vm.item);

            if (item_copy.targetSubgroups != null) {
                item_copy.targetSubgroups.forEach(function (targetSubgroupItem) {
                    var index = item_copy.targetSubgroups.indexOf(targetSubgroupItem);
                    targetSubgroupItem.sortOrder = index;
                    item_copy.targetSubgroups[index] = targetSubgroupItem;
                })
            }

            if (item_copy.educationExecutionStyle == null) {
                item_copy.educationExecutionStyle = 1;
            } else if (item_copy.educationExecutionStyle.id!=null){
                item_copy.educationExecutionStyle = item_copy.educationExecutionStyle.id;
            }

            item_copy.prices.forEach(function (price) {
                if (price.category.name == "InstructorPrice" && price.instructorType.id!=null) {
                    price.instructorType = price.instructorType.id;
                }
            });
            editEducationHelper.setData(item_copy);
            editEducationHelper.done(vm.isEdit);
        }

        function back() {
            $state.go($scope.navigation[4].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('educations.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.education');
            }
        }
    }
})();
