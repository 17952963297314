(function () {
    'use strict';

    angular
        .module('app')
        .controller('addProgramPromotedEntities', addProgramPromotedEntitiesCtrl);

    addProgramPromotedEntitiesCtrl.$inject = ['logger', 'promotedEntitiesDataService', 'exceptionHelper', 'i18nFilter', 'promotedEntityType', 'programs', '$state'];

    function addProgramPromotedEntitiesCtrl(logger, promotedEntitiesDataService, exceptionHelper, i18nFilter, promotedEntityType, programs, $state) {
        var vm = this;

        vm.item = undefined;

        vm.add = add;

        vm.formData = {
            programs: programs
        };


        function add() {
            var entity = {
                entityType: promotedEntityType.program,
                entityId: vm.item.id
            };

            promotedEntitiesDataService.addPromotedEntity(entity).then(function (data) {
                logger.success(i18nFilter('translations.manage_promoted_entities_queue_add'));
                $state.go("^");
            }, function (error) {
                exceptionHelper.handleException(error);
            });
        }
    }
})();
