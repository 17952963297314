(function () {
    'use strict';

    angular
        .module('app.catalogs')
        .controller('ClassicCatalogEducation', ClassicCatalogEducation);

    ClassicCatalogEducation.$inject = ['competenceLevels', 'logger', 'categories', 'targetGroups', 'languages', 'educationExecutionStyle', 'catalogsDataService', 'exceptionHelper', 'i18nFilter', 'rolesDataService', '$scope', 'myEntitiesCatalogFilter', 'contentTypeTags', 'userIsEducationOwner', 'documentsDataService'];

    function ClassicCatalogEducation(competenceLevels, logger, categories, targetGroups, languages, educationExecutionStyle, catalogsDataService, exceptionHelper, i18nFilter, rolesDataService, $scope, myEntitiesCatalogFilter, contentTypeTags, userIsEducationOwner, documentsDataService) {
        var vm = this;

        vm.onCategoryChanged = onCategoryChanged;
        vm.onLanguageChanged = onLanguageChanged;
        vm.onExecutionStyleChanged = onExecutionStyleChanged;
        vm.onStatusChanged = onStatusChanged;
        vm.onCompetenceChanged = onCompetenceChanged;
        vm.onTargetGroupChanged = onTargetGroupChanged;

        vm.refresh = refresh;
        vm.clearSearchQuery = clearSearchQuery;
        vm.sortClick = sortClick;
        vm.modeClick = modeClick;
        vm.toggleFilters = toggleFilters;
        vm.onMyEntitiesChanged = onMyEntitiesChanged;
        vm.downloadList = downloadList;


        vm.formData = {
            userIsEducationOwner: userIsEducationOwner,
            showAddEducation: rolesDataService.isUserInRoles(['CatalogAdmin']),
            showStatusFilter: rolesDataService.isUserInRoles(['CatalogAdmin']),
            loading: false,
            educations: [],
            totalCount: 0,
            competenceLevels: competenceLevels,
            filter: {
                showDeactevated: false,
                sortOrder: undefined,
                showingMode: undefined,
                searchQuery: undefined,
                categories: undefined,
                languages: undefined,
                competenceLevels: undefined,
                executionStyles: undefined,
                myEntities: undefined,
                targetGroups: undefined,
                page: 1,
                itemsPerPage: 8,
                tags: [{
                    id: contentTypeTags.educationTag,
                    name: undefined
                }]
            },
            isFiltersShowing: false,

            sortOrders: [
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_alphabetical_asc'),
                    value: 'name asc'
                },
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_alphabetical_desc'),
                    value: 'name desc'
                },
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_rating_asc'),
                    value: 'rating asc'
                },
                {
                    text: i18nFilter('translations.catalog_gui_filter_sort_options_rating_desc'),
                    value: 'rating desc'
                }
            ],

            showingModes: [
                {
                    text: i18nFilter('translations.catalog_gui_view_option_icons'),
                    value: 'tiles',
                    icon: 'fa-th'
                },
                {
                    text: i18nFilter('translations.catalog_gui_view_option_list'),
                    value: 'list',
                    icon: 'fa-list'
                }
            ],

            executionStyles: translateExecutionStyles(educationExecutionStyle),
            categories: categories,
            languages: languages,
            targetGroups: targetGroups
        };

        setInitialState();

        function setInitialState() {
            vm.formData.filter.sortOrder = vm.formData.sortOrders[0];
            vm.formData.filter.showingMode = vm.formData.showingModes[0];
            vm.formData.filter.myEntities = myEntitiesCatalogFilter.all;

            refresh();
        }

        function refresh() {
            vm.formData.loading = true;
            catalogsDataService.getEducations(vm.formData.filter).then(function (data) {
                vm.formData.educations = data.educations;
                vm.formData.totalCount = data.totalCount;
                vm.formData.loading = false;
            }, function (error) {
                vm.formData.loading = false;
                exceptionHelper.handleException(error);
            });
        }

        function clearSearchQuery() {
            vm.formData.filter.searchQuery = '';
            refresh();
        }

        function onCategoryChanged(categories) {
            vm.formData.filter.categories = categories;
            logger.log("On category changed: ", vm.formData.filter);

            refresh();
        }

        function onCompetenceChanged(competenceLevels) {
            vm.formData.filter.competenceLevels = competenceLevels;
            logger.log("On competence changed: ", vm.formData.filter);

            refresh();
        }

        function onTargetGroupChanged(targetGroups) {
            vm.formData.filter.targetGroups = targetGroups;
            logger.log("On target group changed: ", vm.formData.filter);

            refresh();
        }

        function onLanguageChanged(languages) {
            vm.formData.filter.languages = languages;
            logger.log("On language changed: ", vm.formData.filter);

            refresh();
        }

        function translateExecutionStyles(executionStyles) {
            executionStyles.forEach(function (executionStyle) {
                executionStyle.name = i18nFilter(executionStyle.name);
            })
            return executionStyles;
        }

        function onExecutionStyleChanged(executionStyles) {
            vm.formData.filter.executionStyles = executionStyles;
            logger.log("On execution style changed: ", vm.formData.filter);

            refresh();
        }

        function onMyEntitiesChanged(value) {
            vm.formData.filter.myEntities = value;
            logger.log('On my entities changed: ', vm.formData.filter);

            refresh();
        }

        function onStatusChanged(value) {
            vm.formData.filter.showDeactevated = value;
            refresh();
        }

        function sortClick(sortItem) {
            vm.formData.filter.sortOrder = sortItem;
            vm.refresh();
        }

        function modeClick(modeItem) {
            vm.formData.filter.showingMode = modeItem;
            vm.refresh();
        }

        function downloadList() {
            documentsDataService.downloadDocument('api/educations/educations', 'Seznam usposabljanj.xlsx');
        }

        function toggleFilters() {
            $('.filters').toggleClass('hide-filters');

            //if ($('#tiles-container').hasClass('col-lg-12')) {
            //    $('#tiles-container').toggleClass('col-lg-9').toggleClass('col-lg-12');
            //} else {
            //    setTimeout(function () {
            //        $('#tiles-container').toggleClass('col-lg-9').toggleClass('col-lg-12');
            //    }, 300);
            //}

            if (!vm.formData.isFiltersShowing) {
                vm.formData.isFiltersShowing = true;
            } else {
                setTimeout(function () {
                    vm.formData.isFiltersShowing = false;
                    $scope.$apply();
                }, 300);
            }
        }
    }
})();
