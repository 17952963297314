(function () {
    'use strict';

    angular
        .module('app.dataservice')
        .factory('levelsDataService', levels);

    levels.$inject = ['$http', 'logger', 'locale', '$q', 'activityTypes', 'jsonHelper', 'dateHelper', '$sessionStorage'];

    function levels($http, logger, locale, $q, activityTypes, jsonHelper, dateHelper, $sessionStorage) {
        var service = {
            getAllLevels: getAllLevels
        };

        return service;

        function getAllLevels() {
            return $http.get('api/levels').then(function (data) {
                return data.data;
            }, function (data, status, headers, config) {
                if (data) { return $q.reject({ exception: data.data, status: data.status }); } else { return $q.reject(); }
            });
        }
        
    }
})();
