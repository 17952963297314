(function () {
    'use strict';

    angular
        .module('app')
        .controller('EditProgramFirstStep', firstStep);

    firstStep.$inject = [
        'logger',
        'editProgramHelper',
        'educationAccessMode',
        'categories',
        'usersDataService',
        '$state',
        '$stateParams',
        '$scope',                         
        'i18nFilter',
        'tags',
        'guidGeneratorDataService',
        'rolesDataService',
        'competenceLevels',
        'targetSubgroups'
    ];

    function firstStep(
        logger,
        editProgramHelper,
        educationAccessMode,
        categories,
        usersDataService,
        $state,
        $stateParams,
        $scope,   
        i18nFilter,
        tags,
        guidGeneratorDataService,
        rolesDataService,
        competenceLevels,
        targetSubgroups
    ) {
        var vm = this;
        vm.item = editProgramHelper.getData();
        vm.isEdit = $state.current.data.isEdit;
        //vm.item.id = $stateParams.Id;
        if (vm.isEdit) {
            vm.isAnyDeactivatedEducationPartOfProgram = vm.item.isAnyDeactivatedEducationPartOfProgram;
        }


        vm.localeMessages = {
            selectPlaceholder: i18nFilter("translations.general_gui_placeholder_select"),
            usersSelectPlaceholder: i18nFilter("translations.general_gui_async_ddl_placeholder_select"),
            ownersCanCreateAssignments: i18nFilter("translations.education_edit_gui_tooltip_info_owners_can_create_assignments"),
            hasDedicatedSchedules: i18nFilter("translations.program_edit_gui_tooltip_info_dedicated_schedules"),
            generateDocuments: i18nFilter("translations.education_edit_gui_tooltip_info_generate_documents"),
            catalogVisibility: i18nFilter("translations.program_edit_gui_tooltip_info_catalog_visibility"),
            status: i18nFilter("translations.program_edit_gui_tooltip_info_status"),
            public: i18nFilter("translations.program_edit_gui_tooltip_info_visible_available"),
            displayOnly: i18nFilter("translations.program_edit_gui_tooltip_info_visible_not_available"),
            enrolledUsersAndOwnersOnly: i18nFilter("translations.program_edit_gui_tooltip_info_visible_to_enrolled_users_and_owners"),
            attendeeConfirms: i18nFilter("translations.education_edit_gui_tooltip_info_attendee_confirms_education_finish"),
            ownerConfirms: i18nFilter("translations.education_edit_gui_tooltip_info_owner_confirms_education_finish")
        };
        vm.formData = {                        
            educationAccessMode: educationAccessMode,            
            users: [],
            categories: categories,
            tags: tags,
            usersIsLoading: false,            
            tinymceOptions: {
                plugins: 'advlist autolink lists link image charmap hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
                toolbar1: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
            },
            competenceLevels: competenceLevels,
            targetSubgroups: targetSubgroups
        };


        vm.dateTime = new Date().getTime();
        vm.logData = logData;
        vm.refreshUsers = refreshUsers;
        vm.next = next;
        vm.tagTransform = tagTransform;
        vm.cancel = cancel;
        vm.showStatus = rolesDataService.isUserInRoles(['CatalogAdmin']);
        vm.save = save;
        vm.isCatalogVisibilityInvalid = isCatalogVisibilityInvalid;
        //#region Image
        /////////////////////////// IMAGE RESIZE ///////////////////////////////
        vm.myImage = '';

        var handleFileSelect = function (evt) {
            var file = evt.currentTarget.files[0];
            vm.item.pictureName = file.name;

            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        /////////////////////////// END IMAGE RESIZE ///////////////////////////////
        //#endregion

        function refreshUsers(query, selected) {
            if (query === undefined || query === "") {
                return;
            }

            //For selections that come from multieselect, extract user id's, else create empty array
            const userIds = (selected && Array.isArray(selected)) ? selected.map((user) => user.id) : [];
            vm.formData.instructorsIsLoading = true;

            usersDataService.searchForActiveUsers(query, userIds).then(function (data) {
                vm.formData.users = data;
                vm.formData.usersIsLoading = false;
            }, function (error) {
                vm.formData.instructorsIsLoading = false;
                logger.error(i18nFilter("translations.education_exception_message_fetching_education_owners"));
            });
        }

        function save() {
            var item_copy = angular.copy(vm.item);
            editProgramHelper.setData(item_copy);
            editProgramHelper.done(vm.isEdit, true);
        }

        function next() {
            $state.go($scope.navigation[1].state);
        }

        function cancel() {
            if (vm.isEdit) {
                $state.go('programs.home', $stateParams);
            }
            else {
                $state.go('classicCatalog.program');
            }
        }

        function tagTransform(tag) {
            return {
                name: tag,
                id: guidGeneratorDataService.getGuid()
            };
        }


        function logData() {
            logger.log("Data: ", vm.item);
        }

        function isCatalogVisibilityInvalid() {
            if (vm.item.accessMode != vm.formData.educationAccessMode.enrolledUsersAndOwnersOnly) {
                return true;
            }

            return false;
        }
    }
})();
